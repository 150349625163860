<script lang="ts" src="./component.ts"></script>
<style lang="scss" src="./component.scss"></style>

<template>
  <div :class="b()">
    <div :class="b('row-currency')">
      <span
        v-if="row.type === 'fiat'"
        :class="[
          b('row-icon'),
          'fi',
          `fi-${row.currency ? row.currency.country : row.country}`,
        ]"
      ></span>

      <img
        v-else-if="row.type === 'crypto' && row.name"
        :class="b('row-icon', { crypto: true })"
        :src="require(`@/assets/images/crypto/${row.name.toLowerCase()}.svg`)"
        alt=""
      />

      <div :class="b('row-currency-group')">
        <span :class="b('row-currency-label')">
          {{ row.currency ? row.currency.name : row.name.toUpperCase() }}
        </span>

        <span
          :class="
                b('row-currency-tag', {
                  bank: (row.type as string) === 'fiat',
                  crypto: (row.type as string) === 'crypto',
                })
              "
        >
          {{
            (row.type as string) === "fiat" ? "Bank Account" : "Crypto Account"
          }}
        </span>
      </div>
    </div>

    <div :class="b('row-total')">
      <p :class="b('row-total-label')">
        {{ displayedAmount }}
      </p>
    </div>

    <div :class="b('row-actions')">
      <button
        :class="b('row-actions-dots', { active: isActive })"
        @click="isActive = !isActive"
      >
        <span :class="b('row-action-icon')" v-html="displayedIcons.dots"></span>
      </button>

      <div :class="b('row-actions-list', { visible: isActive })">
        <button
          v-if="row.type === 'fiat'"
          :class="b('row-action')"
          @click="handleWithdrawal"
        >
          <span
            :class="b('row-action-icon')"
            v-html="displayedIcons.withdrawalGray"
          ></span>
        </button>

        <button
          v-if="row.type === 'fiat'"
          :class="b('row-action')"
          @click="handleDeposit"
        >
          <span
            :class="b('row-action-icon')"
            v-html="displayedIcons.depositGray"
          ></span>
        </button>

        <button :class="b('row-action')" @click="handleApplication">
          <span
            :class="b('row-action-icon')"
            v-html="displayedIcons.swapGray"
          ></span>
        </button>

        <button :class="b('row-action')" @click="handleHistory">
          <span
            :class="b('row-action-icon')"
            v-html="displayedIcons.historyGray"
          ></span>
        </button>
      </div>
    </div>
  </div>
</template>
