import { ParserDocuments, ParserField } from "./constants";
import { ParserDocument, ParserFieldKey, ParserFieldName } from "./enums";
import { ParserParseRequest, ParserRequest } from "./repo";

import { getDate } from "@/shared/utils/moment-helpers";
import { MomentFormat } from "@/shared/constants/enums";

export const ParserParseRequestFactory = (
  data: ParserRequest,
  fileName: ParserDocument,
  prefix: string = ""
): ParserParseRequest => {
  return {
    file: ParserDocuments[fileName],
    fileName,
    fields: (
      ParserField[
        fileName as unknown as keyof typeof ParserField
      ] as unknown as string[]
    ).map((field: string) => {
      return {
        fieldName: field,
        fieldText: ParserParseFieldText(field, data, prefix),
      };
    }),
  };
};

export const ParserParseFieldText = (
  field: string,
  data: ParserRequest,
  prefix: string
): string => {
  let fieldText = "";

  switch (field) {
    case ParserFieldName["_АдресФизЛицо_"]:
      if (data.address) {
        const { country, city, state, street, office, zip } = data.address;

        fieldText = `${country}, ${city}, ${state}, ${street}, ${office}, ${zip}`;
      }

      break;
    case ParserFieldName["_ТекущаяДАТА_"]:
      fieldText = getDate(new Date(), MomentFormat.dateReverse);

      break;
    case ParserFieldName["_НомерДоговор_"]:
      fieldText = `${prefix}-${data.id!}`;

      break;
    default:
      fieldText = data[
        ParserFieldKey[field as keyof typeof ParserFieldName]
      ] as string;

      break;
  }

  return fieldText;
};
