<script lang="ts" src="./component.ts"></script>
<style lang="scss" src="./component.scss"></style>

<template>
  <div :class="b()">
    <AdminConfigRequisitesForm v-if="isRequisiteShow" />

    <AdminConfigRequisitesTable v-else />
  </div>
</template>
