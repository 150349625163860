<script lang="ts" src="./component.ts"></script>
<style lang="scss" src="./component.scss"></style>

<template>
  <SharedCard :class="b()">
    <div :class="b('header')">
      <div :class="b('header-group')">
        <SharedBack @click="handleBack" />

        <h3 :class="b('header-title')">
          {{ $t("Участник") }}
        </h3>
      </div>

      <div :class="b('header-checkboxes')">
        <div :class="b('header-checkbox')">
          <SharedCheckbox
            view="22"
            :is-readonly="isReadonly"
            v-model:checked="kybParticipant.is_beneficiary"
          />

          <p :class="b('header-checkbox-label')">
            {{ $t("Является Бенефициаром") }}
          </p>
        </div>

        <div :class="b('header-checkbox')">
          <SharedCheckbox
            view="22"
            :is-readonly="isReadonly"
            v-model:checked="kybParticipant.is_director"
          />

          <p :class="b('header-checkbox-label')">
            {{ $t("Является директором") }}
          </p>
        </div>
      </div>
    </div>

    <div :class="b('form')">
      <div :class="b('form-fields')">
        <SharedValidate
          field="sur_name"
          :value="kybParticipant.sur_name"
          :is-validate="isValidate"
          v-model:field-value="kybParticipantFields.sur_name"
        >
          <div :class="b('form-group')">
            <h5 :class="b('form-title')">
              {{ $t("Фамилия*") }}
            </h5>

            <UIInput
              view="light"
              :is-readonly="isReadonly"
              :is-error="isValidate && !kybParticipantFields.sur_name"
              v-model="kybParticipant.sur_name"
            />
          </div>
        </SharedValidate>

        <SharedValidate
          field="name"
          :value="kybParticipant.name"
          :is-validate="isValidate"
          v-model:field-value="kybParticipantFields.name"
        >
          <div :class="b('form-group')">
            <h5 :class="b('form-title')">
              {{ $t("Имя*") }}
            </h5>

            <UIInput
              view="light"
              :is-readonly="isReadonly"
              :is-error="isValidate && !kybParticipantFields.name"
              v-model="kybParticipant.name"
            />
          </div>
        </SharedValidate>

        <div :class="b('form-group')">
          <h5 :class="b('form-title')">
            {{ $t("Отчество") }}
          </h5>

          <UIInput
            view="light"
            :is-readonly="isReadonly"
            v-model="kybParticipant.patronymic"
          />
        </div>

        <SharedValidate
          field="citizenship"
          :value="kybParticipant.citizenship"
          :is-validate="isValidate"
          v-model:field-value="kybParticipantFields.citizenship"
        >
          <div :class="b('form-group')">
            <h5 :class="b('form-title')">
              {{ $t("Гражданство*") }}
            </h5>

            <SharedCountry
              placeholder="Выберите страну"
              :is-readonly="isReadonly"
              :is-error="isValidate && !kybParticipantFields.citizenship"
              v-model:value="kybParticipant.citizenship"
            />
          </div>
        </SharedValidate>

        <SharedValidate
          field="birth_date"
          :value="kybParticipant.birth_date"
          :is-validate="isValidate"
          v-model:field-value="kybParticipantFields.birth_date"
        >
          <div :class="b('form-group')">
            <h5 :class="b('form-title')">
              {{ $t("Дата рождения*") }}
            </h5>

            <SharedDate
              format="yyyy-MM-dd"
              :is-readonly="isReadonly"
              :is-error="isValidate && !kybParticipantFields.birth_date"
              v-model:date="kybParticipant.birth_date"
            />
          </div>
        </SharedValidate>

        <SharedValidate
          field="gender"
          :value="kybParticipant.gender"
          :is-validate="isValidate"
          v-model:field-value="kybParticipantFields.gender"
        >
          <div :class="b('form-group')">
            <h5 :class="b('form-title')">
              {{ $t("Укажите пол*") }}
            </h5>

            <div :class="b('form-radios')">
              <div :class="b('form-radio')">
                <SharedRadio
                  view="light"
                  checkedValue="male"
                  :is-readonly="isReadonly"
                  :is-error="isValidate && !kybParticipantFields.gender"
                  v-model:value="kybParticipant.gender"
                />

                <p :class="b('form-radio-label')">
                  {{ $t("Мужчина") }}
                </p>
              </div>

              <div :class="b('form-radio')">
                <SharedRadio
                  view="light"
                  checkedValue="female"
                  :is-readonly="isReadonly"
                  :is-error="isValidate && !kybParticipantFields.gender"
                  v-model:value="kybParticipant.gender"
                />

                <p :class="b('form-radio-label')">
                  {{ $t("Женщина") }}
                </p>
              </div>
            </div>
          </div>
        </SharedValidate>
      </div>

      <div :class="b('form-address')">
        <h5 :class="b('form-title')">
          {{ $t("Адрес проживания*") }}
        </h5>

        <div :class="b('form-address-fields')">
          <SharedValidate
            field="address.country"
            :value="kybParticipant.address.country"
            :is-validate="isValidate"
            v-model:field-value="kybParticipantFields['address.country']"
          >
            <SharedCountry
              :class="b('form-address-country')"
              placeholder="Cтрана"
              :is-readonly="isReadonly"
              :is-error="isValidate && !kybParticipantFields['address.country']"
              v-model:value="kybParticipant.address.country"
            />
          </SharedValidate>

          <SharedValidate
            field="address.city"
            :value="kybParticipant.address.city"
            :is-validate="isValidate"
            v-model:field-value="kybParticipantFields['address.city']"
          >
            <UIInput
              :class="b('form-address-city')"
              placeholder="Город"
              view="light"
              :is-readonly="isReadonly"
              :is-error="isValidate && !kybParticipantFields['address.city']"
              v-model="kybParticipant.address.city"
            />
          </SharedValidate>

          <SharedValidate
            field="address.state"
            :value="kybParticipant.address.state"
            :is-validate="isValidate"
            v-model:field-value="kybParticipantFields['address.state']"
          >
            <UIInput
              :class="b('form-address-state')"
              placeholder="Штат / Область"
              view="light"
              :is-readonly="isReadonly"
              :is-error="isValidate && !kybParticipantFields['address.state']"
              v-model="kybParticipant.address.state"
            />
          </SharedValidate>

          <SharedValidate
            field="address.street"
            :value="kybParticipant.address.street"
            :is-validate="isValidate"
            v-model:field-value="kybParticipantFields['address.street']"
          >
            <UIInput
              :class="b('form-address-street')"
              placeholder="Улица"
              view="light"
              :is-readonly="isReadonly"
              :is-error="isValidate && !kybParticipantFields['address.street']"
              v-model="kybParticipant.address.street"
            />
          </SharedValidate>

          <SharedValidate
            field="address.office"
            :value="kybParticipant.address.office"
            :is-validate="isValidate"
            v-model:field-value="kybParticipantFields['address.office']"
          >
            <UIInput
              :class="b('form-address-office')"
              placeholder="Номер Офиса"
              view="light"
              :is-readonly="isReadonly"
              :is-error="isValidate && !kybParticipantFields['address.office']"
              v-model="kybParticipant.address.office"
            />
          </SharedValidate>

          <SharedValidate
            field="address.zip"
            :value="kybParticipant.address.zip"
            :is-validate="isValidate"
            v-model:field-value="kybParticipantFields['address.zip']"
          >
            <UIInput
              :class="b('form-address-index')"
              placeholder="Индекс"
              view="light"
              :is-readonly="isReadonly"
              :is-error="isValidate && !kybParticipantFields['address.zip']"
              v-model="kybParticipant.address.zip"
            />
          </SharedValidate>
        </div>
      </div>

      <div :class="b('form-fields')">
        <div :class="b('form-group')">
          <h5 :class="b('form-title')">
            {{ $t("Какой долей он владеет в обществе?") }}
          </h5>

          <SharedInputDouble
            view="light"
            :is-readonly="isReadonly"
            v-model="kybParticipant.share_in_org"
          />
        </div>
      </div>
    </div>

    <UIButton
      :class="b('continue')"
      label="Продолжить"
      view="main"
      :disabled="isContinueDisabled"
      @click="handleContinue"
    />
  </SharedCard>
</template>
