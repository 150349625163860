<script lang="ts" src="./component.ts"></script>
<style lang="scss" src="./component.scss"></style>

<template>
  <WalletCard :class="b()" title="Пополнение/Вывод" tab="deposit-withdrawal">
    <div :class="b('table')">
      <div :class="b('columns')">
        <div
          :class="b('column')"
          v-for="column in displayedColumns"
          :key="column.id"
        >
          <p :class="b('column-label')">
            {{ $t(column.label) }}
          </p>
        </div>
      </div>

      <div :class="b('rows')">
        <div :class="b('row')" v-for="row in displayedRows" :key="row.id">
          <div :class="b('row-date-time')">
            <p :class="b('row-label')">
              {{ row.created_at }}
            </p>
          </div>

          <div :class="b('row-type')">
            <span
              :class="b('row-icon')"
              v-html="displayedIcons[row.app_type]"
            ></span>

            <p :class="b('row-label', { type: true })">
              {{ row.app_type === "deposit" ? "Ввод" : "Вывод" }}
            </p>

            <p :class="b('row-label', { amount: true })">
              {{ row.amount }}
            </p>
          </div>

          <div :class="b('row-amount')">
            <p :class="b('row-label')">
              {{ row.amount }}
            </p>
          </div>

          <div :class="b('row-currency')">
            <div :class="b('row-currency-group')">
              <span
                v-if="row.currency.country && row.currency_type === 'fiat'"
                :class="[b('row-icon'), 'fi', `fi-${row.currency.country}`]"
              ></span>

              <img
                v-else-if="row.currency_type === 'crypto' && row.currency.name"
                :class="b('row-icon', { crypto: true })"
                :src="
                  require(`@/assets/images/crypto/${row.currency.name.toLowerCase()}.svg`)
                "
                alt=""
              />

              <p :class="b('row-label', { currency: true })">
                {{ row.currency.name }}
              </p>
            </div>

            <span
              :class="b('row-icon')"
              v-html="displayedIcons[row.status]"
            ></span>
          </div>
        </div>
      </div>
    </div>
  </WalletCard>
</template>
