<script lang="ts" src="./component.ts"></script>
<style lang="scss" src="./component.scss"></style>

<template>
  <div :class="b()">
    <div :class="b('tabs')">
      <button
        :class="b('tab', { active: tab.acitve })"
        v-for="tab in displayedTabs"
        :key="tab.id"
        @click="handleTab(tab)"
      >
        {{ $t(tab.label) }}
      </button>
    </div>

    <div :class="b('block')">
      <SharedSearch
        :search="displayedSearch.value"
        @update:search="handleSearch"
      />

      <div :class="b('group')">
        <div :class="b('filter')">
          <button
            :class="b('button', { filter: true, active: isFilterActive })"
            @click="handleFilter"
          >
            <span
              :class="b('button-icon')"
              v-html="displayedIcons.filter"
            ></span>
          </button>

          <button
            v-if="hasFilter"
            :class="b('button', { 'filter-clear': true })"
            @click="handleClearFilter"
          >
            <span
              :class="b('button-icon')"
              v-html="displayedIcons.clear"
            ></span>

            <span :class="b('button-label', { 'filter-clear': true })">
              {{ $t("Очистить") }}
              <span :class="b('button-label-sub')">
                {{ $t("Очистить фильтр") }}
              </span>
            </span>
          </button>
        </div>
      </div>
    </div>
  </div>
</template>
