import { SearchType } from "@/shared/constants/enums";

export interface AdminUsersFilterOption {
  id: number;
  label: string;
  value: SearchType;
  isActive: boolean;
}

export const AdminUsersFilterOptions = (
  type: SearchType
): AdminUsersFilterOption[] => {
  return [
    {
      id: 0,
      label: "Все",
      value: SearchType.all,
    },
    {
      id: 1,
      label: "ID",
      value: SearchType.id,
    },
    {
      id: 2,
      label: "Почта",
      value: SearchType.email,
    },
    {
      id: 3,
      label: "ФИО",
      value: SearchType.fio,
    },
  ].map((item) => ({ ...item, isActive: item.value === type }));
};
