import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_AdminConfigRequisitesForm = _resolveComponent("AdminConfigRequisitesForm")!
  const _component_AdminConfigRequisitesTable = _resolveComponent("AdminConfigRequisitesTable")!

  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(_ctx.b())
  }, [
    (_ctx.isRequisiteShow)
      ? (_openBlock(), _createBlock(_component_AdminConfigRequisitesForm, { key: 0 }))
      : (_openBlock(), _createBlock(_component_AdminConfigRequisitesTable, { key: 1 }))
  ], 2))
}