import { toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, resolveDirective as _resolveDirective, openBlock as _openBlock, createElementBlock as _createElementBlock, withDirectives as _withDirectives } from "vue"

const _hoisted_1 = ["innerHTML"]
const _hoisted_2 = ["disabled"]
const _hoisted_3 = ["disabled"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _directive_click_outside = _resolveDirective("click-outside")!

  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(_ctx.b())
  }, [
    _createElementVNode("p", {
      class: _normalizeClass(_ctx.b('label'))
    }, _toDisplayString(_ctx.requisite.currency.name.toUpperCase()), 3),
    _withDirectives((_openBlock(), _createElementBlock("div", {
      class: _normalizeClass(_ctx.b('action'))
    }, [
      _createElementVNode("button", {
        class: _normalizeClass(_ctx.b('toggle', { active: _ctx.isVisible })),
        onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.handleToggle && _ctx.handleToggle(...args)))
      }, [
        _createElementVNode("span", {
          class: _normalizeClass(_ctx.b('toggle-icon')),
          innerHTML: _ctx.displayedIcons.dots
        }, null, 10, _hoisted_1)
      ], 2),
      _createElementVNode("div", {
        class: _normalizeClass(_ctx.b('options', { active: _ctx.isVisible }))
      }, [
        _createElementVNode("button", {
          class: _normalizeClass(_ctx.b('option')),
          disabled: _ctx.isLoading,
          onClick: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_ctx.handleEdit && _ctx.handleEdit(...args)))
        }, _toDisplayString(_ctx.$t("Редактировать")), 11, _hoisted_2),
        _createElementVNode("button", {
          class: _normalizeClass(_ctx.b('option')),
          disabled: _ctx.isLoading,
          onClick: _cache[2] || (_cache[2] = 
//@ts-ignore
(...args) => (_ctx.handleRemove && _ctx.handleRemove(...args)))
        }, _toDisplayString(_ctx.$t("Удалить")), 11, _hoisted_3)
      ], 2)
    ], 2)), [
      [_directive_click_outside, _ctx.handleHide]
    ])
  ], 2))
}