<script lang="ts" src="./component.ts"></script>
<style lang="scss" src="./component.scss"></style>

<template>
  <SharedWindow :class="b()" title="Детали операции" @close="handleClose">
    <div :class="b('wrapper')">
      <div :class="b('list')">
        <div :class="b('item')">
          <h4 :class="b('item-title')">
            {{ $t("Дата и время") }}
          </h4>

          <p :class="b('item-label')">{{ displayedList.dateTime.label }}</p>
        </div>

        <div :class="b('item')">
          <h4 :class="b('item-title')">
            {{ $t("Тип") }}
          </h4>

          <p :class="b('item-label')">{{ displayedList.type.label }}</p>
        </div>

        <div :class="b('item')">
          <h4 :class="b('item-title')">
            {{ $t("Сумма и валюта") }}
          </h4>

          <p :class="b('item-label')">
            {{ displayedList.amountCurrency.label }}
          </p>
        </div>

        <div :class="b('item')">
          <h4 :class="b('item-title')">
            {{ $t("Комиссия") }}
          </h4>

          <p :class="b('item-label')">{{ displayedList.exchange.label }}</p>
        </div>

        <div :class="b('item')">
          <h4 :class="b('item-title')">
            {{ $t("Статус") }}
          </h4>

          <p :class="b('item-label')">{{ displayedList.status.label }}</p>
        </div>

        <div v-if="displayedList.address" :class="b('item', { address: true })">
          <h4 :class="b('item-title')">
            {{ $t("Адрес перевода") }}
          </h4>

          <p :class="b('item-label')" v-if="!displayedList.address.info">
            {{ displayedList.address.label }}
          </p>

          <div
            :class="b('list', { info: true })"
            v-if="displayedList.address.info"
          >
            <div
              :class="b('item', { info: true })"
              v-for="item in displayedList.address.info"
              :key="item?.title"
            >
              <h4 :class="b('item-title')">
                {{ $t(item?.title ?? "") }}
              </h4>

              <p :class="b('item-label')">
                {{ item?.label }}
              </p>
            </div>
          </div>
        </div>
      </div>

      <UIButton
        label="Закрыть"
        view="main"
        :disabled="isDisabled"
        @click="handleClose"
      />
    </div>
  </SharedWindow>
</template>
