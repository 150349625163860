import { defineComponent } from "vue";

import { COMPONENT_NAME } from "./attributes";

import { SharedSelect, SharedInputDouble } from "@/components/shared";
import { UIInput, UIButton } from "@/components/ui";

import { AdminConfigCommissionFind } from "./find";
import { AdminConfigCommissionExchange } from "./exchange";
import { AdminConfigCommissionDepositWithdrawal } from "./deposit-withdrawal";
import { AdminCommissionExchangeFindFactory } from "@/shared/repository/modules/admin/commission/factory";

export default defineComponent({
  name: COMPONENT_NAME,
  components: {
    SharedSelect,
    SharedInputDouble,
    UIInput,
    UIButton,
    AdminConfigCommissionFind,
    AdminConfigCommissionExchange,
    AdminConfigCommissionDepositWithdrawal,
  },
  data() {
    return {
      commissionExchangeFind: AdminCommissionExchangeFindFactory(),
    };
  },
});
