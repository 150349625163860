<script lang="ts" src="./component.ts"></script>
<style lang="scss" src="./component.scss"></style>

<template>
  <div :class="b()">
    <div :class="b('row-date-time')">
      <p :class="b('row-label', { dateTime: true })">
        {{ row.created_at }}
      </p>
    </div>

    <div :class="b('row-type')">
      <p :class="b('row-label')">
        {{ row.app_type === "deposit" ? "Пополнение" : "Вывод" }}
      </p>
    </div>

    <div :class="b('row-amount')">
      <p
        :class="
          b('row-label', {
            deposit: row.app_type === 'deposit',
            withdrawal: row.app_type === 'withdrawal',
          })
        "
      >
        {{ row.amount }}
      </p>

      <p :class="b('row-label')">
        {{ row.currency.name.toUpperCase() }}
      </p>
    </div>

    <div :class="b('row-commission')">
      <p :class="b('row-label')">цена исполнения</p>
    </div>

    <div :class="b('row-volume')">
      <p :class="b('row-label')">0.0</p>

      <p :class="b('row-label')">
        {{ row.currency.name.toUpperCase() }}
      </p>
    </div>

    <div :class="b('row-commission')">
      <p :class="b('row-label')">
        {{ row.commissions }}
      </p>
    </div>

    <div :class="b('row-status')">
      <div :class="b('row-group')">
        <span :class="b('row-icon')" v-html="displayedSvg[row.status]" />
      </div>
    </div>

    <div :class="b('row-commission')">
      <p :class="b('row-label')">Этап</p>
    </div>

    <div :class="b('row-block')">
      <div :class="b('row-block-group')">
        <div :class="b('row-block-type')">
          <p :class="b('row-label')">
            {{ row.app_type === "deposit" ? "Пополнение" : "Вывод" }}
          </p>

          <span
            :class="
              b('row-label', {
                deposit: row.app_type === 'deposit',
                withdrawal: row.app_type === 'withdrawal',
                block: true,
              })
            "
          >
            {{ row.amount }}

            <p :class="b('row-label', { currency: true })">
              {{ row.currency.name }}
            </p>
          </span>
        </div>

        <div :class="b('row-block-date')">
          <p :class="b('row-label', { dateTime: true })">
            {{ row.created_at }}
          </p>

          <span :class="b('row-icon')" v-html="displayedSvg[row.status]" />
        </div>
      </div>

      <div :class="b('row-block-group')">
        <div :class="b('row-block-commission')">
          <p :class="b('row-label')">Цена</p>

          <p :class="b('row-label')">
            <!-- {{ row.commissions }} -->
          </p>
        </div>

        <div :class="b('row-block-other')">
          <div :class="b('row-block-number')">
            <p :class="b('row-label')">№ {{ $t("Transaction ") }}</p>

            <p :class="b('row-label')">24355</p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
