import { AdminAppicationStatus } from "./enums";

export enum AdminApplicationApiRoutes {
  update = "/api/v1/admin/exchange/app/update",
  delete = "/api/v1/admin/exchange/app/delete",
  show = "/api/v1/admin/exchange/app/show",
  list = "/api/v1/admin/exchange/app/list",
}

export interface AdminApplicationProjectUrlGenerator {
  update(id: number, status: AdminAppicationStatus): string;
  delete(id: number): string;
  show(id: number): string;
  list(): string;
}

export class AdminApplicationUrlGenerator
  implements AdminApplicationProjectUrlGenerator
{
  update(id: number, status: AdminAppicationStatus): string {
    return `${AdminApplicationApiRoutes.update}?id=${id}&status=${status}`;
  }

  delete(id: number): string {
    return `${AdminApplicationApiRoutes.delete}?id=${id}`;
  }

  show(id: number): string {
    return `${AdminApplicationApiRoutes.show}?id=${id}`;
  }

  list(): string {
    return AdminApplicationApiRoutes.list;
  }
}
