<script lang="ts" src="./component.ts"></script>
<style lang="scss" src="./component.scss"></style>

<template>
  <div :class="b()">
    <div :class="b('columns')">
      <div
        :class="b('column')"
        v-for="column in displayedColumns"
        :key="column.id"
      >
        <p :class="b('column-label')">
          {{ $t(column.label) }}
        </p>

        <button :class="b('column-sort')" @click="handleSort(column)">
          <span :class="b('column-icon')" v-html="displayedSvg.sort"></span>
        </button>
      </div>
    </div>

    <div :class="b('rows')">
      <WalletMainTableRow
        :class="b('row')"
        v-for="item in displayedList"
        :key="item.id"
        :row="item"
      />
    </div>
  </div>
</template>
