export interface AdminUsersTableHeader {
  title: string;
  key: string;
}

export const AdminUsersTableHeaders: AdminUsersTableHeader[] = [
  {
    title: "ID",
    key: "id",
  },
  {
    title: "почта",
    key: "email",
  },
  {
    title: "статус KYC",
    key: "kyc_status",
  },
  {
    title: "ФИО",
    key: "fio",
  },
  {
    title: "Баланс",
    key: "balance",
  },
  {
    title: "Кол-во акт. заявок",
    key: "application",
  },
];

export const AdminUsersTableItemsOptions = [];
