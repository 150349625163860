import { defineComponent } from "vue";

import { PAGE_NAME } from "./attributes";

import {
  WalletMain,
  WalletHistory,
  WalletApplications,
  WalletDeposit,
  WalletWithdrawal,
  WalletRequisitesInfo,
  WalletVerification,
} from "@/components/pages/wallet";
import { AdminApplicationType } from "@/shared/repository/modules/admin/application/enums";
import { ApplicationFilterRequestFactory } from "@/shared/repository/modules/application/factory";

export type ModalComponent =
  | typeof WalletDeposit
  | typeof WalletWithdrawal
  | typeof WalletRequisitesInfo
  | typeof WalletVerification
  | "";

export default defineComponent({
  name: PAGE_NAME,
  components: {
    WalletMain,
    WalletHistory,
    WalletApplications,
    WalletDeposit,
    WalletWithdrawal,
    WalletRequisitesInfo,
    WalletVerification,
  },
  data() {
    return {
      walletRepo: this.$projectServices.walletRepo,
      depositWithdrawalRepo: this.$projectServices.depositWithdrawalRepo,
    };
  },
  async created(): Promise<void> {
    this.depositWithdrawalRepo.updateHistory(ApplicationFilterRequestFactory());
  },
  beforeUnmount(): void {
    this.depositWithdrawalRepo.UPDATE_DEPOSIT({
      id: 0,
      currency_id: 0,
      amount: 0,
      currencyName: "",
    });
  },
  computed: {
    displayedModal(): ModalComponent {
      switch (this.walletRepo.modal.type) {
        case AdminApplicationType.deposit:
          return WalletDeposit;
        case AdminApplicationType.withdrawal:
          return WalletWithdrawal;
        case AdminApplicationType.depositRequisites:
          return WalletRequisitesInfo;
        case AdminApplicationType.verificationDeposit:
        case AdminApplicationType.verificationWithdrawal:
          return WalletVerification;
        default:
          return "";
      }
    },

    isModalShow(): boolean {
      return !!this.walletRepo.modal.type;
    },
  },
});
