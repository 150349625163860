<script lang="ts" src="./component.ts"></script>
<style lang="scss" src="./component.scss"></style>

<template>
  <button
    :class="b('', { view, active: checked })"
    :disabled="isReadonly"
    @click="changeChecked"
  >
    <span v-show="checked" :class="b('icon')" v-html="displayedIcon"></span>
  </button>
</template>
