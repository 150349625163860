import { normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString } from "vue"

const _hoisted_1 = ["placeholder", "value"]
const _hoisted_2 = ["src"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(_ctx.b())
  }, [
    _createElementVNode("input", {
      class: _normalizeClass(_ctx.b('field')),
      type: "number",
      placeholder: _ctx.placeholder,
      value: _ctx.value,
      onInput: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.handleInput && _ctx.handleInput(...args)))
    }, null, 42, _hoisted_1),
    _createElementVNode("div", {
      class: _normalizeClass(_ctx.b('currency'))
    }, [
      (_ctx.currency.country && _ctx.currency.type === 'fiat')
        ? (_openBlock(), _createElementBlock("span", {
            key: 0,
            class: _normalizeClass([_ctx.b('icon'), 'fi', `fi-${_ctx.currency.country}`])
          }, null, 2))
        : (_ctx.currency.type === 'crypto' && _ctx.currency.name)
          ? (_openBlock(), _createElementBlock("img", {
              key: 1,
              class: _normalizeClass(_ctx.b('icon', { crypto: true })),
              src: 
          require(`@/assets/images/crypto/${_ctx.currency.name.toLowerCase()}.svg`)
        ,
              alt: ""
            }, null, 10, _hoisted_2))
          : _createCommentVNode("", true),
      _createElementVNode("span", {
        class: _normalizeClass(_ctx.b('currency-label'))
      }, _toDisplayString(_ctx.currency.name.toUpperCase()), 3)
    ], 2)
  ], 2))
}