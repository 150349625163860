import { defineComponent, PropType } from "vue";

import { COMPONENt_NAME } from "./attributes";

import { ApplicationType } from "@/shared/constants/enums";
import { VerificationTab, VerificationTabs } from "@/data/verification/tabs";

export default defineComponent({
  name: COMPONENt_NAME,
  props: {
    tab: {
      type: <PropType<ApplicationType>>String,
      default: ApplicationType.kyc,
    },
  },
  emits: {
    tab: null,
  },
  data() {
    return {
      userRepo: this.$projectServices.userRepo,
    };
  },
  computed: {
    displayedTabs(): VerificationTab[] {
      return VerificationTabs(
        this.tab,
        this.userRepo.userInfo.user_info.application_type
      );
    },
  },
  methods: {
    handleTab(tab: VerificationTab): void {
      this.$emit("tab", tab.value);
    },
  },
});
