<script lang="ts" src="./component.ts"></script>
<style lang="scss" src="./component.scss"></style>

<template>
  <button
    :class="
      b('', {
        success: isSuccess,
        image: !!displayedBackgroundImage,
        center: isCenter,
        padding: isPadding,
        view,
        error: isError,
      })
    "
    :style="{ backgroundImage: displayedBackgroundImage }"
    :disabled="isDisabled"
    @click="handleFile"
  >
    <span v-if="isIconShow" :class="b('icon')" v-html="displayedIcon"></span>

    <p v-if="isLabelShow" :class="b('label', { success: isSuccess })">
      <slot :class="b('label')" name="label"></slot>
    </p>

    <span
      :class="b('preloader', { success: isSuccess })"
      v-if="isPreloaderShow"
      v-html="displayedPreloader"
    ></span>

    <button
      v-if="isRemoveShow"
      :class="b('remove')"
      :disabled="isRemoveDisabled"
      @click.stop="handleRemove"
    >
      <span :class="b('icon')" v-html="displayedRemoveIcon"></span>
    </button>

    <input
      :class="b('file')"
      ref="file"
      type="file"
      accept=".png, .jpeg, .jpg, .pdf, .doc, .docx"
      @change="handleUpload"
    />
  </button>
</template>
