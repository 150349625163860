import { WalletFilterType } from "./enums";

export enum WalletApiRoutes {
  list = "/api/v1/wallet/list",
  currency = "/api/v1/wallet/currency/list",
  withAmount = "/api/v1/wallet/list/with-amount",
}

export interface WalletProjectUrlGenerator {
  list(userId: number, type: WalletFilterType): string;
  currency(): string;
  withAmount(userId: number, type: WalletFilterType): string;
}

export class WalletUrlGenerator implements WalletProjectUrlGenerator {
  list(userId: number, type: WalletFilterType): string {
    if (type) {
      return `${WalletApiRoutes.list}?user_id=${userId}&filter[type]=${type}`;
    }

    return `${WalletApiRoutes.list}?user_id=${userId}`;
  }

  currency(): string {
    return WalletApiRoutes.currency;
  }

  withAmount(userId: number, type: WalletFilterType): string {
    if (type) {
      return `${WalletApiRoutes.withAmount}?user_id=${userId}&filter[type]=${type}`;
    }

    return `${WalletApiRoutes.withAmount}?user_id=${userId}`;
  }
}
