import { SvgAttribute } from "@/shared/constants/constants";

export const COMPONENT_NAME = "shared-crypto-select";

export const SVG: SvgAttribute = {
  chevron: `<svg width="13" height="14" viewBox="0 0 13 14" xmlns="http://www.w3.org/2000/svg">
    <path d="M10.8794 4.2929C11.2699 3.90238 11.903 3.90238 12.2936 4.2929C12.6841 4.68343 12.6841 5.31659 12.2936 5.70712L7.34383 10.6569C6.9533 11.0474 6.32014 11.0474 5.92961 10.6569C5.53909 10.2663 5.53909 9.63317 5.92961 9.24265L10.8794 4.2929Z"/>
    <path d="M7.34383 9.24265C7.73435 9.63317 7.73435 10.2663 7.34383 10.6569C6.9533 11.0474 6.32014 11.0474 5.92961 10.6569L0.979865 5.70712C0.589341 5.31659 0.589341 4.68343 0.979865 4.2929C1.37039 3.90238 2.00355 3.90238 2.39408 4.2929L7.34383 9.24265Z"/>
  </svg>`,
};
