import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createBlock as _createBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = ["onClick"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_UIButton = _resolveComponent("UIButton")!

  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(_ctx.b())
  }, [
    _createElementVNode("div", {
      class: _normalizeClass(_ctx.b('group', { tabs: true }))
    }, [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.displayedTabs, (tab) => {
        return (_openBlock(), _createElementBlock("button", {
          class: _normalizeClass(_ctx.b('tab', { active: tab.isActive })),
          key: tab.id,
          onClick: ($event: any) => (_ctx.handleTab(tab))
        }, _toDisplayString(_ctx.$t(tab.label)), 11, _hoisted_1))
      }), 128))
    ], 2),
    _createElementVNode("div", {
      class: _normalizeClass(_ctx.b('group'))
    }, [
      (_ctx.isRemoveShow)
        ? (_openBlock(), _createBlock(_component_UIButton, {
            key: 0,
            label: "Удалить",
            view: "main-light-outline",
            onClick: _ctx.handleRemove
          }, null, 8, ["onClick"]))
        : _createCommentVNode("", true),
      (_ctx.isAddShow)
        ? (_openBlock(), _createBlock(_component_UIButton, {
            key: 1,
            label: "Добавить",
            view: "main-light",
            onClick: _ctx.handleAdd
          }, null, 8, ["onClick"]))
        : _createCommentVNode("", true)
    ], 2)
  ], 2))
}