<script lang="ts" src="./component.ts"></script>
<style lang="scss" src="./component.scss"></style>

<template>
  <div :class="b()">
    <div :class="b('fields')">
      <div :class="b('field')">
        <p :class="b('field-label')">Выберите страну</p>

        <SharedSelect
          view="light"
          :options="displayedCountryOptions"
          :icon="adminCurrency.country"
          :value="adminCurrency.country"
          :is-search="true"
          @update:value="handleCountry"
        />
      </div>

      <div v-if="isCurrencyShow" :class="b('field')">
        <p :class="b('field-label')">Валюта</p>

        <SharedSelect
          view="light"
          :options="displayedCurrencyOptions"
          :icon="adminCurrency.name ? adminCurrency.country : ''"
          :is-search="true"
          v-model:value="adminCurrency.name"
        />
      </div>
    </div>

    <UIButton
      :label="displayedButtonLabel"
      view="main"
      :disabled="isButtonDisabled"
      @click="handleButton"
    />
  </div>
</template>
