<script lang="ts" src="./component.ts"></script>
<style lang="scss" src="./component.scss"></style>

<template>
  <SharedWindow :class="b()" @close="handleCancel">
    <div :class="b('content')">
      <h2 :class="b('title')">
        {{ $t(displayedTitle) }}
      </h2>

      <div :class="b('buttons')">
        <UIButton label="Отменить" view="main-outline" @click="handleCancel" />

        <UIButton label="Продолжить" view="main" @click="handleContinue" />
      </div>
    </div>
  </SharedWindow>
</template>
