import { normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, resolveDirective as _resolveDirective, withDirectives as _withDirectives } from "vue"

const _hoisted_1 = ["innerHTML"]
const _hoisted_2 = ["value"]
const _hoisted_3 = ["innerHTML"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _directive_click_outside = _resolveDirective("click-outside")!

  return _withDirectives((_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(_ctx.b('', { active: _ctx.isSearch }))
  }, [
    _createElementVNode("button", {
      class: _normalizeClass(_ctx.b('action', { active: _ctx.isSearch })),
      onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.handleSearch && _ctx.handleSearch(...args)))
    }, [
      _createElementVNode("span", {
        class: _normalizeClass(_ctx.b('icon')),
        innerHTML: _ctx.displayedIcons.search
      }, null, 10, _hoisted_1)
    ], 2),
    _createElementVNode("input", {
      class: _normalizeClass(_ctx.b('field')),
      type: "text",
      value: _ctx.search,
      onInput: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_ctx.handleChangeSearch && _ctx.handleChangeSearch(...args)))
    }, null, 42, _hoisted_2),
    (_ctx.isClearShow)
      ? (_openBlock(), _createElementBlock("span", {
          key: 0,
          class: _normalizeClass(_ctx.b('clear')),
          innerHTML: _ctx.displayedIcons.clear,
          onClick: _cache[2] || (_cache[2] = 
//@ts-ignore
(...args) => (_ctx.handleClear && _ctx.handleClear(...args)))
        }, null, 10, _hoisted_3))
      : _createCommentVNode("", true)
  ], 2)), [
    [_directive_click_outside, _ctx.handleHide]
  ])
}