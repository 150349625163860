import { SvgAttribute } from "@/shared/constants/constants";
import { defineComponent, PropType } from "vue";

import { COMPONENT_NAME, SVG } from "./attributes";
import { SharedSelectOption } from "./model";

import { UIInput } from "@/components/ui";

export default defineComponent({
  name: COMPONENT_NAME,
  components: {
    UIInput,
  },
  props: {
    placeholder: {
      type: String,
      default: "",
    },
    view: {
      type: String,
      default: "",
    },
    options: {
      type: <PropType<SharedSelectOption[]>>Array,
      default: () => [],
    },
    value: {
      type: [String, Number],
      default: "",
    },
    icon: {
      type: String,
      default: "",
    },
    isReadonly: {
      type: Boolean,
      default: false,
    },
    isCryptoIcon: {
      type: Boolean,
      default: false,
    },
    isSearch: {
      type: Boolean,
      default: false,
    },
  },
  emits: {
    "update:value": null,
  },
  data() {
    return {
      isVisible: false,
      search: "",
    };
  },
  computed: {
    displaydSvg(): SvgAttribute {
      return SVG;
    },

    displayedIcon(): string {
      return SVG.chevron;
    },

    displayedCryptoIcon(): string {
      return this.isCryptoIcon
        ? require(`@/assets/images/crypto/${this.icon.toLowerCase()}.svg`)
        : "";
    },

    displayedPlaceholder(): string | undefined {
      return this.value
        ? this.options.find((option) => option.value === this.value)?.label
        : this.$t(this.placeholder);
    },

    displayedOptions(): SharedSelectOption[] {
      return this.search.length
        ? this.options.filter((option) =>
            `${option.label} ${option.value}`
              .toLowerCase()
              .includes(this.search.toLowerCase())
          )
        : this.options;
    },
  },
  methods: {
    changeIsVisible(): void {
      this.isVisible = !this.isVisible;
    },

    changeOption({ value }: SharedSelectOption): void {
      this.$emit("update:value", value);

      this.handleHide();
    },

    displayedOptionActiveClass({ isActive }: SharedSelectOption): string {
      return isActive ? `${COMPONENT_NAME}__option--${this.view}-active` : "";
    },

    handleHide(): void {
      this.isVisible = false;
    },
  },
});
