export enum RequisitesApiRoutes {
  bank = "/api/v1/requisite/bank/store",
  bankUpdate = "/api/v1/requisite/bank/update",
  bankDelete = "/api/v1/requisite/bank/delete",
  bankShow = "/api/v1/requisite/bank/show",
  bankList = "/api/v1/requisite/bank/list",

  crypto = "/api/v1/requisite/crypto/store",
  cryptoUpdate = "/api/v1/requisite/crypto/update",
  cryptoDelete = "/api/v1/requisite/crypto/delete",
  cryptoShow = "/api/v1/requisite/crypto/show",
  cryptoList = "/api/v1/requisite/crypto/list",

  verification = "/api/v1/requisite/verification",
}

export interface RequisitesProjectUrlGenerator {
  bank(): string;
  bankUpdate(id: number): string;
  bankDelete(userId: number, id: number): string;
  bankShow(userId: number, id: number): string;
  bankList(userId: number): string;

  crypto(): string;
  cryptoUpdate(id: number): string;
  cryptoDelete(userId: number, id: number): string;
  cryptoShow(userId: number, id: number): string;
  cryptoList(userId: number): string;

  verification(): string;
}

export class RequisitesUrlGenerator implements RequisitesProjectUrlGenerator {
  bank(): string {
    return RequisitesApiRoutes.bank;
  }

  bankUpdate(id: number): string {
    return `${RequisitesApiRoutes.bankUpdate}?id=${id}`;
  }

  bankDelete(userId: number, id: number): string {
    return `${RequisitesApiRoutes.bankDelete}?user_id=${userId}&id=${id}`;
  }

  bankShow(userId: number, id: number): string {
    return `${RequisitesApiRoutes.bankShow}?user_id=${userId}&id=${id}`;
  }

  bankList(userId: number): string {
    return `${RequisitesApiRoutes.bankList}?user_id=${userId}`;
  }

  crypto(): string {
    return RequisitesApiRoutes.crypto;
  }

  cryptoUpdate(id: number): string {
    return `${RequisitesApiRoutes.cryptoUpdate}?id=${id}`;
  }

  cryptoDelete(userId: number, id: number): string {
    return `${RequisitesApiRoutes.cryptoDelete}?user_id=${userId}&id=${id}`;
  }

  cryptoShow(userId: number, id: number): string {
    return `${RequisitesApiRoutes.cryptoShow}?user_id=${userId}&id=${id}`;
  }

  cryptoList(userId: number): string {
    return `${RequisitesApiRoutes.cryptoList}?user_id=${userId}`;
  }

  verification(): string {
    return RequisitesApiRoutes.verification;
  }
}
