import { Language } from "@/shared/constants/enums";

export interface SharedLanguageOption {
  id: number;
  label: string;
  value: Language;
  isActive: boolean;
}

export enum SharedLanguageOptionLabel {
  ru = "RU",
  en = "EN",
}
