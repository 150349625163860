<script lang="ts" src="./component.ts"></script>
<style lang="scss" src="./component.scss"></style>

<template>
  <div :class="b()">
    <div :class="b('info')">
      <span v-if="row.country" :class="['fi', `fi-${row.country}`]"></span>

      <img
        v-if="isCryptoIconShow"
        :class="b('icon', { crypto: true })"
        :src="displayedCryptoIcon"
        alt=""
      />

      <span :class="b('info-label')">
        {{ row.name.toUpperCase() }}
      </span>
    </div>

    <div :class="b('action')" v-click-outside="handleHide">
      <button :class="b('toggle', { active: isVisible })" @click="handleToggle">
        <span :class="b('toggle-icon')" v-html="displayedIcons.dots"></span>
      </button>

      <div :class="b('options', { active: isVisible })">
        <button :class="b('option')" @click="handleDelete">
          {{ $t("Удалить") }}
        </button>
      </div>
    </div>
  </div>
</template>
