import { RoutesTo } from "@/shared/constants/enums";

export interface DefaultToolbarLink {
  id: number;
  label: DefaultToolbarLinkLabel;
  to: RoutesTo;
  icon: string;
  isDisabled: boolean;
}

export enum DefaultToolbarLinkLabel {
  verification = "Реквизиты",
  application = "Создание заявки",
  history = "История",
  settings = "Настройки",
  wallet = "Кошелек",
}

export enum DefaultToolbarLinkIcon {
  verification = "verification",
  application = "application",
  history = "history",
  settings = "settings",
  wallet = "wallet",
}
