import { ApplicationType, Language } from "@/shared/constants/enums";

import {
  AdminUserAppResponse,
  AdminUserAppStatus,
  AdminUserAppStatusRequest,
} from "./repo";

export const AdminUserAppResponseFactory = (
  payload: Partial<AdminUserAppResponse> = {}
): AdminUserAppResponse => {
  return {
    index: payload.index ?? 0,
    id: payload.id ?? 0,
    user_id: payload.user_id ?? 0,
    country: payload.country ?? "",
    organisation: payload.organisation ?? "",
    regional_number: payload.regional_number ?? "",
    registration_date: payload.registration_date ?? "",
    inn: payload.inn ?? "",
    licensed: payload.licensed ?? "",
    telegram: payload.telegram ?? "",
    status: payload.status ?? "",
    type: payload.type ?? ApplicationType.kyc,
    created_at: payload.created_at ?? "",
    updated_at: payload.updated_at ?? "",
    user: {
      id: payload.user?.id ?? 0,
      name: payload.user?.name ?? "",
      email: payload.user?.email ?? "",
      email_verified_at: payload.user?.email_verified_at ?? "",
      two_factor_secret: payload.user?.two_factor_secret ?? null,
      two_factor_recovery_codes:
        payload.user?.two_factor_recovery_codes ?? null,
      created_at: payload.user?.created_at ?? "",
      updated_at: payload.user?.updated_at ?? "",
    },
  };
};

export const AdminUserAppStatusFactory = (
  payload: Partial<AdminUserAppStatus> = {}
): AdminUserAppStatus => {
  return {
    id: payload.id ?? 0,
    status: payload.status ?? "",
    messageRu: payload.messageRu ?? "",
    messageEn: payload.messageEn ?? "",
    type: payload.type ?? ApplicationType.kyc,
    lang: payload.lang ?? Language.ru,
  };
};

export const AdminUserAppStatusRequestFactory = (
  payload: AdminUserAppStatus
): AdminUserAppStatusRequest => {
  return {
    id: payload.id ?? 0,
    status: payload.status ?? "",
    message: {
      ru: payload.messageRu ?? "",
      en: payload.messageEn ?? "",
    },
    type: payload.type ?? ApplicationType.kyc,
  };
};
