import { toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, createElementVNode as _createElementVNode, withModifiers as _withModifiers, createTextVNode as _createTextVNode, resolveDirective as _resolveDirective, withDirectives as _withDirectives } from "vue"

const _hoisted_1 = ["onClick"]
const _hoisted_2 = ["innerHTML"]
const _hoisted_3 = ["innerHTML"]
const _hoisted_4 = ["onClick"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _directive_click_outside = _resolveDirective("click-outside")!

  return _withDirectives((_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(_ctx.b())
  }, [
    _createElementVNode("button", {
      class: _normalizeClass(_ctx.b('toggle')),
      onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.handleToggle && _ctx.handleToggle(...args)))
    }, [
      (_ctx.isLabelShow)
        ? (_openBlock(), _createElementBlock("p", {
            key: 0,
            class: _normalizeClass(_ctx.b('toggle-label'))
          }, _toDisplayString(_ctx.placeholder), 3))
        : (_openBlock(), _createElementBlock("span", {
            key: 1,
            class: _normalizeClass(_ctx.b('toggle-values'))
          }, [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.displayedValues, (value) => {
              return (_openBlock(), _createElementBlock("span", {
                class: _normalizeClass(_ctx.b('toggle-value')),
                key: value.id
              }, [
                (value.icon)
                  ? (_openBlock(), _createElementBlock("span", {
                      key: 0,
                      class: _normalizeClass([_ctx.b('toggle-icon'), 'fi', `fi-${value.icon}`])
                    }, null, 2))
                  : _createCommentVNode("", true),
                _createTextVNode(" " + _toDisplayString(value.label) + " ", 1),
                _createElementVNode("button", {
                  class: _normalizeClass(_ctx.b('toggle-remove')),
                  onClick: _withModifiers(($event: any) => (_ctx.handleRemoveValue(value)), ["stop"])
                }, [
                  _createElementVNode("span", {
                    class: _normalizeClass(_ctx.b('toggle-icon')),
                    innerHTML: _ctx.displayedRemoveIcon
                  }, null, 10, _hoisted_2)
                ], 10, _hoisted_1)
              ], 2))
            }), 128))
          ], 2)),
      _createElementVNode("span", {
        class: _normalizeClass(_ctx.b('toggle-group'))
      }, [
        (_ctx.isCounterShow)
          ? (_openBlock(), _createElementBlock("span", {
              key: 0,
              class: _normalizeClass(_ctx.b('toggle-counter'))
            }, _toDisplayString(_ctx.displayedCounter), 3))
          : _createCommentVNode("", true),
        _createElementVNode("span", {
          class: _normalizeClass(_ctx.b('toggle-icon', { active: _ctx.isVisible })),
          innerHTML: _ctx.displayedChevronIcon
        }, null, 10, _hoisted_3)
      ], 2)
    ], 2),
    _createElementVNode("div", {
      class: _normalizeClass(_ctx.b('options', { active: _ctx.isVisible }))
    }, [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.options, (option) => {
        return (_openBlock(), _createElementBlock("button", {
          class: _normalizeClass(_ctx.b('option', { active: option.isActive })),
          key: option.id,
          onClick: ($event: any) => (_ctx.handleOption(option))
        }, [
          _createElementVNode("span", {
            class: _normalizeClass([_ctx.b('option-icon'), 'fi', `fi-${option.icon}`])
          }, null, 2),
          _createTextVNode(" " + _toDisplayString(option.label), 1)
        ], 10, _hoisted_4))
      }), 128))
    ], 2)
  ], 2)), [
    [_directive_click_outside, _ctx.handleHide]
  ])
}