export enum AdminRequisitesApiRoutes {
  store = "/api/v1/admin/commission/requisite/store",
  update = "/api/v1/admin/commission/requisite/update",
  delete = "/api/v1/admin/commission/requisite/delete",
  bankDelete = "/api/v1/admin/commission/requisite/bank/delete",
  show = "/api/v1/admin/commission/requisite/show",
  list = "/api/v1/admin/commission/requisite/list",
}

export interface AdminRequisitesProjectUrlGenerator {
  store(): string;
  update(): string;
  delete(id: number): string;
  bankDelete(id: number): string;
  show(id: number): string;
  list(): string;
}

export class AdminRequisitesUrlGenerator
  implements AdminRequisitesProjectUrlGenerator
{
  store(): string {
    return AdminRequisitesApiRoutes.store;
  }

  update(): string {
    return AdminRequisitesApiRoutes.update;
  }

  delete(id: number): string {
    return `${AdminRequisitesApiRoutes.delete}?id=${id}`;
  }

  bankDelete(id: number): string {
    return `${AdminRequisitesApiRoutes.bankDelete}?id=${id}`;
  }

  show(id: number): string {
    return `${AdminRequisitesApiRoutes.show}?id=${id}`;
  }

  list(): string {
    return AdminRequisitesApiRoutes.list;
  }
}
