import { toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = ["innerHTML"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(_ctx.b())
  }, [
    _createElementVNode("p", {
      class: _normalizeClass(_ctx.b('label'))
    }, _toDisplayString(_ctx.$t("Пароль должен:")), 3),
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.displayedOptions, (option) => {
      return (_openBlock(), _createElementBlock("div", {
        class: _normalizeClass(_ctx.b('option')),
        key: option.id
      }, [
        _createElementVNode("span", {
          class: _normalizeClass(_ctx.b('option-icon')),
          innerHTML: _ctx.displayedIcon(option)
        }, null, 10, _hoisted_1),
        _createElementVNode("p", {
          class: _normalizeClass(
          _ctx.b('option-label', {
            green: option.isValidate,
            red: !option.isValidate,
          })
        )
        }, _toDisplayString(_ctx.$t(option.label)), 3)
      ], 2))
    }), 128))
  ], 2))
}