import { toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_UIInput = _resolveComponent("UIInput")!
  const _component_UIButton = _resolveComponent("UIButton")!
  const _component_SharedWindow = _resolveComponent("SharedWindow")!

  return (_openBlock(), _createBlock(_component_SharedWindow, {
    class: _normalizeClass(_ctx.b()),
    title: _ctx.displayedTitle,
    "is-big": true,
    onClose: _ctx.handleClose
  }, {
    default: _withCtx(() => [
      _createElementVNode("div", {
        class: _normalizeClass(_ctx.b('wrapper'))
      }, [
        _createElementVNode("h4", {
          class: _normalizeClass(_ctx.b('title'))
        }, _toDisplayString(_ctx.displayedLabel), 3),
        _createElementVNode("div", {
          class: _normalizeClass(_ctx.b('fields'))
        }, [
          _createElementVNode("div", {
            class: _normalizeClass(_ctx.b('field'))
          }, [
            _createElementVNode("p", {
              class: _normalizeClass(_ctx.b('field-label'))
            }, _toDisplayString(_ctx.$t("Номер расчетного счета")), 3),
            _createVNode(_component_UIInput, {
              view: "light",
              disabled: true,
              value: _ctx.requisites.mex_requisite_banks[0]?.recipient_bank
            }, null, 8, ["value"])
          ], 2),
          _createElementVNode("div", {
            class: _normalizeClass(_ctx.b('field'))
          }, [
            _createElementVNode("p", {
              class: _normalizeClass(_ctx.b('field-label'))
            }, _toDisplayString(_ctx.$t("Номер корреспондентского счета")), 3),
            _createVNode(_component_UIInput, {
              view: "light",
              disabled: true,
              value: _ctx.requisites.mex_requisite_banks[0]?.correspondent_account
            }, null, 8, ["value"])
          ], 2),
          _createElementVNode("div", {
            class: _normalizeClass(_ctx.b('field'))
          }, [
            _createElementVNode("p", {
              class: _normalizeClass(_ctx.b('field-label'))
            }, _toDisplayString(_ctx.$t("Банковский идентификационный код (БИК)")), 3),
            _createVNode(_component_UIInput, {
              view: "light",
              disabled: true,
              value: _ctx.requisites.mex_requisite_banks[0]?.bic
            }, null, 8, ["value"])
          ], 2),
          _createElementVNode("div", {
            class: _normalizeClass(_ctx.b('field'))
          }, [
            _createElementVNode("p", {
              class: _normalizeClass(_ctx.b('field-label'))
            }, _toDisplayString(_ctx.$t("Полное наименование банка")), 3),
            _createVNode(_component_UIInput, {
              view: "light",
              disabled: true,
              value: _ctx.requisites.mex_requisite_banks[0]?.recipient_bank
            }, null, 8, ["value"])
          ], 2),
          _createElementVNode("div", {
            class: _normalizeClass(_ctx.b('field'))
          }, [
            _createElementVNode("p", {
              class: _normalizeClass(_ctx.b('field-label'))
            }, _toDisplayString(_ctx.$t("Идентиф. номер налогоплательщика (ИНН)")), 3),
            _createVNode(_component_UIInput, {
              view: "light",
              disabled: true,
              value: _ctx.requisites.mex_requisite_banks[0]?.inn
            }, null, 8, ["value"])
          ], 2),
          _createElementVNode("div", {
            class: _normalizeClass(_ctx.b('field'))
          }, [
            _createElementVNode("p", {
              class: _normalizeClass(_ctx.b('field-label'))
            }, _toDisplayString(_ctx.$t("Код причины постановки на учет (КПП)")), 3),
            _createVNode(_component_UIInput, {
              view: "light",
              disabled: true,
              value: 
              _ctx.requisites.mex_requisite_banks[0]?.recipient_correspondent_account
            
            }, null, 8, ["value"])
          ], 2)
        ], 2),
        _createElementVNode("p", {
          class: _normalizeClass(_ctx.b('label'))
        }, " Нажимая кнопку, вы подтверждаете факт отправки средств на указанный адрес. Ваш баланс обновится после зачисления средств на ваш счет ", 2),
        _createVNode(_component_UIButton, {
          class: _normalizeClass(_ctx.b('confirm')),
          view: "main",
          label: "Подтвердить отправление",
          disabled: _ctx.isLoading,
          onClick: _ctx.handleConfirm
        }, null, 8, ["class", "disabled", "onClick"])
      ], 2)
    ]),
    _: 1
  }, 8, ["class", "title", "onClose"]))
}