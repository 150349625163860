import { resolveComponent as _resolveComponent, createVNode as _createVNode, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_AdminConfigCommissionFind = _resolveComponent("AdminConfigCommissionFind")!
  const _component_AdminConfigCommissionExchange = _resolveComponent("AdminConfigCommissionExchange")!
  const _component_AdminConfigCommissionDepositWithdrawal = _resolveComponent("AdminConfigCommissionDepositWithdrawal")!

  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(_ctx.b())
  }, [
    _createVNode(_component_AdminConfigCommissionFind, {
      type: _ctx.commissionExchangeFind.type,
      "onUpdate:type": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.commissionExchangeFind.type) = $event)),
      userId: _ctx.commissionExchangeFind.user_id,
      "onUpdate:userId": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.commissionExchangeFind.user_id) = $event))
    }, null, 8, ["type", "userId"]),
    _createVNode(_component_AdminConfigCommissionExchange, {
      type: _ctx.commissionExchangeFind.type,
      userId: _ctx.commissionExchangeFind.user_id,
      "onUpdate:userId": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.commissionExchangeFind.user_id) = $event))
    }, null, 8, ["type", "userId"]),
    _createVNode(_component_AdminConfigCommissionDepositWithdrawal, {
      type: _ctx.commissionExchangeFind.type,
      userId: _ctx.commissionExchangeFind.user_id,
      "onUpdate:userId": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.commissionExchangeFind.user_id) = $event))
    }, null, 8, ["type", "userId"])
  ], 2))
}