import { defineComponent, PropType } from "vue";

import { COMPONENT_NAME, SVG } from "./attributes";

import { UIButton } from "@/components/ui";
import { SharedWindow } from "@/components/shared";
import { WalletWithAmountResponse } from "@/shared/repository/modules/wallet/repo";
import { ApplicationStoreRequest } from "@/shared/repository/modules/application/repo";
import { SvgAttribute } from "@/shared/constants/constants";

export default defineComponent({
  name: COMPONENT_NAME,
  components: {
    UIButton,
    SharedWindow,
  },
  props: {
    walletWithAmount: {
      type: <PropType<WalletWithAmountResponse[]>>Array,
      default: () => [],
    },
    applicationStoreRequest: {
      type: <PropType<ApplicationStoreRequest>>Object,
      required: true,
    },
    isLoading: {
      type: Boolean,
      default: false,
    },
    isResult: {
      type: Boolean,
      default: false,
    },
    isConfirm: {
      type: Boolean,
      default: false,
    },
  },
  emits: {
    "update:isLoading": null,
    "update:isResult": null,
    "update:isConfirm": null,
  },
  data() {
    return {
      applicationRepository: this.$projectServices.applicationRepository,
    };
  },
  computed: {
    displayedSvg(): SvgAttribute {
      return SVG;
    },

    displayedCurrencyFrom(): WalletWithAmountResponse {
      const { currency_id_from } = this.applicationStoreRequest;

      return this.walletWithAmount.find(
        (currencyItem) => currencyItem.currency.id === currency_id_from
      )!;
    },

    displayedCurrencyTo(): WalletWithAmountResponse {
      const { currency_id_to } = this.applicationStoreRequest;

      return this.walletWithAmount.find(
        (currencyItem) => currencyItem.currency.id === currency_id_to
      )!;
    },

    displayedCommissions(): string {
      const { commissions } = this.applicationStoreRequest;

      return `~ ${commissions} ${this.displayedCurrencyFrom.currency.name.toUpperCase()}`;
    },
  },
  methods: {
    handleCopy(): void {
      console.log("Handle Copy");
    },

    handleClose(): void {
      if (!this.isLoading) {
        this.$emit("update:isResult", false);
      }
    },

    async handleCreate(): Promise<void> {
      try {
        this.$emit("update:isLoading", true);

        // await this.applicationRepository.store(this.applicationStoreRequest);

        this.$emit("update:isResult", false);
        this.$emit("update:isConfirm", true);
      } catch (error) {
        console.log(error);
      } finally {
        this.$emit("update:isLoading", false);
      }
    },
  },
});
