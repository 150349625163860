import { defineComponent } from "vue";

import { COMPONENT_NAME, SVG } from "./attributes";

import { WalletCard } from "../card";

import { SvgAttribute } from "@/shared/constants/constants";
import { DepositWithdrawalHistoryResponse } from "@/shared/repository/modules/deposit-withdrawal/repo";

export interface WalletHistoryColumn {
  id: number;
  label: string;
}

export default defineComponent({
  name: COMPONENT_NAME,
  components: {
    WalletCard,
  },
  data() {
    return {
      depositWithdrawalRepo: this.$projectServices.depositWithdrawalRepo,
    };
  },
  computed: {
    displayedColumns(): WalletHistoryColumn[] {
      return [
        {
          id: 0,
          label: "Дата и время",
        },
        {
          id: 1,
          label: "Тип операции",
        },
        {
          id: 2,
          label: "Сумма",
        },
        {
          id: 3,
          label: "Валюта",
        },
      ];
    },

    displayedRows(): DepositWithdrawalHistoryResponse[] {
      return this.depositWithdrawalRepo.history
        .slice()
        .sort(
          (a, b) =>
            new Date(b.created_at).getTime() - new Date(a.created_at).getTime()
        )
        .slice(0, 5);
    },

    displayedIcons(): SvgAttribute {
      return SVG;
    },
  },
});
