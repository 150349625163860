import { defineComponent } from "vue";

import { COMPONENT_NAME } from "./attributes";

import { SharedWindow } from "../window";
import { UIButton } from "@/components/ui";
import { SharedDepositField } from "./field";
import { DepositWithdrawalDeposit } from "@/shared/repository/modules/deposit-withdrawal/repo";
import { formatFiatCurrency } from "@/shared/utils/amount-helpers";
import {
  AdminCommissionRequisitesBankResponseFactory,
  AdminRequisitesListResponseFactory,
} from "@/shared/repository/modules/admin/commission/factory";
import { AdminCommissionRequisitesBankResponse } from "@/shared/repository/modules/admin/commission/repo";

const CORRESPONDENT_BANK_MAX_LENGTH = 25;

export default defineComponent({
  name: COMPONENT_NAME,
  components: {
    SharedWindow,
    UIButton,
    SharedDepositField,
  },
  data() {
    return {
      isLoading: false,
      bankId: 0,
      requisites: AdminRequisitesListResponseFactory(),
      depositWithdrawalRepo: this.$projectServices.depositWithdrawalRepo,
      depositWithdrawalRepository:
        this.$projectServices.depositWithdrawalRepository,
    };
  },
  async created(): Promise<void> {
    try {
      this.isLoading = true;

      this.requisites = await this.depositWithdrawalRepository.requisite(
        this.depositWithdrawalRepo.deposit.currency_id
      );

      this.bankId = this.requisites.mex_requisite_banks[0]?.id ?? 0;
    } catch (error) {
      console.log(error);
    } finally {
      this.isLoading = false;
    }
  },
  computed: {
    displayedDeposit(): DepositWithdrawalDeposit {
      return this.depositWithdrawalRepo.deposit;
    },

    displayedBank(): AdminCommissionRequisitesBankResponse {
      return (
        this.requisites.mex_requisite_banks.find(
          (bank) => bank.id === this.bankId
        ) ?? AdminCommissionRequisitesBankResponseFactory()
      );
    },

    displayedAmount(): string {
      return formatFiatCurrency(this.displayedDeposit.amount);
    },

    isDisabled(): boolean {
      return this.isLoading;
    },
  },
  methods: {
    handleClose(): void {
      this.depositWithdrawalRepo.UPDATE_DEPOSIT({
        id: 0,
        currency_id: 0,
        amount: 0,
        currencyName: "",
      });
    },

    handleBank(id: number): void {
      this.bankId = id;
    },

    handleBankLabel(correspondentBank: string): string {
      return correspondentBank.length > CORRESPONDENT_BANK_MAX_LENGTH
        ? `${correspondentBank.substring(0, CORRESPONDENT_BANK_MAX_LENGTH)}...`
        : correspondentBank;
    },

    async handleConfirm(): Promise<void> {
      try {
        this.isLoading = true;

        await this.depositWithdrawalRepository.confirmation(
          this.displayedDeposit.id
        );

        this.handleClose();
      } catch (error) {
        console.log(error);
      } finally {
        this.isLoading = false;
      }
    },
  },
});
