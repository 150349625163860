<script lang="ts" src="./component.ts"></script>
<style lang="scss" src="./component.scss"></style>

<template>
  <SharedWindow
    :class="b()"
    title="Подтверждение заявки через email"
    @close="handleClose"
  >
    <div :class="b('wrapper')">
      <p :class="b('label')">
        {{ $t("Код-подтверждение отправлен на email") }}
      </p>

      <div :class="b('form')">
        <SharedVerification :class="b('verification')" v-model:code="code" />
      </div>

      <UIButton
        label="Создать заявку"
        view="main"
        :disabled="isCreateDisabled"
        @click="handleCreate"
      />
    </div>
  </SharedWindow>
</template>
