<script lang="ts" src="./component.ts"></script>
<style lang="scss" src="./component.scss"></style>

<template>
  <SharedCard :class="b()">
    <div :class="b('header')">
      <SharedBack @click="handleBack" />

      <h3 :class="b('header-title')">
        {{ $t("Загрузка документов") }}
      </h3>
    </div>

    <div :class="b('passport-photo')">
      <div :class="b('passport')">
        <h5 :class="b('title')">
          {{ $t("Загрузка паспорта или ВУ*") }}
        </h5>

        <div :class="b('passport-uploads')">
          <SharedValidate
            field="kyc-personal-documents-passport-selfie"
            :value="documents['kyc-personal-documents-passport-selfie'].id"
            :is-validate="isValidate"
            :is-center="true"
          >
            <SharedUpload
              :class="b('upload')"
              :document="documents['kyc-personal-documents-passport-selfie']"
              :is-loading="isLoading"
              :is-readonly="isReadonly"
              :is-error="
                isValidate &&
                !documents['kyc-personal-documents-passport-selfie'].id
              "
              @upload="handleUploadFile"
              @remove="handleRemoveFile"
            >
              <template #label>
                {{ $t("Страница") }} <br />
                {{ $t("с фото") }}
              </template>
            </SharedUpload>
          </SharedValidate>

          <SharedValidate
            field="kyc-personal-documents-passport-organization"
            :value="
              documents['kyc-personal-documents-passport-organization'].id
            "
            :is-validate="isValidate"
            :is-center="true"
          >
            <SharedUpload
              :class="b('upload')"
              :document="
                documents['kyc-personal-documents-passport-organization']
              "
              :is-loading="isLoading"
              :is-readonly="isReadonly"
              :is-error="
                isValidate &&
                !documents['kyc-personal-documents-passport-organization'].id
              "
              @upload="handleUploadFile"
              @remove="handleRemoveFile"
            >
              <template #label>
                {{ $t("Страница") }} <br />
                {{ $t("кем выдан") }}
              </template>
            </SharedUpload>
          </SharedValidate>

          <SharedValidate
            field="kyc-personal-documents-passport-address"
            :value="documents['kyc-personal-documents-passport-address'].id"
            :is-validate="isValidate"
            :is-center="true"
          >
            <SharedUpload
              :class="b('upload')"
              :document="documents['kyc-personal-documents-passport-address']"
              :is-loading="isLoading"
              :is-readonly="isReadonly"
              :is-error="
                isValidate &&
                !documents['kyc-personal-documents-passport-address'].id
              "
              @upload="handleUploadFile"
              @remove="handleRemoveFile"
            >
              <template #label>
                {{ $t("Страница") }} <br />
                {{ $t("с пропиской") }}
              </template>
            </SharedUpload>
          </SharedValidate>
        </div>
      </div>

      <div :class="b('photo')">
        <h5 :class="b('title')">
          {{ $t("Загрузка селфи*") }}
        </h5>

        <SharedValidate
          :class="b('photo-upload')"
          field="kyc-personal-documents-selfie"
          :value="documents['kyc-personal-documents-selfie'].id"
          :is-validate="isValidate"
          :is-center="true"
        >
          <SharedUpload
            :class="b('photo-upload')"
            :document="documents['kyc-personal-documents-selfie']"
            :is-loading="isLoading"
            :is-readonly="isReadonly"
            :isCenter="true"
            :is-label="false"
            :is-error="
              isValidate && !documents['kyc-personal-documents-selfie'].id
            "
            @upload="handleUploadFile"
            @remove="handleRemoveFile"
          />
        </SharedValidate>
      </div>
    </div>

    <div :class="b('agreements')">
      <h5 :class="b('title')">
        {{ $t("Скачайте и подпишите соглашения*") }}
      </h5>

      <div :class="b('agreements-block')">
        <div :class="b('agreements-group', { kyc: true })">
          <SharedDownload :document="displayedQuestionnaireOfAnIndividual">
            <template #label>
              {{ $t("Анкета") }} <br :class="b('agreements-maintenance')" />
              {{ $t("физического лица") }}
            </template>
          </SharedDownload>

          <SharedValidate
            field="kyc-personal-documents-questionnaire"
            :value="documents['kyc-personal-documents-questionnaire'].id"
            :is-validate="isValidate"
            :is-center="true"
          >
            <SharedUpload
              :class="b('upload')"
              :document="documents['kyc-personal-documents-questionnaire']"
              :is-loading="isLoading"
              :is-readonly="isReadonly"
              :is-error="
                isValidate &&
                !documents['kyc-personal-documents-questionnaire'].id
              "
              @upload="handleUploadFile"
              @remove="handleRemoveFile"
            >
              <template #label>
                {{ $t("Подписанное") }} <br />
                {{ $t("соглашение") }}
              </template>
            </SharedUpload>
          </SharedValidate>
        </div>

        <div :class="b('agreements-group', { personal: true })">
          <SharedDownload
            :document="displayedConsentToTheProcessingOfPersonalData"
          >
            <template #label>
              {{ $t("Согласие на обработку") }} <br />
              {{ $t("персональных данных") }}
            </template>
          </SharedDownload>

          <SharedValidate
            field="kyc-personal-documents-personal-data"
            :value="documents['kyc-personal-documents-personal-data'].id"
            :is-validate="isValidate"
            :is-center="true"
          >
            <SharedUpload
              :class="b('upload')"
              :document="documents['kyc-personal-documents-personal-data']"
              :is-loading="isLoading"
              :is-readonly="isReadonly"
              :is-error="
                isValidate &&
                !documents['kyc-personal-documents-personal-data'].id
              "
              @upload="handleUploadFile"
              @remove="handleRemoveFile"
            >
              <template #label>
                {{ $t("Подписанное") }} <br />
                {{ $t("соглашение") }}
              </template>
            </SharedUpload>
          </SharedValidate>
        </div>

        <div :class="b('agreements-group')">
          <SharedDownload :document="displayedRiskDeclaration">
            <template #label>
              {{ $t("Декларация") }} <br />
              {{ $t("о рисках") }}
            </template>
          </SharedDownload>

          <SharedValidate
            field="kyc-personal-documents-declaration"
            :value="documents['kyc-personal-documents-declaration'].id"
            :is-validate="isValidate"
            :is-center="true"
          >
            <SharedUpload
              :class="b('upload')"
              :document="documents['kyc-personal-documents-declaration']"
              :is-loading="isLoading"
              :is-readonly="isReadonly"
              :is-error="
                isValidate &&
                !documents['kyc-personal-documents-declaration'].id
              "
              @upload="handleUploadFile"
              @remove="handleRemoveFile"
            >
              <template #label>
                {{ $t("Подписанное") }} <br />
                {{ $t("соглашение") }}
              </template>
            </SharedUpload>
          </SharedValidate>
        </div>

        <div :class="b('agreements-group')">
          <SharedDownload :document="displayedBrokerageAgreement">
            <template #label>
              {{ $t("Брокерский") }} <br />
              {{ $t("договор") }}
            </template>
          </SharedDownload>

          <SharedValidate
            field="kyc-personal-documents-broker"
            :value="documents['kyc-personal-documents-broker'].id"
            :is-validate="isValidate"
            :is-center="true"
          >
            <SharedUpload
              :class="b('upload')"
              :document="documents['kyc-personal-documents-broker']"
              :is-loading="isLoading"
              :is-readonly="isReadonly"
              :is-error="
                isValidate && !documents['kyc-personal-documents-broker'].id
              "
              @upload="handleUploadFile"
              @remove="handleRemoveFile"
            >
              <template #label>
                {{ $t("Подписанное") }} <br />
                {{ $t("соглашение") }}
              </template>
            </SharedUpload>
          </SharedValidate>
        </div>
      </div>
    </div>

    <div v-if="isOtherShow" :class="b('other')">
      <h4 :class="b('other-title')">
        {{
          $t(
            "Пожалуйста, прикрепите иные документы для подтверждения вашей верификации"
          )
        }}
      </h4>

      <p :class="b('other-label')">
        {{
          $t(
            "Например: Документ о происхождении денежных средств, Документ подтверждающий постановку на учет в налоговом органе и другие"
          )
        }}
      </p>

      <div :class="b('other-documents')">
        <SharedUpload
          v-for="file in files"
          :key="file.uuid"
          :class="b('other-upload')"
          :document="file"
          :is-upload="file.isUpload"
          :is-remove="file.isRemove"
          :is-readonly="isReadonly"
          :is-loading="isLoading"
          @remove="handleRemoveAdditionalFile"
        >
          <template v-if="file.name" #label>
            {{ file.name }}
          </template>
        </SharedUpload>

        <SharedUpload
          v-if="isAddtionalUpload"
          :class="b('other-upload')"
          :document="file"
          :is-upload="file.isUpload"
          :is-readonly="isReadonly"
          :is-loading="isLoading"
          :isCenter="true"
          :is-label="false"
          @upload="handleUploadAdditionalFile"
        />
      </div>
    </div>

    <UIButton
      :class="b('verification')"
      :label="displayedVerificationLabel"
      view="main"
      :disabled="isVerificationDisabled"
      @click="handleVerification"
    />
  </SharedCard>
</template>
