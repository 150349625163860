import { defineComponent } from "vue";

import { COMPONENT_NAME } from "./attributes";

import { AdminConfigRequisitesTable } from "./table";
import { AdminConfigRequisitesForm } from "./form";

export default defineComponent({
  name: COMPONENT_NAME,
  components: {
    AdminConfigRequisitesTable,
    AdminConfigRequisitesForm,
  },
  data() {
    return {
      adminRequisitesRepo: this.$projectServices.adminRequisitesRepo,
    };
  },
  computed: {
    isRequisiteShow(): boolean {
      return this.adminRequisitesRepo.requisite.isShow;
    },
  },
});
