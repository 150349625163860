<script lang="ts" src="./component.ts"></script>
<style lang="scss" src="./component.scss"></style>

<template>
  <WalletCard :class="b()" title="Заявки" tab="application">
    <div :class="b('table')">
      <div :class="b('columns')">
        <div
          :class="b('column')"
          v-for="column in displayedColumns"
          :key="column.id"
        >
          <p :class="b('column-label')">
            {{ $t(column.label) }}
          </p>
        </div>
      </div>

      <div :class="b('rows')">
        <div
          :class="b('row')"
          v-for="(row, rowIndex) in applicationHistory"
          :key="rowIndex"
        >
          <div :class="b('row-date-time')">
            <p :class="b('row-label')">
              {{ row }}
              <!-- {{ row.dateTime }} -->
            </p>
          </div>

          <div :class="b('row-operation')">
            <div :class="b('row-group')">
              <p :class="b('row-label', { from: true })">
                <!-- {{ row.operation.from.amount }} -->
              </p>

              <p :class="b('row-label')">
                <!-- {{ row.operation.from.currency }} -->
              </p>
            </div>

            <span
              :class="b('row-icon', { chevron: true })"
              v-html="displayedIcons.chevron"
            ></span>

            <div :class="b('row-group')">
              <p :class="b('row-label', { to: true })">
                <!-- {{ row.operation.to.amount }} -->
              </p>

              <p :class="b('row-label')">
                <!-- {{ row.operation.to.currency }} -->
              </p>
            </div>

            <!-- <span
              :class="b('row-icon', { type: true })"
              v-html="row.operation.icon"
            ></span> -->
          </div>
        </div>
      </div>
    </div>
  </WalletCard>
</template>
