import { defineComponent, PropType } from "vue";

import { COMPONENT_NAME } from "./attributes";

import { AdminKycBack } from "../back";

import { SharedAdd } from "@/components/shared";

import { AdminKycComponent } from "@/views/admin-kyc/model";
import { KybParticipant } from "@/shared/repository/modules/kyb/repo";
import { KybParticipantFactory } from "@/shared/repository/modules/kyb/factory";

export default defineComponent({
  name: COMPONENT_NAME,
  components: {
    AdminKycBack,
    SharedAdd,
  },
  props: {
    id: {
      type: Number,
      required: true,
    },
    component: {
      type: <PropType<AdminKycComponent>>String,
      default: "",
    },
  },
  emits: {
    "update:component": null,
  },
  data() {
    return {
      isLoading: false,
      kybParticipants: <KybParticipant[]>[],
      kybRepo: this.$projectServices.kybRepo,
      kybRepository: this.$projectServices.kybRepository,
    };
  },
  async created(): Promise<void> {
    try {
      this.isLoading = true;

      this.kybParticipants = await this.kybRepository.participantsById(this.id);
    } catch (e) {
      console.log(e);
    } finally {
      this.isLoading = false;
    }
  },
  computed: {
    displayedParticipants(): KybParticipant[] {
      return this.kybParticipants.map((kybParticipant) =>
        KybParticipantFactory(kybParticipant)
      );
    },
  },
  methods: {
    handleBack(): void {
      this.$emit("update:component", AdminKycComponent.kybCompany);
    },

    handleParticipant({ id }: KybParticipant): void {
      this.kybRepo.UPDATE_PARTICIPANT_ID(id);

      this.$emit("update:component", AdminKycComponent.kybParticipant);
    },
  },
});
