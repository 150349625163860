<script lang="ts" src="./component.ts"></script>
<style lang="scss" src="./component.scss"></style>

<template>
  <div :class="b()">
    <div :class="b('columns')">
      <div
        :class="b('column')"
        v-for="column in displayedColumns"
        :key="column.key"
      >
        <p :class="b('column-label')">
          {{ $t(column.title) }}
        </p>
      </div>
    </div>

    <div :class="b('rows')">
      <AdminUsersTableRow
        :class="b('row')"
        v-for="user in displayedUsers"
        :key="user.index"
        :user="user"
        @update:isStatus="handleChangeIsStatus"
        @update:isBalance="handleChangeIsBalance"
      />
    </div>
  </div>
</template>
