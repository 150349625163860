<script lang="ts" src="./component.ts"></script>
<style lang="scss" src="./component.scss"></style>

<template>
  <SharedCard :class="b()">
    <div :class="b('header')">
      <SharedBack @click="handleBack" />

      <h3 :class="b('header-title')">
        {{ $t("Загрузка документов") }}
      </h3>
    </div>

    <div :class="b('upload')">
      <h5 :class="b('title')">
        {{ $t("Загрузите подтверждающие документы*") }}
      </h5>

      <div :class="b('upload-group')">
        <SharedValidate
          :class="b('shared-upload')"
          field="kyb-documents-registration-company"
          :value="documents['kyb-documents-registration-company'].id"
          :is-validate="isValidate"
          :is-center="true"
        >
          <SharedUpload
            :class="b('shared-upload')"
            :document="documents['kyb-documents-registration-company']"
            :is-readonly="isReadonly"
            :is-loading="isLoading"
            :is-padding="false"
            :is-error="
              isValidate && !documents['kyb-documents-registration-company'].id
            "
            @upload="handleUploadFile"
            @remove="handleRemoveFile"
          >
            <template #label>
              {{ $t("Сертификат о") }} <br />
              {{ $t("регистрации") }} <br />
              {{ $t("компании") }}
            </template>
          </SharedUpload>
        </SharedValidate>

        <SharedValidate
          :class="b('shared-upload')"
          field="kyb-documents-regulation"
          :value="documents['kyb-documents-regulation'].id"
          :is-validate="isValidate"
          :is-center="true"
        >
          <SharedUpload
            :class="b('shared-upload')"
            :document="documents['kyb-documents-regulation']"
            :is-readonly="isReadonly"
            :is-loading="isLoading"
            :is-error="isValidate && !documents['kyb-documents-regulation'].id"
            @upload="handleUploadFile"
            @remove="handleRemoveFile"
          >
            <template #label>
              <br />
              {{ $t("Устав") }}
            </template>
          </SharedUpload>
        </SharedValidate>

        <SharedValidate
          :class="b('shared-upload')"
          field="kyb-documents-list-of-shareholders"
          :value="documents['kyb-documents-list-of-shareholders'].id"
          :is-validate="isValidate"
          :is-center="true"
        >
          <SharedUpload
            :class="b('shared-upload')"
            :document="documents['kyb-documents-list-of-shareholders']"
            :is-readonly="isReadonly"
            :is-loading="isLoading"
            :is-error="
              isValidate && !documents['kyb-documents-list-of-shareholders'].id
            "
            @upload="handleUploadFile"
            @remove="handleRemoveFile"
          >
            <template #label>
              <br />
              {{ $t("Список акционеров") }}
            </template>
          </SharedUpload>
        </SharedValidate>

        <SharedValidate
          :class="b('shared-upload')"
          field="kyb-documents-license"
          :value="documents['kyb-documents-license'].id"
          :is-validate="isValidate"
          :is-center="true"
        >
          <SharedUpload
            :class="b('shared-upload')"
            :document="documents['kyb-documents-license']"
            :is-readonly="isReadonly"
            :is-loading="isLoading"
            :is-error="isValidate && !documents['kyb-documents-license'].id"
            @upload="handleUploadFile"
            @remove="handleRemoveFile"
          >
            <template #label>
              <br />
              {{ $t("Лицензия") }}
            </template>
          </SharedUpload>
        </SharedValidate>

        <SharedValidate
          :class="b('shared-upload')"
          field="kyb-documents-check-addresses"
          :value="documents['kyb-documents-check-addresses'].id"
          :is-validate="isValidate"
          :is-center="true"
        >
          <SharedUpload
            :class="b('shared-upload')"
            :document="documents['kyb-documents-check-addresses']"
            :is-readonly="isReadonly"
            :is-loading="isLoading"
            :is-padding="false"
            :is-error="
              isValidate && !documents['kyb-documents-check-addresses'].id
            "
            @upload="handleUploadFile"
            @remove="handleRemoveFile"
          >
            <template #label>
              {{ $t("Подтверждение") }} <br />
              {{ $t("адреса") }} <br />
              {{ $t("регистрации") }}
            </template>
          </SharedUpload>
        </SharedValidate>

        <SharedValidate
          :class="b('shared-upload')"
          field="kyb-documents-sow"
          :value="documents['kyb-documents-sow'].id"
          :is-validate="isValidate"
          :is-center="true"
        >
          <SharedUpload
            :class="b('shared-upload')"
            :document="documents['kyb-documents-sow']"
            :is-readonly="isReadonly"
            :is-loading="isLoading"
            :is-padding="false"
            :is-error="isValidate && !documents['kyb-documents-sow'].id"
            @upload="handleUploadFile"
            @remove="handleRemoveFile"
          >
            <template #label>
              {{ $t("Доказательства") }} <br />
              {{ $t("источника дохода") }} <br />
              SoW
            </template>
          </SharedUpload>
        </SharedValidate>

        <SharedValidate
          :class="b('shared-upload')"
          field="kyb-documents-sof"
          :value="documents['kyb-documents-sof'].id"
          :is-validate="isValidate"
          :is-center="true"
        >
          <SharedUpload
            :class="b('shared-upload')"
            :document="documents['kyb-documents-sof']"
            :is-readonly="isReadonly"
            :is-loading="isLoading"
            :is-padding="false"
            :is-error="isValidate && !documents['kyb-documents-sof'].id"
            @upload="handleUploadFile"
            @remove="handleRemoveFile"
          >
            <template #label>
              {{ $t("Доказательства") }} <br />
              {{ $t("источника средств") }} <br />
              SoF
            </template>
          </SharedUpload>
        </SharedValidate>

        <SharedValidate
          :class="b('shared-upload')"
          field="kyb-documents-inn"
          :value="documents['kyb-documents-inn'].id"
          :is-validate="isValidate"
          :is-center="true"
        >
          <SharedUpload
            :class="b('shared-upload')"
            :document="documents['kyb-documents-inn']"
            :is-readonly="isReadonly"
            :is-loading="isLoading"
            :is-error="isValidate && !documents['kyb-documents-inn'].id"
            @upload="handleUploadFile"
            @remove="handleRemoveFile"
          >
            <template #label>
              {{ $t("Свидетельство") }} <br />
              {{ $t("ИНН") }}
            </template>
          </SharedUpload>
        </SharedValidate>

        <SharedValidate
          :class="b('shared-upload')"
          field="kyb-documents-record-sheet"
          :value="documents['kyb-documents-record-sheet'].id"
          :is-validate="isValidate"
          :is-center="true"
        >
          <SharedUpload
            :class="b('shared-upload')"
            :document="documents['kyb-documents-record-sheet']"
            :is-readonly="isReadonly"
            :is-loading="isLoading"
            :is-error="
              isValidate && !documents['kyb-documents-record-sheet'].id
            "
            @upload="handleUploadFile"
            @remove="handleRemoveFile"
          >
            <template #label>
              {{ $t("Лист Записи") }}
            </template>
          </SharedUpload>
        </SharedValidate>

        <SharedValidate
          :class="b('shared-upload')"
          field="kyb-documents-create"
          :value="documents['kyb-documents-create'].id"
          :is-validate="isValidate"
          :is-center="true"
        >
          <SharedUpload
            :class="b('shared-upload')"
            :document="documents['kyb-documents-create']"
            :is-readonly="isReadonly"
            :is-loading="isLoading"
            :is-padding="false"
            :is-error="isValidate && !documents['kyb-documents-create'].id"
            @upload="handleUploadFile"
            @remove="handleRemoveFile"
          >
            <template #label>
              {{ $t("Решение о создании/") }} <br />
              {{ $t("Учредительный") }} <br />
              {{ $t("договор") }}
            </template>
          </SharedUpload>
        </SharedValidate>

        <SharedValidate
          :class="b('shared-upload')"
          field="kyb-documents-update"
          :value="documents['kyb-documents-update'].id"
          :is-validate="isValidate"
          :is-center="true"
        >
          <SharedUpload
            :class="b('shared-upload')"
            :document="documents['kyb-documents-update']"
            :is-readonly="isReadonly"
            :is-loading="isLoading"
            :is-padding="false"
            :is-error="isValidate && !documents['kyb-documents-update'].id"
            @upload="handleUploadFile"
            @remove="handleRemoveFile"
          >
            <template #label>
              {{ $t("Изменения в") }} <br />
              {{ $t("учредительные") }} <br />
              {{ $t("документы") }}
            </template>
          </SharedUpload>
        </SharedValidate>

        <SharedValidate
          :class="b('shared-upload')"
          field="kyb-documents-purpose"
          :value="documents['kyb-documents-purpose'].id"
          :is-validate="isValidate"
          :is-center="true"
        >
          <SharedUpload
            :class="b('shared-upload')"
            :document="documents['kyb-documents-purpose']"
            :is-readonly="isReadonly"
            :is-loading="isLoading"
            :is-padding="false"
            :is-error="isValidate && !documents['kyb-documents-purpose'].id"
            @upload="handleUploadFile"
            @remove="handleRemoveFile"
          >
            <template #label>
              {{ $t("О назначении") }} <br />
              {{ $t("единоличного") }} <br />
              {{ $t("исполнительного") }}...
            </template>
          </SharedUpload>
        </SharedValidate>

        <SharedValidate
          :class="b('shared-upload')"
          field="kyb-documents-finance"
          :value="documents['kyb-documents-finance'].id"
          :is-validate="isValidate"
          :is-center="true"
        >
          <SharedUpload
            :class="b('shared-upload')"
            :document="documents['kyb-documents-finance']"
            :is-readonly="isReadonly"
            :is-loading="isLoading"
            :is-padding="false"
            :is-error="isValidate && !documents['kyb-documents-finance'].id"
            @upload="handleUploadFile"
            @remove="handleRemoveFile"
          >
            <template #label>
              {{ $t("Финансовая") }} <br />
              {{ $t("отчетность") }} <br />
              {{ $t("(за год/за квартал)") }}
            </template>
          </SharedUpload>
        </SharedValidate>

        <SharedValidate
          :class="b('shared-upload')"
          field="kyb-documents-example"
          :value="documents['kyb-documents-example'].id"
          :is-validate="isValidate"
          :is-center="true"
        >
          <SharedUpload
            :class="b('shared-upload')"
            :document="documents['kyb-documents-example']"
            :is-readonly="isReadonly"
            :is-loading="isLoading"
            :is-error="isValidate && !documents['kyb-documents-example'].id"
            @upload="handleUploadFile"
            @remove="handleRemoveFile"
          >
            <template #label>
              {{ $t("Карточка образцов") }} <br />
              {{ $t("подписей и печатей") }}
            </template>
          </SharedUpload>
        </SharedValidate>
      </div>
    </div>

    <div :class="b('download')">
      <h5 :class="b('title')">
        {{ $t("Скачайте и подпишите соглашения*") }}
      </h5>

      <div :class="b('download-groups')">
        <div :class="b('download-group')">
          <SharedDownload :document="displayedQuestionnaireOfALegalEntity">
            <template #label>
              {{ $t("Анкета") }} <br />
              {{ $t("юридического лица") }}
            </template>
          </SharedDownload>

          <SharedValidate
            field="kyb-documents-document"
            :value="documents['kyb-documents-document'].id"
            :is-validate="isValidate"
            :is-center="true"
          >
            <SharedUpload
              :class="b('download-upload')"
              :document="documents['kyb-documents-document']"
              :is-readonly="isReadonly"
              :isCenter="true"
              :is-loading="isLoading"
              :is-error="isValidate && !documents['kyb-documents-document'].id"
              @upload="handleUploadFile"
              @remove="handleRemoveFile"
            >
              <template #label>
                {{ $t("Подписанное") }} <br />
                {{ $t("соглашение") }}
              </template>
            </SharedUpload>
          </SharedValidate>
        </div>

        <div :class="b('download-group')">
          <SharedDownload
            :document="displayedQuestionnaireOfTheBeneficialOwner"
          >
            <template #label>
              {{ $t("Анкета") }} <br />
              {{ $t("бенефициарного") }} <br />
              {{ $t("владельца") }}
            </template>
          </SharedDownload>

          <SharedValidate
            field="kyb-documents-beneficiary"
            :value="documents['kyb-documents-beneficiary'].id"
            :is-validate="isValidate"
            :is-center="true"
          >
            <SharedUpload
              :class="b('download-upload')"
              :document="documents['kyb-documents-beneficiary']"
              :is-readonly="isReadonly"
              :is-loading="isLoading"
              :isCenter="true"
              :is-error="
                isValidate && !documents['kyb-documents-beneficiary'].id
              "
              @upload="handleUploadFile"
              @remove="handleRemoveFile"
            >
              <template #label>
                {{ $t("Подписанное") }} <br />
                {{ $t("соглашение") }}
              </template>
            </SharedUpload>
          </SharedValidate>
        </div>

        <div :class="b('download-group')">
          <SharedDownload :document="displayedBrokerageAgreement">
            <template #label>
              {{ $t("Брокерский") }} <br />
              {{ $t("договор") }}
            </template>
          </SharedDownload>

          <SharedValidate
            field="kyc-personal-documents-broker"
            :value="documents['kyb-documents-broker'].id"
            :is-validate="isValidate"
            :is-center="true"
          >
            <SharedUpload
              :class="b('download-upload')"
              :document="documents['kyb-documents-broker']"
              :is-readonly="isReadonly"
              :is-loading="isLoading"
              :isCenter="true"
              :is-error="isValidate && !documents['kyb-documents-broker'].id"
              @upload="handleUploadFile"
              @remove="handleRemoveFile"
            >
              <template #label>
                {{ $t("Подписанное") }} <br />
                {{ $t("соглашение") }}
              </template>
            </SharedUpload>
          </SharedValidate>
        </div>

        <div :class="b('download-group')">
          <SharedDownload :document="displayedRiskDeclaration">
            <template #label>
              {{ $t("Декларация") }} <br />
              {{ $t("о рисках") }}
            </template>
          </SharedDownload>

          <SharedValidate
            field="kyc-personal-documents-declaration"
            :value="documents['kyb-documents-declaration'].id"
            :is-validate="isValidate"
            :is-center="true"
          >
            <SharedUpload
              :class="b('download-upload')"
              :document="documents['kyb-documents-declaration']"
              :is-readonly="isReadonly"
              :is-loading="isLoading"
              :isCenter="true"
              :is-error="
                isValidate && !documents['kyb-documents-declaration'].id
              "
              @upload="handleUploadFile"
              @remove="handleRemoveFile"
            >
              <template #label>
                {{ $t("Подписанное") }} <br />
                {{ $t("соглашение") }}
              </template>
            </SharedUpload>
          </SharedValidate>
        </div>
      </div>
    </div>

    <div v-if="isOtherShow" :class="b('other')">
      <h5 :class="b('title')">
        {{
          $t(
            "Пожалуйста, прикрепите иные документы для подтверждения вашей верификации"
          )
        }}
      </h5>

      <div :class="b('other-documents')">
        <SharedUpload
          v-for="file in files"
          :key="file.uuid"
          :class="b('other-upload')"
          :document="file"
          :is-upload="file.isUpload"
          :is-remove="file.isRemove"
          :is-readonly="isReadonly"
          :is-loading="isLoading"
          @remove="handleRemoveAdditionalFile"
        >
          <template v-if="file.name" #label>
            {{ file.name }}
          </template>
        </SharedUpload>

        <SharedUpload
          v-if="isAddtionalUpload"
          :class="b('other-upload')"
          :document="file"
          :is-upload="file.isUpload"
          :is-readonly="isReadonly"
          :is-loading="isLoading"
          :isCenter="true"
          :is-label="false"
          @upload="handleUploadAdditionalFile"
        />
      </div>
    </div>

    <UIButton
      :class="b('continue')"
      label="Продолжить"
      view="main"
      :disabled="isContinueDisabled"
      @click="handleContinue"
    />
  </SharedCard>
</template>
