<script lang="ts" src="./component.ts"></script>
<style lang="scss" src="./component.scss"></style>

<template>
  <div :class="b()">
    <h2 :class="b('title')">КУС/КУВ</h2>

    <div :class="b('wrapper')">
      <div v-if="isKyc" :class="b('block')">
        <div :class="b('group')">
          <h4 :class="b('group-title')">
            {{ $t("Паспорт или ВУ") }}
          </h4>

          <div :class="b('fields', { passport: true })">
            <SharedUpload
              :document="kycDocuments['kyc-personal-documents-passport-selfie']"
              :is-readonly="true"
              :is-download="true"
              :is-loading="isLoading"
            >
              <template #label>
                {{ $t("Страница") }} <br />
                {{ $t("с фото") }}
              </template>
            </SharedUpload>

            <SharedUpload
              :document="
                kycDocuments['kyc-personal-documents-passport-organization']
              "
              :is-readonly="true"
              :is-download="true"
              :is-loading="isLoading"
            >
              <template #label>
                {{ $t("Страница") }} <br />
                {{ $t("кем выдан") }}
              </template>
            </SharedUpload>

            <SharedUpload
              :document="
                kycDocuments['kyc-personal-documents-passport-address']
              "
              :is-readonly="true"
              :is-download="true"
              :is-loading="isLoading"
            >
              <template #label>
                {{ $t("Страница") }} <br />
                {{ $t("с пропиской") }}
              </template>
            </SharedUpload>
          </div>
        </div>

        <div :class="b('group')">
          <h4 :class="b('group-title')">
            {{ $t("Селфи") }}
          </h4>

          <div :class="b('fields', { selfie: true })">
            <SharedUpload
              :document="kycDocuments['kyc-personal-documents-selfie']"
              :is-readonly="true"
              :isCenter="true"
              :is-label="false"
              :is-download="true"
              :is-loading="isLoading"
            />
          </div>
        </div>
      </div>

      <div v-if="isKyb" :class="b('group')">
        <h4 :class="b('group-title')">
          {{ $t("Соглашения") }}
        </h4>

        <div :class="b('fields', { agreements: true })">
          <SharedUpload
            :class="b('upload')"
            :document="kybDocuments['kyb-documents-document']"
            :is-readonly="true"
            :is-download="true"
            :is-loading="isLoading"
          >
            <template #label>
              {{ $t("Анкета") }} <br />
              {{ $t("юридического лица") }}
            </template>
          </SharedUpload>

          <SharedUpload
            :class="b('upload')"
            :document="kybDocuments['kyb-documents-beneficiary']"
            :is-readonly="true"
            :is-download="true"
            :is-loading="isLoading"
          >
            <template #label>
              {{ $t("Анкета") }} <br />
              {{ $t("бенефициарного") }} <br />
              {{ $t("владельца") }}
            </template>
          </SharedUpload>

          <SharedUpload
            :class="b('upload')"
            :document="kybDocuments['kyb-documents-broker']"
            :is-readonly="true"
            :is-download="true"
            :is-loading="isLoading"
          >
            <template #label>
              {{ $t("Брокерский") }} <br />
              {{ $t("договор") }}
            </template>
          </SharedUpload>

          <SharedUpload
            :class="b('upload')"
            :document="kybDocuments['kyb-documents-declaration']"
            :is-readonly="true"
            :is-download="true"
            :is-loading="isLoading"
          >
            <template #label>
              {{ $t("Декларация") }} <br />
              {{ $t("о рисках") }}
            </template>
          </SharedUpload>
        </div>
      </div>
    </div>
  </div>
</template>
