<script lang="ts" src="./component.ts"></script>
<style lang="scss" src="./component.scss"></style>

<template>
  <div :class="b()">
    <h5 :class="b('title')" v-if="title">
      {{ $t(title) }}
    </h5>

    <div :class="b('fields')">
      <input
        :class="b('field', { error: isError })"
        type="number"
        v-for="field in displayedFields"
        :key="field.id"
        :value="field.value"
        :ref="field.key"
        @input="changeField($event, field)"
        @keydown="handleBackspace($event, field)"
      />
    </div>

    <p :class="b('label', { error: true })" v-if="isError">
      {{ $t("Вы ввели неверный код!") }}
    </p>

    <p :class="b('label')" v-if="isLabel">
      {{ $t("Введите 6 цифр из сообщения на email") }}
    </p>
  </div>
</template>
