export interface SharedPasswordValidationOption {
  id: number;
  label: SharedPasswordValidationOptionLabel;
  isValidate: boolean;
}

export enum SharedPasswordValidationOptionLabel {
  containNumbers = "содержать числа",
  containSpecialCharacters = "содержать специальные символы",
  charactersLong = "длина должна быть не менее 8 символов",
  match = "совпадение с подтверждением пароля",
}
