<script lang="ts" src="./component.ts"></script>
<style lang="scss" src="./component.scss"></style>

<template>
  <div :class="b()">
    <AdminConfigCommissionField title="Внести изменения">
      <SharedSelect
        view="light"
        :options="displayedOptions"
        :value="type"
        @update:value="handleType"
      />
    </AdminConfigCommissionField>

    <AdminConfigCommissionField v-if="isUserIdShow" title="Поиск по ID">
      <SharedSelect
        view="light"
        placeholder="Выберите пользователя"
        :options="displayedUserIdOptions"
        :value="userId"
        @update:value="handleUserId"
      />
    </AdminConfigCommissionField>
  </div>
</template>
