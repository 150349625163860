<script lang="ts" src="./component.ts"></script>
<style lang="scss" src="./component.scss"></style>

<template>
  <div :class="b()">
    <SharedAdminHeader
      title="KYC"
      :is-loading="isLoading"
      v-model:id="adminKycHeader.id"
      v-model:type="adminKycHeader.type"
      @search="handleSearch"
    />

    <AdminKycWrapper
      v-if="!!isWrapperShow"
      :email="adminUserAppResponse.user.email"
      :id="adminUserAppResponse.index"
      :type="adminUserAppResponse.type"
    >
      <component
        :is="displayedComponent"
        :id="adminUserAppResponse.user_id"
        v-model:component="component"
      />
    </AdminKycWrapper>

    <component
      v-if="isFooterShow"
      :is="displayedFooterComponent"
      :id="adminUserAppResponse.user_id"
      v-model:component="component"
      v-model:is-loading="isLoading"
      v-model:status="adminUserAppResponse.status"
      v-model:type="adminUserAppResponse.type"
    />
  </div>
</template>
