import { defineComponent, PropType } from "vue";

import { COMPONENT_NAME } from "./attributes";

import {
  SharedCard,
  SharedBack,
  SharedDate,
  SharedRadio,
  SharedCheckbox,
  SharedInputDouble,
  SharedCountry,
  SharedValidate,
} from "@/components/shared";
import { UIInput, UIButton } from "@/components/ui";

import { VerificationKybComponent } from "@/views/verification/model";
import {
  KybParticipantFieldsFactory,
  KybParticipantRequestFactory,
  KybParticipantResponseFactory,
} from "@/shared/repository/modules/kyb/factory";

export default defineComponent({
  name: COMPONENT_NAME,
  components: {
    SharedCard,
    SharedBack,
    SharedDate,
    SharedRadio,
    SharedCheckbox,
    SharedInputDouble,
    SharedCountry,
    SharedValidate,
    UIInput,
    UIButton,
  },
  props: {
    component: {
      type: <PropType<VerificationKybComponent>>String,
      default: "",
    },
  },
  emits: {
    "update:component": null,
  },
  data() {
    return {
      isValidate: false,
      isLoading: false,
      userRepo: this.$projectServices.userRepo,
      kybRepo: this.$projectServices.kybRepo,
      kybRepository: this.$projectServices.kybRepository,
      kybParticipant: KybParticipantResponseFactory(),
      kybParticipantFields: KybParticipantFieldsFactory(),
    };
  },
  async created(): Promise<void> {
    try {
      this.isLoading = true;

      if (this.kybRepo.participantId) {
        this.kybParticipant = await this.kybRepository.participantById(
          this.kybRepo.participantId
        );
      }
    } catch (error) {
      console.log(error);
    } finally {
      this.isLoading = false;
    }
  },
  computed: {
    isReadonly(): boolean {
      return this.userRepo.isReadonlyKyb;
    },

    isDisabled(): boolean {
      const { sur_name, name, citizenship, birth_date, gender, address } =
        this.kybParticipant;

      return (
        !sur_name ||
        !name ||
        !citizenship ||
        !birth_date ||
        !gender ||
        !address.country ||
        !address.city ||
        !address.state ||
        !address.street ||
        !address.office ||
        !address.zip
      );
    },

    isContinueDisabled(): boolean {
      return this.isLoading;
    },
  },
  methods: {
    handleBack(): void {
      this.kybRepo.UPDATE_PARTICIPANT_ID(null);

      this.$emit("update:component", VerificationKybComponent.kybParticipants);
    },

    async handleContinue(): Promise<void> {
      try {
        this.isLoading = true;

        if (this.isDisabled) {
          this.isValidate = true;

          return;
        }

        if (this.kybRepo.participantId) {
          await this.kybRepository.participantUpdate(
            this.kybRepo.participantId,
            KybParticipantRequestFactory(this.kybParticipant)
          );
        } else {
          const { id } = await this.kybRepository.participant(
            KybParticipantRequestFactory(this.kybParticipant)
          );

          this.kybRepo.UPDATE_PARTICIPANT_ID(id);
        }

        this.$emit(
          "update:component",
          VerificationKybComponent.kybParticipantFiles
        );
      } catch (e) {
        console.log(e);
      } finally {
        this.isLoading = false;
      }
    },
  },
});
