import { SharedSelectOption } from "@/components/shared/select/model";

export const SelectOptionFactory = (
  params: Partial<SharedSelectOption> = {},
  selectedValue: string | number
): SharedSelectOption => {
  return {
    id: params.id ? params.id : 0,
    label: params.label ? params.label : "",
    value: params.value ? params.value : "",
    icon: params.icon ? params.icon : "",
    isActive: params.value ? params.value === selectedValue : false,
  };
};
