import { AdminDepositWithdrawalStatus } from "./enums";
import {
  AdminDepositWithdrawalListResponse,
  AdminDepositWithdrawalShowResponse,
  AdminDepositWithdrawalUpdateRequest,
} from "./repo";

import { AdminApplicationType } from "../application/enums";
import { AdminCurrencyType } from "../currency/enums";
import { AdminRequisitesType } from "../requisites/enums";

import { UserResponseFactory } from "../../user/factory";
import { AdminCurrencyShowResponseFactory } from "../currency/factory";

export const AdminDepositWithdrawalUpdateRequestFactory = (
  payload: Partial<AdminDepositWithdrawalUpdateRequest> = {}
): AdminDepositWithdrawalUpdateRequest => {
  return {
    id: payload.id ?? 0,
    stage: payload.stage ?? "",
    currency_id: payload.currency_id ?? 0,
    amount: payload.amount ?? 0,
    execution_price: payload.execution_price ?? 0,
    transaction_volume: payload.transaction_volume ?? 0,
  };
};

export const AdminDepositWithdrawalShowResponseFactory = (
  payload: Partial<AdminDepositWithdrawalShowResponse> = {}
): AdminDepositWithdrawalShowResponse => {
  return {
    id: payload.id ?? 0,
    user_id: payload.user_id ?? 0,
    app_type: payload.app_type ?? AdminApplicationType.deposit,
    currency_type: payload.currency_type ?? AdminCurrencyType.fiat,
    currency_id: payload.currency_id ?? 0,
    currency: AdminCurrencyShowResponseFactory(payload.currency ?? {}),
    user_requisite_id: payload.user_requisite_id ?? 0,
    user_requisite_type:
      payload.user_requisite_type ?? AdminRequisitesType.bank,
    amount: Number(payload.amount ?? 0),
    execution_price: payload.execution_price ?? 0,
    transaction_volume: payload.transaction_volume ?? 0,
    commissions: Number(payload.commissions ?? 0),
    status: payload.status ?? AdminDepositWithdrawalStatus.in_work,
    stage: payload.stage ?? "",
    estimate_completion: payload.estimate_completion ?? "",
    deleted_at: payload.deleted_at ?? "",
    created_at: payload.created_at ?? "",
    updated_at: payload.updated_at ?? "",
  };
};

export const AdminDepositWithdrawalListResponseFactory = (
  payload: Partial<AdminDepositWithdrawalListResponse> = {}
): AdminDepositWithdrawalListResponse => {
  return {
    id: payload.id ?? 0,
    user_id: payload.user_id ?? 0,
    app_type: payload.app_type ?? AdminApplicationType.deposit,
    currency_type: payload.currency_type ?? AdminCurrencyType.fiat,
    currency_id: payload.currency_id ?? 0,
    currency: AdminCurrencyShowResponseFactory(payload.currency ?? {}),
    user_requisite_id: payload.user_requisite_id ?? 0,
    user_requisite_type:
      payload.user_requisite_type ?? AdminRequisitesType.bank,
    amount: Number(payload.amount ?? 0),
    execution_price: payload.execution_price ?? 0,
    transaction_volume: payload.transaction_volume ?? 0,
    commissions: Number(payload.commissions ?? 0),
    status: payload.status ?? AdminDepositWithdrawalStatus.in_work,
    stage: payload.stage ?? "",
    estimate_completion: payload.estimate_completion ?? "",
    deleted_at: payload.deleted_at ?? "",
    created_at: payload.created_at ?? "",
    updated_at: payload.updated_at ?? "",
    user: UserResponseFactory(payload.user ?? {}),
  };
};
