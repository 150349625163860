<script lang="ts" src="./component.ts"></script>
<style lang="scss" src="./component.scss"></style>

<template>
  <div :class="b()">
    <div :class="b('fields')">
      <div :class="b('field')">
        <p :class="b('field-label')">
          {{ $t("Выбрерите шаблон реквизитов") }}
        </p>

        <SharedSelect
          view="light"
          :options="displayedRequisiteOptions"
          v-model:value="depositWithdrawalStore.user_requisite_id"
        />
      </div>

      <div :class="b('fields-group')">
        <div :class="b('field')">
          <p :class="b('field-label')">
            {{ $t("Валюта") }}
          </p>

          <SharedSelect
            view="light"
            :options="displayedCurrencyOptions"
            :icon="displayedCurrencyIcon"
            v-model:value="depositWithdrawalStore.currency_id"
          />
        </div>

        <div :class="b('field')">
          <p :class="b('field-label')">
            {{ $t("Сумма") }}
          </p>

          <UIInput
            view="light"
            type="number"
            :is-number="true"
            v-model="depositWithdrawalStore.amount"
          />
        </div>
      </div>
    </div>

    <UIButton
      label="Создать заявку"
      view="main"
      :disabled="isDisabled"
      @click="handleCreate"
    />
  </div>
</template>
