import { defineComponent, PropType } from "vue";

import { COMPONENT_NAME } from "./attributes";
import { AdminCurrencyShowResponseFactory } from "@/shared/repository/modules/admin/currency/factory";
import { AdminCurrencyShowResponse } from "@/shared/repository/modules/admin/currency/repo";

export default defineComponent({
  name: COMPONENT_NAME,
  props: {
    placeholder: {
      type: String,
      required: true,
    },
    value: {
      type: Number,
      required: true,
    },
    currency: {
      type: <PropType<AdminCurrencyShowResponse>>Object,
      default: AdminCurrencyShowResponseFactory(),
    },
  },
  emits: {
    "update:value": null,
  },
  data() {
    return {
      isVisible: false,
    };
  },
  methods: {
    handleInput(event: Event): void {
      const { value } = <HTMLInputElement>event.target;

      this.$emit("update:value", Number(value));
    },
  },
});
