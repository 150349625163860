import { defineComponent } from "vue";

import { COMPONENT_NAME } from "./attributes";
import { AdminConfigTabValue } from "./model";

import {
  AdminConfigTabs,
  AdminConfigCommission,
  AdminConfigCurrency,
  AdminConfigRequisites,
} from "@/components/pages/admin-config";

type Component =
  | typeof AdminConfigCommission
  | typeof AdminConfigCurrency
  | typeof AdminConfigRequisites;

export default defineComponent({
  name: COMPONENT_NAME,
  components: {
    AdminConfigTabs,
    AdminConfigCommission,
    AdminConfigCurrency,
    AdminConfigRequisites,
  },
  data() {
    return {
      tab: AdminConfigTabValue.commission,
      adminCommissionRepository:
        this.$projectServices.adminCommissionRepository,
    };
  },
  computed: {
    displayedComponent(): Component {
      switch (this.tab) {
        case AdminConfigTabValue.commission:
          return AdminConfigCommission;
        case AdminConfigTabValue.requisites:
          return AdminConfigRequisites;
        case AdminConfigTabValue.currency:
          return AdminConfigCurrency;
        default:
          return AdminConfigCommission;
      }
    },
  },
});
