import { toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_SharedVerification = _resolveComponent("SharedVerification")!
  const _component_UIButton = _resolveComponent("UIButton")!
  const _component_SharedWindow = _resolveComponent("SharedWindow")!

  return (_openBlock(), _createBlock(_component_SharedWindow, {
    class: _normalizeClass(_ctx.b()),
    title: "Подтверждение реквизитов через email",
    "is-big": true,
    onClose: _ctx.handleClose
  }, {
    default: _withCtx(() => [
      _createElementVNode("div", {
        class: _normalizeClass(_ctx.b('wrapper'))
      }, [
        _createElementVNode("p", {
          class: _normalizeClass(_ctx.b('label'))
        }, _toDisplayString(_ctx.$t("Код-подтверждение отправлен на email")), 3),
        _createVNode(_component_SharedVerification, {
          class: _normalizeClass(_ctx.b('verification')),
          label: "Введите 6 цифр из сообщения на email",
          code: _ctx.code,
          "onUpdate:code": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.code) = $event)),
          "is-error": _ctx.isError
        }, null, 8, ["class", "code", "is-error"]),
        _createVNode(_component_UIButton, {
          label: "Подтвердить",
          view: "main",
          disabled: _ctx.isConfirmDisabled,
          onClick: _ctx.handleVerification
        }, null, 8, ["disabled", "onClick"])
      ], 2)
    ]),
    _: 1
  }, 8, ["class", "onClose"]))
}