<script lang="ts" src="./component.ts"></script>
<style lang="scss" src="./component.scss"></style>

<template>
  <div :class="b()">
    <h1 :class="b('title')">
      {{ $t("Верификация") }}
    </h1>

    <div :class="b('content')">
      <VerificationTabs :class="b('tabs')" :tab="tab" @tab="handleTab" />

      <component
        v-if="isKycShow"
        :is="displayedKycComponent"
        v-model:component="kycComponent"
      />

      <component
        v-if="isKybShow"
        :is="displayedKybComponent"
        v-model:component="kybComponent"
      />

      <transition name="opacity">
        <VerificationModal
          v-if="isModal"
          :class="b('modal')"
          v-model:tab="tab"
          v-model:isModal="isModal"
          v-model:kycComponent="kycComponent"
          v-model:kybComponent="kybComponent"
        />
      </transition>
    </div>
  </div>
</template>
