import { normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, createBlock as _createBlock, resolveDirective as _resolveDirective, withDirectives as _withDirectives, createCommentVNode as _createCommentVNode, Transition as _Transition } from "vue"

const _hoisted_1 = ["innerHTML"]
const _hoisted_2 = ["innerHTML"]
const _hoisted_3 = ["innerHTML"]
const _hoisted_4 = ["innerHTML"]
const _hoisted_5 = ["innerHTML"]
const _hoisted_6 = ["innerHTML"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_router_link = _resolveComponent("router-link")!
  const _component_SharedLanguage = _resolveComponent("SharedLanguage")!
  const _directive_click_outside = _resolveDirective("click-outside")!

  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(_ctx.b())
  }, [
    _createVNode(_component_router_link, {
      class: _normalizeClass(_ctx.b('logo', { disabled: !_ctx.isVerified })),
      to: "/"
    }, {
      default: _withCtx(() => [
        _createElementVNode("span", {
          class: _normalizeClass(_ctx.b('logo-icon')),
          innerHTML: _ctx.displayedIcons.logo
        }, null, 10, _hoisted_1)
      ]),
      _: 1
    }, 8, ["class"]),
    _createElementVNode("div", {
      class: _normalizeClass(_ctx.b('links'))
    }, [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.displayedLinks, (link) => {
        return (_openBlock(), _createBlock(_component_router_link, {
          class: _normalizeClass(_ctx.b('link', { disabled: link.isDisabled })),
          key: link.id,
          to: link.to
        }, {
          default: _withCtx(() => [
            _createElementVNode("span", {
              class: _normalizeClass(_ctx.b('link-icon')),
              innerHTML: link.icon
            }, null, 10, _hoisted_2),
            _createTextVNode(" " + _toDisplayString(_ctx.$t(link.label)), 1)
          ]),
          _: 2
        }, 1032, ["class", "to"]))
      }), 128))
    ], 2),
    _createElementVNode("button", {
      class: _normalizeClass(_ctx.b('burger')),
      onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.handleBurger && _ctx.handleBurger(...args)))
    }, [
      _createElementVNode("span", {
        class: _normalizeClass(_ctx.b('burger-icon')),
        innerHTML: _ctx.displayedIcons.burger
      }, null, 10, _hoisted_3)
    ], 2),
    _createVNode(_Transition, { name: "opacity" }, {
      default: _withCtx(() => [
        (_ctx.isMenu)
          ? (_openBlock(), _createElementBlock("div", {
              key: 0,
              class: _normalizeClass(_ctx.b('menu'))
            }, [
              _withDirectives((_openBlock(), _createElementBlock("div", {
                class: _normalizeClass(_ctx.b('menu-wrapper'))
              }, [
                _createElementVNode("button", {
                  class: _normalizeClass(_ctx.b('menu-close')),
                  onClick: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_ctx.handleClose && _ctx.handleClose(...args)))
                }, [
                  _createElementVNode("span", {
                    class: _normalizeClass(_ctx.b('menu-close-icon')),
                    innerHTML: _ctx.displayedIcons.close
                  }, null, 10, _hoisted_4)
                ], 2),
                _createElementVNode("div", {
                  class: _normalizeClass(_ctx.b('menu-profile'))
                }, [
                  _createElementVNode("span", {
                    class: _normalizeClass(_ctx.b('menu-profile-icon')),
                    innerHTML: _ctx.displayedIcons.profile
                  }, null, 10, _hoisted_5),
                  _createElementVNode("h4", {
                    class: _normalizeClass(_ctx.b('menu-profile-title'))
                  }, _toDisplayString(_ctx.userRepo.userInfo.email), 3)
                ], 2),
                _createElementVNode("div", {
                  class: _normalizeClass(_ctx.b('menu-language'))
                }, [
                  _createElementVNode("p", {
                    class: _normalizeClass(_ctx.b('menu-language-label'))
                  }, _toDisplayString(_ctx.$t("Язык")), 3),
                  _createVNode(_component_SharedLanguage, {
                    view: "default",
                    "is-chevron": true
                  })
                ], 2),
                _createElementVNode("div", {
                  class: _normalizeClass(_ctx.b('menu-logout'))
                }, [
                  _createElementVNode("p", {
                    class: _normalizeClass(_ctx.b('menu-logout-label'))
                  }, _toDisplayString(_ctx.$t("Выйти из системы")), 3),
                  _createElementVNode("button", {
                    class: _normalizeClass(_ctx.b('menu-logout-action')),
                    onClick: _cache[2] || (_cache[2] = 
//@ts-ignore
(...args) => (_ctx.handleLogout && _ctx.handleLogout(...args)))
                  }, [
                    _createElementVNode("span", {
                      class: _normalizeClass(_ctx.b('menu-logout-icon')),
                      innerHTML: _ctx.displayedIcons.logout
                    }, null, 10, _hoisted_6)
                  ], 2)
                ], 2)
              ], 2)), [
                [_directive_click_outside, _ctx.handleClose]
              ])
            ], 2))
          : _createCommentVNode("", true)
      ]),
      _: 1
    })
  ], 2))
}