import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createBlock as _createBlock } from "vue"

const _hoisted_1 = ["innerHTML"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_router_link = _resolveComponent("router-link")!

  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(_ctx.b())
  }, [
    _createElementVNode("div", {
      class: _normalizeClass(_ctx.b('links'))
    }, [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.displayedLinks, (link) => {
        return (_openBlock(), _createBlock(_component_router_link, {
          class: _normalizeClass(_ctx.b('link', { disabled: link.isDisabled })),
          key: link.id,
          to: link.to
        }, {
          default: _withCtx(() => [
            _createElementVNode("span", {
              class: _normalizeClass(_ctx.b('link-icon')),
              innerHTML: link.icon
            }, null, 10, _hoisted_1),
            _createTextVNode(" " + _toDisplayString(_ctx.$t(link.label)), 1)
          ]),
          _: 2
        }, 1032, ["class", "to"]))
      }), 128))
    ], 2)
  ], 2))
}