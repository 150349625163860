<script lang="ts" src="./component.ts"></script>
<style lang="scss" src="./component.scss"></style>

<template>
  <SharedCard :class="b()">
    <div :class="b('header')">
      <SharedBack @click="handleBack" />

      <h3 :class="b('header-title')">
        {{ $t("Участники организации") }}
      </h3>
    </div>

    <div :class="b('cards')">
      <div
        :class="b('card')"
        v-for="(participant, participantIndex) in displayedParticipants"
        :key="participantIndex"
      >
        <h5 v-if="!!participant.id" :class="b('card-title')">
          {{ $t("Участник") }} №{{ participantIndex + 1 }}
        </h5>

        <SharedAdd
          :is-success="participant.isSuccess"
          :is-expected="participant.isExpected"
          :is-readonly="isReadonly"
          :is-loading="isLoading"
          @click="handleParticipant(participant)"
          @remove="handleParticipantRemove(participant)"
        >
          <template #title>
            {{ participant.name }} <br v-if="participant.name" />
            {{ participant.patronymic }} <br v-if="participant.patronymic" />
            {{ participant.sur_name }}
          </template>

          <template #label>
            {{
              participant.isExpected
                ? $t("Прикрепите документы")
                : $t("Добавить")
            }}
          </template>
        </SharedAdd>
      </div>
    </div>

    <UIButton
      :class="b('continue')"
      label="Продолжить"
      view="main"
      :disabled="isContinueDisabled"
      @click="handleContinue"
    />
  </SharedCard>
</template>
