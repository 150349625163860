<script lang="ts" src="./component.ts"></script>
<style lang="scss" src="./component.scss"></style>

<template>
  <div :class="b()">
    <h1 :class="b('title')">
      {{ $t("Пользователи") }}
    </h1>

    <div :class="b('wrapper')">
      <AdminUsersFilter
        v-model:value="search.value"
        v-model:type="search.type"
      />

      <AdminUsersTable
        :class="b('table')"
        :search="search"
        :sort="sort"
        v-model:is-status="isStatus"
        v-model:is-balance="isBalance"
      />

      <SharedButtonIcon
        v-if="isMoreShow"
        :class="b('more')"
        view="gray-outline"
        @click="handleMore"
      >
        <template #icon-left>
          <span :class="b('more-icon')" v-html="displayedIcons.more"></span>
        </template>

        <template #label>
          {{ $t("Показать больше") }}
        </template>
      </SharedButtonIcon>
    </div>

    <transition name="opacity">
      <AdminUsersStatus
        v-if="isStatusShow"
        :class="b('modal')"
        v-model:isStatus="isStatus"
      />
    </transition>

    <transition name="opacity">
      <AdminUsersBalance
        v-if="isBalanceShow"
        :class="b('modal')"
        v-model:isBalance="isBalance"
      />
    </transition>
  </div>
</template>
