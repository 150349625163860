import { resolveComponent as _resolveComponent, normalizeClass as _normalizeClass, createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, withCtx as _withCtx, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_SharedBack = _resolveComponent("SharedBack")!
  const _component_UIInput = _resolveComponent("UIInput")!
  const _component_SharedValidate = _resolveComponent("SharedValidate")!
  const _component_SharedCountry = _resolveComponent("SharedCountry")!
  const _component_SharedRadio = _resolveComponent("SharedRadio")!
  const _component_UIButton = _resolveComponent("UIButton")!
  const _component_SharedCard = _resolveComponent("SharedCard")!

  return (_openBlock(), _createBlock(_component_SharedCard, {
    class: _normalizeClass(_ctx.b())
  }, {
    default: _withCtx(() => [
      _createElementVNode("div", {
        class: _normalizeClass(_ctx.b('header'))
      }, [
        _createVNode(_component_SharedBack, {
          class: _normalizeClass(_ctx.b('header-back')),
          onClick: _ctx.handleBack
        }, null, 8, ["class", "onClick"]),
        _createElementVNode("div", {
          class: _normalizeClass(_ctx.b('header-group'))
        }, [
          _createElementVNode("h3", {
            class: _normalizeClass(_ctx.b('header-title'))
          }, _toDisplayString(_ctx.$t("Регистрационная информация")), 3),
          _createElementVNode("p", {
            class: _normalizeClass(_ctx.b('header-label'))
          }, _toDisplayString(_ctx.$t(
              "Ответьте на следующие вопросы, чтобы получить список необходимых документов"
            )), 3)
        ], 2)
      ], 2),
      _createElementVNode("div", {
        class: _normalizeClass(_ctx.b('form'))
      }, [
        _createVNode(_component_SharedValidate, {
          field: "organisation_legal_form",
          value: _ctx.kybRegistrationInfo.organisation_legal_form,
          "is-validate": _ctx.isValidate,
          "field-value": _ctx.kybRegistrationInfoFields.organisation_legal_form,
          "onUpdate:fieldValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.kybRegistrationInfoFields.organisation_legal_form) = $event))
        }, {
          default: _withCtx(() => [
            _createElementVNode("div", {
              class: _normalizeClass(_ctx.b('form-group'))
            }, [
              _createElementVNode("h5", {
                class: _normalizeClass(_ctx.b('form-title'))
              }, _toDisplayString(_ctx.$t("Организационно-правовая форма вашей организации*")), 3),
              _createVNode(_component_UIInput, {
                view: "light",
                "is-readonly": _ctx.isReadonly,
                "is-error": 
              _ctx.isValidate && !_ctx.kybRegistrationInfoFields.organisation_legal_form
            ,
                modelValue: _ctx.kybRegistrationInfo.organisation_legal_form,
                "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.kybRegistrationInfo.organisation_legal_form) = $event))
              }, null, 8, ["is-readonly", "is-error", "modelValue"])
            ], 2)
          ]),
          _: 1
        }, 8, ["value", "is-validate", "field-value"]),
        _createVNode(_component_SharedValidate, {
          field: "business_area",
          value: _ctx.kybRegistrationInfo.business_area,
          "is-validate": _ctx.isValidate,
          "field-value": _ctx.kybRegistrationInfoFields.business_area,
          "onUpdate:fieldValue": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.kybRegistrationInfoFields.business_area) = $event))
        }, {
          default: _withCtx(() => [
            _createElementVNode("div", {
              class: _normalizeClass(_ctx.b('form-group'))
            }, [
              _createElementVNode("h5", {
                class: _normalizeClass(_ctx.b('form-title'))
              }, _toDisplayString(_ctx.$t("К какой сфере относится ваш бизнес?*")), 3),
              _createVNode(_component_UIInput, {
                view: "light",
                "is-readonly": _ctx.isReadonly,
                "is-error": _ctx.isValidate && !_ctx.kybRegistrationInfoFields.business_area,
                modelValue: _ctx.kybRegistrationInfo.business_area,
                "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.kybRegistrationInfo.business_area) = $event))
              }, null, 8, ["is-readonly", "is-error", "modelValue"])
            ], 2)
          ]),
          _: 1
        }, 8, ["value", "is-validate", "field-value"]),
        _createElementVNode("div", {
          class: _normalizeClass(_ctx.b('form-group', { address: true }))
        }, [
          _createElementVNode("h5", {
            class: _normalizeClass(_ctx.b('form-title'))
          }, _toDisplayString(_ctx.$t("Адрес организации*")), 3),
          _createElementVNode("div", {
            class: _normalizeClass(_ctx.b('form-block'))
          }, [
            _createVNode(_component_SharedValidate, {
              field: "address.country",
              value: _ctx.kybRegistrationInfo.address.country,
              "is-validate": _ctx.isValidate,
              "field-value": _ctx.kybRegistrationInfoFields['address.country'],
              "onUpdate:fieldValue": _cache[5] || (_cache[5] = ($event: any) => ((_ctx.kybRegistrationInfoFields['address.country']) = $event))
            }, {
              default: _withCtx(() => [
                _createVNode(_component_SharedCountry, {
                  class: _normalizeClass(_ctx.b('form-country')),
                  placeholder: "Cтрана",
                  "is-readonly": _ctx.isReadonly,
                  "is-error": 
                _ctx.isValidate && !_ctx.kybRegistrationInfoFields['address.country']
              ,
                  value: _ctx.kybRegistrationInfo.address.country,
                  "onUpdate:value": _cache[4] || (_cache[4] = ($event: any) => ((_ctx.kybRegistrationInfo.address.country) = $event))
                }, null, 8, ["class", "is-readonly", "is-error", "value"])
              ]),
              _: 1
            }, 8, ["value", "is-validate", "field-value"]),
            _createVNode(_component_SharedValidate, {
              field: "address.city",
              value: _ctx.kybRegistrationInfo.address.city,
              "is-validate": _ctx.isValidate,
              "field-value": _ctx.kybRegistrationInfoFields['address.city'],
              "onUpdate:fieldValue": _cache[7] || (_cache[7] = ($event: any) => ((_ctx.kybRegistrationInfoFields['address.city']) = $event))
            }, {
              default: _withCtx(() => [
                _createVNode(_component_UIInput, {
                  class: _normalizeClass(_ctx.b('form-city')),
                  placeholder: "Город",
                  view: "light",
                  "is-readonly": _ctx.isReadonly,
                  "is-error": 
                _ctx.isValidate && !_ctx.kybRegistrationInfoFields['address.city']
              ,
                  modelValue: _ctx.kybRegistrationInfo.address.city,
                  "onUpdate:modelValue": _cache[6] || (_cache[6] = ($event: any) => ((_ctx.kybRegistrationInfo.address.city) = $event))
                }, null, 8, ["class", "is-readonly", "is-error", "modelValue"])
              ]),
              _: 1
            }, 8, ["value", "is-validate", "field-value"]),
            _createVNode(_component_SharedValidate, {
              field: "address.state",
              value: _ctx.kybRegistrationInfo.address.state,
              "is-validate": _ctx.isValidate,
              "field-value": _ctx.kybRegistrationInfoFields['address.state'],
              "onUpdate:fieldValue": _cache[9] || (_cache[9] = ($event: any) => ((_ctx.kybRegistrationInfoFields['address.state']) = $event))
            }, {
              default: _withCtx(() => [
                _createVNode(_component_UIInput, {
                  class: _normalizeClass(_ctx.b('form-state')),
                  placeholder: "Штат / Область",
                  view: "light",
                  "is-readonly": _ctx.isReadonly,
                  "is-error": 
                _ctx.isValidate && !_ctx.kybRegistrationInfoFields['address.state']
              ,
                  modelValue: _ctx.kybRegistrationInfo.address.state,
                  "onUpdate:modelValue": _cache[8] || (_cache[8] = ($event: any) => ((_ctx.kybRegistrationInfo.address.state) = $event))
                }, null, 8, ["class", "is-readonly", "is-error", "modelValue"])
              ]),
              _: 1
            }, 8, ["value", "is-validate", "field-value"]),
            _createVNode(_component_SharedValidate, {
              field: "address.street",
              value: _ctx.kybRegistrationInfo.address.street,
              "is-validate": _ctx.isValidate,
              "field-value": _ctx.kybRegistrationInfoFields['address.street'],
              "onUpdate:fieldValue": _cache[11] || (_cache[11] = ($event: any) => ((_ctx.kybRegistrationInfoFields['address.street']) = $event))
            }, {
              default: _withCtx(() => [
                _createVNode(_component_UIInput, {
                  class: _normalizeClass(_ctx.b('form-street')),
                  placeholder: "Улица",
                  view: "light",
                  "is-readonly": _ctx.isReadonly,
                  "is-error": 
                _ctx.isValidate && !_ctx.kybRegistrationInfoFields['address.street']
              ,
                  modelValue: _ctx.kybRegistrationInfo.address.street,
                  "onUpdate:modelValue": _cache[10] || (_cache[10] = ($event: any) => ((_ctx.kybRegistrationInfo.address.street) = $event))
                }, null, 8, ["class", "is-readonly", "is-error", "modelValue"])
              ]),
              _: 1
            }, 8, ["value", "is-validate", "field-value"]),
            _createVNode(_component_SharedValidate, {
              field: "address.office",
              value: _ctx.kybRegistrationInfo.address.office,
              "is-validate": _ctx.isValidate,
              "field-value": _ctx.kybRegistrationInfoFields['address.office'],
              "onUpdate:fieldValue": _cache[13] || (_cache[13] = ($event: any) => ((_ctx.kybRegistrationInfoFields['address.office']) = $event))
            }, {
              default: _withCtx(() => [
                _createVNode(_component_UIInput, {
                  class: _normalizeClass(_ctx.b('form-office')),
                  placeholder: "Номер Офиса",
                  view: "light",
                  "is-readonly": _ctx.isReadonly,
                  "is-error": 
                _ctx.isValidate && !_ctx.kybRegistrationInfoFields['address.office']
              ,
                  modelValue: _ctx.kybRegistrationInfo.address.office,
                  "onUpdate:modelValue": _cache[12] || (_cache[12] = ($event: any) => ((_ctx.kybRegistrationInfo.address.office) = $event))
                }, null, 8, ["class", "is-readonly", "is-error", "modelValue"])
              ]),
              _: 1
            }, 8, ["value", "is-validate", "field-value"]),
            _createVNode(_component_SharedValidate, {
              field: "address.zip",
              value: _ctx.kybRegistrationInfo.address.zip,
              "is-validate": _ctx.isValidate,
              "field-value": _ctx.kybRegistrationInfoFields['address.zip'],
              "onUpdate:fieldValue": _cache[15] || (_cache[15] = ($event: any) => ((_ctx.kybRegistrationInfoFields['address.zip']) = $event))
            }, {
              default: _withCtx(() => [
                _createVNode(_component_UIInput, {
                  class: _normalizeClass(_ctx.b('form-index')),
                  placeholder: "Индекс",
                  view: "light",
                  "is-readonly": _ctx.isReadonly,
                  "is-error": 
                _ctx.isValidate && !_ctx.kybRegistrationInfoFields['address.zip']
              ,
                  modelValue: _ctx.kybRegistrationInfo.address.zip,
                  "onUpdate:modelValue": _cache[14] || (_cache[14] = ($event: any) => ((_ctx.kybRegistrationInfo.address.zip) = $event))
                }, null, 8, ["class", "is-readonly", "is-error", "modelValue"])
              ]),
              _: 1
            }, 8, ["value", "is-validate", "field-value"])
          ], 2)
        ], 2),
        _createElementVNode("div", {
          class: _normalizeClass(_ctx.b('form-group'))
        }, [
          _createElementVNode("h5", {
            class: _normalizeClass(_ctx.b('form-title'))
          }, _toDisplayString(_ctx.$t("Фактический адрес совпадает с адресом регистрации?*")), 3),
          _createVNode(_component_SharedValidate, {
            field: "address.isActual",
            value: _ctx.kybRegistrationInfo.address.isActual,
            "is-validate": _ctx.isValidate,
            "field-value": _ctx.kybRegistrationInfoFields['address.isActual'],
            "onUpdate:fieldValue": _cache[18] || (_cache[18] = ($event: any) => ((_ctx.kybRegistrationInfoFields['address.isActual']) = $event))
          }, {
            default: _withCtx(() => [
              _createElementVNode("div", {
                class: _normalizeClass(_ctx.b('form-checkboxes'))
              }, [
                _createElementVNode("div", {
                  class: _normalizeClass(_ctx.b('form-checkbox'))
                }, [
                  _createVNode(_component_SharedRadio, {
                    view: "light",
                    checkedValue: true,
                    "is-readonly": _ctx.isReadonly,
                    value: _ctx.kybRegistrationInfo.address.isActual,
                    "onUpdate:value": _cache[16] || (_cache[16] = ($event: any) => ((_ctx.kybRegistrationInfo.address.isActual) = $event))
                  }, null, 8, ["is-readonly", "value"]),
                  _createElementVNode("p", {
                    class: _normalizeClass(_ctx.b('form-checkbox-label'))
                  }, _toDisplayString(_ctx.$t("Да")), 3)
                ], 2),
                _createElementVNode("div", {
                  class: _normalizeClass(_ctx.b('form-checkbox'))
                }, [
                  _createVNode(_component_SharedRadio, {
                    view: "light",
                    checkedValue: false,
                    "is-readonly": _ctx.isReadonly,
                    value: _ctx.kybRegistrationInfo.address.isActual,
                    "onUpdate:value": _cache[17] || (_cache[17] = ($event: any) => ((_ctx.kybRegistrationInfo.address.isActual) = $event))
                  }, null, 8, ["is-readonly", "value"]),
                  _createElementVNode("p", {
                    class: _normalizeClass(_ctx.b('form-checkbox-label'))
                  }, _toDisplayString(_ctx.$t("Нет")), 3)
                ], 2)
              ], 2)
            ]),
            _: 1
          }, 8, ["value", "is-validate", "field-value"])
        ], 2),
        (!_ctx.kybRegistrationInfo.address.isActual)
          ? (_openBlock(), _createElementBlock("div", {
              key: 0,
              class: _normalizeClass(_ctx.b('form-group', { address: true }))
            }, [
              _createElementVNode("h5", {
                class: _normalizeClass(_ctx.b('form-title'))
              }, _toDisplayString(_ctx.$t("Фактический адрес организации*")), 3),
              _createElementVNode("div", {
                class: _normalizeClass(_ctx.b('form-block'))
              }, [
                _createVNode(_component_SharedValidate, {
                  field: "address.country",
                  value: _ctx.kybRegistrationInfo.address.country_actual,
                  "is-validate": _ctx.isValidate,
                  "field-value": 
              _ctx.kybRegistrationInfoFields['address.country_actual']
            ,
                  "onUpdate:fieldValue": _cache[20] || (_cache[20] = ($event: any) => ((
              _ctx.kybRegistrationInfoFields['address.country_actual']
            ) = $event))
                }, {
                  default: _withCtx(() => [
                    _createVNode(_component_SharedCountry, {
                      class: _normalizeClass(_ctx.b('form-country')),
                      placeholder: "Cтрана",
                      "is-readonly": _ctx.isReadonly,
                      "is-error": 
                _ctx.isValidate &&
                !_ctx.kybRegistrationInfoFields['address.country_actual']
              ,
                      value: _ctx.kybRegistrationInfo.address.country_actual,
                      "onUpdate:value": _cache[19] || (_cache[19] = ($event: any) => ((_ctx.kybRegistrationInfo.address.country_actual) = $event))
                    }, null, 8, ["class", "is-readonly", "is-error", "value"])
                  ]),
                  _: 1
                }, 8, ["value", "is-validate", "field-value"]),
                _createVNode(_component_SharedValidate, {
                  field: "address.city",
                  value: _ctx.kybRegistrationInfo.address.city_actual,
                  "is-validate": _ctx.isValidate,
                  "field-value": 
              _ctx.kybRegistrationInfoFields['address.city_actual']
            ,
                  "onUpdate:fieldValue": _cache[22] || (_cache[22] = ($event: any) => ((
              _ctx.kybRegistrationInfoFields['address.city_actual']
            ) = $event))
                }, {
                  default: _withCtx(() => [
                    _createVNode(_component_UIInput, {
                      class: _normalizeClass(_ctx.b('form-city')),
                      placeholder: "Город",
                      view: "light",
                      "is-readonly": _ctx.isReadonly,
                      "is-error": 
                _ctx.isValidate && !_ctx.kybRegistrationInfoFields['address.city_actual']
              ,
                      modelValue: _ctx.kybRegistrationInfo.address.city_actual,
                      "onUpdate:modelValue": _cache[21] || (_cache[21] = ($event: any) => ((_ctx.kybRegistrationInfo.address.city_actual) = $event))
                    }, null, 8, ["class", "is-readonly", "is-error", "modelValue"])
                  ]),
                  _: 1
                }, 8, ["value", "is-validate", "field-value"]),
                _createVNode(_component_SharedValidate, {
                  field: "address.state",
                  value: _ctx.kybRegistrationInfo.address.state_actual,
                  "is-validate": _ctx.isValidate,
                  "field-value": 
              _ctx.kybRegistrationInfoFields['address.state_actual']
            ,
                  "onUpdate:fieldValue": _cache[24] || (_cache[24] = ($event: any) => ((
              _ctx.kybRegistrationInfoFields['address.state_actual']
            ) = $event))
                }, {
                  default: _withCtx(() => [
                    _createVNode(_component_UIInput, {
                      class: _normalizeClass(_ctx.b('form-state')),
                      placeholder: "Штат / Область",
                      view: "light",
                      "is-readonly": _ctx.isReadonly,
                      "is-error": 
                _ctx.isValidate && !_ctx.kybRegistrationInfoFields['address.state_actual']
              ,
                      modelValue: _ctx.kybRegistrationInfo.address.state_actual,
                      "onUpdate:modelValue": _cache[23] || (_cache[23] = ($event: any) => ((_ctx.kybRegistrationInfo.address.state_actual) = $event))
                    }, null, 8, ["class", "is-readonly", "is-error", "modelValue"])
                  ]),
                  _: 1
                }, 8, ["value", "is-validate", "field-value"]),
                _createVNode(_component_SharedValidate, {
                  field: "address.street",
                  value: _ctx.kybRegistrationInfo.address.street_actual,
                  "is-validate": _ctx.isValidate,
                  "field-value": 
              _ctx.kybRegistrationInfoFields['address.street_actual']
            ,
                  "onUpdate:fieldValue": _cache[26] || (_cache[26] = ($event: any) => ((
              _ctx.kybRegistrationInfoFields['address.street_actual']
            ) = $event))
                }, {
                  default: _withCtx(() => [
                    _createVNode(_component_UIInput, {
                      class: _normalizeClass(_ctx.b('form-street')),
                      placeholder: "Улица",
                      view: "light",
                      "is-readonly": _ctx.isReadonly,
                      "is-error": 
                _ctx.isValidate &&
                !_ctx.kybRegistrationInfoFields['address.street_actual']
              ,
                      modelValue: _ctx.kybRegistrationInfo.address.street_actual,
                      "onUpdate:modelValue": _cache[25] || (_cache[25] = ($event: any) => ((_ctx.kybRegistrationInfo.address.street_actual) = $event))
                    }, null, 8, ["class", "is-readonly", "is-error", "modelValue"])
                  ]),
                  _: 1
                }, 8, ["value", "is-validate", "field-value"]),
                _createVNode(_component_SharedValidate, {
                  field: "address.office",
                  value: _ctx.kybRegistrationInfo.address.office_actual,
                  "is-validate": _ctx.isValidate,
                  "field-value": 
              _ctx.kybRegistrationInfoFields['address.office_actual']
            ,
                  "onUpdate:fieldValue": _cache[28] || (_cache[28] = ($event: any) => ((
              _ctx.kybRegistrationInfoFields['address.office_actual']
            ) = $event))
                }, {
                  default: _withCtx(() => [
                    _createVNode(_component_UIInput, {
                      class: _normalizeClass(_ctx.b('form-office')),
                      placeholder: "Номер Офиса",
                      view: "light",
                      "is-readonly": _ctx.isReadonly,
                      "is-error": 
                _ctx.isValidate &&
                !_ctx.kybRegistrationInfoFields['address.office_actual']
              ,
                      modelValue: _ctx.kybRegistrationInfo.address.office_actual,
                      "onUpdate:modelValue": _cache[27] || (_cache[27] = ($event: any) => ((_ctx.kybRegistrationInfo.address.office_actual) = $event))
                    }, null, 8, ["class", "is-readonly", "is-error", "modelValue"])
                  ]),
                  _: 1
                }, 8, ["value", "is-validate", "field-value"]),
                _createVNode(_component_SharedValidate, {
                  field: "address.zip",
                  value: _ctx.kybRegistrationInfo.address.zip_actual,
                  "is-validate": _ctx.isValidate,
                  "field-value": 
              _ctx.kybRegistrationInfoFields['address.zip_actual']
            ,
                  "onUpdate:fieldValue": _cache[30] || (_cache[30] = ($event: any) => ((
              _ctx.kybRegistrationInfoFields['address.zip_actual']
            ) = $event))
                }, {
                  default: _withCtx(() => [
                    _createVNode(_component_UIInput, {
                      class: _normalizeClass(_ctx.b('form-index')),
                      placeholder: "Индекс",
                      view: "light",
                      "is-readonly": _ctx.isReadonly,
                      "is-error": 
                _ctx.isValidate && !_ctx.kybRegistrationInfoFields['address.zip_actual']
              ,
                      modelValue: _ctx.kybRegistrationInfo.address.zip_actual,
                      "onUpdate:modelValue": _cache[29] || (_cache[29] = ($event: any) => ((_ctx.kybRegistrationInfo.address.zip_actual) = $event))
                    }, null, 8, ["class", "is-readonly", "is-error", "modelValue"])
                  ]),
                  _: 1
                }, 8, ["value", "is-validate", "field-value"])
              ], 2)
            ], 2))
          : _createCommentVNode("", true),
        _createVNode(_component_SharedValidate, {
          field: "contactPhone",
          value: _ctx.kybRegistrationInfo.phone,
          "is-validate": _ctx.isValidate,
          "field-value": _ctx.kybRegistrationInfoFields.phone,
          "onUpdate:fieldValue": _cache[32] || (_cache[32] = ($event: any) => ((_ctx.kybRegistrationInfoFields.phone) = $event))
        }, {
          default: _withCtx(() => [
            _createElementVNode("div", {
              class: _normalizeClass(_ctx.b('form-group'))
            }, [
              _createElementVNode("h5", {
                class: _normalizeClass(_ctx.b('form-title'))
              }, _toDisplayString(_ctx.$t("Контактный номер*")), 3),
              _createVNode(_component_UIInput, {
                view: "light",
                "is-readonly": _ctx.isReadonly,
                "is-error": _ctx.isValidate && !_ctx.kybRegistrationInfoFields.phone,
                modelValue: _ctx.kybRegistrationInfo.phone,
                "onUpdate:modelValue": _cache[31] || (_cache[31] = ($event: any) => ((_ctx.kybRegistrationInfo.phone) = $event))
              }, null, 8, ["is-readonly", "is-error", "modelValue"])
            ], 2)
          ]),
          _: 1
        }, 8, ["value", "is-validate", "field-value"]),
        _createElementVNode("div", {
          class: _normalizeClass(_ctx.b('form-group'))
        }, [
          _createElementVNode("h5", {
            class: _normalizeClass(_ctx.b('form-title'))
          }, _toDisplayString(_ctx.$t("Сайт")), 3),
          _createVNode(_component_UIInput, {
            view: "light",
            "is-readonly": _ctx.isReadonly,
            modelValue: _ctx.kybRegistrationInfo.site,
            "onUpdate:modelValue": _cache[33] || (_cache[33] = ($event: any) => ((_ctx.kybRegistrationInfo.site) = $event))
          }, null, 8, ["is-readonly", "modelValue"])
        ], 2)
      ], 2),
      _createVNode(_component_UIButton, {
        class: _normalizeClass(_ctx.b('continue')),
        label: "Продолжить",
        view: "main",
        disabled: _ctx.isContinueDisabled,
        onClick: _ctx.handleContinue
      }, null, 8, ["class", "disabled", "onClick"])
    ]),
    _: 1
  }, 8, ["class"]))
}