import { toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = ["innerHTML"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_SharedCryptoSelect = _resolveComponent("SharedCryptoSelect")!
  const _component_UIButton = _resolveComponent("UIButton")!
  const _component_SharedCurrency = _resolveComponent("SharedCurrency")!

  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(_ctx.b())
  }, [
    _createElementVNode("div", {
      class: _normalizeClass(_ctx.b('fields'))
    }, [
      _createElementVNode("div", {
        class: _normalizeClass(_ctx.b('scores'))
      }, [
        _createElementVNode("div", {
          class: _normalizeClass(_ctx.b('score'))
        }, [
          _createElementVNode("h4", {
            class: _normalizeClass(_ctx.b('title'))
          }, _toDisplayString(_ctx.$t("Со счета")), 3),
          _createVNode(_component_SharedCryptoSelect, {
            placeholder: _ctx.$t('Выберите из пресетов'),
            options: _ctx.displayedFromOptions,
            value: _ctx.displayedApplicationStoreRequest.currency_id_from,
            "onUpdate:value": _ctx.handleCurrencyIdFrom
          }, null, 8, ["placeholder", "options", "value", "onUpdate:value"])
        ], 2),
        _createElementVNode("span", {
          class: _normalizeClass(_ctx.b('scores-icon')),
          innerHTML: _ctx.displayedArrowIcon
        }, null, 10, _hoisted_1),
        _createElementVNode("div", {
          class: _normalizeClass(_ctx.b('score'))
        }, [
          _createElementVNode("h4", {
            class: _normalizeClass(_ctx.b('title'))
          }, _toDisplayString(_ctx.$t("На счет")), 3),
          _createVNode(_component_SharedCryptoSelect, {
            placeholder: _ctx.$t('Выберите из пресетов / создайте новый'),
            options: _ctx.displayedToOptions,
            value: _ctx.displayedApplicationStoreRequest.currency_id_to,
            "onUpdate:value": _ctx.handleCurrencyIdTo
          }, null, 8, ["placeholder", "options", "value", "onUpdate:value"])
        ], 2)
      ], 2),
      _createElementVNode("div", {
        class: _normalizeClass(_ctx.b('amount'))
      }, [
        _createElementVNode("div", {
          class: _normalizeClass(_ctx.b('amount-group'))
        }, [
          _createElementVNode("h4", {
            class: _normalizeClass(_ctx.b('title'))
          }, _toDisplayString(_ctx.$t("Сумма")), 3),
          _createVNode(_component_UIButton, {
            label: "МАКС",
            view: "max",
            onClick: _ctx.handleMax
          }, null, 8, ["onClick"])
        ], 2),
        _createVNode(_component_SharedCurrency, {
          label: "EUR",
          value: _ctx.displayedApplicationStoreRequest.amount,
          "is-number": true,
          "onUpdate:value": _ctx.handleAmount
        }, null, 8, ["value", "onUpdate:value"]),
        (_ctx.isCommissionsShow)
          ? (_openBlock(), _createElementBlock("div", {
              key: 0,
              class: _normalizeClass(_ctx.b('message', { amount: true }))
            }, [
              _createElementVNode("div", {
                class: _normalizeClass(_ctx.b('message-item'))
              }, [
                _createElementVNode("p", {
                  class: _normalizeClass(_ctx.b('message-label'))
                }, _toDisplayString(_ctx.$t("Комиссия")), 3),
                _createElementVNode("p", {
                  class: _normalizeClass(_ctx.b('message-label'))
                }, " ~ " + _toDisplayString(_ctx.displayedApplicationStoreRequest.commissions) + " " + _toDisplayString(_ctx.displayedCurrencyFrom?.currency.name.toUpperCase()), 3)
              ], 2),
              _createElementVNode("div", {
                class: _normalizeClass(_ctx.b('message-item'))
              }, [
                _createElementVNode("p", {
                  class: _normalizeClass(_ctx.b('message-label'))
                }, _toDisplayString(_ctx.$t("Вы получите")), 3),
                _createElementVNode("p", {
                  class: _normalizeClass(_ctx.b('message-label'))
                }, " ~ ????? " + _toDisplayString(_ctx.displayedCurrencyTo?.currency.name.toUpperCase()), 3)
              ], 2)
            ], 2))
          : _createCommentVNode("", true)
      ], 2)
    ], 2),
    _createVNode(_component_UIButton, {
      label: "Далее",
      view: "main",
      disabled: _ctx.isNextDisabled,
      onClick: _ctx.handleNext
    }, null, 8, ["disabled", "onClick"])
  ], 2))
}