<script lang="ts" src="./component.ts"></script>
<style lang="scss" src="./component.scss"></style>

<template>
  <button :class="b('')" :disabled="isDisabled" @click="handleDownload">
    <span v-if="!isLoading" :class="b('icon')" v-html="displayedSvg.download" />

    <p v-if="!isLoading" :class="b('label')">
      <slot name="label"></slot>
    </p>

    <span
      v-if="isLoading"
      :class="b('icon', { preloader: true })"
      v-html="displayedSvg.preloader"
    />
  </button>
</template>
