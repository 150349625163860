import { SvgAttribute } from "@/shared/constants/constants";

export const COMPONENT_NAME = "kyb-company";

export const SVG: SvgAttribute = {
  check: `<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <ellipse cx="10.9296" cy="10.9502" rx="8.71572" ry="8.71888" fill="white"/>
    <path opacity="0.992" fill-rule="evenodd" clip-rule="evenodd" d="M11.0741 0.00897091C15.9572 -0.150176 19.7107 1.80816 22.3348 5.884C24.2607 9.33851 24.5161 12.9146 23.1008 16.6123C21.5123 20.2041 18.8312 22.5456 15.0575 23.6368C10.3345 24.6841 6.30859 23.4665 2.97973 19.9841C0.241875 16.679 -0.609268 12.9326 0.426294 8.74488C1.54242 5.05695 3.86604 2.44299 7.39717 0.902997C8.58671 0.426644 9.81236 0.128638 11.0741 0.00897091ZM16.1299 8.23401C16.8835 8.20473 17.309 8.56235 17.4066 9.30684C17.3933 9.50863 17.3337 9.69597 17.2279 9.8688C15.3298 11.8357 13.3977 13.7684 11.4316 15.6672C11.0474 15.8496 10.6729 15.8326 10.3081 15.6161C9.12498 14.4326 7.94192 13.2491 6.75881 12.0655C6.46164 11.5191 6.53824 11.0337 6.98862 10.6096C7.34176 10.3521 7.7163 10.318 8.11213 10.5074C9.05547 11.3999 9.98318 12.311 10.8954 13.2406C12.47 11.6654 14.0446 10.0902 15.6192 8.51499C15.7861 8.40617 15.9563 8.31253 16.1299 8.23401Z" fill="#51BC40"/>
  </svg>`,
  chevron: `<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path opacity="0.962" fill-rule="evenodd" clip-rule="evenodd" d="M7.47642 19.8047C7.44867 19.5419 7.48772 19.2919 7.59361 19.0547C9.93801 16.7181 12.2739 14.3744 14.6014 12.0234C12.2739 9.67251 9.93801 7.32876 7.59361 4.99218C7.43737 4.67967 7.43737 4.3672 7.59361 4.05468C7.91915 3.74676 8.28633 3.69206 8.69517 3.89062C11.2499 6.44531 13.8045 8.99999 16.3592 11.5547C16.5155 11.8672 16.5155 12.1797 16.3592 12.4922C13.8045 15.0469 11.2499 17.6016 8.69517 20.1562C8.17364 20.4057 7.76737 20.2886 7.47642 19.8047Z" fill="#9E9FA8"/>
  </svg>`,
  time: `<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clip-path="url(#clip0_115_9437)">
    <path opacity="0.993" fill-rule="evenodd" clip-rule="evenodd" d="M11.1276 0.00730226C15.9722 -0.135439 19.6941 1.81451 22.2932 5.85716C24.1273 9.17825 24.4325 12.6373 23.2088 16.2343C21.5393 20.3538 18.5296 22.8802 14.1797 23.8137C9.74253 24.5135 5.98671 23.2418 2.91238 19.9986C0.334652 16.8942 -0.56402 13.3503 0.216363 9.36708C1.15341 5.58196 3.36618 2.8266 6.85468 1.10097C8.2239 0.495882 9.64822 0.131329 11.1276 0.00730226ZM11.7889 4.73806C12.4017 4.69202 12.7832 4.9633 12.9334 5.55195C12.9504 7.43408 12.9674 9.31621 12.9843 11.1983C14.0186 12.0631 15.053 12.9279 16.0873 13.7926C16.3916 14.2015 16.3916 14.6084 16.0873 15.0135C15.6846 15.4105 15.2352 15.4699 14.7393 15.1915C13.6465 14.2807 12.5443 13.382 11.4328 12.4955C11.2453 12.3322 11.1011 12.1372 11.0004 11.9105C10.9165 9.79401 10.8996 7.67448 10.9496 5.55195C11.0913 5.1303 11.3711 4.85902 11.7889 4.73806Z" fill="#D2DEE4"/>
    <path d="M10.7998 4.80001C10.7998 4.13726 11.3371 3.60001 11.9998 3.60001C12.6625 3.60001 13.1998 4.13726 13.1998 4.80001V12C13.1998 12.6627 12.6625 13.2 11.9998 13.2C11.3371 13.2 10.7998 12.6627 10.7998 12V4.80001Z" fill="white"/>
    <path d="M17.6666 14.0717C18.2405 14.4031 18.4372 15.137 18.1058 15.7109C17.7744 16.2849 17.0405 16.4815 16.4666 16.1502L11.8391 13.4785C11.2651 13.1471 11.0685 12.4132 11.3998 11.8392C11.7312 11.2653 12.4651 11.0686 13.0391 11.4L17.6666 14.0717Z" fill="white"/>
    </g>
    <defs>
    <clipPath id="clip0_115_9437">
    <rect width="24" height="24" fill="white"/>
    </clipPath>
    </defs>
  </svg>`,
  expected: `
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clip-path="url(#clip0_261_15506)">
    <path opacity="0.993" fill-rule="evenodd" clip-rule="evenodd" d="M11.1279 0.00730226C15.9725 -0.135439 19.6943 1.81451 22.2935 5.85716C24.1276 9.17825 24.4328 12.6373 23.2091 16.2343C21.5395 20.3538 18.5299 22.8802 14.18 23.8137C9.74277 24.5135 5.98696 23.2418 2.91263 19.9986C0.334896 16.8942 -0.563776 13.3503 0.216607 9.36708C1.15365 5.58196 3.36642 2.8266 6.85492 1.10097C8.22415 0.495882 9.64846 0.131329 11.1279 0.00730226ZM11.7892 4.73806C12.4019 4.69202 12.7834 4.9633 12.9337 5.55195C12.9506 7.43408 12.9676 9.31621 12.9846 11.1983C14.0189 12.0631 15.0532 12.9279 16.0875 13.7926C16.3919 14.2015 16.3919 14.6084 16.0875 15.0135C15.6848 15.4105 15.2355 15.4699 14.7395 15.1915C13.6467 14.2807 12.5445 13.382 11.4331 12.4955C11.2455 12.3322 11.1014 12.1372 11.0007 11.9105C10.9168 9.79401 10.8998 7.67448 10.9498 5.55195C11.0915 5.1303 11.3713 4.85902 11.7892 4.73806Z" fill="#FCCA13"/>
    <path d="M10.8 4.79998C10.8 4.13723 11.3373 3.59998 12 3.59998C12.6628 3.59998 13.2 4.13723 13.2 4.79998V12C13.2 12.6627 12.6628 13.2 12 13.2C11.3373 13.2 10.8 12.6627 10.8 12V4.79998Z" fill="white"/>
    <path d="M17.6668 14.0717C18.2408 14.4031 18.4374 15.137 18.1061 15.7109C17.7747 16.2849 17.0408 16.4815 16.4668 16.1501L11.8393 13.4784C11.2654 13.1471 11.0687 12.4132 11.4001 11.8392C11.7314 11.2653 12.4654 11.0686 13.0393 11.4L17.6668 14.0717Z" fill="white"/>
    </g>
    <defs>
    <clipPath id="clip0_261_15506">
    <rect width="24" height="24" fill="white"/>
    </clipPath>
    </defs>
  </svg>`,
};
