import { resolveComponent as _resolveComponent, normalizeClass as _normalizeClass, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, Transition as _Transition, withCtx as _withCtx, createVNode as _createVNode, resolveDynamicComponent as _resolveDynamicComponent, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_SharedNotification = _resolveComponent("SharedNotification")!
  const _component_SharedModal = _resolveComponent("SharedModal")!
  const _component_SharedDeposit = _resolveComponent("SharedDeposit")!

  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(_ctx.b())
  }, [
    _createVNode(_Transition, { name: "opacity" }, {
      default: _withCtx(() => [
        (_ctx.isNotificationShow)
          ? (_openBlock(), _createBlock(_component_SharedNotification, {
              key: 0,
              class: _normalizeClass(_ctx.b('notification'))
            }, null, 8, ["class"]))
          : _createCommentVNode("", true)
      ]),
      _: 1
    }),
    _createVNode(_Transition, { name: "opacity" }, {
      default: _withCtx(() => [
        (_ctx.isModalShow)
          ? (_openBlock(), _createBlock(_component_SharedModal, {
              key: 0,
              class: _normalizeClass(_ctx.b('modal'))
            }, null, 8, ["class"]))
          : _createCommentVNode("", true)
      ]),
      _: 1
    }),
    _createVNode(_Transition, { name: "opacity" }, {
      default: _withCtx(() => [
        (_ctx.isDepositShow)
          ? (_openBlock(), _createBlock(_component_SharedDeposit, {
              key: 0,
              class: _normalizeClass(_ctx.b('modal'))
            }, null, 8, ["class"]))
          : _createCommentVNode("", true)
      ]),
      _: 1
    }),
    (_ctx.isLoading)
      ? (_openBlock(), _createBlock(_resolveDynamicComponent(_ctx.displayedLayout), { key: 0 }))
      : _createCommentVNode("", true)
  ], 2))
}