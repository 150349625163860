/* eslint-disable @typescript-eslint/ban-ts-comment */
import { Module, Mutation, VuexModule } from "vuex-module-decorators";

import {
  WalletListResponse,
  WalletModal,
} from "@/shared/repository/modules/wallet/repo";
import { WalletModalFactory } from "@/shared/repository/modules/wallet/factory";

enum WalletMutation {
  UPDATE_MODAL = "UPDATE_MODAL",
  UPDATE_LIST = "UPDATE_LIST",
}

interface State {
  modal?: WalletModal;
  list?: WalletListResponse[];
}

@Module({
  namespaced: true,
  stateFactory: true,
  name: "wallet",
})
export default class WalletModuleState extends VuexModule<State> {
  modal: WalletModal = WalletModalFactory();
  list: WalletListResponse[] = [];

  @Mutation
  [WalletMutation.UPDATE_MODAL](modal: WalletModal): void {
    this.modal = modal;
  }

  @Mutation
  [WalletMutation.UPDATE_LIST](list: WalletListResponse[]): void {
    this.list = list;
  }
}
