import { toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, createBlock as _createBlock, createCommentVNode as _createCommentVNode } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_SharedAdd = _resolveComponent("SharedAdd")!
  const _component_SharedCard = _resolveComponent("SharedCard")!

  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(_ctx.b())
  }, [
    _createVNode(_component_SharedCard, {
      class: _normalizeClass(_ctx.b('content'))
    }, {
      default: _withCtx(() => [
        _createElementVNode("h3", {
          class: _normalizeClass(_ctx.b('content-title'))
        }, _toDisplayString(_ctx.$t("Банковские реквизиты")), 3),
        _createElementVNode("div", {
          class: _normalizeClass(_ctx.b('content-block'))
        }, [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.bankRequisites, (requisite, requisiteIndex) => {
            return (_openBlock(), _createElementBlock("div", {
              class: _normalizeClass(_ctx.b('content-group')),
              key: requisite.id
            }, [
              _createElementVNode("h5", {
                class: _normalizeClass(_ctx.b('content-group-title'))
              }, _toDisplayString(_ctx.$t("Банковский счет")) + " №" + _toDisplayString(requisiteIndex + 1), 3),
              _createVNode(_component_SharedAdd, {
                "is-confirmed": true,
                "is-loading": _ctx.isLoading,
                onClick: ($event: any) => (_ctx.handleBank(requisite)),
                onRemove: ($event: any) => (_ctx.handleBankRemove(requisite))
              }, {
                title: _withCtx(() => [
                  _createTextVNode(_toDisplayString(_ctx.$t("Подтверждено")), 1)
                ]),
                _: 2
              }, 1032, ["is-loading", "onClick", "onRemove"])
            ], 2))
          }), 128)),
          (_ctx.isBankAddShow)
            ? (_openBlock(), _createBlock(_component_SharedAdd, {
                key: 0,
                "is-loading": _ctx.isLoading,
                onClick: _ctx.handleBank
              }, {
                label: _withCtx(() => [
                  _createTextVNode(_toDisplayString(_ctx.$t("Добавить")), 1)
                ]),
                _: 1
              }, 8, ["is-loading", "onClick"]))
            : _createCommentVNode("", true)
        ], 2)
      ]),
      _: 1
    }, 8, ["class"]),
    _createVNode(_component_SharedCard, {
      class: _normalizeClass(_ctx.b('content', { crypto: true }))
    }, {
      default: _withCtx(() => [
        _createElementVNode("h3", {
          class: _normalizeClass(_ctx.b('content-title'))
        }, _toDisplayString(_ctx.$t("Крипто реквизиты")), 3),
        _createElementVNode("div", {
          class: _normalizeClass(_ctx.b('content-block'))
        }, [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.cryptoRequisites, (requisite, requisiteIndex) => {
            return (_openBlock(), _createElementBlock("div", {
              class: _normalizeClass(_ctx.b('content-group')),
              key: requisite.id
            }, [
              _createElementVNode("h5", {
                class: _normalizeClass(_ctx.b('content-group-title'))
              }, _toDisplayString(_ctx.$t("Крипто счет")) + " №" + _toDisplayString(requisiteIndex + 1), 3),
              _createVNode(_component_SharedAdd, {
                "is-confirmed": true,
                "is-loading": _ctx.isLoading,
                onClick: ($event: any) => (_ctx.handleCrypto(requisite)),
                onRemove: ($event: any) => (_ctx.handleCryptoRemove(requisite))
              }, {
                title: _withCtx(() => [
                  _createTextVNode(_toDisplayString(_ctx.$t("Подтверждено")), 1)
                ]),
                _: 2
              }, 1032, ["is-loading", "onClick", "onRemove"])
            ], 2))
          }), 128)),
          (_ctx.isCryptoAddShow)
            ? (_openBlock(), _createBlock(_component_SharedAdd, {
                key: 0,
                "is-loading": _ctx.isLoading,
                onClick: _ctx.handleCrypto
              }, {
                label: _withCtx(() => [
                  _createTextVNode(_toDisplayString(_ctx.$t("Добавить")), 1)
                ]),
                _: 1
              }, 8, ["is-loading", "onClick"]))
            : _createCommentVNode("", true)
        ], 2)
      ]),
      _: 1
    }, 8, ["class"])
  ], 2))
}