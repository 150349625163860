import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_AdminKycBack = _resolveComponent("AdminKycBack")!
  const _component_UIInput = _resolveComponent("UIInput")!
  const _component_SharedDate = _resolveComponent("SharedDate")!
  const _component_SharedRadio = _resolveComponent("SharedRadio")!

  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(_ctx.b())
  }, [
    _createVNode(_component_AdminKycBack, {
      title: "Основная информация об организации",
      onBack: _ctx.handleBack
    }, null, 8, ["onBack"]),
    _createElementVNode("div", {
      class: _normalizeClass(_ctx.b('fields'))
    }, [
      _createElementVNode("div", {
        class: _normalizeClass(_ctx.b('field'))
      }, [
        _createElementVNode("h4", {
          class: _normalizeClass(_ctx.b('field-label'))
        }, _toDisplayString(_ctx.$t("Страна регистрации организации")), 3),
        _createVNode(_component_UIInput, {
          view: "light",
          "is-readonly": true,
          modelValue: _ctx.kybBasicInfo.country,
          "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.kybBasicInfo.country) = $event))
        }, null, 8, ["modelValue"])
      ], 2),
      _createElementVNode("div", {
        class: _normalizeClass(_ctx.b('field'))
      }, [
        _createElementVNode("h4", {
          class: _normalizeClass(_ctx.b('field-label'))
        }, _toDisplayString(_ctx.$t("Название организации")), 3),
        _createVNode(_component_UIInput, {
          view: "light",
          "is-readonly": true,
          modelValue: _ctx.kybBasicInfo.organisation,
          "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.kybBasicInfo.organisation) = $event))
        }, null, 8, ["modelValue"])
      ], 2),
      _createElementVNode("div", {
        class: _normalizeClass(_ctx.b('field'))
      }, [
        _createElementVNode("h4", {
          class: _normalizeClass(_ctx.b('field-label'))
        }, _toDisplayString(_ctx.$t("Регистрационный номер")), 3),
        _createVNode(_component_UIInput, {
          view: "light",
          "is-readonly": true,
          modelValue: _ctx.kybBasicInfo.regional_number,
          "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.kybBasicInfo.regional_number) = $event))
        }, null, 8, ["modelValue"])
      ], 2),
      _createElementVNode("div", {
        class: _normalizeClass(_ctx.b('field'))
      }, [
        _createElementVNode("h4", {
          class: _normalizeClass(_ctx.b('field-label'))
        }, _toDisplayString(_ctx.$t("Дата регистрации")), 3),
        _createVNode(_component_SharedDate, {
          format: "yyyy-MM-dd",
          "is-readonly": true,
          date: _ctx.kybBasicInfo.registration_date,
          "onUpdate:date": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.kybBasicInfo.registration_date) = $event))
        }, null, 8, ["date"])
      ], 2),
      _createElementVNode("div", {
        class: _normalizeClass(_ctx.b('field'))
      }, [
        _createElementVNode("h4", {
          class: _normalizeClass(_ctx.b('field-label'))
        }, _toDisplayString(_ctx.$t("Номер Налогоплательщика")), 3),
        _createVNode(_component_UIInput, {
          view: "light",
          "is-readonly": true,
          modelValue: _ctx.kybBasicInfo.inn,
          "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => ((_ctx.kybBasicInfo.inn) = $event))
        }, null, 8, ["modelValue"])
      ], 2),
      _createElementVNode("div", {
        class: _normalizeClass(_ctx.b('field'))
      }, [
        _createElementVNode("h4", {
          class: _normalizeClass(_ctx.b('field-label'))
        }, _toDisplayString(_ctx.$t("Деятельность лицензируется?")), 3),
        _createElementVNode("div", {
          class: _normalizeClass(_ctx.b('field-radios'))
        }, [
          _createElementVNode("div", {
            class: _normalizeClass(_ctx.b('field-radio'))
          }, [
            _createVNode(_component_SharedRadio, {
              view: "light",
              "checked-value": true,
              "is-readonly": true,
              value: _ctx.kybBasicInfo.licensed,
              "onUpdate:value": _cache[5] || (_cache[5] = ($event: any) => ((_ctx.kybBasicInfo.licensed) = $event))
            }, null, 8, ["value"]),
            _createElementVNode("p", {
              class: _normalizeClass(_ctx.b('field-radio-label'))
            }, _toDisplayString(_ctx.$t("Да")), 3)
          ], 2),
          _createElementVNode("div", {
            class: _normalizeClass(_ctx.b('field-radio'))
          }, [
            _createVNode(_component_SharedRadio, {
              view: "light",
              "checked-value": false,
              "is-readonly": true,
              value: _ctx.kybBasicInfo.licensed,
              "onUpdate:value": _cache[6] || (_cache[6] = ($event: any) => ((_ctx.kybBasicInfo.licensed) = $event))
            }, null, 8, ["value"]),
            _createElementVNode("p", {
              class: _normalizeClass(_ctx.b('field-radio-label'))
            }, _toDisplayString(_ctx.$t("Нет")), 3)
          ], 2)
        ], 2)
      ], 2),
      _createElementVNode("div", {
        class: _normalizeClass(_ctx.b('field'))
      }, [
        _createElementVNode("h4", {
          class: _normalizeClass(_ctx.b('field-label'))
        }, _toDisplayString(_ctx.$t("Телеграм для связи")), 3),
        _createVNode(_component_UIInput, {
          view: "light",
          "is-telegram": true,
          "is-readonly": true,
          modelValue: _ctx.kybBasicInfo.telegram,
          "onUpdate:modelValue": _cache[7] || (_cache[7] = ($event: any) => ((_ctx.kybBasicInfo.telegram) = $event))
        }, null, 8, ["modelValue"])
      ], 2)
    ], 2)
  ], 2))
}