<script lang="ts" src="./component.ts"></script>
<style lang="scss" src="./component.scss"></style>

<template>
  <div :class="b()">
    <SharedCard :class="b('content')">
      <h3 :class="b('content-title')">
        {{ $t("Банковские реквизиты") }}
      </h3>

      <div :class="b('content-block')">
        <div
          :class="b('content-group')"
          v-for="(requisite, requisiteIndex) in bankRequisites"
          :key="requisite.id"
        >
          <h5 :class="b('content-group-title')">
            {{ $t("Банковский счет") }} №{{ requisiteIndex + 1 }}
          </h5>

          <SharedAdd
            :is-confirmed="true"
            :is-loading="isLoading"
            @click="handleBank(requisite)"
            @remove="handleBankRemove(requisite)"
          >
            <template #title>
              {{ $t("Подтверждено") }}
            </template>
          </SharedAdd>
        </div>

        <SharedAdd
          v-if="isBankAddShow"
          :is-loading="isLoading"
          @click="handleBank"
        >
          <template #label>
            {{ $t("Добавить") }}
          </template>
        </SharedAdd>
      </div>
    </SharedCard>

    <SharedCard :class="b('content', { crypto: true })">
      <h3 :class="b('content-title')">
        {{ $t("Крипто реквизиты") }}
      </h3>

      <div :class="b('content-block')">
        <div
          :class="b('content-group')"
          v-for="(requisite, requisiteIndex) in cryptoRequisites"
          :key="requisite.id"
        >
          <h5 :class="b('content-group-title')">
            {{ $t("Крипто счет") }} №{{ requisiteIndex + 1 }}
          </h5>

          <SharedAdd
            :is-confirmed="true"
            :is-loading="isLoading"
            @click="handleCrypto(requisite)"
            @remove="handleCryptoRemove(requisite)"
          >
            <template #title>
              {{ $t("Подтверждено") }}
            </template>
          </SharedAdd>
        </div>

        <SharedAdd
          v-if="isCryptoAddShow"
          :is-loading="isLoading"
          @click="handleCrypto"
        >
          <template #label>
            {{ $t("Добавить") }}
          </template>
        </SharedAdd>
      </div>
    </SharedCard>
  </div>
</template>
