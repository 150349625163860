import { defineComponent, PropType } from "vue";

import { COMPONENT_NAME } from "./attributes";

import { AdminConfigCommissionField } from "../field";
import { SharedSelect } from "@/components/shared";

import { AdminCommissionExchangeType } from "@/shared/repository/modules/admin/commission/enums";
import { AdminCommissionFindOptions } from "@/data/admin/commission/find";
import { SharedSelectOption } from "@/components/shared/select/model";
import { SelectOptionFactory } from "@/shared/factory/select";

export default defineComponent({
  name: COMPONENT_NAME,
  components: {
    AdminConfigCommissionField,
    SharedSelect,
  },
  props: {
    type: {
      type: <PropType<AdminCommissionExchangeType>>String,
      required: true,
    },
    userId: {
      type: Number,
      required: true,
    },
  },
  emits: {
    "update:type": null,
    "update:userId": null,
  },
  data() {
    return {
      usersRepo: this.$projectServices.usersRepo,
    };
  },
  computed: {
    displayedOptions(): SharedSelectOption[] {
      return AdminCommissionFindOptions(this.type);
    },

    displayedUserIdOptions(): SharedSelectOption[] {
      return this.usersRepo.users.map((user) =>
        SelectOptionFactory(
          {
            id: user.index,
            label: `${user.index}: ${user.email}`,
            value: user.id,
          },
          this.userId
        )
      );
    },

    isUserIdShow(): boolean {
      return this.type === AdminCommissionExchangeType.user;
    },
  },
  methods: {
    handleType(type: string): void {
      this.$emit("update:type", type);
    },

    handleUserId(userId: number): void {
      this.$emit("update:userId", userId);
    },
  },
});
