import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createBlock as _createBlock, createCommentVNode as _createCommentVNode, Transition as _Transition, withCtx as _withCtx, createVNode as _createVNode } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_AdminConfigCurrencyRow = _resolveComponent("AdminConfigCurrencyRow")!
  const _component_AdminConfigCurrencyModal = _resolveComponent("AdminConfigCurrencyModal")!

  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(_ctx.b())
  }, [
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.displayedTable, (table) => {
      return (_openBlock(), _createElementBlock("div", {
        class: _normalizeClass(_ctx.b('table')),
        key: table.title
      }, [
        _createElementVNode("div", {
          class: _normalizeClass(_ctx.b('header'))
        }, [
          _createElementVNode("h4", {
            class: _normalizeClass(_ctx.b('header-title'))
          }, _toDisplayString(table.title), 3)
        ], 2),
        _createElementVNode("div", {
          class: _normalizeClass(_ctx.b('body'))
        }, [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(table.rows, (row) => {
            return (_openBlock(), _createBlock(_component_AdminConfigCurrencyRow, {
              class: _normalizeClass(_ctx.b('body-row')),
              key: row.id,
              row: row
            }, null, 8, ["class", "row"]))
          }), 128))
        ], 2)
      ], 2))
    }), 128)),
    _createVNode(_Transition, { name: "opacity" }, {
      default: _withCtx(() => [
        (_ctx.isModalShow)
          ? (_openBlock(), _createBlock(_component_AdminConfigCurrencyModal, {
              key: 0,
              class: _normalizeClass(_ctx.b('modal'))
            }, null, 8, ["class"]))
          : _createCommentVNode("", true)
      ]),
      _: 1
    })
  ], 2))
}