/* eslint-disable @typescript-eslint/ban-ts-comment */
import { Module, Mutation, Action, VuexModule } from "vuex-module-decorators";
import i18n from "@/plugins/i18n";

import { UserInfoResponse } from "@/shared/repository/modules/user/repo";
import { UserInfoResponseFactory } from "@/shared/repository/modules/user/factory";
import {
  ApplicationStatus,
  ApplicationType,
  CookieName,
  Language,
} from "@/shared/constants/enums";
import { getCookie, setCookie } from "@/shared/utils/cookie-helpers";

enum UserMutation {
  UPDATE_IS_AUTHENTICATED = "UPDATE_IS_AUTHENTICATED",
  UPDATE_USER_INFO = "UPDATE_USER_INFO",
  UPDATE_LANGUAGE = "UPDATE_LANGUAGE",
}

interface State {
  isAuthenticated?: boolean;
  userInfo?: UserInfoResponse;
}

@Module({
  namespaced: true,
  stateFactory: true,
  name: "user",
})
export default class UserModuleState extends VuexModule<State> {
  isAuthenticated: boolean = false;
  userInfo = UserInfoResponseFactory();

  get isReadonlyKyc(): boolean {
    const { application_type, user_app_status } = this.userInfo.user_info;

    if (
      application_type === ApplicationType.kyc &&
      (user_app_status === ApplicationStatus.pending ||
        user_app_status === ApplicationStatus.waiting ||
        user_app_status === ApplicationStatus.completed)
    ) {
      return true;
    }

    return false;
  }

  get isReadonlyKyb(): boolean {
    const { application_type, user_app_status } = this.userInfo.user_info;

    if (
      application_type === ApplicationType.kyb &&
      (user_app_status === ApplicationStatus.pending ||
        user_app_status === ApplicationStatus.waiting ||
        user_app_status === ApplicationStatus.completed)
    ) {
      return true;
    }

    return false;
  }

  get isVerified(): boolean {
    const { user_app_status, application_verified } = this.userInfo.user_info;

    return (
      user_app_status === ApplicationStatus.completed && application_verified
    );
  }

  get isVerificationReview(): boolean {
    const { user_app_status } = this.userInfo.user_info;

    return (
      user_app_status === ApplicationStatus.pending ||
      user_app_status === ApplicationStatus.waiting
    );
  }

  @Mutation
  [UserMutation.UPDATE_IS_AUTHENTICATED](isAuthenticated: boolean): void {
    this.isAuthenticated = isAuthenticated;
  }

  @Mutation
  [UserMutation.UPDATE_USER_INFO](payload: UserInfoResponse): void {
    this.userInfo = payload;

    setCookie(CookieName.language, payload.user_info.lang);
    i18n.global.locale = payload.user_info.lang;
  }

  @Mutation
  [UserMutation.UPDATE_LANGUAGE](language: Language): void {
    setCookie(CookieName.language, language);

    this.userInfo.user_info.lang = language;
    i18n.global.locale = language;
  }

  @Action
  setLanguage(): void {
    const language = getCookie(CookieName.language);

    switch (language) {
      case Language.ru:
      case Language.en:
        this.UPDATE_LANGUAGE(language);

        break;
      default:
        break;
    }
  }
}
