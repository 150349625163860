import { defineComponent } from "vue";

import { COMPONENT_NAME } from "./attributes";
import { AdminConfigCurrencyModalTitle } from "./enums";

import { AdminConfigCurrencyModalFiat } from "./fiat";
import { AdminConfigCurrencyModalCrypto } from "./crypto";

import { SharedWindow } from "@/components/shared";
import {
  AdminCurrencyModalOption,
  AdminCurrencyType,
} from "@/shared/repository/modules/admin/currency/enums";
import {
  AdminCurrencyTab,
  AdminCurrencyTabs,
} from "@/data/admin/currency/tabs";
import { AdminCurrencyModal } from "@/shared/repository/modules/admin/currency/repo";

type Component =
  | typeof AdminConfigCurrencyModalFiat
  | typeof AdminConfigCurrencyModalCrypto;

export default defineComponent({
  name: COMPONENT_NAME,
  components: {
    AdminConfigCurrencyModalFiat,
    AdminConfigCurrencyModalCrypto,
    SharedWindow,
  },
  data() {
    return {
      isLoading: false,
      adminCurrencyRepo: this.$projectServices.adminCurrencyRepo,
    };
  },
  computed: {
    displayedModal(): AdminCurrencyModal {
      return this.adminCurrencyRepo.modal;
    },

    displayedTitle(): AdminConfigCurrencyModalTitle {
      switch (this.displayedModal.option) {
        case AdminCurrencyModalOption.create:
          return AdminConfigCurrencyModalTitle.create;
        case AdminCurrencyModalOption.delete:
          return AdminConfigCurrencyModalTitle.delete;
        default:
          return AdminConfigCurrencyModalTitle.create;
      }
    },

    displayedTabs(): AdminCurrencyTab[] {
      return AdminCurrencyTabs(this.displayedModal.type);
    },

    displayedComponent(): Component {
      switch (this.displayedModal.type) {
        case AdminCurrencyType.fiat:
          return AdminConfigCurrencyModalFiat;
        case AdminCurrencyType.crypto:
          return AdminConfigCurrencyModalCrypto;
        default:
          return AdminConfigCurrencyModalCrypto;
      }
    },

    isButtonDisabled(): boolean {
      return false;
    },
  },
  methods: {
    handleClose(): void {
      this.adminCurrencyRepo.UPDATE_IS_SHOW(false);
    },

    handleTab(tab: AdminCurrencyType): void {
      if (this.displayedModal.type !== tab) {
        this.adminCurrencyRepo.UPDATE_ID(0);
      }

      this.adminCurrencyRepo.UPDATE_TYPE(tab);
    },
  },
});
