import { defineComponent, PropType } from "vue";

import { COMPONENT_NAME } from "./attributes";

import { SharedUpload } from "@/components/shared";
import { AdminKycBack } from "../back";

import { AdminKycComponent } from "@/views/admin-kyc/model";
import { FileFor, KybDocumentsFileName } from "@/shared/constants/enums";
import { KybUploadFileResponseFactory } from "@/shared/repository/modules/kyb/factory";
import { KybDocuments } from "@/shared/constants/interfaces";
import { extractFileName } from "@/shared/utils/file-name-helpers";
import { UserFileResponse } from "@/shared/repository/modules/user/repo";

export default defineComponent({
  name: COMPONENT_NAME,
  components: {
    SharedUpload,
    AdminKycBack,
  },
  props: {
    id: {
      type: Number,
      required: true,
    },
    component: {
      type: <PropType<AdminKycComponent>>String,
      default: "",
    },
  },
  emits: {
    "update:component": null,
  },
  data() {
    return {
      kybRepository: this.$projectServices.kybRepository,
      userRepository: this.$projectServices.userRepository,
      documents: Object.keys(KybDocumentsFileName).reduce(
        (documents, fileName) => {
          documents[fileName] = KybUploadFileResponseFactory({
            name: KybDocumentsFileName[fileName as KybDocumentsFileName],
          });

          return documents;
        },
        {} as KybDocuments
      ) as KybDocuments,
      files: <UserFileResponse[]>[],
    };
  },
  async created(): Promise<void> {
    try {
      const [userFiles, files] = await Promise.allSettled([
        this.kybRepository.userFilesById(this.id),
        this.userRepository.filesById(this.id, FileFor.kyb),
      ]);

      if (userFiles.status === "fulfilled") {
        userFiles.value.forEach((userFile) => {
          this.documents[extractFileName(userFile.name)] =
            KybUploadFileResponseFactory(userFile);
        });
      }

      if (files.status === "fulfilled") {
        this.files = files.value;
      }
    } catch (e) {
      console.log(e);
    }
  },
  methods: {
    handleBack(): void {
      this.$emit("update:component", AdminKycComponent.kybCompany);
    },
  },
});
