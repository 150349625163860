import { resolveComponent as _resolveComponent, createVNode as _createVNode, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, Transition as _Transition, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = ["innerHTML"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_SharedAdminHeader = _resolveComponent("SharedAdminHeader")!
  const _component_SharedSearch = _resolveComponent("SharedSearch")!
  const _component_AdminApplicationTable = _resolveComponent("AdminApplicationTable")!
  const _component_SharedButtonIcon = _resolveComponent("SharedButtonIcon")!
  const _component_AdminApplicationEdit = _resolveComponent("AdminApplicationEdit")!

  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(_ctx.b())
  }, [
    _createVNode(_component_SharedAdminHeader, {
      title: "Заявки",
      "is-type": false,
      "is-loading": _ctx.isLoading,
      "is-email": true,
      id: _ctx.search.id,
      "onUpdate:id": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.search.id) = $event)),
      email: _ctx.search.email,
      "onUpdate:email": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.search.email) = $event)),
      onSearch: _ctx.handleSearch
    }, null, 8, ["is-loading", "id", "email", "onSearch"]),
    _createElementVNode("div", {
      class: _normalizeClass(_ctx.b('wrapper'))
    }, [
      _createVNode(_component_SharedSearch, {
        search: _ctx.search.value,
        "onUpdate:search": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.search.value) = $event))
      }, null, 8, ["search"]),
      _createVNode(_component_AdminApplicationTable, {
        class: _normalizeClass(_ctx.b('table')),
        search: _ctx.search,
        sort: _ctx.sort,
        "deposit-withdrawal-list": _ctx.depositWithdrawalList,
        "onUpdate:depositWithdrawalList": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.depositWithdrawalList) = $event)),
        "deposit-withdrawal-item-id": _ctx.depositWithdrawalItemId,
        "onUpdate:depositWithdrawalItemId": _cache[4] || (_cache[4] = ($event: any) => ((_ctx.depositWithdrawalItemId) = $event)),
        "is-work": _ctx.isWork,
        "onUpdate:isWork": _cache[5] || (_cache[5] = ($event: any) => ((_ctx.isWork) = $event)),
        "is-reject": _ctx.isReject,
        "onUpdate:isReject": _cache[6] || (_cache[6] = ($event: any) => ((_ctx.isReject) = $event))
      }, null, 8, ["class", "search", "sort", "deposit-withdrawal-list", "deposit-withdrawal-item-id", "is-work", "is-reject"]),
      (_ctx.isMoreShow)
        ? (_openBlock(), _createBlock(_component_SharedButtonIcon, {
            key: 0,
            class: _normalizeClass(_ctx.b('more')),
            view: "gray-outline",
            onClick: _ctx.handleMore
          }, {
            "icon-left": _withCtx(() => [
              _createElementVNode("span", {
                class: _normalizeClass(_ctx.b('more-icon')),
                innerHTML: _ctx.displayedIcons.more
              }, null, 10, _hoisted_1)
            ]),
            label: _withCtx(() => [
              _createTextVNode(_toDisplayString(_ctx.$t("Показать больше")), 1)
            ]),
            _: 1
          }, 8, ["class", "onClick"]))
        : _createCommentVNode("", true)
    ], 2),
    _createVNode(_Transition, { name: "opacity" }, {
      default: _withCtx(() => [
        (_ctx.depositWithdrawalItemId)
          ? (_openBlock(), _createBlock(_component_AdminApplicationEdit, {
              key: 0,
              class: _normalizeClass(_ctx.b('modal')),
              "deposit-withdrawal-list": _ctx.depositWithdrawalList,
              "onUpdate:depositWithdrawalList": _cache[7] || (_cache[7] = ($event: any) => ((_ctx.depositWithdrawalList) = $event)),
              "deposit-withdrawal-item-id": _ctx.depositWithdrawalItemId,
              "onUpdate:depositWithdrawalItemId": _cache[8] || (_cache[8] = ($event: any) => ((_ctx.depositWithdrawalItemId) = $event)),
              "is-work": _ctx.isWork,
              "onUpdate:isWork": _cache[9] || (_cache[9] = ($event: any) => ((_ctx.isWork) = $event)),
              "is-reject": _ctx.isReject,
              "onUpdate:isReject": _cache[10] || (_cache[10] = ($event: any) => ((_ctx.isReject) = $event))
            }, null, 8, ["class", "deposit-withdrawal-list", "deposit-withdrawal-item-id", "is-work", "is-reject"]))
          : _createCommentVNode("", true)
      ]),
      _: 1
    })
  ], 2))
}