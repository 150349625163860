<script lang="ts" src="./component.ts"></script>
<style lang="scss" src="./component.scss"></style>

<template>
  <div :class="b()">
    <div :class="b('columns')">
      <div
        :class="b('column')"
        v-for="column in displayedColumns"
        :key="column"
      >
        <p :class="b('column-label')" v-html="$t(column)"></p>
      </div>
    </div>

    <div :class="b('rows')">
      <AdminApplicationTableRow
        :class="b('row')"
        v-for="depositWithdrawalItem in displayedDepositWithdrawalList"
        :key="depositWithdrawalItem.id"
        :deposit-withdrawal-item="depositWithdrawalItem"
        :deposit-withdrawal-item-id="depositWithdrawalItemId"
        :is-work="isWork"
        :is-reject="isReject"
        @depositWithdrawalList="handleDepositWithdrawalList"
        @depositWithdrawalItemId="handleDepositWithdrawalItemId"
        @work="handleWork"
        @reject="handleReject"
      />
    </div>
  </div>
</template>
