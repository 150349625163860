import { defineComponent } from "vue";

import { COMPONENT_NAME, SVG } from "./attributes";

import { UIButton } from "@/components/ui";

import { Modal } from "@/shared/constants/constants";
import { ModalFactory } from "@/shared/factory/modal";

export default defineComponent({
  name: COMPONENT_NAME,
  components: {
    UIButton,
  },
  data() {
    return {
      modalRepo: this.$projectServices.modalRepo,
    };
  },
  computed: {
    displayedModal(): Modal {
      return this.modalRepo.modal ? this.modalRepo.modal : ModalFactory();
    },

    displayedIcon(): string {
      return SVG[this.displayedModal?.icon || ""];
    },

    displayedCloseIcon(): string {
      return SVG.close;
    },
  },
  methods: {
    handleHide(): void {
      this.modalRepo.CLOSE_MODAL();
    },

    handleClose(): void {
      this.modalRepo.CLOSE_MODAL();
    },

    handleOk(): void {
      this.modalRepo.CONFIRM_MODAL();
    },
  },
});
