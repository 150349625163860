import { SvgAttribute } from "@/shared/constants/constants";

export const COMPONENT_NAME = "shared-download";

export const SVG: SvgAttribute = {
  download: `<svg width="48" height="49" viewBox="0 0 48 49" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path opacity="0.971" fill-rule="evenodd" clip-rule="evenodd" d="M24.397 12.8333C24.6088 12.8333 24.8206 12.8333 25.0323 12.8333C25.4835 12.9936 25.7571 13.3113 25.853 13.7863C25.8706 19.6809 25.8883 20.2945 25.9059 26.1891C27.9458 24.1315 30.0019 22.0931 32.0741 20.0739C32.713 19.8254 33.2337 19.9754 33.636 20.5239C33.8351 20.9857 33.7998 21.427 33.5301 21.8475C30.8387 24.5389 28.1473 27.2303 25.4559 29.9217C24.9618 30.2394 24.4676 30.2394 23.9734 29.9217C21.282 27.2303 18.5906 24.5389 15.8992 21.8475C15.6295 21.427 15.5942 20.9857 15.7933 20.5239C16.1956 19.9754 16.7163 19.8254 17.3552 20.0739C19.4275 22.0931 21.4835 24.1315 23.5234 26.1891C23.541 20.2945 23.5587 19.6809 23.5763 13.7863C23.6722 13.3113 23.9458 12.9936 24.397 12.8333Z" fill="#9E9FA8"/>
    <path opacity="0.987" fill-rule="evenodd" clip-rule="evenodd" d="M32.9743 34.6603C27.468 34.6603 21.9617 34.6603 16.4553 34.6603C15.679 34.2641 15.4761 33.6641 15.8464 32.8602C16.0102 32.6257 16.2308 32.4758 16.5083 32.4102C21.9793 32.3748 27.4504 32.3748 32.9214 32.4102C33.7474 32.77 33.968 33.3613 33.5832 34.1838C33.416 34.396 33.213 34.5548 32.9743 34.6603Z" fill="#9E9FA8"/>
  </svg>`,
  preloader: `<svg width="26" height="26" viewBox="0 0 26 26" xmlns="http://www.w3.org/2000/svg">
    <path opacity="0.967" fill-rule="evenodd" clip-rule="evenodd" d="M9.27344 2.19531C11.7422 2.1875 14.211 2.19531 16.6797 2.21875C18.1704 2.22424 19.5766 2.56798 20.8984 3.25C22.1579 4.05532 22.9782 5.18813 23.3594 6.64844C23.5193 7.26442 23.6287 7.8894 23.6875 8.52344C23.739 10.0071 23.7624 11.4915 23.7578 12.9766C23.7624 14.4617 23.739 15.946 23.6875 17.4297C23.6072 18.6573 23.2791 19.8136 22.7031 20.8984C21.8978 22.1579 20.765 22.9782 19.3047 23.3594C18.6886 23.5194 18.0636 23.6287 17.4297 23.6875C15.946 23.739 14.4617 23.7624 12.9766 23.7578C11.4915 23.7624 10.0071 23.739 8.52344 23.6875C7.29569 23.6071 6.13942 23.279 5.05469 22.7031C3.62141 21.7692 2.75422 20.4489 2.45313 18.7422C2.31939 18.061 2.24127 17.3736 2.21875 16.6797C2.1875 14.2109 2.1875 11.7422 2.21875 9.27344C2.24147 8.0432 2.47585 6.85572 2.92188 5.71094C3.66247 4.08029 4.90466 3.04123 6.64844 2.59375C7.51811 2.37917 8.39308 2.24636 9.27344 2.19531ZM9.22656 3.74219C11.7266 3.73437 14.2266 3.74219 16.7266 3.76563C17.9007 3.76913 19.0101 4.03475 20.0547 4.5625C20.9755 5.14085 21.5771 5.96116 21.8594 7.02344C21.9986 7.53072 22.0923 8.04634 22.1406 8.57031C22.2158 11.2727 22.2314 13.9759 22.1875 16.6797C22.1672 17.6454 21.9953 18.5829 21.6719 19.4922C21.1369 20.7143 20.2228 21.5034 18.9297 21.8594C18.4226 21.9986 17.907 22.0923 17.3828 22.1406C14.6648 22.216 11.946 22.2316 9.22656 22.1875C8.27697 22.162 7.35508 21.9901 6.46094 21.6719C5.23884 21.1369 4.44978 20.2228 4.09375 18.9297C3.89289 18.1932 3.78351 17.4432 3.76563 16.6797C3.73437 14.2109 3.73437 11.7422 3.76563 9.27344C3.76346 7.98512 4.07595 6.78198 4.70313 5.66406C5.28532 4.86827 6.05875 4.34483 7.02344 4.09375C7.75323 3.90436 8.48762 3.78717 9.22656 3.74219Z"/>
    <path d="M11.9999 6.84965C11.9999 6.3804 12.3596 6 12.8034 6C13.2472 6 13.607 6.3804 13.607 6.84965V12.9475C13.607 13.4168 13.2472 13.7972 12.8034 13.7972C12.3596 13.7972 11.9999 13.4168 11.9999 12.9475V6.84965Z"/>
    <path d="M17.598 15.4144C17.9823 15.649 18.114 16.1687 17.8921 16.575C17.6702 16.9814 17.1788 17.1207 16.7944 16.886L12.6958 13.9944C11.9995 13.5 12.0064 13.0607 11.9995 12.5C12.2214 12.0936 13.115 12.2881 13.4993 12.5227L17.598 15.4144Z"/>
  </svg>`,
};
