import { AdminUserAppStatusRequest } from "./repo";

export enum AdminUserAppApiRoutes {
  app = "/api/v1/admin/user-app",
  status = "/api/v1/admin/user-app/status",
}

export interface AdminUserAppProjectUrlGenerator {
  app(): string;
  status(payload: AdminUserAppStatusRequest): string;
}

export class AdminUserAppUrlGenerator
  implements AdminUserAppProjectUrlGenerator
{
  app(): string {
    return AdminUserAppApiRoutes.app;
  }

  status({ id, status, type }: AdminUserAppStatusRequest): string {
    return `${AdminUserAppApiRoutes.status}?id=${id}&status=${status}&type=${type}`;
  }
}
