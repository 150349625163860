import { normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, resolveDirective as _resolveDirective, withDirectives as _withDirectives } from "vue"

const _hoisted_1 = ["innerHTML"]
const _hoisted_2 = ["innerHTML"]
const _hoisted_3 = ["innerHTML"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_UIButton = _resolveComponent("UIButton")!
  const _directive_click_outside = _resolveDirective("click-outside")!

  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(_ctx.b())
  }, [
    _withDirectives((_openBlock(), _createElementBlock("div", {
      class: _normalizeClass(_ctx.b('wrapper'))
    }, [
      _createElementVNode("span", {
        class: _normalizeClass(_ctx.b('icon')),
        innerHTML: _ctx.displayedIcon
      }, null, 10, _hoisted_1),
      _createElementVNode("button", {
        class: _normalizeClass(_ctx.b('close')),
        onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.handleClose && _ctx.handleClose(...args)))
      }, [
        _createElementVNode("span", {
          class: _normalizeClass(_ctx.b('close-icon')),
          innerHTML: _ctx.displayedCloseIcon
        }, null, 10, _hoisted_2)
      ], 2),
      _createElementVNode("div", {
        class: _normalizeClass(_ctx.b('info'))
      }, [
        _createElementVNode("h3", {
          class: _normalizeClass(_ctx.b('title'))
        }, _toDisplayString(_ctx.displayedModal.title), 3),
        (_ctx.displayedModal.label)
          ? (_openBlock(), _createElementBlock("p", {
              key: 0,
              class: _normalizeClass(_ctx.b('label')),
              innerHTML: _ctx.displayedModal.label
            }, null, 10, _hoisted_3))
          : _createCommentVNode("", true)
      ], 2),
      _createVNode(_component_UIButton, {
        class: _normalizeClass(_ctx.b('ok')),
        label: _ctx.displayedModal.actionLabel,
        view: "main",
        onClick: _ctx.handleOk
      }, null, 8, ["class", "label", "onClick"])
    ], 2)), [
      [_directive_click_outside, _ctx.handleHide]
    ])
  ], 2))
}