import { defineComponent } from "vue";

import { COMPONENT_NAME, SVG } from "./attributes";
import { SharedLanguageOption, SharedLanguageOptionLabel } from "./model";

import { SvgAttribute } from "@/shared/constants/constants";
import { Language } from "@/shared/constants/enums";

export default defineComponent({
  name: COMPONENT_NAME,
  props: {
    view: {
      type: String,
      default: "",
    },
    isChevron: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      isVisible: false,
      userRepo: this.$projectServices.userRepo,
      userRepository: this.$projectServices.userRepository,
    };
  },
  computed: {
    displayedLang(): Language {
      return this.userRepo.userInfo.user_info.lang;
    },

    displayedActiveClass(): string {
      return this.isVisible
        ? `${COMPONENT_NAME}__toggle--${this.view}-active`
        : "";
    },

    displayedLanguage(): SharedLanguageOptionLabel {
      switch (this.displayedLang) {
        case Language.ru:
          return SharedLanguageOptionLabel.ru;
        case Language.en:
          return SharedLanguageOptionLabel.en;
        default:
          return SharedLanguageOptionLabel.ru;
      }
    },

    displayedLanguages(): SharedLanguageOption[] {
      return [
        {
          id: 0,
          label: SharedLanguageOptionLabel.ru,
          value: Language.ru,
          isActive: this.displayedLang === Language.ru,
        },
        {
          id: 1,
          label: SharedLanguageOptionLabel.en,
          value: Language.en,
          isActive: this.displayedLang === Language.en,
        },
      ];
    },

    displayedIcons(): SvgAttribute {
      return SVG;
    },

    isAuthenticated(): boolean {
      return this.userRepo.isAuthenticated;
    },
  },
  methods: {
    handleToggle(): void {
      this.isVisible = !this.isVisible;
    },

    handleHide(): void {
      this.isVisible = false;
    },

    async handleOption({ value }: SharedLanguageOption): Promise<void> {
      try {
        if (this.isAuthenticated) {
          await this.userRepository.language({ lang: value });

          this.userRepo.UPDATE_USER_INFO(await this.userRepository.userInfo());
        } else {
          this.userRepo.UPDATE_LANGUAGE(value);
        }

        this.handleHide();
      } catch (e) {
        console.log(e);
      }
    },
  },
});
