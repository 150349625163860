import { resolveDynamicComponent as _resolveDynamicComponent, openBlock as _openBlock, createBlock as _createBlock, resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, normalizeClass as _normalizeClass, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_UILink = _resolveComponent("UILink")!
  const _component_SharedAuthCard = _resolveComponent("SharedAuthCard")!

  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(_ctx.b())
  }, [
    _createVNode(_component_SharedAuthCard, {
      headerSvg: "user",
      headerTitle: "Зарегистрируйте новую учетную запись",
      headerView: "small",
      footerTitle: "У вас уже есть учетная запись?",
      isTerms: _ctx.isTerms
    }, {
      default: _withCtx(() => [
        (_openBlock(), _createBlock(_resolveDynamicComponent(_ctx.displayedComponent), {
          loading: _ctx.isLoading,
          message: _ctx.message,
          email: _ctx.authRegistrationRequest.email,
          "onUpdate:email": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.authRegistrationRequest.email) = $event)),
          password: _ctx.authRegistrationRequest.password,
          "onUpdate:password": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.authRegistrationRequest.password) = $event)),
          password_confirmation: 
            _ctx.authRegistrationRequest.password_confirmation
          ,
          "onUpdate:password_confirmation": _cache[2] || (_cache[2] = ($event: any) => ((
            _ctx.authRegistrationRequest.password_confirmation
          ) = $event)),
          onRegistration: _ctx.handleRegistration,
          onVerification: _ctx.handleVerification,
          onGoogle: _ctx.handleGoogle
        }, null, 40, ["loading", "message", "email", "password", "password_confirmation", "onRegistration", "onVerification", "onGoogle"]))
      ]),
      footer: _withCtx(() => [
        _createVNode(_component_UILink, {
          label: "Вход в систему",
          view: "main-outline",
          to: "/login"
        })
      ]),
      _: 1
    }, 8, ["isTerms"])
  ], 2))
}