import { SvgAttribute } from "@/shared/constants/constants";

export const COMPONENT_NAME = "wallet-main";

export const SVG: SvgAttribute = {
  withdrawal: `<svg width="24" height="24" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
    <path fill-rule="evenodd" clip-rule="evenodd" d="M17.365 3.37346C17.3299 3.36964 17.2942 3.36768 17.2581 3.36768L8.79169 3.36768C8.2525 3.36768 7.8154 3.80478 7.8154 4.34397C7.8154 4.88316 8.2525 5.32026 8.79169 5.32026L14.9695 5.32026L6.29314 13.9966C5.90261 14.3871 5.90261 15.0203 6.29314 15.4108C6.68366 15.8013 7.31683 15.8013 7.70736 15.4108L16.3949 6.72328L16.3949 12.9215C16.3949 13.4607 16.832 13.8978 17.3712 13.8978C17.9104 13.8978 18.3475 13.4607 18.3475 12.9215L18.3475 4.34974C18.3475 3.81055 17.9104 3.37345 17.3712 3.37345C17.3692 3.37345 17.3671 3.37345 17.365 3.37346Z"/>
    <rect x="2" y="20.0451" width="20" height="2" rx="1"/>
  </svg>`,
  deposit: `<svg width="24" height="24" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
    <path fill-rule="evenodd" clip-rule="evenodd" d="M6.97416 15.1972C7.01184 15.2016 7.05017 15.2039 7.08903 15.2039L15.5554 15.2039C16.0946 15.2039 16.5317 14.7668 16.5317 14.2276C16.5317 13.6884 16.0946 13.2513 15.5554 13.2513L9.37795 13.2513L18.0544 4.57493C18.4449 4.18441 18.4449 3.55125 18.0544 3.16072C17.6638 2.7702 17.0307 2.7702 16.6401 3.16072L7.95254 11.8483L7.95254 5.64913C7.95254 5.10994 7.51544 4.67284 6.97625 4.67284C6.43706 4.67284 5.99996 5.10994 5.99996 5.64913L5.99995 14.2209C5.99995 14.7594 6.43593 15.196 6.97416 15.1972Z"/>
    <rect x="2" y="19.5449" width="20" height="2" rx="1"/>
  </svg>`,
};
