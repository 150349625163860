import { FileParams } from "@/shared/constants/interfaces";
import { FileType } from "@/shared/constants/enums";

const IMAGE_ACCEPTS = ["png", "jpg", "jpeg", "bmp", "heic"];
const DOCUMENT_ACCEPTS = [
  "pdf",
  "doc",
  "docx",
  "vnd.openxmlformats-officedocument.wordprocessingml.document",
];

export function extractFileType(file: File): string | undefined {
  return file.name.split(".").pop();
}

export function extractType(fileType: string): FileType {
  if (IMAGE_ACCEPTS.includes(fileType)) return FileType.image;

  return FileType.document;
}

export function extractFileName(file: File): string {
  return file.name.lastIndexOf(".") !== -1
    ? file.name.substring(0, file.name.lastIndexOf("."))
    : file.name;
}

export function convertFile(file: File): Promise<FileParams> {
  return new Promise((resolve, reject) => {
    const fileType = extractFileType(file);

    if (
      fileType &&
      !IMAGE_ACCEPTS.includes(fileType) &&
      !DOCUMENT_ACCEPTS.includes(fileType)
    )
      reject(File);

    const reader = new FileReader();

    reader.readAsDataURL(file);

    reader.onload = () => {
      resolve({
        file: <string>reader.result,
        link: URL.createObjectURL(file),
        type: extractType(fileType!),
        name: extractFileName(file),
      });
    };

    reader.onerror = (error) => {
      reject(error);
    };
  });
}

export function downloadFile(
  base64: string,
  fileName: string,
  mimeType: string
): void {
  const byteCharacters = atob(base64);
  const byteNumbers = new Array(byteCharacters.length);

  for (let i = 0; i < byteCharacters.length; i++) {
    byteNumbers[i] = byteCharacters.charCodeAt(i);
  }

  const byteArray = new Uint8Array(byteNumbers);
  const blob = new Blob([byteArray], { type: mimeType });
  const link = document.createElement("a");

  link.href = URL.createObjectURL(blob);
  link.download = fileName;

  document.body.appendChild(link);

  link.click();

  document.body.removeChild(link);

  URL.revokeObjectURL(link.href);
}
