<script lang="ts" src="./component.ts"></script>
<style lang="scss" src="./component.scss"></style>

<template>
  <button :class="b()" @click="handleRow">
    <div :class="b('row-date-time')">
      <p :class="b('row-label', { dateTime: true })">
        {{ row.created_at }}
      </p>
    </div>

    <div :class="b('row-type')">
      <p :class="b('row-label')">
        {{ row.app_type === "deposit" ? "Пополнение" : "Вывод" }}
      </p>
    </div>

    <div :class="b('row-amount')">
      <p
        :class="
          b('row-label', {
            deposit: row.app_type === 'deposit',
            withdrawal: row.app_type === 'withdrawal',
          })
        "
      >
        {{ displayedAmount }}
      </p>
    </div>

    <div :class="b('row-currency')">
      <p :class="b('row-label', { currency: true })">
        {{ row.currency.name.toUpperCase() }}
      </p>
    </div>

    <div :class="b('row-commission')">
      <p :class="b('row-label')">
        {{ row.commissions }}
      </p>
    </div>

    <div :class="b('row-status')">
      <div :class="b('row-group')">
        <span :class="b('row-icon')" v-html="displayedSvg[row.status]" />

        <p :class="b('row-label', { status: true })">
          {{ displayedStatus }}
        </p>
      </div>
    </div>

    <div :class="b('row-confirm')">
      <button
        v-if="isConfirmShow"
        :class="b('row-button')"
        @click.stop="handleConfirm"
      >
        Подтвердить
      </button>
    </div>

    <div :class="b('row-block')">
      <div :class="b('row-block-group')">
        <div :class="b('row-block-type')">
          <p :class="b('row-label')">
            {{ row.app_type === "deposit" ? "Пополнение" : "Вывод" }}
          </p>

          <span
            :class="
              b('row-label', {
                deposit: row.app_type === 'deposit',
                withdrawal: row.app_type === 'withdrawal',
                block: true,
              })
            "
          >
            {{ row.amount }}

            <p :class="b('row-label', { currency: true })">
              {{ row.currency.name }}
            </p>
          </span>
        </div>

        <div :class="b('row-block-date')">
          <p :class="b('row-label', { dateTime: true })">
            {{ row.created_at }}
          </p>

          <span :class="b('row-icon')" v-html="displayedSvg[row.status]" />
        </div>
      </div>

      <div :class="b('row-block-group')">
        <div :class="b('row-block-commission')">
          <p :class="b('row-label')">
            {{ $t("Комиссия") }}
          </p>

          <p :class="b('row-label')">
            {{ row.commissions }}
          </p>
        </div>

        <div :class="b('row-block-other')">
          <button
            v-if="isConfirmShow"
            :class="b('row-button')"
            @click.stop="handleConfirm"
          >
            Подтвердить
          </button>

          <div :class="b('row-block-number')">
            <p :class="b('row-label')">№ {{ $t("Transaction ") }}</p>

            <p :class="b('row-label')">
              {{ row.id }}
            </p>
          </div>
        </div>
      </div>
    </div>
  </button>
</template>
