import { defineComponent } from "vue";

import { COMPONENT_NAME, SVG } from "./attributes";
import {
  DefaultNavbarLink,
  DefaultNavbarLinkIcon,
  DefaultNavbarLinkLabel,
} from "./model";
import { RoutesTo } from "@/shared/constants/enums";

export default defineComponent({
  name: COMPONENT_NAME,
  data() {
    return {
      userRepo: this.$projectServices.userRepo,
    };
  },
  computed: {
    displayedLinks(): DefaultNavbarLink[] {
      return [
        {
          id: 0,
          label: DefaultNavbarLinkLabel.wallet,
          to: RoutesTo.wallet,
          icon: SVG[DefaultNavbarLinkIcon.wallet],
          isDisabled: !this.isVerified,
        },
        {
          id: 1,
          label: DefaultNavbarLinkLabel.application,
          to: RoutesTo.application,
          icon: SVG[DefaultNavbarLinkIcon.application],
          isDisabled: !this.isVerified,
        },
        {
          id: 2,
          label: DefaultNavbarLinkLabel.history,
          to: RoutesTo.history,
          icon: SVG[DefaultNavbarLinkIcon.history],
          isDisabled: !this.isVerified,
        },
        {
          id: 3,
          label: DefaultNavbarLinkLabel.settings,
          to: RoutesTo.settings,
          icon: SVG[DefaultNavbarLinkIcon.settings],
          isDisabled: !this.isVerified,
        },
      ];
    },

    isVerified(): boolean {
      return this.userRepo.isVerified;
    },
  },
});
