<template>
  <div :class="b()">
    <transition name="opacity">
      <SharedNotification
        v-if="isNotificationShow"
        :class="b('notification')"
      />
    </transition>

    <transition name="opacity">
      <SharedModal v-if="isModalShow" :class="b('modal')" />
    </transition>

    <transition name="opacity">
      <SharedDeposit v-if="isDepositShow" :class="b('modal')" />
    </transition>

    <component v-if="isLoading" :is="displayedLayout" />
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";

import { AdminLayout, AuthLayout, DefaultLayout } from "@/layouts";
import {
  SharedNotification,
  SharedModal,
  SharedDeposit,
} from "@/components/shared";

import { Layout } from "@/shared/constants/enums";

type DisplayedLayout =
  | typeof AuthLayout
  | typeof DefaultLayout
  | typeof AdminLayout;

export default defineComponent({
  name: "app",
  components: {
    AuthLayout,
    DefaultLayout,
    AdminLayout,
    SharedNotification,
    SharedModal,
    SharedDeposit,
  },
  data() {
    return {
      isLoading: false,
      modalRepo: this.$projectServices.modalRepo,
      notificationRepo: this.$projectServices.notificationRepo,
      depositWithdrawalRepo: this.$projectServices.depositWithdrawalRepo,
      authRepository: this.$projectServices.authRepository,
      parserRepository: this.$projectServices.parserRepository,
    };
  },
  async created() {
    try {
      await Promise.allSettled([
        this.authRepository.csfr(),
        this.parserRepository.login(),
      ]);

      this.isLoading = true;
    } catch (e) {
      this.isLoading = true;
      console.log(e);
    }
  },
  computed: {
    displayedLayout(): DisplayedLayout {
      const { layout } = this.$route.meta;

      switch (layout) {
        case Layout.default:
          return DefaultLayout;
        case Layout.auth:
          return AuthLayout;
        case Layout.admin:
          return AdminLayout;
        default:
          return DefaultLayout;
      }
    },

    isNotificationShow(): boolean {
      return !!this.notificationRepo.notification;
    },

    isModalShow(): boolean {
      return !!this.modalRepo.modal;
    },

    isDepositShow(): boolean {
      const { id, currency_id } = this.depositWithdrawalRepo.deposit;

      return !!id && !!currency_id;
    },
  },
});
</script>

<style lang="scss">
.app {
  @include full-screen;

  position: relative;

  &__notification {
    position: fixed;
    top: 30px;
    left: 50%;
    transform: translateX(-50%);
    z-index: 200;
  }

  &__modal {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 999999;
  }

  .opacity-enter-active {
    transition: $transition;
  }

  .opacity-leave-active {
    transition: $transition;
  }

  .opacity-enter-from,
  .opacity-leave-to {
    opacity: 0;
  }
}
</style>
