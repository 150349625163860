import { defineComponent, PropType } from "vue";

import { COMPONENT_NAME } from "./attributes";

import { SettingsRequisitesVerification } from "../verification";
import { SettingsRequisitesComponent } from "../model";

import { SharedCard, SharedBack, SharedValidate } from "@/components/shared";
import { UIInput, UIButton } from "@/components/ui";

import {
  RequisitesBankFieldsFactory,
  RequisitesBankRequestFactory,
  RequisitesBankUpdateRequestFactory,
} from "@/shared/repository/modules/requisites/factory";

export default defineComponent({
  name: COMPONENT_NAME,
  components: {
    SettingsRequisitesVerification,
    SharedCard,
    SharedBack,
    SharedValidate,
    UIInput,
    UIButton,
  },
  props: {
    component: {
      type: <PropType<SettingsRequisitesComponent>>String,
      required: true,
    },
  },
  emits: {
    "update:component": null,
  },
  data() {
    return {
      isValidate: false,
      isLoading: false,
      isVerification: false,
      requisitesRepo: this.$projectServices.requisitesRepo,
      requisitesRepository: this.$projectServices.requisitesRepository,
      requisitesBankRequest: RequisitesBankRequestFactory(),
      requisitesBankFields: RequisitesBankFieldsFactory(),
    };
  },
  async created(): Promise<void> {
    try {
      this.isLoading = true;

      if (this.requisitesRepo.bankId) {
        this.requisitesBankRequest = await this.requisitesRepository.bankShow(
          this.requisitesRepo.bankId
        );
      }
    } catch (error) {
      console.log(error);
    } finally {
      this.isLoading = false;
    }
  },
  computed: {
    isDisabled(): boolean {
      return Object.values(this.requisitesBankFields).some((field) => !field);
    },

    isContinueDisabled(): boolean {
      return this.isLoading;
    },
  },
  methods: {
    handleBack(): void {
      this.$emit("update:component", SettingsRequisitesComponent.main);

      this.requisitesRepo.UPDATE_BANK_ID(null);
    },

    handleVerificationClose(): void {
      this.isVerification = false;

      this.$emit("update:component", SettingsRequisitesComponent.main);

      this.requisitesRepo.UPDATE_BANK_ID(null);
    },

    async handleContinue(): Promise<void> {
      try {
        this.isLoading = true;

        if (this.isDisabled) {
          this.isValidate = true;

          return;
        }

        if (this.requisitesRepo.bankId) {
          await this.requisitesRepository.bankUpdate(
            this.requisitesRepo.bankId,
            RequisitesBankUpdateRequestFactory(this.requisitesBankRequest)
          );

          this.handleBack();
        } else {
          await this.requisitesRepository.bank(this.requisitesBankRequest);

          this.isVerification = true;
        }
      } catch (e) {
        console.log(e);
      } finally {
        this.isLoading = false;
      }
    },
  },
});
