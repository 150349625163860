import { App } from "vue";
import { getModule } from "vuex-module-decorators";

import { ProjectServices } from "./repo";

import store from "@/store";
import ProfileModuleState from "@/store/modules/profile";
import NotificationModuleState from "@/store/modules/notification";
import ModalModuleState from "@/store/modules/modal";
import UserModuleState from "@/store/modules/user";
import UsersModuleState from "@/store/modules/users";
import KybModuleState from "@/store/modules/kyb";
import RequisitesModuleState from "@/store/modules/requisites";
import AdminCurrencyModuleState from "@/store/modules/admin-currency";
import AdminRequisitesModuleState from "@/store/modules/admin-requisites";
import DepositWithdrawalModuleState from "@/store/modules/deposit-withdrawal";
import WalletModuleState from "@/store/modules/wallet";
import HistoryModuleState from "@/store/modules/history";
import ApplicationModuleState from "@/store/modules/application";

import {
  AdminUsersHttpRepo,
  AdminUsersUrlGenerator,
} from "@/shared/repository/modules/admin/users";
import {
  AdminUserAppHttpRepo,
  AdminUserAppUrlGenerator,
} from "@/shared/repository/modules/admin/user-app";
import {
  AdminCommissionHttpRepo,
  AdminCommissionUrlGenerator,
} from "@/shared/repository/modules/admin/commission";
import {
  AdminCurrencyHttpRepo,
  AdminCurrencyUrlGenerator,
} from "@/shared/repository/modules/admin/currency";
import {
  AdminRequisitesHttpRepo,
  AdminRequisitesUrlGenerator,
} from "@/shared/repository/modules/admin/requisites";
import {
  AdminApplicationHttpRepo,
  AdminApplicationUrlGenerator,
} from "@/shared/repository/modules/admin/application";
import {
  AdminDepositWithdrawalHttpRepo,
  AdminDepositWithdrawalUrlGenerator,
} from "@/shared/repository/modules/admin/deposit-withdrawal";
import {
  UserHttpRepo,
  UserUrlGenerator,
} from "@/shared/repository/modules/user";
import {
  AuthHttpRepo,
  AuthUrlGenerator,
} from "@/shared/repository/modules/auth";
import { KycHttpRepo, KycUrlGenerator } from "@/shared/repository/modules/kyc";
import { KybHttpRepo, KybUrlGenerator } from "@/shared/repository/modules/kyb";
import {
  ParserHttpRepo,
  ParserUrlGenerator,
} from "@/shared/repository/modules/parser";
import {
  RequisitesHttpRepo,
  RequisitesUrlGenerator,
} from "@/shared/repository/modules/requisites";
import { WalletHttpRepo, WalletUrlGenerator } from "./modules/wallet";
import {
  DepositWithdrawalHttpRepo,
  DepositWithdrawalUrlGenerator,
} from "@/shared/repository/modules/deposit-withdrawal";
import {
  ApplicationHttpRepo,
  ApplicationUrlGenerator,
} from "@/shared/repository/modules/application";

export default function createProjectServices(context: App): ProjectServices {
  const { $axios } = context.config.globalProperties;
  const $store = store();

  const profileRepo = getModule(ProfileModuleState, $store);
  const notificationRepo = getModule(NotificationModuleState, $store);
  const modalRepo = getModule(ModalModuleState, $store);
  const userRepo = getModule(UserModuleState, $store);
  const usersRepo = getModule(UsersModuleState, $store);
  const kybRepo = getModule(KybModuleState, $store);
  const requisitesRepo = getModule(RequisitesModuleState, $store);
  const adminCurrencyRepo = getModule(AdminCurrencyModuleState, $store);
  const adminRequisitesRepo = getModule(AdminRequisitesModuleState, $store);
  const depositWithdrawalRepo = getModule(DepositWithdrawalModuleState, $store);
  const walletRepo = getModule(WalletModuleState, $store);
  const historyRepo = getModule(HistoryModuleState, $store);
  const applicationRepo = getModule(ApplicationModuleState, $store);

  const adminUsersRepository = new AdminUsersHttpRepo(
    $axios,
    new AdminUsersUrlGenerator()
  );
  const adminUserAppRepository = new AdminUserAppHttpRepo(
    $axios,
    new AdminUserAppUrlGenerator()
  );
  const adminCommissionRepository = new AdminCommissionHttpRepo(
    $axios,
    new AdminCommissionUrlGenerator()
  );
  const adminCurrencyRepository = new AdminCurrencyHttpRepo(
    $axios,
    new AdminCurrencyUrlGenerator()
  );
  const adminRequisitesRepository = new AdminRequisitesHttpRepo(
    $axios,
    new AdminRequisitesUrlGenerator()
  );
  const adminApplicationRepository = new AdminApplicationHttpRepo(
    $axios,
    new AdminApplicationUrlGenerator()
  );
  const adminDepositWithdrawalRepository = new AdminDepositWithdrawalHttpRepo(
    $axios,
    new AdminDepositWithdrawalUrlGenerator()
  );
  const userRepository = new UserHttpRepo(
    $axios,
    new UserUrlGenerator(),
    userRepo
  );
  const authRepository = new AuthHttpRepo($axios, new AuthUrlGenerator());
  const kycRepository = new KycHttpRepo(
    $axios,
    new KycUrlGenerator(),
    userRepo
  );
  const kybRepository = new KybHttpRepo(
    $axios,
    new KybUrlGenerator(),
    userRepo
  );
  const parserRepository = new ParserHttpRepo(new ParserUrlGenerator());
  const requisitesRepository = new RequisitesHttpRepo(
    $axios,
    new RequisitesUrlGenerator(),
    userRepo
  );
  const walletRepository = new WalletHttpRepo(
    $axios,
    new WalletUrlGenerator(),
    userRepo
  );
  const depositWithdrawalRepository = new DepositWithdrawalHttpRepo(
    $axios,
    new DepositWithdrawalUrlGenerator()
  );
  const applicationRepository = new ApplicationHttpRepo(
    $axios,
    new ApplicationUrlGenerator(),
    userRepo
  );

  return {
    profileRepo,
    notificationRepo,
    modalRepo,
    userRepo,
    usersRepo,
    kybRepo,
    requisitesRepo,
    adminCurrencyRepo,
    adminRequisitesRepo,
    depositWithdrawalRepo,
    walletRepo,
    historyRepo,
    applicationRepo,

    adminUsersRepository,
    adminUserAppRepository,
    adminCommissionRepository,
    adminCurrencyRepository,
    adminRequisitesRepository,
    adminApplicationRepository,
    adminDepositWithdrawalRepository,
    userRepository,
    authRepository,
    kycRepository,
    kybRepository,
    parserRepository,
    requisitesRepository,
    walletRepository,
    depositWithdrawalRepository,
    applicationRepository,
  };
}
