import { normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_SharedSelect = _resolveComponent("SharedSelect")!
  const _component_UIButton = _resolveComponent("UIButton")!

  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(_ctx.b())
  }, [
    _createElementVNode("div", {
      class: _normalizeClass(_ctx.b('fields'))
    }, [
      _createElementVNode("div", {
        class: _normalizeClass(_ctx.b('field'))
      }, [
        _createElementVNode("p", {
          class: _normalizeClass(_ctx.b('field-label'))
        }, "Монета", 2),
        _createVNode(_component_SharedSelect, {
          view: "light",
          options: _ctx.displayedOptions,
          icon: _ctx.adminCurrency.name,
          "is-crypto-icon": true,
          "is-search": true,
          value: _ctx.adminCurrency.name,
          "onUpdate:value": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.adminCurrency.name) = $event))
        }, null, 8, ["options", "icon", "value"])
      ], 2)
    ], 2),
    _createVNode(_component_UIButton, {
      label: _ctx.displayedButtonLabel,
      view: "main",
      disabled: _ctx.isButtonDisabled,
      onClick: _ctx.handleButton
    }, null, 8, ["label", "disabled", "onClick"])
  ], 2))
}