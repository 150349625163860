<script lang="ts" src="./component.ts"></script>
<style lang="scss" src="./component.scss"></style>

<template>
  <div :class="b()">
    <h2 :class="b('title')">
      {{ $t("Персональная информация") }}
    </h2>

    <div :class="b('form')">
      <div :class="b('fields')">
        <div :class="b('field')">
          <h4 :class="b('field-label')">
            {{ $t("Фамилия") }}
          </h4>

          <UIInput
            view="light"
            :is-readonly="true"
            v-model="kycPersonalInfo.sur_name"
          />
        </div>

        <div :class="b('field')">
          <h4 :class="b('field-label')">
            {{ $t("Имя") }}
          </h4>

          <UIInput
            view="light"
            :is-readonly="true"
            v-model="kycPersonalInfo.name"
          />
        </div>

        <div :class="b('field')">
          <h4 :class="b('field-label')">
            {{ $t("Отчество") }}
          </h4>

          <UIInput
            view="light"
            :is-readonly="true"
            v-model="kycPersonalInfo.patronymic"
          />
        </div>

        <div :class="b('field')">
          <h4 :class="b('field-label')">
            {{ $t("Укажите ваш пол") }}
          </h4>

          <div :class="b('field-radios')">
            <div :class="b('field-radio')">
              <SharedRadio
                view="light"
                checked-value="male"
                :is-readonly="true"
                v-model:value="kycPersonalInfo.gender"
              />

              <p :class="b('field-radio-label')">
                {{ $t("Мужчина") }}
              </p>
            </div>

            <div :class="b('field-radio')">
              <SharedRadio
                view="light"
                checked-value="female"
                :is-readonly="true"
                v-model:value="kycPersonalInfo.gender"
              />

              <p :class="b('field-radio-label')">
                {{ $t("Женщина") }}
              </p>
            </div>
          </div>
        </div>

        <div :class="b('field')">
          <h4 :class="b('field-label')">
            {{ $t("Дата рождения") }}
          </h4>

          <SharedDate
            v-model:date="kycPersonalInfo.birth_date"
            :is-readonly="true"
            format="yyyy-MM-dd"
          />
        </div>

        <div :class="b('field')">
          <h4 :class="b('field-label')">
            {{ $t("Гражданство") }}
          </h4>

          <UIInput
            view="light"
            :is-readonly="true"
            v-model="kycPersonalInfo.citizenship"
          />
        </div>

        <div :class="b('field')">
          <h4 :class="b('field-label')">
            {{ $t("Серия и номер паспорта") }}
          </h4>

          <UIInput
            view="light"
            maska="##########"
            :is-readonly="true"
            v-model="kycPersonalInfo.passport"
          />
        </div>

        <div :class="b('field')">
          <h4 :class="b('field-label')">
            {{ $t("Дата выдачи паспорта") }}
          </h4>

          <UIInput
            view="light"
            maska="####-##-##"
            :is-readonly="true"
            v-model="kycPersonalInfo.passport_date"
          />
        </div>

        <div :class="b('field')">
          <h4 :class="b('field-label')">
            {{ $t("Наименование органа выдавшего документ") }}
          </h4>

          <UIInput
            view="light"
            :is-readonly="true"
            v-model="kycPersonalInfo.passport_issued_org"
          />
        </div>
      </div>

      <div :class="b('address')">
        <h4 :class="b('field-label')">
          {{ $t("Адрес регистрации") }}
        </h4>

        <div :class="b('fields', { address: true })">
          <UIInput
            view="light"
            :is-readonly="true"
            v-model="kycPersonalInfo.address.country"
          />

          <UIInput
            view="light"
            :is-readonly="true"
            v-model="kycPersonalInfo.address.city"
          />

          <UIInput
            view="light"
            :is-readonly="true"
            v-model="kycPersonalInfo.address.state"
          />

          <UIInput
            view="light"
            :is-readonly="true"
            v-model="kycPersonalInfo.address.street"
          />

          <UIInput
            view="light"
            :is-readonly="true"
            v-model="kycPersonalInfo.address.office"
          />

          <UIInput
            view="light"
            :is-readonly="true"
            v-model="kycPersonalInfo.address.zip"
          />
        </div>
      </div>

      <div :class="b('fields')">
        <div :class="b('field')">
          <h4 :class="b('field-label')">
            {{ $t("Номер телефона") }}
          </h4>

          <UIInput
            view="light"
            :is-readonly="true"
            v-model="kycPersonalInfo.phone"
          />
        </div>

        <div :class="b('field')">
          <h4 :class="b('field-label')">
            {{ $t("Телеграм для связи") }}
          </h4>

          <UIInput
            view="light"
            :is-telegram="true"
            :is-readonly="true"
            v-model="kycPersonalInfo.telegram"
          />
        </div>
      </div>
    </div>
  </div>
</template>
