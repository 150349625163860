import { toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_UIButton = _resolveComponent("UIButton")!
  const _component_SharedWindow = _resolveComponent("SharedWindow")!

  return (_openBlock(), _createBlock(_component_SharedWindow, {
    class: _normalizeClass(_ctx.b()),
    onClose: _ctx.handleCancel
  }, {
    default: _withCtx(() => [
      _createElementVNode("div", {
        class: _normalizeClass(_ctx.b('content'))
      }, [
        _createElementVNode("h2", {
          class: _normalizeClass(_ctx.b('title'))
        }, _toDisplayString(_ctx.$t(_ctx.displayedTitle)), 3),
        _createElementVNode("div", {
          class: _normalizeClass(_ctx.b('buttons'))
        }, [
          _createVNode(_component_UIButton, {
            label: "Отменить",
            view: "main-outline",
            onClick: _ctx.handleCancel
          }, null, 8, ["onClick"]),
          _createVNode(_component_UIButton, {
            label: "Продолжить",
            view: "main",
            onClick: _ctx.handleContinue
          }, null, 8, ["onClick"])
        ], 2)
      ], 2)
    ]),
    _: 1
  }, 8, ["class", "onClose"]))
}