import {
  AuthForgotRequest,
  AuthLoginRequest,
  AuthRegistrationRequest,
} from "./repo";

export const AuthLoginRequestFactory = (
  payload: Partial<AuthLoginRequest> = {}
): AuthLoginRequest => {
  return {
    email: payload.email ?? "",
    password: payload.password ?? "",
    remember: payload.remember ?? false,
  };
};

export const AuthForgotRequestFactory = (
  payload: Partial<AuthForgotRequest> = {}
): AuthForgotRequest => {
  return {
    email: payload.email ?? "",
    code: payload.code ?? "",
    password: payload.password ?? "",
    password_confirmation: payload.password_confirmation ?? "",
  };
};

export const AuthRegistrationRequestFactory = (
  payload: Partial<AuthRegistrationRequest> = {}
): AuthRegistrationRequest => {
  return {
    name: payload.name ?? "",
    email: payload.email ?? "",
    password: payload.password ?? "",
    password_confirmation: payload.password_confirmation ?? "",
  };
};
