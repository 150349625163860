<script lang="ts" src="./component.ts"></script>
<style lang="scss" src="./component.scss"></style>

<template>
  <div :class="b()">
    <p :class="b('label')">
      {{ requisite.currency.name.toUpperCase() }}
    </p>

    <div :class="b('action')" v-click-outside="handleHide">
      <button :class="b('toggle', { active: isVisible })" @click="handleToggle">
        <span :class="b('toggle-icon')" v-html="displayedIcons.dots"></span>
      </button>

      <div :class="b('options', { active: isVisible })">
        <button :class="b('option')" :disabled="isLoading" @click="handleEdit">
          {{ $t("Редактировать") }}
        </button>

        <button
          :class="b('option')"
          :disabled="isLoading"
          @click="handleRemove"
        >
          {{ $t("Удалить") }}
        </button>
      </div>
    </div>
  </div>
</template>
