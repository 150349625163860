/* eslint-disable @typescript-eslint/ban-ts-comment */
import { Action, Module, Mutation, VuexModule } from "vuex-module-decorators";

import { $projectServices } from "@/shared/repository/instances/project-services";
import {
  AdminRequisite,
  AdminRequisitesListResponse,
} from "@/shared/repository/modules/admin/requisites/repo";
import { AdminRequisiteFactory } from "@/shared/repository/modules/admin/requisites/factory";

enum AdminRequisitesMutation {
  UPDATE_ID = "UPDATE_ID",
  UPDATE_IS_SHOW = "UPDATE_IS_SHOW",
  UPDATE_REQUISITE = "UPDATE_REQUISITE",
  UPDATE_LIST = "UPDATE_LIST",
}

interface State {
  requisite?: AdminRequisite;
  list?: AdminRequisitesListResponse[];
}

@Module({
  namespaced: true,
  stateFactory: true,
  name: "adminRequisites",
})
export default class AdminRequisitesModuleState extends VuexModule<State> {
  requisite: AdminRequisite = AdminRequisiteFactory();
  list: AdminRequisitesListResponse[] = [];

  @Mutation
  [AdminRequisitesMutation.UPDATE_ID](id: number): void {
    this.requisite.id = id;
  }

  @Mutation
  [AdminRequisitesMutation.UPDATE_IS_SHOW](isShow: boolean): void {
    this.requisite.isShow = isShow;
  }

  @Mutation
  [AdminRequisitesMutation.UPDATE_REQUISITE](requisite: AdminRequisite): void {
    this.requisite = requisite;
  }

  @Mutation
  [AdminRequisitesMutation.UPDATE_LIST](
    list: AdminRequisitesListResponse[]
  ): void {
    this.list = list;
  }

  @Action
  async updateList(): Promise<void> {
    this.UPDATE_LIST(await $projectServices.adminRequisitesRepository.list());

    return;
  }
}
