/* eslint-disable @typescript-eslint/ban-ts-comment */
import { Action, Module, Mutation, VuexModule } from "vuex-module-decorators";

import {
  AdminCurrencyListResponse,
  AdminCurrencyModal,
} from "@/shared/repository/modules/admin/currency/repo";
import {
  AdminCurrencyListResponseFactory,
  AdminCurrencyModalFactory,
} from "@/shared/repository/modules/admin/currency/factory";
import { AdminCurrencyType } from "@/shared/repository/modules/admin/currency/enums";
import { $projectServices } from "@/shared/repository/instances/project-services";

enum AdminCurrencyMutation {
  UPDATE_ID = "UPDATE_ID",
  UPDATE_TYPE = "UPDATE_TYPE",
  UPDATE_IS_SHOW = "UPDATE_IS_SHOW",
  UPDATE_MODAL = "UPDATE_MODAL",
  UPDATE_LIST = "UPDATE_LIST",
}

interface State {
  modal?: AdminCurrencyModal;
  list?: AdminCurrencyListResponse;
}

@Module({
  namespaced: true,
  stateFactory: true,
  name: "adminCurrency",
})
export default class AdminCurrencyModuleState extends VuexModule<State> {
  modal: AdminCurrencyModal = AdminCurrencyModalFactory();
  list: AdminCurrencyListResponse = AdminCurrencyListResponseFactory();

  @Mutation
  [AdminCurrencyMutation.UPDATE_ID](id: number): void {
    this.modal.id = id;
  }

  @Mutation
  [AdminCurrencyMutation.UPDATE_TYPE](type: AdminCurrencyType): void {
    this.modal.type = type;
  }

  @Mutation
  [AdminCurrencyMutation.UPDATE_IS_SHOW](isShow: boolean): void {
    this.modal.isShow = isShow;
  }

  @Mutation
  [AdminCurrencyMutation.UPDATE_MODAL](modal: AdminCurrencyModal): void {
    this.modal = modal;
  }

  @Mutation
  [AdminCurrencyMutation.UPDATE_LIST](list: AdminCurrencyListResponse): void {
    this.list = list;
  }

  @Action
  async updateList(): Promise<void> {
    this.UPDATE_LIST(await $projectServices.adminCurrencyRepository.list());

    return;
  }
}
