import { AxiosInstance } from "axios";

import { AdminRequisitesUrlGenerator } from "./url-generator";
import {
  AdminRequisitesListResponse,
  AdminRequisitesShowResponse,
  AdminRequisitesStoreRequest,
  AdminRequisitesUpdateRequest,
} from "./repo";
import { ResponseData } from "@/shared/constants/interfaces";
import {
  AdminRequisitesListResponseFactory,
  AdminRequisitesShowResponseFactory,
} from "./factory";

export interface AdminRequisitesProjectRepository {
  store(payload: AdminRequisitesStoreRequest): Promise<void>;
  update(payload: AdminRequisitesUpdateRequest): Promise<void>;
  delete(id: number): Promise<void>;
  bankDelete(id: number): Promise<void>;
  show(id: number): Promise<AdminRequisitesShowResponse>;
  list(): Promise<AdminRequisitesListResponse[]>;
}

export class AdminRequisitesHttpRepo
  implements AdminRequisitesProjectRepository
{
  constructor(
    private readonly axios: AxiosInstance,
    private readonly urlGenerator: AdminRequisitesUrlGenerator
  ) {}

  async store(payload: AdminRequisitesStoreRequest): Promise<void> {
    await this.axios.post<void>(this.urlGenerator.store(), payload);
  }

  async update(payload: AdminRequisitesUpdateRequest): Promise<void> {
    await this.axios.patch<void>(this.urlGenerator.update(), payload);
  }

  async delete(id: number): Promise<void> {
    await this.axios.delete<void>(this.urlGenerator.delete(id));
  }

  async bankDelete(id: number): Promise<void> {
    await this.axios.delete<void>(this.urlGenerator.delete(id));
  }

  async show(id: number): Promise<AdminRequisitesShowResponse> {
    const { data } = await this.axios.get<
      ResponseData<AdminRequisitesShowResponse>
    >(this.urlGenerator.show(id));

    return AdminRequisitesShowResponseFactory(data.data);
  }

  async list(): Promise<AdminRequisitesListResponse[]> {
    const { data } = await this.axios.get<
      ResponseData<AdminRequisitesListResponse[]>
    >(this.urlGenerator.list());

    return data.data.map((requisite) =>
      AdminRequisitesListResponseFactory(requisite)
    );
  }
}
