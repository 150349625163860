import { defineComponent } from "vue";

import { COMPONENT_NAME } from "./attributes";

import { UIInput, UIButton } from "@/components/ui";
import { SharedWindow } from "@/components/shared";

import { WalletModalFactory } from "@/shared/repository/modules/wallet/factory";
import { ModalFactory } from "@/shared/factory/modal";
import {
  ModalIcon,
  ModalLabel,
  ModalTitle,
} from "@/shared/constants/constants";
import { AdminRequisitesListResponseFactory } from "@/shared/repository/modules/admin/commission/factory";

export default defineComponent({
  name: COMPONENT_NAME,
  components: {
    UIInput,
    UIButton,
    SharedWindow,
  },
  data() {
    return {
      isLoading: false,
      requisites: AdminRequisitesListResponseFactory(),
      walletRepo: this.$projectServices.walletRepo,
      modalRepo: this.$projectServices.modalRepo,
      depositWithdrawalRepository:
        this.$projectServices.depositWithdrawalRepository,
    };
  },
  async created(): Promise<void> {
    try {
      this.isLoading = true;

      this.requisites = await this.depositWithdrawalRepository.requisite(
        this.walletRepo.modal.currencyId
      );
    } catch (error) {
      console.log(error);
    } finally {
      this.isLoading = false;
    }
  },
  computed: {
    displayedTitle(): string {
      return `Ввод ${this.walletRepo.modal.currencyName?.toUpperCase()}`;
    },

    displayedLabel(): string {
      const { amount, currencyName } = this.walletRepo.modal;

      return `Отправьте ${amount} ${currencyName?.toUpperCase()} по этим реквизитам:`;
    },
  },
  methods: {
    handleClose(): void {
      this.walletRepo.UPDATE_MODAL(WalletModalFactory());
    },

    handleConfirm(): void {
      this.handleClose();

      this.modalRepo.UPDATE_MODAL(
        ModalFactory({
          title: ModalTitle.application,
          label: ModalLabel.applicationDeposit,
          icon: ModalIcon.application,
          callback: () => {},
        })
      );
    },
  },
});
