import { toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_UIInput = _resolveComponent("UIInput")!
  const _component_SharedValidate = _resolveComponent("SharedValidate")!
  const _component_SharedRadio = _resolveComponent("SharedRadio")!
  const _component_SharedDate = _resolveComponent("SharedDate")!
  const _component_SharedCountry = _resolveComponent("SharedCountry")!
  const _component_UIButton = _resolveComponent("UIButton")!
  const _component_SharedCard = _resolveComponent("SharedCard")!

  return (_openBlock(), _createBlock(_component_SharedCard, {
    class: _normalizeClass(_ctx.b())
  }, {
    default: _withCtx(() => [
      _createElementVNode("h3", {
        class: _normalizeClass(_ctx.b('title'))
      }, _toDisplayString(_ctx.$t("Персональная информация")), 3),
      _createElementVNode("div", {
        class: _normalizeClass(_ctx.b('form'))
      }, [
        _createVNode(_component_SharedValidate, {
          field: "sur_name",
          value: _ctx.kycPersonalInfo.sur_name,
          "is-validate": _ctx.isValidate,
          "field-value": _ctx.kycPersonalInfoFields.sur_name,
          "onUpdate:fieldValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.kycPersonalInfoFields.sur_name) = $event))
        }, {
          default: _withCtx(() => [
            _createElementVNode("div", {
              class: _normalizeClass(_ctx.b('form-group'))
            }, [
              _createElementVNode("h5", {
                class: _normalizeClass(_ctx.b('form-label'))
              }, _toDisplayString(_ctx.$t("Фамилия*")), 3),
              _createVNode(_component_UIInput, {
                view: "light",
                "is-readonly": _ctx.isReadonly,
                "is-error": _ctx.isValidate && !_ctx.kycPersonalInfoFields.sur_name,
                modelValue: _ctx.kycPersonalInfo.sur_name,
                "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.kycPersonalInfo.sur_name) = $event))
              }, null, 8, ["is-readonly", "is-error", "modelValue"])
            ], 2)
          ]),
          _: 1
        }, 8, ["value", "is-validate", "field-value"]),
        _createVNode(_component_SharedValidate, {
          field: "name",
          value: _ctx.kycPersonalInfo.name,
          "is-validate": _ctx.isValidate,
          "field-value": _ctx.kycPersonalInfoFields.name,
          "onUpdate:fieldValue": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.kycPersonalInfoFields.name) = $event))
        }, {
          default: _withCtx(() => [
            _createElementVNode("div", {
              class: _normalizeClass(_ctx.b('form-group'))
            }, [
              _createElementVNode("h5", {
                class: _normalizeClass(_ctx.b('form-label'))
              }, _toDisplayString(_ctx.$t("Имя*")), 3),
              _createVNode(_component_UIInput, {
                view: "light",
                "is-readonly": _ctx.isReadonly,
                "is-error": _ctx.isValidate && !_ctx.kycPersonalInfoFields.name,
                modelValue: _ctx.kycPersonalInfo.name,
                "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.kycPersonalInfo.name) = $event))
              }, null, 8, ["is-readonly", "is-error", "modelValue"])
            ], 2)
          ]),
          _: 1
        }, 8, ["value", "is-validate", "field-value"]),
        _createElementVNode("div", {
          class: _normalizeClass(_ctx.b('form-group'))
        }, [
          _createElementVNode("h5", {
            class: _normalizeClass(_ctx.b('form-label'))
          }, _toDisplayString(_ctx.$t("Отчество")), 3),
          _createVNode(_component_UIInput, {
            view: "light",
            "is-readonly": _ctx.isReadonly,
            modelValue: _ctx.kycPersonalInfo.patronymic,
            "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => ((_ctx.kycPersonalInfo.patronymic) = $event))
          }, null, 8, ["is-readonly", "modelValue"])
        ], 2),
        _createVNode(_component_SharedValidate, {
          field: "gender",
          value: _ctx.kycPersonalInfo.gender,
          "is-validate": _ctx.isValidate,
          "field-value": _ctx.kycPersonalInfoFields.gender,
          "onUpdate:fieldValue": _cache[7] || (_cache[7] = ($event: any) => ((_ctx.kycPersonalInfoFields.gender) = $event))
        }, {
          default: _withCtx(() => [
            _createElementVNode("div", {
              class: _normalizeClass(_ctx.b('form-group'))
            }, [
              _createElementVNode("h5", {
                class: _normalizeClass(_ctx.b('form-label'))
              }, _toDisplayString(_ctx.$t("Укажите ваш пол*")), 3),
              _createElementVNode("div", {
                class: _normalizeClass(_ctx.b('form-radios'))
              }, [
                _createElementVNode("div", {
                  class: _normalizeClass(_ctx.b('form-radio'))
                }, [
                  _createVNode(_component_SharedRadio, {
                    view: "light",
                    "checked-value": "male",
                    "is-readonly": _ctx.isReadonly,
                    "is-error": _ctx.isValidate && !_ctx.kycPersonalInfoFields.gender,
                    value: _ctx.kycPersonalInfo.gender,
                    "onUpdate:value": _cache[5] || (_cache[5] = ($event: any) => ((_ctx.kycPersonalInfo.gender) = $event))
                  }, null, 8, ["is-readonly", "is-error", "value"]),
                  _createElementVNode("p", {
                    class: _normalizeClass(_ctx.b('form-radio-label'))
                  }, _toDisplayString(_ctx.$t("Мужчина")), 3)
                ], 2),
                _createElementVNode("div", {
                  class: _normalizeClass(_ctx.b('form-radio'))
                }, [
                  _createVNode(_component_SharedRadio, {
                    view: "light",
                    "checked-value": "female",
                    "is-readonly": _ctx.isReadonly,
                    "is-error": _ctx.isValidate && !_ctx.kycPersonalInfoFields.gender,
                    value: _ctx.kycPersonalInfo.gender,
                    "onUpdate:value": _cache[6] || (_cache[6] = ($event: any) => ((_ctx.kycPersonalInfo.gender) = $event))
                  }, null, 8, ["is-readonly", "is-error", "value"]),
                  _createElementVNode("p", {
                    class: _normalizeClass(_ctx.b('form-radio-label'))
                  }, _toDisplayString(_ctx.$t("Женщина")), 3)
                ], 2)
              ], 2)
            ], 2)
          ]),
          _: 1
        }, 8, ["value", "is-validate", "field-value"]),
        _createVNode(_component_SharedValidate, {
          field: "birth_date",
          value: _ctx.kycPersonalInfo.birth_date,
          "is-validate": _ctx.isValidate,
          "field-value": _ctx.kycPersonalInfoFields.birth_date,
          "onUpdate:fieldValue": _cache[9] || (_cache[9] = ($event: any) => ((_ctx.kycPersonalInfoFields.birth_date) = $event))
        }, {
          default: _withCtx(() => [
            _createElementVNode("div", {
              class: _normalizeClass(_ctx.b('form-group'))
            }, [
              _createElementVNode("h5", {
                class: _normalizeClass(_ctx.b('form-label'))
              }, _toDisplayString(_ctx.$t("Дата рождения*")), 3),
              _createVNode(_component_SharedDate, {
                format: "yyyy-MM-dd",
                "is-readonly": _ctx.isReadonly,
                "is-error": _ctx.isValidate && !_ctx.kycPersonalInfoFields.birth_date,
                date: _ctx.kycPersonalInfo.birth_date,
                "onUpdate:date": _cache[8] || (_cache[8] = ($event: any) => ((_ctx.kycPersonalInfo.birth_date) = $event))
              }, null, 8, ["is-readonly", "is-error", "date"])
            ], 2)
          ]),
          _: 1
        }, 8, ["value", "is-validate", "field-value"]),
        _createVNode(_component_SharedValidate, {
          field: "citizenship",
          value: _ctx.kycPersonalInfo.citizenship,
          "is-validate": _ctx.isValidate,
          "field-value": _ctx.kycPersonalInfoFields.citizenship,
          "onUpdate:fieldValue": _cache[11] || (_cache[11] = ($event: any) => ((_ctx.kycPersonalInfoFields.citizenship) = $event))
        }, {
          default: _withCtx(() => [
            _createElementVNode("div", {
              class: _normalizeClass(_ctx.b('form-group'))
            }, [
              _createElementVNode("h5", {
                class: _normalizeClass(_ctx.b('form-label'))
              }, _toDisplayString(_ctx.$t("Гражданство*")), 3),
              _createVNode(_component_SharedCountry, {
                placeholder: "Выберите страну",
                "is-readonly": _ctx.isReadonly,
                "is-error": _ctx.isValidate && !_ctx.kycPersonalInfoFields.citizenship,
                value: _ctx.kycPersonalInfo.citizenship,
                "onUpdate:value": _cache[10] || (_cache[10] = ($event: any) => ((_ctx.kycPersonalInfo.citizenship) = $event))
              }, null, 8, ["is-readonly", "is-error", "value"])
            ], 2)
          ]),
          _: 1
        }, 8, ["value", "is-validate", "field-value"]),
        _createVNode(_component_SharedValidate, {
          field: "passport",
          value: _ctx.kycPersonalInfo.passport,
          "is-validate": _ctx.isValidate,
          "field-value": _ctx.kycPersonalInfoFields.passport,
          "onUpdate:fieldValue": _cache[13] || (_cache[13] = ($event: any) => ((_ctx.kycPersonalInfoFields.passport) = $event))
        }, {
          default: _withCtx(() => [
            _createElementVNode("div", {
              class: _normalizeClass(_ctx.b('form-group'))
            }, [
              _createElementVNode("h5", {
                class: _normalizeClass(_ctx.b('form-label'))
              }, _toDisplayString(_ctx.$t("Серия и номер паспорта*")), 3),
              _createVNode(_component_UIInput, {
                view: "light",
                "is-readonly": _ctx.isReadonly,
                "is-error": _ctx.isValidate && !_ctx.kycPersonalInfoFields.passport,
                modelValue: _ctx.kycPersonalInfo.passport,
                "onUpdate:modelValue": _cache[12] || (_cache[12] = ($event: any) => ((_ctx.kycPersonalInfo.passport) = $event))
              }, null, 8, ["is-readonly", "is-error", "modelValue"])
            ], 2)
          ]),
          _: 1
        }, 8, ["value", "is-validate", "field-value"]),
        _createVNode(_component_SharedValidate, {
          field: "passport_date",
          value: _ctx.kycPersonalInfo.passport_date,
          "is-validate": _ctx.isValidate,
          "field-value": _ctx.kycPersonalInfoFields.passport_date,
          "onUpdate:fieldValue": _cache[15] || (_cache[15] = ($event: any) => ((_ctx.kycPersonalInfoFields.passport_date) = $event))
        }, {
          default: _withCtx(() => [
            _createElementVNode("div", {
              class: _normalizeClass(_ctx.b('form-group'))
            }, [
              _createElementVNode("h5", {
                class: _normalizeClass(_ctx.b('form-label'))
              }, _toDisplayString(_ctx.$t("Дата выдачи паспорта*")), 3),
              _createVNode(_component_SharedDate, {
                format: "yyyy-MM-dd",
                "is-readonly": _ctx.isReadonly,
                "is-error": _ctx.isValidate && !_ctx.kycPersonalInfoFields.passport_date,
                date: _ctx.kycPersonalInfo.passport_date,
                "onUpdate:date": _cache[14] || (_cache[14] = ($event: any) => ((_ctx.kycPersonalInfo.passport_date) = $event))
              }, null, 8, ["is-readonly", "is-error", "date"])
            ], 2)
          ]),
          _: 1
        }, 8, ["value", "is-validate", "field-value"]),
        _createVNode(_component_SharedValidate, {
          field: "passport_issued_org",
          value: _ctx.kycPersonalInfo.passport_issued_org,
          "is-validate": _ctx.isValidate,
          "field-value": _ctx.kycPersonalInfoFields.passport_issued_org,
          "onUpdate:fieldValue": _cache[17] || (_cache[17] = ($event: any) => ((_ctx.kycPersonalInfoFields.passport_issued_org) = $event))
        }, {
          default: _withCtx(() => [
            _createElementVNode("div", {
              class: _normalizeClass(_ctx.b('form-group'))
            }, [
              _createElementVNode("h5", {
                class: _normalizeClass(_ctx.b('form-label'))
              }, _toDisplayString(_ctx.$t("Наименование органа выдавшего документ*")), 3),
              _createVNode(_component_UIInput, {
                view: "light",
                "is-readonly": _ctx.isReadonly,
                "is-error": _ctx.isValidate && !_ctx.kycPersonalInfoFields.passport_issued_org,
                modelValue: _ctx.kycPersonalInfo.passport_issued_org,
                "onUpdate:modelValue": _cache[16] || (_cache[16] = ($event: any) => ((_ctx.kycPersonalInfo.passport_issued_org) = $event))
              }, null, 8, ["is-readonly", "is-error", "modelValue"])
            ], 2)
          ]),
          _: 1
        }, 8, ["value", "is-validate", "field-value"])
      ], 2),
      _createElementVNode("div", {
        class: _normalizeClass(_ctx.b('form', { address: true }))
      }, [
        _createElementVNode("div", {
          class: _normalizeClass(_ctx.b('form-group'))
        }, [
          _createElementVNode("h5", {
            class: _normalizeClass(_ctx.b('form-label'))
          }, _toDisplayString(_ctx.$t("Адрес организации*")), 3),
          _createElementVNode("div", {
            class: _normalizeClass(_ctx.b('form-block', { address: true }))
          }, [
            _createVNode(_component_SharedValidate, {
              class: _normalizeClass(_ctx.b('form-country')),
              field: "address.country",
              value: _ctx.kycPersonalInfo.address.country,
              "is-validate": _ctx.isValidate,
              "field-value": _ctx.kycPersonalInfoFields['address.country'],
              "onUpdate:fieldValue": _cache[19] || (_cache[19] = ($event: any) => ((_ctx.kycPersonalInfoFields['address.country']) = $event))
            }, {
              default: _withCtx(() => [
                _createVNode(_component_SharedCountry, {
                  class: _normalizeClass(_ctx.b('form-country')),
                  placeholder: "Cтрана",
                  "is-readonly": _ctx.isReadonly,
                  "is-error": 
                _ctx.isValidate && !_ctx.kycPersonalInfoFields['address.country']
              ,
                  value: _ctx.kycPersonalInfo.address.country,
                  "onUpdate:value": _cache[18] || (_cache[18] = ($event: any) => ((_ctx.kycPersonalInfo.address.country) = $event))
                }, null, 8, ["class", "is-readonly", "is-error", "value"])
              ]),
              _: 1
            }, 8, ["class", "value", "is-validate", "field-value"]),
            _createVNode(_component_SharedValidate, {
              class: _normalizeClass(_ctx.b('form-city')),
              field: "address.city",
              value: _ctx.kycPersonalInfo.address.city,
              "is-validate": _ctx.isValidate,
              "field-value": _ctx.kycPersonalInfoFields['address.city'],
              "onUpdate:fieldValue": _cache[21] || (_cache[21] = ($event: any) => ((_ctx.kycPersonalInfoFields['address.city']) = $event))
            }, {
              default: _withCtx(() => [
                _createVNode(_component_UIInput, {
                  class: _normalizeClass(_ctx.b('form-city')),
                  placeholder: "Город",
                  view: "light",
                  "is-readonly": _ctx.isReadonly,
                  "is-error": _ctx.isValidate && !_ctx.kycPersonalInfoFields['address.city'],
                  modelValue: _ctx.kycPersonalInfo.address.city,
                  "onUpdate:modelValue": _cache[20] || (_cache[20] = ($event: any) => ((_ctx.kycPersonalInfo.address.city) = $event))
                }, null, 8, ["class", "is-readonly", "is-error", "modelValue"])
              ]),
              _: 1
            }, 8, ["class", "value", "is-validate", "field-value"]),
            _createVNode(_component_SharedValidate, {
              class: _normalizeClass(_ctx.b('form-state')),
              field: "address.state",
              value: _ctx.kycPersonalInfo.address.state,
              "is-validate": _ctx.isValidate,
              "field-value": _ctx.kycPersonalInfoFields['address.state'],
              "onUpdate:fieldValue": _cache[23] || (_cache[23] = ($event: any) => ((_ctx.kycPersonalInfoFields['address.state']) = $event))
            }, {
              default: _withCtx(() => [
                _createVNode(_component_UIInput, {
                  placeholder: "Штат / Область",
                  view: "light",
                  "is-readonly": _ctx.isReadonly,
                  "is-error": _ctx.isValidate && !_ctx.kycPersonalInfoFields['address.state'],
                  modelValue: _ctx.kycPersonalInfo.address.state,
                  "onUpdate:modelValue": _cache[22] || (_cache[22] = ($event: any) => ((_ctx.kycPersonalInfo.address.state) = $event))
                }, null, 8, ["is-readonly", "is-error", "modelValue"])
              ]),
              _: 1
            }, 8, ["class", "value", "is-validate", "field-value"]),
            _createVNode(_component_SharedValidate, {
              class: _normalizeClass(_ctx.b('form-street')),
              field: "address.street",
              value: _ctx.kycPersonalInfo.address.street,
              "is-validate": _ctx.isValidate,
              "field-value": _ctx.kycPersonalInfoFields['address.street'],
              "onUpdate:fieldValue": _cache[25] || (_cache[25] = ($event: any) => ((_ctx.kycPersonalInfoFields['address.street']) = $event))
            }, {
              default: _withCtx(() => [
                _createVNode(_component_UIInput, {
                  placeholder: "Улица",
                  view: "light",
                  "is-readonly": _ctx.isReadonly,
                  "is-error": _ctx.isValidate && !_ctx.kycPersonalInfoFields['address.street'],
                  modelValue: _ctx.kycPersonalInfo.address.street,
                  "onUpdate:modelValue": _cache[24] || (_cache[24] = ($event: any) => ((_ctx.kycPersonalInfo.address.street) = $event))
                }, null, 8, ["is-readonly", "is-error", "modelValue"])
              ]),
              _: 1
            }, 8, ["class", "value", "is-validate", "field-value"]),
            _createVNode(_component_SharedValidate, {
              class: _normalizeClass(_ctx.b('form-office')),
              field: "address.office",
              value: _ctx.kycPersonalInfo.address.office,
              "is-validate": _ctx.isValidate,
              "field-value": _ctx.kycPersonalInfoFields['address.office'],
              "onUpdate:fieldValue": _cache[27] || (_cache[27] = ($event: any) => ((_ctx.kycPersonalInfoFields['address.office']) = $event))
            }, {
              default: _withCtx(() => [
                _createVNode(_component_UIInput, {
                  placeholder: "Номер Офиса",
                  view: "light",
                  "is-readonly": _ctx.isReadonly,
                  "is-error": _ctx.isValidate && !_ctx.kycPersonalInfoFields['address.office'],
                  modelValue: _ctx.kycPersonalInfo.address.office,
                  "onUpdate:modelValue": _cache[26] || (_cache[26] = ($event: any) => ((_ctx.kycPersonalInfo.address.office) = $event))
                }, null, 8, ["is-readonly", "is-error", "modelValue"])
              ]),
              _: 1
            }, 8, ["class", "value", "is-validate", "field-value"]),
            _createVNode(_component_SharedValidate, {
              class: _normalizeClass(_ctx.b('form-index')),
              field: "address.zip",
              value: _ctx.kycPersonalInfo.address.zip,
              "is-validate": _ctx.isValidate,
              "field-value": _ctx.kycPersonalInfoFields['address.zip'],
              "onUpdate:fieldValue": _cache[29] || (_cache[29] = ($event: any) => ((_ctx.kycPersonalInfoFields['address.zip']) = $event))
            }, {
              default: _withCtx(() => [
                _createVNode(_component_UIInput, {
                  placeholder: "Индекс",
                  view: "light",
                  "is-readonly": _ctx.isReadonly,
                  "is-error": _ctx.isValidate && !_ctx.kycPersonalInfoFields['address.zip'],
                  modelValue: _ctx.kycPersonalInfo.address.zip,
                  "onUpdate:modelValue": _cache[28] || (_cache[28] = ($event: any) => ((_ctx.kycPersonalInfo.address.zip) = $event))
                }, null, 8, ["is-readonly", "is-error", "modelValue"])
              ]),
              _: 1
            }, 8, ["class", "value", "is-validate", "field-value"])
          ], 2)
        ], 2)
      ], 2),
      _createElementVNode("div", {
        class: _normalizeClass(_ctx.b('form', { last: true }))
      }, [
        _createVNode(_component_SharedValidate, {
          field: "phone",
          value: _ctx.kycPersonalInfo.phone,
          "is-validate": _ctx.isValidate,
          "field-value": _ctx.kycPersonalInfoFields.phone,
          "onUpdate:fieldValue": _cache[31] || (_cache[31] = ($event: any) => ((_ctx.kycPersonalInfoFields.phone) = $event))
        }, {
          default: _withCtx(() => [
            _createElementVNode("div", {
              class: _normalizeClass(_ctx.b('form-group'))
            }, [
              _createElementVNode("h5", {
                class: _normalizeClass(_ctx.b('form-label'))
              }, _toDisplayString(_ctx.$t("Номер телефона*")), 3),
              _createVNode(_component_UIInput, {
                view: "light",
                "is-readonly": _ctx.isReadonly,
                "is-error": _ctx.isValidate && !_ctx.kycPersonalInfoFields.phone,
                modelValue: _ctx.kycPersonalInfo.phone,
                "onUpdate:modelValue": _cache[30] || (_cache[30] = ($event: any) => ((_ctx.kycPersonalInfo.phone) = $event))
              }, null, 8, ["is-readonly", "is-error", "modelValue"])
            ], 2)
          ]),
          _: 1
        }, 8, ["value", "is-validate", "field-value"]),
        _createVNode(_component_SharedValidate, {
          field: "telegram",
          value: _ctx.kycPersonalInfo.telegram,
          "is-validate": _ctx.isValidate,
          "field-value": _ctx.kycPersonalInfoFields.telegram,
          "onUpdate:fieldValue": _cache[33] || (_cache[33] = ($event: any) => ((_ctx.kycPersonalInfoFields.telegram) = $event))
        }, {
          default: _withCtx(() => [
            _createElementVNode("div", {
              class: _normalizeClass(_ctx.b('form-group'))
            }, [
              _createElementVNode("h5", {
                class: _normalizeClass(_ctx.b('form-label'))
              }, _toDisplayString(_ctx.$t("Телеграм для связи*")), 3),
              _createVNode(_component_UIInput, {
                view: "light",
                "is-telegram": true,
                "is-readonly": _ctx.isReadonly,
                "is-error": _ctx.isValidate && !_ctx.kycPersonalInfoFields.telegram,
                modelValue: _ctx.kycPersonalInfo.telegram,
                "onUpdate:modelValue": _cache[32] || (_cache[32] = ($event: any) => ((_ctx.kycPersonalInfo.telegram) = $event))
              }, null, 8, ["is-readonly", "is-error", "modelValue"])
            ], 2)
          ]),
          _: 1
        }, 8, ["value", "is-validate", "field-value"])
      ], 2),
      _createVNode(_component_UIButton, {
        class: _normalizeClass(_ctx.b('continue')),
        label: "Продолжить",
        view: "main",
        disabled: _ctx.isContinueDisabled,
        onClick: _ctx.handleContinue
      }, null, 8, ["class", "disabled", "onClick"])
    ]),
    _: 1
  }, 8, ["class"]))
}