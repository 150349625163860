export function formatFiatCurrency(value: number): string {
  return value.toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, " ");
}

export function formatCryptoCurrency(value: number): string {
  return value
    .toFixed(10)
    .replace(/\.?0+$/, "")
    .replace(/\B(?=(\d{3})+(?!\d))/g, " ");
}
