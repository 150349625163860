<script lang="ts" src="./component.ts"></script>
<style lang="scss" src="./component.scss"></style>

<template>
  <div :class="b()">
    <input
      :class="b('field', { view })"
      type="number"
      step="any"
      :value="modelValue"
      :disabled="isReadonly"
      @input="handleChangeValue"
    />

    <span :class="b('icon')" v-html="displayedIcons.percent"></span>
  </div>
</template>
