export enum KycApiRoutes {
  personalInfo = "/api/v1/kyc",
  uploadFile = "/api/v1/kyc/upload/file",
  deleteFile = "/api/v1/kyc/file/delete/",
  userFiles = "/api/v1/kyc/user-files",
  verification = "/api/v1/kyc/verification",
}

export interface KycProjectUrlGeneartor {
  personalInfo(): string;
  uploadFile(): string;
  deleteFile(): string;
  userFiles(): string;
  verification(): string;
}

export class KycUrlGenerator implements KycProjectUrlGeneartor {
  personalInfo(): string {
    return KycApiRoutes.personalInfo;
  }

  uploadFile(): string {
    return KycApiRoutes.uploadFile;
  }

  deleteFile(): string {
    return KycApiRoutes.deleteFile;
  }

  userFiles(): string {
    return KycApiRoutes.userFiles;
  }

  verification(): string {
    return KycApiRoutes.verification;
  }
}
