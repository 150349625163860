import { defineComponent } from "vue";

import { PAGE_NAME, SVG } from "./attributes";

import {
  AdminApplicationTable,
  AdminApplicationEdit,
} from "@/components/pages/admin-application";
import {
  SharedSearch,
  SharedAdminHeader,
  SharedButtonIcon,
} from "@/components/shared";

import { PAGE_SIZE, SvgAttribute } from "@/shared/constants/constants";
import { SearchFactory } from "@/shared/factory/search";
import { SortFactory } from "@/shared/factory/sort";
import { AdminDepositWithdrawalListResponse } from "@/shared/repository/modules/admin/deposit-withdrawal/repo";

export default defineComponent({
  name: PAGE_NAME,
  components: {
    AdminApplicationTable,
    AdminApplicationEdit,
    SharedSearch,
    SharedAdminHeader,
    SharedButtonIcon,
  },
  data() {
    return {
      isLoading: false,
      isWork: false,
      isReject: false,
      search: SearchFactory(),
      sort: SortFactory(),
      depositWithdrawalItemId: 0,
      depositWithdrawalList: <AdminDepositWithdrawalListResponse[]>[],
      adminDepositWithdrawalRepository:
        this.$projectServices.adminDepositWithdrawalRepository,
    };
  },
  async created(): Promise<void> {
    try {
      this.depositWithdrawalList =
        await this.adminDepositWithdrawalRepository.list();
    } catch (error) {
      console.log(error);
    }
  },
  computed: {
    displayedIcons(): SvgAttribute {
      return SVG;
    },

    isMoreShow(): boolean {
      return (
        this.depositWithdrawalList.length > (this.sort.page + 1) * PAGE_SIZE &&
        !this.search.value
      );
    },
  },
  methods: {
    async handleSearch(): Promise<void> {
      try {
        this.isLoading = true;

        this.search.value = "";
        this.sort.page = 1;

        const { email, id } = this.search;

        this.depositWithdrawalList =
          await this.adminDepositWithdrawalRepository.list();

        if (email && id) {
          this.depositWithdrawalList = this.depositWithdrawalList.filter(
            (item) => item.user.email === email && item.user.id === id
          );
        } else if (email) {
          this.depositWithdrawalList = this.depositWithdrawalList.filter(
            (item) => item.user.email === email
          );
        } else if (id) {
          this.depositWithdrawalList = this.depositWithdrawalList.filter(
            (item) => item.user.id === id
          );
        }
      } catch (error) {
        console.log(error);
      } finally {
        this.isLoading = false;
      }
    },

    handleMore(): void {
      this.sort.page++;
    },
  },
});
