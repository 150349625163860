export function extractFileName(name: string, separator: string = ":"): string {
  return name.split(separator)[0];
}

export function uniqueFileName(name: string, separator: string = ":"): string {
  return `${name}${separator}${new Date().getTime()}`;
}

export function truncateFileName(name: string): string {
  return name.length > 50 ? `${name.slice(0, 47)}...` : name;
}
