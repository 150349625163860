import { defineComponent, PropType } from "vue";

import { COMPONENT_NAME, SVG } from "./attributes";

import { UIButton } from "@/components/ui";
import { SharedCurrency, SharedCryptoSelect } from "@/components/shared";

import { SharedCryptoSelectOption } from "@/shared/constants/constants";
import { ApplicationStoreRequest } from "@/shared/repository/modules/application/repo";
import { AdminCurrencyType } from "@/shared/repository/modules/admin/currency/enums";
import { WalletWithAmountResponse } from "@/shared/repository/modules/wallet/repo";
import { ApplicationCommissionRequestFactory } from "@/shared/repository/modules/application/factory";

export default defineComponent({
  name: COMPONENT_NAME,
  components: {
    UIButton,
    SharedCurrency,
    SharedCryptoSelect,
  },
  props: {
    walletWithAmount: {
      type: <PropType<WalletWithAmountResponse[]>>Array,
      default: () => [],
    },
    isLoading: {
      type: Boolean,
      default: false,
    },
    isResult: {
      type: Boolean,
      default: false,
    },
  },
  emits: {
    "update:isLoading": null,
    "update:isResult": null,
  },
  data() {
    return {
      isCommissions: false,
      abortController: <AbortController | null>null,
      commissionsRequest: null,
      applicationRepository: this.$projectServices.applicationRepository,
      applicationRepo: this.$projectServices.applicationRepo,
    };
  },
  watch: {
    "applicationStoreRequest.currency_id_from"() {
      this.handleCommission();
    },

    "applicationStoreRequest.currency_id_to"() {
      this.handleCommission();
    },

    "applicationStoreRequest.amount"() {
      this.handleCommission();
    },
  },
  computed: {
    displayedApplicationStoreRequest(): ApplicationStoreRequest {
      return this.applicationRepo.application;
    },

    displayedCurrencyFrom(): WalletWithAmountResponse | undefined {
      const { currency_id_from } = this.displayedApplicationStoreRequest;

      return this.walletWithAmount.find(
        (currencyItem) => currencyItem.currency.id === currency_id_from
      );
    },

    displayedCurrencyTo(): WalletWithAmountResponse | undefined {
      const { currency_id_to } = this.displayedApplicationStoreRequest;

      return this.walletWithAmount.find(
        (currencyItem) => currencyItem.currency.id === currency_id_to
      );
    },

    displayedFromOptions(): SharedCryptoSelectOption[] {
      return this.walletWithAmount
        .filter((currency) => {
          const { currency_id_to } = this.displayedApplicationStoreRequest;

          if (currency_id_to) return currency.currency_id !== currency_id_to;

          return currency;
        })
        .map((currency) => ({
          id: currency.currency_id,
          label: currency.amount.toString(),
          currency: {
            fiatIcon:
              currency.currency.type === AdminCurrencyType.fiat
                ? currency.currency.country
                : "",
            cryptoIcon:
              currency.currency.type === AdminCurrencyType.crypto
                ? currency.currency.name
                : "",
            name: currency.currency.name,
            type: currency.currency.type,
          },
          userRequisiteId: currency.user_requisite_id,
          userRequisiteType: currency.user_requisite_type,
          isActive:
            this.displayedApplicationStoreRequest.currency_id_from ===
            currency.currency_id,
        }));
    },

    displayedToOptions(): SharedCryptoSelectOption[] {
      return this.walletWithAmount
        .filter((currency) => {
          const { currency_id_from } = this.displayedApplicationStoreRequest;

          if (currency_id_from)
            return currency.currency_id !== currency_id_from;

          return currency;
        })
        .map((currency) => ({
          id: currency.currency_id,
          label: currency.amount.toString(),
          currency: {
            fiatIcon:
              currency.currency.type === AdminCurrencyType.fiat
                ? currency.currency.country
                : "",
            cryptoIcon:
              currency.currency.type === AdminCurrencyType.crypto
                ? currency.currency.name
                : "",
            name: currency.currency.name,
            type: currency.currency.type,
          },
          userRequisiteId: currency.user_requisite_id,
          userRequisiteType: currency.user_requisite_type,
          isActive:
            this.displayedApplicationStoreRequest.currency_id_to ===
            currency.currency_id,
        }));
    },

    displayedArrowIcon(): string {
      return SVG.arrow;
    },

    isCommissionsShow(): boolean {
      const { currency_id_from, currency_id_to, amount } =
        this.displayedApplicationStoreRequest;

      return (
        !!currency_id_from &&
        !!currency_id_to &&
        !!amount &&
        !this.isCommissions
      );
    },

    isNextDisabled(): boolean {
      const {
        currency_id_from,
        currency_id_to,
        currency_type_from,
        currency_type_to,
        user_requisite_id_from,
        user_requisite_id_to,
        user_requisite_type_from,
        user_requisite_type_to,
        amount,
      } = this.displayedApplicationStoreRequest;

      return (
        !currency_id_from ||
        !currency_id_to ||
        !currency_type_from ||
        !currency_type_to ||
        !user_requisite_id_from ||
        !user_requisite_id_to ||
        !user_requisite_type_from ||
        !user_requisite_type_to ||
        !amount ||
        this.isLoading
      );
    },
  },
  methods: {
    async handleCurrencyIdFrom(currency_id_from: number): Promise<void> {
      try {
        this.$emit("update:isLoading", true);

        const option = this.displayedFromOptions.find(
          (option) => option.id === currency_id_from
        )!;

        this.applicationRepo.UPDATE_APPLICATION({
          ...this.displayedApplicationStoreRequest,
          currency_id_from,
          currency_type_from: option.currency.type,
          user_requisite_id_from: option.userRequisiteId,
          user_requisite_type_from: option.userRequisiteType,
        });
      } catch (error) {
        console.log(error);
      } finally {
        this.$emit("update:isLoading", false);
      }
    },

    async handleCurrencyIdTo(currency_id_to: number): Promise<void> {
      try {
        this.$emit("update:isLoading", true);

        const option = this.displayedFromOptions.find(
          (option) => option.id === currency_id_to
        )!;

        this.applicationRepo.UPDATE_APPLICATION({
          ...this.displayedApplicationStoreRequest,
          currency_id_to,
          currency_type_to: option?.currency.type,
          user_requisite_id_to: option?.userRequisiteId,
          user_requisite_type_to: option?.userRequisiteType,
        });
      } catch (error) {
        console.log(error);
      } finally {
        this.$emit("update:isLoading", false);
      }
    },

    handleAmount(amount: number): void {
      this.applicationRepo.UPDATE_APPLICATION({
        ...this.displayedApplicationStoreRequest,
        amount,
      });
    },

    handleMax(): void {
      const { currency_id_from } = this.displayedApplicationStoreRequest;

      this.applicationRepo.UPDATE_APPLICATION({
        ...this.displayedApplicationStoreRequest,
        amount: currency_id_from
          ? Number(
              this.displayedFromOptions.find(
                (option) => option.id === currency_id_from
              )?.label ?? 0
            )
          : 0,
      });
    },

    async handleCommission(): Promise<void> {
      if (this.abortController) {
        this.abortController.abort();
      }

      this.abortController = new AbortController();

      try {
        const {
          currency_id_from,
          currency_id_to,
          currency_type_from,
          currency_type_to,
          amount,
        } = this.displayedApplicationStoreRequest;

        if (
          !currency_id_from ||
          !currency_id_to ||
          !currency_type_from ||
          !currency_type_to ||
          !amount
        )
          return;

        this.isCommissions = true;

        await this.applicationRepository.commission(
          ApplicationCommissionRequestFactory({
            currency_id_from,
            currency_id_to,
            currency_type_from,
            currency_type_to,
            amount,
          })
        );

        this.$emit("update:isLoading", true);
      } catch (error) {
        console.log(error);
      } finally {
        this.isCommissions = false;

        this.$emit("update:isLoading", false);
      }
    },

    handleNext(): void {
      this.$emit("update:isResult", true);
    },
  },
});
