import { defineComponent } from "vue";

import { COMPONENT_NAME } from "./attributes";

import { UIInput, UIButton } from "@/components/ui";
import { SharedSelect } from "@/components/shared";

import { DepositWithdrawalStoreRequestFactory } from "@/shared/repository/modules/deposit-withdrawal/factory";
import { SharedSelectOption } from "@/components/shared/select/model";
import { SelectOptionFactory } from "@/shared/factory/select";
import { AdminCurrencyShowResponse } from "@/shared/repository/modules/admin/currency/repo";
import { AdminCurrencyType } from "@/shared/repository/modules/admin/currency/enums";
// import { AdminApplicationType } from "@/shared/repository/modules/admin/application/enums";
import { WalletModalFactory } from "@/shared/repository/modules/wallet/factory";
import { AdminApplicationType } from "@/shared/repository/modules/admin/application/enums";
// import { ApplicationFilterRequestFactory } from "@/shared/repository/modules/application/factory";

export default defineComponent({
  name: COMPONENT_NAME,
  components: {
    UIInput,
    UIButton,
    SharedSelect,
  },
  data() {
    return {
      isLoading: false,
      currency: <AdminCurrencyShowResponse[]>[],
      depositWithdrawalStore: DepositWithdrawalStoreRequestFactory(
        {
          user_id: this.$projectServices.userRepo.userInfo.id,
        },
        true
      ),
      modalRepo: this.$projectServices.modalRepo,
      walletRepo: this.$projectServices.walletRepo,
      depositWithdrawalRepo: this.$projectServices.depositWithdrawalRepo,
      walletRepository: this.$projectServices.walletRepository,
      depositWithdrawalRepository:
        this.$projectServices.depositWithdrawalRepository,
      requisitesRepository: this.$projectServices.requisitesRepository,
    };
  },
  async created(): Promise<void> {
    try {
      this.isLoading = true;

      const currency = await this.walletRepository.currency();

      this.currency = currency.filter(
        (item) => item.type === AdminCurrencyType.fiat && !item.frozen
      );
    } catch (error) {
      console.log(error);
    } finally {
      this.isLoading = false;
    }
  },
  computed: {
    displayedCurrencyOptions(): SharedSelectOption[] {
      return this.currency.map((currency) =>
        SelectOptionFactory(
          {
            id: currency.id,
            label: currency.name.toUpperCase(),
            value: currency.id,
            icon: currency.country,
          },
          this.depositWithdrawalStore.currency_id
        )
      );
    },

    displayedCurrencyIcon(): string {
      return (
        this.currency.find(
          (currency) => currency.id === this.depositWithdrawalStore.currency_id
        )?.country ?? ""
      );
    },

    isDisabled(): boolean {
      const { amount, new_requisite } = this.depositWithdrawalStore;
      const {
        account_number,
        correspondent_account,
        bank_identification_code,
        bank_name,
        inn,
        registration_reason_code,
      } = new_requisite!;

      return (
        !account_number ||
        !correspondent_account ||
        !bank_identification_code ||
        !bank_name ||
        !inn ||
        !registration_reason_code ||
        !amount ||
        this.isLoading
      );
    },
  },
  methods: {
    async handleCreate(): Promise<void> {
      try {
        this.isLoading = true;

        await this.requisitesRepository.bank(
          this.depositWithdrawalStore.new_requisite!
        );

        this.depositWithdrawalRepo.UPDATE_APPLICATION(
          this.depositWithdrawalStore
        );

        this.walletRepo.UPDATE_MODAL(
          WalletModalFactory({
            type:
              this.walletRepo.modal.type === AdminApplicationType.deposit
                ? AdminApplicationType.verificationDeposit
                : AdminApplicationType.verificationWithdrawal,
          })
        );
      } catch (error) {
        console.log(error);
      } finally {
        this.isLoading = false;
      }
    },
  },
});
