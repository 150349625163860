<script lang="ts" src="./component.ts"></script>
<style lang="scss" src="./component.scss"></style>

<template>
  <div :class="b()">
    <div :class="b('header')">
      <h4 :class="b('header-title')">
        {{ $t(title) }}
      </h4>

      <button :class="b('header-link')" @click="handleHistory">
        <span :class="b('header-link-label', { big: true })">
          {{ $t("Смотреть все") }}
        </span>

        <span :class="b('header-link-label', { small: true })">
          {{ $t("Все") }}
        </span>
      </button>
    </div>

    <slot></slot>
  </div>
</template>
