import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, withModifiers as _withModifiers, createTextVNode as _createTextVNode } from "vue"

const _hoisted_1 = ["onClick"]
const _hoisted_2 = ["innerHTML"]
const _hoisted_3 = ["innerHTML"]
const _hoisted_4 = ["innerHTML"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(_ctx.b())
  }, [
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.banks, (bank) => {
      return (_openBlock(), _createElementBlock("button", {
        class: _normalizeClass(
        _ctx.b('bank', {
          active: bank.id === _ctx.bankId,
          added: typeof bank.id === 'number',
        })
      ),
        key: bank.id,
        onClick: ($event: any) => (_ctx.handleBank(bank))
      }, [
        _createTextVNode(_toDisplayString(_ctx.handleBankLabel(bank.correspondent_bank)) + " ", 1),
        (typeof bank.id === 'number')
          ? (_openBlock(), _createElementBlock("span", {
              key: 0,
              class: _normalizeClass(_ctx.b('icon')),
              innerHTML: _ctx.displayedSvg.added
            }, null, 10, _hoisted_2))
          : _createCommentVNode("", true),
        _createElementVNode("button", {
          class: _normalizeClass(_ctx.b('remove')),
          onClick: _cache[0] || (_cache[0] = _withModifiers(
//@ts-ignore
(...args) => (_ctx.handleBankRemove && _ctx.handleBankRemove(...args)), ["stop"]))
        }, [
          _createElementVNode("span", {
            class: _normalizeClass(_ctx.b('icon')),
            innerHTML: _ctx.displayedSvg.remove
          }, null, 10, _hoisted_3)
        ], 2)
      ], 10, _hoisted_1))
    }), 128)),
    (_ctx.isCreateShow)
      ? (_openBlock(), _createElementBlock("button", {
          key: 0,
          class: _normalizeClass(_ctx.b('circle')),
          onClick: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_ctx.handleBankCreate && _ctx.handleBankCreate(...args)))
        }, [
          _createElementVNode("span", {
            class: _normalizeClass(_ctx.b('icon')),
            innerHTML: _ctx.displayedSvg.circle
          }, null, 10, _hoisted_4)
        ], 2))
      : _createCommentVNode("", true)
  ], 2))
}