<script lang="ts" src="./component.ts"></script>
<style lang="scss" src="./component.scss"></style>

<template>
  <div :class="b()">
    <div :class="b('search')">
      <span
        :class="b('search-icon', { search: true })"
        v-html="displayedIcons.search"
      ></span>

      <input
        :class="b('search-field')"
        type="text"
        :value="value"
        @input="handleValue"
      />

      <button
        :class="b('search-clear')"
        v-show="isClearShow"
        @click="handleClear"
      >
        <span
          :class="b('search-icon')"
          v-html="displayedIcons.searchClear"
        ></span>
      </button>
    </div>

    <div :class="b('select')" v-click-outside="handleHide">
      <button
        :class="b('select-toggle', { active: isToggle })"
        @click="handleToggle"
      >
        <span :class="b('select-icon')" v-html="displayedIcons.dots"></span>
      </button>

      <div :class="b('select-options', { active: isToggle })">
        <h4 :class="b('select-title')">
          {{ $t("Поиск по данным:") }}
        </h4>

        <button
          :class="b('select-option', { active: option.isActive })"
          v-for="option in displayedOptions"
          :key="option.id"
          @click="handleOption(option.value)"
        >
          {{ $t(option.label) }}
        </button>
      </div>
    </div>
  </div>
</template>
