export interface AdminUsersTableRowOption {
  id: number;
  label: AdminUsersTableRowOptionLabel;
  value: AdminUsersTableRowOptionValue;
  isDisabled: boolean;
}

export enum AdminUsersTableRowOptionLabel {
  kyc = "Посмотреть КУС",
  status = "Изменить статус КУС",
  admin = "Сделать администратором",
  balance = "Изменить баланс",
  remove = "Удалить аккаунт",
}

export enum AdminUsersTableRowOptionValue {
  kyc = "kyc",
  status = "status",
  admin = "admin",
  balance = "balance",
  remove = "remove",
}
