<script lang="ts" src="./component.ts"></script>
<style lang="scss" src="./component.scss"></style>

<template>
  <div :class="b()">
    <DefaultHeader :class="b('header')" />

    <router-view v-if="isViewShow" />
  </div>
</template>
