<script lang="ts" src="./component.ts"></script>
<style lang="scss" src="./component.scss"></style>

<template>
  <div :class="b()">
    <h1 :class="b('title')">
      {{ $t("История") }}
    </h1>

    <div :class="b('wrapper')">
      <HistoryHeader />

      <HistoryTable :class="b('table')" v-model:is-details="isDetails" />

      <SharedButtonIcon
        v-if="isMoreShow"
        :class="b('more')"
        view="gray-outline"
        @click="handleMore"
      >
        <template #icon-left>
          <span :class="b('more-icon')" v-html="displayedMoreIcon"></span>
        </template>

        <template #label>
          {{ $t("Показать больше") }}
        </template>
      </SharedButtonIcon>
    </div>

    <transition name="opacity">
      <HistoryFilters :class="b('modal')" v-if="isFilter" />
    </transition>

    <transition name="opacity">
      <HistoryDetails
        :class="b('modal')"
        v-if="isDetails"
        v-model:is-details="isDetails"
      />
    </transition>
  </div>
</template>
