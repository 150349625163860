<script lang="ts" src="./component.ts"></script>
<style lang="scss" src="./component.scss"></style>

<template>
  <div :class="b()">
    <input
      :class="b('field')"
      type="number"
      :placeholder="placeholder"
      :value="value"
      @input="handleInput"
    />

    <div :class="b('currency')">
      <span
        v-if="currency.country && currency.type === 'fiat'"
        :class="[b('icon'), 'fi', `fi-${currency.country}`]"
      ></span>

      <img
        v-else-if="currency.type === 'crypto' && currency.name"
        :class="b('icon', { crypto: true })"
        :src="
          require(`@/assets/images/crypto/${currency.name.toLowerCase()}.svg`)
        "
        alt=""
      />

      <span :class="b('currency-label')">
        {{ currency.name.toUpperCase() }}
      </span>
    </div>
  </div>
</template>
