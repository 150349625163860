import { AdminCurrencyType } from "../admin/currency/enums";
import { AdminRequisitesType } from "../admin/requisites/enums";
import {
  WalletListResponse,
  WalletModal,
  WalletWithAmountResponse,
} from "./repo";

export const WalletModalFactory = (
  payload: Partial<WalletModal> = {}
): WalletModal => {
  return {
    type: payload.type ?? "",
    currencyId: payload.currencyId ?? 0,
    currencyName: payload.currencyName ?? "",
    amount: payload.amount ?? 0,
    isDisabled: payload.isDisabled ?? false,
  };
};

export const WalletListResponseFactory = (
  payload: Partial<WalletListResponse> = {}
): WalletListResponse => {
  return {
    id: payload.id ?? 0,
    name: payload.name ?? "",
    type: payload.type ?? AdminCurrencyType.fiat,
    image_path: payload.image_path ?? "",
    country: payload.country ?? "",
    frozen: Boolean(payload.frozen ?? false),
    created_at: payload.created_at ?? "",
    updated_at: payload.updated_at ?? "",
    currency_id: payload.currency_id ?? 0,
    amount: Number(payload.amount ?? 0),
    currency: payload.currency
      ? {
          id: payload.currency?.id ?? 0,
          country: payload.currency?.country ?? "",
          type: payload.currency?.type ?? AdminCurrencyType.fiat,
          name: payload.currency?.name ?? "",
          image_path: payload.currency?.image_path ?? "",
          created_at: payload.currency?.created_at ?? "",
          updated_at: payload.currency?.updated_at ?? "",
          frozen: Boolean(payload.currency?.frozen ?? false),
        }
      : null,
  };
};

export const WalletWithAmountResponseFactory = (
  payload: Partial<WalletWithAmountResponse> = {}
): WalletWithAmountResponse => {
  return {
    id: payload.id ?? 0,
    user_id: payload.user_id ?? 0,
    currency_id: payload.currency_id ?? 0,
    currency_type: payload.currency_type ?? AdminCurrencyType.fiat,
    amount: Number(payload.amount ?? 0),
    user_requisite_id: payload.user_requisite_id ?? 0,
    user_requisite_type:
      payload.user_requisite_type ?? AdminRequisitesType.bank,
    created_at: payload.created_at ?? "",
    updated_at: payload.updated_at ?? "",
    currency: {
      id: payload.currency?.id ?? 0,
      country: payload.currency?.country ?? "",
      type: payload.currency?.type ?? AdminCurrencyType.fiat,
      name: payload.currency?.name ?? "",
      image_path: payload.currency?.image_path ?? "",
      created_at: payload.currency?.created_at ?? "",
      updated_at: payload.currency?.updated_at ?? "",
      frozen: Boolean(payload.currency?.frozen ?? false),
    },
  };
};
