import { defineComponent, PropType } from "vue";

import { COMPONENT_NAME } from "./attributes";

import { UIButton } from "@/components/ui";

import { AdminConfigTabValue } from "@/views/admin-config/model";
import { Tabs, AdminConfigTabsTab } from "@/data/admin/config/tabs";
import { AdminCurrencyModalFactory } from "@/shared/repository/modules/admin/currency/factory";
import { AdminCurrencyModalOption } from "@/shared/repository/modules/admin/currency/enums";

export default defineComponent({
  name: COMPONENT_NAME,
  components: {
    UIButton,
  },
  props: {
    tab: {
      type: <PropType<AdminConfigTabValue>>String,
      required: true,
    },
  },
  emits: {
    "update:tab": null,
  },
  data() {
    return {
      adminCurrencyRepo: this.$projectServices.adminCurrencyRepo,
      adminRequisitesRepo: this.$projectServices.adminRequisitesRepo,
    };
  },
  computed: {
    displayedTabs(): AdminConfigTabsTab[] {
      return Tabs(this.tab);
    },

    isRemoveShow(): boolean {
      return this.tab === AdminConfigTabValue.currency;
    },

    isAddShow(): boolean {
      return (
        (this.tab === AdminConfigTabValue.requisites ||
          this.tab === AdminConfigTabValue.currency) &&
        !this.adminRequisitesRepo.requisite.isShow
      );
    },
  },
  methods: {
    handleTab({ value }: AdminConfigTabsTab): void {
      this.$emit("update:tab", value);
    },

    handleRemove(): void {
      switch (this.tab) {
        case AdminConfigTabValue.currency:
          this.adminCurrencyRepo.UPDATE_MODAL(
            AdminCurrencyModalFactory({
              option: AdminCurrencyModalOption.delete,
              isShow: true,
            })
          );

          break;
        default:
          break;
      }
    },

    handleAdd(): void {
      switch (this.tab) {
        case AdminConfigTabValue.requisites:
          this.adminRequisitesRepo.UPDATE_REQUISITE({
            id: 0,
            isShow: true,
          });

          break;
        case AdminConfigTabValue.currency:
          this.adminCurrencyRepo.UPDATE_MODAL(
            AdminCurrencyModalFactory({
              isShow: true,
            })
          );

          break;
        default:
          break;
      }
    },
  },
});
