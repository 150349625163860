import { toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = ["disabled"]
const _hoisted_2 = ["innerHTML"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(_ctx.b())
  }, [
    _createElementVNode("p", {
      class: _normalizeClass(_ctx.b('label'))
    }, _toDisplayString(_ctx.label), 3),
    _createElementVNode("button", {
      class: _normalizeClass(_ctx.b('input')),
      disabled: _ctx.isCopy,
      onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.handleCopy && _ctx.handleCopy(...args)))
    }, [
      _createElementVNode("span", {
        class: _normalizeClass(_ctx.b('input-label', { copy: _ctx.isCopy }))
      }, _toDisplayString(_ctx.value), 3),
      (_ctx.isCopy)
        ? (_openBlock(), _createElementBlock("span", {
            key: 0,
            class: _normalizeClass(_ctx.b('copy'))
          }, " Скопировано ", 2))
        : (_openBlock(), _createElementBlock("span", {
            key: 1,
            class: _normalizeClass(_ctx.b('icon')),
            innerHTML: _ctx.displayedSvg.copy
          }, null, 10, _hoisted_2))
    ], 10, _hoisted_1)
  ], 2))
}