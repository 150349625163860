import { defineComponent } from "vue";

import { COMPONENT_NAME, SVG } from "./attributes";

import { SharedLanguage } from "@/components/shared";

import { SvgAttribute } from "@/shared/constants/constants";
import { RoutesName } from "@/shared/constants/enums";

export default defineComponent({
  name: COMPONENT_NAME,
  components: {
    SharedLanguage,
  },
  data() {
    return {
      isLoading: false,
      authRepository: this.$projectServices.authRepository,
    };
  },
  computed: {
    displayedIcons(): SvgAttribute {
      return SVG;
    },

    displayedEmail(): string {
      return this.$projectServices.userRepo.userInfo.email;
    },

    isLogoutDisabled(): boolean {
      return this.isLoading;
    },
  },
  methods: {
    async handleLogout(): Promise<void> {
      try {
        this.isLoading = true;

        await this.authRepository.logout();

        this.$router.push({ name: RoutesName.login });
      } catch (e) {
        console.log(e);
      } finally {
        this.isLoading = false;
      }
    },
  },
});
