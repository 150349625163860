import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_AdminKycBack = _resolveComponent("AdminKycBack")!
  const _component_UIInput = _resolveComponent("UIInput")!
  const _component_SharedDate = _resolveComponent("SharedDate")!
  const _component_SharedRadio = _resolveComponent("SharedRadio")!
  const _component_SharedInputDouble = _resolveComponent("SharedInputDouble")!
  const _component_SharedCheckbox = _resolveComponent("SharedCheckbox")!

  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(_ctx.b())
  }, [
    _createVNode(_component_AdminKycBack, {
      title: "Участник",
      onBack: _ctx.handleBack
    }, null, 8, ["onBack"]),
    _createElementVNode("div", {
      class: _normalizeClass(_ctx.b('form'))
    }, [
      _createElementVNode("div", {
        class: _normalizeClass(_ctx.b('fields'))
      }, [
        _createElementVNode("div", {
          class: _normalizeClass(_ctx.b('field'))
        }, [
          _createElementVNode("h4", {
            class: _normalizeClass(_ctx.b('field-label'))
          }, _toDisplayString(_ctx.$t("Фамилия")), 3),
          _createVNode(_component_UIInput, {
            view: "light",
            "is-readonly": true,
            modelValue: _ctx.kybParticipant.sur_name,
            "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.kybParticipant.sur_name) = $event))
          }, null, 8, ["modelValue"])
        ], 2),
        _createElementVNode("div", {
          class: _normalizeClass(_ctx.b('field'))
        }, [
          _createElementVNode("h4", {
            class: _normalizeClass(_ctx.b('field-label'))
          }, _toDisplayString(_ctx.$t("Имя")), 3),
          _createVNode(_component_UIInput, {
            view: "light",
            "is-readonly": true,
            modelValue: _ctx.kybParticipant.name,
            "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.kybParticipant.name) = $event))
          }, null, 8, ["modelValue"])
        ], 2),
        _createElementVNode("div", {
          class: _normalizeClass(_ctx.b('field'))
        }, [
          _createElementVNode("h4", {
            class: _normalizeClass(_ctx.b('field-label'))
          }, _toDisplayString(_ctx.$t("Отчество")), 3),
          _createVNode(_component_UIInput, {
            view: "light",
            "is-readonly": true,
            modelValue: _ctx.kybParticipant.patronymic,
            "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.kybParticipant.patronymic) = $event))
          }, null, 8, ["modelValue"])
        ], 2),
        _createElementVNode("div", {
          class: _normalizeClass(_ctx.b('field'))
        }, [
          _createElementVNode("h4", {
            class: _normalizeClass(_ctx.b('field-label'))
          }, _toDisplayString(_ctx.$t("Гражданство")), 3),
          _createVNode(_component_UIInput, {
            view: "light",
            "is-readonly": true,
            modelValue: _ctx.kybParticipant.citizenship,
            "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.kybParticipant.citizenship) = $event))
          }, null, 8, ["modelValue"])
        ], 2),
        _createElementVNode("div", {
          class: _normalizeClass(_ctx.b('field'))
        }, [
          _createElementVNode("h4", {
            class: _normalizeClass(_ctx.b('field-label'))
          }, _toDisplayString(_ctx.$t("Дата рождения")), 3),
          _createVNode(_component_SharedDate, {
            format: "yyyy-MM-dd",
            "is-readonly": true,
            date: _ctx.kybParticipant.birth_date,
            "onUpdate:date": _cache[4] || (_cache[4] = ($event: any) => ((_ctx.kybParticipant.birth_date) = $event))
          }, null, 8, ["date"])
        ], 2),
        _createElementVNode("div", {
          class: _normalizeClass(_ctx.b('field'))
        }, [
          _createElementVNode("h4", {
            class: _normalizeClass(_ctx.b('field-label'))
          }, _toDisplayString(_ctx.$t("Пол")), 3),
          _createElementVNode("div", {
            class: _normalizeClass(_ctx.b('field-radios'))
          }, [
            _createElementVNode("div", {
              class: _normalizeClass(_ctx.b('field-radio'))
            }, [
              _createVNode(_component_SharedRadio, {
                view: "light",
                checkedValue: "male",
                "is-readonly": true,
                value: _ctx.kybParticipant.gender,
                "onUpdate:value": _cache[5] || (_cache[5] = ($event: any) => ((_ctx.kybParticipant.gender) = $event))
              }, null, 8, ["value"]),
              _createElementVNode("p", {
                class: _normalizeClass(_ctx.b('field-radio-label'))
              }, _toDisplayString(_ctx.$t("Мужчина")), 3)
            ], 2),
            _createElementVNode("div", {
              class: _normalizeClass(_ctx.b('field-radio'))
            }, [
              _createVNode(_component_SharedRadio, {
                view: "light",
                checkedValue: "female",
                "is-readonly": true,
                value: _ctx.kybParticipant.gender,
                "onUpdate:value": _cache[6] || (_cache[6] = ($event: any) => ((_ctx.kybParticipant.gender) = $event))
              }, null, 8, ["value"]),
              _createElementVNode("p", {
                class: _normalizeClass(_ctx.b('field-radio-label'))
              }, _toDisplayString(_ctx.$t("Женщина")), 3)
            ], 2)
          ], 2)
        ], 2)
      ], 2),
      _createElementVNode("div", {
        class: _normalizeClass(_ctx.b('address'))
      }, [
        _createElementVNode("h4", {
          class: _normalizeClass(_ctx.b('field-label'))
        }, _toDisplayString(_ctx.$t("Адрес организации")), 3),
        _createElementVNode("div", {
          class: _normalizeClass(_ctx.b('fields', { address: true }))
        }, [
          _createVNode(_component_UIInput, {
            view: "light",
            placeholder: "Cтрана",
            "is-readonly": true,
            modelValue: _ctx.kybParticipant.address.country,
            "onUpdate:modelValue": _cache[7] || (_cache[7] = ($event: any) => ((_ctx.kybParticipant.address.country) = $event))
          }, null, 8, ["modelValue"]),
          _createVNode(_component_UIInput, {
            view: "light",
            "is-readonly": true,
            modelValue: _ctx.kybParticipant.address.city,
            "onUpdate:modelValue": _cache[8] || (_cache[8] = ($event: any) => ((_ctx.kybParticipant.address.city) = $event))
          }, null, 8, ["modelValue"]),
          _createVNode(_component_UIInput, {
            view: "light",
            placeholder: "Штат / Область",
            "is-readonly": true,
            modelValue: _ctx.kybParticipant.address.state,
            "onUpdate:modelValue": _cache[9] || (_cache[9] = ($event: any) => ((_ctx.kybParticipant.address.state) = $event))
          }, null, 8, ["modelValue"]),
          _createVNode(_component_UIInput, {
            view: "light",
            placeholder: "Улица",
            "is-readonly": true,
            modelValue: _ctx.kybParticipant.address.street,
            "onUpdate:modelValue": _cache[10] || (_cache[10] = ($event: any) => ((_ctx.kybParticipant.address.street) = $event))
          }, null, 8, ["modelValue"]),
          _createVNode(_component_UIInput, {
            view: "light",
            placeholder: "Номер Офиса",
            "is-readonly": true,
            modelValue: _ctx.kybParticipant.address.office,
            "onUpdate:modelValue": _cache[11] || (_cache[11] = ($event: any) => ((_ctx.kybParticipant.address.office) = $event))
          }, null, 8, ["modelValue"]),
          _createVNode(_component_UIInput, {
            view: "light",
            placeholder: "Индекс",
            "is-readonly": true,
            modelValue: _ctx.kybParticipant.address.zip,
            "onUpdate:modelValue": _cache[12] || (_cache[12] = ($event: any) => ((_ctx.kybParticipant.address.zip) = $event))
          }, null, 8, ["modelValue"])
        ], 2)
      ], 2),
      _createElementVNode("div", {
        class: _normalizeClass(_ctx.b('fields'))
      }, [
        _createElementVNode("div", {
          class: _normalizeClass(_ctx.b('field'))
        }, [
          _createElementVNode("h4", {
            class: _normalizeClass(_ctx.b('field-label'))
          }, _toDisplayString(_ctx.$t("Доля владения в обществе")), 3),
          _createVNode(_component_SharedInputDouble, {
            view: "light",
            "is-readonly": true,
            modelValue: _ctx.kybParticipant.share_in_org,
            "onUpdate:modelValue": _cache[13] || (_cache[13] = ($event: any) => ((_ctx.kybParticipant.share_in_org) = $event))
          }, null, 8, ["modelValue"])
        ], 2),
        _createElementVNode("div", {
          class: _normalizeClass(_ctx.b('field'))
        }, [
          _createElementVNode("div", {
            class: _normalizeClass(_ctx.b('field-checkboxes'))
          }, [
            _createElementVNode("div", {
              class: _normalizeClass(_ctx.b('field-checkbox'))
            }, [
              _createVNode(_component_SharedCheckbox, {
                view: "22",
                "is-readonly": true,
                checked: _ctx.kybParticipant.is_beneficiary,
                "onUpdate:checked": _cache[14] || (_cache[14] = ($event: any) => ((_ctx.kybParticipant.is_beneficiary) = $event))
              }, null, 8, ["checked"]),
              _createElementVNode("p", {
                class: _normalizeClass(_ctx.b('field-checkbox-label'))
              }, _toDisplayString(_ctx.$t("Является Бенефициаром")), 3)
            ], 2),
            _createElementVNode("div", {
              class: _normalizeClass(_ctx.b('field-checkbox'))
            }, [
              _createVNode(_component_SharedCheckbox, {
                view: "22",
                "is-readonly": true,
                checked: _ctx.kybParticipant.is_director,
                "onUpdate:checked": _cache[15] || (_cache[15] = ($event: any) => ((_ctx.kybParticipant.is_director) = $event))
              }, null, 8, ["checked"]),
              _createElementVNode("p", {
                class: _normalizeClass(_ctx.b('field-checkbox-label'))
              }, _toDisplayString(_ctx.$t("Является директором")), 3)
            ], 2)
          ], 2)
        ], 2)
      ], 2)
    ], 2)
  ], 2))
}