import { defineComponent, PropType } from "vue";

import { COMPONENT_NAME, SVG } from "./attributes";

import { AdminApplicationTableRow } from "./row";

import { PAGE_SIZE, SvgAttribute } from "@/shared/constants/constants";
import { AdminDepositWithdrawalListResponse } from "@/shared/repository/modules/admin/deposit-withdrawal/repo";
import { Search, Sort } from "@/shared/constants/interfaces";

export default defineComponent({
  name: COMPONENT_NAME,
  components: {
    AdminApplicationTableRow,
  },
  props: {
    depositWithdrawalList: {
      type: <PropType<AdminDepositWithdrawalListResponse[]>>Array,
      default: () => [],
    },
    depositWithdrawalItemId: {
      type: Number,
      default: 0,
    },
    search: {
      type: <PropType<Search>>Object,
      required: true,
    },
    sort: {
      type: <PropType<Sort>>Object,
      required: true,
    },
    isWork: {
      type: Boolean,
      default: false,
    },
    isReject: {
      type: Boolean,
      default: false,
    },
  },
  emits: {
    "update:depositWithdrawalList": null,
    "update:depositWithdrawalItemId": null,
    "update:isWork": null,
    "update:isReject": null,
  },
  computed: {
    displayedIcons(): SvgAttribute {
      return SVG;
    },

    displayedColumns(): string[] {
      return [
        "<span>Дата</span>",
        "<span>ID <br /> польз.</span>",
        "<span>почта</span>",
        "<span>тип</span>",
        "<span>сумма</span>",
        "<span>Цена <br /> исполнения</span>",
        "<span>Объем <br /> сделки</span>",
        "<span>Комис.</span>",
        "<span>№</span>",
        "<span>Статус</span>",
        "<span>Этап</span>",
      ];
    },

    displayedDepositWithdrawalList(): AdminDepositWithdrawalListResponse[] {
      return this.search.value
        ? this.depositWithdrawalList.filter((item) =>
            `${item.id} ${item.amount} ${item.created_at} ${item.user.id} ${item.user.email}`
              .toLowerCase()
              .includes(this.search.value.toLowerCase())
          )
        : this.depositWithdrawalList.slice(0, PAGE_SIZE * (this.sort.page + 1));
    },
  },
  methods: {
    handleDepositWithdrawalList(
      depositWithdrawalList: AdminDepositWithdrawalListResponse[]
    ): void {
      this.$emit("update:depositWithdrawalList", depositWithdrawalList);
    },

    handleDepositWithdrawalItemId(id: number): void {
      this.$emit("update:depositWithdrawalItemId", id);
    },

    handleWork(isWork: boolean): void {
      this.$emit("update:isWork", isWork);
    },

    handleReject(isReject: boolean): void {
      this.$emit("update:isReject", isReject);
    },
  },
});
