import { defineComponent } from "vue";

import { COMPONENT_NAME } from "./attributes";
import { HistoryTableRow } from "./row";
import { HistoryTableRowApplication } from "./row-application";

import { UIButton } from "@/components/ui";
import { DepositWithdrawalHistoryResponse } from "@/shared/repository/modules/deposit-withdrawal/repo";
import { HistoryTab } from "@/shared/constants/enums";

type HistoryTableRowComponent =
  | typeof HistoryTableRow
  | typeof HistoryTableRowApplication;

export interface HistoryTableColumn {
  id: number;
  label: string;
}

export default defineComponent({
  name: COMPONENT_NAME,
  components: {
    HistoryTableRow,
    HistoryTableRowApplication,
    UIButton,
  },
  props: {
    isDetails: {
      type: Boolean,
      default: false,
    },
  },
  emits: {
    "update:isDetails": null,
  },
  data() {
    return {
      historyRepo: this.$projectServices.historyRepo,
      depositWithdrawalRepo: this.$projectServices.depositWithdrawalRepo,
    };
  },
  computed: {
    displayedColumns(): HistoryTableColumn[] {
      switch (this.historyRepo.tab) {
        case HistoryTab.depositWithdrawal:
          return [
            "Дата и время",
            "Тип",
            "Сумма",
            "Валюта",
            "Комиссия",
            "Статус",
          ].map((column, columnIndex) => ({ id: columnIndex, label: column }));
        case HistoryTab.application:
          return [
            "Дата и время",
            "Тип",
            "Сумма",
            "Цена исполнения",
            "Объем сделки",
            "Комиссия",
            "Статус",
            "Этап",
          ].map((column, columnIndex) => ({ id: columnIndex, label: column }));
        default:
          return [];
      }
    },

    displayedSmallColumns(): HistoryTableColumn[] {
      return [
        {
          id: 0,
          label: "Сумма",
        },
        {
          id: 1,
          label: "Валюта",
        },
        {
          id: 2,
          label: "Дата",
        },
        {
          id: 3,
          label: "Статус",
        },
      ];
    },

    displayedRow(): HistoryTableRowComponent {
      switch (this.historyRepo.tab) {
        case HistoryTab.depositWithdrawal:
          return HistoryTableRow;
        case HistoryTab.application:
          return HistoryTableRowApplication;
        default:
          return HistoryTableRow;
      }
    },

    displayedRows(): DepositWithdrawalHistoryResponse[] {
      switch (this.historyRepo.tab) {
        case HistoryTab.depositWithdrawal:
          return this.depositWithdrawalRepo.history.filter((item) =>
            `$${item.created_at} ${item.amount} ${item.currency.name}`
              .toLowerCase()
              .includes(this.historyRepo.search.value.toLowerCase())
          );
        case HistoryTab.application:
          return [];
        default:
          return this.depositWithdrawalRepo.history;
      }
    },

    isApplication(): boolean {
      return this.historyRepo.tab === HistoryTab.application;
    },
  },
  methods: {
    handleIsDetails(isDetails: boolean): void {
      this.$emit("update:isDetails", isDetails);
    },
  },
});
