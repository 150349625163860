import { defineComponent } from "vue";

import { COMPONENT_NAME, SVG } from "./attributes";

import { SvgAttribute } from "@/shared/constants/constants";

export default defineComponent({
  name: COMPONENT_NAME,
  props: {
    view: {
      type: String,
      default: "",
    },
    modelValue: {
      type: Number,
      required: true,
    },
    isReadonly: {
      type: Boolean,
      default: false,
    },
  },
  emits: {
    "update:modelValue": null,
  },
  computed: {
    displayedIcons(): SvgAttribute {
      return SVG;
    },
  },
  methods: {
    handleChangeValue(event: Event): void {
      const { value } = <HTMLInputElement>event.target;

      this.$emit("update:modelValue", Number(value));

      if (!Number(value)) {
        (<HTMLInputElement>event.target).value = "0";
      }
    },
  },
});
