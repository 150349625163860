import { defineComponent } from "vue";

import { COMPONENT_NAME } from "./attributes";

import { UIInput, UIButton } from "@/components/ui";
import { SharedSelect } from "../select";
import { SelectOptionFactory } from "@/shared/factory/select";
import { SharedSelectOption } from "../select/model";

export default defineComponent({
  name: COMPONENT_NAME,
  components: {
    UIInput,
    UIButton,
    SharedSelect,
  },
  props: {
    title: {
      type: String,
      default: "",
    },
    type: {
      type: String,
      default: "",
    },
    id: {
      type: Number,
      default: 0,
    },
    email: {
      type: String,
      default: "",
    },
    isLoading: {
      type: Boolean,
      default: false,
    },
    isType: {
      type: Boolean,
      default: true,
    },
    isEmail: {
      type: Boolean,
      default: false,
    },
  },
  emits: {
    "update:type": null,
    "update:id": null,
    "update:email": null,
    search: null,
  },
  computed: {
    displayedOptions(): SharedSelectOption[] {
      return [
        SelectOptionFactory(
          {
            id: 0,
            label: "Физическое лицо",
            value: "kyc",
          },
          this.type
        ),
        SelectOptionFactory(
          {
            id: 1,
            label: "Юридическое лицо",
            value: "kyb",
          },
          this.type
        ),
      ];
    },

    isSearchDisabled(): boolean {
      if (this.isType) {
        return (!this.id && !Number(this.id)) || !this.type || this.isLoading;
      }

      return this.isLoading;
    },
  },
  methods: {
    handleChangeType(type: string): void {
      this.$emit("update:type", type);
    },

    handleChangeId(id: number): void {
      this.$emit("update:id", id);
    },

    handleChangeEmail(email: string): void {
      this.$emit("update:email", email);
    },

    handleSearch(): void {
      this.$emit("search");
    },
  },
});
