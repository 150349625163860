import { normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

const _hoisted_1 = ["innerHTML"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_VueDatePicker = _resolveComponent("VueDatePicker")!

  return (_openBlock(), _createBlock(_component_VueDatePicker, {
    class: _normalizeClass(_ctx.b('', { open: _ctx.isOpen, error: _ctx.isError })),
    range: _ctx.range,
    "multi-calendars": _ctx.multiCalendars,
    time: _ctx.time,
    format: _ctx.format,
    "preview-format": _ctx.format,
    placeholder: _ctx.$t(_ctx.placeholder),
    "enable-time-picker": _ctx.enableTimePicker,
    "model-value": _ctx.date,
    disabled: _ctx.isReadonly,
    locale: _ctx.displayedLocale,
    "menu-class-name": "shared-date__menu",
    "input-class-name": "shared-date__field",
    onDateUpdate: _ctx.changeDate,
    onCleared: _ctx.clearDate,
    onOpen: _ctx.handleOpen,
    onClosed: _ctx.handleClose
  }, {
    "input-icon": _withCtx(() => [
      _createElementVNode("span", {
        class: _normalizeClass(_ctx.b('icon')),
        innerHTML: _ctx.displayedIcon
      }, null, 10, _hoisted_1)
    ]),
    _: 1
  }, 8, ["class", "range", "multi-calendars", "time", "format", "preview-format", "placeholder", "enable-time-picker", "model-value", "disabled", "locale", "onDateUpdate", "onCleared", "onOpen", "onClosed"]))
}