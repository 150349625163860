import { ApplicationType } from "@/shared/constants/enums";

export enum AdminKycComponent {
  personalInformation = "personal-information",
  personalDocuments = "personal-documents",
  kybCompany = "kyc-company",
  kybBasicInformation = "kyb-basic-information",
  kybRegistrationInformation = "kyb-registration-information",
  kybParticipants = "kyb-participants",
  kybParticipant = "kyb-participant",
  kybParticipantDocuments = "kyb-participant-documents",
  kybDocuments = "kyb-documents",
}

export interface AdminKycHeader {
  id: number;
  type: ApplicationType;
}

export const AdminKycHeaderFactory = (
  payload: Partial<AdminKycHeader> = {}
): AdminKycHeader => {
  return {
    id: payload.id ?? 0,
    type: payload.type ?? ApplicationType.kyc,
  };
};
