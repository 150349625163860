import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createBlock as _createBlock } from "vue"

const _hoisted_1 = ["innerHTML"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_AdminApplicationTableRow = _resolveComponent("AdminApplicationTableRow")!

  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(_ctx.b())
  }, [
    _createElementVNode("div", {
      class: _normalizeClass(_ctx.b('columns'))
    }, [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.displayedColumns, (column) => {
        return (_openBlock(), _createElementBlock("div", {
          class: _normalizeClass(_ctx.b('column')),
          key: column
        }, [
          _createElementVNode("p", {
            class: _normalizeClass(_ctx.b('column-label')),
            innerHTML: _ctx.$t(column)
          }, null, 10, _hoisted_1)
        ], 2))
      }), 128))
    ], 2),
    _createElementVNode("div", {
      class: _normalizeClass(_ctx.b('rows'))
    }, [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.displayedDepositWithdrawalList, (depositWithdrawalItem) => {
        return (_openBlock(), _createBlock(_component_AdminApplicationTableRow, {
          class: _normalizeClass(_ctx.b('row')),
          key: depositWithdrawalItem.id,
          "deposit-withdrawal-item": depositWithdrawalItem,
          "deposit-withdrawal-item-id": _ctx.depositWithdrawalItemId,
          "is-work": _ctx.isWork,
          "is-reject": _ctx.isReject,
          onDepositWithdrawalList: _ctx.handleDepositWithdrawalList,
          onDepositWithdrawalItemId: _ctx.handleDepositWithdrawalItemId,
          onWork: _ctx.handleWork,
          onReject: _ctx.handleReject
        }, null, 8, ["class", "deposit-withdrawal-item", "deposit-withdrawal-item-id", "is-work", "is-reject", "onDepositWithdrawalList", "onDepositWithdrawalItemId", "onWork", "onReject"]))
      }), 128))
    ], 2)
  ], 2))
}