import { toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_SharedSelect = _resolveComponent("SharedSelect")!
  const _component_UIInput = _resolveComponent("UIInput")!
  const _component_AdminConfigRequisitesFormBanks = _resolveComponent("AdminConfigRequisitesFormBanks")!
  const _component_AdminConfigRequisitesFormBank = _resolveComponent("AdminConfigRequisitesFormBank")!
  const _component_UIButton = _resolveComponent("UIButton")!

  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(_ctx.b())
  }, [
    _createElementVNode("div", {
      class: _normalizeClass(_ctx.b('form'))
    }, [
      _createElementVNode("div", {
        class: _normalizeClass(_ctx.b('fields'))
      }, [
        _createElementVNode("div", {
          class: _normalizeClass(_ctx.b('field'))
        }, [
          _createElementVNode("h4", {
            class: _normalizeClass(_ctx.b('field-title'))
          }, _toDisplayString(_ctx.$t("Валюта")), 3),
          _createVNode(_component_SharedSelect, {
            view: "light",
            options: _ctx.displayedCurrencyOptions,
            icon: _ctx.displayedCurrencyIcon,
            value: _ctx.adminRequisitesShow.currency_id,
            "onUpdate:value": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.adminRequisitesShow.currency_id) = $event))
          }, null, 8, ["options", "icon", "value"])
        ], 2)
      ], 2),
      _createElementVNode("div", {
        class: _normalizeClass(_ctx.b('fields'))
      }, [
        _createElementVNode("div", {
          class: _normalizeClass(_ctx.b('field'))
        }, [
          _createElementVNode("h4", {
            class: _normalizeClass(_ctx.b('field-title'))
          }, _toDisplayString(_ctx.$t("Полное фирменное наименование Общества на официальном языке")), 3),
          _createVNode(_component_UIInput, {
            view: "light",
            modelValue: _ctx.adminRequisitesShow.org_full_name_local,
            "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.adminRequisitesShow.org_full_name_local) = $event))
          }, null, 8, ["modelValue"])
        ], 2),
        _createElementVNode("div", {
          class: _normalizeClass(_ctx.b('field'))
        }, [
          _createElementVNode("h4", {
            class: _normalizeClass(_ctx.b('field-title'))
          }, _toDisplayString(_ctx.$t("Полное фирменное наименование Общества на английском языке")), 3),
          _createVNode(_component_UIInput, {
            view: "light",
            modelValue: _ctx.adminRequisitesShow.org_full_name_eng,
            "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.adminRequisitesShow.org_full_name_eng) = $event))
          }, null, 8, ["modelValue"])
        ], 2),
        _createElementVNode("div", {
          class: _normalizeClass(_ctx.b('field'))
        }, [
          _createElementVNode("h4", {
            class: _normalizeClass(_ctx.b('field-title'))
          }, _toDisplayString(_ctx.$t("ИНН")), 3),
          _createVNode(_component_UIInput, {
            view: "light",
            modelValue: _ctx.adminRequisitesShow.inn,
            "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.adminRequisitesShow.inn) = $event))
          }, null, 8, ["modelValue"])
        ], 2),
        _createElementVNode("div", {
          class: _normalizeClass(_ctx.b('field'))
        }, [
          _createElementVNode("h4", {
            class: _normalizeClass(_ctx.b('field-title'))
          }, _toDisplayString(_ctx.$t("Р/с № (мультивалютный)")), 3),
          _createVNode(_component_UIInput, {
            view: "light",
            "is-disabled": true
          })
        ], 2),
        _createElementVNode("div", {
          class: _normalizeClass(_ctx.b('field'))
        }, [
          _createElementVNode("h4", {
            class: _normalizeClass(_ctx.b('field-title'))
          }, _toDisplayString(_ctx.$t("Код назначения платежа")), 3),
          _createVNode(_component_UIInput, {
            view: "light",
            modelValue: _ctx.adminRequisitesShow.payment_code_name,
            "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => ((_ctx.adminRequisitesShow.payment_code_name) = $event))
          }, null, 8, ["modelValue"])
        ], 2),
        _createElementVNode("div", {
          class: _normalizeClass(_ctx.b('field'))
        }, [
          _createElementVNode("h4", {
            class: _normalizeClass(_ctx.b('field-title'))
          }, _toDisplayString(_ctx.$t("Назначение платежа")), 3),
          _createVNode(_component_UIInput, {
            view: "light",
            "is-disabled": true,
            modelValue: _ctx.adminRequisitesShow.current_account,
            "onUpdate:modelValue": _cache[5] || (_cache[5] = ($event: any) => ((_ctx.adminRequisitesShow.current_account) = $event))
          }, null, 8, ["modelValue"])
        ], 2)
      ], 2)
    ], 2),
    _createVNode(_component_AdminConfigRequisitesFormBanks, {
      bankId: _ctx.bankId,
      "onUpdate:bankId": _cache[6] || (_cache[6] = ($event: any) => ((_ctx.bankId) = $event)),
      banks: _ctx.adminRequisitesShow.mex_requisite_banks,
      "onUpdate:banks": _cache[7] || (_cache[7] = ($event: any) => ((_ctx.adminRequisitesShow.mex_requisite_banks) = $event))
    }, null, 8, ["bankId", "banks"]),
    (_ctx.displayedBank)
      ? (_openBlock(), _createBlock(_component_AdminConfigRequisitesFormBank, {
          key: 0,
          correspondentBank: 
        _ctx.adminRequisitesShow.mex_requisite_banks[_ctx.displayedBankIndex]
          .correspondent_bank
      ,
          "onUpdate:correspondentBank": _cache[8] || (_cache[8] = ($event: any) => ((
        _ctx.adminRequisitesShow.mex_requisite_banks[_ctx.displayedBankIndex]
          .correspondent_bank
      ) = $event)),
          bic: 
        _ctx.adminRequisitesShow.mex_requisite_banks[_ctx.displayedBankIndex].bic
      ,
          "onUpdate:bic": _cache[9] || (_cache[9] = ($event: any) => ((
        _ctx.adminRequisitesShow.mex_requisite_banks[_ctx.displayedBankIndex].bic
      ) = $event)),
          inn: 
        _ctx.adminRequisitesShow.mex_requisite_banks[_ctx.displayedBankIndex].inn
      ,
          "onUpdate:inn": _cache[10] || (_cache[10] = ($event: any) => ((
        _ctx.adminRequisitesShow.mex_requisite_banks[_ctx.displayedBankIndex].inn
      ) = $event)),
          correspondentAccount: 
        _ctx.adminRequisitesShow.mex_requisite_banks[_ctx.displayedBankIndex]
          .correspondent_account
      ,
          "onUpdate:correspondentAccount": _cache[11] || (_cache[11] = ($event: any) => ((
        _ctx.adminRequisitesShow.mex_requisite_banks[_ctx.displayedBankIndex]
          .correspondent_account
      ) = $event)),
          correspondentSwift: 
        _ctx.adminRequisitesShow.mex_requisite_banks[_ctx.displayedBankIndex]
          .correspondent_swift
      ,
          "onUpdate:correspondentSwift": _cache[12] || (_cache[12] = ($event: any) => ((
        _ctx.adminRequisitesShow.mex_requisite_banks[_ctx.displayedBankIndex]
          .correspondent_swift
      ) = $event)),
          recipientBank: 
        _ctx.adminRequisitesShow.mex_requisite_banks[_ctx.displayedBankIndex]
          .recipient_bank
      ,
          "onUpdate:recipientBank": _cache[13] || (_cache[13] = ($event: any) => ((
        _ctx.adminRequisitesShow.mex_requisite_banks[_ctx.displayedBankIndex]
          .recipient_bank
      ) = $event)),
          recipientCorrespondentAccount: 
        _ctx.adminRequisitesShow.mex_requisite_banks[_ctx.displayedBankIndex]
          .recipient_correspondent_account
      ,
          "onUpdate:recipientCorrespondentAccount": _cache[14] || (_cache[14] = ($event: any) => ((
        _ctx.adminRequisitesShow.mex_requisite_banks[_ctx.displayedBankIndex]
          .recipient_correspondent_account
      ) = $event)),
          recipientCorrespondentSwift: 
        _ctx.adminRequisitesShow.mex_requisite_banks[_ctx.displayedBankIndex]
          .recipient_correspondent_swift
      ,
          "onUpdate:recipientCorrespondentSwift": _cache[15] || (_cache[15] = ($event: any) => ((
        _ctx.adminRequisitesShow.mex_requisite_banks[_ctx.displayedBankIndex]
          .recipient_correspondent_swift
      ) = $event))
        }, null, 8, ["correspondentBank", "bic", "inn", "correspondentAccount", "correspondentSwift", "recipientBank", "recipientCorrespondentAccount", "recipientCorrespondentSwift"]))
      : _createCommentVNode("", true),
    _createElementVNode("div", {
      class: _normalizeClass(_ctx.b('buttons'))
    }, [
      _createVNode(_component_UIButton, {
        view: "main-outline",
        label: "Назад",
        disabled: _ctx.isCancelDisabled,
        onClick: _ctx.handleCancel
      }, null, 8, ["disabled", "onClick"]),
      _createVNode(_component_UIButton, {
        view: "main",
        label: "Сохранить",
        disabled: _ctx.isSaveDisabled,
        onClick: _ctx.handleSave
      }, null, 8, ["disabled", "onClick"])
    ], 2)
  ], 2))
}