<script lang="ts" src="./component.ts"></script>
<style lang="scss" src="./component.scss"></style>

<template>
  <div :class="b()">
    <p :class="b('label')">
      {{ label }}
    </p>

    <button :class="b('input')" :disabled="isCopy" @click="handleCopy">
      <span :class="b('input-label', { copy: isCopy })">
        {{ value }}
      </span>

      <span v-if="isCopy" :class="b('copy')"> Скопировано </span>

      <span v-else :class="b('icon')" v-html="displayedSvg.copy" />
    </button>
  </div>
</template>
