export enum KybApiRoutes {
  basicInfo = "/api/v1/kyb/basic-info",
  registrationInfo = "/api/v1/kyb/registration-info",

  participants = "/api/v1/kyb/participants",
  participant = "/api/v1/kyb/participant",
  participantDelete = "/api/v1/kyb/participant/delete",
  participantById = "/api/v1/kyb/participant/",
  participantUploadFile = "/api/v1/kyb/participant/upload/file",
  participantDeleteFile = "/api/v1/kyb/participant-file/delete/",
  participantFiles = "/api/v1/kyb/participant-files",

  userFiles = "/api/v1/kyb/user-files",
  uploadFile = "/api/v1/kyb/upload/file",
  deleteFile = "/api/v1/kyb/delete/file/",

  verification = "/api/v1/kyb/verification",
}

export interface KybProjectUrlGenerator {
  basicInfo(): string;
  registrationInfo(): string;

  participants(): string;
  participant(): string;
  participantDelete(id: number): string;
  participantById(id: number): string;
  participantUploadFile(): string;
  participantDeleteFile(): string;
  participantFiles(userId: number, participantId: number): string;

  userFiles(): string;
  uploadFile(): string;
  deleteFile(): string;

  verification(): string;
}

export class KybUrlGenerator implements KybProjectUrlGenerator {
  basicInfo(): string {
    return KybApiRoutes.basicInfo;
  }

  registrationInfo(): string {
    return KybApiRoutes.registrationInfo;
  }

  participants(): string {
    return KybApiRoutes.participants;
  }

  participant(): string {
    return KybApiRoutes.participant;
  }

  participantDelete(id: number): string {
    return `${KybApiRoutes.participantDelete}?id=${id}`;
  }

  participantById(id: number): string {
    return `${KybApiRoutes.participantById}${id}?id=${id}`;
  }

  participantUploadFile(): string {
    return KybApiRoutes.participantUploadFile;
  }

  participantDeleteFile(): string {
    return KybApiRoutes.participantDeleteFile;
  }

  participantFiles(userId: number, participantId: number): string {
    return `${KybApiRoutes.participantFiles}?user_id=${userId}&kyb_participant_id=${participantId}`;
  }

  userFiles(): string {
    return KybApiRoutes.userFiles;
  }

  uploadFile(): string {
    return KybApiRoutes.uploadFile;
  }

  deleteFile(): string {
    return KybApiRoutes.deleteFile;
  }

  verification(): string {
    return KybApiRoutes.verification;
  }
}
