import { defineComponent, PropType } from "vue";
import moment from "moment";

import { COMPONENT_NAME, SVG } from "./attributes";

import { SvgAttribute } from "@/shared/constants/constants";
import { AdminDepositWithdrawalListResponse } from "@/shared/repository/modules/admin/deposit-withdrawal/repo";
import { AdminApplicationType } from "@/shared/repository/modules/admin/application/enums";
import { AdminDepositWithdrawalStatus } from "@/shared/repository/modules/admin/deposit-withdrawal/enums";
import {
  formatCryptoCurrency,
  formatFiatCurrency,
} from "@/shared/utils/amount-helpers";
import { AdminCurrencyType } from "@/shared/repository/modules/admin/currency/enums";

enum OptionType {
  work = "work",
  edit = "edit",
  reject = "reject",
}

interface Option {
  id: number;
  label: string;
  isDisabled: boolean;
  type: OptionType;
}

export default defineComponent({
  name: COMPONENT_NAME,
  props: {
    depositWithdrawalItem: {
      type: <PropType<AdminDepositWithdrawalListResponse>>Object,
      required: true,
    },
    depositWithdrawalItemId: {
      type: Number,
      default: 0,
    },
    isWork: {
      type: Boolean,
      default: false,
    },
    isReject: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      isVisible: false,
      isLoading: false,
      adminDepositWithdrawalRepository:
        this.$projectServices.adminDepositWithdrawalRepository,
    };
  },
  emits: {
    depositWithdrawalList: null,
    depositWithdrawalItemId: null,
    work: null,
    reject: null,
  },
  computed: {
    displayedSvg(): SvgAttribute {
      return SVG;
    },

    displayedCreatedAt(): string {
      return moment(this.depositWithdrawalItem.created_at).format(
        "YYYY-MM-DD HH:mm:ss"
      );
    },

    displayedAppType(): string {
      if (this.isDeposit) return "Пополнение";
      else if (this.isWithdrawal) return "Вывод";

      return "";
    },

    displayedStatusIcon(): string {
      return SVG[this.depositWithdrawalItem.status];
    },

    displayedAmount(): string {
      const { currency_type, amount } = this.depositWithdrawalItem;

      return currency_type === AdminCurrencyType.fiat
        ? formatFiatCurrency(amount)
        : formatCryptoCurrency(amount);
    },

    displayedStatus(): string {
      switch (this.depositWithdrawalItem.status) {
        case AdminDepositWithdrawalStatus.pending:
          return "Создано";
        case AdminDepositWithdrawalStatus.waiting:
          return "Ожидание подтверждения";
        case AdminDepositWithdrawalStatus.reject:
          return "Отклонено";
        case AdminDepositWithdrawalStatus.in_work:
          return "В работе";
        case AdminDepositWithdrawalStatus.completed:
          return "Выполнено";
        default:
          return "";
      }
    },

    displayedOptions(): Option[] {
      const { status } = this.depositWithdrawalItem;

      return [
        {
          id: 0,
          label: "Взять в работу",
          type: OptionType.work,
          isDisabled: this.isLoading,
        },
        {
          id: 1,
          label: "Редактировать",
          type: OptionType.edit,
          isDisabled:
            this.isLoading || status === AdminDepositWithdrawalStatus.pending,
        },
        {
          id: 2,
          label: "Отклонить",
          type: OptionType.reject,
          isDisabled: this.isLoading,
        },
      ];
    },

    isDeposit(): boolean {
      return (
        this.depositWithdrawalItem.app_type === AdminApplicationType.deposit
      );
    },

    isWithdrawal(): boolean {
      return (
        this.depositWithdrawalItem.app_type === AdminApplicationType.withdrawal
      );
    },

    isDisabled(): boolean {
      return this.isLoading;
    },

    isStageGroupShow(): boolean {
      return (
        this.depositWithdrawalItem.status ===
        AdminDepositWithdrawalStatus.pending
      );
    },
  },
  methods: {
    handleToggle(): void {
      this.isVisible = !this.isVisible;
    },

    handleHide(): void {
      this.isVisible = false;
    },

    handleInWork(): void {
      this.handleHide();

      this.$emit("work", true);
      this.$emit("reject", false);

      this.$emit("depositWithdrawalItemId", this.depositWithdrawalItem.id);
    },

    handleOption({ type }: Option): void {
      switch (type) {
        case OptionType.work:
          this.$emit("work", true);
          this.$emit("reject", false);

          break;
        case OptionType.edit:
          this.$emit("work", false);
          this.$emit("reject", false);

          break;
        case OptionType.reject:
          this.$emit("work", false);
          this.$emit("reject", true);

          break;
        default:
          break;
      }

      this.$emit("depositWithdrawalItemId", this.depositWithdrawalItem.id);

      this.handleHide();
    },

    async handleRemove(): Promise<void> {
      try {
        this.isLoading = true;

        await this.adminDepositWithdrawalRepository.delete(
          this.depositWithdrawalItem.id
        );

        this.$emit(
          "depositWithdrawalList",
          await this.adminDepositWithdrawalRepository.list()
        );
      } catch (error) {
        console.log(error);
      } finally {
        this.isLoading = false;
      }
    },
  },
});
