import { normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, renderSlot as _renderSlot } from "vue"

const _hoisted_1 = ["disabled"]
const _hoisted_2 = ["innerHTML"]
const _hoisted_3 = ["innerHTML"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("button", {
    class: _normalizeClass(_ctx.b('')),
    disabled: _ctx.isDisabled,
    onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.handleDownload && _ctx.handleDownload(...args)))
  }, [
    (!_ctx.isLoading)
      ? (_openBlock(), _createElementBlock("span", {
          key: 0,
          class: _normalizeClass(_ctx.b('icon')),
          innerHTML: _ctx.displayedSvg.download
        }, null, 10, _hoisted_2))
      : _createCommentVNode("", true),
    (!_ctx.isLoading)
      ? (_openBlock(), _createElementBlock("p", {
          key: 1,
          class: _normalizeClass(_ctx.b('label'))
        }, [
          _renderSlot(_ctx.$slots, "label")
        ], 2))
      : _createCommentVNode("", true),
    (_ctx.isLoading)
      ? (_openBlock(), _createElementBlock("span", {
          key: 2,
          class: _normalizeClass(_ctx.b('icon', { preloader: true })),
          innerHTML: _ctx.displayedSvg.preloader
        }, null, 10, _hoisted_3))
      : _createCommentVNode("", true)
  ], 10, _hoisted_1))
}