<script lang="ts" src="./component.ts"></script>
<style lang="scss" src="./component.scss"></style>

<template>
  <div :class="b()">
    <div :class="b('table')" v-for="table in displayedTable" :key="table.title">
      <div :class="b('header')">
        <h4 :class="b('header-title')">
          {{ table.title }}
        </h4>
      </div>

      <div :class="b('body')">
        <AdminConfigCurrencyRow
          :class="b('body-row')"
          v-for="row in table.rows"
          :key="row.id"
          :row="row"
        />
      </div>
    </div>

    <transition name="opacity">
      <AdminConfigCurrencyModal v-if="isModalShow" :class="b('modal')" />
    </transition>
  </div>
</template>
