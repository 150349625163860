// Application

export enum ApplicationType {
  kyc = "kyc",
  kyb = "kyb",
}

export enum ApplicationStatus {
  "default" = "",
  "pending" = "pending",
  "waiting" = "waiting",
  "completed" = "completed",
  "reject" = "reject",
  "progress" = "progress",
  "inWork" = "in_work",
}

// Search

export enum SearchType {
  all = "all",
  id = "id",
  email = "email",
  fio = "fio",
}

// File

export enum FileType {
  image = "image",
  document = "document",
}

export enum FileFor {
  kyc = "kyc",
  kyb = "kyb",
  participant = "participant",
}

// Kyc

export enum KycDocumentsFileName {
  "kyc-personal-documents-passport-selfie" = "kyc-personal-documents-passport-selfie",
  "kyc-personal-documents-passport-organization" = "kyc-personal-documents-passport-organization",
  "kyc-personal-documents-passport-address" = "kyc-personal-documents-passport-address",
  "kyc-personal-documents-selfie" = "kyc-personal-documents-selfie",
  "kyc-personal-documents-questionnaire" = "kyc-personal-documents-questionnaire",
  "kyc-personal-documents-personal-data" = "kyc-personal-documents-personal-data",
  "kyc-personal-documents-declaration" = "kyc-personal-documents-declaration",
  "kyc-personal-documents-broker" = "kyc-personal-documents-broker",
}

// Kyb

export enum KybParticipantDocumentsFileName {
  "kyb-participant-documents-passport-selfie" = "kyb-participant-documents-passport-selfie",
  "kyb-participant-documents-passport-organization" = "kyb-participant-documents-passport-organization",
  "kyb-participant-documents-passport-address" = "kyb-participant-documents-passport-address",
  "kyb-participant-documents-selfie" = "kyb-participant-documents-selfie",
  "kyb-participant-documents-questionnaire" = "kyb-participant-documents-questionnaire",
  "kyb-participant-documents-personal-data" = "kyb-participant-documents-personal-data",
}

export enum KybDocumentsFileName {
  "kyb-documents-registration-company" = "kyb-documents-registration-company",
  "kyb-documents-regulation" = "kyb-documents-regulation",
  "kyb-documents-list-of-shareholders" = "kyb-documents-list-of-shareholders",
  "kyb-documents-license" = "kyb-documents-license",
  "kyb-documents-check-addresses" = "kyb-documents-check-addresses",
  "kyb-documents-sow" = "kyb-documents-sow",
  "kyb-documents-sof" = "kyb-documents-sof",
  "kyb-documents-inn" = "kyb-documents-inn",
  "kyb-documents-record-sheet" = "kyb-documents-record-sheet",
  "kyb-documents-create" = "kyb-documents-create",
  "kyb-documents-update" = "kyb-documents-update",
  "kyb-documents-purpose" = "kyb-documents-purpose",
  "kyb-documents-finance" = "kyb-documents-finance",
  "kyb-documents-example" = "kyb-documents-example",
  "kyb-documents-document" = "kyb-documents-document",
  "kyb-documents-beneficiary" = "kyb-documents-beneficiary",
  "kyb-documents-broker" = "kyb-documents-broker",
  "kyb-documents-declaration" = "kyb-documents-declaration",
}

// Layout

export enum Layout {
  default = "default",
  auth = "auth",
  admin = "/admin",
}

// Routes

export enum RoutesName {
  login = "login",
  registration = "registration",
  forgot = "forgot",
  wallet = "wallet",
  application = "application",
  history = "history",
  settings = "settings",
  verification = "verification",
  adminUsers = "admin-users",
  adminKyc = "admin-kyc",
  adminApplication = "admin-application",
  adminConfig = "admin-config",
}

export enum RoutesTo {
  login = "/login",
  registration = "/registration",
  forgot = "/forgot",
  wallet = "/",
  application = "/application",
  history = "/history",
  settings = "/settings",
  verification = "/verification",
  adminUsers = "/admin/users",
  adminKyc = "/admin/kyc",
  adminApplication = "/admin/application",
  adminConfig = "/admin/config",
}

// Cookie

export enum CookieExpires {
  month = 31,
}

export enum CookieName {
  accessToken = "accessToken",
  language = "language",
  isVerification = "isVerification",
}

// Format

export enum MomentFormat {
  date = "MM.DD.YYYY",
  dateReverse = "YYYY-MM-DD",
}

// Language

export enum Language {
  ru = "ru",
  en = "en",
}

// Country

export enum Country {
  Afghanistan = "af",
  Albania = "al",
  Algeria = "dz",
  Andorra = "ad",
  Angola = "ao",
  "Antigua and Barbuda" = "ag",
  Argentina = "ar",
  Armenia = "am",
  Australia = "au",
  Austria = "at",
  Azerbaijan = "az",
  Bahamas = "bs",
  Bahrain = "bh",
  Bangladesh = "bd",
  Barbados = "bb",
  Belarus = "by",
  Belgium = "be",
  Belize = "bz",
  Benin = "bj",
  Bhutan = "bt",
  Bolivia = "bo",
  "Bosnia and Herzegovina" = "ba",
  Botswana = "bw",
  Brazil = "br",
  Brunei = "bn",
  Bulgaria = "bg",
  "Burkina Faso" = "bf",
  Burundi = "bi",
  "Cabo Verde" = "cv",
  Cambodia = "kh",
  Cameroon = "cm",
  Canada = "ca",
  "Central African Republic" = "cf",
  Chad = "td",
  Chile = "cl",
  China = "cn",
  Colombia = "co",
  Comoros = "km",
  "Congo Democratic Republic" = "cd",
  "Congo Republic of" = "cg",
  "Costa Rica" = "cr",
  "Cote dIvoire" = "ci",
  Croatia = "hr",
  Cuba = "cu",
  Cyprus = "cy",
  "Czech Republic" = "cz",
  Denmark = "dk",
  Djibouti = "dj",
  Dominica = "dm",
  "Dominican Republic" = "do",
  "East Timor" = "tl",
  Ecuador = "ec",
  Egypt = "eg",
  "El Salvador" = "sv",
  "Equatorial Guinea" = "gq",
  Eritrea = "er",
  Estonia = "ee",
  Eswatini = "sz",
  Ethiopia = "et",
  Fiji = "fj",
  Finland = "fi",
  France = "fr",
  Gabon = "ga",
  Gambia = "gm",
  Georgia = "ge",
  Germany = "de",
  Ghana = "gh",
  Greece = "gr",
  Grenada = "gd",
  Guatemala = "gt",
  Guinea = "gn",
  "Guinea Bissau" = "gw",
  Guyana = "gy",
  Haiti = "ht",
  Honduras = "hn",
  Hungary = "hu",
  Iceland = "is",
  India = "in",
  Indonesia = "id",
  Iran = "ir",
  Iraq = "iq",
  Ireland = "ie",
  Israel = "il",
  Italy = "it",
  Jamaica = "jm",
  Japan = "jp",
  Jordan = "jo",
  Kazakhstan = "kz",
  Kenya = "ke",
  Kiribati = "ki",
  "Korea North" = "kp",
  "Korea South" = "kr",
  Kosovo = "xk",
  Kuwait = "kw",
  Kyrgyzstan = "kg",
  Laos = "la",
  Latvia = "lv",
  Lebanon = "lb",
  Lesotho = "ls",
  Liberia = "lr",
  Libya = "ly",
  Liechtenstein = "li",
  Lithuania = "lt",
  Luxembourg = "lu",
  Madagascar = "mg",
  Malawi = "mw",
  Malaysia = "my",
  Maldives = "mv",
  Mali = "ml",
  Malta = "mt",
  "Marshall Islands" = "mh",
  Mauritania = "mr",
  Mauritius = "mu",
  Mexico = "mx",
  Micronesia = "fm",
  Moldova = "md",
  Monaco = "mc",
  Mongolia = "mn",
  Montenegro = "me",
  Morocco = "ma",
  Mozambique = "mz",
  Myanmar = "mm",
  Namibia = "na",
  Nauru = "nr",
  Nepal = "np",
  Netherlands = "nl",
  "New Zealand" = "nz",
  Nicaragua = "ni",
  Niger = "ne",
  Nigeria = "ng",
  "North Macedonia" = "mk",
  Norway = "no",
  Oman = "om",
  Pakistan = "pk",
  Palau = "pw",
  Palestine = "ps",
  Panama = "pa",
  "Papua New Guinea" = "pg",
  Paraguay = "py",
  Peru = "pe",
  Philippines = "ph",
  Poland = "pl",
  Portugal = "pt",
  Qatar = "qa",
  Romania = "ro",
  Russia = "ru",
  Rwanda = "rw",
  "Saint Kitts and Nevis" = "kn",
  "Saint Lucia" = "lc",
  "Saint Vincent and the Grenadines" = "vc",
  Samoa = "ws",
  "San Marino" = "sm",
  "Sao Tome and Principe" = "st",
  "Saudi Arabia" = "sa",
  Senegal = "sn",
  Serbia = "rs",
  Seychelles = "sc",
  "Sierra Leone" = "sl",
  Singapore = "sg",
  Slovakia = "sk",
  Slovenia = "si",
  "Solomon Islands" = "sb",
  Somalia = "so",
  "South Africa" = "za",
  "South Sudan" = "ss",
  Spain = "es",
  "Sri Lanka" = "lk",
  Sudan = "sd",
  Suriname = "sr",
  Sweden = "se",
  Switzerland = "ch",
  Syria = "sy",
  Taiwan = "tw",
  Tajikistan = "tj",
  Tanzania = "tz",
  Thailand = "th",
  Togo = "tg",
  Tonga = "to",
  "Trinidad and Tobago" = "tt",
  Tunisia = "tn",
  Turkey = "tr",
  Turkmenistan = "tm",
  Tuvalu = "tv",
  Uganda = "ug",
  Ukraine = "ua",
  "United Arab Emirates" = "ae",
  "United Kingdom" = "gb",
  "United States" = "us",
  Uruguay = "uy",
  Uzbekistan = "uz",
  Vanuatu = "vu",
  "Vatican City" = "va",
  Venezuela = "ve",
  Vietnam = "vn",
  Yemen = "ye",
  Zambia = "zm",
  Zimbabwe = "zw",
}

// History

export enum HistoryTab {
  depositWithdrawal = "deposit-withdrawal",
  application = "application",
}
