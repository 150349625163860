import { createStore, Store } from "vuex";

import { ProjectStore } from "@/store/model";

import {
  Profile,
  Notification,
  Modal,
  User,
  Users,
  Kyb,
  Requisites,
  AdminCurrency,
  AdminRequisites,
  DepositWithdrawal,
  Wallet,
  History,
  Application,
} from "@/store/modules";
import { initializeStore } from "@/shared/repository/instances/store";

const store = (): Store<ProjectStore> => <Store<ProjectStore>>createStore({
    modules: {
      profile: Profile,
      notification: Notification,
      modal: Modal,
      user: User,
      users: Users,
      kyb: Kyb,
      requisites: Requisites,
      adminCurrency: AdminCurrency,
      adminRequisites: AdminRequisites,
      depositWithdrawal: DepositWithdrawal,
      wallet: Wallet,
      history: History,
      application: Application,
    },
  });

initializeStore(store());

export default store;
