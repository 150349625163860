import { normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode } from "vue"

const _hoisted_1 = ["innerHTML"]
const _hoisted_2 = ["disabled"]
const _hoisted_3 = ["innerHTML"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_router_link = _resolveComponent("router-link")!
  const _component_SharedLanguage = _resolveComponent("SharedLanguage")!

  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(_ctx.b())
  }, [
    _createVNode(_component_router_link, {
      class: _normalizeClass(_ctx.b('wallet', { disabled: !_ctx.isVerified })),
      to: "/"
    }, {
      default: _withCtx(() => [
        _createElementVNode("span", {
          class: _normalizeClass(_ctx.b('icon', { wallet: true })),
          innerHTML: _ctx.displayedIcons.wallet
        }, null, 10, _hoisted_1),
        (_ctx.displayedCurrency.id)
          ? (_openBlock(), _createElementBlock("div", {
              key: 0,
              class: _normalizeClass(_ctx.b('wallet-group'))
            }, [
              _createElementVNode("p", {
                class: _normalizeClass(_ctx.b('label'))
              }, _toDisplayString(_ctx.displayedCurrency.amount), 3),
              _createElementVNode("p", {
                class: _normalizeClass(_ctx.b('wallet-currency'))
              }, _toDisplayString(_ctx.displayedCurrency.currency
              ? _ctx.displayedCurrency.currency.name.toUpperCase()
              : _ctx.displayedCurrency.name.toUpperCase()), 3)
            ], 2))
          : _createCommentVNode("", true)
      ]),
      _: 1
    }, 8, ["class"]),
    _createElementVNode("div", {
      class: _normalizeClass(_ctx.b('group'))
    }, [
      _createVNode(_component_SharedLanguage, {
        class: _normalizeClass(_ctx.b('language')),
        view: "light"
      }, null, 8, ["class"]),
      _createElementVNode("div", {
        class: _normalizeClass(_ctx.b('profile'))
      }, [
        _createElementVNode("p", {
          class: _normalizeClass(_ctx.b('label'))
        }, _toDisplayString(_ctx.displayedUserInfo.email), 3),
        _createElementVNode("button", {
          class: _normalizeClass(_ctx.b('profile-logout')),
          disabled: _ctx.isLogoutDisabled,
          onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.handleLogout && _ctx.handleLogout(...args)))
        }, [
          _createElementVNode("span", {
            class: _normalizeClass(_ctx.b('icon')),
            innerHTML: _ctx.displayedIcons.logout
          }, null, 10, _hoisted_3)
        ], 10, _hoisted_2)
      ], 2)
    ], 2)
  ], 2))
}