import { ApplicationType } from "@/shared/constants/enums";

export interface VerificationTab {
  label: string;
  labelSmall: string;
  value: ApplicationType;
  isActive: boolean;
  isDisabled: boolean;
}

export const VerificationTabs = (
  tab: ApplicationType,
  applicationType: ApplicationType | null
): VerificationTab[] => {
  return [
    {
      label: "Физическое лицо (KYC)",
      labelSmall: "Физ. лицо (KYC)",
      value: ApplicationType.kyc,
      isActive: tab === ApplicationType.kyc,
      isDisabled: applicationType === ApplicationType.kyb,
    },
    {
      label: "Юридическое лицо (KYB)",
      labelSmall: "Юр. лицо (KYB)",
      value: ApplicationType.kyb,
      isActive: tab === ApplicationType.kyb,
      isDisabled: applicationType === ApplicationType.kyc,
    },
  ];
};
