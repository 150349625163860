import { defineComponent, PropType } from "vue";

import { COMPONENT_NAME } from "./attributes";

import { UIInput } from "@/components/ui";
import { SharedDate, SharedRadio } from "@/components/shared";
import { AdminKycBack } from "../back";

import { AdminKycComponent } from "@/views/admin-kyc/model";
import { KybBasicInfoResponseFactory } from "@/shared/repository/modules/kyb/factory";

export default defineComponent({
  name: COMPONENT_NAME,
  components: {
    UIInput,
    SharedDate,
    SharedRadio,
    AdminKycBack,
  },
  props: {
    id: {
      type: Number,
      required: true,
    },
    component: {
      type: <PropType<AdminKycComponent>>String,
      default: "",
    },
  },
  emits: {
    "update:component": null,
  },
  data() {
    return {
      kybBasicInfo: KybBasicInfoResponseFactory(),
      kybRepository: this.$projectServices.kybRepository,
    };
  },
  async created(): Promise<void> {
    try {
      this.kybBasicInfo = await this.kybRepository.basicInfoById(this.id);
    } catch (e) {
      console.log(e);
    }
  },
  methods: {
    handleBack(): void {
      this.$emit("update:component", AdminKycComponent.kybCompany);
    },
  },
});
