import { PropType, defineComponent } from "vue";

import { COMPONENT_NAME } from "./attributes";

import { ApplicationType } from "@/shared/constants/enums";

enum TypeLabel {
  kyc = "Физическое лицо",
  kyb = "Юридическое лицо",
}

export default defineComponent({
  name: COMPONENT_NAME,
  props: {
    email: {
      type: String,
      required: true,
    },
    id: {
      type: Number,
      required: true,
    },
    type: {
      type: <PropType<ApplicationType>>String,
      required: true,
    },
  },
  computed: {
    displayedType(): TypeLabel {
      switch (this.type) {
        case ApplicationType.kyc:
          return TypeLabel.kyc;
        case ApplicationType.kyb:
          return TypeLabel.kyb;
        default:
          return TypeLabel.kyc;
      }
    },
  },
});
