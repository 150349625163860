<script lang="ts" src="./component.ts"></script>
<style lang="scss" src="./component.scss"></style>

<template>
  <SharedCard :class="b()">
    <div :class="b('header')">
      <SharedBack @click="handleBack" />

      <h3 :class="b('header-title')">
        {{ $t("Крипто реквизиты") }}
      </h3>
    </div>

    <div :class="b('form')">
      <SharedValidate
        field="name"
        :value="requisitesCryptoRequest.name"
        :is-validate="isValidate"
        v-model:field-value="requisitesCryptoFields.name"
      >
        <div :class="b('form-group')">
          <h5 :class="b('form-title')">
            {{ $t("Название*") }}
          </h5>

          <UIInput view="light" v-model="requisitesCryptoRequest.name" />
        </div>
      </SharedValidate>

      <div :class="b('form-group')"></div>

      <div :class="b('form-group')"></div>

      <SharedValidate
        field="coin"
        :value="requisitesCryptoRequest.coin"
        :is-validate="isValidate"
        v-model:field-value="requisitesCryptoFields.coin"
      >
        <div :class="b('form-group')">
          <h5 :class="b('form-title')">
            {{ $t("Монета*") }}
          </h5>

          <UIInput view="light" v-model="requisitesCryptoRequest.coin" />
        </div>
      </SharedValidate>

      <SharedValidate
        field="network"
        :value="requisitesCryptoRequest.network"
        :is-validate="isValidate"
        v-model:field-value="requisitesCryptoFields.network"
      >
        <div :class="b('form-group')">
          <h5 :class="b('form-title')">
            {{ $t("Сеть*") }}
          </h5>

          <UIInput view="light" v-model="requisitesCryptoRequest.network" />
        </div>
      </SharedValidate>

      <SharedValidate
        field="address"
        :value="requisitesCryptoRequest.address"
        :is-validate="isValidate"
        v-model:field-value="requisitesCryptoFields.address"
      >
        <div :class="b('form-group')">
          <h5 :class="b('form-title')">
            {{ $t("Адрес*") }}
          </h5>

          <UIInput view="light" v-model="requisitesCryptoRequest.address" />
        </div>
      </SharedValidate>
    </div>

    <UIButton
      :class="b('confirm')"
      label="Подтвердить"
      view="main"
      :disabled="isContinueDisabled"
      @click="handleContinue"
    />

    <transition name="opacity">
      <SettingsRequisitesVerification
        v-if="isVerification"
        :class="b('modal')"
        type="crypto-requisite"
        @close="handleVerificationClose"
      />
    </transition>
  </SharedCard>
</template>
