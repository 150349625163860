<script lang="ts" src="./component.ts"></script>
<style lang="scss" src="./component.scss"></style>

<template>
  <div :class="b()">
    <AdminKycBack title="Регистрационная информация" @back="handleBack" />

    <div :class="b('form')">
      <div :class="b('fields')">
        <div :class="b('field')">
          <h4 :class="b('field-label')">
            {{ $t("Организационно-правовая форма организации") }}
          </h4>

          <UIInput
            view="light"
            :is-readonly="true"
            v-model="kybRegistrationInfo.organisation_legal_form"
          />
        </div>

        <div :class="b('field')">
          <h4 :class="b('field-label')">
            {{ $t("Сфера бизнеса") }}
          </h4>

          <UIInput
            view="light"
            :is-readonly="true"
            v-model="kybRegistrationInfo.business_area"
          />
        </div>

        <div :class="b('field')">
          <h4 :class="b('field-label')">
            {{ $t("Контактный номер") }}
          </h4>

          <UIInput
            view="light"
            :is-readonly="true"
            v-model="kybRegistrationInfo.phone"
          />
        </div>

        <div :class="b('field')">
          <h4 :class="b('field-label')">
            {{ $t("Сайт") }}
          </h4>

          <UIInput
            view="light"
            :is-readonly="true"
            v-model="kybRegistrationInfo.site"
          />
        </div>

        <div :class="b('field')">
          <h4 :class="b('field-label')">
            {{ $t("Фактический адрес совпадает с адресом регистрации?") }}
          </h4>

          <div :class="b('field-radios')">
            <div :class="b('field-radio')">
              <SharedRadio
                view="light"
                :checkedValue="true"
                :is-readonly="true"
                v-model:value="kybRegistrationInfo.address.isActual"
              />

              <p :class="b('field-radio-label')">
                {{ $t("Да") }}
              </p>
            </div>

            <div :class="b('field-radio')">
              <SharedRadio
                view="light"
                :checkedValue="false"
                :is-readonly="true"
                v-model:value="kybRegistrationInfo.address.isActual"
              />

              <p :class="b('field-radio-label')">
                {{ $t("Нет") }}
              </p>
            </div>
          </div>
        </div>
      </div>

      <div :class="b('address')">
        <h4 :class="b('field-label')">
          {{ $t("Адрес организации") }}
        </h4>

        <div :class="b('fields', { address: true })">
          <UIInput
            view="light"
            :is-readonly="true"
            v-model="kybRegistrationInfo.address.country"
          />

          <UIInput
            view="light"
            :is-readonly="true"
            v-model="kybRegistrationInfo.address.city"
          />

          <UIInput
            view="light"
            :is-readonly="true"
            v-model="kybRegistrationInfo.address.state"
          />

          <UIInput
            view="light"
            :is-readonly="true"
            v-model="kybRegistrationInfo.address.street"
          />

          <UIInput
            view="light"
            :is-readonly="true"
            v-model="kybRegistrationInfo.address.office"
          />

          <UIInput
            view="light"
            :is-readonly="true"
            v-model="kybRegistrationInfo.address.zip"
          />
        </div>
      </div>

      <div :class="b('address')" v-if="!kybRegistrationInfo.address.isActual">
        <h4 :class="b('field-label')">
          {{ $t("Фактический адрес организации") }}
        </h4>

        <div :class="b('fields', { address: true })">
          <UIInput
            view="light"
            :is-readonly="true"
            v-model="kybRegistrationInfo.address.country_actual"
          />

          <UIInput
            view="light"
            :is-readonly="true"
            v-model="kybRegistrationInfo.address.city_actual"
          />

          <UIInput
            view="light"
            :is-readonly="true"
            v-model="kybRegistrationInfo.address.state_actual"
          />

          <UIInput
            view="light"
            :is-readonly="true"
            v-model="kybRegistrationInfo.address.street_actual"
          />

          <UIInput
            view="light"
            :is-readonly="true"
            v-model="kybRegistrationInfo.address.office_actual"
          />

          <UIInput
            view="light"
            :is-readonly="true"
            v-model="kybRegistrationInfo.address.zip_actual"
          />
        </div>
      </div>
    </div>
  </div>
</template>
