import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createBlock as _createBlock } from "vue"

const _hoisted_1 = ["onClick"]
const _hoisted_2 = ["innerHTML"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_WalletMainTableRow = _resolveComponent("WalletMainTableRow")!

  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(_ctx.b())
  }, [
    _createElementVNode("div", {
      class: _normalizeClass(_ctx.b('columns'))
    }, [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.displayedColumns, (column) => {
        return (_openBlock(), _createElementBlock("div", {
          class: _normalizeClass(_ctx.b('column')),
          key: column.id
        }, [
          _createElementVNode("p", {
            class: _normalizeClass(_ctx.b('column-label'))
          }, _toDisplayString(_ctx.$t(column.label)), 3),
          _createElementVNode("button", {
            class: _normalizeClass(_ctx.b('column-sort')),
            onClick: ($event: any) => (_ctx.handleSort(column))
          }, [
            _createElementVNode("span", {
              class: _normalizeClass(_ctx.b('column-icon')),
              innerHTML: _ctx.displayedSvg.sort
            }, null, 10, _hoisted_2)
          ], 10, _hoisted_1)
        ], 2))
      }), 128))
    ], 2),
    _createElementVNode("div", {
      class: _normalizeClass(_ctx.b('rows'))
    }, [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.displayedList, (item) => {
        return (_openBlock(), _createBlock(_component_WalletMainTableRow, {
          class: _normalizeClass(_ctx.b('row')),
          key: item.id,
          row: item
        }, null, 8, ["class", "row"]))
      }), 128))
    ], 2)
  ], 2))
}