import { AxiosInstance } from "axios";

import { WalletUrlGenerator } from "./url-generator";
import { WalletFilterType } from "./enums";

import { AdminCurrencyShowResponse } from "../admin/currency/repo";
import { AdminCurrencyShowResponseFactory } from "../admin/currency/factory";

import { WalletListResponse, WalletWithAmountResponse } from "./repo";
import {
  WalletListResponseFactory,
  WalletWithAmountResponseFactory,
} from "./factory";

import { ResponseData } from "@/shared/constants/interfaces";
import UserModuleState from "@/store/modules/user";
import { AdminCurrencyType } from "../admin/currency/enums";

export interface WalletProjectRepository {
  list(type: WalletFilterType): Promise<WalletListResponse[]>;
  currency(): Promise<AdminCurrencyShowResponse[]>;
  withAmount(type: WalletFilterType): Promise<WalletWithAmountResponse[]>;
}

export class WalletHttpRepo implements WalletProjectRepository {
  constructor(
    private readonly axios: AxiosInstance,
    private readonly urlGenerator: WalletUrlGenerator,
    private readonly userRepo: UserModuleState
  ) {}

  get userId(): number {
    return this.userRepo.userInfo.id;
  }

  async list(type: WalletFilterType): Promise<WalletListResponse[]> {
    const { data } = await this.axios.get<
      ResponseData<AdminCurrencyShowResponse[]>
    >(this.urlGenerator.list(this.userId, type));

    return data.data
      .filter((item) => {
        if (type === WalletFilterType.all) return !item.frozen;
        else if (type === WalletFilterType.fiat)
          return !item.frozen && item.type === AdminCurrencyType.fiat;

        return !item.frozen && item.type === AdminCurrencyType.crypto;
      })
      .map((item) => WalletListResponseFactory(item))
      .sort((a, b) => {
        if (
          a.type === AdminCurrencyType.fiat &&
          b.type !== AdminCurrencyType.fiat
        )
          return -1;
        if (
          a.type !== AdminCurrencyType.fiat &&
          b.type === AdminCurrencyType.fiat
        )
          return 1;

        return b.amount - a.amount;
      });
  }

  async currency(): Promise<AdminCurrencyShowResponse[]> {
    const { data } = await this.axios.get<
      ResponseData<AdminCurrencyShowResponse[]>
    >(this.urlGenerator.currency());

    return data.data.map((item) => AdminCurrencyShowResponseFactory(item));
  }

  async withAmount(
    type: WalletFilterType
  ): Promise<WalletWithAmountResponse[]> {
    const { data } = await this.axios.get<
      ResponseData<WalletWithAmountResponse[]>
    >(this.urlGenerator.withAmount(this.userId, type));

    return data.data.map((item) => WalletWithAmountResponseFactory(item));
  }
}
