<script lang="ts" src="./component.ts"></script>
<style lang="scss" src="./component.scss"></style>

<template>
  <div :class="b()">
    <div :class="b('fields')">
      <div :class="b('scores')">
        <div :class="b('score')">
          <h4 :class="b('title')">
            {{ $t("Со счета") }}
          </h4>

          <SharedCryptoSelect
            :placeholder="$t('Выберите из пресетов')"
            :options="displayedFromOptions"
            :value="displayedApplicationStoreRequest.currency_id_from"
            @update:value="handleCurrencyIdFrom"
          />
        </div>

        <span :class="b('scores-icon')" v-html="displayedArrowIcon"></span>

        <div :class="b('score')">
          <h4 :class="b('title')">
            {{ $t("На счет") }}
          </h4>

          <SharedCryptoSelect
            :placeholder="$t('Выберите из пресетов / создайте новый')"
            :options="displayedToOptions"
            :value="displayedApplicationStoreRequest.currency_id_to"
            @update:value="handleCurrencyIdTo"
          />
        </div>
      </div>

      <div :class="b('amount')">
        <div :class="b('amount-group')">
          <h4 :class="b('title')">
            {{ $t("Сумма") }}
          </h4>

          <UIButton label="МАКС" view="max" @click="handleMax" />
        </div>

        <SharedCurrency
          label="EUR"
          :value="displayedApplicationStoreRequest.amount"
          :is-number="true"
          @update:value="handleAmount"
        />

        <div v-if="isCommissionsShow" :class="b('message', { amount: true })">
          <div :class="b('message-item')">
            <p :class="b('message-label')">
              {{ $t("Комиссия") }}
            </p>

            <p :class="b('message-label')">
              ~ {{ displayedApplicationStoreRequest.commissions }}
              {{ displayedCurrencyFrom?.currency.name.toUpperCase() }}
            </p>
          </div>

          <div :class="b('message-item')">
            <p :class="b('message-label')">
              {{ $t("Вы получите") }}
            </p>

            <p :class="b('message-label')">
              ~ ????? {{ displayedCurrencyTo?.currency.name.toUpperCase() }}
            </p>
          </div>
        </div>
      </div>
    </div>

    <UIButton
      label="Далее"
      view="main"
      :disabled="isNextDisabled"
      @click="handleNext"
    />
  </div>
</template>
