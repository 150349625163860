<script lang="ts" src="./component.ts"></script>
<style lang="scss" src="./component.scss"></style>

<template>
  <div :class="b()">
    <router-link :class="b('logo', { disabled: !isVerified })" to="/">
      <span :class="b('logo-icon')" v-html="displayedIcons.logo"></span>
    </router-link>

    <div :class="b('links')">
      <router-link
        :class="b('link', { disabled: link.isDisabled })"
        v-for="link in displayedLinks"
        :key="link.id"
        :to="link.to"
      >
        <span :class="b('link-icon')" v-html="link.icon"></span>

        {{ $t(link.label) }}
      </router-link>
    </div>

    <button :class="b('burger')" @click="handleBurger">
      <span :class="b('burger-icon')" v-html="displayedIcons.burger"></span>
    </button>

    <transition name="opacity">
      <div :class="b('menu')" v-if="isMenu">
        <div :class="b('menu-wrapper')" v-click-outside="handleClose">
          <button :class="b('menu-close')" @click="handleClose">
            <span
              :class="b('menu-close-icon')"
              v-html="displayedIcons.close"
            ></span>
          </button>

          <div :class="b('menu-profile')">
            <span
              :class="b('menu-profile-icon')"
              v-html="displayedIcons.profile"
            ></span>

            <h4 :class="b('menu-profile-title')">
              {{ userRepo.userInfo.email }}
            </h4>
          </div>

          <div :class="b('menu-language')">
            <p :class="b('menu-language-label')">
              {{ $t("Язык") }}
            </p>

            <SharedLanguage view="default" :is-chevron="true" />
          </div>

          <div :class="b('menu-logout')">
            <p :class="b('menu-logout-label')">
              {{ $t("Выйти из системы") }}
            </p>

            <button :class="b('menu-logout-action')" @click="handleLogout">
              <span
                :class="b('menu-logout-icon')"
                v-html="displayedIcons.logout"
              ></span>
            </button>
          </div>
        </div>
      </div>
    </transition>
  </div>
</template>
