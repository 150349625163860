import { v4 } from "uuid";

import {
  UserFileRequest,
  UserFileResponse,
  UserInfoResponse,
  UserResponse,
} from "./repo";

import { FileFor, FileType, Language } from "@/shared/constants/enums";
import {
  extractFileName,
  truncateFileName,
  uniqueFileName,
} from "@/shared/utils/file-name-helpers";

export const UserResponseFactory = (
  payload: Partial<UserResponse> = {}
): UserResponse => {
  return {
    id: payload.id ?? 0,
    name: payload.name ?? "",
    email: payload.email ?? "",
    email_verified_at: payload.email_verified_at ?? "",
    two_factor_secret: payload.two_factor_secret ?? "",
    two_factor_recovery_codes: payload.two_factor_recovery_codes ?? "",
    created_at: payload.created_at ?? "",
    updated_at: payload.updated_at ?? "",
  };
};

export const UserInfoResponseFactory = (
  payload: Partial<UserInfoResponse> = {}
): UserInfoResponse => {
  return {
    ...UserResponseFactory(payload),
    user_info: {
      id: payload.user_info?.id ?? 0,
      user_id: payload.user_info?.user_id ?? 0,
      lang: payload.user_info?.lang ?? Language.ru,
      application_type: payload.user_info?.application_type ?? null,
      application_verified: payload.user_info?.application_verified
        ? Boolean(payload.user_info?.application_verified)
        : false,
      user_app_status: payload.user_info?.user_app_status ?? null,
      created_at: payload.user_info?.created_at ?? "",
      updated_at: payload.user_info?.updated_at ?? "",
    },
  };
};

export const UserFileRequestFactory = (
  payload: Partial<UserFileRequest> = {}
): UserFileRequest => {
  return {
    file: payload.file ?? "",
    name: payload.name ? `${uniqueFileName(payload.name)}` : "",
    fileFor: payload.fileFor ?? FileFor.kyc,
    type: payload.type ?? FileType.document,
    kyb_participant_id: payload.kyb_participant_id ?? 0,
  };
};

export const UserFileResponseFactory = (
  payload: Partial<UserFileResponse> = {}
): UserFileResponse => {
  return {
    uuid: payload.uuid ?? v4(),
    id: payload.id ?? null,
    user_id: payload.id ?? null,
    kyb_participant_id: payload.kyb_participant_id
      ? Number(payload.kyb_participant_id)
      : null,
    file_for: payload.file_for ?? "",
    type: payload.type ?? "",
    link: payload.link ?? "",
    name: payload.name ? truncateFileName(extractFileName(payload.name)) : "",
    generated_name: payload.generated_name ?? "",
    created_at: payload.created_at ?? "",
    updated_at: payload.updated_at ?? "",
    isUpload: payload.isUpload ?? false,
    isRemove: payload.isRemove ?? false,
  };
};
