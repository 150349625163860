import Cookies from "js-cookie";

import { CookieExpires, CookieName } from "@/shared/constants/enums";

export function setCookie(name: CookieName | string, value: string): void {
  Cookies.set(<string>name, value, { expires: CookieExpires.month });
}

export function getCookie(name: CookieName | string): string | undefined {
  const cookie = <string | undefined>Cookies.get(<string>name);

  return cookie;
}

export function removeCookie(name: CookieName | string): void {
  Cookies.remove(<string>name);
}
