import { defineComponent, PropType } from "vue";

import { COMPONENT_NAME } from "./attributes";

import { SharedUpload } from "@/components/shared";
import { AdminKycBack } from "../back";

import { AdminKycComponent } from "@/views/admin-kyc/model";
import { FileFor, KycDocumentsFileName } from "@/shared/constants/enums";
import { KycUploadFileResponseFactory } from "@/shared/repository/modules/kyc/factory";
import { KycDocuments } from "@/shared/constants/interfaces";
import { extractFileName } from "@/shared/utils/file-name-helpers";
import { UserFileResponse } from "@/shared/repository/modules/user/repo";

export default defineComponent({
  name: COMPONENT_NAME,
  components: {
    SharedUpload,
    AdminKycBack,
  },
  props: {
    id: {
      type: Number,
      required: true,
    },
    component: {
      type: <PropType<AdminKycComponent>>String,
      default: "",
    },
  },
  data() {
    return {
      kycRepository: this.$projectServices.kycRepository,
      userRepository: this.$projectServices.userRepository,
      documents: Object.keys(KycDocumentsFileName).reduce(
        (documents, fileName) => {
          documents[fileName] = KycUploadFileResponseFactory({
            name: KycDocumentsFileName[fileName as KycDocumentsFileName],
          });

          return documents;
        },
        {} as KycDocuments
      ) as KycDocuments,
      files: <UserFileResponse[]>[],
    };
  },
  async created(): Promise<void> {
    try {
      const [userFiles, files] = await Promise.allSettled([
        this.kycRepository.userFilesById(this.id),
        this.userRepository.filesById(this.id, FileFor.kyc),
      ]);

      if (userFiles.status === "fulfilled") {
        userFiles.value.forEach((userFile) => {
          this.documents[extractFileName(userFile.name)] =
            KycUploadFileResponseFactory(userFile);
        });
      }

      if (files.status === "fulfilled") {
        this.files = files.value;
      }
    } catch (e) {
      console.log(e);
    }
  },
  emits: {
    "update:component": null,
  },
  methods: {
    handleBack(): void {
      this.$emit("update:component", AdminKycComponent.personalInformation);
    },
  },
});
