/* eslint-disable @typescript-eslint/ban-ts-comment */
import { Module, Mutation, VuexModule } from "vuex-module-decorators";

import { AdminUsersResponse } from "@/shared/repository/modules/admin/users/repo";

enum UsersMutation {
  UPDATE_INDEX = "UPDATE_INDEX",
  UPDATE_USERS = "UPDATE_USERS",
}

interface State {
  index?: number;
  users?: AdminUsersResponse[];
}

@Module({
  namespaced: true,
  stateFactory: true,
  name: "users",
})
export default class UsersModuleState extends VuexModule<State> {
  index: number = 0;
  users: AdminUsersResponse[] = [];

  get userByIndex(): AdminUsersResponse | undefined {
    return this.users.find((user) => user.index === this.index);
  }

  @Mutation
  [UsersMutation.UPDATE_INDEX](index: number): void {
    this.index = index;
  }

  @Mutation
  [UsersMutation.UPDATE_USERS](users: AdminUsersResponse[]): void {
    this.users = users;
  }
}
