import { defineComponent } from "vue";

import { COMPONENT_NAME } from "./attributes";

import { UIInput } from "@/components/ui";

export default defineComponent({
  name: COMPONENT_NAME,
  components: {
    UIInput,
  },
  props: {
    correspondentBank: {
      type: String,
      default: "",
    },
    bic: {
      type: String,
      default: "",
    },
    inn: {
      type: String,
      default: "",
    },
    correspondentAccount: {
      type: String,
      default: "",
    },
    correspondentSwift: {
      type: String,
      default: "",
    },
    recipientBank: {
      type: String,
      default: "",
    },
    recipientCorrespondentAccount: {
      type: String,
      default: "",
    },
    recipientCorrespondentSwift: {
      type: String,
      default: "",
    },
  },
  emits: {
    "update:correspondentBank": null,
    "update:bic": null,
    "update:inn": null,
    "update:correspondentAccount": null,
    "update:correspondentSwift": null,
    "update:recipientBank": null,
    "update:recipientCorrespondentAccount": null,
    "update:recipientCorrespondentSwift": null,
  },
  methods: {
    handleChangeCorrespondentBank(correspondentBank: string): void {
      this.$emit("update:correspondentBank", correspondentBank);
    },

    handleChangeBic(bic: string): void {
      this.$emit("update:bic", bic);
    },

    handleChangeInn(inn: string): void {
      this.$emit("update:inn", inn);
    },

    handleChangeCorrespondentAccount(correspondentAccount: string): void {
      this.$emit("update:correspondentAccount", correspondentAccount);
    },

    handleChangeCorrespondentSwift(correspondentSwift: string): void {
      this.$emit("update:correspondentSwift", correspondentSwift);
    },

    handleChangeRecipientBank(recipientBank: string): void {
      this.$emit("update:recipientBank", recipientBank);
    },

    handleChangeRecipientCorrespondentAccount(
      recipientCorrespondentAccount: string
    ): void {
      this.$emit(
        "update:recipientCorrespondentAccount",
        recipientCorrespondentAccount
      );
    },

    handleChangeRecipientCorrespondentSwift(
      recipientCorrespondentSwift: string
    ): void {
      this.$emit(
        "update:recipientCorrespondentSwift",
        recipientCorrespondentSwift
      );
    },
  },
});
