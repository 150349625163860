<script lang="ts" src="./component.ts"></script>
<style lang="scss" src="./component.scss"></style>

<template>
  <SharedWindow
    :class="b()"
    title="Подтверждение реквизитов через email"
    :is-big="true"
    @close="handleClose"
  >
    <div :class="b('wrapper')">
      <p :class="b('label')">
        {{ $t("Код-подтверждение отправлен на email") }}
      </p>

      <SharedVerification
        :class="b('verification')"
        label="Введите 6 цифр из сообщения на email"
        v-model:code="code"
        :is-error="isError"
      />

      <UIButton
        label="Подтвердить"
        view="main"
        :disabled="isConfirmDisabled"
        @click="handleVerification"
      />
    </div>
  </SharedWindow>
</template>
