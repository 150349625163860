import { resolveDynamicComponent as _resolveDynamicComponent, openBlock as _openBlock, createBlock as _createBlock, resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, normalizeClass as _normalizeClass, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_UILink = _resolveComponent("UILink")!
  const _component_SharedAuthCard = _resolveComponent("SharedAuthCard")!

  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(_ctx.b())
  }, [
    _createVNode(_component_SharedAuthCard, {
      headerSvg: "forgot",
      headerTitle: "Восстановление пароля",
      footerTitle: "У вас еще нет учетной записи?",
      isTerms: true
    }, {
      default: _withCtx(() => [
        (_openBlock(), _createBlock(_resolveDynamicComponent(_ctx.displayedComponent), {
          loading: _ctx.loading,
          isVerification: _ctx.isVerification,
          email: _ctx.authForgotRequest.email,
          "onUpdate:email": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.authForgotRequest.email) = $event)),
          code: _ctx.authForgotRequest.code,
          "onUpdate:code": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.authForgotRequest.code) = $event)),
          password: _ctx.authForgotRequest.password,
          "onUpdate:password": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.authForgotRequest.password) = $event)),
          password_confirmation: 
            _ctx.authForgotRequest.password_confirmation
          ,
          "onUpdate:password_confirmation": _cache[3] || (_cache[3] = ($event: any) => ((
            _ctx.authForgotRequest.password_confirmation
          ) = $event)),
          onNext: _ctx.handleNext,
          onVerification: _ctx.handleVerification,
          onForgot: _ctx.handleForgot
        }, null, 40, ["loading", "isVerification", "email", "code", "password", "password_confirmation", "onNext", "onVerification", "onForgot"]))
      ]),
      footer: _withCtx(() => [
        _createVNode(_component_UILink, {
          label: "Создать аккаунт",
          view: "main-outline",
          to: "/registration"
        })
      ]),
      _: 1
    })
  ], 2))
}