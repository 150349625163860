import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createBlock as _createBlock } from "vue"

const _hoisted_1 = ["innerHTML"]
const _hoisted_2 = ["src"]
const _hoisted_3 = ["innerHTML"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_WalletCard = _resolveComponent("WalletCard")!

  return (_openBlock(), _createBlock(_component_WalletCard, {
    class: _normalizeClass(_ctx.b()),
    title: "Пополнение/Вывод",
    tab: "deposit-withdrawal"
  }, {
    default: _withCtx(() => [
      _createElementVNode("div", {
        class: _normalizeClass(_ctx.b('table'))
      }, [
        _createElementVNode("div", {
          class: _normalizeClass(_ctx.b('columns'))
        }, [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.displayedColumns, (column) => {
            return (_openBlock(), _createElementBlock("div", {
              class: _normalizeClass(_ctx.b('column')),
              key: column.id
            }, [
              _createElementVNode("p", {
                class: _normalizeClass(_ctx.b('column-label'))
              }, _toDisplayString(_ctx.$t(column.label)), 3)
            ], 2))
          }), 128))
        ], 2),
        _createElementVNode("div", {
          class: _normalizeClass(_ctx.b('rows'))
        }, [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.displayedRows, (row) => {
            return (_openBlock(), _createElementBlock("div", {
              class: _normalizeClass(_ctx.b('row')),
              key: row.id
            }, [
              _createElementVNode("div", {
                class: _normalizeClass(_ctx.b('row-date-time'))
              }, [
                _createElementVNode("p", {
                  class: _normalizeClass(_ctx.b('row-label'))
                }, _toDisplayString(row.created_at), 3)
              ], 2),
              _createElementVNode("div", {
                class: _normalizeClass(_ctx.b('row-type'))
              }, [
                _createElementVNode("span", {
                  class: _normalizeClass(_ctx.b('row-icon')),
                  innerHTML: _ctx.displayedIcons[row.app_type]
                }, null, 10, _hoisted_1),
                _createElementVNode("p", {
                  class: _normalizeClass(_ctx.b('row-label', { type: true }))
                }, _toDisplayString(row.app_type === "deposit" ? "Ввод" : "Вывод"), 3),
                _createElementVNode("p", {
                  class: _normalizeClass(_ctx.b('row-label', { amount: true }))
                }, _toDisplayString(row.amount), 3)
              ], 2),
              _createElementVNode("div", {
                class: _normalizeClass(_ctx.b('row-amount'))
              }, [
                _createElementVNode("p", {
                  class: _normalizeClass(_ctx.b('row-label'))
                }, _toDisplayString(row.amount), 3)
              ], 2),
              _createElementVNode("div", {
                class: _normalizeClass(_ctx.b('row-currency'))
              }, [
                _createElementVNode("div", {
                  class: _normalizeClass(_ctx.b('row-currency-group'))
                }, [
                  (row.currency.country && row.currency_type === 'fiat')
                    ? (_openBlock(), _createElementBlock("span", {
                        key: 0,
                        class: _normalizeClass([_ctx.b('row-icon'), 'fi', `fi-${row.currency.country}`])
                      }, null, 2))
                    : (row.currency_type === 'crypto' && row.currency.name)
                      ? (_openBlock(), _createElementBlock("img", {
                          key: 1,
                          class: _normalizeClass(_ctx.b('row-icon', { crypto: true })),
                          src: 
                  require(`@/assets/images/crypto/${row.currency.name.toLowerCase()}.svg`)
                ,
                          alt: ""
                        }, null, 10, _hoisted_2))
                      : _createCommentVNode("", true),
                  _createElementVNode("p", {
                    class: _normalizeClass(_ctx.b('row-label', { currency: true }))
                  }, _toDisplayString(row.currency.name), 3)
                ], 2),
                _createElementVNode("span", {
                  class: _normalizeClass(_ctx.b('row-icon')),
                  innerHTML: _ctx.displayedIcons[row.status]
                }, null, 10, _hoisted_3)
              ], 2)
            ], 2))
          }), 128))
        ], 2)
      ], 2)
    ]),
    _: 1
  }, 8, ["class"]))
}