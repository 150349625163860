<script lang="ts" src="./component.ts"></script>
<style lang="scss" src="./component.scss"></style>

<template>
  <SharedWindow
    :class="b()"
    :title="displayedTitle"
    :is-big="true"
    @close="handleClose"
  >
    <div :class="b('wrapper')">
      <h4 :class="b('title')">
        {{ displayedLabel }}
      </h4>

      <div :class="b('fields')">
        <div :class="b('field')">
          <p :class="b('field-label')">
            {{ $t("Номер расчетного счета") }}
          </p>

          <UIInput
            view="light"
            :disabled="true"
            :value="requisites.mex_requisite_banks[0]?.recipient_bank"
          />
        </div>

        <div :class="b('field')">
          <p :class="b('field-label')">
            {{ $t("Номер корреспондентского счета") }}
          </p>

          <UIInput
            view="light"
            :disabled="true"
            :value="requisites.mex_requisite_banks[0]?.correspondent_account"
          />
        </div>

        <div :class="b('field')">
          <p :class="b('field-label')">
            {{ $t("Банковский идентификационный код (БИК)") }}
          </p>

          <UIInput
            view="light"
            :disabled="true"
            :value="requisites.mex_requisite_banks[0]?.bic"
          />
        </div>

        <div :class="b('field')">
          <p :class="b('field-label')">
            {{ $t("Полное наименование банка") }}
          </p>

          <UIInput
            view="light"
            :disabled="true"
            :value="requisites.mex_requisite_banks[0]?.recipient_bank"
          />
        </div>

        <div :class="b('field')">
          <p :class="b('field-label')">
            {{ $t("Идентиф. номер налогоплательщика (ИНН)") }}
          </p>

          <UIInput
            view="light"
            :disabled="true"
            :value="requisites.mex_requisite_banks[0]?.inn"
          />
        </div>

        <div :class="b('field')">
          <p :class="b('field-label')">
            {{ $t("Код причины постановки на учет (КПП)") }}
          </p>

          <UIInput
            view="light"
            :disabled="true"
            :value="
              requisites.mex_requisite_banks[0]?.recipient_correspondent_account
            "
          />
        </div>
      </div>

      <p :class="b('label')">
        Нажимая кнопку, вы подтверждаете факт отправки средств на указанный
        адрес. Ваш баланс обновится после зачисления средств на ваш счет
      </p>

      <UIButton
        :class="b('confirm')"
        view="main"
        label="Подтвердить отправление"
        :disabled="isLoading"
        @click="handleConfirm"
      />
    </div>
  </SharedWindow>
</template>
