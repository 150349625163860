import { RoutesTo } from "@/shared/constants/enums";

export interface AdminToolbarLink {
  id: number;
  label: AdminToolbarLinkLabel;
  to: RoutesTo;
  icon: string;
  isDisabled: boolean;
}

export enum AdminToolbarLinkLabel {
  users = "Пользователи",
  kyc = "КУС",
  application = "Заявки",
  exchange = "Комиссии",
}
