import { SvgAttribute } from "@/shared/constants/constants";

export const COMPONENT_NAME = "admin-users-table-row";

export const SVG: SvgAttribute = {
  dots: `<svg width="16" height="16" viewBox="0 0 16 16" xmlns="http://www.w3.org/2000/svg">
    <circle cx="8" cy="2" r="1.5" />
    <circle cx="8" cy="8" r="1.5" />
    <circle cx="8" cy="14" r="1.5" />
  </svg>`,
  waiting: `<svg width="26" height="26" viewBox="0 0 26 26" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path opacity="0.967" fill-rule="evenodd" clip-rule="evenodd" d="M9.27344 2.19531C11.7422 2.1875 14.211 2.19531 16.6797 2.21875C18.1704 2.22424 19.5766 2.56798 20.8984 3.25C22.1579 4.05532 22.9782 5.18813 23.3594 6.64844C23.5193 7.26442 23.6287 7.8894 23.6875 8.52344C23.739 10.0071 23.7624 11.4915 23.7578 12.9766C23.7624 14.4617 23.739 15.946 23.6875 17.4297C23.6072 18.6573 23.2791 19.8136 22.7031 20.8984C21.8978 22.1579 20.765 22.9782 19.3047 23.3594C18.6886 23.5194 18.0636 23.6287 17.4297 23.6875C15.946 23.739 14.4617 23.7624 12.9766 23.7578C11.4915 23.7624 10.0071 23.739 8.52344 23.6875C7.29569 23.6071 6.13942 23.279 5.05469 22.7031C3.62141 21.7692 2.75422 20.4489 2.45313 18.7422C2.31939 18.061 2.24127 17.3736 2.21875 16.6797C2.1875 14.2109 2.1875 11.7422 2.21875 9.27344C2.24147 8.0432 2.47585 6.85572 2.92188 5.71094C3.66247 4.08029 4.90466 3.04123 6.64844 2.59375C7.51811 2.37917 8.39308 2.24636 9.27344 2.19531ZM9.22656 3.74219C11.7266 3.73437 14.2266 3.74219 16.7266 3.76563C17.9007 3.76913 19.0101 4.03475 20.0547 4.5625C20.9755 5.14085 21.5771 5.96116 21.8594 7.02344C21.9986 7.53072 22.0923 8.04634 22.1406 8.57031C22.2158 11.2727 22.2314 13.9759 22.1875 16.6797C22.1672 17.6454 21.9953 18.5829 21.6719 19.4922C21.1369 20.7143 20.2228 21.5034 18.9297 21.8594C18.4226 21.9986 17.907 22.0923 17.3828 22.1406C14.6648 22.216 11.946 22.2316 9.22656 22.1875C8.27697 22.162 7.35508 21.9901 6.46094 21.6719C5.23884 21.1369 4.44978 20.2228 4.09375 18.9297C3.89289 18.1932 3.78351 17.4432 3.76563 16.6797C3.73437 14.2109 3.73437 11.7422 3.76563 9.27344C3.76346 7.98512 4.07595 6.78198 4.70313 5.66406C5.28532 4.86827 6.05875 4.34483 7.02344 4.09375C7.75323 3.90436 8.48762 3.78717 9.22656 3.74219Z" fill="#A1A2AB"/>
    <path d="M11.9999 6.84965C11.9999 6.3804 12.3596 6 12.8034 6C13.2472 6 13.607 6.3804 13.607 6.84965V12.9475C13.607 13.4168 13.2472 13.7972 12.8034 13.7972C12.3596 13.7972 11.9999 13.4168 11.9999 12.9475V6.84965Z" fill="#A1A2AB"/>
    <path d="M17.598 15.4144C17.9823 15.649 18.114 16.1687 17.8921 16.575C17.6702 16.9814 17.1788 17.1207 16.7944 16.886L12.6958 13.9944C11.9995 13.5 12.0064 13.0607 11.9995 12.5C12.2214 12.0936 13.115 12.2881 13.4993 12.5227L17.598 15.4144Z" fill="#A1A2AB"/>
  </svg>`,
  pending: `<svg width="26" height="26" viewBox="0 0 26 26" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path opacity="0.969" fill-rule="evenodd" clip-rule="evenodd" d="M8.20096 2.41187C11.3833 2.4034 14.5656 2.41187 17.7478 2.43726C18.7125 2.459 19.6604 2.59441 20.5916 2.84351C22.4194 3.48646 23.6297 4.7306 24.2224 6.57593C24.3788 7.14163 24.4804 7.71714 24.5271 8.30249C24.6958 11.4171 24.6958 14.5317 24.5271 17.6462C24.4151 20.0652 23.2894 21.8172 21.1502 22.9021C20.052 23.2967 18.9179 23.4998 17.7478 23.5115C15.6997 23.5453 13.6515 23.5453 11.6033 23.5115C11.0655 23.2354 10.9216 22.8207 11.1717 22.2673C11.2878 22.1169 11.4317 22.0069 11.6033 21.9373C13.6516 21.9109 15.6998 21.877 17.7478 21.8357C21.0711 21.8808 22.7891 20.2474 22.9021 16.9353C22.9786 14.7523 22.9956 12.5687 22.9529 10.3845C22.9525 9.46533 22.8848 8.55127 22.7498 7.64233C21.5226 8.86957 20.2954 10.0967 19.0681 11.324C18.3026 12.0558 17.507 12.7499 16.6814 13.406C14.2101 15.268 11.7387 15.268 9.26737 13.406C8.44177 12.7499 7.64618 12.0558 6.88065 11.324C5.65342 10.0967 4.42622 8.86957 3.19901 7.64233C3.12736 8.09708 3.07657 8.55411 3.04666 9.01343C2.99529 10.4179 2.96143 11.8229 2.9451 13.2283C2.7428 13.7229 2.37887 13.9007 1.8533 13.7615C1.58632 13.6469 1.40859 13.4522 1.3201 13.1775C1.27661 11.5508 1.31046 9.92576 1.42166 8.30249C1.53374 5.88353 2.65939 4.13159 4.79862 3.04663C5.90631 2.65901 7.04041 2.44742 8.20096 2.41187ZM10.6892 4.03687C13.0424 4.02991 15.3953 4.0553 17.7478 4.11304C18.6132 4.13509 19.4596 4.2705 20.2869 4.51929C21.0151 4.82437 21.6075 5.29832 22.0642 5.94116C20.284 7.75527 18.4728 9.53262 16.6306 11.2732C15.9308 11.8857 15.1691 12.4104 14.3455 12.8474C13.2643 13.2724 12.2149 13.2047 11.1971 12.6443C10.5423 12.2265 9.916 11.7695 9.31815 11.2732C7.47596 9.53262 5.66479 7.75527 3.88455 5.94116C4.40484 5.19833 5.09884 4.69053 5.96659 4.41773C6.46724 4.29058 6.97505 4.20595 7.49002 4.16382C8.5642 4.11424 9.6306 4.07193 10.6892 4.03687Z" fill="#FCCA13"/>
    <path opacity="0.957" fill-rule="evenodd" clip-rule="evenodd" d="M0.786919 16.4783C2.03964 16.4698 3.29224 16.4783 4.54473 16.5037C4.99292 16.6802 5.18757 17.0103 5.12872 17.4939C5.05254 17.8409 4.84095 18.0525 4.49395 18.1287C3.29212 18.1625 2.09031 18.1625 0.888481 18.1287C0.255679 17.8898 0.0779443 17.4582 0.355278 16.8337C0.479244 16.6837 0.623124 16.5653 0.786919 16.4783Z" fill="#FCCA13"/>
    <path opacity="0.963" fill-rule="evenodd" clip-rule="evenodd" d="M0.786919 19.7283C3.12292 19.7198 5.45884 19.7283 7.79473 19.7537C8.18133 19.8825 8.38445 20.1533 8.40411 20.5662C8.36374 20.9866 8.1437 21.2574 7.74395 21.3787C5.45879 21.4125 3.17364 21.4125 0.888481 21.3787C0.255679 21.1398 0.0779443 20.7082 0.355278 20.0837C0.479244 19.9337 0.623124 19.8153 0.786919 19.7283Z" fill="#FCCA13"/>
  </svg>`,
  completed: `<svg width="26" height="26" viewBox="0 0 26 26" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path opacity="0.967" fill-rule="evenodd" clip-rule="evenodd" d="M9.27344 2.19531C11.7422 2.1875 14.211 2.19531 16.6797 2.21875C18.1704 2.22424 19.5766 2.56798 20.8984 3.25C22.1579 4.05532 22.9782 5.18813 23.3594 6.64844C23.5193 7.26442 23.6287 7.8894 23.6875 8.52344C23.739 10.0071 23.7624 11.4915 23.7578 12.9766C23.7624 14.4617 23.739 15.946 23.6875 17.4297C23.6072 18.6573 23.2791 19.8136 22.7031 20.8984C21.8978 22.1579 20.765 22.9782 19.3047 23.3594C18.6886 23.5194 18.0636 23.6287 17.4297 23.6875C15.946 23.739 14.4617 23.7624 12.9766 23.7578C11.4915 23.7624 10.0071 23.739 8.52344 23.6875C7.29569 23.6071 6.13942 23.279 5.05469 22.7031C3.62141 21.7692 2.75422 20.4489 2.45313 18.7422C2.31939 18.061 2.24127 17.3736 2.21875 16.6797C2.1875 14.2109 2.1875 11.7422 2.21875 9.27344C2.24147 8.0432 2.47585 6.85572 2.92188 5.71094C3.66247 4.08029 4.90466 3.04123 6.64844 2.59375C7.51811 2.37917 8.39308 2.24636 9.27344 2.19531ZM9.22656 3.74219C11.7266 3.73437 14.2266 3.74219 16.7266 3.76563C17.9007 3.76913 19.0101 4.03475 20.0547 4.5625C20.9755 5.14085 21.5771 5.96116 21.8594 7.02344C21.9986 7.53072 22.0923 8.04634 22.1406 8.57031C22.2158 11.2727 22.2314 13.9759 22.1875 16.6797C22.1672 17.6454 21.9953 18.5829 21.6719 19.4922C21.1369 20.7143 20.2228 21.5034 18.9297 21.8594C18.4226 21.9986 17.907 22.0923 17.3828 22.1406C14.6648 22.216 11.946 22.2316 9.22656 22.1875C8.27697 22.162 7.35508 21.9901 6.46094 21.6719C5.23884 21.1369 4.44978 20.2228 4.09375 18.9297C3.89289 18.1932 3.78351 17.4432 3.76563 16.6797C3.73437 14.2109 3.73437 11.7422 3.76563 9.27344C3.76346 7.98512 4.07595 6.78198 4.70313 5.66406C5.28532 4.86827 6.05875 4.34483 7.02344 4.09375C7.75323 3.90436 8.48762 3.78717 9.22656 3.74219Z" fill="#73D264"/>
    <path opacity="0.959" fill-rule="evenodd" clip-rule="evenodd" d="M17.0079 9.36719C17.8542 9.3775 18.1433 9.78377 17.8751 10.5859C15.8973 12.5794 13.9051 14.556 11.8985 16.5156C11.6021 16.6251 11.3208 16.5938 11.0548 16.4219C10.0782 15.4453 9.10164 14.4687 8.1251 13.4922C7.88946 13.1071 7.91289 12.7398 8.19541 12.3906C8.51402 12.1941 8.84214 12.1785 9.17979 12.3437C9.96883 13.1328 10.7579 13.9219 11.547 14.7109C13.2735 12.9844 15.0001 11.2578 16.7267 9.53125C16.8172 9.46286 16.911 9.4082 17.0079 9.36719Z" fill="#73D264"/>
  </svg>`,
  reject: `<svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path opacity="0.966" fill-rule="evenodd" clip-rule="evenodd" d="M7.32422 0.995606C9.09512 0.989094 10.8659 0.995606 12.6367 1.01514C13.3559 1.108 13.9939 1.38144 14.5508 1.83545C15.7422 3.02686 16.9336 4.21826 18.125 5.40967C18.5797 5.96775 18.8531 6.6058 18.9453 7.32373C18.9714 9.09455 18.9714 10.8654 18.9453 12.6362C18.8531 13.3542 18.5797 13.9922 18.125 14.5503C16.9336 15.7417 15.7422 16.9331 14.5508 18.1245C13.9927 18.5792 13.3547 18.8526 12.6367 18.9448C10.8659 18.9709 9.09504 18.9709 7.32422 18.9448C6.60629 18.8526 5.96824 18.5792 5.41016 18.1245C4.21875 16.9331 3.02735 15.7417 1.83594 14.5503C1.38124 13.9922 1.1078 13.3542 1.01563 12.6362C0.989583 10.8654 0.989583 9.09455 1.01563 7.32373C1.1077 6.60596 1.38114 5.96795 1.83594 5.40967C3.02735 4.21826 4.21875 3.02686 5.41016 1.83545C5.97563 1.38329 6.61367 1.10334 7.32422 0.995606ZM7.40235 2.28467C9.12114 2.27816 10.8399 2.28467 12.5586 2.3042C13.0001 2.38177 13.3907 2.56406 13.7305 2.85107C14.8698 3.99041 16.0091 5.12971 17.1484 6.26904C17.4127 6.60244 17.582 6.98002 17.6563 7.40186C17.6823 9.12061 17.6823 10.8394 17.6563 12.5581C17.5787 12.9996 17.3964 13.3902 17.1094 13.73C15.97 14.8693 14.8307 16.0086 13.6914 17.1479C13.358 17.4122 12.9804 17.5815 12.5586 17.6558C10.8398 17.6818 9.1211 17.6818 7.40235 17.6558C6.96086 17.5782 6.57024 17.3959 6.23047 17.1089C5.09114 15.9696 3.95184 14.8303 2.8125 13.6909C2.54822 13.3575 2.37895 12.9799 2.30469 12.5581C2.27865 10.8394 2.27865 9.12061 2.30469 7.40186C2.38226 6.96037 2.56455 6.56975 2.85156 6.22998C3.9909 5.09065 5.1302 3.95135 6.26953 2.81201C6.61051 2.55028 6.98813 2.3745 7.40235 2.28467Z" fill="#F86E6E"/>
    <path opacity="0.961" fill-rule="evenodd" clip-rule="evenodd" d="M6.89453 6.4253C7.08641 6.40823 7.26867 6.44077 7.44141 6.52296C8.2818 7.36987 9.12812 8.20972 9.98047 9.04249C10.8328 8.20972 11.6791 7.36987 12.5195 6.52296C13.0845 6.29753 13.4231 6.4798 13.5352 7.06983C13.5345 7.20198 13.502 7.32569 13.4375 7.44093C12.5906 8.28132 11.7507 9.12765 10.918 9.97999C11.7507 10.8323 12.5906 11.6787 13.4375 12.5191C13.6629 13.0841 13.4807 13.4226 12.8906 13.5347C12.7585 13.534 12.6348 13.5015 12.5195 13.437C11.6791 12.5901 10.8328 11.7503 9.98047 10.9175C9.12812 11.7503 8.2818 12.5901 7.44141 13.437C6.87641 13.6625 6.53785 13.4802 6.42578 12.8901C6.42645 12.758 6.45898 12.6343 6.52344 12.5191C7.37035 11.6787 8.2102 10.8323 9.04297 9.97999C8.2102 9.12765 7.37035 8.28132 6.52344 7.44093C6.32035 6.97554 6.44402 6.63698 6.89453 6.4253Z" fill="#F86E6E"/>
  </svg>`,
};
