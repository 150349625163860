<script lang="ts" src="./component.ts"></script>
<style lang="scss" src="./component.scss"></style>

<template>
  <SharedCard :class="b()">
    <div :class="b('header')">
      <SharedBack :class="b('header-back')" @click="handleBack" />

      <div :class="b('header-group')">
        <h3 :class="b('header-title')">
          {{ $t("Основная информация об организации") }}
        </h3>

        <p :class="b('header-label')">
          {{
            $t(
              "Ответьте на следующие вопросы, чтобы получить список необходимых документов"
            )
          }}
        </p>
      </div>
    </div>

    <div :class="b('form')">
      <SharedValidate
        field="country"
        :value="kybBasicInfo.country"
        :is-validate="isValidate"
        v-model:field-value="kybBasicInfoFields.country"
      >
        <div :class="b('form-group')">
          <h5 :class="b('form-title')">
            {{ $t("В какой стране зарегистрирована ваша организация?*") }}
          </h5>

          <SharedCountry
            placeholder="Выберите страну"
            :is-readonly="isReadonly"
            :is-error="isValidate && !kybBasicInfoFields.country"
            v-model:value="kybBasicInfo.country"
          />
        </div>
      </SharedValidate>

      <div :class="b('form-block')">
        <SharedValidate
          :class="b('form-group')"
          field="organisation"
          :value="kybBasicInfo.organisation"
          :is-validate="isValidate"
          v-model:field-value="kybBasicInfoFields.organisation"
        >
          <div>
            <h5 :class="b('form-title')">
              {{ $t("Как называется ваша организация?*") }}
            </h5>

            <UIInput
              view="light"
              :is-readonly="isReadonly"
              :is-error="isValidate && !kybBasicInfoFields.organisation"
              v-model="kybBasicInfo.organisation"
            />
          </div>
        </SharedValidate>

        <p :class="b('form-label')">
          {{
            $t(
              "Укажите полное юридическое название вашей организации в соответствии с Меморандумом и Уставом/Конституцией/Положениями."
            )
          }}
        </p>
      </div>

      <div :class="b('form-block')">
        <SharedValidate
          :class="b('form-group')"
          field="regional_number"
          :value="kybBasicInfo.regional_number"
          :is-validate="isValidate"
          v-model:field-value="kybBasicInfoFields.regional_number"
        >
          <div>
            <h5 :class="b('form-title')">
              {{ $t("Какой у вас регистрационный номер?*") }}
            </h5>

            <UIInput
              view="light"
              :is-readonly="isReadonly"
              :is-error="isValidate && !kybBasicInfoFields.regional_number"
              v-model="kybBasicInfo.regional_number"
            />
          </div>
        </SharedValidate>

        <p :class="b('form-label')">
          {{
            $t(
              "Введите регистрационный номер вашей компании. Мы используем этот номер для получения общедоступной информации о вашем бизнесе."
            )
          }}
        </p>
      </div>

      <SharedValidate
        field="registration_date"
        :value="kybBasicInfo.registration_date"
        :is-validate="isValidate"
        v-model:field-value="kybBasicInfoFields.registration_date"
      >
        <div :class="b('form-group')">
          <h5 :class="b('form-title')">
            {{ $t("Дата регистрации*") }}
          </h5>

          <SharedDate
            format="yyyy-MM-dd"
            :is-readonly="isReadonly"
            :is-error="isValidate && !kybBasicInfoFields.registration_date"
            v-model:date="kybBasicInfo.registration_date"
          />
        </div>
      </SharedValidate>

      <SharedValidate
        field="inn"
        :value="kybBasicInfo.inn"
        :is-validate="isValidate"
        v-model:field-value="kybBasicInfoFields.inn"
      >
        <div :class="b('form-group')">
          <h5 :class="b('form-title')">
            {{ $t("Номер Налогоплательщика*") }}
          </h5>

          <UIInput
            view="light"
            :is-readonly="isReadonly"
            :is-error="isValidate && !kybBasicInfoFields.inn"
            v-model="kybBasicInfo.inn"
          />
        </div>
      </SharedValidate>

      <SharedValidate
        field="licensed"
        :value="kybBasicInfo.licensed"
        :is-validate="isValidate"
        v-model:field-value="kybBasicInfoFields.licensed"
      >
        <div :class="b('form-group')">
          <h5 :class="b('form-title')">
            {{ $t("Ваша деятельность лицензируется?*") }}
          </h5>

          <div :class="b('form-radios')">
            <div :class="b('form-radio')">
              <SharedRadio
                view="light"
                :checked-value="true"
                :is-readonly="isReadonly"
                v-model:value="kybBasicInfo.licensed"
              />

              <p :class="b('form-radio-label')">
                {{ $t("Да") }}
              </p>
            </div>

            <div :class="b('form-radio')">
              <SharedRadio
                view="light"
                :checked-value="false"
                :is-readonly="isReadonly"
                v-model:value="kybBasicInfo.licensed"
              />

              <p :class="b('form-radio-label')">
                {{ $t("Нет") }}
              </p>
            </div>
          </div>
        </div>
      </SharedValidate>

      <SharedValidate
        field="telegram"
        :value="kybBasicInfo.telegram"
        :is-validate="isValidate"
        v-model:field-value="kybBasicInfoFields.telegram"
      >
        <div :class="b('form-group')">
          <h5 :class="b('form-title')">
            {{ $t("Телеграм для связи*") }}
          </h5>

          <UIInput
            view="light"
            :is-telegram="true"
            :is-readonly="isReadonly"
            :is-error="isValidate && !kybBasicInfoFields.telegram"
            v-model="kybBasicInfo.telegram"
          />
        </div>
      </SharedValidate>
    </div>

    <UIButton
      :class="b('continue')"
      label="Продолжить"
      view="main"
      :disabled="isContinueDisabled"
      @click="handleContinue"
    />
  </SharedCard>
</template>
