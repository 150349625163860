import { defineComponent } from "vue";

import { COMPONENT_NAME } from "./attributes";

export default defineComponent({
  name: COMPONENT_NAME,
  props: {
    description: {
      type: String,
      required: true,
    },
    value: {
      type: Number,
      required: true,
    },
  },
  emits: {
    "update:value": null,
  },
  methods: {
    handleInput(event: Event): void {
      const { value } = <HTMLInputElement>event.target;

      this.$emit("update:value", Number(value));
    },
  },
});
