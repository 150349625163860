import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, resolveDynamicComponent as _resolveDynamicComponent, createBlock as _createBlock, resolveComponent as _resolveComponent, withCtx as _withCtx } from "vue"

const _hoisted_1 = ["onClick"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_SharedWindow = _resolveComponent("SharedWindow")!

  return (_openBlock(), _createBlock(_component_SharedWindow, {
    class: _normalizeClass(_ctx.b()),
    title: _ctx.displayedTitle,
    onClose: _ctx.handleClose
  }, {
    default: _withCtx(() => [
      _createElementVNode("div", {
        class: _normalizeClass(_ctx.b('content'))
      }, [
        _createElementVNode("div", {
          class: _normalizeClass(_ctx.b('tabs'))
        }, [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.displayedTabs, (tab) => {
            return (_openBlock(), _createElementBlock("button", {
              class: _normalizeClass(_ctx.b('tab', { active: tab.isActive })),
              key: tab.value,
              onClick: ($event: any) => (_ctx.handleTab(tab.value))
            }, _toDisplayString(tab.label), 11, _hoisted_1))
          }), 128))
        ], 2),
        (_openBlock(), _createBlock(_resolveDynamicComponent(_ctx.displayedComponent)))
      ], 2)
    ]),
    _: 1
  }, 8, ["class", "title", "onClose"]))
}