<script lang="ts" src="./component.ts"></script>
<style lang="scss" src="./component.scss"></style>

<template>
  <div :class="b()">
    <AdminKycBack title="Загрузка документов" @back="handleBack" />

    <div :class="b('form')">
      <div :class="b('form-block')">
        <h4 :class="b('form-title')">
          {{ $t("Подтверждающие документы") }}
        </h4>

        <div :class="b('form-fields')">
          <SharedUpload
            :class="b('upload')"
            :document="documents['kyb-documents-registration-company']"
            :is-readonly="true"
            :is-padding="false"
            :is-download="true"
          >
            <template #label>
              {{ $t("Сертификат о") }} <br />
              {{ $t("регистрации") }} <br />
              {{ $t("компании") }}
            </template>
          </SharedUpload>

          <SharedUpload
            :class="b('upload')"
            :document="documents['kyb-documents-regulation']"
            :is-readonly="true"
            :is-download="true"
          >
            <template #label>
              <br />
              {{ $t("Устав") }}
            </template>
          </SharedUpload>

          <SharedUpload
            :class="b('upload')"
            :document="documents['kyb-documents-list-of-shareholders']"
            :is-readonly="true"
            :is-download="true"
          >
            <template #label>
              <br />
              {{ $t("Список акционеров") }}
            </template>
          </SharedUpload>

          <SharedUpload
            :class="b('upload')"
            :document="documents['kyb-documents-license']"
            :is-readonly="true"
            :is-download="true"
          >
            <template #label>
              <br />
              {{ $t("Лицензия") }}
            </template>
          </SharedUpload>

          <SharedUpload
            :class="b('upload')"
            :document="documents['kyb-documents-check-addresses']"
            :is-readonly="true"
            :is-padding="false"
            :is-download="true"
          >
            <template #label>
              {{ $t("Подтверждение") }} <br />
              {{ $t("адреса") }} <br />
              {{ $t("регистрации") }}
            </template>
          </SharedUpload>

          <SharedUpload
            :class="b('upload')"
            :document="documents['kyb-documents-sow']"
            :is-readonly="true"
            :is-padding="false"
            :is-download="true"
          >
            <template #label>
              {{ $t("Доказательства") }} <br />
              {{ $t("источника дохода") }} <br />
              SoW
            </template>
          </SharedUpload>

          <SharedUpload
            :class="b('upload')"
            :document="documents['kyb-documents-sof']"
            :is-readonly="true"
            :is-padding="false"
            :is-download="true"
          >
            <template #label>
              {{ $t("Доказательства") }} <br />
              {{ $t("источника средств") }} <br />
              SoF
            </template>
          </SharedUpload>

          <SharedUpload
            :class="b('upload')"
            :document="documents['kyb-documents-inn']"
            :is-readonly="true"
            :is-download="true"
          >
            <template #label>
              {{ $t("Свидетельство") }} <br />
              {{ $t("ИНН") }}
            </template>
          </SharedUpload>

          <SharedUpload
            :class="b('upload')"
            :document="documents['kyb-documents-record-sheet']"
            :is-readonly="true"
            :is-download="true"
          >
            <template #label>
              {{ $t("Лист Записи") }}
            </template>
          </SharedUpload>

          <SharedUpload
            :class="b('upload')"
            :document="documents['kyb-documents-create']"
            :is-readonly="true"
            :is-padding="false"
            :is-download="true"
          >
            <template #label>
              {{ $t("Решение о создании/") }} <br />
              {{ $t("Учредительный") }} <br />
              {{ $t("договор") }}
            </template>
          </SharedUpload>

          <SharedUpload
            :class="b('upload')"
            :document="documents['kyb-documents-update']"
            :is-readonly="true"
            :is-padding="false"
            :is-download="true"
          >
            <template #label>
              {{ $t("Изменения в") }} <br />
              {{ $t("учредительные") }} <br />
              {{ $t("документы") }}
            </template>
          </SharedUpload>

          <SharedUpload
            :class="b('upload')"
            :document="documents['kyb-documents-purpose']"
            :is-readonly="true"
            :is-padding="false"
            :is-download="true"
          >
            <template #label>
              {{ $t("О назначении") }} <br />
              {{ $t("единоличного") }} <br />
              {{ $t("исполнительного") }}... <br />
            </template>
          </SharedUpload>

          <SharedUpload
            :class="b('upload')"
            :document="documents['kyb-documents-finance']"
            :is-readonly="true"
            :is-padding="false"
            :is-download="true"
          >
            <template #label>
              {{ $t("Финансовая") }} <br />
              {{ $t("отчетность") }} <br />
              {{ $t("(за год/за квартал)") }}
            </template>
          </SharedUpload>

          <SharedUpload
            :class="b('upload')"
            :document="documents['kyb-documents-example']"
            :is-readonly="true"
            :is-download="true"
          >
            <template #label>
              {{ $t("Карточка образцов") }} <br />
              {{ $t("подписей и печатей") }}
            </template>
          </SharedUpload>
        </div>
      </div>

      <div :class="b('form-block')">
        <h4 :class="b('form-title')">
          {{ $t("Cоглашения") }}
        </h4>

        <div :class="b('form-fields')">
          <SharedUpload
            :class="b('upload')"
            :document="documents['kyb-documents-document']"
            :is-readonly="true"
            :is-download="true"
          >
            <template #label>
              {{ $t("Анкета") }} <br />
              {{ $t("юридического лица") }}
            </template>
          </SharedUpload>

          <SharedUpload
            :class="b('upload')"
            :document="documents['kyb-documents-beneficiary']"
            :is-readonly="true"
            :is-download="true"
          >
            <template #label>
              {{ $t("Анкета") }} <br />
              {{ $t("бенефициарного") }} <br />
              {{ $t("владельца") }}
            </template>
          </SharedUpload>

          <SharedUpload
            :class="b('upload')"
            :document="documents['kyb-documents-broker']"
            :is-readonly="true"
            :is-download="true"
          >
            <template #label>
              {{ $t("Брокерский") }} <br />
              {{ $t("договор") }}
            </template>
          </SharedUpload>

          <SharedUpload
            :class="b('upload')"
            :document="documents['kyb-documents-declaration']"
            :is-readonly="true"
            :is-download="true"
          >
            <template #label>
              {{ $t("Декларация") }} <br />
              {{ $t("о рисках") }}
            </template>
          </SharedUpload>
        </div>
      </div>

      <div v-if="files.length" :class="b('form-block')">
        <h4 :class="b('form-title')">
          {{ $t("Документы для верификации") }}
        </h4>

        <div :class="b('form-fields')">
          <SharedUpload
            v-for="file in files"
            :key="file.uuid"
            :class="b('form-upload')"
            :document="file"
            :is-readonly="true"
            :is-download="true"
          >
            <template #label>
              {{ file.name }}
            </template>
          </SharedUpload>
        </div>
      </div>
    </div>
  </div>
</template>
