import { AxiosInstance } from "axios";

import { AuthUrlGenerator } from "./url-generator";
import {
  AuthForgotRequest,
  AuthLoginRequest,
  AuthRegistrationRequest,
} from "./repo";

export interface AuthProjectRepository {
  csfr(): Promise<void>;
  login(payload: AuthLoginRequest): Promise<void>;
  registration(payload: AuthRegistrationRequest): Promise<void>;
  emailCode(payload: string): Promise<void>;
  codeVerification(payload: string): Promise<void>;
  forgot(payload: AuthForgotRequest): Promise<void>;
  logout(): Promise<void>;
}

export class AuthHttpRepo implements AuthProjectRepository {
  constructor(
    private readonly axios: AxiosInstance,
    private readonly urlGenerator: AuthUrlGenerator
  ) {}

  async csfr(): Promise<void> {
    await this.axios.get<void>(this.urlGenerator.csfr());
  }

  async login(payload: AuthLoginRequest): Promise<void> {
    await this.axios.post<void>(this.urlGenerator.login(), payload);
  }

  async registration(payload: AuthRegistrationRequest): Promise<void> {
    await this.axios.post<string>(this.urlGenerator.registration(), {
      name: `User ${Date.now()}`,
      email: payload.email,
      password: payload.password,
      password_confirmation: payload.password_confirmation,
    });
  }

  async emailCode(payload: string): Promise<void> {
    await this.axios.post<void>(this.urlGenerator.emailCode(), {
      email: payload,
    });
  }

  async codeVerification(payload: string): Promise<void> {
    await this.axios.post<void>(this.urlGenerator.codeVerification(), {
      code: payload,
    });
  }

  async forgot(payload: AuthForgotRequest): Promise<void> {
    await this.axios.post<void>(this.urlGenerator.forgot(), {
      email: payload.email,
      password: payload.password,
      password_confirmation: payload.password_confirmation,
    });
  }

  async logout(): Promise<void> {
    await this.axios.post<void>(this.urlGenerator.logout());
  }
}
