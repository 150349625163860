<script lang="ts" src="./component.ts"></script>
<style lang="scss" src="./component.scss"></style>

<template>
  <div :class="b()">
    <AdminKycBack title="Документы" @back="handleBack" />

    <div :class="b('form')">
      <div :class="b('form-group')">
        <div :class="b('form-block')">
          <h4 :class="b('form-title')">
            {{ $t("Паспорт или ВУ") }}
          </h4>

          <div :class="b('form-fields', { passport: true })">
            <SharedUpload
              :document="documents['kyc-personal-documents-passport-selfie']"
              :is-readonly="true"
              :is-download="true"
            >
              <template #label>
                {{ $t("Страница") }} <br />
                {{ $t("с фото") }}
              </template>
            </SharedUpload>

            <SharedUpload
              :document="
                documents['kyc-personal-documents-passport-organization']
              "
              :is-readonly="true"
              :is-download="true"
            >
              <template #label>
                {{ $t("Страница") }} <br />
                {{ $t("кем выдан") }}
              </template>
            </SharedUpload>

            <SharedUpload
              :document="documents['kyc-personal-documents-passport-address']"
              :is-readonly="true"
              :is-download="true"
            >
              <template #label>
                {{ $t("Страница") }} <br />
                {{ $t("с пропиской") }}
              </template>
            </SharedUpload>
          </div>
        </div>

        <div :class="b('form-block')">
          <h4 :class="b('form-title')">
            {{ $t("Селфи") }}
          </h4>

          <div :class="b('form-fields', { selfie: true })">
            <SharedUpload
              :document="documents['kyc-personal-documents-selfie']"
              :is-readonly="true"
              :isCenter="true"
              :is-label="false"
              :is-download="true"
            />
          </div>
        </div>
      </div>

      <div :class="b('form-block')">
        <h4 :class="b('form-title')">
          {{ $t("Соглашения") }}
        </h4>

        <div :class="b('form-fields', { default: true })">
          <SharedUpload
            :document="documents['kyc-personal-documents-questionnaire']"
            :is-readonly="true"
            :is-download="true"
          >
            <template #label>
              {{ $t("Анкета") }} <br />
              {{ $t("физического лица") }}
            </template>
          </SharedUpload>

          <SharedUpload
            :document="documents['kyc-personal-documents-personal-data']"
            :is-readonly="true"
            :is-download="true"
          >
            <template #label>
              {{ $t("Согласие на обработку") }} <br />
              {{ $t("персональных данных") }}
            </template>
          </SharedUpload>

          <SharedUpload
            :document="documents['kyc-personal-documents-declaration']"
            :is-readonly="true"
            :is-download="true"
          >
            <template #label>
              {{ $t("Декларация") }} <br />
              {{ $t("о рисках") }}
            </template>
          </SharedUpload>

          <SharedUpload
            :document="documents['kyc-personal-documents-broker']"
            :is-readonly="true"
            :is-download="true"
          >
            <template #label>
              {{ $t("Брокерский") }} <br />
              {{ $t("договор") }}
            </template>
          </SharedUpload>
        </div>
      </div>

      <div v-if="files.length" :class="b('form-block')">
        <h4 :class="b('form-title')">
          {{ $t("Документы для подтверждения верификации") }}
        </h4>

        <div :class="b('form-fields', { verification: true })">
          <SharedUpload
            v-for="file in files"
            :key="file.uuid"
            :class="b('form-upload')"
            :document="file"
            :is-readonly="true"
            :is-download="true"
          >
            <template #label>
              {{ file.name }}
            </template>
          </SharedUpload>
        </div>
      </div>
    </div>
  </div>
</template>
