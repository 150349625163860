import { Search } from "@/shared/constants/interfaces";
import { SearchType } from "@/shared/constants/enums";

export const SearchFactory = (payload: Partial<Search> = {}): Search => {
  return {
    id: payload.id ?? "",
    value: payload.value ?? "",
    type: payload.type ?? SearchType.all,
    email: payload.email ?? "",
  };
};
