import { defineComponent } from "vue";

import { COMPONENT_NAME } from "./attributes";

import { SharedWindow, SharedSelect } from "@/components/shared";
import { UIInput, UIButton } from "@/components/ui";

import { SharedSelectOption } from "@/components/shared/select/model";
import {
  AdminUserAppStatusFactory,
  AdminUserAppStatusRequestFactory,
} from "@/shared/repository/modules/admin/user-app/factory";
import { AdminUsersResponse } from "@/shared/repository/modules/admin/users/repo";
import { ApplicationStatus, Language } from "@/shared/constants/enums";
import { AdminUsersStatusOptions } from "@/data/admin/status/status";

enum UpdateLabel {
  "Изменить статус пользователя" = "Изменить статус пользователя",
  "Сохранено" = "Сохранено",
}

export default defineComponent({
  name: COMPONENT_NAME,
  components: {
    SharedWindow,
    SharedSelect,
    UIInput,
    UIButton,
  },
  props: {
    isStatus: {
      type: Boolean,
      default: false,
    },
  },
  emits: {
    "update:isStatus": null,
  },
  data() {
    return {
      isLoading: false,
      usersRepo: this.$projectServices.usersRepo,
      adminUserAppStatus: AdminUserAppStatusFactory(),
      adminUserAppRepository: this.$projectServices.adminUserAppRepository,
      adminUsersRepository: this.$projectServices.adminUsersRepository,
    };
  },
  async created() {
    try {
      this.isLoading = true;

      if (this.displayedUser && this.displayedUser.application_type) {
        const { id, application_type, lang, kyb_staus, kyc_status } =
          this.displayedUser;

        const { status } = await this.adminUserAppRepository.app(
          id,
          application_type
        );

        this.adminUserAppStatus = AdminUserAppStatusFactory({
          id,
          status: status || kyc_status || kyb_staus || "",
          messageRu: "",
          messageEn: "",
          type: application_type,
          lang,
        });
      }
    } catch (error) {
      console.log(error);
    } finally {
      this.isLoading = false;
    }
  },
  computed: {
    displayedUser(): AdminUsersResponse {
      return this.usersRepo.userByIndex!;
    },

    displayedEmail(): string {
      return this.displayedUser?.email || "";
    },

    displayedOptions(): SharedSelectOption[] {
      return AdminUsersStatusOptions(this.adminUserAppStatus.status);
    },

    displayedUpdateLabel(): UpdateLabel {
      return this.isSaved
        ? UpdateLabel.Сохранено
        : UpdateLabel["Изменить статус пользователя"];
    },

    isSaved(): boolean {
      const { status } = this.adminUserAppStatus;
      const { kyc_status, kyb_staus } = this.displayedUser;

      return status === kyc_status || status === kyb_staus;
    },

    isUpdateDisabled(): boolean {
      const { id, status, messageRu, messageEn, lang } =
        this.adminUserAppStatus;

      if (this.isSaved) return true;

      if (status === ApplicationStatus.reject) {
        return lang === Language.ru
          ? !id || !messageRu || this.isLoading
          : !id || !messageEn || this.isLoading;
      } else if (status === ApplicationStatus.completed) {
        return !id || this.isLoading;
      }

      return (
        !id ||
        this.isLoading ||
        status === ApplicationStatus.pending ||
        status === ApplicationStatus.waiting ||
        !status
      );
    },
  },
  methods: {
    handleClose(): void {
      this.$emit("update:isStatus", false);

      this.usersRepo.UPDATE_INDEX(0);
    },

    async handleChangeStatus(): Promise<void> {
      try {
        this.isLoading = true;

        await this.adminUserAppRepository.status(
          AdminUserAppStatusRequestFactory(this.adminUserAppStatus)
        );

        this.usersRepo.UPDATE_USERS(await this.adminUsersRepository.users());

        this.handleClose();
      } catch (error) {
        console.log(error);
      } finally {
        this.isLoading = false;
      }
    },
  },
});
