<script lang="ts" src="./component.ts"></script>
<style lang="scss" src="./component.scss"></style>

<template>
  <div :class="b()">
    <h4 :class="b('title')">
      {{ $t(title) }}
    </h4>

    <slot />
  </div>
</template>
