import { defineComponent, PropType } from "vue";

import { COMPONENT_NAME, SVG } from "./attributes";

import { SharedCryptoSelectOption } from "@/shared/constants/constants";

export default defineComponent({
  name: COMPONENT_NAME,
  props: {
    placeholder: {
      type: String,
      default: "",
    },
    options: {
      type: <PropType<SharedCryptoSelectOption[]>>Array,
      default: () => [],
    },
    value: {
      type: Number,
      default: 0,
    },
  },
  emits: {
    create: null,
    "update:value": null,
  },
  data() {
    return {
      isVisible: false,
    };
  },
  computed: {
    displayedChevronIcon(): string {
      return SVG.chevron;
    },

    displayedPlaceholder(): string {
      return (
        this.options.find((option) => option.id === this.value)?.label ??
        this.placeholder
      );
    },

    displayedCurrency(): SharedCryptoSelectOption | boolean {
      return this.options.find((option) => option.id === this.value) ?? false;
    },
  },
  methods: {
    handleToggle(): void {
      this.isVisible = !this.isVisible;
    },

    handleHide(): void {
      this.isVisible = false;
    },

    handleOption(option: SharedCryptoSelectOption): void {
      this.$emit("update:value", option.id);

      this.handleHide();
    },

    handleCreate(): void {
      this.$emit("create");
    },
  },
});
