import { toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createVNode as _createVNode } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_SharedRadio = _resolveComponent("SharedRadio")!

  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(_ctx.b())
  }, [
    _createElementVNode("p", {
      class: _normalizeClass(_ctx.b('label'))
    }, _toDisplayString(_ctx.$t("Реквизиты отправителя")), 3),
    _createElementVNode("div", {
      class: _normalizeClass(_ctx.b('radios'))
    }, [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.displayedRadios, (radio) => {
        return (_openBlock(), _createElementBlock("div", {
          class: _normalizeClass(_ctx.b('radio')),
          key: radio.id
        }, [
          _createVNode(_component_SharedRadio, {
            view: "light",
            value: _ctx.requisite,
            checkedValue: radio.value,
            onClick: ($event: any) => (_ctx.handleRequisite(radio.value))
          }, null, 8, ["value", "checkedValue", "onClick"]),
          _createElementVNode("p", {
            class: _normalizeClass(_ctx.b('radio-label'))
          }, _toDisplayString(_ctx.$t(radio.label)), 3)
        ], 2))
      }), 128))
    ], 2)
  ], 2))
}