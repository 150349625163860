import { defineComponent } from "vue";

import { COMPONENT_NAME } from "./attributes";

import { UIInput } from "@/components/ui";
import { SharedDate, SharedRadio } from "@/components/shared";
import { KycPersonalInfoResponseFactory } from "@/shared/repository/modules/kyc/factory";

export default defineComponent({
  name: COMPONENT_NAME,
  components: {
    UIInput,
    SharedDate,
    SharedRadio,
  },
  props: {
    id: {
      type: Number,
      required: true,
    },
  },
  data() {
    return {
      kycRepository: this.$projectServices.kycRepository,
      kycPersonalInfo: KycPersonalInfoResponseFactory(),
    };
  },
  async created(): Promise<void> {
    try {
      this.kycPersonalInfo = await this.kycRepository.personalInfoById(this.id);
    } catch (e) {
      console.log(e);
    }
  },
});
