import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_SharedBack = _resolveComponent("SharedBack")!
  const _component_SharedCheckbox = _resolveComponent("SharedCheckbox")!
  const _component_UIInput = _resolveComponent("UIInput")!
  const _component_SharedValidate = _resolveComponent("SharedValidate")!
  const _component_SharedCountry = _resolveComponent("SharedCountry")!
  const _component_SharedDate = _resolveComponent("SharedDate")!
  const _component_SharedRadio = _resolveComponent("SharedRadio")!
  const _component_SharedInputDouble = _resolveComponent("SharedInputDouble")!
  const _component_UIButton = _resolveComponent("UIButton")!
  const _component_SharedCard = _resolveComponent("SharedCard")!

  return (_openBlock(), _createBlock(_component_SharedCard, {
    class: _normalizeClass(_ctx.b())
  }, {
    default: _withCtx(() => [
      _createElementVNode("div", {
        class: _normalizeClass(_ctx.b('header'))
      }, [
        _createElementVNode("div", {
          class: _normalizeClass(_ctx.b('header-group'))
        }, [
          _createVNode(_component_SharedBack, { onClick: _ctx.handleBack }, null, 8, ["onClick"]),
          _createElementVNode("h3", {
            class: _normalizeClass(_ctx.b('header-title'))
          }, _toDisplayString(_ctx.$t("Участник")), 3)
        ], 2),
        _createElementVNode("div", {
          class: _normalizeClass(_ctx.b('header-checkboxes'))
        }, [
          _createElementVNode("div", {
            class: _normalizeClass(_ctx.b('header-checkbox'))
          }, [
            _createVNode(_component_SharedCheckbox, {
              view: "22",
              "is-readonly": _ctx.isReadonly,
              checked: _ctx.kybParticipant.is_beneficiary,
              "onUpdate:checked": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.kybParticipant.is_beneficiary) = $event))
            }, null, 8, ["is-readonly", "checked"]),
            _createElementVNode("p", {
              class: _normalizeClass(_ctx.b('header-checkbox-label'))
            }, _toDisplayString(_ctx.$t("Является Бенефициаром")), 3)
          ], 2),
          _createElementVNode("div", {
            class: _normalizeClass(_ctx.b('header-checkbox'))
          }, [
            _createVNode(_component_SharedCheckbox, {
              view: "22",
              "is-readonly": _ctx.isReadonly,
              checked: _ctx.kybParticipant.is_director,
              "onUpdate:checked": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.kybParticipant.is_director) = $event))
            }, null, 8, ["is-readonly", "checked"]),
            _createElementVNode("p", {
              class: _normalizeClass(_ctx.b('header-checkbox-label'))
            }, _toDisplayString(_ctx.$t("Является директором")), 3)
          ], 2)
        ], 2)
      ], 2),
      _createElementVNode("div", {
        class: _normalizeClass(_ctx.b('form'))
      }, [
        _createElementVNode("div", {
          class: _normalizeClass(_ctx.b('form-fields'))
        }, [
          _createVNode(_component_SharedValidate, {
            field: "sur_name",
            value: _ctx.kybParticipant.sur_name,
            "is-validate": _ctx.isValidate,
            "field-value": _ctx.kybParticipantFields.sur_name,
            "onUpdate:fieldValue": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.kybParticipantFields.sur_name) = $event))
          }, {
            default: _withCtx(() => [
              _createElementVNode("div", {
                class: _normalizeClass(_ctx.b('form-group'))
              }, [
                _createElementVNode("h5", {
                  class: _normalizeClass(_ctx.b('form-title'))
                }, _toDisplayString(_ctx.$t("Фамилия*")), 3),
                _createVNode(_component_UIInput, {
                  view: "light",
                  "is-readonly": _ctx.isReadonly,
                  "is-error": _ctx.isValidate && !_ctx.kybParticipantFields.sur_name,
                  modelValue: _ctx.kybParticipant.sur_name,
                  "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.kybParticipant.sur_name) = $event))
                }, null, 8, ["is-readonly", "is-error", "modelValue"])
              ], 2)
            ]),
            _: 1
          }, 8, ["value", "is-validate", "field-value"]),
          _createVNode(_component_SharedValidate, {
            field: "name",
            value: _ctx.kybParticipant.name,
            "is-validate": _ctx.isValidate,
            "field-value": _ctx.kybParticipantFields.name,
            "onUpdate:fieldValue": _cache[5] || (_cache[5] = ($event: any) => ((_ctx.kybParticipantFields.name) = $event))
          }, {
            default: _withCtx(() => [
              _createElementVNode("div", {
                class: _normalizeClass(_ctx.b('form-group'))
              }, [
                _createElementVNode("h5", {
                  class: _normalizeClass(_ctx.b('form-title'))
                }, _toDisplayString(_ctx.$t("Имя*")), 3),
                _createVNode(_component_UIInput, {
                  view: "light",
                  "is-readonly": _ctx.isReadonly,
                  "is-error": _ctx.isValidate && !_ctx.kybParticipantFields.name,
                  modelValue: _ctx.kybParticipant.name,
                  "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => ((_ctx.kybParticipant.name) = $event))
                }, null, 8, ["is-readonly", "is-error", "modelValue"])
              ], 2)
            ]),
            _: 1
          }, 8, ["value", "is-validate", "field-value"]),
          _createElementVNode("div", {
            class: _normalizeClass(_ctx.b('form-group'))
          }, [
            _createElementVNode("h5", {
              class: _normalizeClass(_ctx.b('form-title'))
            }, _toDisplayString(_ctx.$t("Отчество")), 3),
            _createVNode(_component_UIInput, {
              view: "light",
              "is-readonly": _ctx.isReadonly,
              modelValue: _ctx.kybParticipant.patronymic,
              "onUpdate:modelValue": _cache[6] || (_cache[6] = ($event: any) => ((_ctx.kybParticipant.patronymic) = $event))
            }, null, 8, ["is-readonly", "modelValue"])
          ], 2),
          _createVNode(_component_SharedValidate, {
            field: "citizenship",
            value: _ctx.kybParticipant.citizenship,
            "is-validate": _ctx.isValidate,
            "field-value": _ctx.kybParticipantFields.citizenship,
            "onUpdate:fieldValue": _cache[8] || (_cache[8] = ($event: any) => ((_ctx.kybParticipantFields.citizenship) = $event))
          }, {
            default: _withCtx(() => [
              _createElementVNode("div", {
                class: _normalizeClass(_ctx.b('form-group'))
              }, [
                _createElementVNode("h5", {
                  class: _normalizeClass(_ctx.b('form-title'))
                }, _toDisplayString(_ctx.$t("Гражданство*")), 3),
                _createVNode(_component_SharedCountry, {
                  placeholder: "Выберите страну",
                  "is-readonly": _ctx.isReadonly,
                  "is-error": _ctx.isValidate && !_ctx.kybParticipantFields.citizenship,
                  value: _ctx.kybParticipant.citizenship,
                  "onUpdate:value": _cache[7] || (_cache[7] = ($event: any) => ((_ctx.kybParticipant.citizenship) = $event))
                }, null, 8, ["is-readonly", "is-error", "value"])
              ], 2)
            ]),
            _: 1
          }, 8, ["value", "is-validate", "field-value"]),
          _createVNode(_component_SharedValidate, {
            field: "birth_date",
            value: _ctx.kybParticipant.birth_date,
            "is-validate": _ctx.isValidate,
            "field-value": _ctx.kybParticipantFields.birth_date,
            "onUpdate:fieldValue": _cache[10] || (_cache[10] = ($event: any) => ((_ctx.kybParticipantFields.birth_date) = $event))
          }, {
            default: _withCtx(() => [
              _createElementVNode("div", {
                class: _normalizeClass(_ctx.b('form-group'))
              }, [
                _createElementVNode("h5", {
                  class: _normalizeClass(_ctx.b('form-title'))
                }, _toDisplayString(_ctx.$t("Дата рождения*")), 3),
                _createVNode(_component_SharedDate, {
                  format: "yyyy-MM-dd",
                  "is-readonly": _ctx.isReadonly,
                  "is-error": _ctx.isValidate && !_ctx.kybParticipantFields.birth_date,
                  date: _ctx.kybParticipant.birth_date,
                  "onUpdate:date": _cache[9] || (_cache[9] = ($event: any) => ((_ctx.kybParticipant.birth_date) = $event))
                }, null, 8, ["is-readonly", "is-error", "date"])
              ], 2)
            ]),
            _: 1
          }, 8, ["value", "is-validate", "field-value"]),
          _createVNode(_component_SharedValidate, {
            field: "gender",
            value: _ctx.kybParticipant.gender,
            "is-validate": _ctx.isValidate,
            "field-value": _ctx.kybParticipantFields.gender,
            "onUpdate:fieldValue": _cache[13] || (_cache[13] = ($event: any) => ((_ctx.kybParticipantFields.gender) = $event))
          }, {
            default: _withCtx(() => [
              _createElementVNode("div", {
                class: _normalizeClass(_ctx.b('form-group'))
              }, [
                _createElementVNode("h5", {
                  class: _normalizeClass(_ctx.b('form-title'))
                }, _toDisplayString(_ctx.$t("Укажите пол*")), 3),
                _createElementVNode("div", {
                  class: _normalizeClass(_ctx.b('form-radios'))
                }, [
                  _createElementVNode("div", {
                    class: _normalizeClass(_ctx.b('form-radio'))
                  }, [
                    _createVNode(_component_SharedRadio, {
                      view: "light",
                      checkedValue: "male",
                      "is-readonly": _ctx.isReadonly,
                      "is-error": _ctx.isValidate && !_ctx.kybParticipantFields.gender,
                      value: _ctx.kybParticipant.gender,
                      "onUpdate:value": _cache[11] || (_cache[11] = ($event: any) => ((_ctx.kybParticipant.gender) = $event))
                    }, null, 8, ["is-readonly", "is-error", "value"]),
                    _createElementVNode("p", {
                      class: _normalizeClass(_ctx.b('form-radio-label'))
                    }, _toDisplayString(_ctx.$t("Мужчина")), 3)
                  ], 2),
                  _createElementVNode("div", {
                    class: _normalizeClass(_ctx.b('form-radio'))
                  }, [
                    _createVNode(_component_SharedRadio, {
                      view: "light",
                      checkedValue: "female",
                      "is-readonly": _ctx.isReadonly,
                      "is-error": _ctx.isValidate && !_ctx.kybParticipantFields.gender,
                      value: _ctx.kybParticipant.gender,
                      "onUpdate:value": _cache[12] || (_cache[12] = ($event: any) => ((_ctx.kybParticipant.gender) = $event))
                    }, null, 8, ["is-readonly", "is-error", "value"]),
                    _createElementVNode("p", {
                      class: _normalizeClass(_ctx.b('form-radio-label'))
                    }, _toDisplayString(_ctx.$t("Женщина")), 3)
                  ], 2)
                ], 2)
              ], 2)
            ]),
            _: 1
          }, 8, ["value", "is-validate", "field-value"])
        ], 2),
        _createElementVNode("div", {
          class: _normalizeClass(_ctx.b('form-address'))
        }, [
          _createElementVNode("h5", {
            class: _normalizeClass(_ctx.b('form-title'))
          }, _toDisplayString(_ctx.$t("Адрес проживания*")), 3),
          _createElementVNode("div", {
            class: _normalizeClass(_ctx.b('form-address-fields'))
          }, [
            _createVNode(_component_SharedValidate, {
              field: "address.country",
              value: _ctx.kybParticipant.address.country,
              "is-validate": _ctx.isValidate,
              "field-value": _ctx.kybParticipantFields['address.country'],
              "onUpdate:fieldValue": _cache[15] || (_cache[15] = ($event: any) => ((_ctx.kybParticipantFields['address.country']) = $event))
            }, {
              default: _withCtx(() => [
                _createVNode(_component_SharedCountry, {
                  class: _normalizeClass(_ctx.b('form-address-country')),
                  placeholder: "Cтрана",
                  "is-readonly": _ctx.isReadonly,
                  "is-error": _ctx.isValidate && !_ctx.kybParticipantFields['address.country'],
                  value: _ctx.kybParticipant.address.country,
                  "onUpdate:value": _cache[14] || (_cache[14] = ($event: any) => ((_ctx.kybParticipant.address.country) = $event))
                }, null, 8, ["class", "is-readonly", "is-error", "value"])
              ]),
              _: 1
            }, 8, ["value", "is-validate", "field-value"]),
            _createVNode(_component_SharedValidate, {
              field: "address.city",
              value: _ctx.kybParticipant.address.city,
              "is-validate": _ctx.isValidate,
              "field-value": _ctx.kybParticipantFields['address.city'],
              "onUpdate:fieldValue": _cache[17] || (_cache[17] = ($event: any) => ((_ctx.kybParticipantFields['address.city']) = $event))
            }, {
              default: _withCtx(() => [
                _createVNode(_component_UIInput, {
                  class: _normalizeClass(_ctx.b('form-address-city')),
                  placeholder: "Город",
                  view: "light",
                  "is-readonly": _ctx.isReadonly,
                  "is-error": _ctx.isValidate && !_ctx.kybParticipantFields['address.city'],
                  modelValue: _ctx.kybParticipant.address.city,
                  "onUpdate:modelValue": _cache[16] || (_cache[16] = ($event: any) => ((_ctx.kybParticipant.address.city) = $event))
                }, null, 8, ["class", "is-readonly", "is-error", "modelValue"])
              ]),
              _: 1
            }, 8, ["value", "is-validate", "field-value"]),
            _createVNode(_component_SharedValidate, {
              field: "address.state",
              value: _ctx.kybParticipant.address.state,
              "is-validate": _ctx.isValidate,
              "field-value": _ctx.kybParticipantFields['address.state'],
              "onUpdate:fieldValue": _cache[19] || (_cache[19] = ($event: any) => ((_ctx.kybParticipantFields['address.state']) = $event))
            }, {
              default: _withCtx(() => [
                _createVNode(_component_UIInput, {
                  class: _normalizeClass(_ctx.b('form-address-state')),
                  placeholder: "Штат / Область",
                  view: "light",
                  "is-readonly": _ctx.isReadonly,
                  "is-error": _ctx.isValidate && !_ctx.kybParticipantFields['address.state'],
                  modelValue: _ctx.kybParticipant.address.state,
                  "onUpdate:modelValue": _cache[18] || (_cache[18] = ($event: any) => ((_ctx.kybParticipant.address.state) = $event))
                }, null, 8, ["class", "is-readonly", "is-error", "modelValue"])
              ]),
              _: 1
            }, 8, ["value", "is-validate", "field-value"]),
            _createVNode(_component_SharedValidate, {
              field: "address.street",
              value: _ctx.kybParticipant.address.street,
              "is-validate": _ctx.isValidate,
              "field-value": _ctx.kybParticipantFields['address.street'],
              "onUpdate:fieldValue": _cache[21] || (_cache[21] = ($event: any) => ((_ctx.kybParticipantFields['address.street']) = $event))
            }, {
              default: _withCtx(() => [
                _createVNode(_component_UIInput, {
                  class: _normalizeClass(_ctx.b('form-address-street')),
                  placeholder: "Улица",
                  view: "light",
                  "is-readonly": _ctx.isReadonly,
                  "is-error": _ctx.isValidate && !_ctx.kybParticipantFields['address.street'],
                  modelValue: _ctx.kybParticipant.address.street,
                  "onUpdate:modelValue": _cache[20] || (_cache[20] = ($event: any) => ((_ctx.kybParticipant.address.street) = $event))
                }, null, 8, ["class", "is-readonly", "is-error", "modelValue"])
              ]),
              _: 1
            }, 8, ["value", "is-validate", "field-value"]),
            _createVNode(_component_SharedValidate, {
              field: "address.office",
              value: _ctx.kybParticipant.address.office,
              "is-validate": _ctx.isValidate,
              "field-value": _ctx.kybParticipantFields['address.office'],
              "onUpdate:fieldValue": _cache[23] || (_cache[23] = ($event: any) => ((_ctx.kybParticipantFields['address.office']) = $event))
            }, {
              default: _withCtx(() => [
                _createVNode(_component_UIInput, {
                  class: _normalizeClass(_ctx.b('form-address-office')),
                  placeholder: "Номер Офиса",
                  view: "light",
                  "is-readonly": _ctx.isReadonly,
                  "is-error": _ctx.isValidate && !_ctx.kybParticipantFields['address.office'],
                  modelValue: _ctx.kybParticipant.address.office,
                  "onUpdate:modelValue": _cache[22] || (_cache[22] = ($event: any) => ((_ctx.kybParticipant.address.office) = $event))
                }, null, 8, ["class", "is-readonly", "is-error", "modelValue"])
              ]),
              _: 1
            }, 8, ["value", "is-validate", "field-value"]),
            _createVNode(_component_SharedValidate, {
              field: "address.zip",
              value: _ctx.kybParticipant.address.zip,
              "is-validate": _ctx.isValidate,
              "field-value": _ctx.kybParticipantFields['address.zip'],
              "onUpdate:fieldValue": _cache[25] || (_cache[25] = ($event: any) => ((_ctx.kybParticipantFields['address.zip']) = $event))
            }, {
              default: _withCtx(() => [
                _createVNode(_component_UIInput, {
                  class: _normalizeClass(_ctx.b('form-address-index')),
                  placeholder: "Индекс",
                  view: "light",
                  "is-readonly": _ctx.isReadonly,
                  "is-error": _ctx.isValidate && !_ctx.kybParticipantFields['address.zip'],
                  modelValue: _ctx.kybParticipant.address.zip,
                  "onUpdate:modelValue": _cache[24] || (_cache[24] = ($event: any) => ((_ctx.kybParticipant.address.zip) = $event))
                }, null, 8, ["class", "is-readonly", "is-error", "modelValue"])
              ]),
              _: 1
            }, 8, ["value", "is-validate", "field-value"])
          ], 2)
        ], 2),
        _createElementVNode("div", {
          class: _normalizeClass(_ctx.b('form-fields'))
        }, [
          _createElementVNode("div", {
            class: _normalizeClass(_ctx.b('form-group'))
          }, [
            _createElementVNode("h5", {
              class: _normalizeClass(_ctx.b('form-title'))
            }, _toDisplayString(_ctx.$t("Какой долей он владеет в обществе?")), 3),
            _createVNode(_component_SharedInputDouble, {
              view: "light",
              "is-readonly": _ctx.isReadonly,
              modelValue: _ctx.kybParticipant.share_in_org,
              "onUpdate:modelValue": _cache[26] || (_cache[26] = ($event: any) => ((_ctx.kybParticipant.share_in_org) = $event))
            }, null, 8, ["is-readonly", "modelValue"])
          ], 2)
        ], 2)
      ], 2),
      _createVNode(_component_UIButton, {
        class: _normalizeClass(_ctx.b('continue')),
        label: "Продолжить",
        view: "main",
        disabled: _ctx.isContinueDisabled,
        onClick: _ctx.handleContinue
      }, null, 8, ["class", "disabled", "onClick"])
    ]),
    _: 1
  }, 8, ["class"]))
}