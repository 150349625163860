<script lang="ts" src="./component.ts"></script>
<style lang="scss" src="./component.scss"></style>

<template>
  <div :class="b()">
    <AdminConfigCommissionFind
      v-model:type="commissionExchangeFind.type"
      v-model:userId="commissionExchangeFind.user_id"
    />

    <AdminConfigCommissionExchange
      :type="commissionExchangeFind.type"
      v-model:userId="commissionExchangeFind.user_id"
    />

    <AdminConfigCommissionDepositWithdrawal
      :type="commissionExchangeFind.type"
      v-model:userId="commissionExchangeFind.user_id"
    />
  </div>
</template>
