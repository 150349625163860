import { AxiosInstance } from "axios";

import { ApplicationUrlGenerator } from "./url-generator";
import {
  ApplicationCommissionRequest,
  ApplicationCommissionResponse,
  ApplicationFilterRequest,
  ApplicationShowResponse,
  ApplicationStoreRequest,
} from "./repo";
import {
  ApplicationCommissionResponseFactory,
  ApplicationShowResponseFactory,
} from "./factory";

import UserModuleState from "@/store/modules/user";
import { ResponseData } from "@/shared/constants/interfaces";

export interface ApplicationProjectRepository {
  store(payload: ApplicationStoreRequest): Promise<void>;
  show(id: number): Promise<ApplicationShowResponse>;
  from(currencyId: number): Promise<void>;
  to(currencyId: number): Promise<void>;
  commission(
    payload: ApplicationCommissionRequest
  ): Promise<ApplicationCommissionResponse>;
  verification(code: string): Promise<void>;
  history(payload: ApplicationFilterRequest): Promise<void>;
}

export class ApplicationHttpRepo implements ApplicationProjectRepository {
  constructor(
    private readonly axios: AxiosInstance,
    private readonly urlGenerator: ApplicationUrlGenerator,
    private readonly userRepo: UserModuleState
  ) {}

  get userId(): number {
    return this.userRepo.userInfo.id;
  }

  async store(payload: ApplicationStoreRequest): Promise<void> {
    await this.axios.post<void>(this.urlGenerator.store(this.userId), payload);
  }

  async show(id: number): Promise<ApplicationShowResponse> {
    const { data } = await this.axios.get<
      ResponseData<ApplicationShowResponse>
    >(this.urlGenerator.show(id));

    return ApplicationShowResponseFactory(data.data);
  }

  async from(currencyId: number): Promise<void> {
    await this.axios.get<void>(this.urlGenerator.from(currencyId, this.userId));
  }

  async to(currencyId: number): Promise<void> {
    await this.axios.get<void>(this.urlGenerator.to(currencyId, this.userId));
  }

  async commission(
    payload: ApplicationCommissionRequest
  ): Promise<ApplicationCommissionResponse> {
    const { data } = await this.axios.post<
      ResponseData<ApplicationCommissionResponse>
    >(this.urlGenerator.commission(this.userId), payload);

    return ApplicationCommissionResponseFactory(data.data);
  }

  async verification(code: string): Promise<void> {
    await this.axios.post<void>(this.urlGenerator.verification(), {
      code,
    });
  }

  async history(payload: ApplicationFilterRequest): Promise<void> {
    await this.axios.post<void>(
      this.urlGenerator.history(),
      {
        currency_id: payload.currency_id,
      },
      {
        params: {
          "filter[date_from]": payload.date_from
            ? payload.date_from
            : undefined,
          "filter[date_to]": payload.date_to ? payload.date_to : undefined,
          "filter[amount_from]": payload.amount_from
            ? payload.amount_from
            : undefined,
          "filter[amount_to]": payload.amount_to
            ? payload.amount_to
            : undefined,
          "filter[status]": payload.status ? payload.status : undefined,
          page: payload.page,
          per_page: payload.per_page,
        },
      }
    );
  }
}
