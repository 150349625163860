import { AdminCurrencyModalOption, AdminCurrencyType } from "./enums";
import {
  AdminCurrencyListResponse,
  AdminCurrencyModal,
  AdminCurrencyShowResponse,
  AdminCurrencyStoreRequest,
  AdminCurrencyUpdateRequest,
} from "./repo";

export const AdminCurrencyModalFactory = (
  payload: Partial<AdminCurrencyModal> = {}
): AdminCurrencyModal => {
  return {
    id: payload.id ?? 0,
    type: payload.type ?? AdminCurrencyType.fiat,
    option: payload.option ?? AdminCurrencyModalOption.create,
    isShow: payload.isShow ?? false,
  };
};

export const AdminCurrencyStoreRequestFactory = (
  payload: Partial<AdminCurrencyStoreRequest> = {}
): AdminCurrencyStoreRequest => {
  return {
    country: payload.country ?? "",
    name: payload.name ?? "",
    type: payload.type ?? AdminCurrencyType.fiat,
    file: payload.file ? payload.file : payload.country! || payload.name!,
  };
};

export const AdminCurrencyUpdateRequestFactory = (
  payload: Partial<AdminCurrencyUpdateRequest> = {}
): AdminCurrencyUpdateRequest => {
  return {
    id: payload.id ?? 0,
    country: payload.country ?? "",
    name: payload.name ?? "",
    type: payload.type ?? AdminCurrencyType.fiat,
    file: payload.file ?? "",
  };
};

export const AdminCurrencyShowResponseFactory = (
  payload: Partial<AdminCurrencyShowResponse> = {}
): AdminCurrencyShowResponse => {
  return {
    id: payload.id ?? 0,
    name: payload.name ?? "",
    type: payload.type ?? AdminCurrencyType.fiat,
    image_path: payload.image_path ?? "",
    country: payload.country ?? "",
    frozen: Boolean(payload.frozen) ?? false,
    created_at: payload.created_at ?? "",
    updated_at: payload.updated_at ?? "",
  };
};

export const AdminCurrencyListResponseFactory = (
  payload: Partial<AdminCurrencyListResponse> = {}
): AdminCurrencyListResponse => {
  return {
    fiat: payload.fiat
      ? payload.fiat.map((fiat) => AdminCurrencyShowResponseFactory(fiat))
      : [],
    crypto: payload.crypto
      ? payload.crypto.map((crypto) => AdminCurrencyShowResponseFactory(crypto))
      : [],
  };
};
