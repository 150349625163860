import { defineComponent, PropType } from "vue";

import { COMPONENT_NAME } from "./attributes";

import { SharedWindow, SharedVerification } from "@/components/shared";
import { UIButton } from "@/components/ui";

import { CODE_MAX_LENGTH } from "@/shared/constants/constants";
import { RequisitesType } from "@/shared/repository/modules/requisites/enums";
import { RequisitesVerificationRequestFactory } from "@/shared/repository/modules/requisites/factory";

export default defineComponent({
  name: COMPONENT_NAME,
  components: {
    SharedWindow,
    SharedVerification,
    UIButton,
  },
  props: {
    type: {
      type: <PropType<RequisitesType>>String,
      required: true,
    },
  },
  emits: {
    close: null,
  },
  data() {
    return {
      isError: false,
      isLoading: false,
      code: "",
      requisitesRepository: this.$projectServices.requisitesRepository,
    };
  },
  computed: {
    isConfirmDisabled(): boolean {
      return this.isLoading || this.code.length !== CODE_MAX_LENGTH;
    },
  },
  methods: {
    handleClose(): void {
      this.$emit("close");
    },

    async handleVerification(): Promise<void> {
      try {
        this.isError = false;
        this.isLoading = true;

        await this.requisitesRepository.verification(
          RequisitesVerificationRequestFactory({
            code: this.code,
            type: this.type,
          })
        );

        this.$emit("close");
      } catch (error) {
        this.isError = true;
      } finally {
        this.isLoading = false;
      }
    },
  },
});
