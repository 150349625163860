export enum ParserDocument {
  "Анкета физического лица" = "Анкета физического лица",
  "Согласие на обработку персональных данных" = "Согласие на обработку персональных данных",
  "Декларация о рисках" = "Декларация о рисках",
  "Брокерский договор" = "Брокерский договор",
  "Участник: aнкета физического лица" = "Участник: aнкета физического лица",
  "Участник: согласие на обработку персональных данных" = "Участник: согласие на обработку персональных данных",
  "Юр лицо: Анкета юридического лица" = "Юр лицо: Анкета юридического лица",
  "Юр лицо: Анкета бенефициарного владельца" = "Юр лицо: Анкета бенефициарного владельца",
  "Юр лицо: Брокерский договор" = "Юр лицо: Брокерский договор",
  "Юр лицо: Декларация о рисках" = "Юр лицо: Декларация о рисках",
}

export enum ParserFieldName {
  "_Фамилия_" = "_Фамилия_",
  "_Имя_" = "_Имя_",
  "_Отчество_" = "_Отчество_",
  "_ДатаРождения_" = "_ДатаРождения_",
  "_Гражданство_" = "_Гражданство_",
  "_Серия/Номер_" = "_Серия/Номер_",
  "_ДатаВыдачиПаспорт_" = "_ДатаВыдачиПаспорт_",
  "_НаименованиеОрганаВыдавшего_" = "_НаименованиеОрганаВыдавшего_",
  "_АдресФизЛицо_" = "_АдресФизЛицо_",
  "_ТекущаяДАТА_" = "_ТекущаяДАТА_",
  "_Email_" = "_Email_",
  "_НомерДоговор_" = "_НомерДоговор_",
  "_Наименование_" = "_Наименование_",
  "_ОПФ_" = "_ОПФ_",
  "_ИНН_" = "_ИНН_",
  "_ДатаРег_" = "_ДатаРег_",
  "_РегНомер_" = "_РегНомер_",
}

export enum ParserFieldKey {
  "_Фамилия_" = "sur_name",
  "_Имя_" = "name",
  "_Отчество_" = "patronymic",
  "_ДатаРождения_" = "birth_date",
  "_Гражданство_" = "citizenship",
  "_Серия/Номер_" = "passport",
  "_ДатаВыдачиПаспорт_" = "passport_date",
  "_НаименованиеОрганаВыдавшего_" = "passport_issued_org",
  "_АдресФизЛицо_" = "address",
  "_ТекущаяДАТА_" = "date",
  "_Email_" = "email",
  "_НомерДоговор_" = "numberAgreement",
  "_Наименование_" = "companyName",
  "_ОПФ_" = "opf",
  "_ИНН_" = "inn",
  "_ДатаРег_" = "dateRegistration",
  "_РегНомер_" = "numberRegistration",
}
