import { toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createVNode as _createVNode, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_SharedSelect = _resolveComponent("SharedSelect")!
  const _component_UIInput = _resolveComponent("UIInput")!
  const _component_UIButton = _resolveComponent("UIButton")!

  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(_ctx.b())
  }, [
    _createElementVNode("h1", {
      class: _normalizeClass(_ctx.b('title'))
    }, _toDisplayString(_ctx.$t(_ctx.title)), 3),
    _createElementVNode("div", {
      class: _normalizeClass(_ctx.b('form'))
    }, [
      (_ctx.isType)
        ? (_openBlock(), _createBlock(_component_SharedSelect, {
            key: 0,
            class: _normalizeClass(_ctx.b('type')),
            placeholder: "Выберите тип",
            view: "light",
            options: _ctx.displayedOptions,
            value: _ctx.type,
            "onUpdate:value": _ctx.handleChangeType
          }, null, 8, ["class", "options", "value", "onUpdate:value"]))
        : _createCommentVNode("", true),
      (_ctx.isEmail)
        ? (_openBlock(), _createBlock(_component_UIInput, {
            key: 1,
            class: _normalizeClass(_ctx.b('email')),
            placeholder: "Email",
            view: "light",
            modelValue: _ctx.email,
            "onUpdate:modelValue": _ctx.handleChangeEmail
          }, null, 8, ["class", "modelValue", "onUpdate:modelValue"]))
        : _createCommentVNode("", true),
      _createVNode(_component_UIInput, {
        class: _normalizeClass(_ctx.b('id')),
        placeholder: "ID",
        view: "light",
        type: "number",
        modelValue: _ctx.id,
        "is-number": true,
        "onUpdate:modelValue": _ctx.handleChangeId
      }, null, 8, ["class", "modelValue", "onUpdate:modelValue"]),
      _createVNode(_component_UIButton, {
        class: _normalizeClass(_ctx.b('search')),
        label: "Поиск",
        view: "main",
        disabled: _ctx.isSearchDisabled,
        onClick: _ctx.handleSearch
      }, null, 8, ["class", "disabled", "onClick"])
    ], 2)
  ], 2))
}