import {
  RequisitesBankFields,
  RequisitesBankRequest,
  RequisitesBankResponse,
  RequisitesBankUpdateRequest,
  RequisitesCryptoFields,
  RequisitesCryptoRequest,
  RequisitesCryptoResponse,
  RequisitesCryptoUpdateRequest,
  RequisitesVerificationRequest,
} from "./repo";
import { RequisitesType } from "./enums";

export const RequisitesBankRequestFactory = (
  payload: Partial<RequisitesBankRequest> = {}
): RequisitesBankRequest => {
  return {
    name: payload.name ?? "",
    account_number: payload.account_number ?? "",
    correspondent_account: payload.correspondent_account ?? "",
    bank_identification_code: payload.bank_identification_code ?? "",
    bank_name: payload.bank_name ?? "",
    inn: payload.inn ?? "",
    registration_reason_code: payload.registration_reason_code ?? "",
  };
};

export const RequisitesBankUpdateRequestFactory = (
  payload: Partial<RequisitesBankRequest> = {}
): RequisitesBankUpdateRequest => {
  return {
    name: payload.name ?? "",
    account_number: payload.account_number ?? "",
    correspondent_account: payload.correspondent_account ?? "",
    bank_identification_code: payload.bank_identification_code ?? "",
    bank_name: payload.bank_name ?? "",
    inn: payload.inn ?? "",
    registration_reason_code: payload.registration_reason_code ?? "",
  };
};

export const RequisitesBankResponseFactory = (
  payload: Partial<RequisitesBankResponse> = {}
): RequisitesBankResponse => {
  return {
    id: payload.id ?? 0,
    user_id: payload.user_id ?? 0,
    name: payload.name ?? "",
    account_number: payload.account_number ?? "",
    correspondent_account: payload.correspondent_account ?? "",
    bank_identification_code: payload.bank_identification_code ?? "",
    bank_name: payload.bank_name ?? "",
    inn: payload.inn ?? "",
    registration_reason_code: payload.registration_reason_code ?? "",
    creatd_at: payload.creatd_at ?? "",
    updated_at: payload.updated_at ?? "",
  };
};

export const RequisitesBankFieldsFactory = (): RequisitesBankFields => {
  return {
    name: false,
    account_number: false,
    correspondent_account: false,
    bank_identification_code: false,
    bank_name: false,
    inn: false,
    registration_reason_code: false,
  };
};

export const RequisitesCryptoRequestFactory = (
  payload: Partial<RequisitesCryptoRequest> = {}
): RequisitesCryptoRequest => {
  return {
    name: payload.name ?? "",
    coin: payload.coin ?? "",
    network: payload.network ?? "",
    address: payload.address ?? "",
  };
};

export const RequisitesCryptoUpdateRequestFactory = (
  payload: Partial<RequisitesCryptoRequest> = {}
): RequisitesCryptoUpdateRequest => {
  return {
    name: payload.name ?? "",
    coin: payload.coin ?? "",
    network: payload.network ?? "",
    address: payload.address ?? "",
  };
};

export const RequisitesCryptoResponseFactory = (
  payload: Partial<RequisitesCryptoResponse> = {}
): RequisitesCryptoResponse => {
  return {
    id: payload.id ?? 0,
    user_id: payload.user_id ?? 0,
    name: payload.name ?? "",
    coin: payload.coin ?? "",
    network: payload.network ?? "",
    address: payload.address ?? "",
    creatd_at: payload.creatd_at ?? "",
    updated_at: payload.updated_at ?? "",
  };
};

export const RequisitesCryptoFieldsFactory = (): RequisitesCryptoFields => {
  return {
    name: false,
    coin: false,
    network: false,
    address: false,
  };
};

export const RequisitesVerificationRequestFactory = (
  payload: Partial<RequisitesVerificationRequest> = {}
): RequisitesVerificationRequest => {
  return {
    code: payload.code ?? "",
    type: payload.type ?? RequisitesType.bank,
  };
};
