import { AdminConfigTabValue } from "@/views/admin-config/model";

export interface AdminConfigTabsTab {
  id: number;
  label: string;
  value: AdminConfigTabValue;
  isActive: boolean;
}

export const Tabs = (tab: AdminConfigTabValue): AdminConfigTabsTab[] => {
  return [
    {
      id: 0,
      label: "Комиссии",
      value: AdminConfigTabValue.commission,
      isActive: tab === AdminConfigTabValue.commission,
    },
    {
      id: 1,
      label: "Реквизиты",
      value: AdminConfigTabValue.requisites,
      isActive: tab === AdminConfigTabValue.requisites,
    },
    {
      id: 1,
      label: "Валюты",
      value: AdminConfigTabValue.currency,
      isActive: tab === AdminConfigTabValue.currency,
    },
  ];
};
