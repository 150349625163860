import { defineComponent, PropType } from "vue";

import { COMPONENT_NAME, SVG } from "./attributes";

import { convertFile } from "@/shared/utils/file-helpers";
import { KycUploadFileResponse } from "@/shared/repository/modules/kyc/repo";
import {
  KybParticipantUploadFileResponse,
  KybUploadFileResponse,
} from "@/shared/repository/modules/kyb/repo";
import { UserFileResponse } from "@/shared/repository/modules/user/repo";
import { FileType } from "@/shared/constants/enums";

export default defineComponent({
  name: COMPONENT_NAME,
  props: {
    document: {
      type: <
        PropType<
          | KycUploadFileResponse
          | KybUploadFileResponse
          | KybParticipantUploadFileResponse
          | UserFileResponse
        >
      >Object,
      required: true,
    },
    view: {
      type: String,
      default: "",
    },
    isLoading: {
      type: Boolean,
      default: false,
    },
    isReadonly: {
      type: Boolean,
      default: false,
    },
    isCenter: {
      type: Boolean,
      default: false,
    },
    isPadding: {
      type: Boolean,
      default: true,
    },
    isDownload: {
      type: Boolean,
      default: false,
    },
    isIcon: {
      type: Boolean,
      default: true,
    },
    isLabel: {
      type: Boolean,
      default: true,
    },
    isError: {
      type: Boolean,
      default: false,
    },
  },
  emits: {
    upload: null,
    remove: null,
  },
  computed: {
    isImage(): boolean {
      const { type, link, created_at } = this.document;

      return type === FileType.image && !!link && !!created_at;
    },

    isDocument(): boolean {
      const { type, link, created_at } = this.document;

      return type === FileType.document && !!link && !!created_at;
    },

    isSuccess(): boolean {
      return this.isDocument;
    },

    isIconShow(): boolean {
      return this.isIcon && !this.isImage && !this.isPreloaderShow;
    },

    isLabelShow(): boolean {
      return (
        this.isLabel && !this.isImage && !this.isCenter && !this.isPreloaderShow
      );
    },

    isRemoveShow(): boolean {
      if (this.isReadonly) {
        return false;
      }

      return (
        (this.isImage || this.isDocument) &&
        !this.isDownload &&
        !!this.document.id &&
        !this.isPreloaderShow &&
        !this.isDownload
      );
    },

    isPreloaderShow(): boolean {
      const { isUpload, isRemove } = this.document;

      return isUpload || isRemove;
    },

    isDisabled(): boolean {
      const { isUpload, isRemove } = this.document;

      return (
        (this.isLoading ||
          this.isDocument ||
          this.isImage ||
          isUpload ||
          isRemove) &&
        !this.isDownload
      );
    },

    isRemoveDisabled(): boolean {
      const { isUpload, isRemove } = this.document;

      return this.isLoading || isUpload || isRemove;
    },

    displayedPreloader(): string {
      return SVG.preloader;
    },

    displayedIcon(): string {
      return this.isDocument ? SVG.file : SVG.plus;
    },

    displayedRemoveIcon(): string {
      return SVG.remove;
    },

    displayedBackgroundImage(): string {
      return this.isImage && !this.isPreloaderShow
        ? `url(${this.document.link})`
        : "";
    },
  },
  methods: {
    handleFile(): void {
      if (this.isDownload) {
        this.handleDownload();
      } else {
        (<HTMLInputElement>this.$refs.file).click();
      }
    },

    handleDownload(): void {
      const fileLink = document.createElement("a");
      const { link, name } = this.document;

      fileLink.href = link;
      fileLink.download = name;
      fileLink.target = "_blank";

      document.body.appendChild(fileLink);

      fileLink.click();

      document.body.removeChild(fileLink);
    },

    async handleUpload(event: Event): Promise<void> {
      const { files } = <HTMLInputElement>event.target;
      const uploadFile: File | null = files ? files[0] : null;

      if (!uploadFile) return;

      try {
        await convertFile(uploadFile).then((file) => {
          this.$emit("upload", {
            file,
            document: this.document,
          });
        });
      } catch (e) {
        console.log(e);
      } finally {
        (<HTMLInputElement>event.target).value = "";
      }
    },

    handleRemove(): void {
      this.$emit("remove", this.document);
    },
  },
});
