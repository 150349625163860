import { AxiosInstance } from "axios";

import { AdminCommissionUrlGenerator } from "./url-generator";
import {
  AdminCommissionCurrencyListResponse,
  AdminCommissionCurrencyStoreRequest,
  AdminCommissionDepositWithdrawalListResponse,
  AdminCommissionDepositWithdrawalShowResponse,
  AdminCommissionDepositWithdrawalStoreRequest,
  AdminCommissionDepositWithdrawalUpdateRequest,
  AdminCommissionExchangeListResponse,
  AdminCommissionExchangeStoreRequest,
  AdminCommissionExchangeUpdateRequest,
  AdminCommissionRequisitesListResponse,
  AdminCommissionRequisitesShowResponse,
  AdminCommissionRequisitesStoreRequest,
  AdminCommissionRequisitesUpdateRequest,
} from "./repo";
import { ResponseData } from "@/shared/constants/interfaces";
import {
  AdminCommissionCurrencyListResponseFactory,
  AdminCommissionDepositWithdrawalListResponseFactory,
  AdminCommissionDepositWithdrawalShowResponseFactory,
  AdminCommissionExchangeListResponseFactory,
  AdminCommissionRequisitesShowResponseFactory,
  AdminRequisitesListResponseFactory,
} from "./factory";
import { AdminCommissionExchangeType } from "./enums";

export interface AdminCommissionProjectRepository {
  exchangeStore(
    type: AdminCommissionExchangeType,
    payload: AdminCommissionExchangeStoreRequest
  ): Promise<void>;
  exchangeUpdate(
    id: number,
    payload: AdminCommissionExchangeUpdateRequest
  ): Promise<void>;
  exchangeDelete(id: number): Promise<void>;
  exchangeShow(id: number): Promise<void>;
  exchangeList(
    type: AdminCommissionExchangeType,
    userId: number
  ): Promise<AdminCommissionExchangeListResponse>;

  depositWithdrawalStore(
    type: AdminCommissionExchangeType,
    payload: AdminCommissionDepositWithdrawalStoreRequest
  ): Promise<void>;
  depositWithdrawalUpdate(
    id: number,
    payload: AdminCommissionDepositWithdrawalUpdateRequest
  ): Promise<void>;
  depositWithdrawalDelete(id: number): Promise<void>;
  depositWithdrawalShow(
    id: number
  ): Promise<AdminCommissionDepositWithdrawalShowResponse>;
  depositWithdrawalList(
    type: AdminCommissionExchangeType,
    userId: number
  ): Promise<AdminCommissionDepositWithdrawalListResponse>;

  requisitesStore(
    payload: AdminCommissionRequisitesStoreRequest
  ): Promise<void>;
  requisitesUpdate(
    payload: AdminCommissionRequisitesUpdateRequest
  ): Promise<void>;
  requisitesDelete(id: number): Promise<void>;
  requisitesShow(id: number): Promise<AdminCommissionRequisitesShowResponse>;
  requisitesList(): Promise<AdminCommissionRequisitesListResponse[]>;

  currencyStore(payload: AdminCommissionCurrencyStoreRequest): Promise<void>;
  currencyDelete(id: number): Promise<void>;
  currencyList(): Promise<AdminCommissionCurrencyListResponse>;
}

export class AdminCommissionHttpRepo
  implements AdminCommissionProjectRepository
{
  constructor(
    private readonly axios: AxiosInstance,
    private readonly urlGenerator: AdminCommissionUrlGenerator
  ) {}

  // Exchange

  async exchangeStore(
    type: AdminCommissionExchangeType,
    payload: AdminCommissionExchangeStoreRequest
  ): Promise<void> {
    await this.axios.post<void>(this.urlGenerator.exchangeStore(type), payload);
  }

  async exchangeUpdate(
    id: number,
    payload: AdminCommissionExchangeUpdateRequest
  ): Promise<void> {
    await this.axios.patch<void>(this.urlGenerator.exchangeUpdate(id), payload);
  }

  async exchangeDelete(id: number): Promise<void> {
    await this.axios.delete<void>(this.urlGenerator.exchangeDelete(id));
  }

  async exchangeShow(id: number): Promise<void> {
    await this.axios.get<void>(this.urlGenerator.exchangeShow(id));
  }

  async exchangeList(
    type: AdminCommissionExchangeType,
    userId: number
  ): Promise<AdminCommissionExchangeListResponse> {
    const { data } = await this.axios.get<
      ResponseData<AdminCommissionExchangeListResponse[]>
    >(this.urlGenerator.exchangeList(userId));

    switch (type) {
      case AdminCommissionExchangeType.all:
        return AdminCommissionExchangeListResponseFactory(
          data.data.find((item) => item.type === type) ?? {}
        );
      case AdminCommissionExchangeType.user:
        return AdminCommissionExchangeListResponseFactory(
          data.data.find(
            (item) => item.type === type && item.user_id === userId
          )
        );
      default:
        return AdminCommissionExchangeListResponseFactory();
    }
  }

  // Deposit Withdrawal

  async depositWithdrawalStore(
    type: AdminCommissionExchangeType,
    payload: AdminCommissionDepositWithdrawalStoreRequest
  ): Promise<void> {
    await this.axios.post<void>(
      this.urlGenerator.depositWithdrawalStore(type),
      payload
    );
  }

  async depositWithdrawalUpdate(
    id: number,
    payload: AdminCommissionDepositWithdrawalUpdateRequest
  ): Promise<void> {
    await this.axios.patch<void>(
      this.urlGenerator.depositWithdrawalUpdate(id),
      payload
    );
  }

  async depositWithdrawalDelete(id: number): Promise<void> {
    await this.axios.delete<void>(
      this.urlGenerator.depositWithdrawalDelete(id)
    );
  }

  async depositWithdrawalShow(
    id: number
  ): Promise<AdminCommissionDepositWithdrawalShowResponse> {
    const { data } = await this.axios.get<
      ResponseData<AdminCommissionDepositWithdrawalShowResponse>
    >(this.urlGenerator.depositWithdrawalShow(id));

    return AdminCommissionDepositWithdrawalShowResponseFactory(data.data);
  }

  async depositWithdrawalList(
    type: AdminCommissionExchangeType,
    userId: number
  ): Promise<AdminCommissionDepositWithdrawalListResponse> {
    const { data } = await this.axios.get<
      ResponseData<AdminCommissionDepositWithdrawalListResponse[]>
    >(this.urlGenerator.depositWithdrawalList(userId));

    switch (type) {
      case AdminCommissionExchangeType.all:
        return AdminCommissionDepositWithdrawalListResponseFactory(
          data.data.find((item) => item.type === type) ?? {}
        );
      case AdminCommissionExchangeType.user:
        return AdminCommissionDepositWithdrawalListResponseFactory(
          data.data.find(
            (item) => item.type === type && item.user_id === userId
          )
        );
      default:
        return AdminCommissionDepositWithdrawalListResponseFactory();
    }
  }

  // Requisites

  async requisitesStore(
    payload: AdminCommissionRequisitesStoreRequest
  ): Promise<void> {
    await this.axios.post<void>(this.urlGenerator.requisitesStore(), payload);
  }

  async requisitesUpdate(
    payload: AdminCommissionRequisitesUpdateRequest
  ): Promise<void> {
    await this.axios.patch<void>(this.urlGenerator.requisitesUpdate(), payload);
  }

  async requisitesDelete(id: number): Promise<void> {
    await this.axios.delete<void>(this.urlGenerator.requisitesDelete(id));
  }

  async requisitesShow(
    id: number
  ): Promise<AdminCommissionRequisitesShowResponse> {
    const { data } = await this.axios.get<
      ResponseData<AdminCommissionRequisitesShowResponse>
    >(this.urlGenerator.requisitesShow(id));

    return AdminCommissionRequisitesShowResponseFactory(data.data);
  }

  async requisitesList(): Promise<AdminCommissionRequisitesListResponse[]> {
    const { data } = await this.axios.get<
      ResponseData<AdminCommissionRequisitesListResponse[]>
    >(this.urlGenerator.requisitesList());

    return data.data.map((item) => AdminRequisitesListResponseFactory(item));
  }

  // Currency

  async currencyStore(
    payload: AdminCommissionCurrencyStoreRequest
  ): Promise<void> {
    await this.axios.post<void>(this.urlGenerator.currencyStore(), payload);
  }

  async currencyDelete(id: number): Promise<void> {
    await this.axios.delete<void>(this.urlGenerator.currencyDelete(id));
  }

  async currencyList(): Promise<AdminCommissionCurrencyListResponse> {
    const { data } = await this.axios.get<
      ResponseData<AdminCommissionCurrencyListResponse>
    >(this.urlGenerator.currencyList());

    return AdminCommissionCurrencyListResponseFactory(data.data);
  }
}
