<script lang="ts" src="./component.ts"></script>
<style lang="scss" src="./component.scss"></style>

<template>
  <div :class="b()">
    <p :class="b('label')">
      {{ $t("Реквизиты отправителя") }}
    </p>

    <div :class="b('radios')">
      <div :class="b('radio')" v-for="radio in displayedRadios" :key="radio.id">
        <SharedRadio
          view="light"
          :value="requisite"
          :checkedValue="radio.value"
          @click="handleRequisite(radio.value)"
        />

        <p :class="b('radio-label')">
          {{ $t(radio.label) }}
        </p>
      </div>
    </div>
  </div>
</template>
