export enum AdminCurrencyApiRoutes {
  store = "/api/v1/admin/commission/currency/store",
  update = "/api/v1/admin/commission/currency/update",
  delete = "/api/v1/admin/commission/currency/delete",
  show = "/api/v1/admin/commission/currency/show",
  list = "/api/v1/admin/commission/currency/list",
}

export interface AdminCurrencyProjectUrlGenerator {
  store(): string;
  update(): string;
  delete(id: number): string;
  show(id: number): string;
  list(): string;
}

export class AdminCurrencyUrlGenerator
  implements AdminCurrencyProjectUrlGenerator
{
  store(): string {
    return AdminCurrencyApiRoutes.store;
  }

  update(): string {
    return AdminCurrencyApiRoutes.update;
  }

  delete(id: number): string {
    return `${AdminCurrencyApiRoutes.delete}?id=${id}`;
  }

  show(id: number): string {
    return `${AdminCurrencyApiRoutes.show}?id=${id}`;
  }

  list(): string {
    return AdminCurrencyApiRoutes.list;
  }
}
