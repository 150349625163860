<script lang="ts" src="./component.ts"></script>
<style lang="scss" src="./component.scss"></style>

<template>
  <div :class="b()">
    <button
      :class="b('tab', { active: tab.isActive })"
      v-for="tab in displayedTabs"
      :key="tab.value"
      :disabled="tab.isDisabled"
      @click="handleTab(tab)"
    >
      <span :class="b('tab-label')">
        {{ $t(tab.label) }}
      </span>

      <span :class="b('tab-label', { small: true })">
        {{ $t(tab.labelSmall) }}
      </span>
    </button>
  </div>
</template>
