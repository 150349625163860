<script lang="ts" src="./component.ts"></script>
<style lang="scss" src="./component.scss"></style>

<template>
  <div :class="b()">
    <div :class="b('row-id')">
      <span
        :class="
          b('row-status', {
            status: displayedStatus,
          })
        "
      ></span>

      <p :class="b('row-label')">
        {{ user.index }}
      </p>
    </div>

    <div :class="b('row-email')">
      <p :class="b('row-label')">
        {{ user.email }}
      </p>
    </div>

    <div :class="b('row-kyc')">
      <span
        :class="b('row-icon')"
        v-if="displayedStatusKycIcon"
        v-html="displayedStatusKycIcon"
      ></span>

      <p :class="b('row-label')" v-if="displayedStatusKycLabel">
        {{ displayedStatusKycLabel }}
      </p>
    </div>

    <div :class="b('row-fio')">
      <p :class="b('row-label')">
        {{ displayedFio }}
      </p>
    </div>

    <div :class="b('row-balance')">
      <p :class="b('row-label', { divider: true })"></p>
    </div>

    <div :class="b('row-applications')">
      <p :class="b('row-label')"></p>
    </div>

    <div :class="b('row-action')" v-click-outside="handleHide">
      <button
        :class="b('row-toggle', { active: isVisible })"
        @click="handleToggle"
      >
        <span :class="b('row-icon')" v-html="displayedIcons.dots"></span>
      </button>

      <div :class="b('row-options', { active: isVisible })">
        <button
          :class="b('row-option')"
          v-for="option in displayedOptions"
          :key="option.id"
          :disabled="option.isDisabled"
          @click="handleOption(option)"
        >
          {{ $t(option.label) }}
        </button>
      </div>
    </div>
  </div>
</template>
