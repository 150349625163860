<script lang="ts" src="./component.ts"></script>
<style lang="scss" src="./component.scss"></style>

<template>
  <div :class="b()">
    <div :class="b('field')">
      <h4 :class="b('field-label')">
        {{ $t("Изменить статус") }}
      </h4>

      <SharedSelect
        view="light"
        :options="displayedOptions"
        :value="adminUserAppStatus.status"
        @update:value="handleChangeStatus"
      />
    </div>

    <div :class="b('field')">
      <h4 :class="b('field-label')">
        {{ $t("Комментарий для пользователя (на русском)") }}
      </h4>

      <UIInput
        view="light"
        placeholder="Добавьте комментарий при необходимости"
        v-model="adminUserAppStatus.messageRu"
      />
    </div>

    <div :class="b('field')">
      <h4 :class="b('field-label')">
        {{ $t("Комментарий для пользователя (на английском)") }}
      </h4>

      <UIInput
        view="light"
        placeholder="Добавьте комментарий при необходимости"
        v-model="adminUserAppStatus.messageEn"
      />
    </div>

    <UIButton
      :label="displayedSaveLabel"
      view="main"
      :disabled="isSaveDisabled"
      @click="handleSave"
    />
  </div>
</template>
