import { defineComponent } from "vue";

import { COMPONENT_NAME, SVG } from "./attributes";
import { AdminToolbarLink, AdminToolbarLinkLabel } from "./model";

import { SharedLanguage } from "@/components/shared";

import { SvgAttribute } from "@/shared/constants/constants";
import { RoutesTo } from "@/shared/constants/enums";

export default defineComponent({
  name: COMPONENT_NAME,
  components: {
    SharedLanguage,
  },
  computed: {
    displayedIcons(): SvgAttribute {
      return SVG;
    },

    displayedLinks(): AdminToolbarLink[] {
      return [
        {
          id: 0,
          label: AdminToolbarLinkLabel.users,
          to: RoutesTo.adminUsers,
          icon: SVG.users,
          isDisabled: false,
        },
        {
          id: 1,
          label: AdminToolbarLinkLabel.kyc,
          to: RoutesTo.adminKyc,
          icon: SVG.kyc,
          isDisabled: false,
        },
        {
          id: 2,
          label: AdminToolbarLinkLabel.application,
          to: RoutesTo.adminApplication,
          icon: SVG.application,
          isDisabled: false,
        },
        {
          id: 3,
          label: AdminToolbarLinkLabel.exchange,
          to: RoutesTo.adminConfig,
          icon: SVG.config,
          isDisabled: false,
        },
      ].map((link) => ({
        ...link,
        isDisabled:
          !this.$projectServices.userRepo.userInfo.user_info.application_type,
      }));
    },
  },
});
