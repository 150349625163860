import { defineComponent, PropType } from "vue";

import { COMPONENT_NAME, SVG } from "./attributes";
import { AdminKycKybCompanyItem } from "./model";

import { SvgAttribute } from "@/shared/constants/constants";
import { AdminKycComponent } from "@/views/admin-kyc/model";
import { KybCompanyFactory } from "@/shared/repository/modules/kyb/factory";

export default defineComponent({
  name: COMPONENT_NAME,
  props: {
    id: {
      type: Number,
      required: true,
    },
    component: {
      type: <PropType<AdminKycComponent>>String,
      default: "",
    },
  },
  emits: {
    "update:component": null,
  },
  data() {
    return {
      isLoading: false,
      kybRepository: this.$projectServices.kybRepository,
      kybCompany: KybCompanyFactory(),
    };
  },
  async created(): Promise<void> {
    try {
      this.isLoading = true;

      this.kybCompany = await this.kybRepository.companyById(this.id);
    } catch (error) {
      console.log(error);
    } finally {
      this.isLoading = false;
    }
  },
  computed: {
    displayedList(): AdminKycKybCompanyItem[] {
      const { isBasic, isParticipants, isFiles } = this.kybCompany;

      return [
        {
          id: 0,
          title: "Основная информация",
          label:
            "Информация о счете, адрес компании и декларация о происхождении средств",
          icon: isBasic ? SVG.check : SVG.time,
          component: AdminKycComponent.kybBasicInformation,
          isPadding: false,
          isDisabled: this.isLoading,
        },
        {
          id: 1,
          title: "Участники организации",
          label:
            "Основная информация, идентификационные данные и страна проживания связанных сторон",
          icon: isParticipants.isSuccess
            ? SVG.check
            : isParticipants.isExpected
            ? SVG.expected
            : SVG.time,
          component: AdminKycComponent.kybParticipants,
          isPadding: true,
          isDisabled: !isBasic || this.isLoading,
        },
        {
          id: 2,
          title: "Загрузка документов",
          label:
            "Предоставьте необходимые документы для подтверждения вашей компании",
          icon: isFiles ? SVG.check : SVG.time,
          component: AdminKycComponent.kybDocuments,
          isPadding: false,
          isDisabled: isParticipants.isWaiting || this.isLoading,
        },
      ];
    },

    displayedSvg(): SvgAttribute {
      return SVG;
    },
  },
  methods: {
    handleCard({ component }: AdminKycKybCompanyItem): void {
      this.$emit("update:component", component);
    },
  },
});
