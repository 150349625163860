import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createTextVNode as _createTextVNode, withCtx as _withCtx, createBlock as _createBlock } from "vue"

const _hoisted_1 = { key: 0 }
const _hoisted_2 = { key: 1 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_SharedBack = _resolveComponent("SharedBack")!
  const _component_SharedAdd = _resolveComponent("SharedAdd")!
  const _component_UIButton = _resolveComponent("UIButton")!
  const _component_SharedCard = _resolveComponent("SharedCard")!

  return (_openBlock(), _createBlock(_component_SharedCard, {
    class: _normalizeClass(_ctx.b())
  }, {
    default: _withCtx(() => [
      _createElementVNode("div", {
        class: _normalizeClass(_ctx.b('header'))
      }, [
        _createVNode(_component_SharedBack, { onClick: _ctx.handleBack }, null, 8, ["onClick"]),
        _createElementVNode("h3", {
          class: _normalizeClass(_ctx.b('header-title'))
        }, _toDisplayString(_ctx.$t("Участники организации")), 3)
      ], 2),
      _createElementVNode("div", {
        class: _normalizeClass(_ctx.b('cards'))
      }, [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.displayedParticipants, (participant, participantIndex) => {
          return (_openBlock(), _createElementBlock("div", {
            class: _normalizeClass(_ctx.b('card')),
            key: participantIndex
          }, [
            (!!participant.id)
              ? (_openBlock(), _createElementBlock("h5", {
                  key: 0,
                  class: _normalizeClass(_ctx.b('card-title'))
                }, _toDisplayString(_ctx.$t("Участник")) + " №" + _toDisplayString(participantIndex + 1), 3))
              : _createCommentVNode("", true),
            _createVNode(_component_SharedAdd, {
              "is-success": participant.isSuccess,
              "is-expected": participant.isExpected,
              "is-readonly": _ctx.isReadonly,
              "is-loading": _ctx.isLoading,
              onClick: ($event: any) => (_ctx.handleParticipant(participant)),
              onRemove: ($event: any) => (_ctx.handleParticipantRemove(participant))
            }, {
              title: _withCtx(() => [
                _createTextVNode(_toDisplayString(participant.name) + " ", 1),
                (participant.name)
                  ? (_openBlock(), _createElementBlock("br", _hoisted_1))
                  : _createCommentVNode("", true),
                _createTextVNode(" " + _toDisplayString(participant.patronymic) + " ", 1),
                (participant.patronymic)
                  ? (_openBlock(), _createElementBlock("br", _hoisted_2))
                  : _createCommentVNode("", true),
                _createTextVNode(" " + _toDisplayString(participant.sur_name), 1)
              ]),
              label: _withCtx(() => [
                _createTextVNode(_toDisplayString(participant.isExpected
                ? _ctx.$t("Прикрепите документы")
                : _ctx.$t("Добавить")), 1)
              ]),
              _: 2
            }, 1032, ["is-success", "is-expected", "is-readonly", "is-loading", "onClick", "onRemove"])
          ], 2))
        }), 128))
      ], 2),
      _createVNode(_component_UIButton, {
        class: _normalizeClass(_ctx.b('continue')),
        label: "Продолжить",
        view: "main",
        disabled: _ctx.isContinueDisabled,
        onClick: _ctx.handleContinue
      }, null, 8, ["class", "disabled", "onClick"])
    ]),
    _: 1
  }, 8, ["class"]))
}