import { toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, resolveDynamicComponent as _resolveDynamicComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, Transition as _Transition, withCtx as _withCtx, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_VerificationTabs = _resolveComponent("VerificationTabs")!
  const _component_VerificationModal = _resolveComponent("VerificationModal")!

  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(_ctx.b())
  }, [
    _createElementVNode("h1", {
      class: _normalizeClass(_ctx.b('title'))
    }, _toDisplayString(_ctx.$t("Верификация")), 3),
    _createElementVNode("div", {
      class: _normalizeClass(_ctx.b('content'))
    }, [
      _createVNode(_component_VerificationTabs, {
        class: _normalizeClass(_ctx.b('tabs')),
        tab: _ctx.tab,
        onTab: _ctx.handleTab
      }, null, 8, ["class", "tab", "onTab"]),
      (_ctx.isKycShow)
        ? (_openBlock(), _createBlock(_resolveDynamicComponent(_ctx.displayedKycComponent), {
            key: 0,
            component: _ctx.kycComponent,
            "onUpdate:component": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.kycComponent) = $event))
          }, null, 40, ["component"]))
        : _createCommentVNode("", true),
      (_ctx.isKybShow)
        ? (_openBlock(), _createBlock(_resolveDynamicComponent(_ctx.displayedKybComponent), {
            key: 1,
            component: _ctx.kybComponent,
            "onUpdate:component": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.kybComponent) = $event))
          }, null, 40, ["component"]))
        : _createCommentVNode("", true),
      _createVNode(_Transition, { name: "opacity" }, {
        default: _withCtx(() => [
          (_ctx.isModal)
            ? (_openBlock(), _createBlock(_component_VerificationModal, {
                key: 0,
                class: _normalizeClass(_ctx.b('modal')),
                tab: _ctx.tab,
                "onUpdate:tab": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.tab) = $event)),
                isModal: _ctx.isModal,
                "onUpdate:isModal": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.isModal) = $event)),
                kycComponent: _ctx.kycComponent,
                "onUpdate:kycComponent": _cache[4] || (_cache[4] = ($event: any) => ((_ctx.kycComponent) = $event)),
                kybComponent: _ctx.kybComponent,
                "onUpdate:kybComponent": _cache[5] || (_cache[5] = ($event: any) => ((_ctx.kybComponent) = $event))
              }, null, 8, ["class", "tab", "isModal", "kycComponent", "kybComponent"]))
            : _createCommentVNode("", true)
        ]),
        _: 1
      })
    ], 2)
  ], 2))
}