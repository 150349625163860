import { normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, resolveDirective as _resolveDirective, withDirectives as _withDirectives } from "vue"

const _hoisted_1 = ["innerHTML"]
const _hoisted_2 = ["innerHTML"]
const _hoisted_3 = ["disabled", "onClick"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _directive_click_outside = _resolveDirective("click-outside")!

  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(_ctx.b())
  }, [
    _createElementVNode("div", {
      class: _normalizeClass(_ctx.b('row-id'))
    }, [
      _createElementVNode("span", {
        class: _normalizeClass(
          _ctx.b('row-status', {
            status: _ctx.displayedStatus,
          })
        )
      }, null, 2),
      _createElementVNode("p", {
        class: _normalizeClass(_ctx.b('row-label'))
      }, _toDisplayString(_ctx.user.index), 3)
    ], 2),
    _createElementVNode("div", {
      class: _normalizeClass(_ctx.b('row-email'))
    }, [
      _createElementVNode("p", {
        class: _normalizeClass(_ctx.b('row-label'))
      }, _toDisplayString(_ctx.user.email), 3)
    ], 2),
    _createElementVNode("div", {
      class: _normalizeClass(_ctx.b('row-kyc'))
    }, [
      (_ctx.displayedStatusKycIcon)
        ? (_openBlock(), _createElementBlock("span", {
            key: 0,
            class: _normalizeClass(_ctx.b('row-icon')),
            innerHTML: _ctx.displayedStatusKycIcon
          }, null, 10, _hoisted_1))
        : _createCommentVNode("", true),
      (_ctx.displayedStatusKycLabel)
        ? (_openBlock(), _createElementBlock("p", {
            key: 1,
            class: _normalizeClass(_ctx.b('row-label'))
          }, _toDisplayString(_ctx.displayedStatusKycLabel), 3))
        : _createCommentVNode("", true)
    ], 2),
    _createElementVNode("div", {
      class: _normalizeClass(_ctx.b('row-fio'))
    }, [
      _createElementVNode("p", {
        class: _normalizeClass(_ctx.b('row-label'))
      }, _toDisplayString(_ctx.displayedFio), 3)
    ], 2),
    _createElementVNode("div", {
      class: _normalizeClass(_ctx.b('row-balance'))
    }, [
      _createElementVNode("p", {
        class: _normalizeClass(_ctx.b('row-label', { divider: true }))
      }, null, 2)
    ], 2),
    _createElementVNode("div", {
      class: _normalizeClass(_ctx.b('row-applications'))
    }, [
      _createElementVNode("p", {
        class: _normalizeClass(_ctx.b('row-label'))
      }, null, 2)
    ], 2),
    _withDirectives((_openBlock(), _createElementBlock("div", {
      class: _normalizeClass(_ctx.b('row-action'))
    }, [
      _createElementVNode("button", {
        class: _normalizeClass(_ctx.b('row-toggle', { active: _ctx.isVisible })),
        onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.handleToggle && _ctx.handleToggle(...args)))
      }, [
        _createElementVNode("span", {
          class: _normalizeClass(_ctx.b('row-icon')),
          innerHTML: _ctx.displayedIcons.dots
        }, null, 10, _hoisted_2)
      ], 2),
      _createElementVNode("div", {
        class: _normalizeClass(_ctx.b('row-options', { active: _ctx.isVisible }))
      }, [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.displayedOptions, (option) => {
          return (_openBlock(), _createElementBlock("button", {
            class: _normalizeClass(_ctx.b('row-option')),
            key: option.id,
            disabled: option.isDisabled,
            onClick: ($event: any) => (_ctx.handleOption(option))
          }, _toDisplayString(_ctx.$t(option.label)), 11, _hoisted_3))
        }), 128))
      ], 2)
    ], 2)), [
      [_directive_click_outside, _ctx.handleHide]
    ])
  ], 2))
}