import { defineComponent, PropType } from "vue";

import { COMPONENT_NAME } from "./attributes";

import { SettingsRequisitesComponent } from "../model";

import { SharedCard, SharedAdd } from "@/components/shared";
import {
  RequisitesBankResponse,
  RequisitesCryptoResponse,
} from "@/shared/repository/modules/requisites/repo";

const REQUISITES_MAX_LENGTH = 5;

export default defineComponent({
  name: COMPONENT_NAME,
  components: {
    SharedCard,
    SharedAdd,
  },
  props: {
    component: {
      type: <PropType<SettingsRequisitesComponent>>String,
      required: true,
    },
  },
  emits: {
    "update:component": null,
  },
  data() {
    return {
      isLoading: false,
      bankRequisites: <RequisitesBankResponse[]>[],
      cryptoRequisites: <RequisitesCryptoResponse[]>[],
      requisitesRepo: this.$projectServices.requisitesRepo,
      requisitesRepository: this.$projectServices.requisitesRepository,
    };
  },
  async created(): Promise<void> {
    try {
      this.isLoading = true;

      const [bankRequisites, cryptoRequisites] = await Promise.all([
        this.requisitesRepository.bankList(),
        this.requisitesRepository.cryptoList(),
      ]);

      this.bankRequisites = bankRequisites;
      this.cryptoRequisites = cryptoRequisites;
    } catch (error) {
      console.log(error);
    } finally {
      this.isLoading = false;
    }
  },
  computed: {
    isBankAddShow(): boolean {
      return this.bankRequisites.length < REQUISITES_MAX_LENGTH;
    },

    isCryptoAddShow(): boolean {
      return this.cryptoRequisites.length < REQUISITES_MAX_LENGTH;
    },
  },
  methods: {
    handleBank(requisite?: RequisitesBankResponse): void {
      this.requisitesRepo.UPDATE_BANK_ID(requisite?.id ?? null);

      this.$emit("update:component", SettingsRequisitesComponent.bank);
    },

    async handleBankRemove(requisite: RequisitesBankResponse): Promise<void> {
      try {
        this.isLoading = true;

        await this.requisitesRepository.bankDelete(requisite.id!);

        this.bankRequisites = await this.requisitesRepository.bankList();
      } catch (error) {
        console.log(error);
      } finally {
        this.isLoading = false;
      }
    },

    handleCrypto(requisite?: RequisitesCryptoResponse): void {
      this.requisitesRepo.UPDATE_CRYPTO_ID(requisite?.id ?? null);

      this.$emit("update:component", SettingsRequisitesComponent.crypto);
    },

    async handleCryptoRemove(
      requisite: RequisitesCryptoResponse
    ): Promise<void> {
      try {
        this.isLoading = true;

        await this.requisitesRepository.cryptoDelete(requisite.id!);

        this.cryptoRequisites = await this.requisitesRepository.cryptoList();
      } catch (error) {
        console.log(error);
      } finally {
        this.isLoading = false;
      }
    },
  },
});
