import { defineComponent } from "vue";

import { COMPONENT_NAME, SVG } from "./attributes";

import { SharedSearch } from "@/components/shared";

import { SvgAttribute } from "@/shared/constants/constants";
import { HistoryTab } from "@/shared/constants/enums";
import { ApplicationFilterRequest } from "@/shared/repository/modules/application/repo";
import { Search } from "@/shared/constants/interfaces";

export interface HistoryHeaderTab {
  id: number;
  label: string;
  value: HistoryTab;
  acitve: boolean;
}

export default defineComponent({
  name: COMPONENT_NAME,
  components: {
    SharedSearch,
  },
  data() {
    return {
      historyRepo: this.$projectServices.historyRepo,
    };
  },
  computed: {
    displayedTab(): HistoryTab {
      return this.historyRepo.tab;
    },

    displayedFilter(): ApplicationFilterRequest {
      return this.historyRepo.filter;
    },

    displayedSearch(): Search {
      return this.historyRepo.search;
    },

    displayedTabs(): HistoryHeaderTab[] {
      return [
        {
          id: 0,
          label: "Пополнение/Вывод",
          value: HistoryTab.depositWithdrawal,
          acitve: this.displayedTab === HistoryTab.depositWithdrawal,
        },
        {
          id: 1,
          label: "Заявки",
          value: HistoryTab.application,
          acitve: this.displayedTab === HistoryTab.application,
        },
      ];
    },

    displayedIcons(): SvgAttribute {
      return SVG;
    },

    hasFilter(): boolean {
      const { amount_from, amount_to, date_from, date_to, status } =
        this.displayedFilter;

      return (
        !!amount_from || !!amount_to || !!date_from || !!date_to || !!status
      );
    },

    isFilterActive(): boolean {
      return this.historyRepo.isFilter && this.hasFilter;
    },
  },
  methods: {
    async handleTab({ value }: HistoryHeaderTab): Promise<void> {
      this.historyRepo.CLEAR_PAGE();
      this.historyRepo.UPDATE_TAB(value);

      await this.historyRepo.fetch();
    },

    handleFilter(): void {
      this.historyRepo.UPDATE_IS_FILTER(true);
    },

    async handleClearFilter(): Promise<void> {
      this.historyRepo.CLEAR_FILTER();

      await this.historyRepo.fetch();
    },

    handleSearch(value: string): void {
      this.historyRepo.UPDATE_SEARCH_VALUE(value);
    },
  },
});
