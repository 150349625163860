<script lang="ts" src="./component.ts"></script>
<style lang="scss" src="./component.scss"></style>

<template>
  <button
    :class="
      b('', {
        success: isSuccess,
        confirmed: isConfirmed,
        expected: isExpected,
      })
    "
    :disabled="isDisabled"
  >
    <span v-if="isIconShow" :class="b('icon')" v-html="displayedIcon"></span>

    <button
      v-if="isRemoveShow"
      :class="b('remove')"
      :disabled="isRemoveDisabled"
      @click.stop="handleRemove"
    >
      <span :class="b('icon')" v-html="displayedIcons.remove"></span>
    </button>

    <h6 v-if="isTitleShow" :class="b('title')">
      <slot name="title"></slot>
    </h6>

    <p v-if="isLabelShow" :class="b('label', { expected: isExpected })">
      <slot name="label"></slot>
    </p>
  </button>
</template>
