<script lang="ts" src="./component.ts"></script>
<style lang="scss" src="./component.scss"></style>

<template>
  <div :class="b()">
    <div :class="b('fields')">
      <div :class="b('field')">
        <p :class="b('field-label')">Монета</p>

        <SharedSelect
          view="light"
          :options="displayedOptions"
          :icon="adminCurrency.name"
          :is-crypto-icon="true"
          :is-search="true"
          v-model:value="adminCurrency.name"
        />
      </div>
    </div>

    <UIButton
      :label="displayedButtonLabel"
      view="main"
      :disabled="isButtonDisabled"
      @click="handleButton"
    />
  </div>
</template>
