<script lang="ts" src="./component.ts"></script>
<style lang="scss" src="./component.scss"></style>

<template>
  <div :class="b()">
    <SharedAdminHeader
      title="Заявки"
      :is-type="false"
      :is-loading="isLoading"
      :is-email="true"
      v-model:id="search.id"
      v-model:email="search.email"
      @search="handleSearch"
    />

    <div :class="b('wrapper')">
      <SharedSearch v-model:search="search.value" />

      <AdminApplicationTable
        :class="b('table')"
        :search="search"
        :sort="sort"
        v-model:deposit-withdrawal-list="depositWithdrawalList"
        v-model:deposit-withdrawal-item-id="depositWithdrawalItemId"
        v-model:is-work="isWork"
        v-model:is-reject="isReject"
      />

      <SharedButtonIcon
        v-if="isMoreShow"
        :class="b('more')"
        view="gray-outline"
        @click="handleMore"
      >
        <template #icon-left>
          <span :class="b('more-icon')" v-html="displayedIcons.more"></span>
        </template>

        <template #label>
          {{ $t("Показать больше") }}
        </template>
      </SharedButtonIcon>
    </div>

    <transition name="opacity">
      <AdminApplicationEdit
        v-if="depositWithdrawalItemId"
        :class="b('modal')"
        v-model:deposit-withdrawal-list="depositWithdrawalList"
        v-model:deposit-withdrawal-item-id="depositWithdrawalItemId"
        v-model:is-work="isWork"
        v-model:is-reject="isReject"
      />
    </transition>
  </div>
</template>
