<script lang="ts" src="./component.ts"></script>
<style lang="scss" src="./component.scss"></style>

<template>
  <SharedWindow :class="b()" :title="displayedTitle" @close="handleClose">
    <div :class="b('content')">
      <div :class="b('tabs')">
        <button
          :class="b('tab', { active: tab.isActive })"
          v-for="tab in displayedTabs"
          :key="tab.value"
          @click="handleTab(tab.value)"
        >
          {{ tab.label }}
        </button>
      </div>

      <component :is="displayedComponent" />
    </div>
  </SharedWindow>
</template>
