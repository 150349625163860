import { defineComponent } from "vue";

import { COMPONENT_NAME, SVG } from "./attributes";

import { WalletCard } from "../card";

import { SvgAttribute } from "@/shared/constants/constants";
import { ApplicationFilterRequestFactory } from "@/shared/repository/modules/application/factory";

export interface WalletApplicationsColumn {
  id: number;
  label: string;
}

export default defineComponent({
  name: COMPONENT_NAME,
  components: {
    WalletCard,
  },
  data() {
    return {
      applicationHistory: [],
      applicationRepository: this.$projectServices.applicationRepository,
    };
  },
  async created(): Promise<void> {
    try {
      await this.applicationRepository.history(
        ApplicationFilterRequestFactory()
      );
    } catch (error) {
      console.log(error);
    }
  },
  computed: {
    displayedColumns(): WalletApplicationsColumn[] {
      return [
        {
          id: 0,
          label: "Дата и время",
        },
        {
          id: 0,
          label: "Операция",
        },
      ];
    },

    displayedIcons(): SvgAttribute {
      return SVG;
    },
  },
});
