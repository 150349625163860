import { defineComponent, PropType } from "vue";

import { COMPONENT_NAME } from "./attributes";

import { UIInput } from "@/components/ui";
import {
  SharedDate,
  SharedRadio,
  SharedInputDouble,
  SharedCheckbox,
} from "@/components/shared";
import { AdminKycBack } from "../back";

import { AdminKycComponent } from "@/views/admin-kyc/model";
import { KybParticipantResponseFactory } from "@/shared/repository/modules/kyb/factory";

export default defineComponent({
  name: COMPONENT_NAME,
  components: {
    UIInput,
    SharedDate,
    SharedRadio,
    SharedInputDouble,
    SharedCheckbox,
    AdminKycBack,
  },
  props: {
    component: {
      type: <PropType<AdminKycComponent>>String,
      default: "",
    },
  },
  emits: {
    "update:component": null,
  },
  data() {
    return {
      isLoading: false,
      userRepo: this.$projectServices.userRepo,
      kybRepo: this.$projectServices.kybRepo,
      kybRepository: this.$projectServices.kybRepository,
      kybParticipant: KybParticipantResponseFactory(),
    };
  },
  async created(): Promise<void> {
    try {
      this.isLoading = true;

      if (this.kybRepo.participantId) {
        this.kybParticipant = await this.kybRepository.participantById(
          this.kybRepo.participantId
        );
      }
    } catch (error) {
      console.log(error);
    } finally {
      this.isLoading = false;
    }
  },
  methods: {
    handleBack(): void {
      this.kybRepo.UPDATE_PARTICIPANT_ID(null);

      this.$emit("update:component", AdminKycComponent.kybParticipants);
    },
  },
});
