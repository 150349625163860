<script lang="ts" src="./component.ts"></script>
<style lang="scss" src="./component.scss"></style>

<template>
  <SharedCard :class="b()">
    <div :class="b('header')">
      <SharedBack @click="handleBack" />

      <h3 :class="b('header-title')">
        {{ $t("Участник") }}
      </h3>
    </div>

    <div :class="b('form')">
      <div :class="b('form-upload')">
        <div :class="b('form-passport')">
          <h5 :class="b('form-title')">
            {{ $t("Загрузка паспорта или водительских прав*") }}
          </h5>

          <div :class="b('form-uploads')">
            <SharedValidate
              field="kyb-participant-documents-passport-selfie"
              :value="documents['kyb-participant-documents-passport-selfie'].id"
              :is-validate="isValidate"
              :is-center="true"
            >
              <SharedUpload
                :class="b('upload')"
                :document="
                  documents['kyb-participant-documents-passport-selfie']
                "
                :is-readonly="isReadonly"
                :is-loading="isLoading"
                :is-error="
                  isValidate &&
                  !documents['kyb-participant-documents-passport-selfie'].id
                "
                @upload="handleUploadFile"
                @remove="handleRemoveFile"
              >
                <template #label>
                  {{ $t("Страница") }} <br />
                  {{ $t("с фото") }}
                </template>
              </SharedUpload>
            </SharedValidate>

            <SharedValidate
              field="kyb-participant-documents-passport-organization"
              :value="
                documents['kyb-participant-documents-passport-organization'].id
              "
              :is-validate="isValidate"
              :is-center="true"
            >
              <SharedUpload
                :class="b('upload')"
                :document="
                  documents['kyb-participant-documents-passport-organization']
                "
                :is-readonly="isReadonly"
                :is-loading="isLoading"
                :is-error="
                  isValidate &&
                  !documents['kyb-participant-documents-passport-organization']
                    .id
                "
                @upload="handleUploadFile"
                @remove="handleRemoveFile"
              >
                <template #label>
                  {{ $t("Страница") }} <br />
                  {{ $t("кем выдан") }}
                </template>
              </SharedUpload>
            </SharedValidate>

            <SharedValidate
              field="kyc-personal-documents-passport-address"
              :value="
                documents['kyb-participant-documents-passport-address'].id
              "
              :is-validate="isValidate"
              :is-center="true"
            >
              <SharedUpload
                :class="b('upload')"
                :document="
                  documents['kyb-participant-documents-passport-address']
                "
                :is-readonly="isReadonly"
                :is-loading="isLoading"
                :is-error="
                  isValidate &&
                  !documents['kyb-participant-documents-passport-address'].id
                "
                @upload="handleUploadFile"
                @remove="handleRemoveFile"
              >
                <template #label>
                  {{ $t("Страница") }} <br />
                  {{ $t("с пропиской") }}
                </template>
              </SharedUpload>
            </SharedValidate>
          </div>
        </div>

        <div :class="b('form-photo')">
          <h5 :class="b('form-title')">
            {{ $t("Загрузка селфи*") }}
          </h5>

          <SharedValidate
            :class="b('form-photo-upload')"
            field="kyc-personal-documents-selfie"
            :value="documents['kyb-participant-documents-selfie'].id"
            :is-validate="isValidate"
            :is-center="true"
          >
            <SharedUpload
              :class="b('form-photo-upload')"
              :document="documents['kyb-participant-documents-selfie']"
              :is-readonly="isReadonly"
              :is-loading="isLoading"
              :is-center="true"
              :is-error="
                isValidate && !documents['kyb-participant-documents-selfie'].id
              "
              @upload="handleUploadFile"
              @remove="handleRemoveFile"
            />
          </SharedValidate>
        </div>
      </div>
    </div>

    <div :class="b('uploads')">
      <h5 :class="b('form-title')">
        {{ $t("Скачайте и подпишите соглашения*") }}
      </h5>

      <div :class="b('uploads-groups')">
        <div :class="b('uploads-group')">
          <SharedDownload :document="displayedQuestionnaireOfAnIndividual">
            <template #label>
              {{ $t("Анкета физического лица") }} <br />
              {{ $t("(подписанная учредителем)") }}
            </template>
          </SharedDownload>

          <SharedValidate
            field="kyb-participant-documents-questionnaire"
            :value="documents['kyb-participant-documents-questionnaire'].id"
            :is-validate="isValidate"
            :is-center="true"
          >
            <SharedUpload
              :class="b('upload')"
              :document="documents['kyb-participant-documents-questionnaire']"
              :is-readonly="isReadonly"
              :is-loading="isLoading"
              :is-error="
                isValidate &&
                !documents['kyb-participant-documents-questionnaire'].id
              "
              @upload="handleUploadFile"
              @remove="handleRemoveFile"
            >
              <template #label>
                {{ $t("Подписанный") }} <br />
                {{ $t("документ") }}
              </template>
            </SharedUpload>
          </SharedValidate>
        </div>

        <div :class="b('uploads-group')">
          <SharedDownload
            :document="displayedConsentToTheProcessingOfPersonalData"
          >
            <template #label>
              {{ $t("Согласие на обработку") }} <br />
              {{ $t("персональных данных") }}
            </template>
          </SharedDownload>

          <SharedValidate
            field="kyc-personal-documents-personal-data"
            :value="documents['kyb-participant-documents-personal-data'].id"
            :is-validate="isValidate"
            :is-center="true"
          >
            <SharedUpload
              :class="b('upload')"
              :document="documents['kyb-participant-documents-personal-data']"
              :is-readonly="isReadonly"
              :is-loading="isLoading"
              :is-error="
                isValidate &&
                !documents['kyb-participant-documents-personal-data'].id
              "
              @upload="handleUploadFile"
              @remove="handleRemoveFile"
            >
              <template #label>
                {{ $t("Подписанное") }} <br />
                {{ $t("соглашение") }}
              </template>
            </SharedUpload>
          </SharedValidate>
        </div>
      </div>
    </div>

    <div v-if="isOtherShow" :class="b('other')">
      <h5 :class="b('form-title')">
        {{
          $t(
            "Пожалуйста, прикрепите иные документы для подтверждения вашей верификации"
          )
        }}
      </h5>

      <p :class="b('form-label')">
        {{ $t("Например: Приказ о назначении директора") }}
      </p>

      <div :class="b('other-documents')">
        <SharedUpload
          v-for="file in files"
          :key="file.uuid"
          :class="b('other-upload')"
          :document="file"
          :is-upload="file.isUpload"
          :is-remove="file.isRemove"
          :is-readonly="isReadonly"
          :is-loading="isLoading"
          @remove="handleRemoveAdditionalFile"
        >
          <template v-if="file.name" #label>
            {{ file.name }}
          </template>
        </SharedUpload>

        <SharedUpload
          v-if="isAddtionalUpload"
          :class="b('other-upload')"
          :document="file"
          :is-upload="file.isUpload"
          :is-readonly="isReadonly"
          :is-loading="isLoading"
          :isCenter="true"
          :is-label="false"
          @upload="handleUploadAdditionalFile"
        />
      </div>
    </div>

    <UIButton
      :class="b('continue')"
      label="Продолжить"
      view="main"
      :disabled="isContinueDisabled"
      @click="handleContinue"
    />
  </SharedCard>
</template>
