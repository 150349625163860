import { SvgAttribute } from "@/shared/constants/constants";

export const COMPONENT_NAME = "admin-config-requisites-form-banks";

export const SVG: SvgAttribute = {
  circle: `<svg width="26" height="26" viewBox="0 0 26 26" fill="none" xmlns="http://www.w3.org/2000/svg">
  <path opacity="0.964" fill-rule="evenodd" clip-rule="evenodd" d="M11.9094 1.59962C15.8874 1.35938 19.1797 2.73047 21.7864 5.7129C24.2572 8.90963 24.9512 12.4643 23.8684 16.377C22.5904 20.0925 20.0937 22.5892 16.3782 23.8672C12.4655 24.95 8.91084 24.256 5.71411 21.7852C2.49209 18.942 1.16332 15.3789 1.72778 11.0957C2.41531 7.52564 4.3196 4.82575 7.44067 2.9961C8.84315 2.23112 10.3328 1.76563 11.9094 1.59962ZM12.925 3.22462C16.2027 3.31874 18.8348 4.65599 20.8215 7.23634C22.7419 10.0078 23.1819 13.0039 22.1418 16.2246C21.0128 19.1649 18.9646 21.1622 15.9973 22.2168C12.032 23.3245 8.61271 22.4104 5.7395 19.4746C3.31709 16.5877 2.67385 13.3377 3.80982 9.72462C4.93885 6.78438 6.98705 4.78699 9.95435 3.73243C10.9299 3.43304 11.9201 3.26377 12.925 3.22462Z" fill="#36A1EA"/>
  <path opacity="0.988" fill-rule="evenodd" clip-rule="evenodd" d="M12.669 8.91199C13.1697 8.80281 13.5337 8.97207 13.7608 9.4198C13.7862 10.3337 13.7947 11.2478 13.7862 12.162C14.7004 12.1535 15.6145 12.162 16.5284 12.1874C16.8832 12.3299 17.0525 12.5923 17.0362 12.9745C17.0525 13.3567 16.8832 13.6191 16.5284 13.7616C15.6145 13.787 14.7004 13.7955 13.7862 13.787C13.7947 14.7012 13.7862 15.6153 13.7608 16.5292C13.6183 16.884 13.356 17.0532 12.9737 17.037C12.5915 17.0532 12.3292 16.884 12.1866 16.5292C12.1612 15.6153 12.1527 14.7012 12.1612 13.787C11.247 13.7955 10.3329 13.787 9.41904 13.7616C9.06423 13.6191 8.89497 13.3567 8.91122 12.9745C8.89497 12.5923 9.06423 12.3299 9.41904 12.1874C10.3329 12.162 11.247 12.1535 12.1612 12.162C12.1527 11.2478 12.1612 10.3337 12.1866 9.4198C12.2893 9.19027 12.4501 9.02102 12.669 8.91199Z" fill="#36A1EA"/>
  </svg>`,
  added: `<svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <ellipse cx="7.28631" cy="7.29989" rx="5.81048" ry="5.81259" fill="white"/>
    <path opacity="0.992" fill-rule="evenodd" clip-rule="evenodd" d="M7.38274 0.0059806C10.6381 -0.100117 13.1405 1.20544 14.8898 3.92266C16.1738 6.22568 16.344 8.60974 15.4005 11.0749C14.3415 13.4694 12.5541 15.0304 10.0383 15.7579C6.88966 16.4561 4.20573 15.6443 1.98649 13.3227C0.16125 11.1194 -0.406179 8.62173 0.284196 5.82992C1.02828 3.3713 2.57736 1.62866 4.93145 0.601998C5.72447 0.28443 6.54157 0.0857584 7.38274 0.0059806ZM10.7533 5.48934C11.2557 5.46982 11.5394 5.70823 11.6044 6.20456C11.5955 6.33909 11.5558 6.46398 11.4853 6.5792C10.2199 7.89044 8.93183 9.17896 7.62106 10.4448C7.36494 10.5664 7.11524 10.555 6.87206 10.4107C6.08332 9.62171 5.29461 8.83272 4.50587 8.0437C4.30776 7.67938 4.35883 7.35583 4.65908 7.07304C4.89451 6.90139 5.1442 6.87867 5.40809 7.00492C6.03698 7.59995 6.65546 8.20735 7.26358 8.82703C8.31332 7.77692 9.36308 6.72677 10.4128 5.67666C10.524 5.60411 10.6375 5.54169 10.7533 5.48934Z" fill="#51BC40"/>
  </svg>`,
  remove: `<svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path fill-rule="evenodd" clip-rule="evenodd" d="M13.8492 3.94975C14.077 4.17755 14.077 4.5469 13.8492 4.77471L9.72437 8.89949L13.8492 13.0243C14.077 13.2521 14.077 13.6214 13.8492 13.8492C13.6214 14.077 13.252 14.077 13.0242 13.8492L8.89941 9.72445L4.77462 13.8492C4.54682 14.077 4.17747 14.077 3.94967 13.8492C3.72186 13.6214 3.72186 13.2521 3.94967 13.0243L8.07446 8.89949L3.94967 4.7747C3.72186 4.5469 3.72186 4.17755 3.94967 3.94975C4.17747 3.72194 4.54682 3.72194 4.77463 3.94975L8.89941 8.07454L13.0242 3.94975C13.252 3.72194 13.6214 3.72194 13.8492 3.94975Z" fill="#9E9FA8"/>
  </svg>`,
};
