import { resolveComponent as _resolveComponent, normalizeClass as _normalizeClass, createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_SharedBack = _resolveComponent("SharedBack")!
  const _component_SharedCountry = _resolveComponent("SharedCountry")!
  const _component_SharedValidate = _resolveComponent("SharedValidate")!
  const _component_UIInput = _resolveComponent("UIInput")!
  const _component_SharedDate = _resolveComponent("SharedDate")!
  const _component_SharedRadio = _resolveComponent("SharedRadio")!
  const _component_UIButton = _resolveComponent("UIButton")!
  const _component_SharedCard = _resolveComponent("SharedCard")!

  return (_openBlock(), _createBlock(_component_SharedCard, {
    class: _normalizeClass(_ctx.b())
  }, {
    default: _withCtx(() => [
      _createElementVNode("div", {
        class: _normalizeClass(_ctx.b('header'))
      }, [
        _createVNode(_component_SharedBack, {
          class: _normalizeClass(_ctx.b('header-back')),
          onClick: _ctx.handleBack
        }, null, 8, ["class", "onClick"]),
        _createElementVNode("div", {
          class: _normalizeClass(_ctx.b('header-group'))
        }, [
          _createElementVNode("h3", {
            class: _normalizeClass(_ctx.b('header-title'))
          }, _toDisplayString(_ctx.$t("Основная информация об организации")), 3),
          _createElementVNode("p", {
            class: _normalizeClass(_ctx.b('header-label'))
          }, _toDisplayString(_ctx.$t(
              "Ответьте на следующие вопросы, чтобы получить список необходимых документов"
            )), 3)
        ], 2)
      ], 2),
      _createElementVNode("div", {
        class: _normalizeClass(_ctx.b('form'))
      }, [
        _createVNode(_component_SharedValidate, {
          field: "country",
          value: _ctx.kybBasicInfo.country,
          "is-validate": _ctx.isValidate,
          "field-value": _ctx.kybBasicInfoFields.country,
          "onUpdate:fieldValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.kybBasicInfoFields.country) = $event))
        }, {
          default: _withCtx(() => [
            _createElementVNode("div", {
              class: _normalizeClass(_ctx.b('form-group'))
            }, [
              _createElementVNode("h5", {
                class: _normalizeClass(_ctx.b('form-title'))
              }, _toDisplayString(_ctx.$t("В какой стране зарегистрирована ваша организация?*")), 3),
              _createVNode(_component_SharedCountry, {
                placeholder: "Выберите страну",
                "is-readonly": _ctx.isReadonly,
                "is-error": _ctx.isValidate && !_ctx.kybBasicInfoFields.country,
                value: _ctx.kybBasicInfo.country,
                "onUpdate:value": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.kybBasicInfo.country) = $event))
              }, null, 8, ["is-readonly", "is-error", "value"])
            ], 2)
          ]),
          _: 1
        }, 8, ["value", "is-validate", "field-value"]),
        _createElementVNode("div", {
          class: _normalizeClass(_ctx.b('form-block'))
        }, [
          _createVNode(_component_SharedValidate, {
            class: _normalizeClass(_ctx.b('form-group')),
            field: "organisation",
            value: _ctx.kybBasicInfo.organisation,
            "is-validate": _ctx.isValidate,
            "field-value": _ctx.kybBasicInfoFields.organisation,
            "onUpdate:fieldValue": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.kybBasicInfoFields.organisation) = $event))
          }, {
            default: _withCtx(() => [
              _createElementVNode("div", null, [
                _createElementVNode("h5", {
                  class: _normalizeClass(_ctx.b('form-title'))
                }, _toDisplayString(_ctx.$t("Как называется ваша организация?*")), 3),
                _createVNode(_component_UIInput, {
                  view: "light",
                  "is-readonly": _ctx.isReadonly,
                  "is-error": _ctx.isValidate && !_ctx.kybBasicInfoFields.organisation,
                  modelValue: _ctx.kybBasicInfo.organisation,
                  "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.kybBasicInfo.organisation) = $event))
                }, null, 8, ["is-readonly", "is-error", "modelValue"])
              ])
            ]),
            _: 1
          }, 8, ["class", "value", "is-validate", "field-value"]),
          _createElementVNode("p", {
            class: _normalizeClass(_ctx.b('form-label'))
          }, _toDisplayString(_ctx.$t(
              "Укажите полное юридическое название вашей организации в соответствии с Меморандумом и Уставом/Конституцией/Положениями."
            )), 3)
        ], 2),
        _createElementVNode("div", {
          class: _normalizeClass(_ctx.b('form-block'))
        }, [
          _createVNode(_component_SharedValidate, {
            class: _normalizeClass(_ctx.b('form-group')),
            field: "regional_number",
            value: _ctx.kybBasicInfo.regional_number,
            "is-validate": _ctx.isValidate,
            "field-value": _ctx.kybBasicInfoFields.regional_number,
            "onUpdate:fieldValue": _cache[5] || (_cache[5] = ($event: any) => ((_ctx.kybBasicInfoFields.regional_number) = $event))
          }, {
            default: _withCtx(() => [
              _createElementVNode("div", null, [
                _createElementVNode("h5", {
                  class: _normalizeClass(_ctx.b('form-title'))
                }, _toDisplayString(_ctx.$t("Какой у вас регистрационный номер?*")), 3),
                _createVNode(_component_UIInput, {
                  view: "light",
                  "is-readonly": _ctx.isReadonly,
                  "is-error": _ctx.isValidate && !_ctx.kybBasicInfoFields.regional_number,
                  modelValue: _ctx.kybBasicInfo.regional_number,
                  "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => ((_ctx.kybBasicInfo.regional_number) = $event))
                }, null, 8, ["is-readonly", "is-error", "modelValue"])
              ])
            ]),
            _: 1
          }, 8, ["class", "value", "is-validate", "field-value"]),
          _createElementVNode("p", {
            class: _normalizeClass(_ctx.b('form-label'))
          }, _toDisplayString(_ctx.$t(
              "Введите регистрационный номер вашей компании. Мы используем этот номер для получения общедоступной информации о вашем бизнесе."
            )), 3)
        ], 2),
        _createVNode(_component_SharedValidate, {
          field: "registration_date",
          value: _ctx.kybBasicInfo.registration_date,
          "is-validate": _ctx.isValidate,
          "field-value": _ctx.kybBasicInfoFields.registration_date,
          "onUpdate:fieldValue": _cache[7] || (_cache[7] = ($event: any) => ((_ctx.kybBasicInfoFields.registration_date) = $event))
        }, {
          default: _withCtx(() => [
            _createElementVNode("div", {
              class: _normalizeClass(_ctx.b('form-group'))
            }, [
              _createElementVNode("h5", {
                class: _normalizeClass(_ctx.b('form-title'))
              }, _toDisplayString(_ctx.$t("Дата регистрации*")), 3),
              _createVNode(_component_SharedDate, {
                format: "yyyy-MM-dd",
                "is-readonly": _ctx.isReadonly,
                "is-error": _ctx.isValidate && !_ctx.kybBasicInfoFields.registration_date,
                date: _ctx.kybBasicInfo.registration_date,
                "onUpdate:date": _cache[6] || (_cache[6] = ($event: any) => ((_ctx.kybBasicInfo.registration_date) = $event))
              }, null, 8, ["is-readonly", "is-error", "date"])
            ], 2)
          ]),
          _: 1
        }, 8, ["value", "is-validate", "field-value"]),
        _createVNode(_component_SharedValidate, {
          field: "inn",
          value: _ctx.kybBasicInfo.inn,
          "is-validate": _ctx.isValidate,
          "field-value": _ctx.kybBasicInfoFields.inn,
          "onUpdate:fieldValue": _cache[9] || (_cache[9] = ($event: any) => ((_ctx.kybBasicInfoFields.inn) = $event))
        }, {
          default: _withCtx(() => [
            _createElementVNode("div", {
              class: _normalizeClass(_ctx.b('form-group'))
            }, [
              _createElementVNode("h5", {
                class: _normalizeClass(_ctx.b('form-title'))
              }, _toDisplayString(_ctx.$t("Номер Налогоплательщика*")), 3),
              _createVNode(_component_UIInput, {
                view: "light",
                "is-readonly": _ctx.isReadonly,
                "is-error": _ctx.isValidate && !_ctx.kybBasicInfoFields.inn,
                modelValue: _ctx.kybBasicInfo.inn,
                "onUpdate:modelValue": _cache[8] || (_cache[8] = ($event: any) => ((_ctx.kybBasicInfo.inn) = $event))
              }, null, 8, ["is-readonly", "is-error", "modelValue"])
            ], 2)
          ]),
          _: 1
        }, 8, ["value", "is-validate", "field-value"]),
        _createVNode(_component_SharedValidate, {
          field: "licensed",
          value: _ctx.kybBasicInfo.licensed,
          "is-validate": _ctx.isValidate,
          "field-value": _ctx.kybBasicInfoFields.licensed,
          "onUpdate:fieldValue": _cache[12] || (_cache[12] = ($event: any) => ((_ctx.kybBasicInfoFields.licensed) = $event))
        }, {
          default: _withCtx(() => [
            _createElementVNode("div", {
              class: _normalizeClass(_ctx.b('form-group'))
            }, [
              _createElementVNode("h5", {
                class: _normalizeClass(_ctx.b('form-title'))
              }, _toDisplayString(_ctx.$t("Ваша деятельность лицензируется?*")), 3),
              _createElementVNode("div", {
                class: _normalizeClass(_ctx.b('form-radios'))
              }, [
                _createElementVNode("div", {
                  class: _normalizeClass(_ctx.b('form-radio'))
                }, [
                  _createVNode(_component_SharedRadio, {
                    view: "light",
                    "checked-value": true,
                    "is-readonly": _ctx.isReadonly,
                    value: _ctx.kybBasicInfo.licensed,
                    "onUpdate:value": _cache[10] || (_cache[10] = ($event: any) => ((_ctx.kybBasicInfo.licensed) = $event))
                  }, null, 8, ["is-readonly", "value"]),
                  _createElementVNode("p", {
                    class: _normalizeClass(_ctx.b('form-radio-label'))
                  }, _toDisplayString(_ctx.$t("Да")), 3)
                ], 2),
                _createElementVNode("div", {
                  class: _normalizeClass(_ctx.b('form-radio'))
                }, [
                  _createVNode(_component_SharedRadio, {
                    view: "light",
                    "checked-value": false,
                    "is-readonly": _ctx.isReadonly,
                    value: _ctx.kybBasicInfo.licensed,
                    "onUpdate:value": _cache[11] || (_cache[11] = ($event: any) => ((_ctx.kybBasicInfo.licensed) = $event))
                  }, null, 8, ["is-readonly", "value"]),
                  _createElementVNode("p", {
                    class: _normalizeClass(_ctx.b('form-radio-label'))
                  }, _toDisplayString(_ctx.$t("Нет")), 3)
                ], 2)
              ], 2)
            ], 2)
          ]),
          _: 1
        }, 8, ["value", "is-validate", "field-value"]),
        _createVNode(_component_SharedValidate, {
          field: "telegram",
          value: _ctx.kybBasicInfo.telegram,
          "is-validate": _ctx.isValidate,
          "field-value": _ctx.kybBasicInfoFields.telegram,
          "onUpdate:fieldValue": _cache[14] || (_cache[14] = ($event: any) => ((_ctx.kybBasicInfoFields.telegram) = $event))
        }, {
          default: _withCtx(() => [
            _createElementVNode("div", {
              class: _normalizeClass(_ctx.b('form-group'))
            }, [
              _createElementVNode("h5", {
                class: _normalizeClass(_ctx.b('form-title'))
              }, _toDisplayString(_ctx.$t("Телеграм для связи*")), 3),
              _createVNode(_component_UIInput, {
                view: "light",
                "is-telegram": true,
                "is-readonly": _ctx.isReadonly,
                "is-error": _ctx.isValidate && !_ctx.kybBasicInfoFields.telegram,
                modelValue: _ctx.kybBasicInfo.telegram,
                "onUpdate:modelValue": _cache[13] || (_cache[13] = ($event: any) => ((_ctx.kybBasicInfo.telegram) = $event))
              }, null, 8, ["is-readonly", "is-error", "modelValue"])
            ], 2)
          ]),
          _: 1
        }, 8, ["value", "is-validate", "field-value"])
      ], 2),
      _createVNode(_component_UIButton, {
        class: _normalizeClass(_ctx.b('continue')),
        label: "Продолжить",
        view: "main",
        disabled: _ctx.isContinueDisabled,
        onClick: _ctx.handleContinue
      }, null, 8, ["class", "disabled", "onClick"])
    ]),
    _: 1
  }, 8, ["class"]))
}