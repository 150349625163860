import { normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, vShow as _vShow, withDirectives as _withDirectives, toDisplayString as _toDisplayString, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveDirective as _resolveDirective } from "vue"

const _hoisted_1 = ["innerHTML"]
const _hoisted_2 = ["value"]
const _hoisted_3 = ["innerHTML"]
const _hoisted_4 = ["innerHTML"]
const _hoisted_5 = ["onClick"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _directive_click_outside = _resolveDirective("click-outside")!

  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(_ctx.b())
  }, [
    _createElementVNode("div", {
      class: _normalizeClass(_ctx.b('search'))
    }, [
      _createElementVNode("span", {
        class: _normalizeClass(_ctx.b('search-icon', { search: true })),
        innerHTML: _ctx.displayedIcons.search
      }, null, 10, _hoisted_1),
      _createElementVNode("input", {
        class: _normalizeClass(_ctx.b('search-field')),
        type: "text",
        value: _ctx.value,
        onInput: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.handleValue && _ctx.handleValue(...args)))
      }, null, 42, _hoisted_2),
      _withDirectives(_createElementVNode("button", {
        class: _normalizeClass(_ctx.b('search-clear')),
        onClick: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_ctx.handleClear && _ctx.handleClear(...args)))
      }, [
        _createElementVNode("span", {
          class: _normalizeClass(_ctx.b('search-icon')),
          innerHTML: _ctx.displayedIcons.searchClear
        }, null, 10, _hoisted_3)
      ], 2), [
        [_vShow, _ctx.isClearShow]
      ])
    ], 2),
    _withDirectives((_openBlock(), _createElementBlock("div", {
      class: _normalizeClass(_ctx.b('select'))
    }, [
      _createElementVNode("button", {
        class: _normalizeClass(_ctx.b('select-toggle', { active: _ctx.isToggle })),
        onClick: _cache[2] || (_cache[2] = 
//@ts-ignore
(...args) => (_ctx.handleToggle && _ctx.handleToggle(...args)))
      }, [
        _createElementVNode("span", {
          class: _normalizeClass(_ctx.b('select-icon')),
          innerHTML: _ctx.displayedIcons.dots
        }, null, 10, _hoisted_4)
      ], 2),
      _createElementVNode("div", {
        class: _normalizeClass(_ctx.b('select-options', { active: _ctx.isToggle }))
      }, [
        _createElementVNode("h4", {
          class: _normalizeClass(_ctx.b('select-title'))
        }, _toDisplayString(_ctx.$t("Поиск по данным:")), 3),
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.displayedOptions, (option) => {
          return (_openBlock(), _createElementBlock("button", {
            class: _normalizeClass(_ctx.b('select-option', { active: option.isActive })),
            key: option.id,
            onClick: ($event: any) => (_ctx.handleOption(option.value))
          }, _toDisplayString(_ctx.$t(option.label)), 11, _hoisted_5))
        }), 128))
      ], 2)
    ], 2)), [
      [_directive_click_outside, _ctx.handleHide]
    ])
  ], 2))
}