import { defineComponent, PropType } from "vue";

import { COMPONENT_NAME } from "./attributes";

import {
  SharedCard,
  SharedBack,
  SharedRadio,
  SharedCountry,
  SharedValidate,
} from "@/components/shared";
import { UIButton, UIInput } from "@/components/ui";

import { VerificationKybComponent } from "@/views/verification/model";
import {
  KybRegistrationInfoFieldsFactory,
  KybRegistrationInfoRequestFactory,
  KybRegistrationInfoResponseFactory,
} from "@/shared/repository/modules/kyb/factory";

export default defineComponent({
  name: COMPONENT_NAME,
  components: {
    SharedCard,
    SharedBack,
    SharedRadio,
    SharedCountry,
    SharedValidate,
    UIButton,
    UIInput,
  },
  props: {
    component: {
      type: <PropType<VerificationKybComponent>>String,
      default: "",
    },
  },
  emits: {
    "update:component": null,
  },
  data() {
    return {
      isValidate: false,
      isLoading: false,
      userRepo: this.$projectServices.userRepo,
      kybRepository: this.$projectServices.kybRepository,
      kybRegistrationInfo: KybRegistrationInfoResponseFactory(),
      kybRegistrationInfoFields: KybRegistrationInfoFieldsFactory(),
    };
  },
  async created(): Promise<void> {
    try {
      this.isLoading = true;

      this.kybRegistrationInfo = await this.kybRepository.registrationInfo();
    } catch (e) {
      console.log(e);
    } finally {
      this.isLoading = false;
    }
  },
  computed: {
    isReadonly(): boolean {
      return this.userRepo.isReadonlyKyb;
    },

    isDisabled(): boolean {
      const { organisation_legal_form, business_area, phone, address } =
        this.kybRegistrationInfo;

      const defaultDisabled =
        !organisation_legal_form ||
        !business_area ||
        !phone ||
        !address.country ||
        !address.city ||
        !address.state ||
        !address.office ||
        !address.zip;

      if (address.isActual) {
        return defaultDisabled;
      }

      return (
        defaultDisabled ||
        !address.country_actual ||
        !address.city_actual ||
        !address.state_actual ||
        !address.office_actual ||
        !address.zip_actual
      );
    },

    isContinueDisabled(): boolean {
      return this.isLoading;
    },
  },
  methods: {
    handleBack(): void {
      this.$emit(
        "update:component",
        VerificationKybComponent.kybBasicInformation
      );
    },

    async handleContinue(): Promise<void> {
      try {
        this.isLoading = true;

        if (this.isDisabled) {
          this.isValidate = true;

          return;
        }

        if (this.kybRegistrationInfo.address.isActual) {
          this.kybRegistrationInfo = KybRegistrationInfoResponseFactory({
            ...this.kybRegistrationInfo,
            address: {
              ...this.kybRegistrationInfo.address,
              country_actual: "",
              city_actual: "",
              state_actual: "",
              street_actual: "",
              office_actual: "",
              zip_actual: "",
            },
          });
        }

        if (!this.isReadonly) {
          await this.kybRepository.registrationInfoUpdate(
            KybRegistrationInfoRequestFactory(this.kybRegistrationInfo)
          );
        }

        this.$emit("update:component", VerificationKybComponent.kybCompany);
      } catch (e) {
        console.log(e);
      } finally {
        this.isLoading = false;
      }
    },
  },
});
