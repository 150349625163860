import { normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = ["innerHTML"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(_ctx.b())
  }, [
    _createElementVNode("span", {
      class: _normalizeClass(_ctx.b('icon')),
      innerHTML: _ctx.displayedIcon
    }, null, 10, _hoisted_1),
    _createElementVNode("div", {
      class: _normalizeClass(_ctx.b('info'))
    }, [
      _createElementVNode("h4", {
        class: _normalizeClass(_ctx.b('title'))
      }, _toDisplayString(_ctx.$t(_ctx.displayedTitle)), 3),
      _createElementVNode("p", {
        class: _normalizeClass(_ctx.b('label'))
      }, _toDisplayString(_ctx.$t(_ctx.displayedLabel)), 3)
    ], 2)
  ], 2))
}