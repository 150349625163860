import { defineComponent } from "vue";

import { COMPONENT_NAME } from "./attributes";
import { AdminConfigCurrencyRow } from "./row";
import { AdminConfigCurrencyModal } from "./modal";

import {
  AdminCurrencyCryptoResponse,
  AdminCurrencyFiatResponse,
  AdminCurrencyListResponse,
} from "@/shared/repository/modules/admin/currency/repo";

interface AdminConfigCurrencyTable {
  title: string;
  rows: AdminCurrencyFiatResponse[] | AdminCurrencyCryptoResponse[];
}

export default defineComponent({
  name: COMPONENT_NAME,
  components: {
    AdminConfigCurrencyRow,
    AdminConfigCurrencyModal,
  },
  data() {
    return {
      adminCurrencyRepository: this.$projectServices.adminCurrencyRepository,
      adminCurrencyRepo: this.$projectServices.adminCurrencyRepo,
    };
  },
  async created(): Promise<void> {
    try {
      await this.adminCurrencyRepo.updateList();
    } catch (error) {
      console.log(error);
    }
  },
  computed: {
    displayedList(): AdminCurrencyListResponse {
      return this.adminCurrencyRepo.list;
    },

    displayedTable(): AdminConfigCurrencyTable[] {
      return [
        {
          title: "Bank Account",
          rows: this.displayedList.fiat.filter((fiat) => !fiat.frozen),
        },
        {
          title: "Crypto Account",
          rows: this.displayedList.crypto.filter((crypto) => !crypto.frozen),
        },
      ];
    },

    isModalShow(): boolean {
      return this.adminCurrencyRepo.modal.isShow;
    },
  },
});
