import { defineComponent } from "vue";

import { COMPONENT_NAME, SVG } from "./attributes";
import { SvgAttribute } from "@/shared/constants/constants";

export default defineComponent({
  name: COMPONENT_NAME,
  props: {
    isSuccess: {
      type: Boolean,
      default: false,
    },
    isConfirmed: {
      type: Boolean,
      default: false,
    },
    isExpected: {
      type: Boolean,
      default: false,
    },
    isRemove: {
      type: Boolean,
      default: true,
    },
    isReadonly: {
      type: Boolean,
      default: false,
    },
    isLoading: {
      type: Boolean,
      default: false,
    },
  },
  emits: {
    remove: null,
  },
  computed: {
    displayedIcons(): SvgAttribute {
      return SVG;
    },

    displayedIcon(): string {
      if (this.isConfirmed) {
        return SVG.confirmed;
      } else if (this.isExpected) {
        return SVG.expected;
      }

      return SVG.plus;
    },

    isIconShow(): boolean {
      return !this.isSuccess;
    },

    isTitleShow(): boolean {
      return this.isSuccess || this.isConfirmed;
    },

    isLabelShow(): boolean {
      return !this.isSuccess && !this.isConfirmed;
    },

    isRemoveShow(): boolean {
      return (
        (this.isSuccess || this.isConfirmed || this.isExpected) &&
        this.isRemove &&
        !this.isReadonly
      );
    },

    isRemoveDisabled(): boolean {
      return this.isLoading;
    },

    isDisabled(): boolean {
      return this.isLoading;
    },
  },
  methods: {
    handleRemove(): void {
      this.$emit("remove");
    },
  },
});
