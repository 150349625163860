import { defineComponent, PropType } from "vue";

import { COMPONENT_NAME } from "./attributes";

import { SharedSelect } from "@/components/shared";
import { UIInput, UIButton } from "@/components/ui";

import { SharedSelectOption } from "@/components/shared/select/model";
import {
  ApplicationStatus,
  ApplicationType,
  Language,
} from "@/shared/constants/enums";
import {
  AdminUserAppStatusFactory,
  AdminUserAppStatusRequestFactory,
} from "@/shared/repository/modules/admin/user-app/factory";
import { AdminUsersStatusOptions } from "@/data/admin/status/status";
import {
  ModalActionLabel,
  ModalIcon,
  ModalLabel,
  ModalTitle,
} from "@/shared/constants/constants";
import { AdminUsersResponse } from "@/shared/repository/modules/admin/users/repo";

enum SaveLabel {
  "Сохранить" = "Сохранить",
  "Сохранено" = "Сохранено",
}

export default defineComponent({
  name: COMPONENT_NAME,
  components: {
    SharedSelect,
    UIInput,
    UIButton,
  },
  props: {
    id: {
      type: Number,
      required: true,
    },
    status: {
      type: <PropType<ApplicationStatus>>String,
      required: true,
    },
    type: {
      type: <PropType<ApplicationType>>String,
      required: true,
    },
    isLoading: {
      type: Boolean,
      default: false,
    },
  },
  emits: {
    "update:status": null,
    "update:isLoading": null,
  },
  data() {
    return {
      adminUserAppStatus: AdminUserAppStatusFactory(),
      modalRepo: this.$projectServices.modalRepo,
      usersRepo: this.$projectServices.usersRepo,
      adminUsersRepository: this.$projectServices.adminUsersRepository,
      adminUserAppRepository: this.$projectServices.adminUserAppRepository,
    };
  },
  created(): void {
    this.adminUserAppStatus = AdminUserAppStatusFactory({
      id: this.id,
      status: this.status,
      type: this.type,
      lang: this.displayedUser?.lang,
      messageRu: "",
      messageEn: "",
    });
  },
  computed: {
    displayedOptions(): SharedSelectOption[] {
      return AdminUsersStatusOptions(this.adminUserAppStatus.status);
    },

    displayedSaveLabel(): SaveLabel {
      return this.isSaved ? SaveLabel.Сохранено : SaveLabel.Сохранить;
    },

    displayedUser(): AdminUsersResponse | undefined {
      return this.usersRepo.userByIndex;
    },

    isSaved(): boolean {
      return this.status === this.adminUserAppStatus.status;
    },

    isSaveDisabled(): boolean {
      const { status, messageRu, messageEn, lang } = this.adminUserAppStatus;

      if (this.isSaved) return true;

      if (status === ApplicationStatus.reject) {
        return lang === Language.ru
          ? !this.id || !messageRu || this.isLoading
          : !this.id || !messageEn || this.isLoading;
      } else if (status === ApplicationStatus.completed) {
        return !this.id || this.isLoading;
      }

      return (
        !this.id ||
        this.isLoading ||
        status === ApplicationStatus.pending ||
        status === ApplicationStatus.waiting ||
        !status
      );
    },
  },
  methods: {
    handleChangeStatus(status: ApplicationStatus): void {
      this.adminUserAppStatus.status = status;
    },

    async handleSave(): Promise<void> {
      try {
        this.$emit("update:isLoading", true);

        await this.adminUserAppRepository.status(
          AdminUserAppStatusRequestFactory(this.adminUserAppStatus)
        );

        this.usersRepo.UPDATE_USERS(await this.adminUsersRepository.users());

        this.modalRepo.UPDATE_MODAL({
          title: ModalTitle.adminStatusUserUpdateSuccess,
          label: ModalLabel.default,
          icon: ModalIcon.adminStatusUdate,
          actionLabel: ModalActionLabel.ok,
          callback: () => {},
        });

        this.$emit("update:status", this.adminUserAppStatus.status);
      } catch (e) {
        this.modalRepo.UPDATE_MODAL({
          title: ModalTitle.adminStatusUserUpdateError,
          label: ModalLabel.default,
          icon: ModalIcon.adminStatusUdate,
          actionLabel: ModalActionLabel.ok,
          callback: () => {},
        });
      } finally {
        this.$emit("update:isLoading", false);
      }
    },
  },
});
