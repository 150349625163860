import { defineComponent } from "vue";

import { PAGE_NAME } from "./attributes";
import { ApplicationComponent } from "./model";

import {
  ApplicationForm,
  ApplicationCrypto,
  ApplicationResult,
  ApplicationConfirm,
} from "@/components/pages/application";
import { WalletFilterType } from "@/shared/repository/modules/wallet/enums";
import { WalletWithAmountResponse } from "@/shared/repository/modules/wallet/repo";

type Component = typeof ApplicationForm | typeof ApplicationCrypto;

export default defineComponent({
  name: PAGE_NAME,
  components: {
    ApplicationForm,
    ApplicationCrypto,
    ApplicationResult,
    ApplicationConfirm,
  },
  data() {
    return {
      isResult: false,
      isConfirm: false,
      isLoading: false,
      component: ApplicationComponent.form,
      walletWithAmount: <WalletWithAmountResponse[]>[],
      walletRepository: this.$projectServices.walletRepository,
    };
  },
  async created(): Promise<void> {
    try {
      this.isLoading = true;

      this.walletWithAmount = await this.walletRepository.withAmount(
        WalletFilterType.all
      );
    } catch (error) {
      console.log(error);
    } finally {
      this.isLoading = false;
    }
  },
  computed: {
    displayedComponent(): Component {
      switch (this.component) {
        case ApplicationComponent.form:
          return ApplicationForm;
        case ApplicationComponent.crypto:
          return ApplicationCrypto;
        default:
          return ApplicationForm;
      }
    },
  },
});
