<script lang="ts" src="./component.ts"></script>
<style lang="scss" src="./component.scss"></style>

<template>
  <SharedWindow :class="b()" title="Фильтр" @close="handleClose">
    <div :class="b('wrapper')">
      <div :class="b('form')">
        <!-- <div :class="b('group')">
          <p :class="b('label')">
            {{ $t("Валюта") }}
          </p>

          <SharedMultiSelect
            :placeholder="$t('Все')"
            :options="displayedCurrencyOptions"
            :values="displayedFilter.currency_id"
            @update:values="handleCurrency"
          />
        </div> -->

        <div :class="b('block')">
          <div :class="b('group')">
            <p :class="b('label')">
              {{ $t("Объем от") }}
            </p>

            <UIInput
              view="light"
              :is-number="true"
              :model-value="displayedFilter.amount_from"
              @update:modelValue="handleAmountFrom"
            />
          </div>

          <div :class="b('group')">
            <p :class="b('label')">
              {{ $t("до") }}
            </p>

            <UIInput
              view="light"
              :is-number="true"
              :model-value="displayedFilter.amount_to"
              @update:modelValue="handleAmountTo"
            />
          </div>
        </div>

        <div :class="b('block')">
          <div :class="b('group')">
            <p :class="b('label')">
              {{ $t("Дата/Период от") }}
            </p>

            <SharedDate
              format="yyyy-MM-dd"
              :date="displayedFilter.date_from"
              @update:date="handleDateFrom"
            />
          </div>

          <div :class="b('group')">
            <p :class="b('label')">
              {{ $t("до") }}
            </p>

            <SharedDate
              format="yyyy-MM-dd"
              :date="displayedFilter.date_to"
              @update:date="handleDateTo"
            />
          </div>
        </div>

        <div :class="b('group')">
          <p :class="b('label')">
            {{ $t("Статус") }}
          </p>

          <SharedSelect
            view="light"
            :options="displayedStatusOptions"
            :value="displayedFilter.status"
            @update:value="handleStatus"
          />
        </div>
      </div>

      <UIButton
        label="Применить фильтр"
        view="main"
        :disabled="isDisabled"
        @click="handleApply"
      />
    </div>
  </SharedWindow>
</template>
