import { defineComponent } from "vue";

import { COMPONENT_NAME, SVG } from "./attributes";

import { SvgAttribute } from "@/shared/constants/constants";

export default defineComponent({
  name: COMPONENT_NAME,
  props: {
    label: {
      type: String,
      default: "",
    },
    value: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      isCopy: false,
    };
  },
  computed: {
    displayedSvg(): SvgAttribute {
      return SVG;
    },
  },
  methods: {
    handleCopy(): void {
      const textarea = document.createElement("textarea");
      textarea.value = this.value;
      document.body.appendChild(textarea);
      textarea.select();
      document.execCommand("copy");
      document.body.removeChild(textarea);

      this.isCopy = true;

      setTimeout(() => {
        this.isCopy = false;
      }, 2000);
    },
  },
});
