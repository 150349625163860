import { defineComponent } from "vue";

import { COMPONENT_NAME } from "./attributes";

import { SharedWindow, SharedSelect } from "@/components/shared";
import { UIInput, UIButton } from "@/components/ui";

export default defineComponent({
  name: COMPONENT_NAME,
  components: {
    SharedWindow,
    SharedSelect,
    UIInput,
    UIButton,
  },
  props: {
    isBalance: {
      type: Boolean,
      default: false,
    },
  },
  emits: {
    "update:isBalance": null,
  },
  data() {
    return {
      isLoading: false,
    };
  },
  computed: {
    isChangeBalanceDisabled(): boolean {
      return this.isLoading;
    },
  },
  methods: {
    handleClose(): void {
      this.$emit("update:isBalance", false);
    },

    async handleChangeBalance(): Promise<void> {
      try {
        this.isLoading = true;
      } catch (error) {
        console.log(error);
      } finally {
        this.isLoading = false;
      }
    },
  },
});
