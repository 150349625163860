import { AdminDepositWithdrawalStatus } from "./enums";

export enum AdminDepositWithdrawalApiRoutes {
  update = "/api/v1/admin/deposit-withdrawal/app/update",
  delete = "/api/v1/admin/deposit-withdrawal/app/delete",
  show = "/api/v1/admin/deposit-withdrawal/app/show",
  list = "/api/v1/admin/deposit-withdrawal/app/list",
}

export interface AdminDepositWithdrawalProjectUrlGenerator {
  update(status: AdminDepositWithdrawalStatus): string;
  delete(id: number): string;
  show(id: number): string;
  list(): string;
}

export class AdminDepositWithdrawalUrlGenerator
  implements AdminDepositWithdrawalProjectUrlGenerator
{
  update(status: AdminDepositWithdrawalStatus): string {
    return `${AdminDepositWithdrawalApiRoutes.update}?status=${status}`;
  }

  delete(id: number): string {
    return `${AdminDepositWithdrawalApiRoutes.delete}?id=${id}`;
  }

  show(id: number): string {
    return `${AdminDepositWithdrawalApiRoutes.show}?id=${id}`;
  }

  list(): string {
    return AdminDepositWithdrawalApiRoutes.list;
  }
}
