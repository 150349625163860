import { normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, normalizeStyle as _normalizeStyle, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, createTextVNode as _createTextVNode, resolveDirective as _resolveDirective, withDirectives as _withDirectives } from "vue"

const _hoisted_1 = ["placeholder", "value"]
const _hoisted_2 = ["disabled"]
const _hoisted_3 = ["innerHTML"]
const _hoisted_4 = ["onClick"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _directive_click_outside = _resolveDirective("click-outside")!

  return _withDirectives((_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(_ctx.b())
  }, [
    _createElementVNode("div", {
      class: _normalizeClass(_ctx.b('group', { error: _ctx.isError }))
    }, [
      (!_ctx.value)
        ? (_openBlock(), _createElementBlock("input", {
            key: 0,
            class: _normalizeClass(_ctx.b('field')),
            type: "text",
            placeholder: _ctx.$t(_ctx.placeholder),
            value: _ctx.search,
            onInput: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.handleSearch && _ctx.handleSearch(...args)))
          }, null, 42, _hoisted_1))
        : (_openBlock(), _createElementBlock("button", {
            key: 1,
            ref: "toggle",
            class: _normalizeClass(_ctx.b('toggle')),
            disabled: _ctx.isReadonly,
            onClick: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_ctx.handleChangeVisibility && _ctx.handleChangeVisibility(...args)))
          }, [
            (_ctx.value)
              ? (_openBlock(), _createElementBlock("span", {
                  key: 0,
                  class: _normalizeClass(['fi', `fi-${_ctx.displayedCountry}`])
                }, null, 2))
              : _createCommentVNode("", true),
            _createElementVNode("span", {
              class: _normalizeClass(_ctx.b('toggle-label')),
              ref: "toggleLabel",
              style: _normalizeStyle({ maxWidth: _ctx.displayedToggleLabelMaxWidth })
            }, _toDisplayString(_ctx.value), 7)
          ], 10, _hoisted_2)),
      _createElementVNode("span", {
        class: _normalizeClass(_ctx.b('group-icon', { active: _ctx.isVisible, disabled: _ctx.isReadonly })),
        innerHTML: _ctx.displayedIcons.chevron,
        onClick: _cache[2] || (_cache[2] = 
//@ts-ignore
(...args) => (_ctx.handleChangeVisibility && _ctx.handleChangeVisibility(...args)))
      }, null, 10, _hoisted_3)
    ], 2),
    _createElementVNode("div", {
      class: _normalizeClass(_ctx.b('options', { visible: _ctx.isVisible }))
    }, [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.displayedOptions, (option) => {
        return (_openBlock(), _createElementBlock("button", {
          class: _normalizeClass(_ctx.b('option', { active: option.isActive })),
          key: option.value,
          onClick: ($event: any) => (_ctx.handleOption(option))
        }, [
          _createElementVNode("span", {
            class: _normalizeClass(['fi', `fi-${option.icon}`])
          }, null, 2),
          _createTextVNode(" " + _toDisplayString(option.label), 1)
        ], 10, _hoisted_4))
      }), 128))
    ], 2)
  ], 2)), [
    [_directive_click_outside, _ctx.handleHide]
  ])
}