import { defineComponent } from "vue";

import { COMPONENT_NAME } from "./attributes";

import { AdminToolbar, AdminContent } from "@/components/layouts/admin";

export default defineComponent({
  name: COMPONENT_NAME,
  components: {
    AdminToolbar,
    AdminContent,
  },
  data() {
    return {
      usersRepo: this.$projectServices.usersRepo,
      adminUsersRepository: this.$projectServices.adminUsersRepository,
    };
  },
  async created() {
    try {
      this.usersRepo.UPDATE_USERS(await this.adminUsersRepository.users());
    } catch (e) {
      console.log(e);
    }
  },
});
