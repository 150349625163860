import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, Transition as _Transition } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_SharedBack = _resolveComponent("SharedBack")!
  const _component_UIInput = _resolveComponent("UIInput")!
  const _component_SharedValidate = _resolveComponent("SharedValidate")!
  const _component_UIButton = _resolveComponent("UIButton")!
  const _component_SettingsRequisitesVerification = _resolveComponent("SettingsRequisitesVerification")!
  const _component_SharedCard = _resolveComponent("SharedCard")!

  return (_openBlock(), _createBlock(_component_SharedCard, {
    class: _normalizeClass(_ctx.b())
  }, {
    default: _withCtx(() => [
      _createElementVNode("div", {
        class: _normalizeClass(_ctx.b('header'))
      }, [
        _createVNode(_component_SharedBack, { onClick: _ctx.handleBack }, null, 8, ["onClick"]),
        _createElementVNode("h3", {
          class: _normalizeClass(_ctx.b('header-title'))
        }, _toDisplayString(_ctx.$t("Крипто реквизиты")), 3)
      ], 2),
      _createElementVNode("div", {
        class: _normalizeClass(_ctx.b('form'))
      }, [
        _createVNode(_component_SharedValidate, {
          field: "name",
          value: _ctx.requisitesCryptoRequest.name,
          "is-validate": _ctx.isValidate,
          "field-value": _ctx.requisitesCryptoFields.name,
          "onUpdate:fieldValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.requisitesCryptoFields.name) = $event))
        }, {
          default: _withCtx(() => [
            _createElementVNode("div", {
              class: _normalizeClass(_ctx.b('form-group'))
            }, [
              _createElementVNode("h5", {
                class: _normalizeClass(_ctx.b('form-title'))
              }, _toDisplayString(_ctx.$t("Название*")), 3),
              _createVNode(_component_UIInput, {
                view: "light",
                modelValue: _ctx.requisitesCryptoRequest.name,
                "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.requisitesCryptoRequest.name) = $event))
              }, null, 8, ["modelValue"])
            ], 2)
          ]),
          _: 1
        }, 8, ["value", "is-validate", "field-value"]),
        _createElementVNode("div", {
          class: _normalizeClass(_ctx.b('form-group'))
        }, null, 2),
        _createElementVNode("div", {
          class: _normalizeClass(_ctx.b('form-group'))
        }, null, 2),
        _createVNode(_component_SharedValidate, {
          field: "coin",
          value: _ctx.requisitesCryptoRequest.coin,
          "is-validate": _ctx.isValidate,
          "field-value": _ctx.requisitesCryptoFields.coin,
          "onUpdate:fieldValue": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.requisitesCryptoFields.coin) = $event))
        }, {
          default: _withCtx(() => [
            _createElementVNode("div", {
              class: _normalizeClass(_ctx.b('form-group'))
            }, [
              _createElementVNode("h5", {
                class: _normalizeClass(_ctx.b('form-title'))
              }, _toDisplayString(_ctx.$t("Монета*")), 3),
              _createVNode(_component_UIInput, {
                view: "light",
                modelValue: _ctx.requisitesCryptoRequest.coin,
                "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.requisitesCryptoRequest.coin) = $event))
              }, null, 8, ["modelValue"])
            ], 2)
          ]),
          _: 1
        }, 8, ["value", "is-validate", "field-value"]),
        _createVNode(_component_SharedValidate, {
          field: "network",
          value: _ctx.requisitesCryptoRequest.network,
          "is-validate": _ctx.isValidate,
          "field-value": _ctx.requisitesCryptoFields.network,
          "onUpdate:fieldValue": _cache[5] || (_cache[5] = ($event: any) => ((_ctx.requisitesCryptoFields.network) = $event))
        }, {
          default: _withCtx(() => [
            _createElementVNode("div", {
              class: _normalizeClass(_ctx.b('form-group'))
            }, [
              _createElementVNode("h5", {
                class: _normalizeClass(_ctx.b('form-title'))
              }, _toDisplayString(_ctx.$t("Сеть*")), 3),
              _createVNode(_component_UIInput, {
                view: "light",
                modelValue: _ctx.requisitesCryptoRequest.network,
                "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => ((_ctx.requisitesCryptoRequest.network) = $event))
              }, null, 8, ["modelValue"])
            ], 2)
          ]),
          _: 1
        }, 8, ["value", "is-validate", "field-value"]),
        _createVNode(_component_SharedValidate, {
          field: "address",
          value: _ctx.requisitesCryptoRequest.address,
          "is-validate": _ctx.isValidate,
          "field-value": _ctx.requisitesCryptoFields.address,
          "onUpdate:fieldValue": _cache[7] || (_cache[7] = ($event: any) => ((_ctx.requisitesCryptoFields.address) = $event))
        }, {
          default: _withCtx(() => [
            _createElementVNode("div", {
              class: _normalizeClass(_ctx.b('form-group'))
            }, [
              _createElementVNode("h5", {
                class: _normalizeClass(_ctx.b('form-title'))
              }, _toDisplayString(_ctx.$t("Адрес*")), 3),
              _createVNode(_component_UIInput, {
                view: "light",
                modelValue: _ctx.requisitesCryptoRequest.address,
                "onUpdate:modelValue": _cache[6] || (_cache[6] = ($event: any) => ((_ctx.requisitesCryptoRequest.address) = $event))
              }, null, 8, ["modelValue"])
            ], 2)
          ]),
          _: 1
        }, 8, ["value", "is-validate", "field-value"])
      ], 2),
      _createVNode(_component_UIButton, {
        class: _normalizeClass(_ctx.b('confirm')),
        label: "Подтвердить",
        view: "main",
        disabled: _ctx.isContinueDisabled,
        onClick: _ctx.handleContinue
      }, null, 8, ["class", "disabled", "onClick"]),
      _createVNode(_Transition, { name: "opacity" }, {
        default: _withCtx(() => [
          (_ctx.isVerification)
            ? (_openBlock(), _createBlock(_component_SettingsRequisitesVerification, {
                key: 0,
                class: _normalizeClass(_ctx.b('modal')),
                type: "crypto-requisite",
                onClose: _ctx.handleVerificationClose
              }, null, 8, ["class", "onClose"]))
            : _createCommentVNode("", true)
        ]),
        _: 1
      })
    ]),
    _: 1
  }, 8, ["class"]))
}