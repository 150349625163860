<script lang="ts" src="./component.ts"></script>
<style lang="scss" src="./component.scss"></style>

<template>
  <SharedCard :class="b()">
    <div :class="b('header')">
      <SharedBack :class="b('header-back')" @click="handleBack" />

      <div :class="b('header-group')">
        <h3 :class="b('header-title')">
          {{ $t("Регистрационная информация") }}
        </h3>

        <p :class="b('header-label')">
          {{
            $t(
              "Ответьте на следующие вопросы, чтобы получить список необходимых документов"
            )
          }}
        </p>
      </div>
    </div>

    <div :class="b('form')">
      <SharedValidate
        field="organisation_legal_form"
        :value="kybRegistrationInfo.organisation_legal_form"
        :is-validate="isValidate"
        v-model:field-value="kybRegistrationInfoFields.organisation_legal_form"
      >
        <div :class="b('form-group')">
          <h5 :class="b('form-title')">
            {{ $t("Организационно-правовая форма вашей организации*") }}
          </h5>

          <UIInput
            view="light"
            :is-readonly="isReadonly"
            :is-error="
              isValidate && !kybRegistrationInfoFields.organisation_legal_form
            "
            v-model="kybRegistrationInfo.organisation_legal_form"
          />
        </div>
      </SharedValidate>

      <SharedValidate
        field="business_area"
        :value="kybRegistrationInfo.business_area"
        :is-validate="isValidate"
        v-model:field-value="kybRegistrationInfoFields.business_area"
      >
        <div :class="b('form-group')">
          <h5 :class="b('form-title')">
            {{ $t("К какой сфере относится ваш бизнес?*") }}
          </h5>

          <UIInput
            view="light"
            :is-readonly="isReadonly"
            :is-error="isValidate && !kybRegistrationInfoFields.business_area"
            v-model="kybRegistrationInfo.business_area"
          />
        </div>
      </SharedValidate>

      <div :class="b('form-group', { address: true })">
        <h5 :class="b('form-title')">
          {{ $t("Адрес организации*") }}
        </h5>

        <div :class="b('form-block')">
          <SharedValidate
            field="address.country"
            :value="kybRegistrationInfo.address.country"
            :is-validate="isValidate"
            v-model:field-value="kybRegistrationInfoFields['address.country']"
          >
            <SharedCountry
              :class="b('form-country')"
              placeholder="Cтрана"
              :is-readonly="isReadonly"
              :is-error="
                isValidate && !kybRegistrationInfoFields['address.country']
              "
              v-model:value="kybRegistrationInfo.address.country"
            />
          </SharedValidate>

          <SharedValidate
            field="address.city"
            :value="kybRegistrationInfo.address.city"
            :is-validate="isValidate"
            v-model:field-value="kybRegistrationInfoFields['address.city']"
          >
            <UIInput
              :class="b('form-city')"
              placeholder="Город"
              view="light"
              :is-readonly="isReadonly"
              :is-error="
                isValidate && !kybRegistrationInfoFields['address.city']
              "
              v-model="kybRegistrationInfo.address.city"
            />
          </SharedValidate>

          <SharedValidate
            field="address.state"
            :value="kybRegistrationInfo.address.state"
            :is-validate="isValidate"
            v-model:field-value="kybRegistrationInfoFields['address.state']"
          >
            <UIInput
              :class="b('form-state')"
              placeholder="Штат / Область"
              view="light"
              :is-readonly="isReadonly"
              :is-error="
                isValidate && !kybRegistrationInfoFields['address.state']
              "
              v-model="kybRegistrationInfo.address.state"
            />
          </SharedValidate>

          <SharedValidate
            field="address.street"
            :value="kybRegistrationInfo.address.street"
            :is-validate="isValidate"
            v-model:field-value="kybRegistrationInfoFields['address.street']"
          >
            <UIInput
              :class="b('form-street')"
              placeholder="Улица"
              view="light"
              :is-readonly="isReadonly"
              :is-error="
                isValidate && !kybRegistrationInfoFields['address.street']
              "
              v-model="kybRegistrationInfo.address.street"
            />
          </SharedValidate>

          <SharedValidate
            field="address.office"
            :value="kybRegistrationInfo.address.office"
            :is-validate="isValidate"
            v-model:field-value="kybRegistrationInfoFields['address.office']"
          >
            <UIInput
              :class="b('form-office')"
              placeholder="Номер Офиса"
              view="light"
              :is-readonly="isReadonly"
              :is-error="
                isValidate && !kybRegistrationInfoFields['address.office']
              "
              v-model="kybRegistrationInfo.address.office"
            />
          </SharedValidate>

          <SharedValidate
            field="address.zip"
            :value="kybRegistrationInfo.address.zip"
            :is-validate="isValidate"
            v-model:field-value="kybRegistrationInfoFields['address.zip']"
          >
            <UIInput
              :class="b('form-index')"
              placeholder="Индекс"
              view="light"
              :is-readonly="isReadonly"
              :is-error="
                isValidate && !kybRegistrationInfoFields['address.zip']
              "
              v-model="kybRegistrationInfo.address.zip"
            />
          </SharedValidate>
        </div>
      </div>

      <div :class="b('form-group')">
        <h5 :class="b('form-title')">
          {{ $t("Фактический адрес совпадает с адресом регистрации?*") }}
        </h5>

        <SharedValidate
          field="address.isActual"
          :value="kybRegistrationInfo.address.isActual"
          :is-validate="isValidate"
          v-model:field-value="kybRegistrationInfoFields['address.isActual']"
        >
          <div :class="b('form-checkboxes')">
            <div :class="b('form-checkbox')">
              <SharedRadio
                view="light"
                :checkedValue="true"
                :is-readonly="isReadonly"
                v-model:value="kybRegistrationInfo.address.isActual"
              />

              <p :class="b('form-checkbox-label')">
                {{ $t("Да") }}
              </p>
            </div>

            <div :class="b('form-checkbox')">
              <SharedRadio
                view="light"
                :checkedValue="false"
                :is-readonly="isReadonly"
                v-model:value="kybRegistrationInfo.address.isActual"
              />

              <p :class="b('form-checkbox-label')">
                {{ $t("Нет") }}
              </p>
            </div>
          </div>
        </SharedValidate>
      </div>

      <div
        v-if="!kybRegistrationInfo.address.isActual"
        :class="b('form-group', { address: true })"
      >
        <h5 :class="b('form-title')">
          {{ $t("Фактический адрес организации*") }}
        </h5>

        <div :class="b('form-block')">
          <SharedValidate
            field="address.country"
            :value="kybRegistrationInfo.address.country_actual"
            :is-validate="isValidate"
            v-model:field-value="
              kybRegistrationInfoFields['address.country_actual']
            "
          >
            <SharedCountry
              :class="b('form-country')"
              placeholder="Cтрана"
              :is-readonly="isReadonly"
              :is-error="
                isValidate &&
                !kybRegistrationInfoFields['address.country_actual']
              "
              v-model:value="kybRegistrationInfo.address.country_actual"
            />
          </SharedValidate>

          <SharedValidate
            field="address.city"
            :value="kybRegistrationInfo.address.city_actual"
            :is-validate="isValidate"
            v-model:field-value="
              kybRegistrationInfoFields['address.city_actual']
            "
          >
            <UIInput
              :class="b('form-city')"
              placeholder="Город"
              view="light"
              :is-readonly="isReadonly"
              :is-error="
                isValidate && !kybRegistrationInfoFields['address.city_actual']
              "
              v-model="kybRegistrationInfo.address.city_actual"
            />
          </SharedValidate>

          <SharedValidate
            field="address.state"
            :value="kybRegistrationInfo.address.state_actual"
            :is-validate="isValidate"
            v-model:field-value="
              kybRegistrationInfoFields['address.state_actual']
            "
          >
            <UIInput
              :class="b('form-state')"
              placeholder="Штат / Область"
              view="light"
              :is-readonly="isReadonly"
              :is-error="
                isValidate && !kybRegistrationInfoFields['address.state_actual']
              "
              v-model="kybRegistrationInfo.address.state_actual"
            />
          </SharedValidate>

          <SharedValidate
            field="address.street"
            :value="kybRegistrationInfo.address.street_actual"
            :is-validate="isValidate"
            v-model:field-value="
              kybRegistrationInfoFields['address.street_actual']
            "
          >
            <UIInput
              :class="b('form-street')"
              placeholder="Улица"
              view="light"
              :is-readonly="isReadonly"
              :is-error="
                isValidate &&
                !kybRegistrationInfoFields['address.street_actual']
              "
              v-model="kybRegistrationInfo.address.street_actual"
            />
          </SharedValidate>

          <SharedValidate
            field="address.office"
            :value="kybRegistrationInfo.address.office_actual"
            :is-validate="isValidate"
            v-model:field-value="
              kybRegistrationInfoFields['address.office_actual']
            "
          >
            <UIInput
              :class="b('form-office')"
              placeholder="Номер Офиса"
              view="light"
              :is-readonly="isReadonly"
              :is-error="
                isValidate &&
                !kybRegistrationInfoFields['address.office_actual']
              "
              v-model="kybRegistrationInfo.address.office_actual"
            />
          </SharedValidate>

          <SharedValidate
            field="address.zip"
            :value="kybRegistrationInfo.address.zip_actual"
            :is-validate="isValidate"
            v-model:field-value="
              kybRegistrationInfoFields['address.zip_actual']
            "
          >
            <UIInput
              :class="b('form-index')"
              placeholder="Индекс"
              view="light"
              :is-readonly="isReadonly"
              :is-error="
                isValidate && !kybRegistrationInfoFields['address.zip_actual']
              "
              v-model="kybRegistrationInfo.address.zip_actual"
            />
          </SharedValidate>
        </div>
      </div>

      <SharedValidate
        field="contactPhone"
        :value="kybRegistrationInfo.phone"
        :is-validate="isValidate"
        v-model:field-value="kybRegistrationInfoFields.phone"
      >
        <div :class="b('form-group')">
          <h5 :class="b('form-title')">
            {{ $t("Контактный номер*") }}
          </h5>

          <UIInput
            view="light"
            :is-readonly="isReadonly"
            :is-error="isValidate && !kybRegistrationInfoFields.phone"
            v-model="kybRegistrationInfo.phone"
          />
        </div>
      </SharedValidate>

      <div :class="b('form-group')">
        <h5 :class="b('form-title')">
          {{ $t("Сайт") }}
        </h5>

        <UIInput
          view="light"
          :is-readonly="isReadonly"
          v-model="kybRegistrationInfo.site"
        />
      </div>
    </div>

    <UIButton
      :class="b('continue')"
      label="Продолжить"
      view="main"
      :disabled="isContinueDisabled"
      @click="handleContinue"
    />
  </SharedCard>
</template>
