import { defineComponent, PropType } from "vue";

import { COMPONENT_NAME } from "./attributes";

import { SharedUpload } from "@/components/shared";
import { AdminKycBack } from "../back";

import { AdminKycComponent } from "@/views/admin-kyc/model";
import {
  FileFor,
  KybParticipantDocumentsFileName,
} from "@/shared/constants/enums";
import { KybParticipantUploadFileResponseFactory } from "@/shared/repository/modules/kyb/factory";
import { KybParticipantDocuments } from "@/shared/constants/interfaces";
import { extractFileName } from "@/shared/utils/file-name-helpers";
import { UserFileResponse } from "@/shared/repository/modules/user/repo";

export default defineComponent({
  name: COMPONENT_NAME,
  components: {
    SharedUpload,
    AdminKycBack,
  },
  props: {
    id: {
      type: Number,
      required: true,
    },
    component: {
      type: <PropType<AdminKycComponent>>String,
      default: "",
    },
  },
  emits: {
    "update:component": null,
  },
  data() {
    return {
      isLoading: false,
      kybRepo: this.$projectServices.kybRepo,
      kybRepository: this.$projectServices.kybRepository,
      userRepository: this.$projectServices.userRepository,
      documents: Object.keys(KybParticipantDocumentsFileName).reduce(
        (documents, fileName) => {
          documents[fileName] = KybParticipantUploadFileResponseFactory({
            name: KybParticipantDocumentsFileName[
              fileName as KybParticipantDocumentsFileName
            ],
            kyb_participant_id: this.$projectServices.kybRepo.participantId,
          });

          return documents;
        },
        {} as KybParticipantDocuments
      ) as KybParticipantDocuments,
      files: <UserFileResponse[]>[],
    };
  },
  async created(): Promise<void> {
    try {
      this.isLoading = true;

      const [kybParticipantFiles, files] = await Promise.allSettled([
        this.kybRepository.participantFilesById(
          this.id,
          this.kybRepo.participantId!
        ),
        this.userRepository.filesById(
          this.id,
          FileFor.participant,
          this.kybRepo.participantId!
        ),
      ]);

      if (kybParticipantFiles.status === "fulfilled") {
        kybParticipantFiles.value.forEach((kybParticipantFile) => {
          this.documents[extractFileName(kybParticipantFile.name)] =
            KybParticipantUploadFileResponseFactory({
              ...kybParticipantFile,
              kyb_participant_id: this.kybRepo.participantId,
            });
        });
      }

      if (files.status === "fulfilled") {
        this.files = files.value;
      }
    } catch (e) {
      console.log(e);
    } finally {
      this.isLoading = false;
    }
  },
  methods: {
    handleBack(): void {
      this.$emit("update:component", AdminKycComponent.kybParticipant);
    },
  },
});
