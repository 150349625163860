import { normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveDirective as _resolveDirective, withDirectives as _withDirectives } from "vue"

const _hoisted_1 = ["src"]
const _hoisted_2 = ["innerHTML"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _directive_click_outside = _resolveDirective("click-outside")!

  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(_ctx.b())
  }, [
    _createElementVNode("div", {
      class: _normalizeClass(_ctx.b('info'))
    }, [
      (_ctx.row.country)
        ? (_openBlock(), _createElementBlock("span", {
            key: 0,
            class: _normalizeClass(['fi', `fi-${_ctx.row.country}`])
          }, null, 2))
        : _createCommentVNode("", true),
      (_ctx.isCryptoIconShow)
        ? (_openBlock(), _createElementBlock("img", {
            key: 1,
            class: _normalizeClass(_ctx.b('icon', { crypto: true })),
            src: _ctx.displayedCryptoIcon,
            alt: ""
          }, null, 10, _hoisted_1))
        : _createCommentVNode("", true),
      _createElementVNode("span", {
        class: _normalizeClass(_ctx.b('info-label'))
      }, _toDisplayString(_ctx.row.name.toUpperCase()), 3)
    ], 2),
    _withDirectives((_openBlock(), _createElementBlock("div", {
      class: _normalizeClass(_ctx.b('action'))
    }, [
      _createElementVNode("button", {
        class: _normalizeClass(_ctx.b('toggle', { active: _ctx.isVisible })),
        onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.handleToggle && _ctx.handleToggle(...args)))
      }, [
        _createElementVNode("span", {
          class: _normalizeClass(_ctx.b('toggle-icon')),
          innerHTML: _ctx.displayedIcons.dots
        }, null, 10, _hoisted_2)
      ], 2),
      _createElementVNode("div", {
        class: _normalizeClass(_ctx.b('options', { active: _ctx.isVisible }))
      }, [
        _createElementVNode("button", {
          class: _normalizeClass(_ctx.b('option')),
          onClick: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_ctx.handleDelete && _ctx.handleDelete(...args)))
        }, _toDisplayString(_ctx.$t("Удалить")), 3)
      ], 2)
    ], 2)), [
      [_directive_click_outside, _ctx.handleHide]
    ])
  ], 2))
}