import { defineComponent, PropType } from "vue";

import { COMPONENT_NAME } from "./attributes";

import { SharedRadio } from "@/components/shared";
import { RequisitesWalletType } from "@/shared/repository/modules/requisites/enums";

export interface WalletRequisitesRadio {
  id: number;
  label: string;
  value: RequisitesWalletType;
}

export default defineComponent({
  name: COMPONENT_NAME,
  components: {
    SharedRadio,
  },
  props: {
    requisite: {
      type: <PropType<RequisitesWalletType>>String,
      default: RequisitesWalletType.pattern,
    },
  },
  emits: {
    "update:requisite": null,
  },
  computed: {
    displayedRadios(): WalletRequisitesRadio[] {
      return [
        {
          id: 0,
          label: "Использовать шаблон реквизитов",
          value: RequisitesWalletType.pattern,
        },
        {
          id: 1,
          label: "Новые реквизиты",
          value: RequisitesWalletType.new,
        },
      ];
    },
  },
  methods: {
    handleRequisite(requisite: RequisitesWalletType): void {
      this.$emit("update:requisite", requisite);
    },
  },
});
