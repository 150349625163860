import { defineComponent, PropType } from "vue";

import { COMPONENT_NAME, SVG } from "./attributes";
import {
  AdminUsersTableRowOption,
  AdminUsersTableRowOptionLabel,
  AdminUsersTableRowOptionValue,
} from "./model";

import { SvgAttribute } from "@/shared/constants/constants";
import { AdminUsersResponse } from "@/shared/repository/modules/admin/users/repo";
import { RoutesName } from "@/shared/constants/enums";

const NA = "N/A";

export default defineComponent({
  name: COMPONENT_NAME,
  props: {
    user: {
      type: <PropType<AdminUsersResponse>>Object,
      required: true,
    },
    isStatus: {
      type: Boolean,
      default: false,
    },
    isBalance: {
      type: Boolean,
      default: false,
    },
  },
  emits: {
    "update:isStatus": null,
    "update:isBalance": null,
  },
  data() {
    return {
      isVisible: false,
      isLoading: false,
      usersRepo: this.$projectServices.usersRepo,
      adminUsersRepository: this.$projectServices.adminUsersRepository,
    };
  },
  computed: {
    displayedStatus(): string {
      const { kyc_status, kyb_staus } = this.user;

      return kyb_staus || kyc_status || "";
    },

    displayedFio(): string {
      const { applicant_name, applicant_surname, organisation } = this.user;

      if (applicant_name && applicant_surname) {
        return `${applicant_surname} ${applicant_name}`;
      } else if (organisation) {
        return organisation;
      }

      return NA;
    },

    displayedStatusKycIcon(): string {
      const { kyc_status, kyb_staus } = this.user;

      if (kyc_status) {
        return SVG[kyc_status];
      }

      if (kyb_staus) {
        return SVG[kyb_staus];
      }

      return "";
    },

    displayedStatusKycLabel(): string {
      const { kyc_status, kyb_staus, application_type } = this.user;

      return (kyc_status || kyb_staus) && application_type ? "" : NA;
    },

    displayedIcons(): SvgAttribute {
      return SVG;
    },

    displayedOptions(): AdminUsersTableRowOption[] {
      const { application_type } = this.user;

      return [
        {
          id: 0,
          label: AdminUsersTableRowOptionLabel.kyc,
          value: AdminUsersTableRowOptionValue.kyc,
          isDisabled: !application_type || this.isLoading,
        },
        {
          id: 1,
          label: AdminUsersTableRowOptionLabel.status,
          value: AdminUsersTableRowOptionValue.status,
          isDisabled: !application_type || this.isLoading,
        },
        {
          id: 2,
          label: AdminUsersTableRowOptionLabel.admin,
          value: AdminUsersTableRowOptionValue.admin,
          isDisabled: this.isLoading,
        },
        {
          id: 3,
          label: AdminUsersTableRowOptionLabel.balance,
          value: AdminUsersTableRowOptionValue.balance,
          isDisabled: this.isLoading,
        },
        {
          id: 4,
          label: AdminUsersTableRowOptionLabel.remove,
          value: AdminUsersTableRowOptionValue.remove,
          isDisabled: this.isLoading,
        },
      ];
    },
  },
  methods: {
    handleToggle(): void {
      this.isVisible = !this.isVisible;
    },

    handleHide(): void {
      this.isVisible = false;
    },

    async handleOption({ value }: AdminUsersTableRowOption): Promise<void> {
      try {
        const { index, email } = this.user;

        switch (value) {
          case AdminUsersTableRowOptionValue.kyc:
            this.$router.replace({
              name: RoutesName.adminKyc,
              query: { index },
            });

            break;
          case AdminUsersTableRowOptionValue.status:
            this.usersRepo.UPDATE_INDEX(index);

            this.$emit("update:isStatus", true);

            break;
          case AdminUsersTableRowOptionValue.balance:
            this.usersRepo.UPDATE_INDEX(index);

            this.$emit("update:isBalance", true);

            break;
          case AdminUsersTableRowOptionValue.admin:
            await this.adminUsersRepository.make(email);

            break;
          case AdminUsersTableRowOptionValue.remove:
            this.isLoading = true;

            break;
          default:
            break;
        }
      } catch (e) {
        console.log(e);
      } finally {
        this.isLoading = false;

        this.handleHide();
      }
    },
  },
});
