import { defineComponent } from "vue";

import { COMPONENT_NAME } from "./attributes";

import { SharedWindow } from "@/components/shared";
import { UIButton } from "@/components/ui";
import { DepositWithdrawalHistoryResponseFactory } from "@/shared/repository/modules/deposit-withdrawal/factory";
import { formatFiatCurrency } from "@/shared/utils/amount-helpers";
import { ApplicationStatus } from "@/shared/constants/enums";
import { AdminCommissionRequisitesListResponse } from "@/shared/repository/modules/admin/commission/repo";

interface HistoryDetails {
  dateTime: HistoryDetailsParams;
  type: HistoryDetailsParams;
  amountCurrency: HistoryDetailsParams;
  exchange: HistoryDetailsParams;
  status: HistoryDetailsParams;
  address: {
    title: string;
    label: string;
    info?: {
      rs?: HistoryDetailsParams;
      ks?: HistoryDetailsParams;
      bik?: HistoryDetailsParams;
      name?: HistoryDetailsParams;
      inn?: HistoryDetailsParams;
      kpp?: HistoryDetailsParams;
    };
  } | null;
}

interface HistoryDetailsParams {
  title: string;
  label: string;
}

export default defineComponent({
  name: COMPONENT_NAME,
  components: {
    SharedWindow,
    UIButton,
  },
  props: {
    isDetails: {
      type: Boolean,
      default: false,
    },
  },
  emits: {
    "update:isDetails": null,
  },
  data() {
    return {
      isLoading: false,
      details: DepositWithdrawalHistoryResponseFactory(),
      requisites: <AdminCommissionRequisitesListResponse | null>null,
      depositWithdrawalRepo: this.$projectServices.depositWithdrawalRepo,
      depositWithdrawalRepository:
        this.$projectServices.depositWithdrawalRepository,
    };
  },
  async created(): Promise<void> {
    try {
      this.isLoading = true;

      this.details = this.depositWithdrawalRepo.history.find(
        (item) => item.id === this.depositWithdrawalRepo.detailsId
      )!;

      this.requisites = await this.depositWithdrawalRepository.requisite(
        this.depositWithdrawalRepo.detailsCurrencyId
      );
    } catch (error) {
      console.log("ERROR", error);
    } finally {
      this.isLoading = false;
    }
  },
  beforeUnmount(): void {
    this.depositWithdrawalRepo.UPDATE_DETAILS({
      id: 0,
      currencyId: 0,
    });
  },
  computed: {
    displayedList(): HistoryDetails {
      const { created_at, app_type, amount, currency, commissions, status } =
        this.details;

      const getStatus = (): string => {
        switch (status) {
          case ApplicationStatus.pending:
            return "Создано";
          case ApplicationStatus.waiting:
            return "Ожидание подтверждения";
          case ApplicationStatus.inWork:
            return "В работе";
          case ApplicationStatus.reject:
            return "Ошибка";
          case ApplicationStatus.completed:
            return "Выполнено";
          default:
            return "Создано";
        }
      };

      return {
        dateTime: {
          title: "Дата и время",
          label: created_at,
        },
        type: {
          title: "Тип",
          label: app_type === "deposit" ? "Пополнение" : "Вывод",
        },
        amountCurrency: {
          title: "Сумма и валюта",
          label: `${formatFiatCurrency(amount)} ${currency.name.toUpperCase()}`,
        },
        exchange: {
          title: "Комиссия",
          label: `${commissions} ${currency.name.toUpperCase()}`,
        },
        status: {
          title: "Статус",
          label: getStatus(),
        },
        address:
          this.requisites !== null
            ? {
                title: "Адрес перевода",
                label: "",
                info: {
                  // rs: {
                  //   title: "Р/С",
                  //   label: "Не приходит",
                  // },
                  // ks: {
                  //   title: "К/С",
                  //   label: "Не приходит",
                  // },
                  // bik: {
                  //   title: "БИК",
                  //   label: "Не приходит",
                  // },
                  name: {
                    title: "Полное наименование банка",
                    label: this.requisites.org_full_name_local,
                  },
                  inn: {
                    title: "ИНН",
                    label: this.requisites.inn,
                  },
                  // kpp: {
                  //   title: "КПП",
                  //   label: "Не приходит",
                  // },
                },
              }
            : null,
      };
    },

    isDisabled(): boolean {
      return this.isLoading;
    },
  },
  methods: {
    handleClose(): void {
      this.$emit("update:isDetails", !this.isDetails);
    },
  },
});
