import { defineComponent } from "vue";

import { COMPONENT_NAME, SharedDateFormat, SVG } from "./attributes";

import { getDate } from "@/shared/utils/moment-helpers";
import { Language, MomentFormat } from "@/shared/constants/enums";

export default defineComponent({
  name: COMPONENT_NAME,
  props: {
    range: {
      type: Boolean,
      default: false,
    },
    multiCalendars: {
      type: Boolean,
      default: false,
    },
    time: {
      type: Boolean,
      default: false,
    },
    format: {
      type: String,
      default: SharedDateFormat.date,
    },
    placeholder: {
      type: String,
      default: "",
    },
    enableTimePicker: {
      type: Boolean,
      default: false,
    },
    date: {
      type: [String, Array],
      default: "",
    },
    isReadonly: {
      type: Boolean,
      default: false,
    },
    isError: {
      type: Boolean,
      default: false,
    },
  },
  emits: {
    "update:date": null,
  },
  data() {
    return {
      isOpen: false,
      userRepo: this.$projectServices.userRepo,
    };
  },
  computed: {
    displayedIcon(): string {
      return SVG.icon;
    },

    displayedFormat(): MomentFormat {
      switch (this.format) {
        case SharedDateFormat.date:
          return MomentFormat.date;
        case SharedDateFormat.dateReverse:
          return MomentFormat.dateReverse;
        default:
          return MomentFormat.date;
      }
    },

    displayedLocale(): Language {
      return this.userRepo.userInfo.user_info.lang;
    },
  },
  methods: {
    changeDate(date: Date | Date[]): void {
      if (this.range) {
        console.log(date);
      } else {
        this.$emit("update:date", getDate(<Date>date, this.displayedFormat));
      }
    },

    clearDate(): void {
      this.$emit("update:date", this.range ? [] : "");
    },

    handleOpen(): void {
      this.isOpen = true;
    },

    handleClose(): void {
      this.isOpen = false;
    },
  },
});
