<script lang="ts" src="./component.ts"></script>
<style lang="scss" src="./component.scss"></style>

<template>
  <VueDatePicker
    :class="b('', { open: isOpen, error: isError })"
    :range="range"
    :multi-calendars="multiCalendars"
    :time="time"
    :format="format"
    :preview-format="format"
    :placeholder="$t(placeholder)"
    :enable-time-picker="enableTimePicker"
    :model-value="date"
    :disabled="isReadonly"
    :locale="displayedLocale"
    menu-class-name="shared-date__menu"
    input-class-name="shared-date__field"
    @date-update="changeDate"
    @cleared="clearDate"
    @open="handleOpen"
    @closed="handleClose"
  >
    <template #input-icon>
      <span :class="b('icon')" v-html="displayedIcon"></span>
    </template>
  </VueDatePicker>
</template>
