<script lang="ts" src="./component.ts"></script>
<style lang="scss" src="./component.scss"></style>

<template>
  <div :class="b()" v-click-outside="handleHide">
    <button :class="b('toggle', { active: isVisible })" @click="handleToggle">
      <span :class="b('toggle-placeholder')">
        {{ displayedPlaceholder }}
      </span>

      <span :class="b('toggle-group')">
        <span
          :class="b('toggle-currency')"
          v-if="typeof displayedCurrency === 'object'"
        >
          <span
            v-if="displayedCurrency.currency.fiatIcon"
            :class="[
              b('toggle-icon', { icon: true }),
              'fi',
              `fi-${displayedCurrency.currency.fiatIcon}`,
            ]"
          ></span>

          <img
            v-if="displayedCurrency.currency.cryptoIcon"
            :class="b('toggle-icon', { icon: true })"
            :src="
              require(`@/assets/images/crypto/${displayedCurrency.currency.cryptoIcon.toLowerCase()}.svg`)
            "
            alt=""
          />

          {{ displayedCurrency.currency.name.toUpperCase() }}
        </span>

        <span
          :class="b('toggle-icon', { active: isVisible })"
          v-html="displayedChevronIcon"
        ></span>
      </span>
    </button>

    <div :class="b('options', { visible: isVisible })">
      <button
        :class="b('option', { active: option.isActive })"
        v-for="option in options"
        :key="option.id"
        @click="handleOption(option)"
      >
        {{ option.label }}

        <span :class="b('option-currency')">
          <span
            v-if="option.currency.fiatIcon"
            :class="[b('option-icon'), 'fi', `fi-${option.currency.fiatIcon}`]"
          ></span>

          <img
            v-if="option.currency.cryptoIcon"
            :class="b('option-icon', { crypto: true })"
            :src="
              require(`@/assets/images/crypto/${option.currency.cryptoIcon.toLowerCase()}.svg`)
            "
            alt=""
          />

          {{ option.currency.name.toUpperCase() }}
        </span>
      </button>

      <button :class="b('option', { create: true })" @click="handleCreate">
        Создать новый
      </button>
    </div>
  </div>
</template>
