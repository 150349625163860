import { defineComponent } from "vue";

import { COMPONENT_NAME, SVG } from "./attributes";

import { WalletMainTable } from "./table";
import { SharedButtonIcon } from "@/components/shared";

import { SvgAttribute } from "@/shared/constants/constants";
import { WalletFilterType } from "@/shared/repository/modules/wallet/enums";
import { WalletModalFactory } from "@/shared/repository/modules/wallet/factory";
import { AdminApplicationType } from "@/shared/repository/modules/admin/application/enums";
import { WalletListResponse } from "@/shared/repository/modules/wallet/repo";

export interface WalletMainTab {
  id: number;
  label: string;
  value: WalletFilterType;
  active: boolean;
}

export default defineComponent({
  name: COMPONENT_NAME,
  components: {
    WalletMainTable,
    SharedButtonIcon,
  },
  data() {
    return {
      tab: WalletFilterType.all,
      walletRepo: this.$projectServices.walletRepo,
      walletRepository: this.$projectServices.walletRepository,
    };
  },
  async created(): Promise<void> {
    this.walletRepo.UPDATE_LIST(await this.walletRepository.list(this.tab));
  },
  computed: {
    displayedList(): WalletListResponse[] {
      return this.walletRepo.list;
    },

    displayedTabs(): WalletMainTab[] {
      return [
        {
          id: 0,
          label: "all",
          value: WalletFilterType.all,
          active: this.tab === WalletFilterType.all,
        },
        {
          id: 1,
          label: "fiat",
          value: WalletFilterType.fiat,
          active: this.tab === WalletFilterType.fiat,
        },
        {
          id: 2,
          label: "crypto",
          value: WalletFilterType.crypto,
          active: this.tab === WalletFilterType.crypto,
        },
      ];
    },

    displayedIcons(): SvgAttribute {
      return SVG;
    },
  },
  methods: {
    async handleTab(tab: WalletFilterType): Promise<void> {
      this.tab = tab;

      this.walletRepo.UPDATE_LIST(await this.walletRepository.list(tab));
    },

    handleDeposit(): void {
      this.walletRepo.UPDATE_MODAL(
        WalletModalFactory({
          type: AdminApplicationType.deposit,
        })
      );
    },

    handleWithdrawal(): void {
      this.walletRepo.UPDATE_MODAL(
        WalletModalFactory({
          type: AdminApplicationType.withdrawal,
        })
      );
    },
  },
});
