import { toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_UIInput = _resolveComponent("UIInput")!

  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(_ctx.b())
  }, [
    _createElementVNode("div", {
      class: _normalizeClass(_ctx.b('field'))
    }, [
      _createElementVNode("h4", {
        class: _normalizeClass(_ctx.b('field-title'))
      }, _toDisplayString(_ctx.$t("БАНК-корреспондент")), 3),
      _createVNode(_component_UIInput, {
        view: "light",
        "model-value": _ctx.correspondentBank,
        "max-length": 150,
        "onUpdate:modelValue": _ctx.handleChangeCorrespondentBank
      }, null, 8, ["model-value", "onUpdate:modelValue"])
    ], 2),
    _createElementVNode("div", {
      class: _normalizeClass(_ctx.b('field'))
    }, [
      _createElementVNode("h4", {
        class: _normalizeClass(_ctx.b('field-title'))
      }, _toDisplayString(_ctx.$t("БИК")), 3),
      _createVNode(_component_UIInput, {
        view: "light",
        "model-value": _ctx.bic,
        "onUpdate:modelValue": _ctx.handleChangeBic
      }, null, 8, ["model-value", "onUpdate:modelValue"])
    ], 2),
    _createElementVNode("div", {
      class: _normalizeClass(_ctx.b('field'))
    }, [
      _createElementVNode("h4", {
        class: _normalizeClass(_ctx.b('field-title'))
      }, _toDisplayString(_ctx.$t("ИНН")), 3),
      _createVNode(_component_UIInput, {
        view: "light",
        "model-value": _ctx.inn,
        "onUpdate:modelValue": _ctx.handleChangeInn
      }, null, 8, ["model-value", "onUpdate:modelValue"])
    ], 2),
    _createElementVNode("div", {
      class: _normalizeClass(_ctx.b('field'))
    }, [
      _createElementVNode("h4", {
        class: _normalizeClass(_ctx.b('field-title'))
      }, _toDisplayString(_ctx.$t("Корр. счет")), 3),
      _createVNode(_component_UIInput, {
        view: "light",
        "model-value": _ctx.correspondentAccount,
        "onUpdate:modelValue": _ctx.handleChangeCorrespondentAccount
      }, null, 8, ["model-value", "onUpdate:modelValue"])
    ], 2),
    _createElementVNode("div", {
      class: _normalizeClass(_ctx.b('field'))
    }, [
      _createElementVNode("h4", {
        class: _normalizeClass(_ctx.b('field-title'))
      }, "SWIFT", 2),
      _createVNode(_component_UIInput, {
        view: "light",
        "model-value": _ctx.correspondentSwift,
        "onUpdate:modelValue": _ctx.handleChangeCorrespondentSwift
      }, null, 8, ["model-value", "onUpdate:modelValue"])
    ], 2),
    _createElementVNode("div", {
      class: _normalizeClass(_ctx.b('field'))
    }, [
      _createElementVNode("h4", {
        class: _normalizeClass(_ctx.b('field-title'))
      }, _toDisplayString(_ctx.$t("БАНК получателя")), 3),
      _createVNode(_component_UIInput, {
        view: "light",
        "model-value": _ctx.recipientBank,
        "onUpdate:modelValue": _ctx.handleChangeRecipientBank
      }, null, 8, ["model-value", "onUpdate:modelValue"])
    ], 2),
    _createElementVNode("div", {
      class: _normalizeClass(_ctx.b('field'))
    }, [
      _createElementVNode("h4", {
        class: _normalizeClass(_ctx.b('field-title'))
      }, _toDisplayString(_ctx.$t("Корр. счет")), 3),
      _createVNode(_component_UIInput, {
        view: "light",
        "model-value": _ctx.recipientCorrespondentAccount,
        "onUpdate:modelValue": _ctx.handleChangeRecipientCorrespondentAccount
      }, null, 8, ["model-value", "onUpdate:modelValue"])
    ], 2),
    _createElementVNode("div", {
      class: _normalizeClass(_ctx.b('field'))
    }, [
      _createElementVNode("h4", {
        class: _normalizeClass(_ctx.b('field-title'))
      }, "SWIFT", 2),
      _createVNode(_component_UIInput, {
        view: "light",
        "model-value": _ctx.recipientCorrespondentSwift,
        "onUpdate:modelValue": _ctx.handleChangeRecipientCorrespondentSwift
      }, null, 8, ["model-value", "onUpdate:modelValue"])
    ], 2)
  ], 2))
}