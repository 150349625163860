import { SharedSelectOption } from "@/components/shared/select/model";

import { SelectOptionFactory } from "@/shared/factory/select";
import { AdminCommissionExchangeType } from "@/shared/repository/modules/admin/commission/enums";

export const AdminCommissionFindOptions = (
  type: AdminCommissionExchangeType
): SharedSelectOption[] => {
  return [
    SelectOptionFactory(
      {
        id: 0,
        label: "Для всех",
        value: AdminCommissionExchangeType.all,
      },
      type
    ),
    SelectOptionFactory(
      {
        id: 1,
        label: "Для пользователя",
        value: AdminCommissionExchangeType.user,
      },
      type
    ),
  ];
};
