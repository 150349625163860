import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_SharedSelect = _resolveComponent("SharedSelect")!
  const _component_AdminConfigCommissionField = _resolveComponent("AdminConfigCommissionField")!

  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(_ctx.b())
  }, [
    _createVNode(_component_AdminConfigCommissionField, { title: "Внести изменения" }, {
      default: _withCtx(() => [
        _createVNode(_component_SharedSelect, {
          view: "light",
          options: _ctx.displayedOptions,
          value: _ctx.type,
          "onUpdate:value": _ctx.handleType
        }, null, 8, ["options", "value", "onUpdate:value"])
      ]),
      _: 1
    }),
    (_ctx.isUserIdShow)
      ? (_openBlock(), _createBlock(_component_AdminConfigCommissionField, {
          key: 0,
          title: "Поиск по ID"
        }, {
          default: _withCtx(() => [
            _createVNode(_component_SharedSelect, {
              view: "light",
              placeholder: "Выберите пользователя",
              options: _ctx.displayedUserIdOptions,
              value: _ctx.userId,
              "onUpdate:value": _ctx.handleUserId
            }, null, 8, ["options", "value", "onUpdate:value"])
          ]),
          _: 1
        }))
      : _createCommentVNode("", true)
  ], 2))
}