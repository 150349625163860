import { defineComponent } from "vue";

import { COMPONENT_NAME } from "./attributes";

import { SharedSelect } from "@/components/shared";
import { UIButton } from "@/components/ui";

import {
  AdminCurrencyCryptoResponse,
  AdminCurrencyListResponse,
  AdminCurrencyModal,
} from "@/shared/repository/modules/admin/currency/repo";
import {
  AdminCurrencyModalOption,
  AdminCurrencyType,
} from "@/shared/repository/modules/admin/currency/enums";
import {
  AdminCurrencyShowResponseFactory,
  AdminCurrencyStoreRequestFactory,
} from "@/shared/repository/modules/admin/currency/factory";
import { SharedSelectOption } from "@/components/shared/select/model";
import { SelectOptionFactory } from "@/shared/factory/select";
import { Coins } from "@/shared/constants/constants";

enum ButtonLabel {
  create = "Добавить криптовалюту",
  delete = "Удалить криптовалюту",
}

export default defineComponent({
  name: COMPONENT_NAME,
  components: {
    SharedSelect,
    UIButton,
  },
  data() {
    return {
      isLoading: false,
      adminCurrency: AdminCurrencyShowResponseFactory(),
      adminCurrencyRepository: this.$projectServices.adminCurrencyRepository,
      adminCurrencyRepo: this.$projectServices.adminCurrencyRepo,
    };
  },
  async created(): Promise<void> {
    try {
      this.isLoading = true;

      if (this.displayedModal.id) {
        this.adminCurrency = await this.adminCurrencyRepository.show(
          this.displayedModal.id
        );
      }
    } catch (error) {
      console.log(error);
    } finally {
      this.isLoading = false;
    }
  },
  computed: {
    displayedModal(): AdminCurrencyModal {
      return this.adminCurrencyRepo.modal;
    },

    displayedList(): AdminCurrencyListResponse {
      return this.adminCurrencyRepo.list;
    },

    displayedCrypto(): AdminCurrencyCryptoResponse[] {
      return this.displayedList.crypto.filter((crypto) => !crypto.frozen);
    },

    displayedOptions(): SharedSelectOption[] {
      switch (this.displayedModal.option) {
        case AdminCurrencyModalOption.create:
          return Object.values(Coins)
            .filter(
              (coin) =>
                !this.displayedCrypto.find((crypto) => crypto.name === coin)
            )
            .map((coin, coinKeyIndex) =>
              SelectOptionFactory(
                {
                  id: coinKeyIndex,
                  label: coin,
                  value: coin,
                  icon: coin,
                },
                this.adminCurrency.name
              )
            );
        case AdminCurrencyModalOption.delete:
          return Object.values(Coins)
            .filter((coin) =>
              this.displayedCrypto.find((crypto) => crypto.name === coin)
            )
            .map((coin, coinIndex) =>
              SelectOptionFactory(
                {
                  id: coinIndex,
                  label: coin,
                  value: coin,
                  icon: coin,
                },
                this.adminCurrency.name
              )
            );
        default:
          return [];
      }
    },

    displayedButtonLabel(): ButtonLabel {
      switch (this.displayedModal.option) {
        case AdminCurrencyModalOption.create:
          return ButtonLabel.create;
        case AdminCurrencyModalOption.delete:
          return ButtonLabel.delete;
        default:
          return ButtonLabel.create;
      }
    },

    isButtonDisabled(): boolean {
      return !this.adminCurrency.name || this.isLoading;
    },
  },
  methods: {
    async handleButton(): Promise<void> {
      try {
        this.isLoading = true;

        switch (this.displayedModal.option) {
          case AdminCurrencyModalOption.create:
            await this.adminCurrencyRepository.store(
              AdminCurrencyStoreRequestFactory({
                ...this.adminCurrency,
                type: AdminCurrencyType.crypto,
              })
            );

            break;
          case AdminCurrencyModalOption.delete:
            await this.adminCurrencyRepository.delete(this.displayedModal.id);

            break;
          default:
            break;
        }

        await this.adminCurrencyRepo.updateList();

        this.adminCurrencyRepo.UPDATE_IS_SHOW(false);
      } catch (error) {
        console.log(error);
      } finally {
        this.isLoading = false;
      }
    },
  },
});
