import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_AdminKycBack = _resolveComponent("AdminKycBack")!
  const _component_UIInput = _resolveComponent("UIInput")!
  const _component_SharedRadio = _resolveComponent("SharedRadio")!

  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(_ctx.b())
  }, [
    _createVNode(_component_AdminKycBack, {
      title: "Регистрационная информация",
      onBack: _ctx.handleBack
    }, null, 8, ["onBack"]),
    _createElementVNode("div", {
      class: _normalizeClass(_ctx.b('form'))
    }, [
      _createElementVNode("div", {
        class: _normalizeClass(_ctx.b('fields'))
      }, [
        _createElementVNode("div", {
          class: _normalizeClass(_ctx.b('field'))
        }, [
          _createElementVNode("h4", {
            class: _normalizeClass(_ctx.b('field-label'))
          }, _toDisplayString(_ctx.$t("Организационно-правовая форма организации")), 3),
          _createVNode(_component_UIInput, {
            view: "light",
            "is-readonly": true,
            modelValue: _ctx.kybRegistrationInfo.organisation_legal_form,
            "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.kybRegistrationInfo.organisation_legal_form) = $event))
          }, null, 8, ["modelValue"])
        ], 2),
        _createElementVNode("div", {
          class: _normalizeClass(_ctx.b('field'))
        }, [
          _createElementVNode("h4", {
            class: _normalizeClass(_ctx.b('field-label'))
          }, _toDisplayString(_ctx.$t("Сфера бизнеса")), 3),
          _createVNode(_component_UIInput, {
            view: "light",
            "is-readonly": true,
            modelValue: _ctx.kybRegistrationInfo.business_area,
            "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.kybRegistrationInfo.business_area) = $event))
          }, null, 8, ["modelValue"])
        ], 2),
        _createElementVNode("div", {
          class: _normalizeClass(_ctx.b('field'))
        }, [
          _createElementVNode("h4", {
            class: _normalizeClass(_ctx.b('field-label'))
          }, _toDisplayString(_ctx.$t("Контактный номер")), 3),
          _createVNode(_component_UIInput, {
            view: "light",
            "is-readonly": true,
            modelValue: _ctx.kybRegistrationInfo.phone,
            "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.kybRegistrationInfo.phone) = $event))
          }, null, 8, ["modelValue"])
        ], 2),
        _createElementVNode("div", {
          class: _normalizeClass(_ctx.b('field'))
        }, [
          _createElementVNode("h4", {
            class: _normalizeClass(_ctx.b('field-label'))
          }, _toDisplayString(_ctx.$t("Сайт")), 3),
          _createVNode(_component_UIInput, {
            view: "light",
            "is-readonly": true,
            modelValue: _ctx.kybRegistrationInfo.site,
            "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.kybRegistrationInfo.site) = $event))
          }, null, 8, ["modelValue"])
        ], 2),
        _createElementVNode("div", {
          class: _normalizeClass(_ctx.b('field'))
        }, [
          _createElementVNode("h4", {
            class: _normalizeClass(_ctx.b('field-label'))
          }, _toDisplayString(_ctx.$t("Фактический адрес совпадает с адресом регистрации?")), 3),
          _createElementVNode("div", {
            class: _normalizeClass(_ctx.b('field-radios'))
          }, [
            _createElementVNode("div", {
              class: _normalizeClass(_ctx.b('field-radio'))
            }, [
              _createVNode(_component_SharedRadio, {
                view: "light",
                checkedValue: true,
                "is-readonly": true,
                value: _ctx.kybRegistrationInfo.address.isActual,
                "onUpdate:value": _cache[4] || (_cache[4] = ($event: any) => ((_ctx.kybRegistrationInfo.address.isActual) = $event))
              }, null, 8, ["value"]),
              _createElementVNode("p", {
                class: _normalizeClass(_ctx.b('field-radio-label'))
              }, _toDisplayString(_ctx.$t("Да")), 3)
            ], 2),
            _createElementVNode("div", {
              class: _normalizeClass(_ctx.b('field-radio'))
            }, [
              _createVNode(_component_SharedRadio, {
                view: "light",
                checkedValue: false,
                "is-readonly": true,
                value: _ctx.kybRegistrationInfo.address.isActual,
                "onUpdate:value": _cache[5] || (_cache[5] = ($event: any) => ((_ctx.kybRegistrationInfo.address.isActual) = $event))
              }, null, 8, ["value"]),
              _createElementVNode("p", {
                class: _normalizeClass(_ctx.b('field-radio-label'))
              }, _toDisplayString(_ctx.$t("Нет")), 3)
            ], 2)
          ], 2)
        ], 2)
      ], 2),
      _createElementVNode("div", {
        class: _normalizeClass(_ctx.b('address'))
      }, [
        _createElementVNode("h4", {
          class: _normalizeClass(_ctx.b('field-label'))
        }, _toDisplayString(_ctx.$t("Адрес организации")), 3),
        _createElementVNode("div", {
          class: _normalizeClass(_ctx.b('fields', { address: true }))
        }, [
          _createVNode(_component_UIInput, {
            view: "light",
            "is-readonly": true,
            modelValue: _ctx.kybRegistrationInfo.address.country,
            "onUpdate:modelValue": _cache[6] || (_cache[6] = ($event: any) => ((_ctx.kybRegistrationInfo.address.country) = $event))
          }, null, 8, ["modelValue"]),
          _createVNode(_component_UIInput, {
            view: "light",
            "is-readonly": true,
            modelValue: _ctx.kybRegistrationInfo.address.city,
            "onUpdate:modelValue": _cache[7] || (_cache[7] = ($event: any) => ((_ctx.kybRegistrationInfo.address.city) = $event))
          }, null, 8, ["modelValue"]),
          _createVNode(_component_UIInput, {
            view: "light",
            "is-readonly": true,
            modelValue: _ctx.kybRegistrationInfo.address.state,
            "onUpdate:modelValue": _cache[8] || (_cache[8] = ($event: any) => ((_ctx.kybRegistrationInfo.address.state) = $event))
          }, null, 8, ["modelValue"]),
          _createVNode(_component_UIInput, {
            view: "light",
            "is-readonly": true,
            modelValue: _ctx.kybRegistrationInfo.address.street,
            "onUpdate:modelValue": _cache[9] || (_cache[9] = ($event: any) => ((_ctx.kybRegistrationInfo.address.street) = $event))
          }, null, 8, ["modelValue"]),
          _createVNode(_component_UIInput, {
            view: "light",
            "is-readonly": true,
            modelValue: _ctx.kybRegistrationInfo.address.office,
            "onUpdate:modelValue": _cache[10] || (_cache[10] = ($event: any) => ((_ctx.kybRegistrationInfo.address.office) = $event))
          }, null, 8, ["modelValue"]),
          _createVNode(_component_UIInput, {
            view: "light",
            "is-readonly": true,
            modelValue: _ctx.kybRegistrationInfo.address.zip,
            "onUpdate:modelValue": _cache[11] || (_cache[11] = ($event: any) => ((_ctx.kybRegistrationInfo.address.zip) = $event))
          }, null, 8, ["modelValue"])
        ], 2)
      ], 2),
      (!_ctx.kybRegistrationInfo.address.isActual)
        ? (_openBlock(), _createElementBlock("div", {
            key: 0,
            class: _normalizeClass(_ctx.b('address'))
          }, [
            _createElementVNode("h4", {
              class: _normalizeClass(_ctx.b('field-label'))
            }, _toDisplayString(_ctx.$t("Фактический адрес организации")), 3),
            _createElementVNode("div", {
              class: _normalizeClass(_ctx.b('fields', { address: true }))
            }, [
              _createVNode(_component_UIInput, {
                view: "light",
                "is-readonly": true,
                modelValue: _ctx.kybRegistrationInfo.address.country_actual,
                "onUpdate:modelValue": _cache[12] || (_cache[12] = ($event: any) => ((_ctx.kybRegistrationInfo.address.country_actual) = $event))
              }, null, 8, ["modelValue"]),
              _createVNode(_component_UIInput, {
                view: "light",
                "is-readonly": true,
                modelValue: _ctx.kybRegistrationInfo.address.city_actual,
                "onUpdate:modelValue": _cache[13] || (_cache[13] = ($event: any) => ((_ctx.kybRegistrationInfo.address.city_actual) = $event))
              }, null, 8, ["modelValue"]),
              _createVNode(_component_UIInput, {
                view: "light",
                "is-readonly": true,
                modelValue: _ctx.kybRegistrationInfo.address.state_actual,
                "onUpdate:modelValue": _cache[14] || (_cache[14] = ($event: any) => ((_ctx.kybRegistrationInfo.address.state_actual) = $event))
              }, null, 8, ["modelValue"]),
              _createVNode(_component_UIInput, {
                view: "light",
                "is-readonly": true,
                modelValue: _ctx.kybRegistrationInfo.address.street_actual,
                "onUpdate:modelValue": _cache[15] || (_cache[15] = ($event: any) => ((_ctx.kybRegistrationInfo.address.street_actual) = $event))
              }, null, 8, ["modelValue"]),
              _createVNode(_component_UIInput, {
                view: "light",
                "is-readonly": true,
                modelValue: _ctx.kybRegistrationInfo.address.office_actual,
                "onUpdate:modelValue": _cache[16] || (_cache[16] = ($event: any) => ((_ctx.kybRegistrationInfo.address.office_actual) = $event))
              }, null, 8, ["modelValue"]),
              _createVNode(_component_UIInput, {
                view: "light",
                "is-readonly": true,
                modelValue: _ctx.kybRegistrationInfo.address.zip_actual,
                "onUpdate:modelValue": _cache[17] || (_cache[17] = ($event: any) => ((_ctx.kybRegistrationInfo.address.zip_actual) = $event))
              }, null, 8, ["modelValue"])
            ], 2)
          ], 2))
        : _createCommentVNode("", true)
    ], 2)
  ], 2))
}