import { defineComponent } from "vue";

import { COMPONENT_NAME, SVG } from "./attributes";
import {
  DefaultToolbarLink,
  DefaultToolbarLinkIcon,
  DefaultToolbarLinkLabel,
} from "./model";

import { SharedLanguage } from "@/components/shared";

import { SvgAttribute } from "@/shared/constants/constants";
import { RoutesName, RoutesTo } from "@/shared/constants/enums";

export default defineComponent({
  name: COMPONENT_NAME,
  components: {
    SharedLanguage,
  },
  data() {
    return {
      isMenu: false,
      isLoading: false,
      userRepo: this.$projectServices.userRepo,
      authRepository: this.$projectServices.authRepository,
    };
  },
  computed: {
    displayedIcons(): SvgAttribute {
      return SVG;
    },

    displayedLinks(): DefaultToolbarLink[] {
      return [
        {
          id: 0,
          label: DefaultToolbarLinkLabel.wallet,
          to: RoutesTo.wallet,
          icon: SVG[DefaultToolbarLinkIcon.wallet],
          isDisabled: !this.isVerified,
        },
        {
          id: 1,
          label: DefaultToolbarLinkLabel.application,
          to: RoutesTo.application,
          icon: SVG[DefaultToolbarLinkIcon.application],
          isDisabled: !this.isVerified,
        },
        {
          id: 2,
          label: DefaultToolbarLinkLabel.history,
          to: RoutesTo.history,
          icon: SVG[DefaultToolbarLinkIcon.history],
          isDisabled: !this.isVerified,
        },
        {
          id: 3,
          label: DefaultToolbarLinkLabel.settings,
          to: RoutesTo.settings,
          icon: SVG[DefaultToolbarLinkIcon.settings],
          isDisabled: !this.isVerified,
        },
      ];
    },

    isVerified(): boolean {
      return this.userRepo.isVerified;
    },
  },
  methods: {
    handleBurger(): void {
      this.isMenu = !this.isMenu;
    },

    handleClose(): void {
      this.isMenu = !this.isMenu;
    },

    async handleLogout(): Promise<void> {
      try {
        this.isLoading = true;

        await this.authRepository.logout();

        this.$router.push({ name: RoutesName.login });
      } catch (e) {
        console.log(e);
      } finally {
        this.isLoading = false;
      }
    },
  },
});
