import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_AdminUsersTableRow = _resolveComponent("AdminUsersTableRow")!

  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(_ctx.b())
  }, [
    _createElementVNode("div", {
      class: _normalizeClass(_ctx.b('columns'))
    }, [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.displayedColumns, (column) => {
        return (_openBlock(), _createElementBlock("div", {
          class: _normalizeClass(_ctx.b('column')),
          key: column.key
        }, [
          _createElementVNode("p", {
            class: _normalizeClass(_ctx.b('column-label'))
          }, _toDisplayString(_ctx.$t(column.title)), 3)
        ], 2))
      }), 128))
    ], 2),
    _createElementVNode("div", {
      class: _normalizeClass(_ctx.b('rows'))
    }, [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.displayedUsers, (user) => {
        return (_openBlock(), _createBlock(_component_AdminUsersTableRow, {
          class: _normalizeClass(_ctx.b('row')),
          key: user.index,
          user: user,
          "onUpdate:isStatus": _ctx.handleChangeIsStatus,
          "onUpdate:isBalance": _ctx.handleChangeIsBalance
        }, null, 8, ["class", "user", "onUpdate:isStatus", "onUpdate:isBalance"]))
      }), 128))
    ], 2)
  ], 2))
}