import { SvgAttribute } from "@/shared/constants/constants";

export const COMPONENT_NAME = "history-table-row";

export const SVG: SvgAttribute = {
  pending: `<svg width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path opacity="0.967" fill-rule="evenodd" clip-rule="evenodd" d="M7.77324 1.64929C9.46336 1.64253 11.1534 1.64929 12.8434 1.66958C12.9428 1.69897 13.0374 1.73953 13.1274 1.79126C15.4732 4.13707 17.819 6.4829 20.1648 8.8287C20.2165 8.91862 20.2571 9.01329 20.2865 9.11263C20.3135 10.8027 20.3135 12.4927 20.2865 14.1828C20.2817 15.4728 19.9843 16.6897 19.3941 17.8334C18.6973 18.9232 17.717 19.633 16.4534 19.9629C15.9203 20.1013 15.3795 20.1959 14.831 20.2468C13.5471 20.2914 12.2627 20.3116 10.9776 20.3076C9.69253 20.3116 8.40811 20.2914 7.12425 20.2468C6.06186 20.1773 5.06133 19.8933 4.1227 19.395C2.88246 18.5868 2.13207 17.4443 1.87153 15.9675C1.7558 15.3781 1.6882 14.7833 1.66872 14.1828C1.64168 12.0466 1.64168 9.91035 1.66872 7.77409C1.68838 6.70956 1.89119 5.68201 2.27714 4.69141C2.91799 3.28039 3.99287 2.38128 5.50179 1.99407C6.25421 1.80841 7.01137 1.69348 7.77324 1.64929ZM7.73268 2.98783C9.16584 2.98783 10.599 2.98783 12.0322 2.98783C12.0159 4.25964 12.0362 5.53055 12.093 6.80062C12.197 8.43188 13.042 9.42564 14.6281 9.78189C14.9634 9.84525 15.3014 9.88581 15.6422 9.90358C16.7508 9.92386 17.8595 9.93063 18.9682 9.92386C18.975 11.3436 18.9682 12.7633 18.948 14.1828C18.9498 15.2976 18.6794 16.3387 18.1367 17.3061C17.5543 18.0623 16.7904 18.5423 15.845 18.746C15.4961 18.8177 15.1446 18.8717 14.7904 18.9082C12.4384 18.9735 10.0859 18.987 7.73268 18.9488C6.63224 18.9441 5.60465 18.6737 4.65 18.1376C3.96138 17.6338 3.50845 16.9645 3.29118 16.1298C3.11737 15.4925 3.02273 14.8435 3.00725 14.1828C2.98021 12.0466 2.98021 9.91035 3.00725 7.77409C3.00769 6.74488 3.23754 5.77141 3.6968 4.85366C4.19726 4.05684 4.90708 3.53629 5.82628 3.29204C6.45779 3.12816 7.09326 3.02675 7.73268 2.98783ZM13.3707 3.96131C14.9174 5.48772 16.4588 7.02229 17.9948 8.56505C17.0609 8.59989 16.128 8.58638 15.196 8.52448C14.226 8.46011 13.6514 7.95309 13.4721 7.00342C13.4317 6.76164 13.4047 6.51827 13.391 6.27332C13.3707 5.50277 13.364 4.7321 13.3707 3.96131Z" fill="#9E9FA8"/>
    <path opacity="0.974" fill-rule="evenodd" clip-rule="evenodd" d="M9.92423 11.1814C10.1235 11.1636 10.3127 11.1974 10.4921 11.2828C11.1343 11.925 11.7766 12.5672 12.4188 13.2094C12.5618 13.4739 12.5483 13.7308 12.3782 13.9801C11.7495 14.6088 11.1208 15.2375 10.4921 15.8662C9.9091 16.1021 9.55759 15.9128 9.43749 15.2984C9.4523 15.1457 9.49963 15.0038 9.57946 14.8725C9.78904 14.6629 9.99858 14.4533 10.2082 14.2438C8.95075 14.2303 7.69334 14.2167 6.43593 14.2032C5.99442 13.9888 5.87951 13.6575 6.09116 13.2094C6.16275 13.0889 6.26416 13.001 6.39537 12.9458C7.66629 12.9323 8.93725 12.9187 10.2082 12.9052C9.99858 12.6956 9.78904 12.4861 9.57946 12.2765C9.33333 11.7914 9.44828 11.4264 9.92423 11.1814Z" fill="#9E9FA8"/>
  </svg>`,
  waiting: `<svg width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
  <path opacity="0.969" fill-rule="evenodd" clip-rule="evenodd" d="M6.93935 2.04102C9.63212 2.03385 12.3248 2.04102 15.0175 2.0625C15.8338 2.0809 16.6358 2.19548 17.4237 2.40625C18.9703 2.95029 19.9945 4.00303 20.496 5.56445C20.6283 6.04313 20.7142 6.53009 20.7538 7.02539C20.8965 9.66084 20.8965 12.2962 20.7538 14.9316C20.659 16.9785 19.7065 18.4609 17.8964 19.3789C16.9671 19.7128 16.0075 19.8847 15.0175 19.8945C13.2844 19.9232 11.5513 19.9232 9.81826 19.8945C9.36322 19.661 9.24144 19.31 9.45302 18.8418C9.55129 18.7145 9.67302 18.6214 9.81826 18.5625C11.5514 18.5402 13.2845 18.5115 15.0175 18.4766C17.8294 18.5147 19.2832 17.1326 19.3788 14.3301C19.4435 12.4829 19.4579 10.6352 19.4218 8.78711C19.4214 8.00933 19.3641 7.2359 19.2499 6.4668C18.2115 7.50522 17.1731 8.54361 16.1347 9.58203C15.4869 10.2013 14.8137 10.7886 14.1151 11.3438C12.024 12.9193 9.93285 12.9193 7.84169 11.3438C7.14311 10.7886 6.46992 10.2013 5.82216 9.58203C4.78374 8.54361 3.74534 7.50522 2.70693 6.4668C2.6463 6.85158 2.60333 7.2383 2.57802 7.62695C2.53455 8.81538 2.5059 10.0042 2.49208 11.1934C2.32091 11.6119 2.01296 11.7623 1.56826 11.6445C1.34235 11.5476 1.19196 11.3829 1.11708 11.1504C1.08028 9.77393 1.10892 8.39893 1.20302 7.02539C1.29786 4.97857 2.25033 3.49617 4.06044 2.57813C4.99772 2.25014 5.95734 2.0711 6.93935 2.04102ZM9.04482 3.41602C11.036 3.41013 13.0269 3.43161 15.0175 3.48047C15.7497 3.49913 16.4659 3.61371 17.1659 3.82422C17.7821 4.08237 18.2834 4.4834 18.6698 5.02734C17.1635 6.56236 15.6309 8.06627 14.0722 9.53906C13.48 10.0573 12.8354 10.5013 12.1386 10.8711C11.2237 11.2307 10.3357 11.1734 9.47451 10.6992C8.92047 10.3457 8.39053 9.959 7.88466 9.53906C6.32588 8.06627 4.79336 6.56236 3.28701 5.02734C3.72725 4.3988 4.31447 3.96912 5.04872 3.73828C5.47235 3.6307 5.90204 3.55909 6.33779 3.52344C7.24671 3.48149 8.14905 3.44568 9.04482 3.41602Z" fill="#FCCA13"/>
  <path opacity="0.957" fill-rule="evenodd" clip-rule="evenodd" d="M0.667807 13.9434C1.7278 13.9362 2.7877 13.9434 3.84749 13.9648C4.22673 14.1143 4.39144 14.3935 4.34164 14.8027C4.27718 15.0963 4.09814 15.2754 3.80453 15.3399C2.7876 15.3685 1.77067 15.3685 0.753745 15.3399C0.218297 15.1378 0.067906 14.7725 0.302573 14.2441C0.407467 14.1172 0.529212 14.017 0.667807 13.9434Z" fill="#FCCA13"/>
  <path opacity="0.963" fill-rule="evenodd" clip-rule="evenodd" d="M0.667807 16.6934C2.64442 16.6862 4.62098 16.6934 6.59749 16.7148C6.92462 16.8238 7.09649 17.053 7.11312 17.4023C7.07896 17.7581 6.89278 17.9872 6.55453 18.0898C4.62093 18.1185 2.68734 18.1185 0.753745 18.0898C0.218297 17.8878 0.067906 17.5225 0.302573 16.9941C0.407467 16.8672 0.529212 16.767 0.667807 16.6934Z" fill="#FCCA13"/>
  </svg>`,
  reject: `<svg width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
  <path opacity="0.986" fill-rule="evenodd" clip-rule="evenodd" d="M5.77953 2.04102C9.24569 2.03385 12.7118 2.04102 16.178 2.0625C17.7755 2.32823 18.9142 3.19477 19.594 4.66211C19.738 5.02253 19.8383 5.39494 19.8948 5.7793C19.9234 9.24546 19.9234 12.7116 19.8948 16.1777C19.4866 18.2474 18.2476 19.4863 16.178 19.8945C12.7118 19.9232 9.24569 19.9232 5.77953 19.8945C4.1815 19.6282 3.04283 18.7617 2.36351 17.2949C2.21933 16.934 2.11907 16.5617 2.06273 16.1777C2.02048 12.6243 2.0348 9.07225 2.1057 5.52149C2.60166 3.58593 3.82627 2.42578 5.77953 2.04102ZM6.33812 3.41602C9.43191 3.40885 12.5257 3.41602 15.6194 3.4375C16.9354 3.52583 17.8449 4.17752 18.3479 5.39258C18.455 5.71317 18.5123 6.04261 18.5198 6.38086C18.5484 9.44595 18.5484 12.5111 18.5198 15.5762C18.4089 17.0375 17.657 17.99 16.2639 18.4336C16.0368 18.4777 15.8077 18.5063 15.5764 18.5195C12.5113 18.5482 9.44618 18.5482 6.38109 18.5195C4.9904 18.4318 4.05225 17.7372 3.56663 16.4355C3.49027 16.1542 3.4473 15.8678 3.43773 15.5762C3.39624 12.3527 3.41056 9.13009 3.4807 5.9082C3.84221 4.39338 4.79468 3.56264 6.33812 3.41602Z" fill="#F86E6E"/>
  <path opacity="0.962" fill-rule="evenodd" clip-rule="evenodd" d="M7.8427 7.36913C8.06945 7.34468 8.28429 7.38052 8.48723 7.47655C9.31137 8.30787 10.1421 9.13145 10.9794 9.94726C11.8167 9.13145 12.6475 8.30787 13.4716 7.47655C13.8189 7.30885 14.1412 7.34468 14.4384 7.58398C14.6186 7.87603 14.6329 8.17682 14.4814 8.48632C13.6501 9.31046 12.8265 10.1412 12.0107 10.9785C12.8265 11.8158 13.6501 12.6466 14.4814 13.4707C14.6491 13.818 14.6132 14.1403 14.374 14.4375C14.0819 14.6177 13.7811 14.632 13.4716 14.4805C12.6475 13.6491 11.8167 12.8256 10.9794 12.0098C10.1421 12.8256 9.31137 13.6491 8.48723 14.4805C8.13992 14.6482 7.81765 14.6123 7.52044 14.373C7.34027 14.081 7.32596 13.7802 7.47747 13.4707C8.30879 12.6466 9.13237 11.8158 9.94817 10.9785C9.13237 10.1412 8.30879 9.31046 7.47747 8.48632C7.24535 7.98668 7.36712 7.61427 7.8427 7.36913Z" fill="#F86E6E"/>
  </svg>`,
  in_work: `<svg width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
  <path opacity="0.964" fill-rule="evenodd" clip-rule="evenodd" d="M6.42188 2.25586C6.98245 2.29221 7.51238 2.4426 8.01172 2.70703C11.1914 4.49741 14.3711 6.28775 17.5508 8.07812C18.9653 9.24748 19.3592 10.7013 18.7324 12.4395C18.4938 12.9788 18.1428 13.4299 17.6797 13.793C14.5213 15.5942 11.3559 17.3846 8.18359 19.1641C6.52174 20.0386 5.03931 19.8023 3.73633 18.4551C3.31432 17.9259 3.05651 17.3244 2.96289 16.6504C2.93424 12.8691 2.93424 9.08789 2.96289 5.30664C3.34834 3.40967 4.50134 2.39274 6.42188 2.25586ZM5.99219 3.7168C6.55276 3.64926 7.06838 3.77101 7.53906 4.08203C10.5755 5.78647 13.612 7.49087 16.6484 9.19531C17.4418 9.78476 17.7354 10.5654 17.5293 11.5371C17.3707 12.111 17.0341 12.5479 16.5195 12.8477C13.3311 14.6352 10.1371 16.4112 6.9375 18.1758C6.00332 18.3896 5.25136 18.1103 4.68164 17.3379C4.53031 17.0844 4.43006 16.8123 4.38086 16.5215C4.3522 12.8262 4.3522 9.13086 4.38086 5.43555C4.57388 4.53372 5.11099 3.96079 5.99219 3.7168Z" fill="#36A1EA"/>
  </svg>`,
  completed: `<svg width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
  <path opacity="0.967" fill-rule="evenodd" clip-rule="evenodd" d="M7.84856 1.85758C9.93755 1.85097 12.0265 1.85758 14.1154 1.87741C15.3767 1.88205 16.5666 2.17292 17.6851 2.75001C18.7508 3.43143 19.4449 4.38996 19.7674 5.62561C19.9028 6.14683 19.9953 6.67566 20.0451 7.21215C20.0887 8.46757 20.1085 9.72356 20.1046 10.9802C20.1085 12.2368 20.0887 13.4928 20.0451 14.7482C19.9771 15.7869 19.6995 16.7653 19.2121 17.6833C18.5307 18.749 17.5722 19.4431 16.3365 19.7656C15.8152 19.901 15.2864 19.9935 14.75 20.0433C13.4946 20.0869 12.2386 20.1067 10.982 20.1028C9.72535 20.1067 8.46937 20.0869 7.21394 20.0433C6.17508 19.9753 5.1967 19.6976 4.27885 19.2103C3.06607 18.4201 2.3323 17.3029 2.07752 15.8588C1.96436 15.2824 1.89826 14.7007 1.87921 14.1136C1.85276 12.0246 1.85276 9.93572 1.87921 7.84676C1.89843 6.8058 2.09675 5.801 2.47416 4.83234C3.10082 3.45256 4.1519 2.57336 5.6274 2.19472C6.36328 2.01315 7.10364 1.90078 7.84856 1.85758ZM7.80889 3.16648C9.9243 3.15986 12.0397 3.16648 14.155 3.18631C15.1485 3.18927 16.0872 3.41403 16.9711 3.86059C17.7503 4.34996 18.2593 5.04406 18.4982 5.94292C18.616 6.37215 18.6953 6.80845 18.7362 7.25181C18.7998 9.53849 18.813 11.8258 18.7758 14.1136C18.7586 14.9307 18.6132 15.724 18.3395 16.4934C17.8869 17.5275 17.1134 18.1952 16.0192 18.4964C15.5902 18.6142 15.1539 18.6935 14.7103 18.7344C12.4104 18.7982 10.11 18.8114 7.80889 18.774C7.00539 18.7525 6.22533 18.6071 5.46875 18.3377C4.43467 17.8851 3.767 17.1116 3.46575 16.0174C3.29578 15.3943 3.20324 14.7597 3.1881 14.1136C3.16166 12.0246 3.16166 9.93572 3.1881 7.84676C3.18627 6.75665 3.45069 5.73861 3.98137 4.79268C4.47399 4.11931 5.12844 3.6764 5.94471 3.46395C6.56223 3.3037 7.18364 3.20454 7.80889 3.16648Z" fill="#73D264"/>
  <path opacity="0.959" fill-rule="evenodd" clip-rule="evenodd" d="M14.3905 7.92609C15.1066 7.93481 15.3512 8.27858 15.1243 8.95734C13.4507 10.6441 11.765 12.3166 10.0672 13.9748C9.81633 14.0674 9.57835 14.0409 9.35326 13.8954C8.52695 13.0691 7.7006 12.2428 6.87429 11.4165C6.67491 11.0906 6.69474 10.7799 6.93379 10.4844C7.20338 10.3181 7.48103 10.3049 7.76672 10.4447C8.43438 11.1124 9.10207 11.7801 9.76973 12.4477C11.2307 10.9868 12.6916 9.52583 14.1525 8.06491C14.2292 8.00704 14.3085 7.96079 14.3905 7.92609Z" fill="#73D264"/>
  </svg>`,
};
