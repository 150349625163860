import { PropType, defineComponent } from "vue";

import { COMPONENT_NAME, SVG } from "./attributes";

import { SvgAttribute } from "@/shared/constants/constants";
import { SearchType } from "@/shared/constants/enums";
import {
  AdminUsersFilterOption,
  AdminUsersFilterOptions,
} from "@/data/admin/users/filter";

export default defineComponent({
  name: COMPONENT_NAME,
  props: {
    value: {
      type: String,
      default: "",
    },
    type: {
      type: <PropType<SearchType>>String,
      default: SearchType.all,
    },
  },
  emits: {
    "update:value": null,
    "update:type": null,
  },
  data() {
    return {
      isToggle: false,
    };
  },
  computed: {
    displayedOptions(): AdminUsersFilterOption[] {
      return AdminUsersFilterOptions(this.type);
    },

    displayedIcons(): SvgAttribute {
      return SVG;
    },

    isClearShow(): boolean {
      return !!this.value;
    },
  },
  methods: {
    handleToggle(): void {
      this.isToggle = !this.isToggle;
    },

    handleHide(): void {
      this.isToggle = false;
    },

    handleValue(event: Event): void {
      this.$emit("update:value", (<HTMLInputElement>event.target).value);
    },

    handleClear(): void {
      this.$emit("update:value", "");
    },

    handleOption(type: SearchType): void {
      this.$emit("update:type", type);
    },
  },
});
