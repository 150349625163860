import { renderSlot as _renderSlot, toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(_ctx.b())
  }, [
    _renderSlot(_ctx.$slots, "default"),
    (_ctx.displayedLabel)
      ? (_openBlock(), _createElementBlock("p", {
          key: 0,
          class: _normalizeClass(_ctx.b('label', { center: _ctx.isCenter, absolute: _ctx.isAbsolute }))
        }, _toDisplayString(_ctx.displayedLabel), 3))
      : _createCommentVNode("", true)
  ], 2))
}