import { Sort } from "@/shared/constants/interfaces";

export const SortFactory = (payload: Partial<Sort> = {}): Sort => {
  return {
    page: payload.page ?? 1,
    key: payload.key ?? "",
    isUp: payload.isUp ?? false,
    isDown: payload.isDown ?? false,
  };
};
