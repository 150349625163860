import { ApplicationStatus } from "@/shared/constants/enums";
import { AdminApplicationType } from "../admin/application/enums";
import { AdminCurrencyType } from "../admin/currency/enums";
import { AdminRequisitesType } from "../admin/requisites/enums";
import {
  ApplicationCommissionRequest,
  ApplicationCommissionResponse,
  ApplicationFilterRequest,
  ApplicationHistoryResponse,
  ApplicationShowResponse,
  ApplicationStoreRequest,
} from "./repo";
import { UserResponseFactory } from "../user/factory";

export const ApplicationStoreRequestFactory = (
  payload: Partial<ApplicationStoreRequest> = {}
): ApplicationStoreRequest => {
  return {
    amount: payload.amount ?? 0,
    currency_id_from: payload.currency_id_from ?? 0,
    currency_type_from: payload.currency_type_from ?? AdminCurrencyType.fiat,
    currency_id_to: payload.currency_id_to ?? 0,
    currency_type_to: payload.currency_type_to ?? AdminCurrencyType.fiat,
    user_requisite_type_from:
      payload.user_requisite_type_from ?? AdminRequisitesType.bank,
    user_requisite_type_to:
      payload.user_requisite_type_to ?? AdminRequisitesType.bank,
    user_requisite_id_to: payload.user_requisite_id_to ?? 0,
    user_requisite_id_from: payload.user_requisite_id_from ?? 0,
    commissions: payload.commissions ?? 0,
    execution_price: payload.execution_price ?? 0,
    transaction_volume: payload.transaction_volume ?? 0,
    max_amount: payload.max_amount ?? 0,
    new_requisite: payload.new_requisite ?? {},
  };
};

export const ApplicationShowResponseFactory = (
  payload: Partial<ApplicationShowResponse> = {}
): ApplicationShowResponse => {
  return {
    id: payload.id ?? 0,
    user_id: payload.user_id ?? 0,
    app_type: payload.app_type ?? AdminApplicationType.deposit,
    currency_type: payload.currency_type ?? AdminCurrencyType.fiat,
    currency_id: payload.currency_id ?? 0,
    user_requisite_id: payload.user_requisite_id ?? 0,
    user_requisite_type:
      payload.user_requisite_type ?? AdminRequisitesType.bank,
    amount: payload.amount ?? 0,
    execution_price: payload.execution_price ?? 0,
    transaction_volume: payload.transaction_volume ?? 0,
    commissions: payload.commissions ?? 0,
    status: payload.status ?? ApplicationStatus.pending,
    stage: payload.stage ?? "",
    estimate_completion: payload.estimate_completion ?? "",
    deleted_at: payload.deleted_at ?? "",
    created_at: payload.created_at ?? "",
    updated_at: payload.updated_at ?? "",
    user: UserResponseFactory(payload.user ?? {}),
  };
};

export const ApplicationCommissionResponseFactory = (
  payload: Partial<ApplicationCommissionResponse> = {}
): ApplicationCommissionResponse => {
  return {
    currency_id_from: payload.currency_id_from ?? 0,
    currency_id_to: payload.currency_id_to ?? 0,
    currency_type_from: payload.currency_type_from ?? AdminCurrencyType.fiat,
    currency_type_to: payload.currency_type_to ?? AdminCurrencyType.fiat,
    amount: payload.amount ?? 0,
  };
};

export const ApplicationHistoryResponseFactory = (
  payload: Partial<ApplicationHistoryResponse> = {}
): ApplicationHistoryResponse => {
  return {
    id: payload.id ?? 0,
    user_id: payload.user_id ?? 0,
    app_type: payload.app_type ?? AdminApplicationType.deposit,
    currency_type: payload.currency_type ?? AdminCurrencyType.fiat,
    currency_id: payload.currency_id ?? 0,
    user_requisite_id: payload.user_requisite_id ?? 0,
    user_requisite_type:
      payload.user_requisite_type ?? AdminRequisitesType.bank,
    amount: payload.amount ?? 0,
    execution_price: payload.execution_price ?? 0,
    transaction_volume: payload.transaction_volume ?? 0,
    commissions: payload.commissions ?? 0,
    status: payload.status ?? ApplicationStatus.pending,
    stage: payload.stage ?? "",
    estimate_completion: payload.estimate_completion ?? "",
    deleted_at: payload.deleted_at ?? "",
    created_at: payload.created_at ?? "",
    updated_at: payload.updated_at ?? "",
    user: UserResponseFactory(payload.user ?? {}),
  };
};

export const ApplicationFilterRequestFactory = (
  payload: Partial<ApplicationFilterRequest> = {}
): ApplicationFilterRequest => {
  return {
    date_from: payload.date_from ?? "",
    date_to: payload.date_to ?? "",
    amount_from: payload.amount_from ?? 0,
    amount_to: payload.amount_to ?? 0,
    status: payload.status ?? ApplicationStatus.default,
    page: payload.page ?? 1,
    per_page: payload.per_page ?? 10,
    currency_id: payload.currency_id ?? [],
  };
};

export const ApplicationCommissionRequestFactory = (
  payload: Partial<ApplicationCommissionRequest> = {}
): ApplicationCommissionRequest => {
  return {
    currency_id_from: payload.currency_id_from ?? 0,
    currency_id_to: payload.currency_id_to ?? 0,
    currency_type_from: payload.currency_type_from ?? AdminCurrencyType.fiat,
    currency_type_to: payload.currency_type_to ?? AdminCurrencyType.fiat,
    amount: payload.amount ?? 0,
  };
};
