import { defineComponent } from "vue";

import { PAGE_NAME } from "./attributes";
import { VerificationKybComponent, VerificationKycComponent } from "./model";

import { VerificationTabs, VerificationModal } from "@/components/verification";

import { KycPersonalInformation, KycDocuments } from "@/components/pages/kyc";
import {
  KybCompany,
  KybBasicInformation,
  KybRegistrationInformation,
  KybParticipants,
  KybParticipant,
  KybParticipantFiles,
  KybDocuments,
} from "@/components/pages/kyb";

import { ApplicationType } from "@/shared/constants/enums";
import { ModalFactory } from "@/shared/factory/modal";
import {
  ModalIcon,
  ModalLabel,
  ModalTitle,
} from "@/shared/constants/constants";

type KycComponentType = typeof KycPersonalInformation | typeof KycDocuments;

type KybComponentType =
  | typeof KybCompany
  | typeof KybBasicInformation
  | typeof KybRegistrationInformation
  | typeof KybParticipants
  | typeof KybParticipant
  | typeof KybParticipantFiles
  | typeof KybDocuments;

export default defineComponent({
  name: PAGE_NAME,
  components: {
    VerificationTabs,
    VerificationModal,
    KycPersonalInformation,
    KycDocuments,
    KybCompany,
    KybBasicInformation,
    KybRegistrationInformation,
    KybParticipants,
    KybParticipant,
    KybParticipantFiles,
    KybDocuments,
  },
  data() {
    return {
      isModal: false,
      tab: ApplicationType.kyc,
      kycComponent: VerificationKycComponent.kycPersonalInformation,
      kybComponent: VerificationKybComponent.kybCompany,
      userRepo: this.$projectServices.userRepo,
      modalRepo: this.$projectServices.modalRepo,
    };
  },
  created(): void {
    const { application_type } = this.userRepo.userInfo.user_info;

    switch (this.userRepo.userInfo.user_info.application_type) {
      case ApplicationType.kyc:
      case ApplicationType.kyb:
        this.tab = application_type!;

        if (this.userRepo.isVerificationReview) {
          this.modalRepo.UPDATE_MODAL(
            ModalFactory({
              title: ModalTitle.application,
              label: ModalLabel.application,
              icon: ModalIcon.application,
              callback: () => {},
            })
          );
        }

        break;
      default:
        break;
    }
  },
  computed: {
    displayedKycComponent(): KycComponentType {
      switch (this.kycComponent) {
        case VerificationKycComponent.kycPersonalInformation:
          return KycPersonalInformation;
        case VerificationKycComponent.kycDocuments:
          return KycDocuments;
        default:
          return KycPersonalInformation;
      }
    },

    displayedKybComponent(): KybComponentType {
      switch (this.kybComponent) {
        case VerificationKybComponent.kybCompany:
          return KybCompany;
        case VerificationKybComponent.kybBasicInformation:
          return KybBasicInformation;
        case VerificationKybComponent.kybRegistrationInformation:
          return KybRegistrationInformation;
        case VerificationKybComponent.kybParticipants:
          return KybParticipants;
        case VerificationKybComponent.kybParticipant:
          return KybParticipant;
        case VerificationKybComponent.kybParticipantFiles:
          return KybParticipantFiles;
        case VerificationKybComponent.kybDocuments:
          return KybDocuments;
        default:
          return KybCompany;
      }
    },

    isKycShow(): boolean {
      return this.tab === ApplicationType.kyc;
    },

    isKybShow(): boolean {
      return this.tab === ApplicationType.kyb;
    },
  },
  methods: {
    handleTab(tab: ApplicationType): void {
      if (this.tab !== tab) {
        this.isModal = true;
      }
    },
  },
});
