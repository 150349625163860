<script lang="ts" src="./component.ts"></script>
<style lang="scss" src="./component.scss"></style>

<template>
  <div :class="b()">
    <div :class="b('fields')">
      <SharedValidate
        field="deposit_fiat_commission"
        :value="commissionDepositWithdrawalStoreRequest.deposit_fiat_commission"
        :is-validate="isValidate"
        v-model:field-value="
          commissionDepositWithdrawalStoreFields.deposit_fiat_commission
        "
      >
        <AdminConfigCommissionField title="Комиссия на ввод (Фиат)">
          <SharedInputDouble
            view="light"
            v-model="
              commissionDepositWithdrawalStoreRequest.deposit_fiat_commission
            "
          />
        </AdminConfigCommissionField>
      </SharedValidate>

      <SharedValidate
        field="withdrawal_fiat_commission"
        :value="
          commissionDepositWithdrawalStoreRequest.withdrawal_fiat_commission
        "
        :is-validate="isValidate"
        v-model:field-value="
          commissionDepositWithdrawalStoreFields.withdrawal_fiat_commission
        "
      >
        <AdminConfigCommissionField title="Комиссия на вывод (Фиат)">
          <SharedInputDouble
            view="light"
            v-model="
              commissionDepositWithdrawalStoreRequest.withdrawal_fiat_commission
            "
          />
        </AdminConfigCommissionField>
      </SharedValidate>

      <SharedValidate
        field="deposit_crypto_commission"
        :value="
          commissionDepositWithdrawalStoreRequest.deposit_crypto_commission
        "
        :is-validate="isValidate"
        v-model:field-value="
          commissionDepositWithdrawalStoreFields.deposit_crypto_commission
        "
      >
        <AdminConfigCommissionField title="Комиссия на ввод (Крипто)">
          <SharedInputDouble
            view="light"
            v-model="
              commissionDepositWithdrawalStoreRequest.deposit_crypto_commission
            "
          />
        </AdminConfigCommissionField>
      </SharedValidate>

      <SharedValidate
        field="withdrawal_crypto_commission"
        :value="
          commissionDepositWithdrawalStoreRequest.withdrawal_crypto_commission
        "
        :is-validate="isValidate"
        v-model:field-value="
          commissionDepositWithdrawalStoreFields.withdrawal_crypto_commission
        "
      >
        <AdminConfigCommissionField title="Комиссия на вывод (Крипто)">
          <SharedInputDouble
            view="light"
            v-model="
              commissionDepositWithdrawalStoreRequest.withdrawal_crypto_commission
            "
          />
        </AdminConfigCommissionField>
      </SharedValidate>
    </div>

    <UIButton
      :class="b('save')"
      label="Сохранить"
      view="main"
      :disabled="isSaveDisabled"
      @click="handleSave"
    />
  </div>
</template>
