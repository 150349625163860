import { toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, withModifiers as _withModifiers, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createTextVNode as _createTextVNode } from "vue"

const _hoisted_1 = ["innerHTML"]
const _hoisted_2 = ["innerHTML"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("button", {
    class: _normalizeClass(_ctx.b()),
    onClick: _cache[2] || (_cache[2] = 
//@ts-ignore
(...args) => (_ctx.handleRow && _ctx.handleRow(...args)))
  }, [
    _createElementVNode("div", {
      class: _normalizeClass(_ctx.b('row-date-time'))
    }, [
      _createElementVNode("p", {
        class: _normalizeClass(_ctx.b('row-label', { dateTime: true }))
      }, _toDisplayString(_ctx.row.created_at), 3)
    ], 2),
    _createElementVNode("div", {
      class: _normalizeClass(_ctx.b('row-type'))
    }, [
      _createElementVNode("p", {
        class: _normalizeClass(_ctx.b('row-label'))
      }, _toDisplayString(_ctx.row.app_type === "deposit" ? "Пополнение" : "Вывод"), 3)
    ], 2),
    _createElementVNode("div", {
      class: _normalizeClass(_ctx.b('row-amount'))
    }, [
      _createElementVNode("p", {
        class: _normalizeClass(
          _ctx.b('row-label', {
            deposit: _ctx.row.app_type === 'deposit',
            withdrawal: _ctx.row.app_type === 'withdrawal',
          })
        )
      }, _toDisplayString(_ctx.displayedAmount), 3)
    ], 2),
    _createElementVNode("div", {
      class: _normalizeClass(_ctx.b('row-currency'))
    }, [
      _createElementVNode("p", {
        class: _normalizeClass(_ctx.b('row-label', { currency: true }))
      }, _toDisplayString(_ctx.row.currency.name.toUpperCase()), 3)
    ], 2),
    _createElementVNode("div", {
      class: _normalizeClass(_ctx.b('row-commission'))
    }, [
      _createElementVNode("p", {
        class: _normalizeClass(_ctx.b('row-label'))
      }, _toDisplayString(_ctx.row.commissions), 3)
    ], 2),
    _createElementVNode("div", {
      class: _normalizeClass(_ctx.b('row-status'))
    }, [
      _createElementVNode("div", {
        class: _normalizeClass(_ctx.b('row-group'))
      }, [
        _createElementVNode("span", {
          class: _normalizeClass(_ctx.b('row-icon')),
          innerHTML: _ctx.displayedSvg[_ctx.row.status]
        }, null, 10, _hoisted_1),
        _createElementVNode("p", {
          class: _normalizeClass(_ctx.b('row-label', { status: true }))
        }, _toDisplayString(_ctx.displayedStatus), 3)
      ], 2)
    ], 2),
    _createElementVNode("div", {
      class: _normalizeClass(_ctx.b('row-confirm'))
    }, [
      (_ctx.isConfirmShow)
        ? (_openBlock(), _createElementBlock("button", {
            key: 0,
            class: _normalizeClass(_ctx.b('row-button')),
            onClick: _cache[0] || (_cache[0] = _withModifiers(
//@ts-ignore
(...args) => (_ctx.handleConfirm && _ctx.handleConfirm(...args)), ["stop"]))
          }, " Подтвердить ", 2))
        : _createCommentVNode("", true)
    ], 2),
    _createElementVNode("div", {
      class: _normalizeClass(_ctx.b('row-block'))
    }, [
      _createElementVNode("div", {
        class: _normalizeClass(_ctx.b('row-block-group'))
      }, [
        _createElementVNode("div", {
          class: _normalizeClass(_ctx.b('row-block-type'))
        }, [
          _createElementVNode("p", {
            class: _normalizeClass(_ctx.b('row-label'))
          }, _toDisplayString(_ctx.row.app_type === "deposit" ? "Пополнение" : "Вывод"), 3),
          _createElementVNode("span", {
            class: _normalizeClass(
              _ctx.b('row-label', {
                deposit: _ctx.row.app_type === 'deposit',
                withdrawal: _ctx.row.app_type === 'withdrawal',
                block: true,
              })
            )
          }, [
            _createTextVNode(_toDisplayString(_ctx.row.amount) + " ", 1),
            _createElementVNode("p", {
              class: _normalizeClass(_ctx.b('row-label', { currency: true }))
            }, _toDisplayString(_ctx.row.currency.name), 3)
          ], 2)
        ], 2),
        _createElementVNode("div", {
          class: _normalizeClass(_ctx.b('row-block-date'))
        }, [
          _createElementVNode("p", {
            class: _normalizeClass(_ctx.b('row-label', { dateTime: true }))
          }, _toDisplayString(_ctx.row.created_at), 3),
          _createElementVNode("span", {
            class: _normalizeClass(_ctx.b('row-icon')),
            innerHTML: _ctx.displayedSvg[_ctx.row.status]
          }, null, 10, _hoisted_2)
        ], 2)
      ], 2),
      _createElementVNode("div", {
        class: _normalizeClass(_ctx.b('row-block-group'))
      }, [
        _createElementVNode("div", {
          class: _normalizeClass(_ctx.b('row-block-commission'))
        }, [
          _createElementVNode("p", {
            class: _normalizeClass(_ctx.b('row-label'))
          }, _toDisplayString(_ctx.$t("Комиссия")), 3),
          _createElementVNode("p", {
            class: _normalizeClass(_ctx.b('row-label'))
          }, _toDisplayString(_ctx.row.commissions), 3)
        ], 2),
        _createElementVNode("div", {
          class: _normalizeClass(_ctx.b('row-block-other'))
        }, [
          (_ctx.isConfirmShow)
            ? (_openBlock(), _createElementBlock("button", {
                key: 0,
                class: _normalizeClass(_ctx.b('row-button')),
                onClick: _cache[1] || (_cache[1] = _withModifiers(
//@ts-ignore
(...args) => (_ctx.handleConfirm && _ctx.handleConfirm(...args)), ["stop"]))
              }, " Подтвердить ", 2))
            : _createCommentVNode("", true),
          _createElementVNode("div", {
            class: _normalizeClass(_ctx.b('row-block-number'))
          }, [
            _createElementVNode("p", {
              class: _normalizeClass(_ctx.b('row-label'))
            }, "№ " + _toDisplayString(_ctx.$t("Transaction ")), 3),
            _createElementVNode("p", {
              class: _normalizeClass(_ctx.b('row-label'))
            }, _toDisplayString(_ctx.row.id), 3)
          ], 2)
        ], 2)
      ], 2)
    ], 2)
  ], 2))
}