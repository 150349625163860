/* eslint-disable @typescript-eslint/ban-ts-comment */
import { Action, Module, Mutation, VuexModule } from "vuex-module-decorators";

import { $projectServices } from "@/shared/repository/instances/project-services";
import {
  DepositWithdrawalDeposit,
  DepositWithdrawalHistoryResponse,
  DepositWithdrawalStoreRequest,
} from "@/shared/repository/modules/deposit-withdrawal/repo";
import { AdminCurrencyShowResponseFactory } from "@/shared/repository/modules/admin/currency/factory";
import { ApplicationFilterRequest } from "@/shared/repository/modules/application/repo";
import { DepositWithdrawalDepositFactory } from "@/shared/repository/modules/deposit-withdrawal/factory";

enum DepositWithdrawalMutation {
  UPDATE_DEPOSIT = "UPDATE_DEPOSIT",
  UPDATE_DETAILS = "UPDATE_DETAILS",
  UPDATE_HISTORY = "UPDATE_HISTORY",
  UPDATE_APPLICATION = "UPDATE_APPLICATION",
  CLEAR_APPLICATION = "CLEAR_APPLICATION",
}

interface State {
  deposit?: DepositWithdrawalDeposit;
  detailsId?: number;
  detailsCurrencyId?: number;
  history?: DepositWithdrawalHistoryResponse[];
  application?: DepositWithdrawalStoreRequest | null;
}

@Module({
  namespaced: true,
  stateFactory: true,
  name: "depositWithdrawal",
})
export default class DepositWithdrawalModuleState extends VuexModule<State> {
  deposit: DepositWithdrawalDeposit = DepositWithdrawalDepositFactory();
  detailsId: number = 0;
  detailsCurrencyId: number = 0;
  history: DepositWithdrawalHistoryResponse[] = [];
  application: DepositWithdrawalStoreRequest | null = null;

  @Mutation
  [DepositWithdrawalMutation.UPDATE_DEPOSIT](
    deposit: DepositWithdrawalDeposit
  ): void {
    this.deposit = DepositWithdrawalDepositFactory(deposit);
  }

  @Mutation
  [DepositWithdrawalMutation.UPDATE_DETAILS]({
    id,
    currencyId,
  }: {
    id: number;
    currencyId: number;
  }): void {
    this.detailsId = id;
    this.detailsCurrencyId = currencyId;
  }

  @Mutation
  [DepositWithdrawalMutation.UPDATE_HISTORY](
    history: DepositWithdrawalHistoryResponse[]
  ): void {
    this.history = history;
  }

  @Mutation
  [DepositWithdrawalMutation.UPDATE_APPLICATION](
    application: DepositWithdrawalStoreRequest
  ): void {
    this.application = application;
  }

  @Mutation
  [DepositWithdrawalMutation.CLEAR_APPLICATION](): void {
    this.application = null;
  }

  @Action
  async updateHistory(filter: ApplicationFilterRequest): Promise<void> {
    const [history, currency] = await Promise.all([
      $projectServices.depositWithdrawalRepository.history(filter),
      $projectServices.walletRepository.currency(),
    ]);

    const newHistory =
      filter.page === 1 ? history : [...this.history, ...history];

    this.UPDATE_HISTORY(
      newHistory.map((item) => ({
        ...item,
        currency:
          currency.find(
            (currencyItem) => currencyItem.id === item.currency_id
          ) ?? AdminCurrencyShowResponseFactory(),
      }))
    );

    return;
  }
}
