<script lang="ts" src="./component.ts"></script>
<style lang="scss" src="./component.scss"></style>

<template>
  <SharedCard :class="b()">
    <div :class="b('header')">
      <SharedBack @click="handleBack" />

      <h3 :class="b('header-title')">
        {{ $t("Банковские реквизиты") }}
      </h3>
    </div>

    <div :class="b('form')">
      <SharedValidate
        field="name"
        :value="requisitesBankRequest.name"
        :is-validate="isValidate"
        :is-absolute="true"
        v-model:field-value="requisitesBankFields.name"
      >
        <div :class="b('form-group')">
          <h5 :class="b('form-title')">
            {{ $t("Название*") }}
          </h5>

          <UIInput view="light" v-model="requisitesBankRequest.name" />
        </div>
      </SharedValidate>

      <div :class="b('form-group')"></div>

      <div :class="b('form-group')"></div>

      <SharedValidate
        field="account_number"
        :value="requisitesBankRequest.account_number"
        :is-validate="isValidate"
        :is-absolute="true"
        v-model:field-value="requisitesBankFields.account_number"
      >
        <div :class="b('form-group')">
          <h5 :class="b('form-title')">
            {{ $t("Номер расчетного счета*") }}
          </h5>

          <UIInput
            view="light"
            v-model="requisitesBankRequest.account_number"
          />
        </div>
      </SharedValidate>

      <SharedValidate
        field="correspondent_account"
        :value="requisitesBankRequest.correspondent_account"
        :is-validate="isValidate"
        :is-absolute="true"
        v-model:field-value="requisitesBankFields.correspondent_account"
      >
        <div :class="b('form-group')">
          <h5 :class="b('form-title')">
            {{ $t("Номер корреспондентского счета*") }}
          </h5>

          <UIInput
            view="light"
            v-model="requisitesBankRequest.correspondent_account"
          />
        </div>
      </SharedValidate>

      <SharedValidate
        field="bank_identification_code"
        :value="requisitesBankRequest.bank_identification_code"
        :is-validate="isValidate"
        :is-absolute="true"
        v-model:field-value="requisitesBankFields.bank_identification_code"
      >
        <div :class="b('form-group')">
          <h5 :class="b('form-title')">
            {{ $t("Банковский идентификационный код (БИК)*") }}
          </h5>

          <UIInput
            view="light"
            v-model="requisitesBankRequest.bank_identification_code"
          />
        </div>
      </SharedValidate>

      <SharedValidate
        field="bank_name"
        :value="requisitesBankRequest.bank_name"
        :is-validate="isValidate"
        :is-absolute="true"
        v-model:field-value="requisitesBankFields.bank_name"
      >
        <div :class="b('form-group')">
          <h5 :class="b('form-title')">
            {{ $t("Полное наименование банка*") }}
          </h5>

          <UIInput view="light" v-model="requisitesBankRequest.bank_name" />
        </div>
      </SharedValidate>

      <SharedValidate
        field="inn"
        :value="requisitesBankRequest.inn"
        :is-validate="isValidate"
        :is-absolute="true"
        v-model:field-value="requisitesBankFields.inn"
      >
        <div :class="b('form-group')">
          <h5 :class="b('form-title')">
            {{ $t("Идентификационный номер") }}
            <br />
            {{ $t("налогоплательщика (ИНН)*") }}
          </h5>

          <UIInput view="light" v-model="requisitesBankRequest.inn" />
        </div>
      </SharedValidate>

      <SharedValidate
        field="registration_reason_code"
        :value="requisitesBankRequest.registration_reason_code"
        :is-validate="isValidate"
        :is-absolute="true"
        v-model:field-value="requisitesBankFields.registration_reason_code"
      >
        <div :class="b('form-group')">
          <h5 :class="b('form-title')">
            {{ $t("Код причины постановки на учет (КПП)*") }}
          </h5>

          <UIInput
            view="light"
            v-model="requisitesBankRequest.registration_reason_code"
          />
        </div>
      </SharedValidate>
    </div>

    <UIButton
      :class="b('confirm')"
      label="Подтвердить"
      view="main"
      :disabled="isContinueDisabled"
      @click="handleContinue"
    />

    <transition name="opacity">
      <SettingsRequisitesVerification
        v-if="isVerification"
        :class="b('modal')"
        type="bank-requisite"
        @close="handleVerificationClose"
      />
    </transition>
  </SharedCard>
</template>
