import { toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

const _hoisted_1 = ["innerHTML"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_UIButton = _resolveComponent("UIButton")!
  const _component_SharedWindow = _resolveComponent("SharedWindow")!

  return (_openBlock(), _createBlock(_component_SharedWindow, {
    class: _normalizeClass(_ctx.b()),
    title: "Подтверждение заявки",
    onClose: _ctx.handleClose
  }, {
    default: _withCtx(() => [
      _createElementVNode("div", {
        class: _normalizeClass(_ctx.b('content'))
      }, [
        _createElementVNode("div", {
          class: _normalizeClass(_ctx.b('list'))
        }, [
          _createElementVNode("div", {
            class: _normalizeClass(_ctx.b('item'))
          }, [
            _createElementVNode("h4", {
              class: _normalizeClass(_ctx.b('item-title'))
            }, _toDisplayString(_ctx.$t("Со счета")), 3),
            _createElementVNode("p", {
              class: _normalizeClass(_ctx.b('item-label'))
            }, _toDisplayString(_ctx.applicationStoreRequest.user_requisite_type_from === "bank"
                ? _ctx.$t("Банковский счет")
                : _ctx.$t("Крипто счет")), 3)
          ], 2),
          _createElementVNode("div", {
            class: _normalizeClass(_ctx.b('item', { to: true }))
          }, [
            _createElementVNode("div", {
              class: _normalizeClass(_ctx.b('item-group'))
            }, [
              _createElementVNode("h4", {
                class: _normalizeClass(_ctx.b('item-title'))
              }, _toDisplayString(_ctx.$t("На счет")), 3),
              _createElementVNode("p", {
                class: _normalizeClass(_ctx.b('item-label'))
              }, _toDisplayString(_ctx.applicationStoreRequest.user_requisite_type_to === "bank"
                  ? _ctx.$t("Банковский счет")
                  : _ctx.$t("Крипто счет")) + " (" + _toDisplayString(_ctx.displayedCurrencyTo.currency.name.toUpperCase()) + ") ", 3)
            ], 2)
          ], 2),
          _createElementVNode("div", {
            class: _normalizeClass(_ctx.b('item', { operation: true }))
          }, [
            _createElementVNode("h4", {
              class: _normalizeClass(_ctx.b('item-title'))
            }, _toDisplayString(_ctx.$t("Операция")), 3),
            _createElementVNode("div", {
              class: _normalizeClass(_ctx.b('item-operation'))
            }, [
              _createElementVNode("span", {
                class: _normalizeClass(_ctx.b('item-icon')),
                innerHTML: _ctx.displayedSvg.chevron
              }, null, 10, _hoisted_1),
              _createElementVNode("div", {
                class: _normalizeClass(_ctx.b('item-operations'))
              }, [
                _createElementVNode("p", {
                  class: _normalizeClass(_ctx.b('item-label'))
                }, _toDisplayString(_ctx.applicationStoreRequest.amount) + " " + _toDisplayString(_ctx.displayedCurrencyFrom.currency.name.toUpperCase()), 3),
                _createElementVNode("p", {
                  class: _normalizeClass(_ctx.b('item-label'))
                }, " ~ ????? " + _toDisplayString(_ctx.displayedCurrencyTo.currency.name.toUpperCase()), 3)
              ], 2)
            ], 2)
          ], 2),
          _createElementVNode("div", {
            class: _normalizeClass(_ctx.b('item'))
          }, [
            _createElementVNode("h4", {
              class: _normalizeClass(_ctx.b('item-title'))
            }, _toDisplayString(_ctx.$t("Комиссия")), 3),
            _createElementVNode("p", {
              class: _normalizeClass(_ctx.b('item-label'))
            }, _toDisplayString(_ctx.displayedCommissions), 3)
          ], 2)
        ], 2),
        _createVNode(_component_UIButton, {
          label: "Создать заявку",
          view: "main",
          onClick: _ctx.handleCreate
        }, null, 8, ["onClick"])
      ], 2)
    ]),
    _: 1
  }, 8, ["class", "onClose"]))
}