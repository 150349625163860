import { AxiosInstance } from "axios";

import { RequisitesUrlGenerator } from "./url-generator";
import {
  RequisitesBankRequest,
  RequisitesBankResponse,
  RequisitesBankUpdateRequest,
  RequisitesCryptoRequest,
  RequisitesCryptoResponse,
  RequisitesCryptoUpdateRequest,
  RequisitesVerificationRequest,
} from "./repo";
import {
  RequisitesBankResponseFactory,
  RequisitesCryptoResponseFactory,
} from "./factory";

import UserModuleState from "@/store/modules/user";
import { ResponseData } from "@/shared/constants/interfaces";

export interface RequisitesProjectRepository {
  bank(payload: RequisitesBankRequest): Promise<RequisitesBankResponse>;
  bankUpdate(
    id: number,
    payload: RequisitesBankUpdateRequest
  ): Promise<RequisitesBankResponse>;
  bankDelete(id: number): Promise<void>;
  bankShow(id: number): Promise<RequisitesBankResponse>;
  bankList(): Promise<RequisitesBankResponse[]>;

  crypto(payload: RequisitesCryptoRequest): Promise<RequisitesCryptoResponse>;
  cryptoUpdate(
    id: number,
    payload: RequisitesCryptoUpdateRequest
  ): Promise<RequisitesCryptoResponse>;
  cryptoDelete(id: number): Promise<void>;
  cryptoShow(id: number): Promise<RequisitesCryptoResponse>;
  cryptoList(): Promise<RequisitesCryptoResponse[]>;

  verification(payload: RequisitesVerificationRequest): Promise<void>;
}

export class RequisitesHttpRepo implements RequisitesProjectRepository {
  constructor(
    private readonly axios: AxiosInstance,
    private readonly urlGenerator: RequisitesUrlGenerator,
    private readonly userRepo: UserModuleState
  ) {}

  get userId(): number {
    return this.userRepo.userInfo.id;
  }

  async bank(payload: RequisitesBankRequest): Promise<RequisitesBankResponse> {
    const { data } = await this.axios.post<
      ResponseData<RequisitesBankResponse>
    >(this.urlGenerator.bank(), {
      user_id: this.userId,
      ...payload,
    });

    return RequisitesBankResponseFactory(data.data);
  }

  async bankUpdate(
    id: number,
    payload: RequisitesBankUpdateRequest
  ): Promise<RequisitesBankResponse> {
    const { data } = await this.axios.patch<
      ResponseData<RequisitesBankResponse>
    >(this.urlGenerator.bankUpdate(id), payload);

    return RequisitesBankResponseFactory(data.data);
  }

  async bankDelete(id: number): Promise<void> {
    await this.axios.delete<void>(
      this.urlGenerator.bankDelete(this.userId, id)
    );
  }

  async bankShow(id: number): Promise<RequisitesBankResponse> {
    const { data } = await this.axios.get<ResponseData<RequisitesBankResponse>>(
      this.urlGenerator.bankShow(this.userId, id)
    );

    return RequisitesBankResponseFactory(data.data);
  }

  async bankList(): Promise<RequisitesBankResponse[]> {
    const { data } = await this.axios.get<
      ResponseData<RequisitesBankResponse[]>
    >(this.urlGenerator.bankList(this.userId));

    return data.data.map((item) => RequisitesBankResponseFactory(item));
  }

  async crypto(
    payload: RequisitesCryptoRequest
  ): Promise<RequisitesCryptoResponse> {
    const { data } = await this.axios.post<
      ResponseData<RequisitesCryptoResponse>
    >(this.urlGenerator.crypto(), {
      user_id: this.userId,
      ...payload,
    });

    return RequisitesCryptoResponseFactory(data.data);
  }

  async cryptoUpdate(
    id: number,
    payload: RequisitesCryptoUpdateRequest
  ): Promise<RequisitesCryptoResponse> {
    const { data } = await this.axios.patch<
      ResponseData<RequisitesCryptoResponse>
    >(this.urlGenerator.cryptoUpdate(id), payload);

    return RequisitesCryptoResponseFactory(data.data);
  }

  async cryptoDelete(id: number): Promise<void> {
    await this.axios.delete<void>(
      this.urlGenerator.cryptoDelete(this.userId, id)
    );
  }

  async cryptoShow(id: number): Promise<RequisitesCryptoResponse> {
    const { data } = await this.axios.get<
      ResponseData<RequisitesCryptoResponse>
    >(this.urlGenerator.cryptoShow(this.userId, id));

    return RequisitesCryptoResponseFactory(data.data);
  }

  async cryptoList(): Promise<RequisitesCryptoResponse[]> {
    const { data } = await this.axios.get<
      ResponseData<RequisitesCryptoResponse[]>
    >(this.urlGenerator.cryptoList(this.userId));

    return data.data.map((item) => RequisitesCryptoResponseFactory(item));
  }

  async verification(payload: RequisitesVerificationRequest): Promise<void> {
    await this.axios.post<void>(this.urlGenerator.verification(), payload);
  }
}
