import { defineComponent, PropType } from "vue";

import { COMPONENT_NAME, SVG } from "./attributes";

import { DepositWithdrawalHistoryResponse } from "@/shared/repository/modules/deposit-withdrawal/repo";
import { SvgAttribute } from "@/shared/constants/constants";
import { ApplicationStatus } from "@/shared/constants/enums";

export default defineComponent({
  name: COMPONENT_NAME,
  props: {
    row: {
      type: <PropType<DepositWithdrawalHistoryResponse>>Object,
      required: true,
    },
  },
  computed: {
    displayedSvg(): SvgAttribute {
      return SVG;
    },

    displayedStatus(): string {
      switch (this.row.status) {
        case ApplicationStatus.pending:
          return "Создано";
        case ApplicationStatus.waiting:
          return "Ожидание";
        case ApplicationStatus.progress:
          return "Ожидание подтверждения";
        case ApplicationStatus.inWork:
          return "В работе";
        case ApplicationStatus.reject:
          return "Ошибка";
        case ApplicationStatus.completed:
          return "Выполнено";
        default:
          return "Создано";
      }
    },
  },
});
