import { defineComponent, PropType } from "vue";

import { COMPONENT_NAME } from "./attributes";

import { UIButton } from "@/components/ui";
import { SharedVerification, SharedWindow } from "@/components/shared";

import { CODE_MAX_LENGTH } from "@/shared/constants/constants";
import { ApplicationStoreRequest } from "@/shared/repository/modules/application/repo";
import { ApplicationStoreRequestFactory } from "@/shared/repository/modules/application/factory";

export default defineComponent({
  name: COMPONENT_NAME,
  components: {
    UIButton,
    SharedVerification,
    SharedWindow,
  },
  props: {
    applicationStoreRequest: {
      type: <PropType<ApplicationStoreRequest>>Object,
      required: true,
    },
    isLoading: {
      type: Boolean,
      default: false,
    },
    isConfirm: {
      type: Boolean,
      default: false,
    },
  },
  emits: {
    "update:isLoading": null,
    "update:isConfirm": null,
    "update:applicationStoreRequest": null,
  },
  data() {
    return {
      code: "",
      applicationRepository: this.$projectServices.applicationRepository,
    };
  },
  computed: {
    isCreateDisabled(): boolean {
      return this.code.length !== CODE_MAX_LENGTH || this.isLoading;
    },
  },
  methods: {
    handleClose(): void {
      this.$emit("update:isConfirm", false);
    },

    async handleCreate(): Promise<void> {
      try {
        this.$emit("update:isLoading", true);

        // await this.applicationRepository.verification(this.code);
        this.$emit(
          "update:applicationStoreRequest",
          ApplicationStoreRequestFactory()
        );
        this.$emit("update:isConfirm", false);
      } catch (error) {
        console.log(error);
      } finally {
        this.$emit("update:isLoading", false);
      }
    },
  },
});
