import { defineComponent, PropType } from "vue";

import { COMPONENT_NAME, SVG } from "./attributes";

import { WalletMainTableRow } from "./row";

import { WalletListResponse } from "@/shared/repository/modules/wallet/repo";
import { SvgAttribute } from "@/shared/constants/constants";
import { AdminCurrencyType } from "@/shared/repository/modules/admin/currency/enums";

interface WalletMainTableColumn {
  id: number;
  label: string;
  isCurrency: boolean;
  isAmount: boolean;
}

export default defineComponent({
  name: COMPONENT_NAME,
  components: {
    WalletMainTableRow,
  },
  props: {
    list: {
      type: <PropType<WalletListResponse[]>>Array,
      default: () => [],
    },
  },
  data() {
    return {
      isCurrency: false,
      isAmount: false,
    };
  },
  computed: {
    displayedSvg(): SvgAttribute {
      return SVG;
    },

    displayedColumns(): WalletMainTableColumn[] {
      return [
        {
          id: 0,
          label: "Валюта",
          isCurrency: true,
          isAmount: false,
        },
        {
          id: 1,
          label: "Всего",
          isCurrency: false,
          isAmount: true,
        },
        {
          id: 2,
          label: "Действие",
          isCurrency: false,
          isAmount: false,
        },
      ];
    },

    displayedList(): WalletListResponse[] {
      let fiat = this.list
        .slice()
        .filter((item) => item.type === AdminCurrencyType.fiat);
      let crypto = this.list
        .slice()
        .filter((item) => item.type === AdminCurrencyType.crypto);

      if (this.isCurrency) {
        fiat = fiat.sort((a, b) =>
          (a.currency ? a.currency.name : a.name).localeCompare(
            b.currency ? b.currency.name : b.name
          )
        );
        crypto = crypto.sort((a, b) => a.name.localeCompare(b.name));
      } else if (this.isAmount) {
        fiat = fiat.sort((a, b) => a.amount - b.amount);
        crypto = crypto.sort((a, b) => a.amount - b.amount);
      }

      return [...fiat, ...crypto];
    },
  },
  methods: {
    handleSort({ isCurrency, isAmount }: WalletMainTableColumn): void {
      if (isCurrency) {
        this.isAmount = false;

        this.isCurrency = !this.isCurrency;
      } else if (isAmount) {
        this.isCurrency = false;

        this.isAmount = !this.isAmount;
      }
    },
  },
});
