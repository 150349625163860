import { SvgAttribute } from "@/shared/constants/constants";

export const COMPONENT_NAME = "admin-requisites-table-row";

export const SVG: SvgAttribute = {
  dots: `<svg width="16" height="16" viewBox="0 0 16 16" xmlns="http://www.w3.org/2000/svg">
    <circle cx="8" cy="2" r="1.5"/>
    <circle cx="8" cy="8" r="1.5"/>
    <circle cx="8" cy="14" r="1.5"/>
  </svg>`,
};
