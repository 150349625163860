import { defineComponent, PropType } from "vue";

import { COMPONENT_NAME } from "./attributes";

import { SharedCard, SharedBack, SharedAdd } from "@/components/shared";
import { UIButton } from "@/components/ui";

import { VerificationKybComponent } from "@/views/verification/model";
import { KybParticipant } from "@/shared/repository/modules/kyb/repo";
import { KybParticipantFactory } from "@/shared/repository/modules/kyb/factory";

const PARTICIPANTS_MAX_LENGTH = 5;

export default defineComponent({
  name: COMPONENT_NAME,
  components: {
    SharedCard,
    SharedBack,
    SharedAdd,
    UIButton,
  },
  props: {
    component: {
      type: <PropType<VerificationKybComponent>>String,
      default: "",
    },
  },
  emits: {
    "update:component": null,
  },
  data() {
    return {
      isLoading: false,
      kybParticipants: <KybParticipant[]>[],
      userRepo: this.$projectServices.userRepo,
      kybRepo: this.$projectServices.kybRepo,
      kybRepository: this.$projectServices.kybRepository,
    };
  },
  async created(): Promise<void> {
    try {
      this.isLoading = true;

      this.kybParticipants = await this.kybRepository.participants();
    } catch (e) {
      console.log(e);
    } finally {
      this.isLoading = false;
    }
  },
  computed: {
    displayedParticipants(): KybParticipant[] {
      return [
        ...this.kybParticipants.map((kybParticipant) =>
          KybParticipantFactory(kybParticipant)
        ),
        KybParticipantFactory(),
      ].filter((participant) =>
        this.isReadonly ||
        this.kybParticipants.length === PARTICIPANTS_MAX_LENGTH
          ? !!participant.id
          : participant
      );
    },

    isReadonly(): boolean {
      return this.userRepo.isReadonlyKyb;
    },

    isContinueDisabled(): boolean {
      return this.isLoading || !this.kybParticipants.length;
    },
  },
  methods: {
    handleBack(): void {
      this.$emit("update:component", VerificationKybComponent.kybCompany);
    },

    handleContinue(): void {
      this.$emit("update:component", VerificationKybComponent.kybCompany);
    },

    handleParticipant({ id }: KybParticipant): void {
      this.kybRepo.UPDATE_PARTICIPANT_ID(id);

      this.$emit("update:component", VerificationKybComponent.kybParticipant);
    },

    async handleParticipantRemove({ id }: KybParticipant): Promise<void> {
      try {
        this.isLoading = true;

        await this.kybRepository.participantDelete(id!);

        this.kybParticipants = await this.kybRepository.participants();
      } catch (error) {
        console.log(error);
      } finally {
        this.isLoading = false;
      }
    },
  },
});
