<script lang="ts" src="./component.ts"></script>
<style lang="scss" src="./component.scss"></style>

<template>
  <div :class="b()" v-click-outside="handleHide">
    <button
      :class="b('toggle', { view })"
      :disabled="isReadonly"
      @click="changeIsVisible"
    >
      <div :class="b('group')">
        <span
          v-if="icon && !displayedCryptoIcon"
          :class="[b('icon'), 'fi', `fi-${icon}`]"
        ></span>

        <img
          v-if="icon && isCryptoIcon"
          :class="b('icon', { crypto: true })"
          :src="displayedCryptoIcon"
        />

        <span :class="b('toggle-label')">
          {{ displayedPlaceholder }}
        </span>
      </div>

      <span
        :class="b('toggle-icon', { active: isVisible })"
        v-html="displayedIcon"
      ></span>
    </button>

    <div :class="b('options', { view, visible: isVisible })">
      <UIInput
        v-if="isSearch"
        :class="b('options-search')"
        placeholder="Поиск"
        view="light"
        v-model="search"
      />

      <button
        :class="[b('option', { view }), displayedOptionActiveClass(option)]"
        v-for="option in displayedOptions"
        :key="option.id"
        @click="changeOption(option)"
      >
        <span
          v-if="option.icon && !displaydSvg[option.icon] && !isCryptoIcon"
          :class="[b('icon'), 'fi', `fi-${option.icon}`]"
        ></span>

        <img
          v-if="isCryptoIcon"
          :class="b('icon', { crypto: true })"
          :src="
            require(`@/assets/images/crypto/${option.icon.toLowerCase()}.svg`)
          "
        />

        {{ option.label }}
      </button>
    </div>
  </div>
</template>
