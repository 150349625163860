import { defineComponent } from "vue";

import { COMPONENT_NAME } from "./attributes";

import { SharedUpload } from "@/components/shared";
import {
  ApplicationType,
  KybDocumentsFileName,
  KycDocumentsFileName,
} from "@/shared/constants/enums";
import { KycUploadFileResponseFactory } from "@/shared/repository/modules/kyc/factory";
import { KybDocuments, KycDocuments } from "@/shared/constants/interfaces";
import { extractFileName } from "@/shared/utils/file-name-helpers";
import { KybUploadFileResponseFactory } from "@/shared/repository/modules/kyb/factory";

export default defineComponent({
  name: COMPONENT_NAME,
  components: {
    SharedUpload,
  },
  data() {
    return {
      isLoading: false,
      kycDocuments: Object.keys(KycDocumentsFileName).reduce(
        (documents, fileName) => {
          documents[fileName] = KycUploadFileResponseFactory({
            name: KycDocumentsFileName[fileName as KycDocumentsFileName],
          });

          return documents;
        },
        {} as KycDocuments
      ) as KycDocuments,
      kybDocuments: Object.keys(KybDocumentsFileName).reduce(
        (documents, fileName) => {
          documents[fileName] = KybUploadFileResponseFactory({
            name: KybDocumentsFileName[fileName as KybDocumentsFileName],
          });

          return documents;
        },
        {} as KybDocuments
      ) as KybDocuments,
      userRepo: this.$projectServices.userRepo,
      kycRepository: this.$projectServices.kycRepository,
      kybRepository: this.$projectServices.kybRepository,
    };
  },
  async created(): Promise<void> {
    try {
      this.isLoading = true;

      switch (this.userRepo.userInfo.user_info.application_type) {
        case ApplicationType.kyc:
          const kycFiles = await this.kycRepository.userFiles();

          kycFiles.forEach((userFile) => {
            this.kycDocuments[extractFileName(userFile.name)] =
              KycUploadFileResponseFactory(userFile);
          });

          break;
        case ApplicationType.kyb:
          const kybFiles = await this.kybRepository.userFiles();

          kybFiles.forEach((userFile) => {
            this.kybDocuments[extractFileName(userFile.name)] =
              KybUploadFileResponseFactory(userFile);
          });

          break;
        default:
          break;
      }
    } catch (error) {
      console.log(error);
    } finally {
      this.isLoading = false;
    }
  },
  computed: {
    isKyc(): boolean {
      return this.userRepo.isReadonlyKyc;
    },

    isKyb(): boolean {
      return this.userRepo.isReadonlyKyb;
    },
  },
});
