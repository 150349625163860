import { createApp } from "vue";
import VueBemCn from "vue-bem-cn";
import ClickOutside from "click-outside-vue3";
import { vMaska } from "maska";
import VueDatePicker from "@vuepic/vue-datepicker";

import App from "@/App.vue";
import router from "@/router";
import store from "@/store";

import "@/assets/styles/main.scss";
import "@vuepic/vue-datepicker/dist/main.css";
import "/node_modules/flag-icons/css/flag-icons.min.css";

import AxiosPlugin from "@/plugins/axios";
import i18n from "@/plugins/i18n";

import ProjectServicesPlugin from "@/plugins/project-services";

createApp(App)
  .use(store)
  .use(VueBemCn)
  .use(ClickOutside)
  .use(router)
  .use(AxiosPlugin)
  .use(ProjectServicesPlugin)
  .use(i18n)
  .directive("maska", vMaska)
  .component("VueDatePicker", VueDatePicker)
  .mount("#app");
