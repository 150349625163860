import { AxiosInstance } from "axios";

import { AdminCurrencyUrlGenerator } from "./url-generator";
import {
  AdminCurrencyListResponse,
  AdminCurrencyShowResponse,
  AdminCurrencyStoreRequest,
  AdminCurrencyUpdateRequest,
} from "./repo";
import { ResponseData } from "@/shared/constants/interfaces";
import {
  AdminCurrencyListResponseFactory,
  AdminCurrencyShowResponseFactory,
} from "./factory";

export interface AdminCurrencyProjectRepository {
  store(payload: AdminCurrencyStoreRequest): Promise<void>;
  update(payload: AdminCurrencyUpdateRequest): Promise<void>;
  delete(id: number): Promise<void>;
  show(id: number): Promise<AdminCurrencyShowResponse>;
  list(): Promise<AdminCurrencyListResponse>;
}

export class AdminCurrencyHttpRepo implements AdminCurrencyProjectRepository {
  constructor(
    private readonly axios: AxiosInstance,
    private readonly urlGenerator: AdminCurrencyUrlGenerator
  ) {}

  async store(payload: AdminCurrencyStoreRequest): Promise<void> {
    await this.axios.post<void>(this.urlGenerator.store(), payload);
  }

  async update(payload: AdminCurrencyUpdateRequest): Promise<void> {
    await this.axios.patch<void>(this.urlGenerator.update(), payload);
  }

  async delete(id: number): Promise<void> {
    await this.axios.delete<void>(this.urlGenerator.delete(id));
  }

  async show(id: number): Promise<AdminCurrencyShowResponse> {
    const { data } = await this.axios.get<
      ResponseData<AdminCurrencyShowResponse>
    >(this.urlGenerator.show(id));

    return AdminCurrencyShowResponseFactory(data.data);
  }

  async list(): Promise<AdminCurrencyListResponse> {
    const { data } = await this.axios.get<
      ResponseData<AdminCurrencyListResponse>
    >(this.urlGenerator.list());

    return AdminCurrencyListResponseFactory(data.data);
  }
}
