<script lang="ts" src="./component.ts"></script>
<style lang="scss" src="./component.scss"></style>

<template>
  <div :class="b()">
    <div :class="b('field')">
      <h4 :class="b('field-title')">
        {{ $t("БАНК-корреспондент") }}
      </h4>

      <UIInput
        view="light"
        :model-value="correspondentBank"
        :max-length="150"
        @update:modelValue="handleChangeCorrespondentBank"
      />
    </div>

    <div :class="b('field')">
      <h4 :class="b('field-title')">
        {{ $t("БИК") }}
      </h4>

      <UIInput
        view="light"
        :model-value="bic"
        @update:modelValue="handleChangeBic"
      />
    </div>

    <div :class="b('field')">
      <h4 :class="b('field-title')">
        {{ $t("ИНН") }}
      </h4>

      <UIInput
        view="light"
        :model-value="inn"
        @update:modelValue="handleChangeInn"
      />
    </div>

    <div :class="b('field')">
      <h4 :class="b('field-title')">
        {{ $t("Корр. счет") }}
      </h4>

      <UIInput
        view="light"
        :model-value="correspondentAccount"
        @update:modelValue="handleChangeCorrespondentAccount"
      />
    </div>

    <div :class="b('field')">
      <h4 :class="b('field-title')">SWIFT</h4>

      <UIInput
        view="light"
        :model-value="correspondentSwift"
        @update:modelValue="handleChangeCorrespondentSwift"
      />
    </div>

    <div :class="b('field')">
      <h4 :class="b('field-title')">
        {{ $t("БАНК получателя") }}
      </h4>

      <UIInput
        view="light"
        :model-value="recipientBank"
        @update:modelValue="handleChangeRecipientBank"
      />
    </div>

    <div :class="b('field')">
      <h4 :class="b('field-title')">
        {{ $t("Корр. счет") }}
      </h4>

      <UIInput
        view="light"
        :model-value="recipientCorrespondentAccount"
        @update:modelValue="handleChangeRecipientCorrespondentAccount"
      />
    </div>

    <div :class="b('field')">
      <h4 :class="b('field-title')">SWIFT</h4>

      <UIInput
        view="light"
        :model-value="recipientCorrespondentSwift"
        @update:modelValue="handleChangeRecipientCorrespondentSwift"
      />
    </div>
  </div>
</template>
