import { AdminCommissionExchangeType } from "./enums";

export enum AdminCommissionApiRoutes {
  exchangeStore = "/api/v1/admin/commission/exchange/store",
  exchangeUpdate = "/api/v1/admin/commission/exchange/update",
  exchangeDelete = "/api/v1/admin/commission/exchange/delete",
  exchangeShow = "/api/v1/admin/commission/exchange/show",
  exchangeList = "/api/v1/admin/commission/exchange/list",

  depositWithdrawalStore = "/api/v1/admin/commission/deposit-withdrawal/store",
  depositWithdrawalUpdate = "/api/v1/admin/commission/deposit-withdrawal/update",
  depositWithdrawalDelete = "/api/v1/admin/commission/deposit-withdrawal/delete",
  depositWithdrawalShow = "/api/v1/admin/commission/deposit-withdrawal/show",
  depositWithdrawalList = "/api/v1/admin/commission/deposit-withdrawal/list",

  requisitesStore = "/api/v1/admin/commission/requisite/store",
  requisitesUpdate = "/api/v1/admin/commission/requisite/update",
  requisitesDelete = "/api/v1/admin/commission/requisite/delete",
  requisitesShow = "/api/v1/admin/commission/requisite/show",
  requisitesList = "/api/v1/admin/commission/requisite/list",

  currencyStore = "/api/v1/admin/commission/currency/store",
  currencyDelete = "/api/v1/admin/commission/currency/delete",
  currencyList = "/api/v1/admin/commission/currency/list",
}

export interface AdminCommissionProjectUrlGenerator {
  exchangeStore(type: AdminCommissionExchangeType): string;
  exchangeUpdate(id: number): string;
  exchangeDelete(id: number): string;
  exchangeShow(id: number): string;
  exchangeList(userId?: number): string;

  depositWithdrawalStore(type: AdminCommissionExchangeType): string;
  depositWithdrawalUpdate(id: number): string;
  depositWithdrawalDelete(id: number): string;
  depositWithdrawalShow(id: number): string;
  depositWithdrawalList(userId?: number): string;

  requisitesStore(): string;
  requisitesUpdate(): string;
  requisitesDelete(id: number): string;
  requisitesShow(id: number): string;
  requisitesList(): string;

  currencyStore(): string;
  currencyDelete(id: number): string;
  currencyList(): string;
}

export class AdminCommissionUrlGenerator
  implements AdminCommissionProjectUrlGenerator
{
  // Exchange

  exchangeStore(type: AdminCommissionExchangeType): string {
    return `${AdminCommissionApiRoutes.exchangeStore}?type=${type}`;
  }

  exchangeUpdate(id: number): string {
    return `${AdminCommissionApiRoutes.exchangeUpdate}?id=${id}`;
  }

  exchangeDelete(id: number): string {
    return `${AdminCommissionApiRoutes.exchangeDelete}?id=${id}`;
  }

  exchangeShow(id: number): string {
    return `${AdminCommissionApiRoutes.exchangeShow}?id=${id}`;
  }

  exchangeList(userId?: number): string {
    if (userId) {
      return `${AdminCommissionApiRoutes.exchangeList}?filter%5Buser_id%5D=${userId}&page=1&per_page=10`;
    }

    return `${AdminCommissionApiRoutes.exchangeList}?page=1&per_page=10`;
  }

  // Deposit-withdrawal

  depositWithdrawalStore(type: AdminCommissionExchangeType): string {
    return `${AdminCommissionApiRoutes.depositWithdrawalStore}?type=${type}`;
  }

  depositWithdrawalUpdate(id: number): string {
    return `${AdminCommissionApiRoutes.depositWithdrawalUpdate}?id=${id}`;
  }

  depositWithdrawalDelete(id: number): string {
    return `${AdminCommissionApiRoutes.depositWithdrawalDelete}?id=${id}`;
  }

  depositWithdrawalShow(id: number): string {
    return `${AdminCommissionApiRoutes.depositWithdrawalShow}?id=${id}`;
  }

  depositWithdrawalList(userId?: number): string {
    if (userId) {
      return `${AdminCommissionApiRoutes.depositWithdrawalList}?filter%5Buser_id%5D=${userId}&page=1&per_page=10`;
    }

    return `${AdminCommissionApiRoutes.depositWithdrawalList}?page=1&per_page=10`;
  }

  // Requisites

  requisitesStore(): string {
    return AdminCommissionApiRoutes.requisitesStore;
  }

  requisitesUpdate(): string {
    return AdminCommissionApiRoutes.requisitesUpdate;
  }

  requisitesDelete(id: number): string {
    return `${AdminCommissionApiRoutes.requisitesDelete}?id=${id}`;
  }

  requisitesShow(id: number): string {
    return `${AdminCommissionApiRoutes.requisitesShow}?id=${id}`;
  }

  requisitesList(): string {
    return AdminCommissionApiRoutes.requisitesList;
  }

  // Currency

  currencyStore(): string {
    return AdminCommissionApiRoutes.currencyStore;
  }

  currencyDelete(id: number): string {
    return `${AdminCommissionApiRoutes.currencyDelete}?id=${id}`;
  }

  currencyList(): string {
    return AdminCommissionApiRoutes.currencyList;
  }
}
