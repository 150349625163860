import { defineComponent, PropType } from "vue";

import { COMPONENT_NAME } from "./attributes";

import { SharedWindow } from "@/components/shared";
import { UIButton } from "@/components/ui";

import {
  VerificationKybComponent,
  VerificationKycComponent,
} from "@/views/verification/model";
import { ApplicationType } from "@/shared/constants/enums";

enum Title {
  kyc = "Вы действительно хотите перейти на верификацию Юридического лица?",
  kyb = "Вы действительно хотите перейти на верификацию Физического лица?",
}

export default defineComponent({
  name: COMPONENT_NAME,
  components: {
    SharedWindow,
    UIButton,
  },
  props: {
    tab: {
      type: <PropType<ApplicationType>>String,
      required: true,
    },
    isModal: {
      type: Boolean,
      required: true,
    },
    kycComponent: {
      type: <PropType<VerificationKycComponent>>String,
      required: true,
    },
    kybComponent: {
      type: <PropType<VerificationKybComponent>>String,
      required: true,
    },
  },
  emits: {
    "update:tab": null,
    "update:isModal": null,
    "update:kycComponent": null,
    "update:kybComponent": null,
  },
  computed: {
    displayedTitle(): Title {
      return Title[this.tab];
    },
  },
  methods: {
    handleCancel(): void {
      this.$emit("update:isModal", false);
    },

    handleContinue(): void {
      switch (this.tab) {
        case ApplicationType.kyc:
          this.$emit("update:tab", ApplicationType.kyb);

          this.$emit(
            "update:kybComponent",
            VerificationKybComponent.kybCompany
          );

          break;
        case ApplicationType.kyb:
          this.$emit("update:tab", ApplicationType.kyc);

          this.$emit(
            "update:kycComponent",
            VerificationKycComponent.kycPersonalInformation
          );

          break;
        default:
          break;
      }

      this.handleCancel();
    },
  },
});
