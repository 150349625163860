import { defineComponent, PropType } from "vue";

import { COMPONENT_NAME, SVG } from "./attributes";

import { AdminUsersTableRow } from "./row";

import { PAGE_SIZE, SvgAttribute } from "@/shared/constants/constants";
import { AdminUsersResponse } from "@/shared/repository/modules/admin/users/repo";
import {
  AdminUsersTableHeaders,
  AdminUsersTableHeader,
} from "@/data/admin/users/table";
import { Search, Sort } from "@/shared/constants/interfaces";
import { SearchType } from "@/shared/constants/enums";

export default defineComponent({
  name: COMPONENT_NAME,
  components: {
    AdminUsersTableRow,
  },
  props: {
    search: {
      type: <PropType<Search>>Object,
      required: true,
    },
    sort: {
      type: <PropType<Sort>>Object,
      required: true,
    },
    isStatus: {
      type: Boolean,
      default: false,
    },
    isBalance: {
      type: Boolean,
      default: false,
    },
  },
  emits: {
    "update:isStatus": null,
    "update:isBalance": null,
  },
  data() {
    return {
      usersRepo: this.$projectServices.usersRepo,
    };
  },
  computed: {
    displayedIcons(): SvgAttribute {
      return SVG;
    },

    displayedColumns(): AdminUsersTableHeader[] {
      return AdminUsersTableHeaders;
    },

    displayedUsers(): AdminUsersResponse[] {
      const { value, type } = this.search;
      const users = this.usersRepo.users;

      return value
        ? users.filter((user) => {
            if (value) {
              switch (type) {
                case SearchType.all:
                  return `${user.index} ${user.email} ${
                    user.applicant_name ?? ""
                  } ${user.applicant_surname ?? ""}`
                    .toLowerCase()
                    .includes(value.toLowerCase());
                case SearchType.id:
                  return user.index
                    .toString()
                    .toLowerCase()
                    .includes(value.toLowerCase());
                case SearchType.email:
                  return user.email
                    .toString()
                    .toLowerCase()
                    .includes(value.toLowerCase());
                case SearchType.fio:
                  return `${user.applicant_name ?? ""} ${
                    user.applicant_surname
                  }`
                    .toLowerCase()
                    .includes(value.toLowerCase());
                default:
                  return user;
              }
            }

            return user;
          })
        : users.slice(0, this.sort.page * PAGE_SIZE);
    },
  },
  methods: {
    handleChangeIsStatus(isStatus: boolean): void {
      this.$emit("update:isStatus", isStatus);
    },

    handleChangeIsBalance(isBalance: boolean): void {
      this.$emit("update:isBalance", isBalance);
    },
  },
});
