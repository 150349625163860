import { defineComponent } from "vue";

import { COMPONENT_NAME } from "./attributes";

import { SharedSelect } from "@/components/shared";
import { UIInput, UIButton } from "@/components/ui";

export default defineComponent({
  name: COMPONENT_NAME,
  components: {
    SharedSelect,
    UIInput,
    UIButton,
  },
  data() {
    return {
      isLoading: false,
    };
  },
  computed: {
    // displayedRequisitesOptions(): SharedSelectOption[] {
    //   return this.requisites.map((requisite) =>
    //     SelectOptionFactory(
    //       {
    //         id: requisite.id,
    //         label: requisite.org_full_name_local,
    //         value: requisite.id,
    //       },
    //       this.userRequisiteId
    //     )
    //   );
    // },

    isDisabled(): boolean {
      return this.isLoading;
    },
  },
  methods: {
    handleCreate(): void {},
  },
});
