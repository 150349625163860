<script lang="ts" src="./component.ts"></script>
<style lang="scss" src="./component.scss"></style>

<template>
  <SharedWindow
    title="Вывод"
    :isBig="isBig"
    :is-center="true"
    @close="handleClose"
  >
    <div :class="b('wrapper')">
      <div :class="b('header')">
        <WalletRequisites v-model:requisite="requisite" />
      </div>

      <component :is="displayedComponent" />
    </div>
  </SharedWindow>
</template>
