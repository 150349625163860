import { toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = ["disabled", "onClick"]
const _hoisted_2 = ["innerHTML"]
const _hoisted_3 = ["innerHTML"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(_ctx.b())
  }, [
    _createElementVNode("h2", {
      class: _normalizeClass(_ctx.b('title'))
    }, _toDisplayString(_ctx.$t("Верификация информации о компании")), 3),
    _createElementVNode("div", {
      class: _normalizeClass(_ctx.b('cards'))
    }, [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.displayedList, (item) => {
        return (_openBlock(), _createElementBlock("button", {
          class: _normalizeClass(_ctx.b('card', { padding: item.isPadding })),
          key: item.id,
          disabled: item.isDisabled,
          onClick: ($event: any) => (_ctx.handleCard(item))
        }, [
          _createElementVNode("span", {
            class: _normalizeClass(_ctx.b('card-icon')),
            innerHTML: item.icon
          }, null, 10, _hoisted_2),
          _createElementVNode("div", {
            class: _normalizeClass(_ctx.b('card-info'))
          }, [
            _createElementVNode("h4", {
              class: _normalizeClass(_ctx.b('card-title'))
            }, _toDisplayString(_ctx.$t(item.title)), 3),
            _createElementVNode("p", {
              class: _normalizeClass(_ctx.b('card-label'))
            }, _toDisplayString(_ctx.$t(item.label)), 3)
          ], 2),
          _createElementVNode("span", {
            class: _normalizeClass(_ctx.b('card-chevron')),
            innerHTML: _ctx.displayedSvg.chevron
          }, null, 10, _hoisted_3)
        ], 10, _hoisted_1))
      }), 128))
    ], 2)
  ], 2))
}