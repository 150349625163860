import { toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, createTextVNode as _createTextVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, Transition as _Transition, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = ["innerHTML"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_HistoryHeader = _resolveComponent("HistoryHeader")!
  const _component_HistoryTable = _resolveComponent("HistoryTable")!
  const _component_SharedButtonIcon = _resolveComponent("SharedButtonIcon")!
  const _component_HistoryFilters = _resolveComponent("HistoryFilters")!
  const _component_HistoryDetails = _resolveComponent("HistoryDetails")!

  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(_ctx.b())
  }, [
    _createElementVNode("h1", {
      class: _normalizeClass(_ctx.b('title'))
    }, _toDisplayString(_ctx.$t("История")), 3),
    _createElementVNode("div", {
      class: _normalizeClass(_ctx.b('wrapper'))
    }, [
      _createVNode(_component_HistoryHeader),
      _createVNode(_component_HistoryTable, {
        class: _normalizeClass(_ctx.b('table')),
        "is-details": _ctx.isDetails,
        "onUpdate:isDetails": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.isDetails) = $event))
      }, null, 8, ["class", "is-details"]),
      (_ctx.isMoreShow)
        ? (_openBlock(), _createBlock(_component_SharedButtonIcon, {
            key: 0,
            class: _normalizeClass(_ctx.b('more')),
            view: "gray-outline",
            onClick: _ctx.handleMore
          }, {
            "icon-left": _withCtx(() => [
              _createElementVNode("span", {
                class: _normalizeClass(_ctx.b('more-icon')),
                innerHTML: _ctx.displayedMoreIcon
              }, null, 10, _hoisted_1)
            ]),
            label: _withCtx(() => [
              _createTextVNode(_toDisplayString(_ctx.$t("Показать больше")), 1)
            ]),
            _: 1
          }, 8, ["class", "onClick"]))
        : _createCommentVNode("", true)
    ], 2),
    _createVNode(_Transition, { name: "opacity" }, {
      default: _withCtx(() => [
        (_ctx.isFilter)
          ? (_openBlock(), _createBlock(_component_HistoryFilters, {
              key: 0,
              class: _normalizeClass(_ctx.b('modal'))
            }, null, 8, ["class"]))
          : _createCommentVNode("", true)
      ]),
      _: 1
    }),
    _createVNode(_Transition, { name: "opacity" }, {
      default: _withCtx(() => [
        (_ctx.isDetails)
          ? (_openBlock(), _createBlock(_component_HistoryDetails, {
              key: 0,
              class: _normalizeClass(_ctx.b('modal')),
              "is-details": _ctx.isDetails,
              "onUpdate:isDetails": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.isDetails) = $event))
            }, null, 8, ["class", "is-details"]))
          : _createCommentVNode("", true)
      ]),
      _: 1
    })
  ], 2))
}