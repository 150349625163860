import { defineComponent, PropType } from "vue";

import { COMPONENT_NAME, SVG } from "./attributes";

import { SvgAttribute } from "@/shared/constants/constants";
import { ParserParseRequest } from "@/shared/repository/modules/parser/repo";

export default defineComponent({
  name: COMPONENT_NAME,
  props: {
    document: {
      type: <PropType<ParserParseRequest>>Object,
      required: true,
    },
  },
  data() {
    return {
      isLoading: false,
      parserRepository: this.$projectServices.parserRepository,
    };
  },
  computed: {
    displayedSvg(): SvgAttribute {
      return SVG;
    },

    isDisabled(): boolean {
      return this.isLoading;
    },
  },
  methods: {
    async handleDownload(): Promise<void> {
      try {
        this.isLoading = true;

        await this.parserRepository.parse(this.document);
      } catch (error) {
        console.log(error);
      } finally {
        this.isLoading = false;
      }
    },
  },
});
