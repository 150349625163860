import { AdminCommissionExchangeType } from "./enums";
import {
  AdminCommissionCurrencyItemResponse,
  AdminCommissionCurrencyListResponse,
  AdminCommissionCurrencyStoreRequest,
  AdminCommissionDepositWithdrawalFields,
  AdminCommissionDepositWithdrawalListResponse,
  AdminCommissionDepositWithdrawalShowResponse,
  AdminCommissionDepositWithdrawalStoreRequest,
  AdminCommissionDepositWithdrawalUpdateRequest,
  AdminCommissionExchangeFind,
  AdminCommissionExchangeListResponse,
  AdminCommissionExchangeStoreFields,
  AdminCommissionExchangeStoreRequest,
  AdminCommissionExchangeUpdateRequest,
  AdminCommissionRequisitesBankRequest,
  AdminCommissionRequisitesBankResponse,
  AdminCommissionRequisitesListResponse,
  AdminCommissionRequisitesShowResponse,
  AdminCommissionRequisitesStoreRequest,
  AdminCommissionRequisitesUpdateRequest,
} from "./repo";

// Exchange

export const AdminCommissionExchangeFindFactory = (
  payload: Partial<AdminCommissionExchangeFind> = {}
): AdminCommissionExchangeFind => {
  return {
    type: payload.type ?? AdminCommissionExchangeType.all,
    user_id: payload.user_id ?? 0,
  };
};

export const AdminCommissionExchangeStoreFieldsFactory =
  (): AdminCommissionExchangeStoreFields => {
    return {
      mex_commission: false,
      moex_commission: false,
      swift: false,
      crypto_exchange_commission: false,
      blockchain_transfers_commission: false,
      volatility: false,
    };
  };

export const AdminCommissionExchangeStoreRequestFactory = (
  payload: Partial<AdminCommissionExchangeStoreRequest> = {}
): AdminCommissionExchangeStoreRequest => {
  const params: Partial<AdminCommissionExchangeStoreRequest> = {};

  if (payload.type === AdminCommissionExchangeType.user) {
    params.user_id = payload.user_id;
  }

  return {
    ...params,
    type: payload.type ?? AdminCommissionExchangeType.all,
    mex_commission: payload.mex_commission ?? 0,
    moex_commission: payload.moex_commission ?? 0,
    swift: payload.swift ?? 0,
    crypto_exchange_commission: payload.crypto_exchange_commission ?? 0,
    blockchain_transfers_commission:
      payload.blockchain_transfers_commission ?? 0,
    volatility: payload.volatility ?? 0,
  };
};

export const AdminCommissionExchangeUpdateRequestFactory = (
  payload: Partial<AdminCommissionExchangeUpdateRequest> = {}
): AdminCommissionExchangeUpdateRequest => {
  const params: Partial<AdminCommissionExchangeUpdateRequest> = {};

  if (payload.user_id) {
    params.user_id = payload.user_id;
  }

  return {
    ...params,
    mex_commission: payload.mex_commission ?? 0,
    moex_commission: payload.moex_commission ?? 0,
    swift: payload.swift ?? 0,
    crypto_exchange_commission: payload.crypto_exchange_commission ?? 0,
    blockchain_transfers_commission:
      payload.blockchain_transfers_commission ?? 0,
    volatility: payload.volatility ?? 0,
  };
};

export const AdminCommissionExchangeListResponseFactory = (
  payload: Partial<AdminCommissionExchangeListResponse> = {}
): AdminCommissionExchangeListResponse => {
  return {
    id: payload.id ?? 0,
    type: payload.type ?? AdminCommissionExchangeType.all,
    user_id: payload.user_id ?? 0,
    mex_commission: Number(payload.mex_commission) ?? 0,
    moex_commission: Number(payload.moex_commission) ?? 0,
    swift: Number(payload.swift) ?? 0,
    crypto_exchange_commission: Number(payload.crypto_exchange_commission) ?? 0,
    blockchain_transfers_commission:
      Number(payload.blockchain_transfers_commission) ?? 0,
    volatility: Number(payload.volatility) ?? 0,
    created_at: payload.created_at ?? "",
    updated_at: payload.updated_at ?? "",
  };
};

// Deposit Withdrawal

export const AdminCommissionDepositWithdrawalStoreFieldsFactory =
  (): AdminCommissionDepositWithdrawalFields => {
    return {
      deposit_fiat_commission: false,
      withdrawal_fiat_commission: false,
      deposit_crypto_commission: false,
      withdrawal_crypto_commission: false,
    };
  };

export const AdminCommissionDepositWithdrawalStoreRequestFactory = (
  payload: Partial<AdminCommissionDepositWithdrawalStoreRequest> = {}
): AdminCommissionDepositWithdrawalStoreRequest => {
  const params: Partial<AdminCommissionDepositWithdrawalStoreRequest> = {};

  if (payload.user_id) {
    params.user_id = payload.user_id;
  }

  return {
    ...params,
    deposit_fiat_commission: payload.deposit_fiat_commission ?? 0,
    withdrawal_fiat_commission: payload.withdrawal_fiat_commission ?? 0,
    deposit_crypto_commission: payload.deposit_crypto_commission ?? 0,
    withdrawal_crypto_commission: payload.withdrawal_crypto_commission ?? 0,
  };
};

export const AdminCommissionDepositWithdrawalUpdateRequestFactory = (
  payload: AdminCommissionDepositWithdrawalUpdateRequest
): AdminCommissionDepositWithdrawalUpdateRequest => {
  const params: Partial<AdminCommissionDepositWithdrawalUpdateRequest> = {};

  if (payload.user_id) {
    params.user_id = payload.user_id;
  }

  return {
    ...params,
    deposit_fiat_commission: payload.deposit_fiat_commission ?? 0,
    withdrawal_fiat_commission: payload.withdrawal_fiat_commission ?? 0,
    deposit_crypto_commission: payload.deposit_crypto_commission ?? 0,
    withdrawal_crypto_commission: payload.withdrawal_crypto_commission ?? 0,
  };
};

export const AdminCommissionDepositWithdrawalShowResponseFactory = (
  payload: Partial<AdminCommissionDepositWithdrawalShowResponse> = {}
): AdminCommissionDepositWithdrawalShowResponse => {
  return {
    id: payload.id ?? 0,
    type: payload.type ?? AdminCommissionExchangeType.all,
    user_id: payload.user_id ?? 0,
    deposit_fiat_commission: Number(payload.deposit_fiat_commission) ?? 0,
    withdrawal_fiat_commission: Number(payload.withdrawal_fiat_commission) ?? 0,
    deposit_crypto_commission: Number(payload.deposit_crypto_commission) ?? 0,
    withdrawal_crypto_commission:
      Number(payload.withdrawal_crypto_commission) ?? 0,
    created_at: payload.created_at ?? "",
    updated_at: payload.updated_at ?? "",
  };
};

export const AdminCommissionDepositWithdrawalListResponseFactory = (
  payload: Partial<AdminCommissionDepositWithdrawalListResponse> = {}
): AdminCommissionDepositWithdrawalListResponse => {
  return {
    id: payload.id ?? 0,
    type: payload.type ?? AdminCommissionExchangeType.all,
    user_id: payload.user_id ?? 0,
    deposit_fiat_commission: Number(payload.deposit_fiat_commission) ?? 0,
    withdrawal_fiat_commission: Number(payload.withdrawal_fiat_commission) ?? 0,
    deposit_crypto_commission: Number(payload.deposit_crypto_commission) ?? 0,
    withdrawal_crypto_commission:
      Number(payload.withdrawal_crypto_commission) ?? 0,
    created_at: payload.created_at ?? "",
    updated_at: payload.updated_at ?? "",
  };
};

// Requisites

export const AdminCommissionRequisitesStoreRequestFactory = (
  payload: Partial<AdminCommissionRequisitesStoreRequest> = {}
): AdminCommissionRequisitesStoreRequest => {
  return {
    currency_id: payload.currency_id ?? 0,
    org_full_name_local: payload.org_full_name_local ?? "",
    org_full_name_eng: payload.org_full_name_eng ?? "",
    inn: payload.inn ?? "",
    current_account: payload.current_account ?? "",
    payment_code_name: payload.payment_code_name ?? "",
    mex_requisite_banks:
      payload.mex_requisite_banks?.map((bank) =>
        AdminCommissionRequisitesBankRequestFactory(bank)
      ) ?? [],
  };
};

export const AdminCommissionRequisitesUpdateRequestFactory = (
  payload: Partial<AdminCommissionRequisitesUpdateRequest> = {}
): AdminCommissionRequisitesUpdateRequest => {
  return {
    id: payload.id ?? 0,
    currency_id: payload.currency_id ?? 0,
    org_full_name_local: payload.org_full_name_local ?? "",
    org_full_name_eng: payload.org_full_name_eng ?? "",
    inn: payload.inn ?? "",
    current_account: payload.current_account ?? "",
    payment_code_name: payload.payment_code_name ?? "",
    mex_requisite_banks:
      payload.mex_requisite_banks?.map((bank) =>
        AdminCommissionRequisitesBankRequestFactory(bank)
      ) ?? [],
  };
};

export const AdminCommissionRequisitesBankRequestFactory = (
  payload: Partial<AdminCommissionRequisitesBankRequest> = {}
): AdminCommissionRequisitesBankRequest => {
  const params: Partial<AdminCommissionRequisitesBankRequest> = {};

  if (payload.id) {
    params.id = payload.id;
  }

  return {
    ...params,
    correspondent_bank: payload.correspondent_bank ?? "",
    bic: payload.bic ?? "",
    inn: payload.inn ?? "",
    correspondent_account: payload.correspondent_account ?? "",
    correspondent_swift: payload.correspondent_swift ?? "",
    recipient_bank: payload.recipient_bank ?? "",
    recipient_correspondent_account:
      payload.recipient_correspondent_account ?? "",
    recipient_correspondent_swift: payload.recipient_correspondent_swift ?? "",
  };
};

export const AdminCommissionRequisitesBankResponseFactory = (
  payload: Partial<AdminCommissionRequisitesBankResponse> = {}
): AdminCommissionRequisitesBankResponse => {
  return {
    id: payload.id ?? 0,
    mex_requisite_id: payload.mex_requisite_id ?? 0,
    correspondent_bank: payload.correspondent_bank ?? "",
    bic: payload.bic ?? "",
    inn: payload.inn ?? "",
    correspondent_account: payload.correspondent_account ?? "",
    correspondent_swift: payload.correspondent_swift ?? "",
    recipient_bank: payload.recipient_bank ?? "",
    recipient_correspondent_account:
      payload.recipient_correspondent_account ?? "",
    recipient_correspondent_swift: payload.recipient_correspondent_swift ?? "",
    created_at: payload.created_at ?? "",
    updated_at: payload.updated_at ?? "",
  };
};

export const AdminCommissionRequisitesShowResponseFactory = (
  payload: Partial<AdminCommissionRequisitesShowResponse> = {}
): AdminCommissionRequisitesShowResponse => {
  return {
    id: payload.id ?? 0,
    currency_id: payload.currency_id ?? 0,
    org_full_name_local: payload.org_full_name_local ?? "",
    org_full_name_eng: payload.org_full_name_eng ?? "",
    inn: payload.inn ?? "",
    current_account: payload.current_account ?? "",
    payment_code_name: payload.payment_code_name ?? "",
    mex_requisite_banks:
      payload.mex_requisite_banks?.map((bank) =>
        AdminCommissionRequisitesBankRequestFactory(bank)
      ) ?? [],
  };
};

export const AdminRequisitesListResponseFactory = (
  payload: Partial<AdminCommissionRequisitesListResponse> = {}
): AdminCommissionRequisitesListResponse => {
  return {
    id: payload.id ?? 0,
    currency_id: payload.currency_id ?? 0,
    org_full_name_local: payload.org_full_name_local ?? "",
    org_full_name_eng: payload.org_full_name_eng ?? "",
    inn: payload.inn ?? "",
    current_account: payload.current_account ?? "",
    payment_code_name: payload.payment_code_name ?? "",
    created_at: payload.created_at ?? "",
    updated_at: payload.updated_at ?? "",
    currency: AdminCommissionCurrencyItemResponseFactory(payload.currency),
    mex_requisite_banks:
      payload.mex_requisite_banks?.map((bank) =>
        AdminCommissionRequisitesBankResponseFactory(bank)
      ) ?? [],
  };
};

// Currency

export const AdminCommissionCurrencyFiatStoreRequestFactory = (
  payload: AdminCommissionCurrencyStoreRequest
): AdminCommissionCurrencyStoreRequest => {
  return {
    name: payload.name ?? "",
    type: payload.type ?? "",
    file: payload.file ?? "",
    country: payload.country ?? "",
  };
};

export const AdminCurrencyStoreCryptoRequestFactory = (
  payload: AdminCommissionCurrencyStoreRequest
): AdminCommissionCurrencyStoreRequest => {
  return {
    name: payload.name ?? "",
    type: payload.type ?? "",
    file: payload.file ?? "",
  };
};

export const AdminCommissionCurrencyListResponseFactory = (
  payload: Partial<AdminCommissionCurrencyListResponse> = {}
): AdminCommissionCurrencyListResponse => {
  return {
    fiat:
      payload.fiat?.map((item) =>
        AdminCommissionCurrencyItemResponseFactory(item)
      ) ?? [],
    crypto:
      payload.crypto?.map((item) =>
        AdminCommissionCurrencyItemResponseFactory(item)
      ) ?? [],
  };
};

export const AdminCommissionCurrencyItemResponseFactory = (
  payload: Partial<AdminCommissionCurrencyItemResponse> = {}
): AdminCommissionCurrencyItemResponse => {
  return {
    id: payload.id ?? 0,
    name: payload.name ?? "",
    type: payload.type ?? "",
    image_path: payload.image_path ?? "",
    created_at: payload.created_at ?? "",
    updated_at: payload.updated_at ?? "",
    country: payload.country ?? "",
  };
};
