<script lang="ts" src="./component.ts"></script>
<style lang="scss" src="./component.scss"></style>

<template>
  <button
    :class="b('', { view, error: isError })"
    :checked="isChecked"
    :disabled="isDisabled"
    @click="handleRadio"
  ></button>
</template>
