import { defineComponent } from "vue";

import { COMPONENT_NAME } from "./attributes";

import { SharedWindow, SharedVerification } from "@/components/shared";
import { UIButton } from "@/components/ui";

import { CODE_MAX_LENGTH } from "@/shared/constants/constants";
import { RequisitesVerificationRequestFactory } from "@/shared/repository/modules/requisites/factory";
import { RequisitesType } from "@/shared/repository/modules/requisites/enums";
import { WalletModalFactory } from "@/shared/repository/modules/wallet/factory";
import { AdminApplicationType } from "@/shared/repository/modules/admin/application/enums";

export default defineComponent({
  name: COMPONENT_NAME,
  components: {
    SharedWindow,
    SharedVerification,
    UIButton,
  },
  data() {
    return {
      isError: false,
      isLoading: false,
      code: "",
      requisitesRepository: this.$projectServices.requisitesRepository,
      walletRepo: this.$projectServices.walletRepo,
      depositWithdrawalRepo: this.$projectServices.depositWithdrawalRepo,
    };
  },
  computed: {
    isConfirmDisabled(): boolean {
      return this.isLoading || this.code.length !== CODE_MAX_LENGTH;
    },
  },
  methods: {
    async handleVerification(): Promise<void> {
      try {
        this.isError = false;
        this.isLoading = true;

        await this.requisitesRepository.verification(
          RequisitesVerificationRequestFactory({
            code: this.code,
            type: RequisitesType.bank,
          })
        );

        this.walletRepo.UPDATE_MODAL(
          WalletModalFactory({
            type:
              this.walletRepo.modal.type ===
              AdminApplicationType.verificationDeposit
                ? AdminApplicationType.deposit
                : AdminApplicationType.withdrawal,
          })
        );
      } catch (error) {
        this.isError = true;
      } finally {
        this.isLoading = false;
      }
    },
  },
});
