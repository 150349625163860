import { AxiosInstance } from "axios";

import { ResponseData } from "@/shared/constants/interfaces";
import { ApplicationType } from "@/shared/constants/enums";

import { AdminUserAppUrlGenerator } from "./url-generator";
import { AdminUserAppResponse, AdminUserAppStatusRequest } from "./repo";
import { AdminUserAppResponseFactory } from "./factory";

export interface AdminUserAppProjectRepository {
  app(appId: number, type: ApplicationType): Promise<AdminUserAppResponse>;
  status(payload: AdminUserAppStatusRequest): Promise<void>;
}

export class AdminUserAppHttpRepo implements AdminUserAppProjectRepository {
  constructor(
    private readonly axios: AxiosInstance,
    private readonly urlGenerator: AdminUserAppUrlGenerator
  ) {}

  async app(
    appId: number,
    type: ApplicationType
  ): Promise<AdminUserAppResponse> {
    const { data } = await this.axios.get<ResponseData<AdminUserAppResponse>>(
      this.urlGenerator.app(),
      {
        params: {
          id: appId,
          type: type,
        },
      }
    );

    return AdminUserAppResponseFactory(data.data);
  }

  async status(payload: AdminUserAppStatusRequest): Promise<void> {
    await this.axios.patch<void>(this.urlGenerator.status(payload), {
      message: [
        {
          ru: payload.message.ru,
          en: payload.message.en,
        },
      ],
    });
  }
}
