import { toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_SharedSelect = _resolveComponent("SharedSelect")!
  const _component_UIInput = _resolveComponent("UIInput")!
  const _component_UIButton = _resolveComponent("UIButton")!

  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(_ctx.b())
  }, [
    _createElementVNode("div", {
      class: _normalizeClass(_ctx.b('fields'))
    }, [
      _createElementVNode("div", {
        class: _normalizeClass(_ctx.b('field'))
      }, [
        _createElementVNode("p", {
          class: _normalizeClass(_ctx.b('field-label'))
        }, _toDisplayString(_ctx.$t("Выбрерите шаблон реквизитов")), 3),
        _createVNode(_component_SharedSelect, {
          view: "light",
          options: _ctx.displayedRequisiteOptions,
          value: _ctx.depositWithdrawalStore.user_requisite_id,
          "onUpdate:value": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.depositWithdrawalStore.user_requisite_id) = $event))
        }, null, 8, ["options", "value"])
      ], 2),
      _createElementVNode("div", {
        class: _normalizeClass(_ctx.b('fields-group'))
      }, [
        _createElementVNode("div", {
          class: _normalizeClass(_ctx.b('field'))
        }, [
          _createElementVNode("p", {
            class: _normalizeClass(_ctx.b('field-label'))
          }, _toDisplayString(_ctx.$t("Валюта")), 3),
          _createVNode(_component_SharedSelect, {
            view: "light",
            options: _ctx.displayedCurrencyOptions,
            icon: _ctx.displayedCurrencyIcon,
            value: _ctx.depositWithdrawalStore.currency_id,
            "onUpdate:value": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.depositWithdrawalStore.currency_id) = $event))
          }, null, 8, ["options", "icon", "value"])
        ], 2),
        _createElementVNode("div", {
          class: _normalizeClass(_ctx.b('field'))
        }, [
          _createElementVNode("p", {
            class: _normalizeClass(_ctx.b('field-label'))
          }, _toDisplayString(_ctx.$t("Сумма")), 3),
          _createVNode(_component_UIInput, {
            view: "light",
            type: "number",
            "is-number": true,
            modelValue: _ctx.depositWithdrawalStore.amount,
            "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.depositWithdrawalStore.amount) = $event))
          }, null, 8, ["modelValue"])
        ], 2)
      ], 2)
    ], 2),
    _createVNode(_component_UIButton, {
      label: "Создать заявку",
      view: "main",
      disabled: _ctx.isDisabled,
      onClick: _ctx.handleCreate
    }, null, 8, ["disabled", "onClick"])
  ], 2))
}