import { normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_AdminRequisitesTableRow = _resolveComponent("AdminRequisitesTableRow")!

  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(_ctx.b())
  }, [
    _createElementVNode("div", {
      class: _normalizeClass(_ctx.b('columns'))
    }, [
      _createElementVNode("p", {
        class: _normalizeClass(_ctx.b('columns-label'))
      }, "Bank", 2)
    ], 2),
    _createElementVNode("div", {
      class: _normalizeClass(_ctx.b('body'))
    }, [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.displayedRequisites, (requisite) => {
        return (_openBlock(), _createBlock(_component_AdminRequisitesTableRow, {
          key: requisite.id,
          requisite: requisite
        }, null, 8, ["requisite"]))
      }), 128))
    ], 2)
  ], 2))
}