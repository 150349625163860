<script lang="ts" src="./component.ts"></script>
<style lang="scss" src="./component.scss"></style>

<template>
  <div :class="b()">
    <div :class="b('group', { tabs: true })">
      <button
        :class="b('tab', { active: tab.isActive })"
        v-for="tab in displayedTabs"
        :key="tab.id"
        @click="handleTab(tab)"
      >
        {{ $t(tab.label) }}
      </button>
    </div>

    <div :class="b('group')">
      <UIButton
        v-if="isRemoveShow"
        label="Удалить"
        view="main-light-outline"
        @click="handleRemove"
      />

      <UIButton
        v-if="isAddShow"
        label="Добавить"
        view="main-light"
        @click="handleAdd"
      />
    </div>
  </div>
</template>
