<script lang="ts" src="./component.ts"></script>
<style lang="scss" src="./component.scss"></style>

<template>
  <div :class="b()">
    <AdminKycBack title="Участники организации" @back="handleBack" />

    <div :class="b('cards')">
      <div
        :class="b('card')"
        v-for="(participant, participantIndex) in displayedParticipants"
        :key="participantIndex"
      >
        <h5 :class="b('card-title')">
          {{ $t("Участник") }} №{{ participantIndex + 1 }}
        </h5>

        <SharedAdd
          :is-success="participant.isSuccess"
          :is-expected="participant.isExpected"
          :is-loading="isLoading"
          :is-readonly="true"
          :is-remove="false"
          @click="handleParticipant(participant)"
        >
          <template #title>
            {{ participant.name }} <br v-if="participant.name" />
            {{ participant.patronymic }} <br v-if="participant.patronymic" />
            {{ participant.sur_name }}
          </template>

          <template #label>
            {{ $t("Не заполнено") }}
          </template>
        </SharedAdd>
      </div>
    </div>
  </div>
</template>
