import { normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createBlock as _createBlock, renderList as _renderList, Fragment as _Fragment, createTextVNode as _createTextVNode, resolveDirective as _resolveDirective, withDirectives as _withDirectives } from "vue"

const _hoisted_1 = ["disabled"]
const _hoisted_2 = ["src"]
const _hoisted_3 = ["innerHTML"]
const _hoisted_4 = ["onClick"]
const _hoisted_5 = ["src"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_UIInput = _resolveComponent("UIInput")!
  const _directive_click_outside = _resolveDirective("click-outside")!

  return _withDirectives((_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(_ctx.b())
  }, [
    _createElementVNode("button", {
      class: _normalizeClass(_ctx.b('toggle', { view: _ctx.view })),
      disabled: _ctx.isReadonly,
      onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.changeIsVisible && _ctx.changeIsVisible(...args)))
    }, [
      _createElementVNode("div", {
        class: _normalizeClass(_ctx.b('group'))
      }, [
        (_ctx.icon && !_ctx.displayedCryptoIcon)
          ? (_openBlock(), _createElementBlock("span", {
              key: 0,
              class: _normalizeClass([_ctx.b('icon'), 'fi', `fi-${_ctx.icon}`])
            }, null, 2))
          : _createCommentVNode("", true),
        (_ctx.icon && _ctx.isCryptoIcon)
          ? (_openBlock(), _createElementBlock("img", {
              key: 1,
              class: _normalizeClass(_ctx.b('icon', { crypto: true })),
              src: _ctx.displayedCryptoIcon
            }, null, 10, _hoisted_2))
          : _createCommentVNode("", true),
        _createElementVNode("span", {
          class: _normalizeClass(_ctx.b('toggle-label'))
        }, _toDisplayString(_ctx.displayedPlaceholder), 3)
      ], 2),
      _createElementVNode("span", {
        class: _normalizeClass(_ctx.b('toggle-icon', { active: _ctx.isVisible })),
        innerHTML: _ctx.displayedIcon
      }, null, 10, _hoisted_3)
    ], 10, _hoisted_1),
    _createElementVNode("div", {
      class: _normalizeClass(_ctx.b('options', { view: _ctx.view, visible: _ctx.isVisible }))
    }, [
      (_ctx.isSearch)
        ? (_openBlock(), _createBlock(_component_UIInput, {
            key: 0,
            class: _normalizeClass(_ctx.b('options-search')),
            placeholder: "Поиск",
            view: "light",
            modelValue: _ctx.search,
            "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.search) = $event))
          }, null, 8, ["class", "modelValue"]))
        : _createCommentVNode("", true),
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.displayedOptions, (option) => {
        return (_openBlock(), _createElementBlock("button", {
          class: _normalizeClass([_ctx.b('option', { view: _ctx.view }), _ctx.displayedOptionActiveClass(option)]),
          key: option.id,
          onClick: ($event: any) => (_ctx.changeOption(option))
        }, [
          (option.icon && !_ctx.displaydSvg[option.icon] && !_ctx.isCryptoIcon)
            ? (_openBlock(), _createElementBlock("span", {
                key: 0,
                class: _normalizeClass([_ctx.b('icon'), 'fi', `fi-${option.icon}`])
              }, null, 2))
            : _createCommentVNode("", true),
          (_ctx.isCryptoIcon)
            ? (_openBlock(), _createElementBlock("img", {
                key: 1,
                class: _normalizeClass(_ctx.b('icon', { crypto: true })),
                src: 
            require(`@/assets/images/crypto/${option.icon.toLowerCase()}.svg`)
          
              }, null, 10, _hoisted_5))
            : _createCommentVNode("", true),
          _createTextVNode(" " + _toDisplayString(option.label), 1)
        ], 10, _hoisted_4))
      }), 128))
    ], 2)
  ], 2)), [
    [_directive_click_outside, _ctx.handleHide]
  ])
}