<script lang="ts" src="./component.ts"></script>
<style lang="scss" src="./component.scss"></style>

<template>
  <div :class="b()">
    <router-link :class="b('wallet', { disabled: !isVerified })" to="/">
      <span
        :class="b('icon', { wallet: true })"
        v-html="displayedIcons.wallet"
      ></span>

      <div v-if="displayedCurrency.id" :class="b('wallet-group')">
        <p :class="b('label')">
          {{ displayedCurrency.amount }}
        </p>

        <p :class="b('wallet-currency')">
          {{
            displayedCurrency.currency
              ? displayedCurrency.currency.name.toUpperCase()
              : displayedCurrency.name.toUpperCase()
          }}
        </p>
      </div>
    </router-link>

    <div :class="b('group')">
      <SharedLanguage :class="b('language')" view="light" />

      <div :class="b('profile')">
        <p :class="b('label')">{{ displayedUserInfo.email }}</p>

        <button
          :class="b('profile-logout')"
          :disabled="isLogoutDisabled"
          @click="handleLogout"
        >
          <span :class="b('icon')" v-html="displayedIcons.logout"></span>
        </button>
      </div>
    </div>
  </div>
</template>
