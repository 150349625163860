import { SharedSelectOption } from "@/components/shared/select/model";
import { ApplicationStatus } from "@/shared/constants/enums";
import { SelectOptionFactory } from "@/shared/factory/select";

export const AdminUsersStatusOptions = (
  status: ApplicationStatus | string
): SharedSelectOption[] => {
  return [
    SelectOptionFactory(
      {
        id: 0,
        label: "Отклонено",
        value: "reject",
      },
      status
    ),
    SelectOptionFactory(
      {
        id: 1,
        label: "Успешно",
        value: "completed",
      },
      status
    ),
  ];
};
