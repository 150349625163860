<script lang="ts" src="./component.ts"></script>
<style lang="scss" src="./component.scss"></style>

<template>
  <div :class="b()">
    <span :class="b('logo')" v-html="displayedLogo"></span>

    <h1 :class="b('title')">
      {{ $t("Объединяя международные рынки") }} <br />
      {{ $t("Расширяя возможности глобальных трейдеров") }}
    </h1>
  </div>
</template>
