/* eslint-disable @typescript-eslint/ban-ts-comment */
import { Module, Mutation, VuexModule } from "vuex-module-decorators";

import { ApplicationStoreRequest } from "@/shared/repository/modules/application/repo";
import { ApplicationStoreRequestFactory } from "@/shared/repository/modules/application/factory";

enum ApplicationMutation {
  UPDATE_APPLICATION = "UPDATE_APPLICATION",
}

interface State {
  application?: ApplicationStoreRequest;
}

@Module({
  namespaced: true,
  stateFactory: true,
  name: "application",
})
export default class ApplicationModuleState extends VuexModule<State> {
  application: ApplicationStoreRequest = ApplicationStoreRequestFactory();

  @Mutation
  [ApplicationMutation.UPDATE_APPLICATION](
    application: ApplicationStoreRequest
  ): void {
    this.application = application;
  }
}
