import { createI18n } from "vue-i18n";

import ru from "@/locales/ru-RU.json";
import en from "@/locales/en-US.json";

type MessageSchema = typeof ru;

const i18n = createI18n<[MessageSchema], "ru" | "en">({
  locale: "ru",
  fallbackLocale: "en",
  messages: {
    ru: ru,
    en: en,
  },
});

export default i18n;
