<script lang="ts" src="./component.ts"></script>
<style lang="scss" src="./component.scss"></style>

<template>
  <div :class="b()" v-click-outside="handleHide">
    <div :class="b('group', { error: isError })">
      <input
        v-if="!value"
        :class="b('field')"
        type="text"
        :placeholder="$t(placeholder)"
        :value="search"
        @input="handleSearch"
      />

      <button
        v-else
        ref="toggle"
        :class="b('toggle')"
        :disabled="isReadonly"
        @click="handleChangeVisibility"
      >
        <span v-if="value" :class="['fi', `fi-${displayedCountry}`]"></span>

        <span
          :class="b('toggle-label')"
          ref="toggleLabel"
          :style="{ maxWidth: displayedToggleLabelMaxWidth }"
        >
          {{ value }}
        </span>
      </button>

      <span
        :class="b('group-icon', { active: isVisible, disabled: isReadonly })"
        v-html="displayedIcons.chevron"
        @click="handleChangeVisibility"
      ></span>
    </div>

    <div :class="b('options', { visible: isVisible })">
      <button
        :class="b('option', { active: option.isActive })"
        v-for="option in displayedOptions"
        :key="option.value"
        @click="handleOption(option)"
      >
        <span :class="['fi', `fi-${option.icon}`]"></span>

        {{ option.label }}
      </button>
    </div>
  </div>
</template>
