/* eslint-disable @typescript-eslint/ban-ts-comment */
import { Module, Mutation, VuexModule } from "vuex-module-decorators";

enum RequisitesMutation {
  UPDATE_BANK_ID = "UPDATE_BANK_ID",
  UPDATE_CRYPTO_ID = "UPDATE_CRYPTO_ID",
}

interface State {
  bankId?: number | null;
  cryptoId?: number | null;
}

@Module({
  namespaced: true,
  stateFactory: true,
  name: "requisites",
})
export default class RequisitesModuleState extends VuexModule<State> {
  bankId = <number | null>null;
  cryptoId = <number | null>null;

  @Mutation
  [RequisitesMutation.UPDATE_BANK_ID](bankId: number | null): void {
    this.bankId = bankId;
  }

  @Mutation
  [RequisitesMutation.UPDATE_CRYPTO_ID](cryptoId: number | null): void {
    this.cryptoId = cryptoId;
  }
}
