import { SvgAttribute } from "@/shared/constants/constants";

export const COMPONENT_NAME = "history-table-row-application";

export const SVG: SvgAttribute = {
  waiting: `<svg width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
  <path opacity="0.967" fill-rule="evenodd" clip-rule="evenodd" d="M7.8466 1.85758C9.9356 1.85097 12.0245 1.85758 14.1134 1.87741C15.3748 1.88205 16.5647 2.17292 17.6831 2.75001C18.7488 3.43143 19.4429 4.38996 19.7655 5.62561C19.9008 6.14683 19.9934 6.67566 20.0431 7.21215C20.0867 8.46757 20.1065 9.72356 20.1026 10.9802C20.1065 12.2368 20.0867 13.4928 20.0431 14.7482C19.9752 15.7869 19.6975 16.7653 19.2102 17.6833C18.5288 18.749 17.5702 19.4431 16.3346 19.7656C15.8133 19.901 15.2845 19.9935 14.748 20.0433C13.4926 20.0869 12.2366 20.1067 10.98 20.1028C9.7234 20.1067 8.46742 20.0869 7.21199 20.0433C6.17312 19.9753 5.19474 19.6976 4.27689 19.2103C3.06412 18.4201 2.33035 17.3029 2.07557 15.8588C1.96241 15.2824 1.8963 14.7007 1.87725 14.1136C1.85081 12.0246 1.85081 9.93572 1.87725 7.84676C1.89648 6.8058 2.0948 5.801 2.47221 4.83234C3.09886 3.45256 4.14995 2.57336 5.62545 2.19472C6.36133 2.01315 7.10168 1.90078 7.8466 1.85758ZM7.80694 3.16648C9.92235 3.15986 12.0378 3.16648 14.1531 3.18631C15.1466 3.18927 16.0853 3.41403 16.9692 3.86059C17.7484 4.34996 18.2574 5.04406 18.4962 5.94292C18.614 6.37215 18.6934 6.80845 18.7342 7.25181C18.7978 9.53849 18.811 11.8258 18.7739 14.1136C18.7567 14.9307 18.6113 15.724 18.3376 16.4934C17.8849 17.5275 17.1115 18.1952 16.0173 18.4964C15.5882 18.6142 15.1519 18.6935 14.7084 18.7344C12.4085 18.7982 10.108 18.8114 7.80694 18.774C7.00344 18.7525 6.22338 18.6071 5.4668 18.3377C4.43271 17.8851 3.76505 17.1116 3.46379 16.0174C3.29383 15.3943 3.20128 14.7597 3.18615 14.1136C3.1597 12.0246 3.1597 9.93572 3.18615 7.84676C3.18431 6.75665 3.44873 5.73861 3.97942 4.79268C4.47204 4.11931 5.12648 3.6764 5.94276 3.46395C6.56028 3.3037 7.18169 3.20454 7.80694 3.16648Z" fill="#A1A2AB"/>
  <path d="M10.1536 5.79584C10.1536 5.39878 10.458 5.0769 10.8336 5.0769C11.2091 5.0769 11.5135 5.39878 11.5135 5.79584V10.9556C11.5135 11.3526 11.2091 11.6745 10.8336 11.6745C10.458 11.6745 10.1536 11.3526 10.1536 10.9556V5.79584Z" fill="#A1A2AB"/>
  <path d="M14.8905 13.0429C15.2157 13.2415 15.3271 13.6811 15.1394 14.025C14.9516 14.3689 14.5358 14.4867 14.2106 14.2882L10.7425 11.8414C10.1533 11.4231 10.1592 11.0513 10.1533 10.5769C10.3411 10.233 11.0972 10.3976 11.4224 10.5961L14.8905 13.0429Z" fill="#A1A2AB"/>
  </svg>`,
  pending: `<svg width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
  <path opacity="0.967" fill-rule="evenodd" clip-rule="evenodd" d="M7.77421 1.64926C9.46433 1.6425 11.1544 1.64926 12.8444 1.66954C12.9437 1.69894 13.0384 1.7395 13.1283 1.79123C15.4741 4.13704 17.82 6.48287 20.1658 8.82867C20.2175 8.91859 20.2581 9.01326 20.2875 9.1126C20.3145 10.8027 20.3145 12.4927 20.2875 14.1828C20.2827 15.4728 19.9852 16.6897 19.3951 17.8333C18.6983 18.9231 17.718 19.633 16.4544 19.9628C15.9213 20.1013 15.3805 20.1959 14.8319 20.2468C13.5481 20.2913 12.2637 20.3116 10.9786 20.3076C9.69351 20.3116 8.40908 20.2913 7.12523 20.2468C6.06284 20.1772 5.06231 19.8933 4.12367 19.395C2.88344 18.5868 2.13305 17.4443 1.8725 15.9675C1.75678 15.3781 1.68918 14.7832 1.6697 14.1828C1.64265 12.0465 1.64265 9.91032 1.6697 7.77406C1.68936 6.70953 1.89216 5.68198 2.27812 4.69138C2.91897 3.28036 3.99385 2.38125 5.50277 1.99404C6.25518 1.80838 7.01235 1.69345 7.77421 1.64926ZM7.73365 2.9878C9.16682 2.9878 10.6 2.9878 12.0332 2.9878C12.0169 4.25961 12.0372 5.53052 12.094 6.80059C12.198 8.43185 13.043 9.42561 14.6291 9.78186C14.9644 9.84522 15.3024 9.88578 15.6432 9.90355C16.7518 9.92383 17.8605 9.9306 18.9692 9.92383C18.976 11.3436 18.9692 12.7632 18.9489 14.1828C18.9508 15.2976 18.6804 16.3387 18.1377 17.306C17.5552 18.0623 16.7913 18.5423 15.846 18.746C15.4971 18.8176 15.1456 18.8717 14.7914 18.9082C12.4394 18.9734 10.0868 18.987 7.73365 18.9488C6.63322 18.9441 5.60563 18.6737 4.65097 18.1375C3.96236 17.6338 3.50942 16.9645 3.29216 16.1298C3.11835 15.4925 3.02371 14.8435 3.00823 14.1828C2.98119 12.0465 2.98119 9.91032 3.00823 7.77406C3.00867 6.74485 3.23851 5.77138 3.69778 4.85363C4.19823 4.05681 4.90805 3.53626 5.82726 3.29201C6.45876 3.12813 7.09424 3.02672 7.73365 2.9878ZM13.3717 3.96127C14.9184 5.48769 16.4597 7.02225 17.9957 8.56501C17.0619 8.59986 16.129 8.58635 15.197 8.52445C14.227 8.46008 13.6524 7.95306 13.4731 7.00339C13.4327 6.76161 13.4057 6.51824 13.392 6.27328C13.3717 5.50274 13.3649 4.73207 13.3717 3.96127Z" fill="#9E9FA8"/>
  <path opacity="0.974" fill-rule="evenodd" clip-rule="evenodd" d="M9.92374 11.1813C10.123 11.1636 10.3122 11.1974 10.4916 11.2827C11.1338 11.9249 11.7761 12.5672 12.4183 13.2094C12.5613 13.4739 12.5478 13.7307 12.3777 13.9801C11.749 14.6088 11.1203 15.2375 10.4916 15.8662C9.90861 16.1021 9.55711 15.9128 9.437 15.2983C9.45181 15.1457 9.49914 15.0037 9.57897 14.8724C9.78855 14.6629 9.99809 14.4533 10.2077 14.2437C8.95026 14.2302 7.69286 14.2167 6.43545 14.2032C5.99393 13.9888 5.87902 13.6575 6.09067 13.2094C6.16226 13.0888 6.26367 13.0009 6.39488 12.9458C7.6658 12.9323 8.93676 12.9187 10.2077 12.9052C9.99809 12.6956 9.78855 12.4861 9.57897 12.2765C9.33284 11.7914 9.44779 11.4263 9.92374 11.1813Z" fill="#9E9FA8"/>
  </svg>`,
  progress: `<svg width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
  <path opacity="0.969" fill-rule="evenodd" clip-rule="evenodd" d="M6.93935 2.04102C9.63212 2.03385 12.3248 2.04102 15.0175 2.0625C15.8338 2.0809 16.6358 2.19548 17.4237 2.40625C18.9703 2.95029 19.9945 4.00303 20.496 5.56445C20.6283 6.04313 20.7142 6.53009 20.7538 7.02539C20.8965 9.66084 20.8965 12.2962 20.7538 14.9316C20.659 16.9785 19.7065 18.4609 17.8964 19.3789C16.9671 19.7128 16.0075 19.8847 15.0175 19.8945C13.2844 19.9232 11.5513 19.9232 9.81826 19.8945C9.36322 19.661 9.24144 19.31 9.45302 18.8418C9.55129 18.7145 9.67302 18.6214 9.81826 18.5625C11.5514 18.5402 13.2845 18.5115 15.0175 18.4766C17.8294 18.5147 19.2832 17.1326 19.3788 14.3301C19.4435 12.4829 19.4579 10.6352 19.4218 8.78711C19.4214 8.00933 19.3641 7.2359 19.2499 6.4668C18.2115 7.50522 17.1731 8.54361 16.1347 9.58203C15.4869 10.2013 14.8137 10.7886 14.1151 11.3438C12.024 12.9193 9.93285 12.9193 7.84169 11.3438C7.14311 10.7886 6.46992 10.2013 5.82216 9.58203C4.78374 8.54361 3.74534 7.50522 2.70693 6.4668C2.6463 6.85158 2.60333 7.2383 2.57802 7.62695C2.53455 8.81538 2.5059 10.0042 2.49208 11.1934C2.32091 11.6119 2.01296 11.7623 1.56826 11.6445C1.34235 11.5476 1.19196 11.3829 1.11708 11.1504C1.08028 9.77393 1.10892 8.39893 1.20302 7.02539C1.29786 4.97857 2.25033 3.49617 4.06044 2.57813C4.99772 2.25014 5.95734 2.0711 6.93935 2.04102ZM9.04482 3.41602C11.036 3.41013 13.0269 3.43161 15.0175 3.48047C15.7497 3.49913 16.4659 3.61371 17.1659 3.82422C17.7821 4.08237 18.2834 4.4834 18.6698 5.02734C17.1635 6.56236 15.6309 8.06627 14.0722 9.53906C13.48 10.0573 12.8354 10.5013 12.1386 10.8711C11.2237 11.2307 10.3357 11.1734 9.47451 10.6992C8.92047 10.3457 8.39053 9.959 7.88466 9.53906C6.32588 8.06627 4.79336 6.56236 3.28701 5.02734C3.72725 4.3988 4.31447 3.96912 5.04872 3.73828C5.47235 3.6307 5.90204 3.55909 6.33779 3.52344C7.24671 3.48149 8.14905 3.44568 9.04482 3.41602Z" fill="#FCCA13"/>
  <path opacity="0.957" fill-rule="evenodd" clip-rule="evenodd" d="M0.666098 13.9434C1.72609 13.9362 2.78599 13.9434 3.84579 13.9648C4.22502 14.1143 4.38973 14.3935 4.33993 14.8027C4.27547 15.0963 4.09644 15.2754 3.80282 15.3399C2.78589 15.3685 1.76896 15.3685 0.752036 15.3399C0.216588 15.1378 0.066197 14.7725 0.300864 14.2441C0.405758 14.1172 0.527503 14.017 0.666098 13.9434Z" fill="#FCCA13"/>
  <path opacity="0.963" fill-rule="evenodd" clip-rule="evenodd" d="M0.666098 16.6934C2.64271 16.6862 4.61927 16.6934 6.59579 16.7148C6.92291 16.8238 7.09478 17.053 7.11141 17.4023C7.07725 17.7581 6.89107 17.9872 6.55282 18.0898C4.61922 18.1185 2.68563 18.1185 0.752036 18.0898C0.216588 17.8878 0.066197 17.5225 0.300864 16.9941C0.405758 16.8672 0.527503 16.767 0.666098 16.6934Z" fill="#FCCA13"/>
  </svg>`,
  in_work: `<svg width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
  <path opacity="0.964" fill-rule="evenodd" clip-rule="evenodd" d="M6.42383 2.25586C6.9844 2.29221 7.51433 2.4426 8.01367 2.70703C11.1934 4.49741 14.373 6.28775 17.5527 8.07812C18.9672 9.24748 19.3611 10.7013 18.7344 12.4395C18.4957 12.9788 18.1448 13.4299 17.6816 13.793C14.5233 15.5942 11.3579 17.3846 8.18555 19.1641C6.52369 20.0386 5.04127 19.8023 3.73828 18.4551C3.31628 17.9259 3.05847 17.3244 2.96484 16.6504C2.9362 12.8691 2.9362 9.08789 2.96484 5.30664C3.3503 3.40967 4.5033 2.39274 6.42383 2.25586ZM5.99414 3.7168C6.55471 3.64926 7.07034 3.77101 7.54102 4.08203C10.5775 5.78647 13.6139 7.49087 16.6504 9.19531C17.4438 9.78476 17.7374 10.5654 17.5313 11.5371C17.3727 12.111 17.0361 12.5479 16.5215 12.8477C13.333 14.6352 10.139 16.4112 6.93945 18.1758C6.00527 18.3896 5.25332 18.1103 4.68359 17.3379C4.53226 17.0844 4.43201 16.8123 4.38281 16.5215C4.35415 12.8262 4.35415 9.13086 4.38281 5.43555C4.57583 4.53372 5.11294 3.96079 5.99414 3.7168Z" fill="#36A1EA"/>
  </svg>`,
  reject: `<svg width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
  <path opacity="0.986" fill-rule="evenodd" clip-rule="evenodd" d="M5.77953 2.04102C9.24569 2.03385 12.7118 2.04102 16.178 2.0625C17.7755 2.32823 18.9142 3.19477 19.594 4.66211C19.738 5.02253 19.8383 5.39494 19.8948 5.7793C19.9234 9.24546 19.9234 12.7116 19.8948 16.1777C19.4866 18.2474 18.2476 19.4863 16.178 19.8945C12.7118 19.9232 9.24569 19.9232 5.77953 19.8945C4.1815 19.6282 3.04283 18.7617 2.36351 17.2949C2.21933 16.934 2.11907 16.5617 2.06273 16.1777C2.02048 12.6243 2.0348 9.07225 2.1057 5.52149C2.60166 3.58593 3.82627 2.42578 5.77953 2.04102ZM6.33812 3.41602C9.43191 3.40885 12.5257 3.41602 15.6194 3.4375C16.9354 3.52583 17.8449 4.17752 18.3479 5.39258C18.455 5.71317 18.5123 6.04261 18.5198 6.38086C18.5484 9.44595 18.5484 12.5111 18.5198 15.5762C18.4089 17.0375 17.657 17.99 16.2639 18.4336C16.0368 18.4777 15.8077 18.5063 15.5764 18.5195C12.5113 18.5482 9.44618 18.5482 6.38109 18.5195C4.9904 18.4318 4.05225 17.7372 3.56663 16.4355C3.49027 16.1542 3.4473 15.8678 3.43773 15.5762C3.39624 12.3527 3.41056 9.13009 3.4807 5.9082C3.84221 4.39338 4.79468 3.56264 6.33812 3.41602Z" fill="#F86E6E"/>
  <path opacity="0.962" fill-rule="evenodd" clip-rule="evenodd" d="M7.84173 7.36913C8.06847 7.34468 8.28332 7.38052 8.48626 7.47655C9.3104 8.30787 10.1412 9.13145 10.9784 9.94726C11.8157 9.13145 12.6465 8.30787 13.4706 7.47655C13.8179 7.30885 14.1402 7.34468 14.4374 7.58398C14.6176 7.87603 14.6319 8.17682 14.4804 8.48632C13.6491 9.31046 12.8255 10.1412 12.0097 10.9785C12.8255 11.8158 13.6491 12.6466 14.4804 13.4707C14.6481 13.818 14.6123 14.1403 14.373 14.4375C14.0809 14.6177 13.7801 14.632 13.4706 14.4805C12.6465 13.6491 11.8157 12.8256 10.9784 12.0098C10.1412 12.8256 9.3104 13.6491 8.48626 14.4805C8.13894 14.6482 7.81668 14.6123 7.51946 14.373C7.33929 14.081 7.32498 13.7802 7.47649 13.4707C8.30781 12.6466 9.13139 11.8158 9.9472 10.9785C9.13139 10.1412 8.30781 9.31046 7.47649 8.48632C7.24437 7.98668 7.36615 7.61427 7.84173 7.36913Z" fill="#F86E6E"/>
  </svg>`,
  completed: `<svg width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
  <path opacity="0.967" fill-rule="evenodd" clip-rule="evenodd" d="M7.8466 1.85758C9.9356 1.85097 12.0245 1.85758 14.1134 1.87741C15.3748 1.88205 16.5647 2.17292 17.6831 2.75001C18.7488 3.43143 19.4429 4.38996 19.7655 5.62561C19.9008 6.14683 19.9934 6.67566 20.0431 7.21215C20.0867 8.46757 20.1065 9.72356 20.1026 10.9802C20.1065 12.2368 20.0867 13.4928 20.0431 14.7482C19.9752 15.7869 19.6975 16.7653 19.2102 17.6833C18.5288 18.749 17.5702 19.4431 16.3346 19.7656C15.8133 19.901 15.2845 19.9935 14.748 20.0433C13.4926 20.0869 12.2366 20.1067 10.98 20.1028C9.7234 20.1067 8.46742 20.0869 7.21199 20.0433C6.17312 19.9753 5.19474 19.6976 4.27689 19.2103C3.06412 18.4201 2.33035 17.3029 2.07557 15.8588C1.96241 15.2824 1.8963 14.7007 1.87725 14.1136C1.85081 12.0246 1.85081 9.93572 1.87725 7.84676C1.89648 6.8058 2.0948 5.801 2.47221 4.83234C3.09886 3.45256 4.14995 2.57336 5.62545 2.19472C6.36133 2.01315 7.10168 1.90078 7.8466 1.85758ZM7.80694 3.16648C9.92235 3.15986 12.0378 3.16648 14.1531 3.18631C15.1466 3.18927 16.0853 3.41403 16.9692 3.86059C17.7484 4.34996 18.2574 5.04406 18.4962 5.94292C18.614 6.37215 18.6934 6.80845 18.7342 7.25181C18.7978 9.53849 18.811 11.8258 18.7739 14.1136C18.7567 14.9307 18.6113 15.724 18.3376 16.4934C17.8849 17.5275 17.1115 18.1952 16.0173 18.4964C15.5882 18.6142 15.1519 18.6935 14.7084 18.7344C12.4085 18.7982 10.108 18.8114 7.80694 18.774C7.00344 18.7525 6.22338 18.6071 5.4668 18.3377C4.43271 17.8851 3.76505 17.1116 3.46379 16.0174C3.29383 15.3943 3.20128 14.7597 3.18615 14.1136C3.1597 12.0246 3.1597 9.93572 3.18615 7.84676C3.18431 6.75665 3.44873 5.73861 3.97942 4.79268C4.47204 4.11931 5.12648 3.6764 5.94276 3.46395C6.56028 3.3037 7.18169 3.20454 7.80694 3.16648Z" fill="#73D264"/>
  <path opacity="0.959" fill-rule="evenodd" clip-rule="evenodd" d="M14.3915 7.92609C15.1076 7.93481 15.3522 8.27858 15.1253 8.95734C13.4517 10.6441 11.766 12.3166 10.0682 13.9748C9.81731 14.0674 9.57933 14.0409 9.35424 13.8954C8.52793 13.0691 7.70158 12.2428 6.87527 11.4165C6.67588 11.0906 6.69571 10.7799 6.93477 10.4844C7.20436 10.3181 7.482 10.3049 7.7677 10.4447C8.43535 11.1124 9.10305 11.7801 9.7707 12.4477C11.2316 10.9868 12.6926 9.52583 14.1535 8.06491C14.2301 8.00704 14.3095 7.96079 14.3915 7.92609Z" fill="#73D264"/>
  </svg>`,
};
