import { defineComponent } from "vue";

import { COMPONENT_NAME } from "./attributes";
import { SettingsRequisitesComponent } from "./model";

import { SettingsRequisitesMain } from "./main";
import { SettingsRequisitesBank } from "./bank";
import { SettingsRequisitesCrypto } from "./crypto";

type ComponentType =
  | typeof SettingsRequisitesMain
  | typeof SettingsRequisitesBank
  | typeof SettingsRequisitesCrypto;

export default defineComponent({
  name: COMPONENT_NAME,
  components: {
    SettingsRequisitesMain,
    SettingsRequisitesBank,
    SettingsRequisitesCrypto,
  },
  data() {
    return {
      component: SettingsRequisitesComponent.main,
    };
  },
  computed: {
    displayedComponent(): ComponentType {
      switch (this.component) {
        case SettingsRequisitesComponent.main:
          return SettingsRequisitesMain;
        case SettingsRequisitesComponent.bank:
          return SettingsRequisitesBank;
        case SettingsRequisitesComponent.crypto:
          return SettingsRequisitesCrypto;
        default:
          return SettingsRequisitesMain;
      }
    },
  },
});
