import { defineComponent } from "vue";

import { COMPONENT_NAME } from "./attributes";

import { SharedSelect } from "@/components/shared";
import { UIButton } from "@/components/ui";

import {
  AdminCurrencyListResponse,
  AdminCurrencyModal,
} from "@/shared/repository/modules/admin/currency/repo";
import { AdminCurrencyModalOption } from "@/shared/repository/modules/admin/currency/enums";
import {
  AdminCurrencyShowResponseFactory,
  AdminCurrencyStoreRequestFactory,
} from "@/shared/repository/modules/admin/currency/factory";
import { SharedSelectOption } from "@/components/shared/select/model";
import { Country } from "@/shared/constants/enums";
import { SelectOptionFactory } from "@/shared/factory/select";
import { CountryCurrencies } from "@/shared/constants/constants";

enum ButtonLabel {
  create = "Добавить валюту",
  delete = "Удалить валюту",
}

export default defineComponent({
  name: COMPONENT_NAME,
  components: {
    SharedSelect,
    UIButton,
  },
  data() {
    return {
      isLoading: false,
      adminCurrency: AdminCurrencyShowResponseFactory(),
      adminCurrencyRepository: this.$projectServices.adminCurrencyRepository,
      adminCurrencyRepo: this.$projectServices.adminCurrencyRepo,
    };
  },
  async created(): Promise<void> {
    try {
      this.isLoading = true;

      if (this.displayedModal.id) {
        this.adminCurrency = await this.adminCurrencyRepository.show(
          this.displayedModal.id
        );
      }
    } catch (error) {
      console.log(error);
    } finally {
      this.isLoading = false;
    }
  },
  computed: {
    displayedModal(): AdminCurrencyModal {
      return this.adminCurrencyRepo.modal;
    },

    displayedList(): AdminCurrencyListResponse {
      return this.adminCurrencyRepo.list;
    },

    displayedCountryOptions(): SharedSelectOption[] {
      switch (this.displayedModal.option) {
        case AdminCurrencyModalOption.create:
          return Object.keys(Country)
            .map((countryKey, countryKeyIndex) => {
              const findFiat = this.displayedList.fiat.find(
                (fiat) =>
                  fiat.country === Country[countryKey as keyof typeof Country]
              );

              if (findFiat && findFiat?.frozen) {
                return SelectOptionFactory(
                  {
                    id: countryKeyIndex,
                    label: countryKey,
                    value: Country[countryKey as keyof typeof Country],
                    icon: Country[countryKey as keyof typeof Country],
                  },
                  this.adminCurrency.country
                );
              } else if (!findFiat) {
                return SelectOptionFactory(
                  {
                    id: countryKeyIndex,
                    label: countryKey,
                    value: Country[countryKey as keyof typeof Country],
                    icon: Country[countryKey as keyof typeof Country],
                  },
                  this.adminCurrency.country
                );
              }

              return SelectOptionFactory(
                {
                  id: -1,
                  label: "",
                  value: "",
                  icon: "",
                },
                ""
              );
            })
            .filter((country) => country.id !== -1);
        case AdminCurrencyModalOption.delete:
          return Object.keys(Country)
            .filter((countryKey) =>
              this.displayedList.fiat
                .filter((fiat) => !fiat.frozen)
                .find(
                  (fiat) =>
                    fiat.country === Country[countryKey as keyof typeof Country]
                )
            )
            .map((countryKey, countryKeyIndex) =>
              SelectOptionFactory(
                {
                  id: countryKeyIndex,
                  label: countryKey,
                  value: Country[countryKey as keyof typeof Country],
                  icon: Country[countryKey as keyof typeof Country],
                },
                this.adminCurrency.country
              )
            );
        default:
          return [];
      }
    },

    displayedCurrencyOptions(): SharedSelectOption[] {
      return Object.keys(CountryCurrencies)
        .filter((country) => country === this.adminCurrency.country)
        .map((country, countryIndex) =>
          SelectOptionFactory(
            {
              id: countryIndex,
              label:
                CountryCurrencies[country as keyof typeof CountryCurrencies]
                  .name,
              value:
                CountryCurrencies[country as keyof typeof CountryCurrencies]
                  .currency,
              icon: country,
            },
            this.adminCurrency.name
          )
        );
    },

    displayedButtonLabel(): ButtonLabel {
      switch (this.displayedModal.option) {
        case AdminCurrencyModalOption.create:
          return ButtonLabel.create;
        case AdminCurrencyModalOption.delete:
          return ButtonLabel.delete;
        default:
          return ButtonLabel.create;
      }
    },

    isCurrencyShow(): boolean {
      return this.displayedModal.option !== AdminCurrencyModalOption.delete;
    },

    isButtonDisabled(): boolean {
      const { country, name } = this.adminCurrency;

      return this.displayedModal.option === AdminCurrencyModalOption.create
        ? !country || !name || this.isLoading
        : !country || this.isLoading;
    },
  },
  methods: {
    async handleCountry(country: string): Promise<void> {
      try {
        if (this.displayedModal.option === AdminCurrencyModalOption.delete) {
          this.isLoading = true;

          this.adminCurrency = await this.adminCurrencyRepository.show(
            this.displayedList.fiat.find((fiat) => fiat.country === country)
              ?.id || 0
          );
        } else {
          this.adminCurrency = AdminCurrencyShowResponseFactory({
            country: country,
          });
        }
      } catch (error) {
        console.log(error);
      } finally {
        this.isLoading = false;
      }
    },

    async handleButton(): Promise<void> {
      try {
        this.isLoading = true;

        switch (this.displayedModal.option) {
          case AdminCurrencyModalOption.create:
            await this.adminCurrencyRepository.store(
              AdminCurrencyStoreRequestFactory(this.adminCurrency)
            );

            break;
          case AdminCurrencyModalOption.delete:
            await this.adminCurrencyRepository.delete(this.displayedModal.id);

            break;
          default:
            break;
        }

        await this.adminCurrencyRepo.updateList();

        this.adminCurrencyRepo.UPDATE_IS_SHOW(false);
      } catch (error) {
        console.log(error);
      } finally {
        this.isLoading = false;
      }
    },
  },
});
