import { toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, renderSlot as _renderSlot, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(_ctx.b())
  }, [
    _createElementVNode("div", {
      class: _normalizeClass(_ctx.b('header'))
    }, [
      _createElementVNode("h4", {
        class: _normalizeClass(_ctx.b('header-title'))
      }, _toDisplayString(_ctx.$t(_ctx.title)), 3),
      _createElementVNode("button", {
        class: _normalizeClass(_ctx.b('header-link')),
        onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.handleHistory && _ctx.handleHistory(...args)))
      }, [
        _createElementVNode("span", {
          class: _normalizeClass(_ctx.b('header-link-label', { big: true }))
        }, _toDisplayString(_ctx.$t("Смотреть все")), 3),
        _createElementVNode("span", {
          class: _normalizeClass(_ctx.b('header-link-label', { small: true }))
        }, _toDisplayString(_ctx.$t("Все")), 3)
      ], 2)
    ], 2),
    _renderSlot(_ctx.$slots, "default")
  ], 2))
}