<script lang="ts" src="./component.ts"></script>
<style lang="scss" src="./component.scss"></style>

<template>
  <div :class="b()">
    <div :class="b('columns')">
      <p :class="b('columns-label')">Bank</p>
    </div>

    <div :class="b('body')">
      <AdminRequisitesTableRow
        v-for="requisite in displayedRequisites"
        :key="requisite.id"
        :requisite="requisite"
      />
    </div>
  </div>
</template>
