<script lang="ts" src="./component.ts"></script>
<style lang="scss" src="./component.scss"></style>

<template>
  <div :class="b()">
    <h2 :class="b('title')">
      {{ $t("Верификация информации о компании") }}
    </h2>

    <div :class="b('cards')">
      <button
        :class="b('card', { padding: item.isPadding })"
        v-for="item in displayedList"
        :key="item.id"
        :disabled="item.isDisabled"
        @click="handleCard(item)"
      >
        <span :class="b('card-icon')" v-html="item.icon"></span>

        <div :class="b('card-info')">
          <h4 :class="b('card-title')">
            {{ $t(item.title) }}
          </h4>

          <p :class="b('card-label')">
            {{ $t(item.label) }}
          </p>
        </div>

        <span :class="b('card-chevron')" v-html="displayedSvg.chevron"></span>
      </button>
    </div>
  </div>
</template>
