/* eslint-disable @typescript-eslint/ban-ts-comment */
import { Module, Mutation, Action, VuexModule } from "vuex-module-decorators";

import {
  ApplicationFilterRequest,
  ApplicationHistoryResponse,
} from "@/shared/repository/modules/application/repo";
import { ApplicationFilterRequestFactory } from "@/shared/repository/modules/application/factory";
import { HistoryTab } from "@/shared/constants/enums";
import { Search } from "@/shared/constants/interfaces";
import { SearchFactory } from "@/shared/factory/search";
import { $projectServices } from "@/shared/repository/instances/project-services";

enum HistoryMutation {
  UPDATE_APPLICATION_HISTORY = "UPDATE_APPLICATION_HISTORY",
  UPDATE_FILTER = "UPDATE_FILTER",
  CLEAR_FILTER = "CLEAR_FILTER",
  UPDATE_TAB = "UPDATE_TAB",
  UPDATE_IS_FILTER = "UPDATE_IS_FILTER",
  UPDATE_SEARCH_VALUE = "UPDATE_SEARCH_VALUE",
  UPDATE_IS_LOADING = "UPDATE_IS_LOADING",
  ADD_PAGE = "ADD_PAGE",
  CLEAR_PAGE = "CLEAR_PAGE",
}

interface State {
  applicationHistory?: ApplicationHistoryResponse[];
  filter?: ApplicationFilterRequest;
  tab?: HistoryTab;
  isFilter?: boolean;
  search?: Search;
  isLoading?: boolean;
}

@Module({
  namespaced: true,
  stateFactory: true,
  name: "history",
})
export default class HistoryModuleState extends VuexModule<State> {
  applicationHistory: ApplicationHistoryResponse[] = [];
  filter: ApplicationFilterRequest = ApplicationFilterRequestFactory();
  tab: HistoryTab = HistoryTab.depositWithdrawal;
  isFilter: boolean = false;
  search: Search = SearchFactory();
  isLoading: boolean = false;

  @Mutation
  [HistoryMutation.UPDATE_APPLICATION_HISTORY](
    applicationHistory: ApplicationHistoryResponse[]
  ): void {
    this.applicationHistory = applicationHistory;
  }

  @Mutation
  [HistoryMutation.UPDATE_FILTER](filter: ApplicationFilterRequest): void {
    this.filter = filter;
  }

  @Mutation
  [HistoryMutation.CLEAR_FILTER](): void {
    this.filter = ApplicationFilterRequestFactory();
  }

  @Mutation
  [HistoryMutation.UPDATE_TAB](tab: HistoryTab): void {
    this.tab = tab;
  }

  @Mutation
  [HistoryMutation.UPDATE_IS_FILTER](isFilter: boolean): void {
    this.isFilter = isFilter;
  }

  @Mutation
  [HistoryMutation.UPDATE_SEARCH_VALUE](value: string): void {
    this.search.value = value;
  }

  @Mutation
  [HistoryMutation.UPDATE_IS_LOADING](isLoading: boolean): void {
    this.isLoading = isLoading;
  }

  @Mutation
  [HistoryMutation.ADD_PAGE](): void {
    this.filter.page += 1;
  }

  @Mutation
  [HistoryMutation.CLEAR_PAGE](): void {
    this.filter.page = 1;
  }

  @Action
  async fetch(): Promise<void> {
    try {
      this.UPDATE_IS_LOADING(true);

      switch (this.tab) {
        case HistoryTab.depositWithdrawal:
          await $projectServices.depositWithdrawalRepo.updateHistory(
            this.filter
          );

          break;
        case HistoryTab.application:
          await $projectServices.applicationRepository.history(this.filter);

          break;
        default:
          break;
      }
    } catch (error) {
      console.log(error);
    } finally {
      this.UPDATE_IS_LOADING(false);
    }
  }
}
