import { defineComponent } from "vue";

import { PAGE_NAME, SVG } from "./attributes";

import {
  HistoryHeader,
  HistoryTable,
  HistoryDetails,
  HistoryApplicationsDetails,
  HistoryFilters,
  HistoryRemove,
} from "@/components/pages/history";
import { SharedButtonIcon } from "@/components/shared";
import { HistoryTab } from "@/shared/constants/enums";
import { PAGE_SIZE } from "@/shared/constants/constants";

export default defineComponent({
  name: PAGE_NAME,
  components: {
    HistoryHeader,
    HistoryTable,
    HistoryDetails,
    HistoryApplicationsDetails,
    HistoryFilters,
    HistoryRemove,
    SharedButtonIcon,
  },
  data() {
    return {
      isDetails: false,
      isLoading: false,
      historyRepo: this.$projectServices.historyRepo,
      depositWithdrawalRepo: this.$projectServices.depositWithdrawalRepo,
    };
  },
  async created(): Promise<void> {
    try {
      this.historyRepo.CLEAR_PAGE();

      await this.historyRepo.fetch();
    } catch (error) {
      console.log(error);
    }
  },
  computed: {
    displayedMoreIcon(): string {
      return SVG.more;
    },

    isFilter(): boolean {
      return this.historyRepo.isFilter;
    },

    isMoreShow(): boolean {
      switch (this.historyRepo.tab) {
        case HistoryTab.depositWithdrawal:
          return (
            this.depositWithdrawalRepo.history.length ===
            this.historyRepo.filter.page * PAGE_SIZE
          );
        case HistoryTab.application:
          return false;
        default:
          return false;
      }
    },
  },
  methods: {
    async handleMore(): Promise<void> {
      try {
        this.isLoading = true;

        this.historyRepo.ADD_PAGE();

        await this.historyRepo.fetch();
      } catch (error) {
        console.log(error);
      } finally {
        this.isLoading = false;
      }
    },
  },
});
