import { defineComponent } from "vue";

import { PAGE_NAME } from "./attributes";
import { SettingsTabValue } from "./model";

import {
  SettingsTabs,
  SettingsMain,
  SettingsKycKyb,
  SettingsRequisites,
} from "@/components/pages/settings";

type Component =
  | typeof SettingsMain
  | typeof SettingsKycKyb
  | typeof SettingsRequisites;

export default defineComponent({
  name: PAGE_NAME,
  components: {
    SettingsTabs,
    SettingsMain,
    SettingsKycKyb,
    SettingsRequisites,
  },
  data() {
    return {
      tab: SettingsTabValue.main,
    };
  },
  computed: {
    displayedComponent(): Component {
      switch (this.tab) {
        case SettingsTabValue.main:
          return SettingsMain;
        case SettingsTabValue["kyc-kyb"]:
          return SettingsKycKyb;
        case SettingsTabValue.requisites:
          return SettingsRequisites;
        default:
          return SettingsMain;
      }
    },
  },
});
