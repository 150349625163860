import { AxiosInstance } from "axios";

import { ResponseData } from "@/shared/constants/interfaces";

import { AdminUsersUrlGenerator } from "./url-generator";
import { AdminUsersResponseFactory } from "./factory";
import { AdminUsersListResponse, AdminUsersResponse } from "./repo";

export interface AdminUsersProjectRepository {
  users(): Promise<AdminUsersResponse[]>;
  make(email: string): Promise<void>;
  list(): Promise<AdminUsersListResponse>;
}

export class AdminUsersHttpRepo implements AdminUsersProjectRepository {
  constructor(
    private readonly axios: AxiosInstance,
    private readonly urlGenerator: AdminUsersUrlGenerator
  ) {}

  async users(): Promise<AdminUsersResponse[]> {
    const { data } = await this.axios.get<ResponseData<AdminUsersResponse[]>>(
      this.urlGenerator.users()
    );

    return data.data.map((user, userIndex) =>
      AdminUsersResponseFactory(user, userIndex)
    );
  }

  async make(email: string): Promise<void> {
    await this.axios.post<void>(this.urlGenerator.make(), {
      email,
    });
  }

  async list(): Promise<AdminUsersListResponse> {
    const { data } = await this.axios.get<ResponseData<AdminUsersListResponse>>(
      this.urlGenerator.list()
    );

    return data.data;
  }
}
