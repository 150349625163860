import { defineComponent } from "vue";

import { COMPONENT_NAME } from "./attributes";

import {
  SharedWindow,
  SharedDate,
  SharedSelect,
  SharedMultiSelect,
} from "@/components/shared";
import { UIButton, UIInput } from "@/components/ui";

import { ApplicationFilterRequest } from "@/shared/repository/modules/application/repo";
import { SharedSelectOption } from "@/components/shared/select/model";
import { SelectOptionFactory } from "@/shared/factory/select";
import { ApplicationStatus } from "@/shared/constants/enums";
import { SharedMultiSelectOption } from "@/shared/constants/constants";
import { AdminCurrencyShowResponse } from "@/shared/repository/modules/admin/currency/repo";
import { AdminCurrencyType } from "@/shared/repository/modules/admin/currency/enums";

export default defineComponent({
  name: COMPONENT_NAME,
  components: {
    SharedWindow,
    SharedDate,
    SharedSelect,
    SharedMultiSelect,
    UIButton,
    UIInput,
  },
  data() {
    return {
      currency: <AdminCurrencyShowResponse[]>[],
      historyRepo: this.$projectServices.historyRepo,
      walletRepository: this.$projectServices.walletRepository,
    };
  },
  async created(): Promise<void> {
    try {
      this.currency = (await this.walletRepository.currency()).filter(
        (currency) => currency.type === AdminCurrencyType.fiat
      );
    } catch (error) {
      console.log(error);
    }
  },
  computed: {
    displayedFilter(): ApplicationFilterRequest {
      return this.historyRepo.filter;
    },

    displayedCurrencyOptions(): SharedMultiSelectOption[] {
      return this.currency.map((currency) => ({
        id: currency.id,
        label: currency.name.toUpperCase(),
        value: currency.id,
        icon: currency.country,
        isActive: !!this.displayedFilter.currency_id.find(
          (id) => id === currency.id
        ),
      }));
    },

    displayedStatusOptions(): SharedSelectOption[] {
      return [
        SelectOptionFactory(
          {
            id: 0,
            label: "Создано",
            value: ApplicationStatus.pending,
          },
          this.displayedFilter.status
        ),
        SelectOptionFactory(
          {
            id: 0,
            label: "Ожидание",
            value: ApplicationStatus.waiting,
          },
          this.displayedFilter.status
        ),
        SelectOptionFactory(
          {
            id: 0,
            label: "В работе",
            value: ApplicationStatus.inWork,
          },
          this.displayedFilter.status
        ),
        SelectOptionFactory(
          {
            id: 0,
            label: "Отклонено",
            value: ApplicationStatus.reject,
          },
          this.displayedFilter.status
        ),
        SelectOptionFactory(
          {
            id: 0,
            label: "Успешно",
            value: ApplicationStatus.completed,
          },
          this.displayedFilter.status
        ),
      ];
    },

    isLoading(): boolean {
      return this.historyRepo.isLoading;
    },

    isDisabled(): boolean {
      return this.isLoading;
    },
  },
  methods: {
    handleCurrency(currency_id: number[]): void {
      this.historyRepo.UPDATE_FILTER({
        ...this.displayedFilter,
        currency_id,
      });
    },

    handleAmountFrom(amount_from: number): void {
      this.historyRepo.UPDATE_FILTER({
        ...this.displayedFilter,
        amount_from,
      });
    },

    handleAmountTo(amount_to: number): void {
      this.historyRepo.UPDATE_FILTER({
        ...this.displayedFilter,
        amount_to,
      });
    },

    handleDateFrom(date_from: string): void {
      this.historyRepo.UPDATE_FILTER({
        ...this.displayedFilter,
        date_from,
      });
    },

    handleDateTo(date_to: string): void {
      this.historyRepo.UPDATE_FILTER({
        ...this.displayedFilter,
        date_to,
      });
    },

    handleStatus(status: ApplicationStatus): void {
      this.historyRepo.UPDATE_FILTER({
        ...this.displayedFilter,
        status,
      });
    },

    async handleClose(): Promise<void> {
      this.historyRepo.CLEAR_FILTER();
      this.historyRepo.UPDATE_IS_FILTER(false);

      await this.historyRepo.fetch();
    },

    async handleApply(): Promise<void> {
      this.historyRepo.CLEAR_PAGE();

      await this.historyRepo.fetch();

      this.historyRepo.UPDATE_IS_FILTER(false);
    },
  },
});
