<script lang="ts" src="./component.ts"></script>
<style lang="scss" src="./component.scss"></style>

<template>
  <div :class="b()">
    <h1 :class="b('title')">
      {{ $t(title) }}
    </h1>

    <div :class="b('form')">
      <SharedSelect
        :class="b('type')"
        v-if="isType"
        placeholder="Выберите тип"
        view="light"
        :options="displayedOptions"
        :value="type"
        @update:value="handleChangeType"
      />

      <UIInput
        v-if="isEmail"
        :class="b('email')"
        placeholder="Email"
        view="light"
        :modelValue="email"
        @update:modelValue="handleChangeEmail"
      />

      <UIInput
        :class="b('id')"
        placeholder="ID"
        view="light"
        type="number"
        :modelValue="id"
        :is-number="true"
        @update:modelValue="handleChangeId"
      />

      <UIButton
        :class="b('search')"
        label="Поиск"
        view="main"
        :disabled="isSearchDisabled"
        @click="handleSearch"
      />
    </div>
  </div>
</template>
