export enum AdminUsersApiRoutes {
  users = "/api/v1/admin/users",
  make = "/api/v1/admin/make-admin",
  list = "/api/v1/admin/list",
}

export interface AdminUsersProjectUrlGenerator {
  users(): string;
  make(): string;
  list(): string;
}

export class AdminUsersUrlGenerator implements AdminUsersProjectUrlGenerator {
  users(): string {
    return AdminUsersApiRoutes.users;
  }

  make(): string {
    return AdminUsersApiRoutes.make;
  }

  list(): string {
    return AdminUsersApiRoutes.list;
  }
}
