import { AdminCurrencyShowResponseFactory } from "../currency/factory";
import {
  AdminRequisite,
  AdminRequisitesBankListResponse,
  AdminRequisitesBankShowResponse,
  AdminRequisitesBankStoreRequest,
  AdminRequisitesBankUpdateRequest,
  AdminRequisitesListResponse,
  AdminRequisitesShowResponse,
  AdminRequisitesStoreRequest,
  AdminRequisitesUpdateRequest,
} from "./repo";

export const AdminRequisiteFactory = (
  payload: Partial<AdminRequisite> = {}
): AdminRequisite => {
  return {
    id: payload.id ?? 0,
    isShow: payload.isShow ?? false,
  };
};

// store

export const AdminRequisitesStoreRequestFactory = (
  payload: Partial<AdminRequisitesStoreRequest> = {}
): AdminRequisitesStoreRequest => {
  return {
    currency_id: payload.currency_id ?? 0,
    org_full_name_local: payload.org_full_name_local ?? "",
    org_full_name_eng: payload.org_full_name_eng ?? "",
    inn: payload.inn ?? "",
    current_account: payload.current_account ?? "",
    payment_code_name: payload.payment_code_name ?? "",
    mex_requisite_banks:
      payload.mex_requisite_banks?.map((requisiteBank) =>
        AdminRequisitesBankStoreRequestFactory(requisiteBank)
      ) ?? [],
  };
};

export const AdminRequisitesBankStoreRequestFactory = (
  payload: Partial<AdminRequisitesBankStoreRequest> = {}
): AdminRequisitesBankStoreRequest => {
  return {
    correspondent_bank: payload.correspondent_bank ?? "",
    bic: payload.bic ?? "",
    inn: payload.inn ?? "",
    correspondent_account: payload.correspondent_account ?? "",
    correspondent_swift: payload.correspondent_swift ?? "",
    recipient_bank: payload.recipient_bank ?? "",
    recipient_correspondent_account:
      payload.recipient_correspondent_account ?? "",
    recipient_correspondent_swift: payload.recipient_correspondent_swift ?? "",
  };
};

// update

export const AdminRequisitesUpdateRequestFactory = (
  payload: Partial<AdminRequisitesUpdateRequest> = {},
  isBanks: boolean = true
): AdminRequisitesUpdateRequest => {
  return {
    id: payload.id ?? 0,
    currency_id: payload.currency_id ?? 0,
    org_full_name_local: payload.org_full_name_local ?? "",
    org_full_name_eng: payload.org_full_name_eng ?? "",
    inn: payload.inn ?? "",
    current_account: payload.current_account ?? "",
    payment_code_name: payload.payment_code_name ?? "",
    mex_requisite_banks: isBanks
      ? payload.mex_requisite_banks?.map((requisiteBank) =>
          AdminRequisitesBankUpdateRequestFactory(requisiteBank)
        ) ?? []
      : payload.mex_requisite_banks!,
  };
};

export const AdminRequisitesBankUpdateRequestFactory = (
  payload: Partial<AdminRequisitesBankUpdateRequest> = {}
): AdminRequisitesBankUpdateRequest => {
  return {
    id: payload.id ?? 0,
    correspondent_bank: payload.correspondent_bank ?? "",
    bic: payload.bic ?? "",
    inn: payload.inn ?? "",
    correspondent_account: payload.correspondent_account ?? "",
    correspondent_swift: payload.correspondent_swift ?? "",
    recipient_bank: payload.recipient_bank ?? "",
    recipient_correspondent_account:
      payload.recipient_correspondent_account ?? "",
    recipient_correspondent_swift: payload.recipient_correspondent_swift ?? "",
  };
};

// show

export const AdminRequisitesShowResponseFactory = (
  payload: Partial<AdminRequisitesShowResponse> = {}
): AdminRequisitesShowResponse => {
  return {
    id: payload.id ?? 0,
    currency_id: payload.currency_id ?? 0,
    org_full_name_local: payload.org_full_name_local ?? "",
    org_full_name_eng: payload.org_full_name_eng ?? "",
    inn: payload.inn ?? "",
    current_account: payload.current_account ?? "",
    payment_code_name: payload.payment_code_name ?? "",
    created_at: payload.created_at ?? "",
    updated_at: payload.updated_at ?? "",
    mex_requisite_banks:
      payload.mex_requisite_banks?.map((requisiteBank) =>
        AdminRequisitesBankShowResponseFactory(requisiteBank)
      ) ?? [],
  };
};

export const AdminRequisitesBankShowResponseFactory = (
  payload: Partial<AdminRequisitesBankShowResponse> = {}
): AdminRequisitesBankShowResponse => {
  return {
    id: payload.id ?? "",
    mex_requisite_id: payload.mex_requisite_id ?? 0,
    correspondent_bank: payload.correspondent_bank ?? "",
    bic: payload.bic ?? "",
    inn: payload.inn ?? "",
    correspondent_account: payload.correspondent_account ?? "",
    correspondent_swift: payload.correspondent_swift ?? "",
    recipient_bank: payload.recipient_bank ?? "",
    recipient_correspondent_account:
      payload.recipient_correspondent_account ?? "",
    recipient_correspondent_swift: payload.recipient_correspondent_swift ?? "",
    created_at: payload.created_at ?? "",
    updated_at: payload.updated_at ?? "",
  };
};

// list

export const AdminRequisitesListResponseFactory = (
  payload: Partial<AdminRequisitesListResponse> = {}
): AdminRequisitesListResponse => {
  return {
    id: payload.id ?? 0,
    currency_id: payload.currency_id ?? 0,
    org_full_name_local: payload.org_full_name_local ?? "",
    org_full_name_eng: payload.org_full_name_eng ?? "",
    inn: payload.inn ?? "",
    current_account: payload.current_account ?? "",
    payment_code_name: payload.payment_code_name ?? "",
    created_at: payload.created_at ?? "",
    updated_at: payload.updated_at ?? "",
    currency: AdminCurrencyShowResponseFactory(payload.currency ?? {}),
    mex_requisite_banks:
      payload.mex_requisite_banks?.map((requisiteBank) =>
        AdminRequisitesBankListResponseFactory(requisiteBank)
      ) ?? [],
  };
};

export const AdminRequisitesBankListResponseFactory = (
  payload: Partial<AdminRequisitesBankListResponse> = {}
): AdminRequisitesBankListResponse => {
  return {
    id: payload.id ?? 0,
    mex_requisite_id: payload.mex_requisite_id ?? 0,
    correspondent_bank: payload.correspondent_bank ?? "",
    bic: payload.bic ?? "",
    inn: payload.inn ?? "",
    correspondent_account: payload.correspondent_account ?? "",
    correspondent_swift: payload.correspondent_swift ?? "",
    recipient_bank: payload.recipient_bank ?? "",
    recipient_correspondent_account:
      payload.recipient_correspondent_account ?? "",
    recipient_correspondent_swift: payload.recipient_correspondent_swift ?? "",
    created_at: payload.created_at ?? "",
    updated_at: payload.updated_at ?? "",
  };
};
