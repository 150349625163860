import { defineComponent, PropType } from "vue";

import { COMPONENT_NAME, SVG } from "./attributes";

import { SvgAttribute } from "@/shared/constants/constants";
import { AdminRequisitesListResponse } from "@/shared/repository/modules/admin/requisites/repo";
import { AdminRequisiteFactory } from "@/shared/repository/modules/admin/requisites/factory";

export default defineComponent({
  name: COMPONENT_NAME,
  props: {
    requisite: {
      type: <PropType<AdminRequisitesListResponse>>Object,
      required: true,
    },
  },
  data() {
    return {
      isVisible: false,
      isLoading: false,
      adminRequisitesRepo: this.$projectServices.adminRequisitesRepo,
      adminRequisitesRepository:
        this.$projectServices.adminRequisitesRepository,
    };
  },
  computed: {
    displayedIcons(): SvgAttribute {
      return SVG;
    },
  },
  methods: {
    handleToggle(): void {
      this.isVisible = !this.isVisible;
    },

    handleHide(): void {
      this.isVisible = false;
    },

    handleEdit(): void {
      this.adminRequisitesRepo.UPDATE_REQUISITE(
        AdminRequisiteFactory({
          id: this.requisite.id,
          isShow: true,
        })
      );
    },

    async handleRemove(): Promise<void> {
      try {
        this.isLoading = true;

        await this.adminRequisitesRepository.delete(this.requisite.id);

        await this.adminRequisitesRepo.updateList();
      } catch (error) {
        console.log(error);
      } finally {
        this.isLoading = false;
      }
    },
  },
});
