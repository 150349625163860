import { defineComponent } from "vue";

import { COMPONENT_NAME } from "./attributes";

import { AdminRequisitesTableRow } from "./row";

import { AdminRequisitesListResponse } from "@/shared/repository/modules/admin/requisites/repo";

export default defineComponent({
  name: COMPONENT_NAME,
  components: {
    AdminRequisitesTableRow,
  },
  data() {
    return {
      adminRequisitesRepo: this.$projectServices.adminRequisitesRepo,
    };
  },
  async created(): Promise<void> {
    await this.adminRequisitesRepo.updateList();
  },
  computed: {
    displayedRequisites(): AdminRequisitesListResponse[] {
      return this.adminRequisitesRepo.list;
    },
  },
});
