import { defineComponent } from "vue";

import { COMPONENT_NAME, SVG } from "./attributes";

import {
  AdminUsersFilter,
  AdminUsersTable,
  AdminUsersStatus,
  AdminUsersBalance,
} from "@/components/pages/admin-users";
import { SharedButtonIcon } from "@/components/shared";

import { PAGE_SIZE, SvgAttribute } from "@/shared/constants/constants";
import { SortFactory } from "@/shared/factory/sort";
import { SearchFactory } from "@/shared/factory/search";

export default defineComponent({
  name: COMPONENT_NAME,
  components: {
    AdminUsersFilter,
    AdminUsersTable,
    AdminUsersStatus,
    AdminUsersBalance,
    SharedButtonIcon,
  },
  data() {
    return {
      isBalance: false,
      isStatus: false,
      sort: SortFactory(),
      search: SearchFactory(),
      usersRepo: this.$projectServices.usersRepo,
    };
  },
  computed: {
    displayedUserIndex(): number {
      return this.usersRepo.index;
    },

    displayedIcons(): SvgAttribute {
      return SVG;
    },

    isMoreShow(): boolean {
      return (
        this.sort.page * PAGE_SIZE < this.usersRepo.users.length &&
        !this.search.value
      );
    },

    isStatusShow(): boolean {
      return !!this.displayedUserIndex && this.isStatus;
    },

    isBalanceShow(): boolean {
      return !!this.displayedUserIndex && this.isBalance;
    },
  },
  methods: {
    handleMore(): void {
      this.sort.page++;
    },
  },
});
