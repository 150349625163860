import {
  KybBasicInfoFields,
  KybParticipantFields,
  KybRegistrationInfoFields,
} from "./interfaces";
import {
  KybBasicInfoRequest,
  KybBasicInfoResponse,
  KybCompany,
  KybParticipant,
  KybParticipantRequest,
  KybParticipantResponse,
  KybParticipantUploadFileRequest,
  KybParticipantUploadFileResponse,
  KybRegistrationInfoRequest,
  KybRegistrationInfoResponse,
  KybUploadFileRequest,
  KybUploadFileResponse,
} from "./repo";

import {
  extractFileName,
  uniqueFileName,
} from "@/shared/utils/file-name-helpers";

export const KybCompanyFactory = (
  payload: Partial<KybCompany> = {}
): KybCompany => {
  return {
    isBasic: payload.isBasic ?? false,
    isParticipants: payload.isParticipants ?? {
      isWaiting:
        payload.isParticipants && "isWaiting" in payload.isParticipants
          ? payload.isParticipants
          : false,
      isSuccess:
        payload.isParticipants && "isSuccess" in payload.isParticipants
          ? payload.isParticipants
          : false,
      isExpected:
        payload.isParticipants && "isExpected" in payload.isParticipants
          ? payload.isParticipants
          : false,
    },
    isFiles: payload.isFiles ?? false,
  };
};

export const KybBasicInfoRequestFactory = (
  payload: Partial<KybBasicInfoResponse> = {}
): KybBasicInfoRequest => {
  return {
    country: payload.country ?? "",
    organisation: payload.organisation ?? "",
    regional_number: payload.regional_number ?? "",
    registration_date: payload.registration_date ?? "",
    inn: payload.inn ?? "",
    licensed: payload.licensed ? Boolean(payload.licensed) : false,
    telegram: payload.telegram ?? "",
  };
};

export const KybBasicInfoResponseFactory = (
  payload: Partial<KybBasicInfoResponse> = {}
): KybBasicInfoResponse => {
  return {
    id: payload?.id ?? null,
    user_app_id: payload?.user_app_id ?? null,
    country: payload?.country ?? "",
    organisation: payload?.organisation ?? "",
    regional_number: payload?.regional_number ?? "",
    registration_date: payload?.registration_date ?? "",
    inn: payload?.inn ?? "",
    licensed: Number(payload?.licensed) ? Boolean(payload.licensed) : false,
    telegram: payload?.telegram ?? "",
    status: payload?.status ?? "",
    creatd_at: payload?.creatd_at ?? "",
    updated_at: payload?.updated_at ?? "",
  };
};

export const KybRegistrationInfoRequestFactory = (
  payload: Partial<KybRegistrationInfoResponse> = {}
): KybRegistrationInfoRequest => {
  return {
    organisation_legal_form: payload.organisation_legal_form ?? "",
    business_area: payload.business_area ?? "",
    country: payload.address?.country ?? "",
    city: payload.address?.city ?? "",
    state: payload.address?.state ?? "",
    street: payload.address?.street ?? "",
    office: payload.address?.office ?? "",
    zip: payload.address?.zip ?? "",
    country_actual:
      payload.address && !payload.address.isActual
        ? payload.address.country_actual
        : "",
    city_actual:
      payload.address && !payload.address.isActual
        ? payload.address.city_actual
        : "",
    state_actual:
      payload.address && !payload.address.isActual
        ? payload.address.state_actual
        : "",
    street_actual:
      payload.address && !payload.address.isActual
        ? payload.address.street_actual
        : "",
    office_actual:
      payload.address && !payload.address.isActual
        ? payload.address.office_actual
        : "",
    zip_actual:
      payload.address && !payload.address.isActual
        ? payload.address.zip_actual
        : "",
    phone: payload.phone ?? "",
    site: payload.site ?? "",
  };
};

export const KybRegistrationInfoResponseFactory = (
  payload: Partial<KybRegistrationInfoResponse> = {}
): KybRegistrationInfoResponse => {
  return {
    id: payload.id ?? "",
    kyb_basic_infos_id: payload.kyb_basic_infos_id ?? "",
    organisation_legal_form: payload.organisation_legal_form ?? "",
    business_area: payload.business_area ?? "",
    phone: payload.phone ?? "",
    site: payload.site ?? "",
    creatd_at: payload.creatd_at ?? "",
    updated_at: payload.updated_at ?? "",
    address: {
      country: payload.address?.country ?? "",
      id: payload.address?.id ?? "",
      user_app_id: payload.address?.user_app_id ?? "",
      city: payload.address?.city ?? "",
      state: payload.address?.state ?? "",
      street: payload.address?.street ?? "",
      office: payload.address?.office ?? "",
      zip: payload.address?.zip ?? "",
      contact: payload.address?.contact ?? "",
      country_actual: payload.address?.country_actual ?? "",
      city_actual: payload.address?.city_actual ?? "",
      state_actual: payload.address?.state_actual ?? "",
      street_actual: payload.address?.street_actual ?? "",
      office_actual: payload.address?.office_actual ?? "",
      zip_actual: payload.address?.zip_actual ?? "",
      isActual:
        payload.address &&
        payload.address.country_actual &&
        payload.address.city_actual &&
        payload.address.state_actual &&
        payload.address.street_actual &&
        payload.address.office_actual &&
        payload.address.zip_actual
          ? false
          : true,
      creatd_at: payload.address?.creatd_at ?? "",
      updated_at: payload.address?.updated_at ?? "",
    },
  };
};

export const KybParticipantFactory = (
  payload: Partial<KybParticipant> = {}
): KybParticipant => {
  return {
    id: payload.id ?? null,
    sur_name: payload.sur_name ?? "",
    name: payload.name ?? "",
    patronymic: payload.patronymic ?? "",
    isSuccess: payload.isSuccess ?? false,
    isExpected: payload.isExpected ?? false,
  };
};

export const KybParticipantRequestFactory = (
  payload: Partial<KybParticipantResponse> = {}
): KybParticipantRequest => {
  return {
    sur_name: payload.sur_name ?? "",
    name: payload.name ?? "",
    patronymic: payload.patronymic ?? "",
    citizenship: payload.citizenship ?? "",
    birth_date: payload.birth_date ?? "",
    gender: payload.gender ?? "",
    share_in_org: payload.share_in_org ? Number(payload.share_in_org) : 0,
    country: payload.address?.country ?? "",
    city: payload.address?.city ?? "",
    state: payload.address?.state ?? "",
    street: payload.address?.street ?? "",
    office: payload.address?.office ?? "",
    zip: payload.address?.zip ?? "",
    phone: payload.address?.phone ?? "",
    is_beneficiary: payload.is_beneficiary ?? false,
    is_director: payload.is_director ?? false,
  };
};

export const KybParticipantResponseFactory = (
  payload: Partial<KybParticipantResponse> = {}
): KybParticipantResponse => {
  return {
    id: payload.id ?? null,
    kyb_basic_infos_id: payload.kyb_basic_infos_id ?? "",
    sur_name: payload.sur_name ?? "",
    name: payload.name ?? "",
    patronymic: payload.patronymic ?? "",
    citizenship: payload.citizenship ?? "",
    gender: payload.gender ?? "",
    birth_date: payload.birth_date ?? "",
    share_in_org: payload.share_in_org ? payload.share_in_org.toString() : "0",
    is_beneficiary: payload.is_beneficiary
      ? Boolean(payload.is_beneficiary)
      : false,
    is_director: payload.is_director ? Boolean(payload.is_director) : false,
    address: {
      id: payload.address?.id ?? "",
      country: payload.address?.country ?? "",
      user_app_id: payload.address?.user_app_id ?? "",
      city: payload.address?.city ?? "",
      state: payload.address?.state ?? "",
      street: payload.address?.street ?? "",
      office: payload.address?.office ?? "",
      zip: payload.address?.zip ?? "",
      contact: payload.address?.contact ?? "",
      country_actual: payload.address?.country_actual ?? "",
      city_actual: payload.address?.city_actual ?? "",
      state_actual: payload.address?.state_actual ?? "",
      street_actual: payload.address?.street_actual ?? "",
      office_actual: payload.address?.office_actual ?? "",
      zip_actual: payload.address?.zip_actual ?? "",
      phone: payload.address?.phone ?? "",
      creatd_at: payload.address?.creatd_at ?? "",
      updated_at: payload.address?.updated_at ?? "",
    },
  };
};

export const KybParticipantUploadFileRequestFactory = (
  payload: Partial<KybParticipantUploadFileResponse> = {}
): KybParticipantUploadFileRequest => {
  return {
    file: payload.file ?? "",
    name: payload.name ? uniqueFileName(payload.name) : "",
    type: payload.type ?? "",
    kyb_participant_id: payload.kyb_participant_id ?? null,
  };
};

export const KybParticipantUploadFileResponseFactory = (
  payload: Partial<KybParticipantUploadFileResponse> = {}
): KybParticipantUploadFileResponse => {
  return {
    id: payload.id ?? null,
    user_id: payload.user_id ?? null,
    kyb_participant_id: payload.kyb_participant_id ?? null,
    name: payload.name ? extractFileName(payload.name) : "",
    generated_name: payload.generated_name ?? "",
    link: payload.link ?? "",
    file: payload.file ?? "",
    type: payload.type ?? "",
    created_at: payload.created_at ?? "",
    updated_at: payload.updated_at ?? "",
    isUpload: payload.isUpload ?? false,
    isRemove: payload.isRemove ?? false,
  };
};

export const KybUploadFileRequestFactory = (
  payload: Partial<KybUploadFileResponse> = {}
): KybUploadFileRequest => {
  return {
    file: payload.file ?? "",
    name: payload.name ? uniqueFileName(payload.name) : "",
    type: payload.type ?? "",
  };
};

export const KybUploadFileResponseFactory = (
  payload: Partial<KybUploadFileResponse> = {}
): KybUploadFileResponse => {
  return {
    id: payload.id ?? null,
    user_id: payload.user_id ?? null,
    name: payload.name ? extractFileName(payload.name) : "",
    generated_name: payload.generated_name ?? "",
    link: payload.link ?? "",
    file: payload.file ?? "",
    type: payload.type ?? "",
    created_at: payload.created_at ?? "",
    updated_at: payload.updated_at ?? "",
    isUpload: payload.isUpload ?? false,
    isRemove: payload.isRemove ?? false,
  };
};

export const KybBasicInfoFieldsFactory = (): KybBasicInfoFields => {
  return {
    country: false,
    organisation: false,
    regional_number: false,
    registration_date: false,
    inn: false,
    licensed: true,
    telegram: false,
  };
};

export const KybRegistrationInfoFieldsFactory =
  (): KybRegistrationInfoFields => {
    return {
      organisation_legal_form: false,
      business_area: false,
      "address.country": false,
      "address.city": false,
      "address.state": false,
      "address.street": false,
      "address.office": false,
      "address.zip": false,
      "address.isActual": true,
      "address.country_actual": false,
      "address.city_actual": false,
      "address.state_actual": false,
      "address.street_actual": false,
      "address.office_actual": false,
      "address.zip_actual": false,
      phone: false,
    };
  };

export const KybParticipantFieldsFactory = (): KybParticipantFields => {
  return {
    sur_name: false,
    name: false,
    gender: false,
    birth_date: false,
    citizenship: false,
    "address.country": false,
    "address.city": false,
    "address.state": false,
    "address.street": false,
    "address.office": false,
    "address.zip": false,
  };
};
