import { AxiosInstance } from "axios";

import { AdminDepositWithdrawalUrlGenerator } from "./url-generator";
import {
  AdminDepositWithdrawalListResponse,
  AdminDepositWithdrawalShowResponse,
  AdminDepositWithdrawalUpdateRequest,
} from "./repo";
import { ResponseData } from "@/shared/constants/interfaces";
import {
  AdminDepositWithdrawalListResponseFactory,
  AdminDepositWithdrawalShowResponseFactory,
} from "./factory";
import { AdminDepositWithdrawalStatus } from "./enums";
import { $projectServices } from "@/shared/repository/instances/project-services";

export interface AdminDepositWithdrawalProjectRepository {
  update(
    status: AdminDepositWithdrawalStatus,
    payload: AdminDepositWithdrawalUpdateRequest
  ): Promise<void>;
  delete(id: number): Promise<void>;
  show(id: number): Promise<AdminDepositWithdrawalShowResponse>;
  list(): Promise<AdminDepositWithdrawalListResponse[]>;
}

export class AdminDepositWithdrawalHttpRepo
  implements AdminDepositWithdrawalProjectRepository
{
  constructor(
    private readonly axios: AxiosInstance,
    private readonly urlGenerator: AdminDepositWithdrawalUrlGenerator
  ) {}

  async update(
    status: AdminDepositWithdrawalStatus,
    payload: AdminDepositWithdrawalUpdateRequest
  ): Promise<void> {
    await this.axios.patch<void>(this.urlGenerator.update(status), payload);
  }

  async delete(id: number): Promise<void> {
    await this.axios.delete<void>(this.urlGenerator.delete(id));
  }

  async show(id: number): Promise<AdminDepositWithdrawalShowResponse> {
    const { data } = await this.axios.get<
      ResponseData<AdminDepositWithdrawalShowResponse>
    >(this.urlGenerator.show(id));

    const currency = await $projectServices.adminCurrencyRepository.show(
      data.data.currency_id
    );

    return AdminDepositWithdrawalShowResponseFactory({
      ...data.data,
      currency,
    });
  }

  async list(): Promise<AdminDepositWithdrawalListResponse[]> {
    const { data } = await this.axios.get<
      ResponseData<AdminDepositWithdrawalListResponse[]>
    >(this.urlGenerator.list());

    const list: AdminDepositWithdrawalListResponse[] = [];

    for await (const item of data.data) {
      const currency = await $projectServices.adminCurrencyRepository.show(
        item.currency_id
      );

      list.push({
        ...item,
        currency,
      });
    }

    return list.map((item) => AdminDepositWithdrawalListResponseFactory(item));
  }
}
