export enum ParserApiRoutes {
  login = "/auth/login",
  parse = "/file/parce",
}

export interface ParserProjectUrlGenerator {
  login(): string;
  parse(): string;
}

export class ParserUrlGenerator implements ParserProjectUrlGenerator {
  login(): string {
    return ParserApiRoutes.login;
  }

  parse(): string {
    return ParserApiRoutes.parse;
  }
}
