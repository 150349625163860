<script lang="ts" src="./component.ts"></script>
<style lang="scss" src="./component.scss"></style>

<template>
  <div :class="b()">
    <div :class="b('created-at')">
      <p :class="b('label')">
        {{ displayedCreatedAt }}
      </p>
    </div>

    <div :class="b('user-id')">
      <p :class="b('label')">
        {{ depositWithdrawalItem.user_id }}
      </p>
    </div>

    <div :class="b('email')">
      <p :class="b('label')">
        {{ depositWithdrawalItem.user.email }}
      </p>
    </div>

    <div :class="b('app-type')">
      <p :class="b('label')">
        {{ displayedAppType }}
      </p>
    </div>

    <div :class="b('amount')">
      <p :class="b('label')">
        <span
          :class="
            b('label-amount', { deposit: isDeposit, withdrawal: isWithdrawal })
          "
        >
          {{ displayedAmount }}
        </span>
      </p>

      <p :class="b('label')">
        {{ depositWithdrawalItem.currency.name.toUpperCase() }}
      </p>
    </div>

    <div :class="b('execution-price')">
      <p :class="b('label')">
        {{ depositWithdrawalItem.execution_price }}
      </p>
    </div>

    <div :class="b('transaction-volume')">
      <p :class="b('label')">
        {{ depositWithdrawalItem.transaction_volume }}
      </p>
    </div>

    <div :class="b('commissions')">
      <p :class="b('label')">
        {{ depositWithdrawalItem.commissions }}
      </p>
    </div>

    <div :class="b('number')">
      <p :class="b('label')">
        {{ depositWithdrawalItem.id }}
      </p>
    </div>

    <div :class="b('status')">
      <span :class="b('icon')" v-html="displayedStatusIcon"></span>

      <p :class="b('label')">
        {{ displayedStatus }}
      </p>
    </div>

    <div :class="b('stage')">
      <div v-if="isStageGroupShow" :class="b('stage-group')">
        <button :class="b('stage-work')" @click="handleInWork">
          {{ $t("В работе") }}
        </button>

        <button
          :class="b('action')"
          :disabled="isDisabled"
          @click="handleRemove"
        >
          <span :class="b('icon')" v-html="displayedSvg.remove"></span>
        </button>
      </div>

      <p v-else :class="b('label')">
        {{ depositWithdrawalItem.stage }}
      </p>

      <div :class="b('action')">
        <button
          :class="b('toggle', { active: isVisible })"
          @click="handleToggle"
        >
          <span :class="b('toggle-icon')" v-html="displayedSvg.dots"></span>
        </button>

        <div v-if="isVisible" :class="b('options', { active: isVisible })">
          <button
            v-for="option in displayedOptions"
            :key="option.id"
            :class="b('option')"
            :disabled="option.isDisabled"
            @click="handleOption(option)"
          >
            {{ option.label }}
          </button>
        </div>
      </div>
    </div>
  </div>
</template>
