/* eslint-disable @typescript-eslint/ban-ts-comment */
import { Module, Mutation, VuexModule } from "vuex-module-decorators";

enum KybMutation {
  UPDATE_PARTICIPANT_ID = "UPDATE_PARTICIPANT_ID",
}

interface State {
  participantId?: number | null;
}

@Module({
  namespaced: true,
  stateFactory: true,
  name: "kyb",
})
export default class KybModuleState extends VuexModule<State> {
  participantId = <number | null>null;

  @Mutation
  [KybMutation.UPDATE_PARTICIPANT_ID](participantId: number | null): void {
    this.participantId = participantId;
  }
}
