import { KycPersonalInfoFields } from "./interfaces";
import {
  KycPersonalInfoRequest,
  KycPersonalInfoResponse,
  KycUploadFileRequest,
  KycUploadFileResponse,
} from "./repo";

import {
  extractFileName,
  uniqueFileName,
} from "@/shared/utils/file-name-helpers";

export const KycPersonalInfoRequestFactory = (
  payload: Partial<KycPersonalInfoResponse> = {}
): KycPersonalInfoRequest => {
  return {
    sur_name: payload.sur_name ?? "",
    name: payload.name ?? "",
    patronymic: payload.patronymic ?? "",
    gender: payload.gender ?? "",
    birth_date: payload.birth_date ?? "",
    citizenship: payload.citizenship ?? "",
    passport: payload.passport ?? "",
    passport_date: payload.passport_date ?? "",
    passport_issued_org: payload.passport_issued_org ?? "",
    telegram: payload.telegram ?? "",
    phone: payload.phone ?? "",
    country: payload.address?.country ?? "",
    city: payload.address?.city ?? "",
    state: payload.address?.state ?? "",
    street: payload.address?.street ?? "",
    office: payload.address?.office ?? "",
    zip: payload.address?.zip ?? "",
  };
};

export const KycPersonalInfoResponseFactory = (
  payload: Partial<KycPersonalInfoResponse> = {}
): KycPersonalInfoResponse => {
  return {
    id: payload.id ?? null,
    user_id: payload.user_id ?? null,
    sur_name: payload.sur_name ?? "",
    name: payload.name ?? "",
    patronymic: payload.patronymic ?? "",
    gender: payload.gender ?? "",
    birth_date: payload.birth_date ?? "",
    citizenship: payload.citizenship ?? "",
    passport: payload.passport ?? "",
    passport_date: payload.passport_date ?? "",
    passport_issued_org: payload.passport_issued_org ?? "",
    telegram: payload.telegram ?? "",
    status: payload.status ?? "",
    phone: payload.phone ?? "",
    creatd_at: payload.creatd_at ?? "",
    updated_at: payload.updated_at ?? "",
    deleted_at: payload.deleted_at ?? null,
    address: {
      id: payload.address?.id ?? null,
      user_app_id: payload.address?.user_app_id ?? null,
      country: payload.address?.country ?? "",
      city: payload.address?.city ?? "",
      state: payload.address?.state ?? "",
      street: payload.address?.street ?? "",
      office: payload.address?.office ?? "",
      zip: payload.address?.zip ?? "",
      country_actual: payload.address?.country_actual ?? null,
      city_actual: payload.address?.city_actual ?? null,
      state_actual: payload.address?.state_actual ?? null,
      street_actual: payload.address?.street_actual ?? null,
      office_actual: payload.address?.office_actual ?? null,
      zip_actual: payload.address?.zip_actual ?? null,
      creatd_at: payload.address?.creatd_at ?? "",
      updated_at: payload.address?.updated_at ?? "",
    },
  };
};

export const KycUploadFileRequestFactory = (
  payload: Partial<KycUploadFileResponse> = {}
): KycUploadFileRequest => {
  return {
    file: payload.file ?? "",
    name: payload.name ? `${uniqueFileName(payload.name)}` : "",
    type: payload.type ?? "",
  };
};

export const KycUploadFileResponseFactory = (
  payload: Partial<KycUploadFileResponse> = {}
): KycUploadFileResponse => {
  return {
    id: payload.id ?? null,
    user_id: payload.user_id ?? null,
    name: payload.name ? extractFileName(payload.name) : "",
    generated_name: payload.generated_name ?? "",
    file: payload.file ?? "",
    link: payload.link ?? "",
    type: payload.type ?? "",
    created_at: payload.created_at ?? "",
    updated_at: payload.updated_at ?? "",
    isUpload: payload.isUpload ?? false,
    isRemove: payload.isRemove ?? false,
  };
};

export const KycPersonalInfoFieldsFactory = (): KycPersonalInfoFields => {
  return {
    sur_name: false,
    name: false,
    gender: false,
    birth_date: false,
    citizenship: false,
    passport: false,
    passport_date: false,
    passport_issued_org: false,
    phone: false,
    telegram: false,
    "address.country": false,
    "address.city": false,
    "address.state": false,
    "address.street": false,
    "address.office": false,
    "address.zip": false,
  };
};
