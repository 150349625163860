import { AdminCurrencyType } from "@/shared/repository/modules/admin/currency/enums";

export interface AdminCurrencyTab {
  label: string;
  value: AdminCurrencyType;
  isActive: boolean;
}

export const AdminCurrencyTabs = (
  tab: AdminCurrencyType
): AdminCurrencyTab[] => {
  return [
    {
      label: "Fiat",
      value: AdminCurrencyType.fiat,
      isActive: tab === AdminCurrencyType.fiat,
    },
    {
      label: "Crypto",
      value: AdminCurrencyType.crypto,
      isActive: tab === AdminCurrencyType.crypto,
    },
  ];
};
