import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createBlock as _createBlock } from "vue"

const _hoisted_1 = ["innerHTML"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_WalletCard = _resolveComponent("WalletCard")!

  return (_openBlock(), _createBlock(_component_WalletCard, {
    class: _normalizeClass(_ctx.b()),
    title: "Заявки",
    tab: "application"
  }, {
    default: _withCtx(() => [
      _createElementVNode("div", {
        class: _normalizeClass(_ctx.b('table'))
      }, [
        _createElementVNode("div", {
          class: _normalizeClass(_ctx.b('columns'))
        }, [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.displayedColumns, (column) => {
            return (_openBlock(), _createElementBlock("div", {
              class: _normalizeClass(_ctx.b('column')),
              key: column.id
            }, [
              _createElementVNode("p", {
                class: _normalizeClass(_ctx.b('column-label'))
              }, _toDisplayString(_ctx.$t(column.label)), 3)
            ], 2))
          }), 128))
        ], 2),
        _createElementVNode("div", {
          class: _normalizeClass(_ctx.b('rows'))
        }, [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.applicationHistory, (row, rowIndex) => {
            return (_openBlock(), _createElementBlock("div", {
              class: _normalizeClass(_ctx.b('row')),
              key: rowIndex
            }, [
              _createElementVNode("div", {
                class: _normalizeClass(_ctx.b('row-date-time'))
              }, [
                _createElementVNode("p", {
                  class: _normalizeClass(_ctx.b('row-label'))
                }, _toDisplayString(row), 3)
              ], 2),
              _createElementVNode("div", {
                class: _normalizeClass(_ctx.b('row-operation'))
              }, [
                _createElementVNode("div", {
                  class: _normalizeClass(_ctx.b('row-group'))
                }, [
                  _createElementVNode("p", {
                    class: _normalizeClass(_ctx.b('row-label', { from: true }))
                  }, null, 2),
                  _createElementVNode("p", {
                    class: _normalizeClass(_ctx.b('row-label'))
                  }, null, 2)
                ], 2),
                _createElementVNode("span", {
                  class: _normalizeClass(_ctx.b('row-icon', { chevron: true })),
                  innerHTML: _ctx.displayedIcons.chevron
                }, null, 10, _hoisted_1),
                _createElementVNode("div", {
                  class: _normalizeClass(_ctx.b('row-group'))
                }, [
                  _createElementVNode("p", {
                    class: _normalizeClass(_ctx.b('row-label', { to: true }))
                  }, null, 2),
                  _createElementVNode("p", {
                    class: _normalizeClass(_ctx.b('row-label'))
                  }, null, 2)
                ], 2)
              ], 2)
            ], 2))
          }), 128))
        ], 2)
      ], 2)
    ]),
    _: 1
  }, 8, ["class"]))
}