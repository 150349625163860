import { AdminUsersResponse } from "./repo";

import { Language } from "@/shared/constants/enums";

export const AdminUsersResponseFactory = (
  payload: AdminUsersResponse,
  index: number
): AdminUsersResponse => {
  return {
    index: index + 1,
    id: payload.id ?? 0,
    name: payload.name ?? "",
    email: payload.email ?? "",
    email_verified_at: payload.email_verified_at ?? "",
    lang: payload.lang ?? Language.ru,
    application_type: payload.application_type ?? null,
    kyb_id: payload.kyb_id ?? null,
    kyb_staus: payload.kyb_staus ?? null,
    kyc_id: payload.kyc_id ?? null,
    kyc_status: payload.kyc_status ?? null,
    applicant_name: payload.applicant_name ?? null,
    applicant_surname: payload.applicant_surname ?? null,
    organisation: payload.organisation ?? null,
  };
};
