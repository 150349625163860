import { defineComponent } from "vue";

import { PAGE_NAME } from "./attributes";

import { LoginForm } from "@/components/pages/login";
import { SharedAuthCard } from "@/components/shared";
import { UILink } from "@/components/ui";

import { Error } from "@/shared/constants/interfaces";
import { AuthLoginRequestFactory } from "@/shared/repository/modules/auth/factory";
import { RoutesName } from "@/shared/constants/enums";

export default defineComponent({
  name: PAGE_NAME,
  components: {
    LoginForm,
    SharedAuthCard,
    UILink,
  },
  data() {
    return {
      message: "",
      isLoading: false,
      authLoginRequest: AuthLoginRequestFactory(),
      authRepository: this.$projectServices.authRepository,
    };
  },
  methods: {
    async handleLogin(): Promise<void> {
      try {
        this.isLoading = true;
        this.message = "";

        await this.authRepository.login(this.authLoginRequest);

        this.$router.push({ name: RoutesName.wallet });
      } catch (e) {
        const { message } = <Error>e;

        this.message = message;
      } finally {
        this.isLoading = false;
      }
    },

    handleGoogle(): void {
      console.log("Google");
    },
  },
});
