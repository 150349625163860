import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, resolveDynamicComponent as _resolveDynamicComponent, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(_ctx.b())
  }, [
    _createElementVNode("div", {
      class: _normalizeClass(_ctx.b('columns', { application: _ctx.isApplication }))
    }, [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.displayedColumns, (column) => {
        return (_openBlock(), _createElementBlock("div", {
          class: _normalizeClass(_ctx.b('column')),
          key: column.id
        }, [
          _createElementVNode("p", {
            class: _normalizeClass(_ctx.b('column-label'))
          }, _toDisplayString(_ctx.$t(column.label)), 3)
        ], 2))
      }), 128)),
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.displayedSmallColumns, (column) => {
        return (_openBlock(), _createElementBlock("div", {
          class: _normalizeClass(_ctx.b('column', { small: true })),
          key: column.id
        }, [
          _createElementVNode("p", {
            class: _normalizeClass(_ctx.b('column-label'))
          }, _toDisplayString(_ctx.$t(column.label)), 3)
        ], 2))
      }), 128))
    ], 2),
    _createElementVNode("div", {
      class: _normalizeClass(_ctx.b('rows'))
    }, [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.displayedRows, (row) => {
        return (_openBlock(), _createBlock(_resolveDynamicComponent(_ctx.displayedRow), {
          class: _normalizeClass(_ctx.b('row')),
          key: row.id,
          row: row,
          onIsDetails: _ctx.handleIsDetails
        }, null, 40, ["class", "row", "onIsDetails"]))
      }), 128))
    ], 2)
  ], 2))
}