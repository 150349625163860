import { toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_UIButton = _resolveComponent("UIButton")!
  const _component_SharedWindow = _resolveComponent("SharedWindow")!

  return (_openBlock(), _createBlock(_component_SharedWindow, {
    class: _normalizeClass(_ctx.b()),
    title: "Детали операции",
    onClose: _ctx.handleClose
  }, {
    default: _withCtx(() => [
      _createElementVNode("div", {
        class: _normalizeClass(_ctx.b('wrapper'))
      }, [
        _createElementVNode("div", {
          class: _normalizeClass(_ctx.b('list'))
        }, [
          _createElementVNode("div", {
            class: _normalizeClass(_ctx.b('item'))
          }, [
            _createElementVNode("h4", {
              class: _normalizeClass(_ctx.b('item-title'))
            }, _toDisplayString(_ctx.$t("Дата и время")), 3),
            _createElementVNode("p", {
              class: _normalizeClass(_ctx.b('item-label'))
            }, _toDisplayString(_ctx.displayedList.dateTime.label), 3)
          ], 2),
          _createElementVNode("div", {
            class: _normalizeClass(_ctx.b('item'))
          }, [
            _createElementVNode("h4", {
              class: _normalizeClass(_ctx.b('item-title'))
            }, _toDisplayString(_ctx.$t("Тип")), 3),
            _createElementVNode("p", {
              class: _normalizeClass(_ctx.b('item-label'))
            }, _toDisplayString(_ctx.displayedList.type.label), 3)
          ], 2),
          _createElementVNode("div", {
            class: _normalizeClass(_ctx.b('item'))
          }, [
            _createElementVNode("h4", {
              class: _normalizeClass(_ctx.b('item-title'))
            }, _toDisplayString(_ctx.$t("Сумма и валюта")), 3),
            _createElementVNode("p", {
              class: _normalizeClass(_ctx.b('item-label'))
            }, _toDisplayString(_ctx.displayedList.amountCurrency.label), 3)
          ], 2),
          _createElementVNode("div", {
            class: _normalizeClass(_ctx.b('item'))
          }, [
            _createElementVNode("h4", {
              class: _normalizeClass(_ctx.b('item-title'))
            }, _toDisplayString(_ctx.$t("Комиссия")), 3),
            _createElementVNode("p", {
              class: _normalizeClass(_ctx.b('item-label'))
            }, _toDisplayString(_ctx.displayedList.exchange.label), 3)
          ], 2),
          _createElementVNode("div", {
            class: _normalizeClass(_ctx.b('item'))
          }, [
            _createElementVNode("h4", {
              class: _normalizeClass(_ctx.b('item-title'))
            }, _toDisplayString(_ctx.$t("Статус")), 3),
            _createElementVNode("p", {
              class: _normalizeClass(_ctx.b('item-label'))
            }, _toDisplayString(_ctx.displayedList.status.label), 3)
          ], 2),
          (_ctx.displayedList.address)
            ? (_openBlock(), _createElementBlock("div", {
                key: 0,
                class: _normalizeClass(_ctx.b('item', { address: true }))
              }, [
                _createElementVNode("h4", {
                  class: _normalizeClass(_ctx.b('item-title'))
                }, _toDisplayString(_ctx.$t("Адрес перевода")), 3),
                (!_ctx.displayedList.address.info)
                  ? (_openBlock(), _createElementBlock("p", {
                      key: 0,
                      class: _normalizeClass(_ctx.b('item-label'))
                    }, _toDisplayString(_ctx.displayedList.address.label), 3))
                  : _createCommentVNode("", true),
                (_ctx.displayedList.address.info)
                  ? (_openBlock(), _createElementBlock("div", {
                      key: 1,
                      class: _normalizeClass(_ctx.b('list', { info: true }))
                    }, [
                      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.displayedList.address.info, (item) => {
                        return (_openBlock(), _createElementBlock("div", {
                          class: _normalizeClass(_ctx.b('item', { info: true })),
                          key: item?.title
                        }, [
                          _createElementVNode("h4", {
                            class: _normalizeClass(_ctx.b('item-title'))
                          }, _toDisplayString(_ctx.$t(item?.title ?? "")), 3),
                          _createElementVNode("p", {
                            class: _normalizeClass(_ctx.b('item-label'))
                          }, _toDisplayString(item?.label), 3)
                        ], 2))
                      }), 128))
                    ], 2))
                  : _createCommentVNode("", true)
              ], 2))
            : _createCommentVNode("", true)
        ], 2),
        _createVNode(_component_UIButton, {
          label: "Закрыть",
          view: "main",
          disabled: _ctx.isDisabled,
          onClick: _ctx.handleClose
        }, null, 8, ["disabled", "onClick"])
      ], 2)
    ]),
    _: 1
  }, 8, ["class", "onClose"]))
}