<script lang="ts" src="./component.ts"></script>
<style lang="scss" src="./component.scss"></style>

<template>
  <SharedCard :class="b()">
    <h3 :class="b('title')">
      {{ $t("Персональная информация") }}
    </h3>

    <div :class="b('form')">
      <SharedValidate
        field="sur_name"
        :value="kycPersonalInfo.sur_name"
        :is-validate="isValidate"
        v-model:field-value="kycPersonalInfoFields.sur_name"
      >
        <div :class="b('form-group')">
          <h5 :class="b('form-label')">
            {{ $t("Фамилия*") }}
          </h5>

          <UIInput
            view="light"
            :is-readonly="isReadonly"
            :is-error="isValidate && !kycPersonalInfoFields.sur_name"
            v-model="kycPersonalInfo.sur_name"
          />
        </div>
      </SharedValidate>

      <SharedValidate
        field="name"
        :value="kycPersonalInfo.name"
        :is-validate="isValidate"
        v-model:field-value="kycPersonalInfoFields.name"
      >
        <div :class="b('form-group')">
          <h5 :class="b('form-label')">
            {{ $t("Имя*") }}
          </h5>

          <UIInput
            view="light"
            :is-readonly="isReadonly"
            :is-error="isValidate && !kycPersonalInfoFields.name"
            v-model="kycPersonalInfo.name"
          />
        </div>
      </SharedValidate>

      <div :class="b('form-group')">
        <h5 :class="b('form-label')">
          {{ $t("Отчество") }}
        </h5>

        <UIInput
          view="light"
          :is-readonly="isReadonly"
          v-model="kycPersonalInfo.patronymic"
        />
      </div>

      <SharedValidate
        field="gender"
        :value="kycPersonalInfo.gender"
        :is-validate="isValidate"
        v-model:field-value="kycPersonalInfoFields.gender"
      >
        <div :class="b('form-group')">
          <h5 :class="b('form-label')">
            {{ $t("Укажите ваш пол*") }}
          </h5>

          <div :class="b('form-radios')">
            <div :class="b('form-radio')">
              <SharedRadio
                view="light"
                checked-value="male"
                :is-readonly="isReadonly"
                :is-error="isValidate && !kycPersonalInfoFields.gender"
                v-model:value="kycPersonalInfo.gender"
              />

              <p :class="b('form-radio-label')">
                {{ $t("Мужчина") }}
              </p>
            </div>

            <div :class="b('form-radio')">
              <SharedRadio
                view="light"
                checked-value="female"
                :is-readonly="isReadonly"
                :is-error="isValidate && !kycPersonalInfoFields.gender"
                v-model:value="kycPersonalInfo.gender"
              />

              <p :class="b('form-radio-label')">
                {{ $t("Женщина") }}
              </p>
            </div>
          </div>
        </div>
      </SharedValidate>

      <SharedValidate
        field="birth_date"
        :value="kycPersonalInfo.birth_date"
        :is-validate="isValidate"
        v-model:field-value="kycPersonalInfoFields.birth_date"
      >
        <div :class="b('form-group')">
          <h5 :class="b('form-label')">
            {{ $t("Дата рождения*") }}
          </h5>

          <SharedDate
            format="yyyy-MM-dd"
            :is-readonly="isReadonly"
            :is-error="isValidate && !kycPersonalInfoFields.birth_date"
            v-model:date="kycPersonalInfo.birth_date"
          />
        </div>
      </SharedValidate>

      <SharedValidate
        field="citizenship"
        :value="kycPersonalInfo.citizenship"
        :is-validate="isValidate"
        v-model:field-value="kycPersonalInfoFields.citizenship"
      >
        <div :class="b('form-group')">
          <h5 :class="b('form-label')">
            {{ $t("Гражданство*") }}
          </h5>

          <SharedCountry
            placeholder="Выберите страну"
            :is-readonly="isReadonly"
            :is-error="isValidate && !kycPersonalInfoFields.citizenship"
            v-model:value="kycPersonalInfo.citizenship"
          />
        </div>
      </SharedValidate>

      <SharedValidate
        field="passport"
        :value="kycPersonalInfo.passport"
        :is-validate="isValidate"
        v-model:field-value="kycPersonalInfoFields.passport"
      >
        <div :class="b('form-group')">
          <h5 :class="b('form-label')">
            {{ $t("Серия и номер паспорта*") }}
          </h5>

          <UIInput
            view="light"
            :is-readonly="isReadonly"
            :is-error="isValidate && !kycPersonalInfoFields.passport"
            v-model="kycPersonalInfo.passport"
          />
        </div>
      </SharedValidate>

      <SharedValidate
        field="passport_date"
        :value="kycPersonalInfo.passport_date"
        :is-validate="isValidate"
        v-model:field-value="kycPersonalInfoFields.passport_date"
      >
        <div :class="b('form-group')">
          <h5 :class="b('form-label')">
            {{ $t("Дата выдачи паспорта*") }}
          </h5>

          <SharedDate
            format="yyyy-MM-dd"
            :is-readonly="isReadonly"
            :is-error="isValidate && !kycPersonalInfoFields.passport_date"
            v-model:date="kycPersonalInfo.passport_date"
          />
        </div>
      </SharedValidate>

      <SharedValidate
        field="passport_issued_org"
        :value="kycPersonalInfo.passport_issued_org"
        :is-validate="isValidate"
        v-model:field-value="kycPersonalInfoFields.passport_issued_org"
      >
        <div :class="b('form-group')">
          <h5 :class="b('form-label')">
            {{ $t("Наименование органа выдавшего документ*") }}
          </h5>

          <UIInput
            view="light"
            :is-readonly="isReadonly"
            :is-error="isValidate && !kycPersonalInfoFields.passport_issued_org"
            v-model="kycPersonalInfo.passport_issued_org"
          />
        </div>
      </SharedValidate>
    </div>

    <div :class="b('form', { address: true })">
      <div :class="b('form-group')">
        <h5 :class="b('form-label')">
          {{ $t("Адрес организации*") }}
        </h5>

        <div :class="b('form-block', { address: true })">
          <SharedValidate
            :class="b('form-country')"
            field="address.country"
            :value="kycPersonalInfo.address.country"
            :is-validate="isValidate"
            v-model:field-value="kycPersonalInfoFields['address.country']"
          >
            <SharedCountry
              :class="b('form-country')"
              placeholder="Cтрана"
              :is-readonly="isReadonly"
              :is-error="
                isValidate && !kycPersonalInfoFields['address.country']
              "
              v-model:value="kycPersonalInfo.address.country"
            />
          </SharedValidate>

          <SharedValidate
            :class="b('form-city')"
            field="address.city"
            :value="kycPersonalInfo.address.city"
            :is-validate="isValidate"
            v-model:field-value="kycPersonalInfoFields['address.city']"
          >
            <UIInput
              :class="b('form-city')"
              placeholder="Город"
              view="light"
              :is-readonly="isReadonly"
              :is-error="isValidate && !kycPersonalInfoFields['address.city']"
              v-model="kycPersonalInfo.address.city"
            />
          </SharedValidate>

          <SharedValidate
            :class="b('form-state')"
            field="address.state"
            :value="kycPersonalInfo.address.state"
            :is-validate="isValidate"
            v-model:field-value="kycPersonalInfoFields['address.state']"
          >
            <UIInput
              placeholder="Штат / Область"
              view="light"
              :is-readonly="isReadonly"
              :is-error="isValidate && !kycPersonalInfoFields['address.state']"
              v-model="kycPersonalInfo.address.state"
            />
          </SharedValidate>

          <SharedValidate
            :class="b('form-street')"
            field="address.street"
            :value="kycPersonalInfo.address.street"
            :is-validate="isValidate"
            v-model:field-value="kycPersonalInfoFields['address.street']"
          >
            <UIInput
              placeholder="Улица"
              view="light"
              :is-readonly="isReadonly"
              :is-error="isValidate && !kycPersonalInfoFields['address.street']"
              v-model="kycPersonalInfo.address.street"
            />
          </SharedValidate>

          <SharedValidate
            :class="b('form-office')"
            field="address.office"
            :value="kycPersonalInfo.address.office"
            :is-validate="isValidate"
            v-model:field-value="kycPersonalInfoFields['address.office']"
          >
            <UIInput
              placeholder="Номер Офиса"
              view="light"
              :is-readonly="isReadonly"
              :is-error="isValidate && !kycPersonalInfoFields['address.office']"
              v-model="kycPersonalInfo.address.office"
            />
          </SharedValidate>

          <SharedValidate
            :class="b('form-index')"
            field="address.zip"
            :value="kycPersonalInfo.address.zip"
            :is-validate="isValidate"
            v-model:field-value="kycPersonalInfoFields['address.zip']"
          >
            <UIInput
              placeholder="Индекс"
              view="light"
              :is-readonly="isReadonly"
              :is-error="isValidate && !kycPersonalInfoFields['address.zip']"
              v-model="kycPersonalInfo.address.zip"
            />
          </SharedValidate>
        </div>
      </div>
    </div>

    <div :class="b('form', { last: true })">
      <SharedValidate
        field="phone"
        :value="kycPersonalInfo.phone"
        :is-validate="isValidate"
        v-model:field-value="kycPersonalInfoFields.phone"
      >
        <div :class="b('form-group')">
          <h5 :class="b('form-label')">
            {{ $t("Номер телефона*") }}
          </h5>

          <UIInput
            view="light"
            :is-readonly="isReadonly"
            :is-error="isValidate && !kycPersonalInfoFields.phone"
            v-model="kycPersonalInfo.phone"
          />
        </div>
      </SharedValidate>

      <SharedValidate
        field="telegram"
        :value="kycPersonalInfo.telegram"
        :is-validate="isValidate"
        v-model:field-value="kycPersonalInfoFields.telegram"
      >
        <div :class="b('form-group')">
          <h5 :class="b('form-label')">
            {{ $t("Телеграм для связи*") }}
          </h5>

          <UIInput
            view="light"
            :is-telegram="true"
            :is-readonly="isReadonly"
            :is-error="isValidate && !kycPersonalInfoFields.telegram"
            v-model="kycPersonalInfo.telegram"
          />
        </div>
      </SharedValidate>
    </div>

    <UIButton
      :class="b('continue')"
      label="Продолжить"
      view="main"
      :disabled="isContinueDisabled"
      @click="handleContinue"
    />
  </SharedCard>
</template>
