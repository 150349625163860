import { defineComponent, PropType } from "vue";

import { COMPONENT_NAME, SVG } from "./attributes";

import { SvgAttribute } from "@/shared/constants/constants";
import {
  AdminCurrencyCryptoResponse,
  AdminCurrencyFiatResponse,
} from "@/shared/repository/modules/admin/currency/repo";
import { AdminCurrencyModalFactory } from "@/shared/repository/modules/admin/currency/factory";
import {
  AdminCurrencyModalOption,
  AdminCurrencyType,
} from "@/shared/repository/modules/admin/currency/enums";

export default defineComponent({
  name: COMPONENT_NAME,
  props: {
    row: {
      type: <PropType<AdminCurrencyFiatResponse | AdminCurrencyCryptoResponse>>(
        Object
      ),
      required: true,
    },
  },
  data() {
    return {
      isVisible: false,
      adminCurrencyRepo: this.$projectServices.adminCurrencyRepo,
    };
  },
  computed: {
    displayedIcons(): SvgAttribute {
      return SVG;
    },

    displayedCryptoIcon(): string {
      return require(`@/assets/images/crypto/${this.row.name.toLowerCase()}.svg`);
    },

    isCryptoIconShow(): boolean {
      return this.row.type === AdminCurrencyType.crypto;
    },
  },
  methods: {
    handleToggle(): void {
      this.isVisible = !this.isVisible;
    },

    handleHide(): void {
      this.isVisible = false;
    },

    handleDelete(): void {
      this.adminCurrencyRepo.UPDATE_MODAL(
        AdminCurrencyModalFactory({
          id: this.row.id,
          type: this.row.type,
          option: AdminCurrencyModalOption.delete,
          isShow: true,
        })
      );

      this.handleHide();
    },
  },
});
