import { defineComponent, PropType } from "vue";

import { COMPONENT_NAME } from "./attributes";

import { UIInput, UIButton } from "@/components/ui";
import {
  SharedCard,
  SharedRadio,
  SharedDate,
  SharedCountry,
  SharedValidate,
} from "@/components/shared";

import { VerificationKycComponent } from "@/views/verification/model";
import {
  KycPersonalInfoFieldsFactory,
  KycPersonalInfoRequestFactory,
  KycPersonalInfoResponseFactory,
} from "@/shared/repository/modules/kyc/factory";

export default defineComponent({
  name: COMPONENT_NAME,
  components: {
    UIInput,
    UIButton,
    SharedCard,
    SharedRadio,
    SharedDate,
    SharedCountry,
    SharedValidate,
  },
  props: {
    component: {
      type: <PropType<VerificationKycComponent>>String,
      default: "",
    },
  },
  emits: {
    "update:component": null,
  },
  data() {
    return {
      isValidate: false,
      isLoading: false,
      userRepo: this.$projectServices.userRepo,
      kycRepository: this.$projectServices.kycRepository,
      kycPersonalInfo: KycPersonalInfoResponseFactory(),
      kycPersonalInfoFields: KycPersonalInfoFieldsFactory(),
    };
  },
  async created(): Promise<void> {
    try {
      this.isLoading = true;

      this.kycPersonalInfo = await this.kycRepository.personalInfo();
    } catch (e) {
      console.log(e);
    } finally {
      this.isLoading = false;
    }
  },
  computed: {
    isReadonly(): boolean {
      return this.userRepo.isReadonlyKyc;
    },

    isDisabled(): boolean {
      return Object.values(this.kycPersonalInfoFields).some((field) => !field);
    },

    isContinueDisabled(): boolean {
      return this.isLoading;
    },
  },
  methods: {
    async handleContinue(): Promise<void> {
      try {
        this.isLoading = true;

        if (this.isDisabled) {
          this.isValidate = true;

          return;
        }

        if (!this.isReadonly) {
          await this.kycRepository.personalInfoUpdate(
            KycPersonalInfoRequestFactory(this.kycPersonalInfo)
          );
        }

        this.$emit("update:component", VerificationKycComponent.kycDocuments);
      } catch (e) {
        console.log(e);
      } finally {
        this.isLoading = false;
      }
    },
  },
});
